define("@additive-apps/ui/components/ui-label", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-label flex mb05 font-sm">
    <label class="label secondary pr05 flex-grow-1 ellipsis" for={{this.for}}>
      {{this.value}}
    </label>
    {{#if this.helperText}}
      <div
        class="ui-input__info ph05 blue ellipsis cpointer"
        tabindex="1"
        {{on "click" this.onHelp bubbles=false}}
      >
        {{this.helperText}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "xW5smUiJ",
    "block": "[[[10,0],[14,0,\"ui-label flex mb05 font-sm\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"label secondary pr05 flex-grow-1 ellipsis\"],[15,\"for\",[30,0,[\"for\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"value\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"helperText\"]],[[[1,\"    \"],[11,0],[24,0,\"ui-input__info ph05 blue ellipsis cpointer\"],[24,\"tabindex\",\"1\"],[4,[38,1],[\"click\",[30,0,[\"onHelp\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n      \"],[1,[30,0,[\"helperText\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"on\"]]",
    "moduleName": "@additive-apps/ui/components/ui-label.hbs",
    "isStrictMode": false
  });
  let UiLabelComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiLabelComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * for attribute of the label component
       *
       * @argument for
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "for", _descriptor, this);
      /**
       * Text that is shown in the label component
       *
       * @argument value
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "value", _descriptor2, this);
      /**
       * Text that is shown when hovering over the label component
       *
       * @argument helperText
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "helperText", _descriptor3, this);
    }
    /**
     * Function that is executed on click
     *
     * @argument onHelp
     * @type {Function}
     */

    onHelp() {}
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "for", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "helperText", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onHelp", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onHelp"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLabelComponent);
});