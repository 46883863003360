define("@additive-apps/ui/components/ui-toggle-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-toggle-group flex gap1">
    {{yield
      (hash
        item=(component
          "ui-toggle-group/item"
          activeValue=this._activeValue
          theme=this.theme
          isReadOnly=this.isReadOnly
          onChange=this._onChange
        )
      )
    }}
  </div>
  
  */
  {
    "id": "5Vl7TS+Z",
    "block": "[[[10,0],[14,0,\"ui-toggle-group flex gap1\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"ui-toggle-group/item\",0,null,[[\"activeValue\",\"theme\",\"isReadOnly\",\"onChange\"],[[30,0,[\"_activeValue\"]],[30,0,[\"theme\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"_onChange\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-toggle-group.hbs",
    "isStrictMode": false
  });
  /**
   * The toggle-group is similar to a radio-button-group and can be used to select a value
   *
   * @class ui-toggle-group
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  let UiToggleGroup = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiToggleGroup extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the theme of the toggle-group
       *  - white
       *  - dark
       *
       * @property theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor, this);
      /**
       * the selected toggle value
       *
       * @property activeValue
       * @type {String|Number}
       * @default null
       */
      _initializerDefineProperty(this, "activeValue", _descriptor2, this);
      /**
       * whether the toggles are read-only
       *
       * @property isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      /**
       * internal active value
       *
       * @property _activeValue
       * @type {String|Number}
       * @private
       */
      _initializerDefineProperty(this, "_activeValue", _descriptor4, this);
      /**
       * the callback that is invoked when the active value changes
       *
       * @function onChange
       * @param {String|Number} value
       */
      _initializerDefineProperty(this, "onChange", _descriptor5, this);
    }
    /**
     * changes the active value and calls the callback
     *
     * @function onChange
     * @param {String|Number} value
     */
    _onChange(value) {
      this.onChange(value);
      this._activeValue = value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeValue", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_activeValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.activeValue;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return (/* value */) => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiToggleGroup);
});