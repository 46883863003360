define("@additive-apps/auth/helpers/access-guard", ["exports", "@ember/component/helper", "@additive-apps/auth/utils/roles-map"], function (_exports, _helper, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * template helper to expose the hasAccess check
   *
   * @extends Ember.Helper
   */
  class AccessGuard extends _helper.default {
    /**
     * Checks if a given userRole respects the requiredRoles roles access map.
     *
     * @param {STRING} requiredRole the min. required access-role
     * @param {STRING} userRole the current users role
     */
    compute(_ref) {
      let [requiredRole, userRole] = _ref;
      return (0, _rolesMap.hasAccess)(requiredRole, userRole);
    }
  }
  _exports.default = AccessGuard;
});