define("@additive-apps/ui/components/ui-table/head-cell", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-table/head-cell", "@ember/runloop", "@ember/object/computed", "@additive-apps/ui/utils/constants"], function (_exports, _component, _headCell, _runloop, _computed, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _headCell.default,
    //eslint-disable-next-line ember/require-tagless-components
    tagName: 'th',
    classNames: ['ui-table__head-cell', 'ph1', 'bg-white', 'font-sm'],
    classNameBindings: ['column.sort:cpointer', '_isSorted:blue'],
    /**
     * the column object
     *  ```js
     *  {
     *      name: String,
     *      key: String,
     *      description: String,
     *      minWidth: String,
     *      classNames: String,
     *      sort: String,
     *      compare: Function
     *  }
     * ```
     *
     * @property column
     * @type {Object}
     * @default undefined
     */
    column: undefined,
    _isSorted: (0, _computed.or)('_isAscSorted', '_isDescSorted'),
    _isAscSorted: (0, _computed.equal)('sorted', _constants.SORT_DIRECTIONS.ASCENDING),
    _isDescSorted: (0, _computed.equal)('sorted', _constants.SORT_DIRECTIONS.DESCENDING),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      if (this.column && this.column.sort) {
        this._onClick = (0, _runloop.bind)(this, this._onClick);
        if (this.element) {
          this.element.addEventListener('mouseup', this._onClick, false);
        }
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      if (this.column && this.column.sort) {
        if (this.element) {
          this.element.removeEventListener('mouseup', this._onClick, false);
        }
      }
    },
    onClick() {},
    /**
     * the function that is triggered on click, calls the onClick callback action
     *
     * @function onClick
     * @event mouseup
     */
    _onClick() {
      const column = this.column;
      this.onClick(column);
    }
  });
});