define("@additive-apps/ui/components/ui-filter-inputs/daterange", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/daterange", "@ember/object", "@ember/object/computed", "@additive-apps/ui/utils/date-util", "date-fns", "lodash.clonedeep", "@additive-apps/ui/utils/constants"], function (_exports, _base, _daterange, _object, _computed, _dateUtil, _dateFns, _lodash, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Filter-input for type `date_range`.
   * Renders select with provided `values`,
   * when `custom` is selected we render an additional date-picker,
   * the filter-value is in this case the date-picker value,
   * otherwise it will be the value of the select-option like `lastWeek`.
   *
   * When `custom` value has meta configuration we use a different view.
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/daterange
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _daterange.default,
    /**
     * The custom meta range
     *
     * @property _customMetaRange
     * @return {Object}
     * @private
     */
    _customMetaRange: null,
    /**
     * Whether `custom` value has meta configuration
     *
     * @computed _hasCustomMeta
     * @return {Boolean}
     * @private
     */
    _hasCustomMeta: (0, _computed.equal)('_customValue.meta.year', false),
    /**
     * Whether `custom` is selected
     *
     * @computed _isCustom
     * @return {Boolean}
     * @private
     */
    _isCustom: (0, _computed.equal)('_singleSelected', 'custom'),
    /**
     * Whether `custom` is selected
     *
     * @computed _isCustom
     * @return {Boolean}
     * @private
     */
    _customValue: (0, _object.computed)('filter.values.[]', {
      get() {
        return (this.filter && this.filter.values || []).find(value => value.value === 'custom');
      }
    }),
    /**
     * The current selected value of the `single` filter-input component
     *
     * @computed _singleSelected
     * @return {Any}
     * @private
     */
    _singleSelected: (0, _object.computed)('_singleInitialValue', {
      get() {
        const initialValue = this._singleInitialValue;
        if (initialValue && initialValue.label) {
          return initialValue.value;
        }
        return null;
      },
      set(key, value) {
        return value;
      }
    }),
    /**
     * The initial selected value of the `single` filter-input component
     *
     * @computed _singleInitialValue
     * @return {Any}
     * @private
     */
    _singleInitialValue: (0, _object.computed)('initialValues.[]', 'initialValue', 'filter.{key,values.@each.value}', {
      get() {
        /* Check whether initial value is one simple select-value like `lastWeek` */
        if (!Array.isArray(this.initialValue) && this.initialValue) {
          return this.initialValue;
        }
        if (!this.initialValues) {
          return null;
        }
        const filterValues = this.filter && this.filter.values;
        const initialValue = this.initialValues && this.initialValues[this.filter.key];

        /* When `filter.values` is empty or a empty array we return the given initial value */
        if (!Array.isArray(filterValues) || Array.isArray(filterValues) && filterValues.length === 0) {
          return initialValue;
        }

        /* When we have a initial value it must be a custom value, so use custom filter value */
        if (initialValue) {
          return filterValues.find(value => value.value === 'custom');
        }
        return null;
      },
      set(key, value) {
        return value;
      }
    }),
    /**
     * The initial date when filter-value is `custom`
     *
     * @computed _initialRange
     * @return {Object|null}
     * @private
     */
    _initialRange: (0, _object.computed)('_isCustom', 'initialValues', 'filter.{key,values.@each.value}', {
      get() {
        if (!this._isCustom || !this.initialValues) {
          return null;
        }
        const initialValue = this.initialValues[this.filter.key];
        try {
          let [start, end] = Array.isArray(initialValue) ? initialValue : [initialValue];
          start = (0, _dateUtil.parseDate)(start);
          end = end && (0, _dateUtil.parseDate)(end);
          const validOrNull = date => date instanceof Date && !isNaN(date) ? date : null;
          return {
            start: validOrNull(start),
            end: validOrNull(end)
          };
        } catch (e) {
          return null;
        }
      },
      set(key, value) {
        return value;
      }
    }),
    /**
     * The initial value for the custom meta range when filter-value is `custom`
     *
     * @computed _initialCustomMetaRange
     * @return {Object|null}
     * @private
     */
    _initialCustomMetaRange: (0, _object.computed)('_isCustom', 'initialValues', 'filter.{key,values.@each.value}', {
      get() {
        if (!this._isCustom || !this.initialValues) {
          return {
            start: {
              day: null,
              month: null
            },
            end: {
              day: null,
              month: null
            }
          };
        }
        const initialValue = this.initialValues[this.filter.key];
        let startDay = null;
        let startMonth = null;
        let endDay = null;
        let endMonth = null;
        if (Array.isArray(initialValue)) {
          const start = initialValue && initialValue[0];
          [startMonth, startDay] = start && start.split('-') || [];
          const end = initialValue && initialValue[1];
          [endMonth, endDay] = end && end.split('-') || [];
        } else {
          const start = initialValue;
          [startMonth, startDay] = start && start.split('-') || [];
        }
        return {
          start: {
            day: startDay,
            month: startMonth
          },
          end: {
            day: endDay,
            month: endMonth
          }
        };
      },
      set(key, value) {
        return value;
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_customMetaRange', (0, _lodash.default)(this._initialCustomMetaRange));
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      const hasNoInitialValue = !this.initialValue || !Array.isArray(this.initialValue) || this.initialValue.length === 0;

      /*
       * initialValue will be undefined when a range is selected,
       * so when initialRange/initialCustomMetaRange is present but no initialValue,
       * we set `custom` as selected
       */
      if (this._hasCustomMeta) {
        if (hasNoInitialValue && this._initialCustomMetaRange && (this._initialCustomMetaRange.start.day && this._initialCustomMetaRange.start.month || this._initialCustomMetaRange.end.day && this._initialCustomMetaRange.end.month)) {
          (0, _object.set)(this, '_singleSelected', 'custom');
          (0, _object.set)(this, '_customMetaRange', (0, _lodash.default)(this._initialCustomMetaRange));
          return;
        }
      } else {
        if (hasNoInitialValue && this._initialRange && (this._initialRange.start || this._initialRange.end)) {
          (0, _object.set)(this, '_singleSelected', 'custom');
        }
        return;
      }
      if (this.initialValue !== this._singleSelected) {
        (0, _object.set)(this, '_singleSelected', this.initialValue);
      }
    },
    _buildOnChangeCustomMetaRange() {
      const customMetaRange = this._customMetaRange;
      const ensureTwoDigits = value => value && ('0' + value).slice(-2);
      const startDay = ensureTwoDigits(customMetaRange.start.day);
      const startMonth = ensureTwoDigits(customMetaRange.start.month);
      const endDay = ensureTwoDigits(customMetaRange.end.day);
      const endMonth = ensureTwoDigits(customMetaRange.end.month);
      let response = null;
      if (startDay && startMonth) {
        response = [];
        response.push(`${startMonth}-${startDay}`);
        if (endDay && endMonth) {
          response.push(`${endMonth}-${endDay}`);
        }
      }
      return response;
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onCustomMetaChange(mode, key, value) {
        const customMetaRange = (0, _lodash.default)(this._customMetaRange);
        (0, _object.set)(customMetaRange, `${mode}.${key}`, value);
        (0, _object.set)(this, '_customMetaRange', customMetaRange);
        const onChangeValue = this._buildOnChangeCustomMetaRange();
        this._onChange(this.filter.key, onChangeValue || null);
      },
      onOptionChange(key, value) {
        (0, _object.set)(this, '_singleSelected', value);

        /* Trigger onChange for initial values when changed to custom */
        if (value === 'custom') {
          let onChangeValue = null;
          if (this._hasCustomMeta) {
            onChangeValue = this._buildOnChangeCustomMetaRange();
          } else {
            const range = this._initialRange;
            if (range && range.start) {
              let result = [(0, _dateFns.format)(range.start, _constants.UI_DATEPICKER_DEFAULT_FORMAT)];
              if (range.end) {
                result.push([(0, _dateFns.format)(range.end, _constants.UI_DATEPICKER_DEFAULT_FORMAT)]);
              }
              onChangeValue = result;
            }
          }
          this._onChange(key, onChangeValue || null);
        } else {
          /* Reset possible inital values */
          (0, _object.setProperties)(this, {
            _customMetaRange: {
              start: {},
              end: {}
            },
            _initialRange: null,
            _initialCustomMetaRange: null
          });
          this._onChange(key, value);
        }
      },
      onDatepickerChange(start, end) {
        let value = [start];
        end && value.push(end);
        this._onChange(this.filter.key, value);
      }
    }
  });
});