define("@additive-apps/ui/components/ui-editor-legacy", ["exports", "@ember/object/computed", "@ember/component", "@additive-apps/ui/templates/components/ui-editor-legacy", "@ember/runloop", "@ember/object", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/utils/editor-util"], function (_exports, _computed, _component, _uiEditorLegacy, _runloop, _object, _domUtil, _editorUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components

  const CONTENT_CLASS = '.ui-editor__content';
  const ACTION_CLASS = '.ui-editor__action';
  // classname for an active action: sets color to blue
  const ACTIVE_ACTION_CLASS_NAME = 'ui-editor__action--active';

  /**
   *  WYSIWYG text editor with formatting options
   *
   * ```hbs
   * {{ui-editor
   *    value
   *    isLight
   *    isDisabled
   *    label
   *    helperText
   *    infoMessage
   *    errorMessage
   *    theme
   *    isTouched
   *    maxlength
   *    onHelp
   *    onChange
   *    onBlur
   *    onFocus
   * }}
   * ```
   *
   * @class ui-editor
   * @module ui-editor
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiEditorLegacy.default,
    classNames: ['ui-editor-container', 'flex', 'flex-column'],
    classNameBindings: ['_isError:ui-editor-container--error', 'isDisabled:ui-editor-container--disabled'],
    attributeBindings: ['theme'],
    /**
     * offset of the sticky action bar
     *
     * @property actionBarOffset
     * @type {Number}
     * @default 56
     */
    actionBarOffset: 56,
    /**
     * the placeholders that are allowed as weblink
     *
     * @argument allowedWebLinkPlaceholders
     * @type {Array}
     * @default null
     */
    allowedWebLinkPlaceholders: null,
    /**
     * the error message displayed below the editor, only if it was touched
     *
     * @property errorMessage
     * @type {String}
     * @default null
     */
    errorMessage: null,
    /**
     * whether the heading actions are displayed,
     *
     * @property hasEditorActions
     * @type {Boolean}
     * @default true
     */
    hasHeadingActions: true,
    /**
     * the info message displayed below the editor
     *
     * @property infoMessage
     * @type {String}
     * @default null
     */
    infoMessage: null,
    /**
     * whether the editor is disabled
     *
     * @property isDisabled
     * @type {Boolean}
     * @default false
     */
    isDisabled: false,
    /**
     * whether the editor is focused
     *
     * @property isFocused
     * @type {Boolean}
     * @default false
     */
    isFocused: false,
    /**
     * a light-editor only includes the formatting options:
     * bold, italic, underline, ordered and unordered lists
     *
     * @property isLight
     * @type {Boolean}
     * @default false
     */
    isLight: false,
    /**
     * whether the editor was touched
     *
     * @property isTouched
     * @type {Boolean}
     * @default false
     */
    isTouched: false,
    /**
     * the label of that is displayed above the editor
     *
     * @property label
     * @type {String}
     * @default null
     */
    label: null,
    /**
     * the maximum count of characters
     *
     * @property maxlength
     * @type {Number}
     * @default null
     */
    maxlength: null,
    /**
     * the recommended count of characters
     *
     * @property recommendedLength
     * @type {Number}
     * @default null
     */
    recommendedLength: null,
    /**
     * the warning shown when the recommended length is exceeded
     *
     * @property recommendedWarning
     * @type {String}
     * @default null
     */
    recommendedWarning: null,
    /**
     * the state property indicates the values current state.
     *
     * State is only handled for statefull inputs.
     *
     * Possible values are: success, error and warning
     *
     * @property state
     * @type {String}
     * @default undefined
     */
    state: undefined,
    /**
     * the theme of the editor
     *
     * @property theme
     * @type {String}
     * @default 'white'
     */
    theme: 'white',
    /**
     * the text inside the editor
     *
     * @property value
     * @type {String}
     * @default null
     */
    value: null,
    /**
     * the dom element of the editable content
     *
     * @property _contentElement
     * @type {Object}
     * @default null
     * @private
     */
    _contentElement: null,
    /**
     * array containing the handler functions for the event listeners of the actions
     *
     * @property _actionHandlers
     * @type {Function[]}
     * @default undefined
     * @private
     */
    _actionHandlers: undefined,
    /**
     * the id of the sentinel
     *
     * @property _editorSentinelId
     * @type {String}
     * @default null
     * @private
     */
    _editorSentinelId: null,
    /**
     * whether firefox is used
     *
     * @property _isFirefox
     * @type {Boolean}
     * @default false
     * @private
     */
    _isFirefox: false,
    /**
     * whether the editor should be editable
     *
     * @property _isEditable
     * @type {Boolean}
     * @private
     */
    _isEditable: (0, _computed.not)('isDisabled'),
    /**
     * whether there is an error
     *
     * @property _isError
     * @type {Boolean}
     * @private
     */
    _isError: (0, _computed.and)('errorMessage', 'isTouched'),
    inputElementId: (0, _object.computed)('elementId', {
      get() {
        return `editor-${this.elementId}`;
      }
    }),
    /**
     * the number of inserted characters
     *
     * @property _charCount
     * @type {String}
     * @private
     */
    _charCount: (0, _object.computed)('_contentElement', 'value', function () {
      const content = this._contentElement;
      const plainText = content && content.textContent || '';
      return plainText.length;
    }),
    /**
     * the displayed message
     *
     * @property _message
     * @type {String}
     * @private
     */
    message: (0, _object.computed)('errorMessage', 'infoMessage', 'isDestroying', 'isTouched', {
      get() {
        const {
          errorMessage,
          infoMessage,
          isTouched
        } = this;
        if (errorMessage && isTouched) {
          // eslint-disable-next-line ember/no-side-effects
          (0, _domUtil.nextTick)().then(() => this.isDestroying ? null : (0, _object.set)(this, 'state', 'error'));
          return typeof errorMessage === 'string' ? errorMessage : errorMessage.firstObject;
        } else {
          // eslint-disable-next-line ember/no-side-effects
          (0, _domUtil.nextTick)().then(() => this.isDestroying ? null : (0, _object.set)(this, 'state', null));
        }
        return infoMessage;
      }
    }),
    init() {
      this._super(...arguments);
      this._editorSentinelId = this.elementId && `${this.elementId}-editor-sentinel`;
      this._isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      // find and set all necessary dom elements
      const element = this.element;
      const content = element.querySelector(CONTENT_CLASS);
      const actionElements = element.querySelectorAll(ACTION_CLASS);
      (0, _object.set)(this, '_contentElement', content);

      // initialize event listeners
      if (document && content && actionElements) {
        this._inputListener = (0, _runloop.bind)(this, this.onInput);
        this._keydownListener = (0, _runloop.bind)(this, this.onKeyDown);
        this._handleBlur = (0, _runloop.bind)(this, this._handleBlur);
        this._handleFocus = (0, _runloop.bind)(this, this._handleFocus);
        this._handlePaste = (0, _runloop.bind)(this, this._handlePaste);
        content.addEventListener('input', this._inputListener, false);
        content.addEventListener('keydown', this._keydownListener, false);
        content.addEventListener('blur', this._handleBlur, false);
        content.addEventListener('focus', this._handleFocus, false);
        content.addEventListener('paste', this._handlePaste, false);

        // set inital content value
        const value = (0, _editorUtil.cleanValue)(this.value);
        content.innerHTML = value;

        // set default paragraph separator to <p>
        (0, _editorUtil.exec)('defaultParagraphSeparator', 'p');
        const actionHandlers = [];
        // add event listeners to every action button and set the action class according to
        // the state of the command they represent
        actionElements.forEach(action => {
          const command = action.getAttribute('command');
          const value = action.getAttribute('value');
          if (command) {
            const handler = () => {
              const state = (0, _editorUtil.queryState)(command) || !this._isFirefox && value === (0, _editorUtil.queryValue)(command);
              action.classList[state ? 'add' : 'remove'](ACTIVE_ACTION_CLASS_NAME);
            };
            actionHandlers.push(handler);
            content.addEventListener('keyup', handler, false);
            content.addEventListener('mouseup', handler, false);
          }
        });
        (0, _object.set)(this, '_actionHandlers', actionHandlers);
        (0, _domUtil.nextTick)().then(() => !this.isDestroying && (0, _object.set)(this, 'errorMessage', undefined));
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      const content = this._contentElement;
      const inputValue = this.value || '';
      if (!this.isFocused && content.innerHTML !== inputValue) {
        content.innerHTML = (0, _editorUtil.cleanValue)(inputValue);
        (0, _domUtil.nextTick)().then(() => !this.isDestroying ? this._handleChange() : null);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      const content = this._contentElement;
      const actionHandlers = this._actionHandlers;
      if (content && actionHandlers) {
        content.removeEventListener('input', this._inputListener, false);
        content.removeEventListener('keyup', this._keyupListener, false);
        content.removeEventListener('keydown', this._keydownListener, false);
        content.removeEventListener('blur', this._handleBlur, false);
        content.removeEventListener('focus', this._handleFocus, false);
        content.removeEventListener('paste', this._handlePaste, false);
        actionHandlers.forEach(handler => {
          content.removeEventListener('keyup', handler, false);
          content.removeEventListener('mouseup', handler, false);
        });
      }
    },
    /**
     * focus on content when editor is clicked
     *
     * @event click
     */
    click() {
      if (!this.isDisabled) {
        const content = this._contentElement;
        if (content) {
          content.focus();
        }
      }
    },
    /**
     * wraps the first line of the content inside a <p> and removes unnecessary tags
     *
     * @function onInput
     * @param {Event} e
     */
    onInput(e) {
      const firstChild = e && e.target && e.target.firstChild;
      const content = this._contentElement;

      // nodeType 3 is text, ignore empty inputTypes (paste)
      if (firstChild && firstChild.nodeType === 3 && e.inputType !== '') {
        (0, _editorUtil.exec)('formatBlock', `<p>`);

        // remove tags if content is empty
      } else if (content && content.innerHTML !== (0, _editorUtil.cleanValue)(content.innerHTML)) {
        content.innerHTML = '';
        (0, _object.set)(this, 'value', '');
      } else {
        if (content.innerHTML.startsWith('<hr>')) {
          content.innerHTML = '<p><br/></p>' + content.innerHTML;
          (0, _object.set)(this, 'value', content.innerHTML);
        }
        if (content.innerHTML.endsWith('<hr>')) {
          content.innerHTML = content.innerHTML + '<p><br/></p>';
          (0, _object.set)(this, 'value', content.innerHTML);
        }
      }
      this._handleChange();
    },
    /**
     * prevent browser from adding a new paragraph on `SHIFT + ENTER` key-press
     *
     * @function onKeyDown
     * @param {Event} event
     * @event onkeydown
     */
    onKeyDown(event) {
      if (event && event.key === 'Enter' && event.shiftKey) {
        (0, _editorUtil.exec)('InsertLineBreak');
        event.preventDefault();
      } else if (event && event.key === 'Enter') {
        const range = window.getSelection().getRangeAt(0);
        const element = range.commonAncestorContainer;
        if (element.tagName === 'BLOCKQUOTE' || element.parentElement.tagName === 'BLOCKQUOTE' || element.parentElement.parentElement.tagName === 'BLOCKQUOTE') {
          event.preventDefault();
          const blockQuoteElement = element.tagName === 'BLOCKQUOTE' ? element : element.parentElement.tagName === 'BLOCKQUOTE' ? element.parentElement : element.parentElement.parentElement;
          const selectedTag = blockQuoteElement.nextSibling || document.createElement('p');
          if (!blockQuoteElement.nextSibling) {
            selectedTag.innerHTML = '<br/>';
            blockQuoteElement.parentNode.appendChild(selectedTag);
            this._handleChange();
          }
          const selection = window.getSelection();
          const range = document.createRange();
          range.setStart(selectedTag, 0);
          range.setEnd(selectedTag, 0);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }

      // stop propagation in order to avoid triggering actions outside of the editor
      event.stopPropagation();
    },
    /**
     *  set the value to the inner HTML of the content on every input
     *
     * @function _handleChange
     * @private
     */
    _handleChange() {
      const content = this._contentElement;
      if (content && typeof content.innerHTML === 'string') {
        let value = content.innerHTML;

        // ensure that value starts with a <h#> or <p> tag
        if (value && value.indexOf('<h1>') !== 0 && value.indexOf('<h2>') !== 0 && value.indexOf('<h3>') !== 0 && value.indexOf('<p>') !== 0) {
          if (value.indexOf('<p>') !== -1) {
            value = `<p>${value.substring(0, value.indexOf('<p>'))}</p>${value.substring(value.indexOf('<p>'))}`;
          } else {
            value = `<p>${value}</p>`;
          }
        }

        // remove span tags and inline styles
        value = value.replace(/<\/?span[^>]*>/g, '').replace(/style="[^"]*"/g, '');
        (0, _object.set)(this, 'value', value);
        this.onChange(value);
      }
    },
    /**
     * set the isTouched property to true on blur
     * set the isFocused property to false on blur
     *
     * @function _handleBlur
     * @private
     */
    _handleBlur() {
      this.onBlur();
      (0, _object.set)(this, 'isFocused', false);
      (0, _object.set)(this, 'isTouched', true);
    },
    /**
     * set the isFocused property to true on focus
     *
     * @function _handleFocus
     * @private
     */
    _handleFocus() {
      this.onFocus();
      (0, _object.set)(this, 'isFocused', true);
    },
    /**
     * inserts the pasted text as formatted and cleaned html
     *
     * @function _handlePaste
     * @param {Event} e the paste event
     * @private
     */
    _handlePaste(e) {
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      if (document && clipboardData) {
        const html = clipboardData.getData('text/html') || clipboardData.getData('Text');
        const text = (0, _editorUtil.cleanHtml)(html);
        (0, _editorUtil.exec)('insertHTML', !this.value && !text.startsWith('<p') ? `<p>${text}</p>` : text);
      }
    },
    onHelp() {},
    onBlur() {},
    onFocus() {},
    onChange() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * executes the given command to format the text
       * available commands: https://www.w3schools.com/jsref/met_document_execcommand.asp
       *
       * @function execCommand
       * @param {String} command
       */
      execCommand(command) {
        let value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        if (document && !this.isDisabled) {
          // allow only one type of formatBlock command
          if (command === 'formatBlock') {
            // if the active command is applied again, format back to default
            if (value === (0, _editorUtil.getActiveBlockTag)()) {
              return (0, _editorUtil.exec)(command, '<p>');
            } else if (['blockquote', 'pre'].indexOf(value) !== -1) {
              const range = window.getSelection().getRangeAt(0);
              const element = range.commonAncestorContainer;
              let blockQuoteElement = null;
              let checkElement = element;

              // look for parent blockquote or pre
              while (checkElement.tagName !== 'DIV') {
                if (checkElement.tagName === value.toUpperCase()) {
                  blockQuoteElement = checkElement;
                  break;
                }
                checkElement = checkElement.parentElement;
              }

              // if surrounding tag found, replace with p-tag
              if (blockQuoteElement) {
                let blockQuoteChild = blockQuoteElement.childNodes[0];
                if (blockQuoteElement.childNodes.length > 1) {
                  const pTag = document.createElement('p');
                  [...blockQuoteElement.childNodes].forEach(node => {
                    pTag.appendChild(node);
                    blockQuoteChild = pTag;
                  });
                }
                const blockQuoteParent = blockQuoteElement.parentElement;
                blockQuoteParent.replaceChild(blockQuoteChild, blockQuoteElement);
                this._handleChange();
                const selection = window.getSelection();
                const range = document.createRange();
                range.setStart(blockQuoteChild, 0);
                range.setEnd(blockQuoteChild, 0);
                selection.removeAllRanges();
                selection.addRange(range);
                return;
              }
            }
          }
          (0, _editorUtil.exec)(command, value);
          this._contentElement.focus();
          this._handleChange();

          // check the state of the executed command and set the class of the corresponding action button
          const state = (0, _editorUtil.queryState)(command) || value === (0, _editorUtil.getActiveBlockTag)();
          const actionSelector = (0, _editorUtil.getActionSelector)(command, value);
          const actionElement = this.element.querySelector(actionSelector);
          if (actionElement) {
            actionElement.classList[state ? 'add' : 'remove'](ACTIVE_ACTION_CLASS_NAME);
          }
        }
      },
      onHelp() {
        this.onHelp();
      }
    }
  });
});