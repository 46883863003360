define("@additive-apps/ui/components/ui-card/header/content/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    image=(component 'ui-card/header/content/image')
    iframe=(component 'ui-card/header/content/iframe')
  )}}
  */
  {
    "id": "uMxB6fKh",
    "block": "[[[18,1,[[28,[37,1],null,[[\"image\",\"iframe\"],[[50,\"ui-card/header/content/image\",0,null,null],[50,\"ui-card/header/content/iframe\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/content/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});