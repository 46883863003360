define("@additive-apps/media/utils/medium", ["exports", "@additive-apps/media/constants", "@ember/utils"], function (_exports, _constants, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mediumUrlOptions = _exports.mediumToResource = _exports.VIDEO_URLS_BLUEPRINT = void 0;
  const VIDEO_URLS_BLUEPRINT = _exports.VIDEO_URLS_BLUEPRINT = {
    youtube: {
      thumbnailUrl: 'https://i.ytimg.com/vi/{{remote_id}}/maxresdefault.jpg',
      embedUrl: 'https://www.youtube.com/embed/{{remote_id}}'
    }
  };

  /**
   * Util to add media options like size, transformations etc.
   *
   * ```js
   * mediumUrlOptions('https://www.media.api/my-image', { size: '360x200', mo: [50, 50, 50], co: [10, 0.5] })
   * ```
   *
   * @param {string} url      Mediums base url
   * @param {Object} options  Url options
   * @param {string} options.size  The desired size
   * @param {string} options.mo    The desired transformation `mo` (modulate)
   * @param {string} options.co    The desired transformation `co` (contrast)
   * @param {Array}  options.poi   The desired transformation `poi` (point of interests, `[x,y]` range of 0-1)
   *
   * @function mediumUrlOptions
   */
  const mediumUrlOptions = _exports.mediumUrlOptions = function mediumUrlOptions(url) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (!options || !url) {
      return url;
    }

    /* Create unmutable copy */
    const _options = Object.assign({}, options);
    const [baseUrl, searchParams] = url.split('?');
    const urlSearchParams = new URLSearchParams(searchParams);

    /*
     * When we have poi and size we use the dedicated `szp` query param for this.
     * `szp` is a cominbation of imbo smart-size and our resource-poi.
     * Where the first 2 values are the desired thumbnail size `width`+`height`
     * and the second 2 values are the `x`+`y` in a range of 0-1 (like percentage)
     */
    if (_options.poi && _options.size) {
      urlSearchParams.set('szp', `${_options.size.replace('x', ',')},${_options.poi.join(',')}`);
      delete _options.poi;
      delete _options.size;
    } else if (_options.poi && !_options.size) {
      /* Remove poi as it does not work without a size */
      delete _options.poi;
    }

    /* Iterate through all  */
    Object.keys(_options).forEach(optionKey => {
      /* Ingore option if not supported */
      if (_constants.SUPPORTED_MEDIUM_URL_OPTIONS.indexOf(optionKey) === -1) {
        // eslint-disable-next-line no-console
        console.warn(`[mm/utils/medium::mediumUrlOptions] option '${optionKey}' not supported, ignored`);
        return;
      }

      /* Use alternative query-param-key if exists */
      const queryParamKey = _constants.QUERY_PARAMS_KEYS_FOR_OPTIONS[optionKey] || optionKey;
      let value = _options[optionKey];
      /* When its an array join it by `,` seperator --> [10, 20] -> 10,20 */
      if (Array.isArray(value)) {
        value = value.join(',');
      }
      if ((0, _utils.isPresent)(value)) {
        urlSearchParams.set(queryParamKey, value);
      } else {
        urlSearchParams.delete(queryParamKey);
      }
    });
    const searchParamsString = urlSearchParams.toString();
    return baseUrl + (searchParamsString ? '?' + searchParamsString : '');
  };

  /**
   * Util which returns a medium as resource.
   * Which should be used to save inside a Object (example: voucher.media)
   *
   * ```js
   * mediumToResource(store.peekRecord('medium', 'myId'))
   * ```
   *
   * @param {object} medium The medium model
   *
   * @function mediumToResource
   */
  const mediumToResource = medium => {
    return {
      mediumId: medium.id,
      url: medium.url,
      name: medium.name,
      type: medium.type,
      provider: medium.provider,
      remoteId: medium.remoteId,
      // Video related
      thumbnailUrl: medium.thumbnailUrl,
      embedUrl: medium.embedUrl
    };
  };
  _exports.mediumToResource = mediumToResource;
});