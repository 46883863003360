define("@additive-apps/ui/components/ui-view-modes/content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/template", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _template, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div style={{this._styles}} ...attributes data-test-ui-view-modes-content>
    {{yield}}
  </div>
  */
  {
    "id": "jbok23wE",
    "block": "[[[11,0],[16,5,[30,0,[\"_styles\"]]],[17,1],[24,\"data-test-ui-view-modes-content\",\"\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-view-modes/content.hbs",
    "isStrictMode": false
  });
  /**
   * Component to render a container where its size depends on the passed arguments.
   *
   * @class ui-view-modes/content
   */
  let UiViewModesContentComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _class = class UiViewModesContentComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The container size
       *
       * @argument size
       * @type {Object}
       */
      _initializerDefineProperty(this, "size", _descriptor, this);
    }
    /**
     * The container size `style` value
     *
     * @property _styles
     * @type {String}
     */
    get _styles() {
      const styles = Object.keys(this.size).reduce((accumulator, key) => {
        const value = this.size[key];
        if (typeof value != 'string') {
          return accumulator;
        }
        return accumulator += `${key}:${value};`;
      }, '');
      return (0, _template.htmlSafe)(styles);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "size", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {
        width: '100%',
        height: '100%'
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiViewModesContentComponent);
});