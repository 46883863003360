define("@additive-apps/ui/components/ui-card/footer/menu/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@content.menuItem @onClick={{@onClick}} @isDisabled={{@isDisabled}} ...attributes>
    <span class="font-sm {{if @isDestructive "red"}}">
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </span>
  </@content.menuItem>
  */
  {
    "id": "LjUWvpCT",
    "block": "[[[8,[30,1,[\"menuItem\"]],[[17,2]],[[\"@onClick\",\"@isDisabled\"],[[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[15,0,[29,[\"font-sm \",[52,[30,5],\"red\"]]]],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"      \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,6]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@content\",\"&attrs\",\"@onClick\",\"@isDisabled\",\"@isDestructive\",\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/menu/item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});