define("@additive-apps/ui/components/ui-card/header/content/iframe", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="content iframe {{if this.isBlank 'blank'}} - flex-auto flex items-center justify-center ohidden"
    {{did-insert (ui-optional-action @onLoad)}}
  >
    {{#if this.isBlank}}
      {{svg-jar "img-placeholder" class="placeholder"}}
    {{else}}
      <div class="scaled - w-100 h-100" style={{this.scaledStyle}}>
        <iframe
          title="iframe"
          frameborder="0"
          scrolling="no"
          src={{@src}}
          srcdoc={{@content}}
        ></iframe>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "e6Z7jx2C",
    "block": "[[[11,0],[16,0,[29,[\"content iframe \",[52,[30,0,[\"isBlank\"]],\"blank\"],\" - flex-auto flex items-center justify-center ohidden\"]]],[4,[38,1],[[28,[37,2],[[30,1]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isBlank\"]],[[[1,\"    \"],[1,[28,[35,3],[\"img-placeholder\"],[[\"class\"],[\"placeholder\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"scaled - w-100 h-100\"],[15,5,[30,0,[\"scaledStyle\"]]],[12],[1,\"\\n      \"],[10,\"iframe\"],[14,\"title\",\"iframe\"],[14,\"frameborder\",\"0\"],[14,\"scrolling\",\"no\"],[15,\"src\",[30,2]],[15,\"srcdoc\",[30,3]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@onLoad\",\"@src\",\"@content\"],false,[\"if\",\"did-insert\",\"ui-optional-action\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/content/iframe.hbs",
    "isStrictMode": false
  });
  class UiCardHeaderContentIframe extends _component2.default {
    get isBlank() {
      let {
        args: {
          src,
          content
        }
      } = this;
      return !src && !content;
    }
    get scaledStyle() {
      let {
        args: {
          scale
        }
      } = this;
      return (0, _template.htmlSafe)(`--scale: ${scale || 1.0}`);
    }
  }
  _exports.default = UiCardHeaderContentIframe;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCardHeaderContentIframe);
});