define("@additive-apps/ui/components/ui-loading-page", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-loading-page flex flex-row flex-grow-1 items-center justify-center w-100 h-100 pa3 fixed bg-primary absolute--fill"
  >
    <svg fill="white" width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
      <rect class="vertical" x="32" y="12" width="16" height="56" rx="1.5"></rect>
      <rect class="horizontal" x="12" y="32" width="56" height="16" rx="1.5"></rect>
    </svg>
  </div>
  */
  {
    "id": "RyFCrPjL",
    "block": "[[[10,0],[14,0,\"ui-loading-page flex flex-row flex-grow-1 items-center justify-center w-100 h-100 pa3 fixed bg-primary absolute--fill\"],[12],[1,\"\\n  \"],[10,\"svg\"],[14,\"fill\",\"white\"],[14,\"width\",\"80\"],[14,\"height\",\"80\"],[14,\"viewBox\",\"0 0 80 80\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12],[1,\"\\n    \"],[10,\"rect\"],[14,0,\"vertical\"],[14,\"x\",\"32\"],[14,\"y\",\"12\"],[14,\"width\",\"16\"],[14,\"height\",\"56\"],[14,\"rx\",\"1.5\"],[12],[13],[1,\"\\n    \"],[10,\"rect\"],[14,0,\"horizontal\"],[14,\"x\",\"12\"],[14,\"y\",\"32\"],[14,\"width\",\"56\"],[14,\"height\",\"16\"],[14,\"rx\",\"1.5\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "@additive-apps/ui/components/ui-loading-page.hbs",
    "isStrictMode": false
  });
  /**
   * ```hbs
   * <UiLoadingPage />
   * ```
   *
   * @class UiLoadingPageComponent
   */
  const UiLoadingPageComponent = (0, _templateOnly.default)();
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLoadingPageComponent);
});