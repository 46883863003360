define("@additive-apps/media/templates/components/mm-picture-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "S28Cq+B2",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_srcSets\"]]],null]],null],null,[[[1,\"  \"],[10,\"source\"],[15,\"srcset\",[30,1,[\"url\"]]],[15,\"media\",[30,1,[\"media\"]]],[12],[13],[1,\"\\n\"]],[1]],null],[10,\"img\"],[15,\"src\",[30,0,[\"_imageTagUrl\"]]],[15,\"alt\",[30,0,[\"alt\"]]],[14,0,\"db image\"],[12],[13]],[\"srcSet\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-picture-tag.hbs",
    "isStrictMode": false
  });
});