define("@additive-apps/ui/components/ui-view-modes", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_MODES = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      content=(component
        "ui-view-modes/content"
        size=(hash width=this._activeMode.width height=this._activeMode.height)
      )
      toggle=(component
        "ui-view-modes/toggle"
        modes=this.modes
        activeModeKey=this._activeMode.key
        onChange=this._changeMode
      )
      activeModeKey=this._activeMode.key
    )
  }}
  */
  {
    "id": "ce6sBuo1",
    "block": "[[[18,1,[[28,[37,1],null,[[\"content\",\"toggle\",\"activeModeKey\"],[[50,\"ui-view-modes/content\",0,null,[[\"size\"],[[28,[37,1],null,[[\"width\",\"height\"],[[30,0,[\"_activeMode\",\"width\"]],[30,0,[\"_activeMode\",\"height\"]]]]]]]],[50,\"ui-view-modes/toggle\",0,null,[[\"modes\",\"activeModeKey\",\"onChange\"],[[30,0,[\"modes\"]],[30,0,[\"_activeMode\",\"key\"]],[30,0,[\"_changeMode\"]]]]],[30,0,[\"_activeMode\",\"key\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-view-modes.hbs",
    "isStrictMode": false
  });
  const DEFAULT_MODES = [{
    key: 'sm',
    icon: 'smartphone',
    width: '375px',
    height: '667px'
  }, {
    key: 'md',
    icon: 'tablet',
    width: '768px',
    height: '1024px'
  }, {
    key: 'fluid',
    icon: 'desktop',
    width: '100%',
    height: '100%'
  }];

  /**
   * Component to render a set of toggleable view-modes.
   *
   * @class ui-view-modes
   */
  let UiViewModesComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiViewModesComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      /**
       * array of mode objects:
       ```js
          {
            key: 'sm',
            icon: 'smartphone',
            width: '375px',
            height: '667px'
          }
       ```
       *
       * @argument modes
       * @type {Object[]}
       */
      _initializerDefineProperty(this, "modes", _descriptor, this);
      /**
       * The key of the mode which should be initialy active
       *
       * @argument string
       * @type {Array}
       * @default 'fluid'
       */
      _initializerDefineProperty(this, "initialModeKey", _descriptor2, this);
      /**
       * The object of the active mode
       *
       * @property _activeMode
       * @type {Object}
       * @private
       */
      _initializerDefineProperty(this, "_activeMode", _descriptor3, this);
      const modes = args.modes || this.modes;
      const initialModeKey = args.initialModeKey || this.initialModeKey;
      const activeMode = modes.find(mode => mode.key === initialModeKey);
      if (activeMode) {
        this._activeMode = activeMode;
      } else if (!args.initialModeKey && args.modes.length >= 1) {
        /* Set first mode as default, when no matching initial value is available */
        this._activeMode = modes[0];
      }
    }

    /**
     * Updates the active mode by mode key
     *
     * @function _changeMode
     * @type {Function}
     * @private
     */
    _changeMode(modeKey) {
      const activeMode = this.modes.find(mode => mode.key === modeKey);
      if (activeMode) {
        this._activeMode = activeMode;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return DEFAULT_MODES;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initialModeKey", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'fluid';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_activeMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_changeMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_changeMode"), _class.prototype), _class);
  const IMMUTABLE_DEFAULT_MODES = _exports.DEFAULT_MODES = JSON.parse(JSON.stringify(DEFAULT_MODES));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiViewModesComponent);
});