define("@additive-apps/ui/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.URL_SEARCH_PARAM_KEY = _exports.URL_PER_PAGE_PARAM_KEY = _exports.URL_ARRAY_DELIMITER = _exports.PER_PAGE_DEFAULT = _exports.DYNAMIC_FILTER_DEFAULT_GROUP_KEY = _exports.DEFAULT_MENU_POSITION = _exports.APP_ID_VOUCHER = _exports.APP_ID_NEWSLETTER = _exports.APP_ID_MULTIMEDIA = _exports.APP_ID_MARKETING_INSIGHTS = _exports.APP_ID_MARKETING_CLOUD = _exports.APP_ID_MAILER = _exports.APP_ID_JOURNAL = _exports.APP_ID_GUEST_INTRANET = _exports.APP_ID_ENQUIRY = _exports.APP_ID_CRM = _exports.APP_ID_CONTENT = _exports.APP_ID_CMS = _exports.APP_ID_ACCOUNT = _exports.APP_IDS_WITH_URL = _exports.APP_IDS = void 0;
  const URL_ARRAY_DELIMITER = _exports.URL_ARRAY_DELIMITER = ',';
  const URL_SEARCH_PARAM_KEY = _exports.URL_SEARCH_PARAM_KEY = 'q';
  const URL_PER_PAGE_PARAM_KEY = _exports.URL_PER_PAGE_PARAM_KEY = 'per_page';
  const PER_PAGE_DEFAULT = _exports.PER_PAGE_DEFAULT = 40;
  const DYNAMIC_FILTER_DEFAULT_GROUP_KEY = _exports.DYNAMIC_FILTER_DEFAULT_GROUP_KEY = 'default';

  /* App-ids */
  const APP_ID_ACCOUNT = _exports.APP_ID_ACCOUNT = 1;
  const APP_ID_VOUCHER = _exports.APP_ID_VOUCHER = 2;
  const APP_ID_CONTENT = _exports.APP_ID_CONTENT = 4;
  const APP_ID_MULTIMEDIA = _exports.APP_ID_MULTIMEDIA = 5;
  const APP_ID_MAILER = _exports.APP_ID_MAILER = 6;
  const APP_ID_NEWSLETTER = _exports.APP_ID_NEWSLETTER = 7;
  const APP_ID_GUEST_INTRANET = _exports.APP_ID_GUEST_INTRANET = 8;
  const APP_ID_JOURNAL = _exports.APP_ID_JOURNAL = 9;
  const APP_ID_ENQUIRY = _exports.APP_ID_ENQUIRY = 11;
  const APP_ID_CRM = _exports.APP_ID_CRM = 12;
  const APP_ID_MARKETING_CLOUD = _exports.APP_ID_MARKETING_CLOUD = 50;
  const APP_ID_MARKETING_INSIGHTS = _exports.APP_ID_MARKETING_INSIGHTS = 51;
  const APP_ID_CMS = _exports.APP_ID_CMS = 100;
  const APP_IDS = _exports.APP_IDS = [APP_ID_ACCOUNT, APP_ID_VOUCHER, APP_ID_CONTENT, APP_ID_MULTIMEDIA, APP_ID_MAILER, APP_ID_NEWSLETTER, APP_ID_GUEST_INTRANET, APP_ID_JOURNAL, APP_ID_ENQUIRY, APP_ID_CRM, APP_ID_MARKETING_CLOUD, APP_ID_MARKETING_INSIGHTS, APP_ID_CMS];
  const APP_IDS_WITH_URL = _exports.APP_IDS_WITH_URL = [APP_ID_ACCOUNT, APP_ID_VOUCHER, APP_ID_CONTENT, APP_ID_NEWSLETTER, APP_ID_ENQUIRY, APP_ID_CRM, APP_ID_MARKETING_CLOUD, APP_ID_MARKETING_INSIGHTS, APP_ID_CMS];

  /* Default ui-menu content position, will be used by ember-basic-dropdown */
  const DEFAULT_MENU_POSITION = _exports.DEFAULT_MENU_POSITION = {
    horizontal: 'auto-right',
    vertical: 'auto'
  };
});