define("@additive-apps/ui/components/ui-datetime-picker", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object", "date-fns", "@additive-apps/ui/utils/date-util"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object, _dateFns, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-datetime-picker flex flex-column w-100 {{@classNames}} {{@class}}"
    ...attributes
    {{did-update this.onDidUpdate}}
  >
    <UiDatepicker
      @label={{if this.dateLabel this.dateLabel (t "uiDatetimePicker.date")}}
      @minDate={{this.minDate}}
      @maxDate={{this.maxDate}}
      @onSelect={{this.onDateChange}}
      @initialDate={{this.initialDate}}
      @errorMessage={{this.dateErrorMessage}}
      @isTouched={{this.isTouched}}
      @displayFormat={{this.displayFormat}}
      @theme={{this.theme}}
      @placeholder={{this.datePlaceholder}}
      @resetDate={{this.resetDate}}
      @isClearable={{this.isClearable}}
      @onClear={{this.this._onClear}}
      @isReadOnly={{this.isReadOnly}}
      @hasFilters={{this.hasFilters}}
      @filters={{this.filters}}
      @defaultFilter={{this.defaultFilter}}
    />
  
    <UiTimepicker
      @label={{if this.timeLabel this.timeLabel (t "uiDatetimePicker.time")}}
      @onChange={{this.onTimeChange}}
      @initialValue={{this._initialTime}}
      @minTime={{this.minTime}}
      @maxTime={{this.maxTime}}
      @isClearable={{this.isClearable}}
      @errorMessage={{this.timeErrorMessage}}
      @theme={{this.theme}}
      @placeholder={{this.timePlaceholder}}
      @onValidStateChange={{this.onValidStateChange}}
      @isReadOnly={{this.isReadOnly}}
    />
  </div>
  */
  {
    "id": "6IqFXdal",
    "block": "[[[11,0],[16,0,[29,[\"ui-datetime-picker flex flex-column w-100 \",[30,1],\" \",[30,2]]]],[17,3],[4,[38,0],[[30,0,[\"onDidUpdate\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@label\",\"@minDate\",\"@maxDate\",\"@onSelect\",\"@initialDate\",\"@errorMessage\",\"@isTouched\",\"@displayFormat\",\"@theme\",\"@placeholder\",\"@resetDate\",\"@isClearable\",\"@onClear\",\"@isReadOnly\",\"@hasFilters\",\"@filters\",\"@defaultFilter\"],[[52,[30,0,[\"dateLabel\"]],[30,0,[\"dateLabel\"]],[28,[37,3],[\"uiDatetimePicker.date\"],null]],[30,0,[\"minDate\"]],[30,0,[\"maxDate\"]],[30,0,[\"onDateChange\"]],[30,0,[\"initialDate\"]],[30,0,[\"dateErrorMessage\"]],[30,0,[\"isTouched\"]],[30,0,[\"displayFormat\"]],[30,0,[\"theme\"]],[30,0,[\"datePlaceholder\"]],[30,0,[\"resetDate\"]],[30,0,[\"isClearable\"]],[30,0,[\"_onClear\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"hasFilters\"]],[30,0,[\"filters\"]],[30,0,[\"defaultFilter\"]]]],null],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@label\",\"@onChange\",\"@initialValue\",\"@minTime\",\"@maxTime\",\"@isClearable\",\"@errorMessage\",\"@theme\",\"@placeholder\",\"@onValidStateChange\",\"@isReadOnly\"],[[52,[30,0,[\"timeLabel\"]],[30,0,[\"timeLabel\"]],[28,[37,3],[\"uiDatetimePicker.time\"],null]],[30,0,[\"onTimeChange\"]],[30,0,[\"_initialTime\"]],[30,0,[\"minTime\"]],[30,0,[\"maxTime\"]],[30,0,[\"isClearable\"]],[30,0,[\"timeErrorMessage\"]],[30,0,[\"theme\"]],[30,0,[\"timePlaceholder\"]],[30,0,[\"onValidStateChange\"]],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"],[13]],[\"@classNames\",\"@class\",\"&attrs\"],false,[\"did-update\",\"ui-datepicker\",\"if\",\"t\",\"ui-timepicker\"]]",
    "moduleName": "@additive-apps/ui/components/ui-datetime-picker.hbs",
    "isStrictMode": false
  });
  /**
   *  Component to pick a date and a time
   *
   * ```hbs
   * {{ui-datetime-picker
   *  dateLabel
   *  timeLabel
   *  initialDate
   *  minDate
   *  maxDate
   *  minTime
   *  maxTime
   *  onChange
   * }}
   * ```
   * @class ui-datetime-picker
   */
  let UiDatetimePickerComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec6 = (0, _emberArgTypes.arg)(_propTypes.array), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string), _dec8 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec9 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec10 = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec11 = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec12 = (0, _emberArgTypes.arg)(_propTypes.string), _dec13 = (0, _emberArgTypes.arg)(_propTypes.string), _dec14 = (0, _emberArgTypes.arg)(_propTypes.string), _dec15 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.date), _dec16 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec17 = (0, _emberArgTypes.arg)(_propTypes.string), _dec18 = (0, _emberArgTypes.arg)(_propTypes.string), _dec19 = (0, _emberArgTypes.arg)(_propTypes.string), _dec20 = (0, _emberArgTypes.arg)(_propTypes.func), _dec21 = (0, _emberArgTypes.arg)(_propTypes.func), _dec22 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiDatetimePickerComponent extends _component2.default {
    _onClear() {
      this.onClear();
      this._initialDate = this.initialDate;
      this._initialTime = null;
    }

    /**
     * callback triggered when valid state changes
     *
     * @function onValidStateChange
     * @param {Boolean} isValid Whether changeset is valid
     */

    constructor() {
      super(...arguments);
      /**
       * the label for the date input
       *
       * @argument dateLabel
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "dateLabel", _descriptor, this);
      /**
       * the error message of the date input
       *
       * @argument dateErrorMessage
       * @type {String | Array}
       * @default null
       */
      _initializerDefineProperty(this, "dateErrorMessage", _descriptor2, this);
      /**
       * the placeholder of the date input
       *
       * @argument datePlaceholder
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "datePlaceholder", _descriptor3, this);
      /**
       * the initial value of the date time picker
       *
       * @argument initialDate
       * @type {Date | String}
       * @default null
       */
      _initializerDefineProperty(this, "initialDate", _descriptor4, this);
      /**
       * whether the datepicker has filters
       *
       * @argument hasFilters
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasFilters", _descriptor5, this);
      /**
       * the filters applied to the datepicker
       *
       * @argument filters
       * @type {Array}
       * @default ['this_week', 'last_week', 'this_month', 'last_month', 'last_thirty_days', 'custom_date']
       */
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      /**
       * the default filter
       *
       * @argument defaultFilter
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "defaultFilter", _descriptor7, this);
      /**
       * whether the component is readonly
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor8, this);
      /**
       * whether one of the inputs has been touched
       *
       * @argument isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTouched", _descriptor9, this);
      /**
       * the minimal selectable date
       *
       * @argument minDate
       * @type {Date | String}
       * @default null
       */
      _initializerDefineProperty(this, "minDate", _descriptor10, this);
      /**
       * the maximal selectable date
       *
       * @argument maxDate
       * @type {Date | String}
       * @default null
       */
      _initializerDefineProperty(this, "maxDate", _descriptor11, this);
      /**
       * the minimal selectable time
       *
       * @argument minTime
       * @type {String}
       * @default '00:00'
       */
      _initializerDefineProperty(this, "minTime", _descriptor12, this);
      /**
       * the maximal selectable time
       *
       * @argument maxTime
       * @type {String}
       * @default '23:59'
       */
      _initializerDefineProperty(this, "maxTime", _descriptor13, this);
      /**
       * the theme of the inputs
       *
       * @argument theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor14, this);
      /**
       * the reset date of the date input
       *
       * @argument resetDate
       * @type {String|Date}
       * @default null
       */
      _initializerDefineProperty(this, "resetDate", _descriptor15, this);
      /**
       * whether the date picker is clearable
       *
       * @argument isClearable
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isClearable", _descriptor16, this);
      /**
       * the label for the time input
       *
       * @argument timeLabel
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "timeLabel", _descriptor17, this);
      /**
       * the errorMessage for the time input
       *
       * @argument timeErrorMessage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "timeErrorMessage", _descriptor18, this);
      /**
       * the placeholder of the time input
       *
       * @argument timePlaceholder
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "timePlaceholder", _descriptor19, this);
      /**
       * the selected date
       *
       * @property date
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "date", _descriptor20, this);
      /**
       * the initial time that should be selected
       *
       * @property _intialTime
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_initialTime", _descriptor21, this);
      /**
       * the initial date that should be selected
       *
       * @property _initialDate
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_initialDate", _descriptor22, this);
      /**
       * the callback for the date change
       *
       * @function onChange
       * @param {Date} date
       */
      _initializerDefineProperty(this, "onChange", _descriptor23, this);
      /**
       * callback triggered when the clear button is pressed
       *
       * @function onClear
       */
      _initializerDefineProperty(this, "onClear", _descriptor24, this);
      _initializerDefineProperty(this, "onValidStateChange", _descriptor25, this);
      this._initialDate = this.initialDate;

      // preselect the initial date and time if it's defined
      this._preselect();
    }
    onDidUpdate() {
      if (JSON.stringify(this._initialDate) !== JSON.stringify(this.initialDate)) {
        this._preselect();
      }
    }
    _preselect() {
      if (this.initialDate) {
        const initialDate = (0, _dateUtil.parseDate)(this.initialDate);
        const hours = (0, _dateFns.getHours)(initialDate);
        const minutes = (0, _dateFns.getMinutes)(initialDate);
        const timeString = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
        this._initialTime = timeString;
        this.date = initialDate;
      }
    }

    /**
     * called when the date changes, it modifies the date object to set the new date, while preserving the time
     *
     * @function date
     * @param {Date} date
     */
    onDateChange(date) {
      let dateObject = null;
      if (date) dateObject = (0, _dateFns.parseISO)(date);
      if (this.date && date) {
        dateObject = (0, _dateFns.setHours)(dateObject, (0, _dateFns.getHours)(this.date));
        dateObject = (0, _dateFns.setMinutes)(dateObject, (0, _dateFns.getMinutes)(this.date));
      }
      this.date = dateObject;
      this.onChange(dateObject);
    }
    /**
     * called when the time is changed, it modifies the date object, setting the new time, while preserving the date
     *
     * @function onTimeChange
     * @param {Date} dateTime
     */
    onTimeChange(dateTime) {
      let date = this.date || new Date();
      date = (0, _dateFns.setHours)(date, (0, _dateFns.getHours)(dateTime));
      date = (0, _dateFns.setMinutes)(date, (0, _dateFns.getMinutes)(dateTime));
      this.date = date;
      this.onChange(date);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "dateLabel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateErrorMessage", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "datePlaceholder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initialDate", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasFilters", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return ['this_week', 'last_week', 'this_month', 'last_month', 'last_thirty_days', 'custom_date'];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultFilter", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec8, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_dec9, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "minDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "maxDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "minTime", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '00:00';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "maxTime", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '23:59';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "resetDate", [_dec15, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isClearable", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "timeLabel", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "timeErrorMessage", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "timePlaceholder", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "date", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "_initialTime", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "_initialDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "onClear", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClear"), _class.prototype), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "onValidStateChange", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDateChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDateChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTimeChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTimeChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDatetimePickerComponent);
});