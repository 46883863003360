define("@additive-apps/ui/components/ui-breadcrumbs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _object, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this._classNames}}>
    {{#each this.routeHierarchy as |route index|}}
      {{#if index}}
        <span class="secondary">
          &nbsp;&nbsp;/&nbsp;&nbsp;
        </span>
      {{/if}}
      {{#if route.path}}
        <LinkTo @route={{route.path}}>
          {{route.name}}
        </LinkTo>
      {{else}}
        {{#if this._siblingRoutes}}
          <UiMenu @position="right target" @offset="0 -8" as |menu|>
            <menu.trigger class="ui-breadcrumbs__dropdown flex items-center">
              {{route.name}}
              {{svg-jar "caret" width=this.iconSize height=this.iconSize}}
            </menu.trigger>
            <menu.content>
              {{#each this._siblingRoutes as |route|}}
                <UiMenuItem @onClick={{fn this.goTo route}}>
                  {{route.name}}
                </UiMenuItem>
              {{/each}}
            </menu.content>
          </UiMenu>
        {{else}}
          {{route.name}}
        {{/if}}
      {{/if}}
    {{/each}}
    {{yield (hash titleSuffix=(component "ui-blank-template" tagName="div" classNames="pl05"))}}
  </div>
  */
  {
    "id": "VagJocau",
    "block": "[[[10,0],[15,0,[30,0,[\"_classNames\"]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"routeHierarchy\"]]],null]],null],null,[[[41,[30,2],[[[1,\"      \"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n          /  \\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"path\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@route\"],[[30,1,[\"path\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"_siblingRoutes\"]],[[[1,\"        \"],[8,[39,4],null,[[\"@position\",\"@offset\"],[\"right target\",\"0 -8\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"trigger\"]],[[24,0,\"ui-breadcrumbs__dropdown flex items-center\"]],null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"],[1,[28,[35,5],[\"caret\"],[[\"width\",\"height\"],[[30,0,[\"iconSize\"]],[30,0,[\"iconSize\"]]]]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_siblingRoutes\"]]],null]],null],null,[[[1,\"              \"],[8,[39,6],null,[[\"@onClick\"],[[28,[37,7],[[30,0,[\"goTo\"]],[30,4]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,4,[\"name\"]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[4]],null],[1,\"          \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]]],[]]]],[1,2]],null],[1,\"  \"],[18,5,[[28,[37,9],null,[[\"titleSuffix\"],[[50,\"ui-blank-template\",0,null,[[\"tagName\",\"classNames\"],[\"div\",\"pl05\"]]]]]]]],[1,\"\\n\"],[13]],[\"route\",\"index\",\"menu\",\"route\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"link-to\",\"ui-menu\",\"svg-jar\",\"ui-menu-item\",\"fn\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-breadcrumbs.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  /**
   * Component that creates a breadcrumb hierarchy for the current route
   *
   * @class ui-breadcrumbs
   * @public
   */
  let UiBreadcrumbsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.number), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string), _dec8 = (0, _object.computed)('currentRouteName.length', 'router.currentRouteName'), _dec9 = (0, _object.computed)('router.{currentRoute.parent.name,currentRouteName}', 'siblingRoutes.[]'), _class = class UiBreadcrumbsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "classNames", _descriptor3, this);
      /**
       * defines the end of the route
       *
       * @property currentRouteName
       * @type {String}
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "currentRouteName", _descriptor4, this);
      /**
       * overwrites the text of the current route
       * can be used at detail pages
       *
       * @property currentRouteNameText
       * @type {String}
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "currentRouteNameText", _descriptor5, this);
      /**
       * overwrites the text of the previous route
       *
       * @property prevRouteNameText
       * @type {String}
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "prevRouteNameText", _descriptor6, this);
      /**
       * the root route of the breadcrumbs
       * if not defined, all routes will be used
       *
       * @property rootName
       * @type {String}
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "rootName", _descriptor7, this);
      /**
       * the maximum count of breadcrumbs show
       * if the maximum count is reached, the first breadcrumb is displayed with 3 dots
       *
       * @property maxCount
       * @type {Int}
       * @default 3
       * @public
       */
      _initializerDefineProperty(this, "maxCount", _descriptor8, this);
      /**
       * List of sibling route names.
       * Can either be specified using string separated by commas
       * `route1,route2,route3`
       *
       * or as an array of objects containing a the route name, and optionally a path
       * and a title (if no title is defined it will be set using the path)
       * `[{routeName: "route1", param: "id", title: "Route" , options: { queryParams: {param: 1} } }]
       *
       * @property siblingRoutes
       * @type {String|Array}
       * @default undefined
       * @public
       */
      _initializerDefineProperty(this, "siblingRoutes", _descriptor9, this);
      /**
       * Specifies the font size of the breadcrumbs
       * as well as the dropdown icons size.
       *
       * As long as no sibling routes are defined the size
       * can also be specified using the font size classes.
       *
       * @property size
       */
      _initializerDefineProperty(this, "size", _descriptor10, this);
    }
    get _fontClassName() {
      let {
        size
      } = this;
      let regular = ['sm', 'md', 'lg'];
      let oversized = ['xl'];
      let sizes = [...regular, ...oversized];
      if (sizes.includes(size)) {
        return `font-${size}`;
      }
      return null;
    }
    get _classNames() {
      let {
        classNames,
        _fontClassName: font
      } = this;
      return [classNames, 'ui-breadcrumbs flex', font].filter(Boolean).join(' ');
    }

    /**
     * sets the size of the dropdown icon depending on the font size
     * property
     *
     * @computed iconSize
     * @type {Number}
     */
    get iconSize() {
      if (this.size === 'sm') return 20;
      if (this.size === 'xl') return 32;
      return 24;
    }

    /**
     * the hierarchy of routes to be displayed in the breadcrumbs
     *
     * @computed _routeHierarchy
     * @return {Array}
     * @public
     */
    get routeHierarchy() {
      if (!this._currentRouteName) {
        return [];
      }
      let routes = [];
      let routeNames = this._currentRouteName.split('.');
      if (routeNames[routeNames.length - 1] === 'index') {
        routeNames.pop();
      }
      while (routeNames.length > 0) {
        /*
         * prepends an object for each breadcrumb containing the name and route
         * the first added route alias the current route has no path and is therefore not linked
         */
        routes.unshift({
          path: routes.length > 0 ? routeNames.join('.') : null,
          name: this.intl.t(`breadcrumbs.${routeNames.filter(route => route !== 'index').join('.')}.routeName`)
        });
        const lastRouteNamePart = routeNames.pop();
        if (routeNames[routeNames.length - 1] === 'index') {
          routeNames.pop();
        }
        if (this.rootName && lastRouteNamePart === this.rootName) {
          break;
        }
      }

      // if maxCount is defined, keep only first and last maxCount-1 routes and drop routes in between
      if (this.maxCount > 0 && routes.length >= this.maxCount) {
        const firstRoute = routes[0];
        firstRoute.name = '...';
        routes = routes.slice(-1 * (this.maxCount - 1));
        routes.unshift(firstRoute);
      }
      if (this.currentRouteNameText) {
        routes[routes.length - 1].name = this.currentRouteNameText;
      }
      if (this.prevRouteNameText) {
        routes[routes.length - 2].name = this.prevRouteNameText;
      }
      return routes;
    }

    /**
     * the current route name
     *
     * @computed _currentRouteName
     * @return {String}
     * @private
     */
    get _currentRouteName() {
      let _currentRouteName = this.router.currentRouteName;
      if (this.currentRouteName && _currentRouteName.indexOf(`.${this.currentRouteName}.`) > 0) {
        const routeEnd = _currentRouteName.indexOf(`.${this.currentRouteName}.`) + this.currentRouteName.length + 1;
        _currentRouteName = _currentRouteName.substring(0, routeEnd);
      }
      return _currentRouteName;
    }

    /**
     * Array of sibling routes containing path, name and params
     *
     * @computed _siblingRoutes
     * @type {Array}
     * @private
     */
    get _siblingRoutes() {
      let {
        siblingRoutes
      } = this;
      if (!siblingRoutes) {
        return null;
      }

      // split routes if type is string
      if (typeof siblingRoutes === 'string') {
        siblingRoutes = siblingRoutes.split(',').map(route => {
          return {
            routeName: route
          };
        });
      }
      const parentRoute = this.router.currentRoute.parent.name;
      // add path and route names
      return siblingRoutes.map(route => {
        const path = `${parentRoute}.${route.routeName}`;
        const name = route.title || this.intl.t(`breadcrumbs.${path}.routeName`);
        return {
          path,
          name,
          param: route.param,
          options: route.options
        };
      });
    }

    /**
     * transitions to a sibling route
     *
     * @function goTo
     * @param {Object} route
     */
    goTo(route) {
      const args = [route.path];
      route.param && args.push(route.param);
      route.options && args.push(route.options);
      this.router.transitionTo(...args);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentRouteNameText", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "prevRouteNameText", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "rootName", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "maxCount", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 3;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "siblingRoutes", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'sm';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_currentRouteName", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "_currentRouteName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_siblingRoutes", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "_siblingRoutes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goTo"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiBreadcrumbsComponent);
});