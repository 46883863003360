define("@additive-apps/media/components/mm-medium-item", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-medium-item", "@ember/runloop", "@ember/object", "@ember/utils"], function (_exports, _component, _mmMediumItem, _runloop, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Component to output a medium object.
   *
   *  You can add css-class 'show-on-hovered' to yielded elements for default hover behavior.
   *
   * ```hbs
   *{{mm-medium-item
   *  medium=medium
   *  defaultSize=defaultSize
   *  mediaSets=mediaSets
   *  sizeSets=sizeSets
   *}}
   * ```
   *
   * @class mm-medium-item
   * @yield {Hash} item
   * @yield {Component} item.topLeft
   * @yield {Component} item.topRight
   * @yield {Component} item.bottomRight
   * @yield {Component} item.bottomLeft
   * @yield {Component} item.mediumContainer
   * @yield {Component} item.fileName
   * @yield {Boolean}   item.isMouseOver
   * @yield {Boolean}   item.isSelected
   * @yield {Boolean}   item.isWarning
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmMediumItem.default,
    classNames: ['mm-medium-item', 'relative', 'br1 ohidden', 'w-100 h-100'],
    classNameBindings: ['_isMouseOver:hovered', '_cleanView:clean-view', 'isSelected:selected', 'isWarning:warning'],
    /**
     * Image's default size (beside srcSets)
     *
     * @property defaultSize
     * @type {String}
     */
    defaultSize: null,
    /**
     * `<picture>`'s media-sets
     *
     * @property mediaSets
     * @type {Hash}
     */
    mediaSets: null,
    /**
     * The medium model
     *
     * @property medium
     * @type {Medium}
     */
    medium: null,
    /**
     * `<picture>`'s size-sets
     *
     * @property sizeSets
     * @type {Hash}
     */
    sizeSets: null,
    /**
     * Whether hover events should not be used
     *
     * @property ignoreHover
     * @type {Boolean}
     */
    ignoreHover: false,
    /**
     * whether the medium is selected, blue background
     *
     * @property isSelected
     * @type {Boolean}
     */
    isSelected: false,
    /**
     * whether a warning should be displayed, yellow background
     *
     * @property isWarning
     * @type {Boolean}
     */
    isWarning: false,
    /**
     * Whether videos should be shown + controlls not only thumbnail
     *
     * @property withVideo
     * @type {Boolean}
     */
    withVideo: false,
    /**
     * Whether mouse is over item or not
     * @property _isMouseOver
     * @type {Boolean}
     * @private
     */
    _isMouseOver: false,
    didInsertElement() {
      this._super(...arguments);
      const element = this.element;
      if (this.ignoreHover === false) {
        const mouseOverEvent = (0, _runloop.bind)(this, this._onMouseOver);
        const mouseOutEvent = (0, _runloop.bind)(this, this._onMouseOut);
        element.addEventListener('mouseover', mouseOverEvent, false);
        element.addEventListener('mouseout', mouseOutEvent, false);
      }
    },
    init() {
      this._super(...arguments);
      (0, _utils.isNone)(this.mediaSets) && (0, _object.set)(this, 'mediaSets', {});
      (0, _utils.isNone)(this.sizeSets) && (0, _object.set)(this, 'sizeSets', {});
    },
    willDestroyElement() {
      this._super(...arguments);
      const element = this.element;
      if (this.ignoreHover === false) {
        element.removeEventListener('mouseover', this._onMouseOver);
        element.removeEventListener('mouseout', this._onMouseOut);
      }
    },
    click() {
      this.onClick();
    },
    /**
     * on click handler
     *
     * @function onClick
     */
    onClick() {},
    /**
     * On mouse over event
     *
     * @function _onMouseOver
     * @private
     */
    _onMouseOver() {
      (0, _object.set)(this, '_isMouseOver', true);
    },
    /**
     * On mouse out event
     *
     * @function _onMouseOut
     * @private
     */
    _onMouseOut() {
      (0, _object.set)(this, '_isMouseOver', false);
    },
    /**
     * On video start event
     *
     * @function _onVideoStarted
     * @private
     */
    _onVideoStarted() {
      (0, _object.set)(this, '_cleanView', true);
      this.onVideoStarted(...arguments);
    },
    /**
     * On video stop event
     *
     * @function _onVideoStopped
     * @private
     */
    _onVideoStopped() {
      (0, _object.set)(this, '_cleanView', false);
      this.onVideoStopped(...arguments);
    },
    /**
     * On video start event
     *
     * @function onVideoStarted
     */
    onVideoStarted() {},
    /**
     * On video stop event
     *
     * @function onVideoStopped
     */
    onVideoStopped() {}
  });
});