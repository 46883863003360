define("@additive-apps/ui/models/app", ["exports", "@ember-data/model", "@ember/object", "@additive-apps/ui/utils/app-utils"], function (_exports, _model, _object, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    planName: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    managed: (0, _model.attr)('boolean'),
    managedBy: (0, _model.attr)('string'),
    hasModules: (0, _model.attr)('boolean'),
    modules: (0, _model.attr)(),
    trialEndsAt: (0, _model.attr)(),
    updatedAt: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    /**
     * The app frontend-url
     *
     * @computed appUrl
     * @type {String}
     */
    appUrl: (0, _object.computed)('id', {
      get() {
        return (0, _appUtils.getAppUrlById)(this.id, this);
      }
    }),
    /**
     * If the trial-period has not ended yet and the app is active,
     * the trial is active
     *
     * @computed isTrial
     * @type {Boolean}
     */
    isTrial: (0, _object.computed)('trialEndsAt', 'active', {
      get() {
        const trialEndsAtDate = new Date(this.trialEndsAt);
        const now = new Date();
        if (trialEndsAtDate > now && this.active) {
          return true;
        }
        return false;
      }
    }),
    /**
     * If it is an buyable app through SaaS
     *
     * @computed isSaasBuyable
     * @type {Boolean}
     */
    isSaasBuyable: (0, _object.computed)('id', {
      get() {
        return (0, _appUtils.isSaasBuyable)(this.id);
      }
    }),
    /**
     * If its plan can be upgraded
     *
     * @computed isUpgradable
     * @type {Boolean}
     */
    isUpgradable: (0, _object.computed)('active', 'planName', 'id', {
      get() {
        const planName = this.planName;
        if (!this.active || !planName) {
          return false;
        }

        /*
         * TODO: use `plans` from `/apps?bought=1` to compute upgradability.
         *
         * Temporary check for CRM as it does not have an enterprise plan
         */
        if (_appUtils.APPS_WITHOUT_ENTERPRISE_PLAN.indexOf(parseInt(this.id)) >= 0) {
          return planName !== 'professional_monthly' && planName !== 'professional_yearly';
        }
        return planName !== 'enterprise_monthly' && planName !== 'enterprise_yearly';
      }
    })
  });
});