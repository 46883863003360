define("@additive-apps/ui/components/ui-application-navigation-drawer/user-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/application", "ember-arg-types", "prop-types", "@additive-apps/ui/constants"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _application, _emberArgTypes, _propTypes, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="flex items-center justify-between pl2 pr3 pv2 bg-secondary--10--hover cpointer"
    role="button"
    {{on "click" this.onClick}}
  >
    <div class="flex items-center">
      <div class="mr2 br-100 ohidden">
        {{#if this.image}}
          <img src={{this.image}} alt="user avatar" width="32" height="32" class="db" />
        {{else}}
          <div class="pa1 bg-secondary--20">
            {{svg-jar "placeholder-user" width="16" height="16" class="db secondary--40"}}
          </div>
        {{/if}}
      </div>
      <span class="ellipsis font-sm">
        {{this.userFullName}}
      </span>
    </div>
  
    <UiMenu @class="flex items-center" as |menu|>
      <menu.trigger class="flex items-center">
        <UiIconButton
          @class="primary"
          @isSecondary={{true}}
          @icon="more"
          data-test-user-menu-button
        />
      </menu.trigger>
  
      <menu.content as |content|>
        <content.menuItem @class="font-sm" @onClick={{this.logout}}>
          {{t "global.actions.logout"}}
        </content.menuItem>
      </menu.content>
    </UiMenu>
  </div>
  */
  {
    "id": "9uTCqRtR",
    "block": "[[[11,0],[24,0,\"flex items-center justify-between pl2 pr3 pv2 bg-secondary--10--hover cpointer\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr2 br-100 ohidden\"],[12],[1,\"\\n\"],[41,[30,0,[\"image\"]],[[[1,\"        \"],[10,\"img\"],[15,\"src\",[30,0,[\"image\"]]],[14,\"alt\",\"user avatar\"],[14,\"width\",\"32\"],[14,\"height\",\"32\"],[14,0,\"db\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"pa1 bg-secondary--20\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"placeholder-user\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"db secondary--40\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"ellipsis font-sm\"],[12],[1,\"\\n      \"],[1,[30,0,[\"userFullName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@class\"],[\"flex items-center\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"trigger\"]],[[24,0,\"flex items-center\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],[[24,\"data-test-user-menu-button\",\"\"]],[[\"@class\",\"@isSecondary\",\"@icon\"],[\"primary\",true,\"more\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"menuItem\"]],null,[[\"@class\",\"@onClick\"],[\"font-sm\",[30,0,[\"logout\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,5],[\"global.actions.logout\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13]],[\"menu\",\"content\"],false,[\"on\",\"if\",\"svg-jar\",\"ui-menu\",\"ui-icon-button\",\"t\"]]",
    "moduleName": "@additive-apps/ui/components/ui-application-navigation-drawer/user-button.hbs",
    "isStrictMode": false
  });
  let UiApplicationNavigationDrawerUserButton = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _class = class UiApplicationNavigationDrawerUserButton extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      /**
       * the user object
       *
       * @argument user
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "user", _descriptor3, this);
    }
    /**
     * the user image
     *
     * @computed image
     * @type {String}
     */
    get image() {
      let user = this.user;
      if (!user && this.currentUser.user) {
        user = this.currentUser.user;
      }
      if (!user) {
        return null;
      }
      if (user.imagePath) {
        return user.imagePath;
      } else if (user.imageUrl) {
        return user.imageUrl;
      }
      return null;
    }

    /**
     * the full name of the user
     *
     * @computed userFullName
     * @type {String}
     */
    get userFullName() {
      var _this$user, _this$currentUser;
      return ((_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.fullName) || ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.fullName);
    }

    /**
     * the app id
     *
     * @computed appId
     * @type {String}
     */
    get appId() {
      var _this$ENV;
      return (_this$ENV = this.ENV) === null || _this$ENV === void 0 || (_this$ENV = _this$ENV.APP) === null || _this$ENV === void 0 ? void 0 : _this$ENV.appId;
    }

    /**
     * the environment config
     *
     * @computed ENV
     * @type {Object}
     */
    get ENV() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }

    /**
     * whether the current app is the account app
     *
     * @computed isAccount
     * @type {Boolean}
     */
    get isAccount() {
      return this.appId === _constants.APP_ID_ACCOUNT;
    }
    onClick() {
      if (this.appId === _constants.APP_ID_ACCOUNT) {
        return this.router.transitionTo('instances.me');
      }
      window.location.href = `${this.ENV.APP.accountHost}/me`;
    }
    logout() {
      if (this.appId === _constants.APP_ID_ACCOUNT) {
        return this.router.transitionTo('logout');
      }
      window.location.href = `${this.ENV.APP.accountHost}/logout`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiApplicationNavigationDrawerUserButton);
});