define("@additive-apps/ui/components/ui-filter-inputs/inputs", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-filter-inputs/inputs"], function (_exports, _component, _inputs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Components which represents the input fields
   *
   * @class ui-filter-inputs/inputs
   * @module ui-filter-inputs
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _inputs.default,
    /**
     * Group filters by group-key.
     *
     * @computed groups
     * @type {Object[]|Any}
     */
    groups: null,
    /**
     * The initial values for the filters.
     *
     * object key  => filterkey
     * object[key] => filtervalue
     *
     ```javascript
        { category: 'category-id', age: ['1-10', '11-18']}
     ```
     *
     * @property initialValues
     * @type {Object}
     */
    initialValues: null,
    /**
     * Event for change.
     *
     * @function onChange
     * @param {String} key The filter key which changes
     * @param {Any} value The new value
     */
    onChange() {}
  });
});