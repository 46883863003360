define("@additive-apps/ui/helpers/ui-days-left", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiDaysLeft = uiDaysLeft;
  function uiDaysLeft(_ref /*, hash*/) {
    let [date] = _ref;
    const now = new Date();
    const trialEndsAt = new Date(date);
    const dateDiff = trialEndsAt - now;
    const dateDiffInDays = dateDiff / 1000 / 60 / 60 / 24;
    return Math.floor(dateDiffInDays);
  }
  var _default = _exports.default = (0, _helper.helper)(uiDaysLeft);
});