define("@additive-apps/ui/components/ui-navigation-drawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/object/internals", "@ember/runloop", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _internals, _runloop, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class2, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id={{this._elementId}}
    class="ui-navigation-drawer h-100 bg-white
      {{if this._isOpen 'ui-navigation-drawer--open'}}
      {{if this._isPermanent 'ui-navigation-drawer--permanent'}}
      {{if this._isHidden 'ui-navigation-drawer--hidden'}}
      {{if this.isRight 'ui-navigation-drawer__right'}}
      {{if this.isSubDrawer 'ui-navigation-drawer--sub-drawer'}}
      {{@class}}"
    {{did-insert this.onDidInsert}}
    {{will-destroy this.onWillDestroy}}
    ...attributes
  >
    <div class="h-100 bg-white">
      <div class="flex flex-column h-100 bg-secondary--10">
        {{yield
          (hash
            navbar=(component "ui-navigation-bar" hideMenuButton=true)
            header=(component
              "ui-blank-template" tagName="div" classNames="flex flex-grow-0"
            )
            content=(component
              "ui-blank-template"
              tagName="div"
              class="ui-navigation-drawer__content ph3 oauto"
            )
            footer=(component
              "ui-blank-template" tagName="div" classNames="flex flex-grow-0"
            )
            subDrawer=(component "ui-navigation-drawer" isSubDrawer=true parentId=this._elementId)
            close=this._close
          )
        }}
      </div>
    </div>
  </div>
  */
  {
    "id": "RVvz79sG",
    "block": "[[[11,0],[16,1,[30,0,[\"_elementId\"]]],[16,0,[29,[\"ui-navigation-drawer h-100 bg-white\\n    \",[52,[30,0,[\"_isOpen\"]],\"ui-navigation-drawer--open\"],\"\\n    \",[52,[30,0,[\"_isPermanent\"]],\"ui-navigation-drawer--permanent\"],\"\\n    \",[52,[30,0,[\"_isHidden\"]],\"ui-navigation-drawer--hidden\"],\"\\n    \",[52,[30,0,[\"isRight\"]],\"ui-navigation-drawer__right\"],\"\\n    \",[52,[30,0,[\"isSubDrawer\"]],\"ui-navigation-drawer--sub-drawer\"],\"\\n    \",[30,1]]]],[17,2],[4,[38,1],[[30,0,[\"onDidInsert\"]]],null],[4,[38,2],[[30,0,[\"onWillDestroy\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"h-100 bg-white\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column h-100 bg-secondary--10\"],[12],[1,\"\\n      \"],[18,3,[[28,[37,4],null,[[\"navbar\",\"header\",\"content\",\"footer\",\"subDrawer\",\"close\"],[[50,\"ui-navigation-bar\",0,null,[[\"hideMenuButton\"],[true]]],[50,\"ui-blank-template\",0,null,[[\"tagName\",\"classNames\"],[\"div\",\"flex flex-grow-0\"]]],[50,\"ui-blank-template\",0,null,[[\"tagName\",\"class\"],[\"div\",\"ui-navigation-drawer__content ph3 oauto\"]]],[50,\"ui-blank-template\",0,null,[[\"tagName\",\"classNames\"],[\"div\",\"flex flex-grow-0\"]]],[50,\"ui-navigation-drawer\",0,null,[[\"isSubDrawer\",\"parentId\"],[true,[30,0,[\"_elementId\"]]]]],[30,0,[\"_close\"]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@class\",\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-navigation-drawer.hbs",
    "isStrictMode": false
  });
  // If this breakpoint gets changed, change it in css too. scss breakpoint 'lg'
  const permanentMediaQuery = '(min-width: 1280px)';
  let State = (_class = class State extends _object.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isOpen", _descriptor, this);
      _initializerDefineProperty(this, "isPermanent", _descriptor2, this);
      _initializerDefineProperty(this, "isHidden", _descriptor3, this);
      _initializerDefineProperty(this, "onToggle", _descriptor4, this);
    }
    /**
     * Opens the navdrawer.
     * @function open
     */
    open() {
      if (!this.isPermanent) {
        const body = document.body;
        body.classList.add('ohidden');
      }
      this.isOpen = true;
      this.onToggle();
    }

    /**
     * Closes the navdrawer.
     * Has only effect if is not permanent (for example on mobile)
     *
     * @function close
     */
    close() {
      if (!this.isPermanent) {
        const body = document.body;
        body.classList.remove('ohidden');
      }
      this.isOpen = false;
      this.onToggle();
    }

    /**
     * Closes the navdrawer.
     * Has only effect if is not permanent (for example on mobile)
     *
     * @function toggle
     */
    toggle() {
      this.isOpen = !this.isOpen;
      this.onToggle();
    }

    /**
     * Hide the navdrawer.
     * Completely hides the navdrawer, even it is permanent.
     *
     * @function hide
     */
    hide() {
      this.isHidden = true;
    }

    /**
     * Shows a hidden navdrawer.
     *
     * @function show
     */
    show() {
      this.isHidden = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPermanent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isHidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onToggle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  let UiNavigationDrawer = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _class2 = class UiNavigationDrawer extends _component2.default {
    get _isPermanent() {
      return this.state.isPermanent === true;
    }
    get _isHidden() {
      return this.state.isHidden === true;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiState", _descriptor5, this);
      _initializerDefineProperty(this, "uiBackdrop", _descriptor6, this);
      /**
       * the state key for the nav drawer
       *
       * @argument navDrawerStateKey
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "navDrawerStateKey", _descriptor7, this);
      /**
       * whether the nav drawer should close on outside click
       *
       * @argument closeOnOutsideClick
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "closeOnOutsideClick", _descriptor8, this);
      /**
       * whether the nav drawer is permanent
       *
       * @argument isPermanent
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "isPermanent", _descriptor9, this);
      /**
       * whether the nav drawer is positioned right
       *
       * @argument isRight
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isRight", _descriptor10, this);
      /**
       * whether the nav drawer is a child of another nav drawer
       *
       * @argument isSubDrawer
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSubDrawer", _descriptor11, this);
      /**
       * the id of the parent nav drawer
       *
       * @argument parentId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "parentId", _descriptor12, this);
      /**
       * Current state.
       *
       * @property state
       * @type {Object}
       * @private
       */
      _defineProperty(this, "state", null);
      /**
       * The di of the root element
       *
       * @property _elementId
       * @type {String}
       * @private
       */
      _defineProperty(this, "_elementId", (0, _internals.guidFor)(this));
      /**
       * Current open state.
       *
       * @property _isOpen
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isOpen", _descriptor13, this);
      /**
       * On navigation drawer close
       *
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor14, this);
      /**
       * On navigation drawer open
       *
       * @function onOpen
       */
      _initializerDefineProperty(this, "onOpen", _descriptor15, this);
      this._close = (0, _runloop.bind)(this, this._close);
      this._handleOpenChange = (0, _runloop.bind)(this, this._handleOpenChange);
      this.state = State.create();
      this.state.set('onToggle', this._handleOpenChange);

      // register local drawer state to state service
      this.uiState.register(this.navDrawerStateKey, this.state);
      this.resizeListener = null;
    }
    _handleOpenChange() {
      var _this$state;
      let isOpenState = (_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.isOpen;
      if (this._isPermanent) {
        isOpenState = false;
      }
      if (isOpenState) {
        const backdropOnClickCallback = this.closeOnOutsideClick ? () => {
          if (this.isDestroying) {
            return;
          }
          this._close();
        } : () => {};
        this.uiBackdrop.show(this.isSubDrawer ? this.parentId : this._elementId, backdropOnClickCallback);
        this.onOpen();
      } else {
        if (this.isSubDrawer) {
          if (this.shouldBePermanent) {
            this.uiBackdrop.hide(this.parentId);
          }
        } else {
          this.uiBackdrop.hide(this._elementId);
        }
        this.onClose();
      }
      this._isOpen = isOpenState;
    }
    get shouldBePermanent() {
      return window.matchMedia(permanentMediaQuery).matches;
    }
    updatePermanent() {
      const {
        shouldBePermanent
      } = this;
      this.isDestroying ? null : this.state.isPermanent = shouldBePermanent;
    }

    /**
     * Function to close navigation-drawer
     *
     * @function _onClose
     * @private
     */
    _close() {
      const state = this.uiState.getState(this.navDrawerStateKey);
      state === null || state === void 0 || state.close();
    }
    onDidInsert() {
      if (!this.isPermanent) {
        return;
      }
      this.resizeListener = window.addEventListener('resize', () => {
        (0, _runloop.debounce)(this, this.updatePermanent, 150);
      }, true);
      this.updatePermanent();
    }
    onWillDestroy() {
      /*
       * State and listener cleanup.
       * To avoid mem-leaks we remove all listener and unregister the states
       */
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
      this.uiBackdrop.hide(this.isSubDrawer ? this.parentId : this._elementId);
      this.uiState.unregister(this.navDrawerStateKey);
    }
  }, _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "uiBackdrop", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "navDrawerStateKey", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'ui-navigation-drawer';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "closeOnOutsideClick", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "isPermanent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "isRight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "isSubDrawer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "parentId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "_isOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "onClose", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "onOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onWillDestroy"), _class2.prototype), _class2);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiNavigationDrawer);
});