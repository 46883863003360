define("@additive-apps/jam-ember/sentry/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaysConfig = _exports.ignoreErrors = _exports.denyUrls = _exports.allowUrls = void 0;
  const ignoreErrors = _exports.ignoreErrors = ['TransitionAborted' // Ember default transition aborted error
  ];
  const denyUrls = _exports.denyUrls = [];
  const allowUrls = _exports.allowUrls = [/(assets)(.*)(.js$)/ // Anything that matches /assets/xxxxxx.js (/assets/additive-xxx.js, /assets/chunk.0.xxxx.js)
  ];
  const replaysConfig = _exports.replaysConfig = {
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false
  };
});