define("@additive-apps/media/components/mm-picture-tag", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-picture-tag", "@ember/object", "@ember/utils", "@ember/debug", "@ember/runloop", "@additive-apps/media/utils/medium"], function (_exports, _component, _mmPictureTag, _object, _utils, _debug, _runloop, _medium) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  HTML-`<picture>` wrapper with srcSets-abbility.
   *
   * ```hbs
   *{{mm-picture-tag
   *   mediaSets=(hash large="(min-width: 1200px)" medium="(min-width: 900px)")
   *   sizeSets=(hash large="300x300" medium="150x150")
   *   defaultSize=75x75
   *   urlOptions=(hash poi=[0.5,0.3] mo=[50, 50, 50])
   *   url="https://source.unsplash.com/random"
   *   alt="my-picture-tag"
   *   useImageEvents=true
   *   onImageLoad=(action "onImageLoad")
   *   onImageError=(action "onImageError")
   *}}
   * ```
   *
   * @class mm-picture-tag
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmPictureTag.default,
    tagName: 'picture',
    /**
     * Image's `alt`-attributes value
     * @property alt
     * @type {String}
     */
    alt: null,
    /**
     * Image's default size (beside srcSets)
     * @property defaultSize
     * @type {String}
     */
    defaultSize: null,
    /**
     * `<picture>`'s media-sets
     * @property mediaSets
     * @type {Hash}
     */
    mediaSets: null,
    /**
     * `<picture>`'s size-sets
     * @property sizeSets
     * @type {Hash}
     */
    sizeSets: null,
    /**
     * The base url to use
     * @property url
     * @type {String}
     */
    url: '',
    /**
     * Additional url options
     * @property urlOptions
     * @type {Object}
     */
    urlOptions: null,
    /**
     * Whether it should register image-events.
     * Currently handled image-events are:
     *   - load --> onImageLoad (Action)
     *   - error --> onImageError (Action)
     *
     * @property useImageEvents
     * @type {Boolean}
     * @default false
     */
    useImageEvents: false,
    /**
     * `<picture>`'s image-tag url with defaultSize as query-param if given
     * @computed _imageTagUrl
     * @type {String}
     * @private
     */
    _imageTagUrl: (0, _object.computed)('defaultSize', 'elementId', 'url', {
      get() {
        if (!this.url) {
          (false && (0, _debug.warn)(`image-url not provided ${this.elementId}`, false, {
            id: 'mm-picture-tag.image-url not provided'
          }));
          return;
        }
        let url = this.url;
        const defaultSize = this.defaultSize;
        url = this._addUrlOptions(url, defaultSize);
        return url;
      }
    }),
    /**
     * `<picture>`'s src sets as array
     * @computed _srcSets
     * @type {[String]}
     * @private
     */
    _srcSets: (0, _object.computed)('url', 'mediaSets.length', 'sizeSets.length', {
      get() {
        const mediaSets = this.mediaSets;
        const mediaSetsKeys = mediaSets && Object.keys(mediaSets);
        const sizeSets = this.sizeSets;
        const sizeSetsKeys = sizeSets && Object.keys(sizeSets);
        if (mediaSetsKeys && sizeSetsKeys) {
          if (mediaSetsKeys.length !== sizeSetsKeys.length) {
            throw new ErrorEvent('[mm-picture-tag] src sets are uneven');
          }
          const url = this.url;
          let srcSets = [];
          for (let i = 0; i < mediaSetsKeys.length; i++) {
            (0, _utils.isPresent)(sizeSets[mediaSetsKeys[i]]) && srcSets.push({
              media: mediaSets[mediaSetsKeys[i]],
              url: this._addUrlOptions(url, sizeSets[mediaSetsKeys[i]])
            });
          }
          return srcSets;
        }
        return;
      }
    }),
    init() {
      this._super(...arguments);
      (0, _utils.isNone)(this.mediaSets) && (0, _object.set)(this, 'mediaSets', {});
      (0, _utils.isNone)(this.sizeSets) && (0, _object.set)(this, 'sizeSets', {});
      (0, _utils.isNone)(this.urlOptions) && (0, _object.set)(this, 'urlOptions', {});
      (false && (0, _debug.warn)(`image-alt not provided ${this.elementId}`, (0, _utils.isPresent)(this.alt), {
        id: 'mm-picture-tag.img-alt-not-provided'
      }));
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.useImageEvents === false) {
        return;
      }
      const element = this.element;
      const elementImage = element && element.querySelector('.image');
      if (!elementImage) {
        return;
      }
      const onImageLoad = (0, _runloop.bind)(this, this.onImageLoad);
      const onImageError = (0, _runloop.bind)(this, this.onImageError);
      elementImage.addEventListener('load', onImageLoad, false);
      elementImage.addEventListener('error', onImageError, false);
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.useImageEvents === false) {
        return;
      }
      const element = this.element;
      const elementImage = element && element.querySelector('.image');
      if (!elementImage) {
        return;
      }
      elementImage.removeEventListener('load', this.onImageLoad);
      elementImage.removeEventListener('error', this.onImageError);
    },
    /**
     * Image did load event
     * @function onImageLoad
     */
    onImageLoad() {},
    /**
     * Image load error event
     * @function onImageError
     */
    onImageError() {},
    /**
     * Adds url-options as query-param
     * @param {String} url  the base url to append the params to
     * @param {String} size the size value
     * @function _addUrlOptions
     */
    _addUrlOptions(url, size) {
      const options = Object.assign({}, {
        size
      }, this.urlOptions);
      return (0, _medium.mediumUrlOptions)(url, options);
    }
  });
});