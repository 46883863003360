define("@additive-apps/ui/components/ui-routable-tabs", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-routable-tabs"], function (_exports, _component, _uiRoutableTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * The tab-view consists of a header component and an outlet/liquid-bind containing the tabs body.
   *
   * The tab-items are yielded into the header, while the tabs content is displayed inside the
   * content sub-component.
   *
   * ```hbs
   * <UiRoutableTabs as |Tabs|>
   *
   *  <Tabs.header as |Header|>
   *    <Header.tab @tabName="" @routePath="" @icon="" contentCount=""}}
   *  </Tabs.header>
   *
   *  <Tabs.content>
   * </UiRoutableTabs>
   * ```
   *
   * @class ui-routable-tabs
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiRoutableTabs.default,
    tagName: ''
  });
});