define("@additive-apps/ui/templates/components/ui-item-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sStCaZkE",
    "block": "[[[41,[30,0,[\"title\"]],[[[1,\"  \"],[10,0],[14,0,\"ui-list__title font-sm secondary pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,1,[[28,[37,2],null,[[\"item\",\"input\"],[[50,\"ui-item\",0,null,[[\"class\"],[\"pa2 mb05 font-sm bg-white\"]]],[50,\"ui-input-item\",0,null,null]]]]]]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-item-group.hbs",
    "isStrictMode": false
  });
});