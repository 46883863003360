define("@additive-apps/media/components/mm-poi", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-poi", "@ember/object", "@ember/utils"], function (_exports, _component, _mmPoi, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Component which yields a movable component across whole mm-poi container
   *
   *```hbs
   *{{#mm-poi as |poi|}}
   *    {{#poi.movableContainer}}Content of movableContainer{{/poi.movableContainer}}
   *{{/mm-poi}}
   * ```
   *
   * @class mm-poi
   * @public
   * @yield {Hash} poi
   * @yield {Component} poi.movableContainer The movable container
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmPoi.default,
    classNames: ['mm-poi', 'relative', 'dib'],
    /**
     * Position where to start
     * @property position
     * @type {Object}
     */
    position: null,
    /**
     * Whether it is disabled
     * @property isDisabled
     * @type {Boolean}
     */
    isDisabled: false,
    init() {
      this._super(...arguments);
      (0, _utils.isNone)(this.position) && (0, _object.set)(this, 'position', {
        x: 0.5,
        y: 0.5
      });
    },
    /**
     * On position changed
     * @function onPositionChanged
     */
    onPositionChanged() {}
  });
});