define("@additive-apps/ui/templates/components/ui-page-not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aRtYQqzR",
    "block": "[[[1,[28,[35,0],[\"404\"],[[\"class\"],[\"w-100 h-auto\"]]]],[1,\"\\n\"],[10,\"h1\"],[14,0,\"font-lg font-xl-sm\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"pageNotFound.title\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,2],[14,0,\"font-sm secondary mt2 mb4\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"pageNotFound.content\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@onClick\",\"@label\"],[[28,[37,3],[[30,0],\"buttonTransition\"],null],[28,[37,1],[\"pageNotFound.button\"],null]]],null]],[],false,[\"svg-jar\",\"t\",\"ui-button\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-page-not-found.hbs",
    "isStrictMode": false
  });
});