define("@additive-apps/ui/components/ui-filter-inputs/single", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/single", "@ember/object", "lodash.clonedeep"], function (_exports, _base, _single, _object, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Filter-input for type `single`.
   * Renders select with provided `values`
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/single
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _single.default,
    /**
     * The current value
     *
     * @property _value
     * @type {Any}
     * @private
     */
    _value: null,
    /**
     * The current selected value
     *
     * @computed _selected
     * @return {Any}
     * @private
     */
    _selected: (0, _object.computed)('initialValue', '_value', {
      get() {
        const value = this._value;
        if (value && Object.prototype.hasOwnProperty.call(value, 'label')) {
          return value;
        }
        const initialValue = (0, _lodash.default)(this.initialValue);
        if (initialValue && Object.prototype.hasOwnProperty.call(initialValue, 'label')) {
          return initialValue;
        }
        return null;
      }
    }),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (this.initialValue !== this._value) {
        (0, _object.set)(this, '_value', (0, _lodash.default)(this.initialValue));
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onChange(selected) {
        (0, _object.set)(this, '_value', selected);
        // parents onChange
        this._onChange(this.filter.key, selected.value);
      }
    }
  });
});