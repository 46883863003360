define("@additive-apps/ui/components/ui-item-group", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-item-group"], function (_exports, _component, _uiItemGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Wrapper component for all items that should be group into
   * common HOC-logic
   *
   * ```hbs
   * {{#ui-item-group title selected as |group|}}
   *   {{group.item}}
   *   {{group.input}}
   * {{/ui-item-group}}
   * ```
   *
   * @class ui-item-group
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiItemGroup.default,
    classNames: ['ui-item-group', 'pv1'],
    classNameBindings: ['selectable:ui-item-group--selectable'],
    /**
     * The groups title
     *
     * @property title
     * @type {String}
     * @default undefined
     */
    title: undefined,
    /**
     * If set the selection states will be highlighted for the
     * child items.
     *
     * @property selectable
     * @type {Boolean}
     * @default false
     */
    selectable: false
  });
});