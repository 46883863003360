define("@additive-apps/ui/components/ui-page-not-found", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-page-not-found", "@ember/service"], function (_exports, _component, _uiPageNotFound, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Use this component for the default full-screen page-not-found view.
   * It has a button for transitioning to a different route (default: instances)
   *
   * ```hbs
   * {{ui-page-not-found homePageRoute='application'}}
   * ```
   *
   * @class ui-page-not-found
   **/
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _uiPageNotFound.default,
    classNames: ['ui-page-not-found', 'flex', 'flex-column', 'items-center', 'justify-center', 'pa2', 'bg-primary', 'white', 'w-100', 'min-vh-100'],
    /**
     * The route the button will transition to
     *
     * @property buttonRoute
     * @type {String}
     */
    buttonRoute: 'instances',
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      buttonTransition() {
        return this.router.transitionTo(this.buttonRoute);
      }
    }
  });
});