define("@additive-apps/ui/components/ui-filter-inputs/base", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Base component for the filter types.
   *
   * @class ui-filter-inputs/base
   * @module ui-filter-inputs
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    /**
     * The filter object
     *
     * @property filter
     * @type {Object}
     */
    filter: null,
    /**
     * The initial values for all filters
     *
     * @property initialValues
     * @type {Object[]|Any}
     */
    initialValues: null,
    /**
     * Short: The initial value for a the current filter.
     * Long: Find out initial value(s) which are relevant for this instance
     * depending on the key which is inside the `filter` object.
     *
     * @computed initialValue
     * @type {Object[]|Any}
     */
    initialValue: (0, _object.computed)('initialValues', 'filter.{key,values.@each.value}', {
      get() {
        if (!this.initialValues) {
          return null;
        }
        const filterValues = this.filter && this.filter.values;
        const initialValue = this.initialValues[this.filter.key];

        /* When `filter.values` is empty or a empty array we return the given initial value */
        if (!Array.isArray(filterValues) || Array.isArray(filterValues) && filterValues.length === 0) {
          return initialValue;
        }

        /* When `filter.values` is an array, we have to find the initial values out of the values array */
        if (Array.isArray(initialValue)) {
          return filterValues.filter(value => initialValue.indexOf(value.value) >= 0 || initialValue.indexOf(`${value.value}`) >= 0);
        }

        /* Check values as string as well, as URL-values are treated as strings: true -> ' true' */
        return filterValues.find(value => value.value === initialValue || `${value.value}` === initialValue);
      }
    }),
    /**
     * When the input changes
     *
     * @function onChange
     * @param {String} filterKey
     * @param {Any} newValue
     */
    onChange(/*filterKey, newValue*/) {},
    /**
     * On change wrapper to remove initial value before triggering onChange
     *
     * @function _onChange
     * @param {String} filterKey
     * @param {Any} newValue
     * @private
     */
    _onChange(filterKey, newValue) {
      this.initialValues && delete this.initialValues[filterKey];
      this.onChange(filterKey, newValue);
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onChange() {
        this._onChange(this.filter.key, ...arguments);
      }
    }
  });
});