define("@additive-apps/media/templates/components/mm-youtube-video", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cm7t6Od0",
    "block": "[[[10,\"iframe\"],[15,1,[30,0,[\"_iframeId\"]]],[14,0,\"w-100 h-100\"],[15,\"title\",[30,0,[\"_iframeTitle\"]]],[15,\"src\",[30,0,[\"_iframeSrc\"]]],[14,\"frameborder\",\"0\"],[14,4,\"text/html\"],[12],[13],[1,\"\\n\\n\"],[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-youtube-video.hbs",
    "isStrictMode": false
  });
});