define("@additive-apps/ui/templates/components/ui-menu-content-inner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "e2KGpaY5",
    "block": "[[[18,1,[[28,[37,1],null,[[\"menuItem\"],[[50,\"ui-menu-item\",0,null,[[\"dropdown\"],[[30,0,[\"dropdown\"]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-menu-content-inner.hbs",
    "isStrictMode": false
  });
});