define("@additive-apps/media/components/ui-carousel/grow-container", ["exports", "@ember/component", "@additive-apps/media/templates/components/ui-carousel/grow-container", "@ember/object", "@ember/string"], function (_exports, _component, _growContainer, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * TODO: first try, not finished at all
   *
   *  Component handle grid-column-gaps on lazy-rendering.
   *  Imagine we start at index one and navigate backwards to last index.
   *  Now we have to put some dummy-grid-columns to fill the gap between index 2 and last index.
   *  We will archieve this by putting this container with css-option `grid-columns: x/x`.
   *  This component needs to be putted twice, when we have a startIndex other than 1 or last.
   *  It will need one before startIndex and one after.
   *
   * @class ui-carousel/grow-container
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _growContainer.default,
    tagName: '',
    /**
     * Its parent-state object
     * @property parentState
     * @type {Object}
     */
    parentState: null,
    /**
     * The "old" startIndex
     * @property _currentStartIndex
     * @type {Number}
     * @private
     */
    _currentStartIndex: null,
    /**
     * The endIndex
     * @computed _endIndex
     * @type {Number}
     * @private
     */
    _endIndex: (0, _object.computed)('parentState.containerCount', {
      get() {
        var _this$parentState;
        return ((_this$parentState = this.parentState) === null || _this$parentState === void 0 ? void 0 : _this$parentState.containerCount) + 1;
      }
    }),
    /**
     * The "grid-column" (css) styles for the grow-container to specifiy how many grid-columns it needs to fill.
     * @computed _gridColumnStyles
     * @type {String}
     * @private
     */
    _gridColumnStyles: (0, _object.computed)('_startIndex', '_endIndex', {
      get() {
        if (!this._startIndex || !this._endIndex) {
          return {};
        }
        return {
          'grid-column': `${this._startIndex}/${this._endIndex}`
        };
      }
    }),
    /**
     * The new startIndex
     * @computed _startIndex
     * @type {Number}
     * @private
     */
    _startIndex: (0, _object.computed)('_currentStartIndex', 'parentState.activeIndex', {
      get() {
        var _this$parentState2, _this$parentState3;
        const currentStartIndex = this._currentStartIndex || ((_this$parentState2 = this.parentState) === null || _this$parentState2 === void 0 ? void 0 : _this$parentState2.activeIndex) + 1;
        // +1 as we pre-render the next-slide
        if (((_this$parentState3 = this.parentState) === null || _this$parentState3 === void 0 ? void 0 : _this$parentState3.activeIndex) + 1 < currentStartIndex) {
          return currentStartIndex;
        }

        // eslint-disable-next-line ember/no-side-effects
        (0, _object.set)(this, '_currentStartIndex', currentStartIndex + 1);
        return currentStartIndex + 1;
      }
    }),
    /**
     * The inline-styles which are needed: _gridColumnStyles + order
     * @computed _inlineStyles
     * @type {String}
     * @private
     */
    _inlineStyles: (0, _object.computed)('_gridColumnStyles', '_startIndex', '_endIndex', {
      get() {
        // When all container has been loaded we do not need a grow-container anymore
        if (!this._startIndex || this._startIndex > this._endIndex) {
          return null;
        }
        const containerStyles = this._gridColumnStyles || {};
        const index = this._startIndex;
        const styles = Object.assign(containerStyles, {
          order: index
        });
        const formattedStyles = Object.keys(styles).map(styleKey => {
          const styleValue = styles[styleKey];
          return styleValue && `${styleKey}: ${styleValue};`;
        });
        return (0, _string.htmlSafe)(formattedStyles.join(' '));
      }
    })
  });
});