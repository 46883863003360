define("@additive-apps/ui/services/ui-query-params", ["exports", "@ember/service", "@additive-apps/ui/classes/ui-query-param", "@additive-apps/ui/utils/query-param-util", "@ember/utils"], function (_exports, _service, _uiQueryParam, _queryParamUtil, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This service allows managing url search params.
   * It allows registering param-changed listener and an API to control it.
   *
   * @class ui-query-params
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    /**
     * Whether browser supports all necessary features
     *
     * @property _hasFeatureSupport
     * @type {Boolean}
     * @private
     */
    _hasFeatureSupport: false,
    /**
     * Object which holds each registered query param objects
     *
     * @property _queryParams
     * @type {Object}
     * @private
     */
    _queryParams: null,
    /**
     * Object which holds each registered member with his registered options
     *
     * @property _members
     * @type {Object}
     * @private
     */
    _members: null,
    /**
     * Object which holds the registered member key for a location pathname
     *
     * @property _memberKeyByLocation
     * @type {Object}
     * @private
     */
    _memberKeyByLocation: null,
    init() {
      this._super(...arguments);
      if (typeof URLSearchParams === 'function') {
        this._hasFeatureSupport = true;
      } else {
        // eslint-disable-next-line no-console
        console && console.warning('[service/ui-query-params] no URLSearchParams feature support');
        return;
      }
      this._queryParams = {};
      this._members = {};
      this._memberKeyByLocation = {};
      this._setup();
    },
    /**
     * Get and return the query param object if exists
     *
     * @function getQueryParamObject
     */
    getQueryParamObject(memberKey, queryParamKey) {
      const object = this._queryParams[memberKey][queryParamKey];
      return object;
    },
    /**
     * Get the value of a query param (registered only)
     *
     * @function getValue
     */
    getValue(memberKey, queryParamKey) {
      const object = this.getQueryParamObject(memberKey, queryParamKey);
      return object && object.getValue();
    },
    /**
     * Set the value of a query param (registered only)
     *
     * @function setValue
     * @param {String}  queryParamKey The key
     * @param {Any}     value   The value
     * @param {Boolean} notify  Whether it should notify members of change
     * @param {Boolean} history Whether it should push into browsers history
     */
    setValue(memberKey, queryParamKey, value) {
      let notify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      let history = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      const object = this.getQueryParamObject(memberKey, queryParamKey);
      return object && object.setValue(value, notify, history);
    },
    /**
     * Function to register an query param and a on change listener.
     *
     * @function register
     * @param {String} memberKey  Unique key of the use case
     * @param {Object} options    Options
     * @param {Object} optionsKey The query param
     * @param {Object} options.onChange Callback when query params value changes
     *
     * ```js
     * this.uiQueryParams.register('demo', {
     *   'page': {
     *     onChange: (key, value) => {},
     *   },
     *   'limit': {
     *     onChange: (key, value) => {}
     *   },
     *   'category': {
     *     onChange: (key, value) => {}
     *   }
     * });
     * ```
     */
    register(memberKey, options) {
      if (!this._hasFeatureSupport) {
        return;
      }
      this._members[memberKey] = options;
      this._queryParams[memberKey] = {};
      Object.keys(options).forEach(queryParamKey => {
        if (typeof this._queryParams[memberKey][queryParamKey] === 'undefined') {
          this._queryParams[memberKey][queryParamKey] = _uiQueryParam.default.create();
        }
        this._queryParams[memberKey][queryParamKey].key = queryParamKey;
        if (typeof options[queryParamKey].onChange === 'function') {
          this._queryParams[memberKey][queryParamKey].addOnChangeCallback(memberKey, options[queryParamKey].onChange);
        }
      });
      const {
        pathname
      } = window.location;
      this._memberKeyByLocation[pathname] = memberKey;

      // We ignore empty values to prevent overwriting potential value with empty (happens when only defaultValue is used)
      this._getAndStoreUrlValues(false, true);

      // Set options defaultValues to query-param service as initalValue (when no url value is present)
      Object.keys(options).forEach(queryParam => {
        if (this._queryParams[memberKey][queryParam].getValue() === undefined && options[queryParam] && options[queryParam].defaultValue) {
          this._queryParams[memberKey][queryParam].setValue(options[queryParam].defaultValue, false, false);
        }
      });
    },
    /**
     * Function to unregister a registered member
     *
     * @function unregister
     * @param {String} memberKey
     */
    unregister(memberKey) {
      // Iterate each query params and remove onChange listener of the member
      const queryParams = this._queryParams && this._queryParams[memberKey] && Object.keys(this._queryParams[memberKey]);
      if (queryParams) {
        queryParams.forEach(queryParamKey => {
          var _this$_queryParams;
          const queryParam = (_this$_queryParams = this._queryParams) === null || _this$_queryParams === void 0 || (_this$_queryParams = _this$_queryParams[memberKey]) === null || _this$_queryParams === void 0 ? void 0 : _this$_queryParams[queryParamKey];
          queryParam.removeOnChangeCallback(memberKey);

          // When no more listeners exists we can destroy the query-param object as its not needed anymore
          if (Object.keys(queryParam === null || queryParam === void 0 ? void 0 : queryParam._onChangeCallbacks).length === 0) {
            queryParam.destroy();
          }
        });
        delete this._queryParams[memberKey];
      }
      delete this._members[memberKey];
    },
    /**
     * Function to reset all members query-params
     *
     * @function reset
     * @param {String} memberKey
     * @param {Boolean} notify  Whether it should notify registered listener that value did change
     * @param {Boolean} history Whether it should push into browsers history
     */
    reset(memberKey) {
      let notify = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      let history = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      Object.keys(this._members[memberKey] || {}).forEach(queryParamKey => {
        const queryParam = this._queryParams[memberKey][queryParamKey];
        queryParam && queryParam.setValue(undefined, notify, false);
      });
      if (history) {
        const {
          origin,
          pathname
        } = window.location;
        // Bulk state change, to prevent multiple history-pushes due to resetting each param
        window.history.pushState({}, document.title, origin + pathname);
      }
    },
    /**
     * Function to register browsers onpopstate event to internal handler
     *
     * @function _setup
     * @private
     */
    _setup() {
      if (!window || !this._hasFeatureSupport) {
        return;
      }
      window.onpopstate = this._onPopState.bind(this);
    },
    /**
     * Uses the current location pathname to find the active member key
     *
     * @function _getMemberKeyByLocation
     * @return {String} memberKey
     * @private
     */
    _getMemberKeyByLocation() {
      const {
        pathname
      } = window.location;
      return this._memberKeyByLocation[pathname];
    },
    /**
     * Function with greps current url search params and
     * set it as value to the registered query params
     *
     * @function _getAndStoreUrlValues
     * @param {Boolean} notify Whether it should notify members of change
     * @param {Boolean} ignoreEmpty Whether it should apply empty url value
     */
    _getAndStoreUrlValues() {
      let notify = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let ignoreEmpty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (Object.keys(this._queryParams).length === 0) {
        return;
      }
      const urlSearchParams = new URLSearchParams(window.location.search);
      const memberKey = this._getMemberKeyByLocation();
      Object.keys(this._queryParams[memberKey]).forEach(queryParamKey => {
        const paramValues = urlSearchParams.getAll(queryParamKey);
        let value = paramValues.length === 1 ? paramValues[0] : paramValues;
        value = value.length === 0 ? undefined : value;
        if (!ignoreEmpty || !(0, _utils.isNone)(value)) {
          this._queryParams[memberKey][queryParamKey].setValue((0, _queryParamUtil.normalizeQueryParamValue)(value), notify, false);
        }
      });
    },
    /**
     * Browsers onpopstate event handler for this service.
     *
     * @function _onPopState
     * @private
     */
    _onPopState() {
      this._getAndStoreUrlValues(true);
    }
  });
});