define("@additive-apps/configurator/services/ae-save-stack", ["exports", "@ember/service", "@glimmer/tracking", "ember-inflector", "@ember/string"], function (_exports, _service, _tracking, _emberInflector, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AeSaveStackService = _exports.default = (_class = class AeSaveStackService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      /**
       * Model names that can be grouped in a save batch request
       */
      _initializerDefineProperty(this, "batchModels", _descriptor3, this);
      /**
       * Request headers and adapterOptions for the batch request
       */
      _initializerDefineProperty(this, "requestOptions", _descriptor4, this);
      _initializerDefineProperty(this, "_stack", _descriptor5, this);
      _initializerDefineProperty(this, "errors", _descriptor6, this);
    }
    get isDirty() {
      return this._stack.length > 0;
    }
    reset() {
      this._stack = [];
    }
    pushChanges(model) {
      // reset error if model is changed
      const m = this._stack.find(m => m.id === model.id);
      if (m && this.errors.length) {
        this.errors = [];
      }
      const stack = this._stack.filter(m => m.id !== model.id);
      stack.push(model);
      this._stack = [...stack];
    }
    async save() {
      const allErrors = [];
      const requests = [];
      // build batch requests
      this.batchModels.forEach(bm => {
        const models = this._stack.filter(model => model.constructor.modelName === bm);
        if (!models.length) {
          return;
        }
        const adapter = this.store.adapterFor(bm);
        const url = adapter.urlForMultiple(bm, {
          adapterOptions: this.requestOptions.adapterOptions
        });
        const key = (0, _string.camelize)((0, _emberInflector.pluralize)(bm));
        const request = this.authenticatedFetch.fetch(url, {
          headers: this.requestOptions.headers,
          method: 'PUT'
        }, {
          [key]: models.map(model => model.serialize({
            includeId: true
          }))
        });
        requests.push(request);
      });

      // add non batch requests
      const nonBatchModels = this._stack.filter(m => this.batchModels.indexOf(m.constructor.modelName) < 0);
      const nonBatchRequests = nonBatchModels.map(m => m.save());
      const responses = await Promise.all(requests.concat(nonBatchRequests));
      const errors = responses.filter(r => (r === null || r === void 0 ? void 0 : r.status) >= 400);
      if (errors.length > 0) {
        const error = errors[0];
        // dont handle server errors
        if (error.status === 500) {
          throw error;
        }

        // iterate through all errors and push into array
        const {
          errors: response
        } = await error.json();
        Object.keys(response).forEach(key => {
          response[key].forEach(err => {
            allErrors.push(err);
          });
        });
        this.errors = allErrors;
        if (this.errors.length) {
          throw allErrors;
        }
      }

      // push changes into store
      const successfulResponses = await Promise.all(responses.filter(r => r.ok).map(r => r.json()));
      successfulResponses.map(r => {
        this.store.pushPayload(r);
      });
      this._stack = [];
      return responses;
    }
    discardChanges() {
      this.errors = [];
      this._stack.forEach(m => m.rollbackAttributes());
      this._stack = [];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "batchModels", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "requestOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_stack", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _class);
});