define("@additive-apps/ui/components/grid", ["exports", "@ember/component", "@additive-apps/ui/templates/components/grid", "@ember/object", "@ember/string", "@ember/runloop", "@ember/utils"], function (_exports, _component, _grid, _object, _string, _runloop, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component which generates css-grids for different breakpoint, if needed.
   *
   * ```hbs
   *  <Grid columns=3>
   *    ...children
   *  </Grid>
   * ```
   *
   * will generate a 3-column layout for all breakpoints.
   *
   * To define different values for each breakpoint, the values can be passed
   * for each one by separating them by comma, i.e.:
   * ```hbs
   *  <Grid
   *    columns=1,2,3
   *    gap=8,16
   *  >
   *    ...children
   *  </Grid>
   * ``` will generate:
   *  - a 1-column grid for mobile, with a 8px gap
   *  - a 2 column grid for medium screens with a 16px gap
   *  - a 3 column grid for large screen with a 16px gap
   *
   * Same behavior when using `columnGap` or `rowGap` (in addition or without `gap`).
   *
   * The global breakpoints are:
   *  - < 600px for sm
   *  - < 960px for md
   *  - >= 960px for lg
   *
   * @class Grid
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _grid.default,
    classNames: ['grid w-100 flex-wrap'],
    attributeBindings: ['style'],
    /**
     * The number of columns
     * Allowed values are a integer or up to 3, comma-separeted, integers
     *
     * @argument columns
     * @type {Integer|String}
     * @default 3
     */
    columns: 3,
    /**
     * The gap between elements in our grid
     * Allowed values are a integer or up to 3, comma-separeted, integers
     *
     * @argument columnGap
     * @type {Integer|String}
     * @default null
     */
    columnGap: null,
    /**
     * The gap between elements in our grid
     * Allowed values are a integer or up to 3, comma-separeted, integers
     *
     * @argument columns
     * @type {Integer|String}
     * @default 24
     */
    gap: 24,
    /**
     * The gap between elements in our grid
     * Allowed values are a integer or up to 3, comma-separeted, integers
     *
     * @argument rowGap
     * @type {Integer|String}
     * @default null
     */
    rowGap: null,
    /**
     * The column count for sm, md, and lg screens
     *
     * @property _columns
     * @type {Integer[]}
     * @default null
     * @private
     */
    _columns: null,
    /**
     * The column gap between children for sm, md, and lg screens
     *
     * @property _columnGaps
     * @type {Integer[]}
     * @default null
     * @private
     */
    _columnGaps: null,
    /**
     * The gap between children for sm, md, and lg screens
     *
     * @property _gaps
     * @type {Integer[]}
     * @default null
     * @private
     */
    _gaps: null,
    /**
     * The row gap between children for sm, md, and lg screens
     *
     * @property _rowGaps
     * @type {Integer[]}
     * @default null
     * @private
     */
    _rowGaps: null,
    init() {
      this._super(...arguments);
      this._fillGridProperties();
      this._buildInlineStyles();
      this.resizeListener = (0, _runloop.bind)(this, this._buildInlineStyles);
      window.addEventListener('resize', this.resizeListener, false);
    },
    /**
     * Takes the passed columns and gap props and fills them up for each screensize
     * if the passed arguments aren't complete.
     *
     * We expect both props to have exactly 3 items, each one representing a
     * breakpoint in our design-system. If there is one value missing, we fill the array up
     * with the last defined one.
     *
     * i.e.:
     *   - 1,2 => [1,2,3]
     *   - 2 => [2,2,2]
     *
     * @function _fillGridProperties
     * @private
     * @return {void}
     */
    _fillGridProperties() {
      const _columns = this.columns && `${this.columns}`.split(',');
      const _columnGaps = this.columnGap && `${this.columnGap}`.split(',');
      const _gaps = this.gap && `${this.gap}`.split(',');
      const _rowGaps = this.rowGap && `${this.rowGap}`.split(',');
      for (let i = 0; i < 3; i++) {
        _columns && !_columns[i] ? _columns[i] = _columns[i - 1] : null;
        _columnGaps && !_columnGaps[i] ? _columnGaps[i] = _columnGaps[i - 1] : null;
        _gaps && !_gaps[i] ? _gaps[i] = _gaps[i - 1] : null;
        _rowGaps && !_rowGaps[i] ? _rowGaps[i] = _rowGaps[i - 1] : null;
      }
      (0, _object.setProperties)(this, {
        _columns,
        _columnGaps,
        _gaps,
        _rowGaps
      });
    },
    /**
     * Builds the inline styles to represent the grid.
     *
     * @function _buildInlineStyles
     * @private
     * @return {void}
     */
    _buildInlineStyles() {
      const sizeIndex = this._getSizeIndex();
      const gridTemplateColumns = `grid-template-columns: repeat(${this._columns[sizeIndex] || 'auto-fit'}, minmax(148px, 1fr));`;
      let gap = '';
      if ((0, _utils.isPresent)(this._gaps)) {
        gap = `gap: ${this._gaps[sizeIndex]}px;`;
      }
      let rowGap = '';
      if ((0, _utils.isPresent)(this._rowGaps)) {
        rowGap = `row-gap: ${this._rowGaps[sizeIndex]}px;`;
      }
      let columnGap = '';
      if ((0, _utils.isPresent)(this._columnGaps)) {
        columnGap = `column-gap: ${this._columnGaps[sizeIndex]}px;`;
      }
      const inlineStyles = (0, _string.htmlSafe)(`${gridTemplateColumns}${gap}${rowGap}${columnGap}`);
      (0, _object.set)(this, 'style', inlineStyles);
    },
    /**
     * Returns one of 0,1 or 2. Each of these represents the index for the individual
     * screensizes defined by our global breakpoints.
     *
     * @function _getSizeIndex
     * @return {Integer} the index of our internal screensize-def.
     * @private
     */
    _getSizeIndex() {
      return window.matchMedia('(max-width: 599px)').matches ? 0 : window.matchMedia('(max-width: 959px)').matches ? 1 : 2;
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  });
});