define("@additive-apps/auth/mixins/auth-route-mixin", ["exports", "@ember/object/mixin", "@ember/object", "@additive-apps/auth/utils/url", "@ember/application", "@ember/service"], function (_exports, _mixin, _object, _url, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ember/no-new-mixins: 0 */
  /**
   *  The mixin for the first route for which the user needs to be authenticated to access.
   *
   *  Before the model is loaded it verfifies that the user is authenticated, if he is not
   *  the user is redirected to the login with the callback parameters and then redirected
   *  back to the route he was trying to access before.
   *
   *  @mixin AuthRouteMixin
   *  @module additive-auth/mixins/auth-route-mixin
   *  @extends Ember.Mixin
   *  @public
   */
  var _default = _exports.default = _mixin.default.create({
    authUtils: (0, _service.inject)(),
    session: (0, _service.inject)(),
    router: (0, _service.inject)(),
    /**
     * The route for the authentication.
     * Handled by simple-auth.
     *
     * @property authenticationRoute
     * @type String
     * @default 'auth.login'
     */
    authenticationRoute: 'auth.login',
    /**
     * ENV config of the aplication
     *
     * @property ENV
     * @type {Object}
     */
    ENV: (0, _object.computed)({
      get() {
        return (0, _application.getOwner)(this).resolveRegistration('config:environment');
      }
    }),
    /**
     * The uri to which the user is redirected back after the login.
     * Needs to be overwritten.
     *
     * @property redirectUri
     * @type String
     */
    redirectUri: (0, _object.computed)('ENV.APP.{baseHost,redirectRoute}', {
      get() {
        var _this$ENV, _this$ENV2;
        const baseUrl = this.ENV.environment === 'staging' ? window.location.origin : (_this$ENV = this.ENV) === null || _this$ENV === void 0 || (_this$ENV = _this$ENV.APP) === null || _this$ENV === void 0 ? void 0 : _this$ENV.baseHost;
        return `${baseUrl}${(_this$ENV2 = this.ENV) === null || _this$ENV2 === void 0 || (_this$ENV2 = _this$ENV2.APP) === null || _this$ENV2 === void 0 ? void 0 : _this$ENV2.redirectRoute}`;
      }
    }),
    /**
     * If the session is not authenticated the user is redirected to the login.
     * After successfull authentication he is redirected back to the same route.
     */
    beforeModel(transition) {
      this._super(...arguments);
      const {
        targetName
      } = transition;

      // don't redirect if callback route
      if (targetName !== 'auth.callback') {
        var _this$session;
        let routingParams = {};
        if (transition.to) {
          let _routingParams = {};
          this._getParamsByRouteInfo(transition.to, _routingParams);
          // As we start at current route and recursivley bubble routes up we have to reverse to start at first route param
          Object.keys(_routingParams).reverse().forEach(key => {
            routingParams[key] = _routingParams[key];
          });
        } else {
          this._getParamsByTransition(transition, routingParams);
        }

        // cache the routename and params in order to restore them in case the session is invalidated
        this.session._stateParams = {
          targetName,
          routingParams
        };
        if (!((_this$session = this.session) !== null && _this$session !== void 0 && _this$session.isAuthenticated)) {
          var _this$ENV3, _this$ENV4;
          let stateParams = {};
          if (window.location.search.substring(1)) {
            stateParams = (0, _url.objectToQueryString)({
              continue: {
                route: `${targetName}`,
                params: routingParams,
                query: `${window.location.search.substring(1)}`
              }
            });
          } else {
            stateParams = (0, _url.objectToQueryString)({
              continue: {
                route: `${targetName}`,
                params: routingParams
              }
            });
          }
          const queryParams = (0, _url.objectToQueryString)({
            response_type: 'code',
            client_id: `${(_this$ENV3 = this.ENV) === null || _this$ENV3 === void 0 || (_this$ENV3 = _this$ENV3.APP) === null || _this$ENV3 === void 0 ? void 0 : _this$ENV3.clientId}`,
            redirect_uri: this.redirectUri,
            state: stateParams
          });
          const uri = '/oauth/authorize';
          const redirectUrl = `${(_this$ENV4 = this.ENV) === null || _this$ENV4 === void 0 || (_this$ENV4 = _this$ENV4.APP) === null || _this$ENV4 === void 0 ? void 0 : _this$ENV4.accountApiHost}${uri}?${queryParams}`;
          this.authUtils.redirectToUrl(redirectUrl);
        }
      }
    },
    /**
     * Goes through the routeInfo objects recursively using the parent pointer
     * and adds the params to our routingParams object.
     *
     * Only works with ember >= 3.6 (RouteInfo does not exist prior 3.6)
     *
     * @function _getParamsByRouteInfo
     */
    _getParamsByRouteInfo(routeInfo, routingParams) {
      if (!routeInfo) {
        return;
      }
      if (Object.keys(routeInfo.params).length > 0) {
        Object.assign(routingParams, routeInfo.params);
      }
      return this._getParamsByRouteInfo(routeInfo.parent, routingParams);
    },
    /**
     * Goes through the transitions.params objects and adds the params to our routingParams object.
     *
     * @deprecated Only for ember < 3.6
     *
     * @function _getParamsByTransition
     */
    _getParamsByTransition(transition, routingParams) {
      if (!transition || !transition.params) {
        return;
      }
      const params = transition.params;
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach(key => {
          Object.assign(routingParams, params[key]);
        });
      }
      return;
    }
  });
});