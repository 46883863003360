define("@additive-apps/media/components/mm-medium-item/medium-container", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-medium-item/medium-container", "@ember/object", "@ember/object/computed", "@additive-apps/media/classes/video-control"], function (_exports, _component, _mediumContainer, _object, _computed, _videoControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Sub component of mm-medium-item to render image/video depending on provided medium type .
   *
   *```hbs
   *{{mm-medium-item/medium-container
   *    medium=medium
   *    defaultSize=defaultSize
   *    mediaSets=mediaSets
   *    sizeSets=sizeSets
   *    onImageLoad=(action "onImageLoad")
   *    onImageError=(action "onImageError")
   *}}
   *```
   *
   * @class medium-container
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mediumContainer.default,
    classNames: ['medium-container', 'flex flex-row justify-center items-center', 'relative', 'w-100 h-100'],
    /**
     * Image's default size (beside srcSets)
     *
     * @property defaultSize
     * @type {String}
     */
    defaultSize: null,
    /**
     * `<picture>`'s media-sets
     *
     * @property mediaSets
     * @type {Hash}
     */
    mediaSets: null,
    /**
     * The medium model
     *
     * @property medium
     * @type {Medium}
     */
    medium: null,
    /**
     * `<picture>`'s size-sets
     *
     * @property sizeSets
     * @type {Hash}
     */
    sizeSets: null,
    /**
     * Whether videos should be shown + controlls not only thumbnail
     *
     * @property withVideo
     * @type {Boolean}
     */
    withVideo: false,
    /**
     * Whether the medium is loading
     *
     * @property _isLoading
     * @type {Boolean}
     * @default false
     * @private
     */
    _isLoading: false,
    /**
     * Whether the medium has an load error
     *
     * @property _hasLoadError
     * @type {Boolean}
     * @default false
     * @private
     */
    _hasLoadError: false,
    /**
     * whether its a youtube video
     *
     * @computed _isYoutube
     * @type {Boolean}
     */
    _isYoutube: (0, _computed.equal)('medium.mimeType', 'video/x-youtube'),
    /**
     * Image did load event
     *
     * @function onImageLoad
     */
    onImageLoad() {},
    /**
     * Image load error event
     *
     * @function onImageError
     */
    onImageError() {},
    /**
     * Video started event
     *
     * @function onVideoStarted
     * @param {VideoControl} videoControl VideoControl instance for the current medium
     */
    onVideoStarted(/*videoControl*/) {},
    didInsertElement() {
      var _this$medium;
      this._super(...arguments);
      if ((_this$medium = this.medium) !== null && _this$medium !== void 0 && _this$medium.isImage) {
        (0, _object.set)(this, '_isLoading', true);
      }
    },
    actions: {
      onImageLoad() {
        if (this.isDestroying) {
          return;
        }
        (0, _object.set)(this, '_isLoading', false);
        (0, _object.set)(this, '_hasLoadError', false);
        this.onImageLoad();
      },
      onImageError() {
        if (this.isDestroying) {
          return;
        }
        (0, _object.set)(this, '_isLoading', false);
        (0, _object.set)(this, '_hasLoadError', true);
        this.onImageError();
      },
      /**
       * When the user started the video.
       *
       * Create a VideoControl class for the specific video provider
       * and pass it to the onVideoStarted event.
       *
       * @function
       */
      _onVideoStarted() {
        if (this._isYoutube) {
          const [player] = arguments;
          const youtubeVideoControl = new _videoControl.default({
            start: () => player.playVideo(),
            stop: () => player.stopVideo()
          });
          return this.onVideoStarted(youtubeVideoControl);
        }
        this.onVideoStarted(...arguments);
      }
    }
  });
});