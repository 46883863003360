define("@additive-apps/auth/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * exports the route "auth/callback" to be used inside the apps
   */
  function _default(router) {
    router.route('callback');
  }
});