define("@additive-apps/ui/components/ui-dropzone", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _object, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-dropzone-container cpointer flex flex-column {{@class}} {{@classNames}}"
    ...attributes
    role="button"
    {{on "click" this.click}}
  >
    {{#if this.label}}
      <div class="ph1 font-xs pt2">
        {{! template-lint-disable no-nested-interactive }}
        <label class="secondary ph05">
          {{this.label}}
        </label>
      </div>
    {{/if}}
    <div
      class="ui-dropzone
    flex flex-column items-center justify-center flex-shrink-0 flex-grow-1
    font-sm blue w-100 br1"
    >
      {{yield}}
  
      {{this.text}}
    </div>
  </div>
  */
  {
    "id": "hh5UF6FW",
    "block": "[[[11,0],[16,0,[29,[\"ui-dropzone-container cpointer flex flex-column \",[30,1],\" \",[30,2]]]],[17,3],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,0],[14,0,\"ph1 font-xs pt2\"],[12],[1,\"\\n\"],[1,\"      \"],[10,\"label\"],[14,0,\"secondary ph05\"],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"ui-dropzone\\n  flex flex-column items-center justify-center flex-shrink-0 flex-grow-1\\n  font-sm blue w-100 br1\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n\\n    \"],[1,[30,0,[\"text\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-dropzone.hbs",
    "isStrictMode": false
  });
  /**
   * Component which renders a styled dropzone container
   * including `label` and `text`.
   *
   * @class ui-dropzone
   */
  let UiDropzoneComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiDropzoneComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Label above dropzone (optional)
       *
       * @property label
       * @type {String}
       */
      _initializerDefineProperty(this, "label", _descriptor, this);
      /**
       * Text inside dropzone
       *
       * @property text
       * @type {String}
       */
      _initializerDefineProperty(this, "text", _descriptor2, this);
      /**
       * On click event
       *
       * @function onClick
       */
      _initializerDefineProperty(this, "onClick", _descriptor3, this);
    }
    click() {
      this.onClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "text", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDropzoneComponent);
});