define("@additive-apps/ui/components/ui-skeleton-card", ["exports", "@ember/component", "@ember/object", "@ember/string", "@additive-apps/ui/templates/components/ui-skeleton-card"], function (_exports, _component, _object, _string, _uiSkeletonCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSkeletonCard.default,
    classNames: ['ui-skeleton-card', 'w-100', 'br1'],
    attributeBindings: ['style'],
    init() {
      this._super(...arguments);
      const index = this.index;
      const height = this.cardHeight;
      (0, _object.set)(this, 'style', (0, _string.htmlSafe)(`animation-delay: ${index * 0.2}s;height: ${height}px;`));
    }
  });
});