define("@additive-apps/ui/components/ui-card/header/content/image", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="content image - flex-auto flex items-center justify-center" style={{this.style}}>
    {{#unless @url}}
      {{svg-jar "placeholder-image" class="placeholder"}}
    {{/unless}}
  </div>
  */
  {
    "id": "ZuD3gdvu",
    "block": "[[[10,0],[14,0,\"content image - flex-auto flex items-center justify-center\"],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[1,[28,[35,1],[\"placeholder-image\"],[[\"class\"],[\"placeholder\"]]]],[1,\"\\n\"]],[]],null],[13]],[\"@url\"],false,[\"unless\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/content/image.hbs",
    "isStrictMode": false
  });
  class UiCardHeaderContentImage extends _component2.default {
    get style() {
      let {
        args: {
          url
        }
      } = this;
      if (!url) {
        return null;
      }
      return (0, _template.htmlSafe)(`background-image: url("${url}")`);
    }
  }
  _exports.default = UiCardHeaderContentImage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCardHeaderContentImage);
});