define("@additive-apps/ui/components/ui-toast", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-toast", "@ember/object", "@ember/utils", "@ember/runloop", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _uiToast, _object, _utils, _runloop, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components

  const rootClass = 'ui-toast--animating';

  /**
    A component that renders a toast
  
    @class ui-toast
    @public
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiToast.default,
    tagName: '',
    /**
     * Object containing all necessary data to display a progress bar
     * @property progress
     * @type {Object}
     * @default undefined
     */
    progress: undefined,
    /**
     * additional custom styles for the toast
     *
     * @property hasCustomParent
     * @type {Boolean}
     * @default false
     */
    hasCustomParent: false,
    /**
     * The parent DOM element
     * @computed _destinationEl
     * @type {Element}
     * @private
     */
    _destinationEl: (0, _object.computed)('parent', {
      get() {
        const el = document.querySelector(this.parent);
        return el;
      }
    }),
    /**
     * Computed property indicating whether toast has progress bar or not
     * @computed _hasProgressBar
     * @type {Boolean}
     * @private
     */
    _hasProgressBar: (0, _object.computed)('progress.progress', {
      get() {
        return this.progress && !(0, _utils.isEmpty)(this.progress.progress);
      }
    }),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willInsertElement() {
      this._super(...arguments);
      if (!this.parent) {
        throw new Error('[UI-TOAST] parent missing');
      }
      const element = document.querySelector(this.parent);
      const rootClassList = element ? element.classList : null;

      // set animation class to parent
      if (rootClassList && !rootClassList.contains(rootClass)) {
        rootClassList.add(rootClass);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      const duration = this.duration || 2000;
      (0, _domUtil.nextTick)().then(() => {
        (0, _object.set)(this, 'entering', true);
      });
      if (duration !== -1) {
        _runloop.run.later(this, 'destroyMessage', duration);
      }
    },
    destroyMessage() {
      if (!this.isDestroyed) {
        this.onClose();
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      var _document$querySelect;
      this._super(...arguments);
      const rootClassList = this.parent && ((_document$querySelect = document.querySelector(this.parent)) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList);
      if (rootClassList !== null && rootClassList !== void 0 && rootClassList.contains(rootClass)) {
        rootClassList.remove(rootClass);
      }
    },
    onAbort() {},
    onClick() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onAbort() {
        this.onAbort();
      },
      onClick() {
        this.onClick();
      }
    }
  });
});