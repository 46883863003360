define("@additive-apps/configurator/components/panel-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/function-util"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes, _functionUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pt4 {{@class}} {{@classNames}}">
    <div class="w-100 flex justify-between align-center font-sm primary pb2">
      {{this.title}}
    </div>
  
    <div class="flex flex-column gap1">
      {{yield (hash item=(component "panel"))}}
    </div>
  
    {{#if (and this._hasButtonCallback this.buttonText)}}
      <UiButton @classNames="mt05" @isSecondary={{true}} onClick={{this.onButtonClick}}>
        {{this.buttonText}}
      </UiButton>
    {{/if}}
  </div>
  */
  {
    "id": "q0T/AGY4",
    "block": "[[[10,0],[15,0,[29,[\"pt4 \",[30,1],\" \",[30,2]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-100 flex justify-between align-center font-sm primary pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex flex-column gap1\"],[12],[1,\"\\n    \"],[18,3,[[28,[37,1],null,[[\"item\"],[[50,\"panel\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,4],[[30,0,[\"_hasButtonCallback\"]],[30,0,[\"buttonText\"]]],null],[[[1,\"    \"],[8,[39,5],[[16,\"onClick\",[30,0,[\"onButtonClick\"]]]],[[\"@classNames\",\"@isSecondary\"],[\"mt05\",true]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"buttonText\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@classNames\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\",\"and\",\"ui-button\"]]",
    "moduleName": "@additive-apps/configurator/components/panel-group.hbs",
    "isStrictMode": false
  });
  let PanelGroup = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class PanelGroup extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the title of the panel group
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * the text of the button
       *
       * @argument buttonText
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "buttonText", _descriptor2, this);
      /**
       * callback that is triggered on button click
       *
       * @function onButtonClick
       */
      _initializerDefineProperty(this, "onButtonClick", _descriptor3, this);
    }
    /**
     * whether a button callback is set
     *
     * @computed _hasButtonCallback
     */
    get _hasButtonCallback() {
      return (0, _functionUtil.hasBody)(this.onButtonClick);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "buttonText", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PanelGroup);
});