define("@additive-apps/ui/components/ui-skeleton", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-skeleton", "@ember/object", "@ember/string", "@ember/runloop"], function (_exports, _component, _uiSkeleton, _object, _string, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSkeleton.default,
    classNames: ['ui-skeleton'],
    attributeBindings: ['style'],
    // defaults
    itemsCount: 3,
    itemsHeight: 280,
    // properties
    gap: 24,
    items: null,
    itemsSmartphone: null,
    itemsPerRow: null,
    itemsPerRowSmartphone: null,
    cardHeight: null,
    cardHeightSmartphone: null,
    init() {
      this._super(...arguments);
      this._buildInlineStyles();
      this.resizeListener = (0, _runloop.bind)(this, this._buildInlineStyles);
      window.addEventListener('resize', this.resizeListener, false);
    },
    _buildInlineStyles() {
      const isSm = this.isSm();
      const itemsCount = isSm ? this.itemsSmartphone || this.items : this.items;
      const cardHeight = isSm ? this.cardHeightSmartphone || this.cardHeight || 160 : this.cardHeight;
      (0, _object.set)(this, 'itemsHeight', cardHeight);
      (0, _object.set)(this, 'itemsCount', itemsCount || 3);
      let gridTemplateColumns = `grid-template-columns: repeat(${this.itemsPerRow || 'auto-fit'}, minmax(148px, 1fr));`;
      if (isSm) {
        const itemsPerRow = this.itemsPerRowSmartphone || this.itemsPerRow || 'auto-fit';
        gridTemplateColumns = `grid-template-columns: repeat(${itemsPerRow}, minmax(80px, 1fr));`;
      }
      const gap = `gap: ${this.gap}px;`;
      const spacing = `padding-bottom: ${this.gap}px;`;
      const inlineStyles = (0, _string.htmlSafe)(`${gridTemplateColumns}${gap}${spacing}`);
      (0, _object.set)(this, 'style', inlineStyles);
    },
    isSm() {
      return !window.matchMedia('(min-width: 600px)').matches;
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  });
});