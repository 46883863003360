define("@additive-apps/media/templates/components/mm-multimedia-overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sf+bkJo9",
    "block": "[[[41,[30,0,[\"isMultimediaOverlay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@size\",\"@theme\",\"@modalClassNames\",\"@isLoading\",\"@onClose\"],[\"lg\",\"grey\",\"mm-multimedia-overlay\",[52,[30,0,[\"_isEngineLoaded\"]],false,true],[28,[37,2],[[30,0],[30,0,[\"multimedia\",\"closeMultimedia\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"multimedia\",\"isFilterMode\"]]],[[[1,\"      \"],[8,[30,1,[\"header\"]],null,[[\"@title\"],[[52,[30,0,[\"_isEngineLoaded\"]],[50,\"mm-folder-tree\",0,null,[[\"folderRouteName\"],[[28,[37,5],[[30,0,[\"_multimediaBaseRouteName\"]],\".folder\"],null]]]],\"\"]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"action\"]],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"search\",[28,[37,6],[\"uiActions.search\"],null],[28,[37,2],[[30,0],[30,0,[\"_openFilter\"]]],null]]],null],[1,\"\\n        \\n\"],[41,[30,0,[\"_hasDownload\"]],[[[1,\"          \"],[8,[30,2,[\"action\"]],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"download\",[28,[37,6],[\"mmBulkDownload.download\"],null],[28,[37,2],[[30,0],\"onDownloadFolder\",[30,0,[\"multimedia\",\"currentFolder\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[2]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n    \"],[8,[30,1,[\"fixedBottom\"]],null,null,null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],null]],[\"modal\",\"header\",\"content\",\"&default\"],false,[\"if\",\"ui-modal\",\"action\",\"unless\",\"component\",\"concat\",\"t\",\"yield\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-multimedia-overlay.hbs",
    "isStrictMode": false
  });
});