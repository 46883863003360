define("@additive-apps/ui/components/ui-table/head", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-table/head", "@ember/object", "@ember/utils", "@additive-apps/ui/utils/sort-util", "@additive-apps/ui/utils/constants"], function (_exports, _component, _head, _object, _utils, _sortUtil, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _head.default,
    //eslint-disable-next-line ember/require-tagless-components
    tagName: 'thead',
    classNames: ['ui-table__head', 'secondary', 'bg-white'],
    /**
     * the header columns
     *
     ```js
     *  [{
     *      name: String,
     *      key: String,
     *      description: String,
     *      minWidth: String,
     *      classNames: String,
     *      sort: String,
     *      compare: Function
     *  }]
     * ```
     *
     * @property columns
     * @type {Object[]}
     * @default undefined
     */
    columns: undefined,
    /**
     * the objects containing the values of the rows
     *
     * @property rows
     * @type {Object[]}
     * @default undefined
     */
    rows: undefined,
    /**
     * header actions are disabled while loading or if
     * an error occurs
     *
     * @property isDisabled
     * @type {Boolean}
     * @default false
     */
    isDisabled: false,
    /**
     * the key of the column which should be used
     * to sort the rows
     *
     * @property initialSortKey
     * @type {String}
     * @default null
     */
    initialSortKey: null,
    /**
     * the initial sort direction
     *
     * @property initialSortDirection
     * @type {String}
     * @default null
     */
    initialSortDirection: null,
    /**
     * the previous value of the rows, used to
     * trigger the initial sort
     *
     * @property rows
     * @type {Object[]}
     * @default undefined
     * @private
     */
    _prevRows: undefined,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_sortState', {});
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didReceiveAttrs() {
      this._super(...arguments);
      const {
        columns,
        rows,
        _prevRows,
        initialSortKey,
        initialSortDirection
      } = this;

      // once the rows are available the rows are sorted if the initialSortKey is specified
      if (columns && rows && !_prevRows && (0, _utils.isPresent)(initialSortKey)) {
        const direction = initialSortDirection || _constants.SORT_DIRECTIONS.ASCENDING;
        const sortColumn = this.columns.find(col => col.key === initialSortKey);
        sortColumn && this.send('onSort', sortColumn, direction, true);
      }
      (0, _object.set)(this, '_prevRows', this.rows);
    },
    onSort() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onSort(column) {
        let direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        let initial = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        if (!this.isDisabled) {
          const {
            key
          } = column;
          const sortState = this._sortState;
          const sortDirection = direction || (0, _sortUtil.toggleSortDirection)(sortState[key]);
          (0, _object.set)(this, '_sortState', {
            [key]: sortDirection
          });
          !initial && this.onSort(column, sortDirection);
        }
      }
    }
  });
});