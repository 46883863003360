define("@additive-apps/ui/services/ui-app-settings", ["exports", "@ember/service", "ember-concurrency", "@ember/object", "@ember/application", "@additive-apps/utils/utils/plans", "@additive-apps/ui/utils/app-utils"], function (_exports, _service, _emberConcurrency, _object, _application, _plans, _appUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  This service loads and gives access to the global app settings.
   *
   *  For now it handles the language settings, but should be extended with
   *  active locale and currency settings in the future.
   *
   * @class ui-app-settings
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    /**
     * the organization currency
     *
     * @property currency
     * @type {String}
     * @default undefined
     */
    currency: undefined,
    /**
     * object containing defaultLanguage and contentLanguages settings
     *
     * @property languages
     * @type {Object}
     * @default undefined
     */
    languages: undefined,
    /**
     * the currently active locale
     *
     * @property currentLocale
     * @type {String}
     * @default 'de'
     */
    currentLocale: 'de',
    /**
     * Contains app plan name
     *
     * @property planName
     * @type {String}
     * @default undefined
     */
    planName: undefined,
    /**
     * If the app is marked as managed
     *
     * @property managed
     * @type {Boolean}
     * @default undefined
     */
    managed: undefined,
    /**
     * Whether the organization is managed by ADDITIVE
     *
     * @property managedByAdditive
     * @type {Boolean}
     * @default undefined
     */
    managedByAdditive: undefined,
    /**
     * Whether the organization is managed by the partner
     *
     * @property managedByPartner
     * @type {Boolean}
     * @default undefined
     */
    managedByPartner: undefined,
    modules: undefined,
    /**
     * @computed isProfessionalPlan
     * @return {Boolean} if the user has at least the professional plan of the current app
     */
    isProfessionalPlan: (0, _object.computed)('isEnterprisePlan', 'planName', {
      get() {
        return this.planName === _plans.PLAN_PROFESSIONAL || this.isEnterprisePlan;
      }
    }),
    /**
     * @computed isEnterprisePlan
     * @return {Boolean} if the user has the enterprise plan of the current app
     */
    isEnterprisePlan: (0, _object.computed)('planName', {
      get() {
        return this.planName === _plans.PLAN_ENTERPRISE;
      }
    }),
    init() {
      this._super(...arguments);

      // initialize language object
      (0, _object.set)(this, 'languages', {});
    },
    /**
     *  fetches the languages
     *
     * @function loadContentLanguages
     */
    loadLanguages(headers) {
      if (this.languages.contentLanguages && this.languages.defaultLanguage) return;
      return this.instanceTask.perform(headers);
    },
    /**
     * resets organization languages on organization switch
     *
     * @function resetLanguages
     */
    resetLanguages() {
      (0, _object.set)(this, 'languages', {});
    },
    /**
     * Resets app settings
     *
     * @function reset
     */
    reset() {
      /* TODO: verify if resetLanguages is needed or if the intention of that function was simply to clear this model */
      this.resetLanguages();
      (0, _object.set)(this, 'planName', null);
    },
    /**
     * sets the current locale
     *
     * @function setLocale
     * @param {String} locale
     */
    setLocale(locale) {
      (0, _object.set)(this, 'currentLocale', locale);
    },
    /**
     * task for fetching the the instance in the apps
     *
     * @function instanceTask
     * @type {Task}
     * @param {Object} headers custom headers for request
     */
    instanceTask: (0, _emberConcurrency.task)(function* (headers) {
      var _this$currentUser, _this$currentUser2, _ENV$APP;
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const request = yield this.authenticatedFetch.fetch(`${ENV.APP.apiBaseHost}/${(_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id}`, {
        headers: headers || undefined
      });
      if (request.ok) {
        const {
          organization: {
            contentLanguages,
            currency,
            defaultLanguage,
            planName,
            modules,
            managed
          }
        } = yield request.json();
        (0, _object.set)(this, 'languages.contentLanguages', contentLanguages || []);
        (0, _object.set)(this, 'languages.defaultLanguage', defaultLanguage || 'de');
        (0, _object.set)(this, 'currentLocale', defaultLanguage || 'de');
        (0, _object.set)(this, 'currency', currency || 'EUR');
        (0, _object.set)(this, 'managed', managed || false);
        (0, _object.set)(this, 'modules', modules || []);
        /* Strip uninteresting payment interval: professional_monthly --> professional */
        (0, _object.set)(this, 'planName', (planName || '').replace('_monthly', '').replace('_yearly', ''));
      }

      // Fetch accessible apps
      const apps = yield _appUtils.fetchAccessibleApps.task.perform(this, (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.currentOrganization) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.id);

      // Get current app
      const currentAppId = ENV === null || ENV === void 0 || (_ENV$APP = ENV.APP) === null || _ENV$APP === void 0 ? void 0 : _ENV$APP.appId;
      const app = apps.find(app => app.id === currentAppId);

      // Set managed by properties
      (0, _object.set)(this, 'managedByAdditive', (app === null || app === void 0 ? void 0 : app.managedBy) === 'additive');
      (0, _object.set)(this, 'managedByPartner', (app === null || app === void 0 ? void 0 : app.managedBy) === 'partner');
    })
  });
});