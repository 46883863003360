define("@additive-apps/ui/instance-initializers/ui-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'ui:body',
    initialize() {
      if (typeof navigator !== 'undefined' && typeof document !== 'undefined') {
        var _navigator$userAgent;
        let isMac = (_navigator$userAgent = navigator.userAgent) === null || _navigator$userAgent === void 0 ? void 0 : _navigator$userAgent.includes('Macintosh');
        document.body.dataset.isMac = isMac;
      }
    }
  };
});