define("@additive-apps/auth/authenticators/authorization-code-grant", ["exports", "@ember/service", "@ember/utils", "@ember/runloop", "@ember/debug", "@ember/polyfills", "@ember/application", "ember-simple-auth/authenticators/oauth2-password-grant", "rsvp"], function (_exports, _service, _utils, _runloop, _debug, _polyfills, _application, _oauth2PasswordGrant, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AuthorizationCodeGrant = _exports.default = (_class = class AuthorizationCodeGrant extends _oauth2PasswordGrant.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
    }
    //ENV config is loaded from application
    get ENV() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    get serverTokenEndpoint() {
      var _this$ENV;
      return `${(_this$ENV = this.ENV) === null || _this$ENV === void 0 || (_this$ENV = _this$ENV.APP) === null || _this$ENV === void 0 ? void 0 : _this$ENV.accountApiHost}/oauth/token`;
    }
    get serverInvalidateEndpoint() {
      var _this$ENV2;
      return `${(_this$ENV2 = this.ENV) === null || _this$ENV2 === void 0 || (_this$ENV2 = _this$ENV2.APP) === null || _this$ENV2 === void 0 ? void 0 : _this$ENV2.accountApiHost}/auth/logout`;
    }
    get redirectUri() {
      var _this$ENV3, _this$ENV4, _this$ENV5;
      const baseUrl = ((_this$ENV3 = this.ENV) === null || _this$ENV3 === void 0 ? void 0 : _this$ENV3.environment) === 'staging' ? window.location.origin : (_this$ENV4 = this.ENV) === null || _this$ENV4 === void 0 || (_this$ENV4 = _this$ENV4.APP) === null || _this$ENV4 === void 0 ? void 0 : _this$ENV4.baseHost;
      return `${baseUrl}${(_this$ENV5 = this.ENV) === null || _this$ENV5 === void 0 || (_this$ENV5 = _this$ENV5.APP) === null || _this$ENV5 === void 0 ? void 0 : _this$ENV5.redirectRoute}`;
    }
    authenticate(code) {
      return new _rsvp.default.Promise((resolve, reject) => {
        var _this$ENV6, _this$ENV7;
        this.makeRequest(this.serverTokenEndpoint, {
          grant_type: 'authorization_code',
          client_id: (_this$ENV6 = this.ENV) === null || _this$ENV6 === void 0 || (_this$ENV6 = _this$ENV6.APP) === null || _this$ENV6 === void 0 ? void 0 : _this$ENV6.clientId,
          client_secret: (_this$ENV7 = this.ENV) === null || _this$ENV7 === void 0 || (_this$ENV7 = _this$ENV7.APP) === null || _this$ENV7 === void 0 ? void 0 : _this$ENV7.clientSecret,
          redirect_uri: this.redirectUri,
          code: code
        }).catch(error => {
          reject(error);
        }).then(response => {
          if (response) {
            const expiresAt = this._absolutizeExpirationTime(response['expires_in']);
            this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
            resolve(response);
          } else {
            reject(response);
          }
        });
      });
    }
    invalidate() {
      var _this$authenticatedFe;
      const simpleAuthInvalidatePromise = super.invalidate(...arguments);
      const apiInvalidatePromise = (_this$authenticatedFe = this.authenticatedFetch) === null || _this$authenticatedFe === void 0 ? void 0 : _this$authenticatedFe.fetch(this.serverInvalidateEndpoint, {
        method: 'POST'
      });
      return (0, _rsvp.allSettled)([simpleAuthInvalidatePromise, apiInvalidatePromise]);
    }

    // Following methods are copies with some modifications of the ember-simple-auth password-grant
    _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
      const offset = this.tokenRefreshOffset;
      const now = new Date().getTime();
      if ((0, _utils.isEmpty)(expiresAt) && !(0, _utils.isEmpty)(expiresIn)) {
        expiresAt = new Date(now + expiresIn * 1000).getTime();
      }
      if (!(0, _utils.isEmpty)(refreshToken) && !(0, _utils.isEmpty)(expiresAt) && expiresAt > now - offset) {
        var _this$ENV8;
        _runloop.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        if (((_this$ENV8 = this.ENV) === null || _this$ENV8 === void 0 ? void 0 : _this$ENV8.environment) !== 'test') {
          this._refreshTokenTimeout = _runloop.run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
        }
      }
    }
    _refreshAccessToken(expiresIn, refreshToken) {
      var _this$ENV9, _this$ENV10;
      const tokenEndpoint = this.serverTokenEndpoint;
      const data = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: (_this$ENV9 = this.ENV) === null || _this$ENV9 === void 0 || (_this$ENV9 = _this$ENV9.APP) === null || _this$ENV9 === void 0 ? void 0 : _this$ENV9.clientId,
        client_secret: (_this$ENV10 = this.ENV) === null || _this$ENV10 === void 0 || (_this$ENV10 = _this$ENV10.APP) === null || _this$ENV10 === void 0 ? void 0 : _this$ENV10.clientSecret
      };
      return new _rsvp.default.Promise((resolve, reject) => {
        this.makeRequest(tokenEndpoint, data).then(response => {
          (0, _runloop.run)(() => {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;
            const expiresAt = this._absolutizeExpirationTime(expiresIn);
            const data = (0, _polyfills.assign)(response, {
              expires_in: expiresIn,
              expires_at: expiresAt,
              refresh_token: refreshToken
            });
            this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
            this.trigger('sessionDataUpdated', data);
            resolve(data);
          });
        }, response => {
          (false && (0, _debug.warn)(`Access token could not be refreshed - server responded with ${response.responseJSON}.`, false, {
            id: 'ember-simple-auth.failedOAuth2TokenRefresh'
          }));
          reject();
        });
      });
    }
    _absolutizeExpirationTime(expiresIn) {
      if (!(0, _utils.isEmpty)(expiresIn)) {
        return new Date(new Date().getTime() + expiresIn * 1000).getTime();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});