define("@additive-apps/media/components/mm-cropper-view", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-cropper-view", "@ember/object"], function (_exports, _component, _mmCropperView, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * HOC to handle combination between mm-fullscreen-view and mm-cropper.
   *
   *```hbs
   *{{#mm-cropper-view
   *   onSave=(action "onSave")
   *   onClose=(action "onClose")
   *as |view|}}
   *    {{view.cropper medium=model}}
   *{{/mm-cropper-view}}
   *```
   * @class mm-cropper-view
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmCropperView.default,
    tagName: '',
    /**
     * The current crop data
     * @property _currentCropData
     * @type {Object}
     * @private
     */
    _currentCropData: null,
    /**
     * Whether cropperjs is loading
     * @property _isCropperLoading
     * @type {Boolean}
     * @private
     */
    _isCropperLoading: true,
    /**
     * On close
     * @function onClose
     */
    onClose() {},
    /**
     * On save
     * @function onSave
     * @return {Promise}
     */
    onSave() {},
    actions: {
      onCropDataChanged(newCrop) {
        (0, _object.set)(this, '_currentCropData', newCrop);
      },
      onCropperReady() {
        (0, _object.set)(this, '_isCropperLoading', false);
      },
      onSave() {
        return this.onSave(this._currentCropData);
      }
    }
  });
});