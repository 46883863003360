define("@additive-apps/ui/components/ui-date-filter", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-grow-0 {{@class}}" ...attributes>
    <UiDatepicker
      @label={{t "uiDateFilter.label"}}
      @isPlain={{this.isPlain}}
      @maxDate={{this._maxDate}}
      @minDate={{this.minDate}}
      @isRangePicker={{true}}
      @range={{this.range}}
      @onConfirm={{this.onSelect}}
      @hasFilters={{this.hasFilters}}
      @filters={{this.filters}}
      @defaultFilter={{this._defaultFilter}}
    />
  </div>
  
  */
  {
    "id": "8QRIPg3Z",
    "block": "[[[11,0],[16,0,[29,[\"flex flex-grow-0 \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@label\",\"@isPlain\",\"@maxDate\",\"@minDate\",\"@isRangePicker\",\"@range\",\"@onConfirm\",\"@hasFilters\",\"@filters\",\"@defaultFilter\"],[[28,[37,1],[\"uiDateFilter.label\"],null],[30,0,[\"isPlain\"]],[30,0,[\"_maxDate\"]],[30,0,[\"minDate\"]],true,[30,0,[\"range\"]],[30,0,[\"onSelect\"]],[30,0,[\"hasFilters\"]],[30,0,[\"filters\"]],[30,0,[\"_defaultFilter\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"&attrs\"],false,[\"ui-datepicker\",\"t\"]]",
    "moduleName": "@additive-apps/ui/components/ui-date-filter.hbs",
    "isStrictMode": false
  });
  /**
   * Wrapper for the date range picker with max date set to today
   *
   * @class ui-date-filter
   */
  let UiDateFilterComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.array), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.object), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiDateFilterComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the initial date Range
       *
       * @argument initialRange
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "initialRange", _descriptor, this);
      /**
       * if true, label and error message are not rendered
       *
       * @argument isPlain
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPlain", _descriptor2, this);
      /**
       * The maximum date
       *
       * @argument maxDate
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "maxDate", _descriptor3, this);
      /**
       * The minimum date
       *
       * @argument minDate
       * @type {Date | String}
       * @default null
       */
      _initializerDefineProperty(this, "minDate", _descriptor4, this);
      /**
       * whether the datepicker has filters
       *
       * @argument hasFilters
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasFilters", _descriptor5, this);
      /**
       * the filters applied to the datepicker
       *
       * @argument filters
       * @type {Array}
       * @default ['this_week', 'last_week', 'this_month', 'last_month', 'last_thirty_days', 'custom_date']
       */
      _initializerDefineProperty(this, "filters", _descriptor6, this);
      /**
       * the default filter
       *
       * @argument defaultFilter
       * @type {String | Object}
       * @default null
       */
      _initializerDefineProperty(this, "defaultFilter", _descriptor7, this);
      /**
       * the selected date range
       *
       * @property range
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "range", _descriptor8, this);
      /**
       * internal default filter value
       *
       * @property _defaultFilter
       * @type {String | Object}
       * @default this.defaultFilter
       */
      _initializerDefineProperty(this, "_defaultFilter", _descriptor9, this);
      /**
       * internal value of initialRange
       *
       * @property _initialRange
       * @type {String}
       * @default this.initialRange
       */
      _initializerDefineProperty(this, "_initialRange", _descriptor10, this);
      /**
       * internal value of maxDate
       *
       * @property _maxDate
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "_maxDate", _descriptor11, this);
      /**
       * callback triggered on change
       *
       * @function onChange
       * @param {Object} dateRange
       */
      _initializerDefineProperty(this, "onChange", _descriptor12, this);
      if (this.initialRange) {
        this.range = Object.assign({}, this._initialRange);
      }
    }
    /**
     *
     * @function onSelect
     * @param {Date} start
     * @param {Date} end
     */
    onSelect(start, end, dateFilter) {
      if (start <= end) {
        this.onChange({
          start,
          end,
          dateFilter
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "initialRange", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPlain", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "maxDate", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "minDate", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasFilters", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return ['this_week', 'last_week', 'this_month', 'last_month', 'last_thirty_days', 'custom_date'];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultFilter", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "range", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_defaultFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.defaultFilter;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_initialRange", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.initialRange;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_maxDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return this.maxDate;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDateFilterComponent);
});