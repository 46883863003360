define("@additive-apps/auth/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryStringToObject = _exports.objectToQueryString = void 0;
  const objectToQueryString = object => {
    var pairs = [],
      add = (key, value) => {
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      };
    for (var name in object) {
      buildParam(name, object[name], add);
    }
    return pairs.join('&').replace(/%20/g, '+');
  };
  _exports.objectToQueryString = objectToQueryString;
  const buildParam = (prefix, obj, add) => {
    if (Array.isArray(obj)) {
      for (var i = 0, l = obj.length; i < l; ++i) {
        buildParam(prefix + '[]', obj[i], add);
      }
    } else if (obj && typeof obj === 'object') {
      for (var name in obj) {
        buildParam(prefix + '[' + name + ']', obj[name], add);
      }
    } else {
      add(prefix, obj);
    }
  };

  /**
   *
   *
   * A form-urlencoded string of key-value pairs.
   *
   * Deserialize a query string into an array or object.
   * Takes a string of name value pairs and returns a Object literal that represents those params.
   *
   * ```
   * "foo=bar&number=1234" // -> '{"foo" : "bar", "number": 1234}'
   * "foo[bar]=bar&foo[baz]=baz" // -> '{"foo" : { "bar": "bar", "baz": "baz" }}'
   * "foo[]=bar&foo[]=baz" // -> '{"foo" : ["bar", "baz"]}'
   * "foo=bar%20%26%20baz"  // -> '{"foo" : "bar & baz"}'
   * ```
   */
  const digitTest = /^\d+$/,
    // eslint-disable-next-line
    keyBreaker = /([^\[\]]+)|(\[\])/g,
    paramTest = /([^?#]*)(#.*)?$/,
    entityRegex = /%([^0-9a-f][0-9a-f]|[0-9a-f][^0-9a-f]|[^0-9a-f][^0-9a-f])/i,
    prep = str => {
      str = str.replace(/\+/g, ' ');
      try {
        return decodeURIComponent(str);
      } catch (e) {
        return decodeURIComponent(str.replace(entityRegex, (match, hex) => {
          return '%25' + hex;
        }));
      }
    };
  const isArrayLikeName = name => {
    return digitTest.test(name) || name === '[]';
  };
  const idenity = value => {
    return value;
  };

  /**
   * TODO: Add doc
   * @param {*} params
   * @param {*} valueDeserializer
   */
  const queryStringToObject = (params, valueDeserializer) => {
    valueDeserializer = valueDeserializer || idenity;
    let data = {},
      pairs,
      lastPart;
    if (params && paramTest.test(params)) {
      pairs = params.split('&');
      pairs.forEach(pair => {
        let parts = pair.split('='),
          key = prep(parts.shift()),
          value = prep(parts.join('=')),
          current = data;
        if (key) {
          parts = key.match(keyBreaker);
          for (let j = 0, l = parts.length - 1; j < l; j++) {
            let currentName = parts[j],
              nextName = parts[j + 1],
              currentIsArray = isArrayLikeName(currentName) && current instanceof Array;
            if (!current[currentName]) {
              if (currentIsArray) {
                current.push(isArrayLikeName(nextName) ? [] : {});
              } else {
                // If what we are pointing to looks like an `array`
                current[currentName] = isArrayLikeName(nextName) ? [] : {};
              }
            }
            if (currentIsArray) {
              current = current[current.length - 1];
            } else {
              current = current[currentName];
            }
          }
          lastPart = parts.pop();
          if (isArrayLikeName(lastPart)) {
            current.push(valueDeserializer(value));
          } else {
            current[lastPart] = valueDeserializer(value);
          }
        }
      });
    }
    return data;
  };
  _exports.queryStringToObject = queryStringToObject;
});