define("@additive-apps/ui/components/ui-content-wizard/step", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class="ui-content-wizard__step relative w-100 flex items-center font-sm secondary bg-secondary--10--hover ph3 ohidden cpointer
      {{@class}}
      {{if this._isHighlighted 'blue'}}
      {{if this._isActive 'ui-content-wizard-step__is-active'}}
      {{if this._isVisible 'pv1' 'h0'}}"
    type="button"
    {{on "click" (fn @onClick this._stepSlug)}}
    {{did-insert
      (fn @onDidInsert @title this._stepSlug @position @isTitleEditable @onTitleChange @titlePrefix)
    }}
    {{did-update (fn @onPositionUpdate this._stepSlug @position) @position}}
    {{will-destroy (fn @onDestroy this._stepSlug @parentStep @depth)}}
  >
    <span class="pv05 text-left mr05 ellipsis">
      {{#if @depth}}
        {{#each (loop @depth)}}
          <span class="pl2"></span>
        {{/each}}
      {{/if}}
      {{#if @titlePrefix}}
        {{@titlePrefix}}
      {{/if}}
      {{@title}}
    </span>
    <span class="flex-grow-1"></span>
    {{#if @state}}
      <UiStatus @state={{@state}} />
    {{/if}}
  </button>
  
  {{yield
    (hash
      substep=(component
        "ui-content-wizard/step"
        activeStep=@activeStep
        depth=(inc this.depth)
        parentStep=this._stepSlug
        onDidInsert=@onDidInsert
        onPositionUpdate=@onPositionUpdate
        onDestroy=@onDestroy
        onClick=@onClick
      )
    )
  }}
  
  */
  {
    "id": "1OjC5OVW",
    "block": "[[[11,\"button\"],[16,0,[29,[\"ui-content-wizard__step relative w-100 flex items-center font-sm secondary bg-secondary--10--hover ph3 ohidden cpointer\\n    \",[30,1],\"\\n    \",[52,[30,0,[\"_isHighlighted\"]],\"blue\"],\"\\n    \",[52,[30,0,[\"_isActive\"]],\"ui-content-wizard-step__is-active\"],\"\\n    \",[52,[30,0,[\"_isVisible\"]],\"pv1\",\"h0\"]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,2],[30,0,[\"_stepSlug\"]]],null]],null],[4,[38,3],[[28,[37,2],[[30,3],[30,4],[30,0,[\"_stepSlug\"]],[30,5],[30,6],[30,7],[30,8]],null]],null],[4,[38,4],[[28,[37,2],[[30,9],[30,0,[\"_stepSlug\"]],[30,5]],null],[30,5]],null],[4,[38,5],[[28,[37,2],[[30,10],[30,0,[\"_stepSlug\"]],[30,11],[30,12]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"pv05 text-left mr05 ellipsis\"],[12],[1,\"\\n\"],[41,[30,12],[[[42,[28,[37,7],[[28,[37,7],[[28,[37,8],[[30,12]],null]],null]],null],null,[[[1,\"        \"],[10,1],[14,0,\"pl2\"],[12],[13],[1,\"\\n\"]],[]],null]],[]],null],[41,[30,8],[[[1,\"      \"],[1,[30,8]],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"flex-grow-1\"],[12],[13],[1,\"\\n\"],[41,[30,13],[[[1,\"    \"],[8,[39,9],null,[[\"@state\"],[[30,13]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[18,15,[[28,[37,11],null,[[\"substep\"],[[50,\"ui-content-wizard/step\",0,null,[[\"activeStep\",\"depth\",\"parentStep\",\"onDidInsert\",\"onPositionUpdate\",\"onDestroy\",\"onClick\"],[[30,14],[28,[37,13],[[30,0,[\"depth\"]]],null],[30,0,[\"_stepSlug\"]],[30,3],[30,9],[30,10],[30,2]]]]]]]]],[1,\"\\n\"]],[\"@class\",\"@onClick\",\"@onDidInsert\",\"@title\",\"@position\",\"@isTitleEditable\",\"@onTitleChange\",\"@titlePrefix\",\"@onPositionUpdate\",\"@onDestroy\",\"@parentStep\",\"@depth\",\"@state\",\"@activeStep\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"did-insert\",\"did-update\",\"will-destroy\",\"each\",\"-track-array\",\"loop\",\"ui-status\",\"yield\",\"hash\",\"component\",\"inc\"]]",
    "moduleName": "@additive-apps/ui/components/ui-content-wizard/step.hbs",
    "isStrictMode": false
  });
  let UiContentWizardStep = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.number), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberArgTypes.arg)(_propTypes.string), _dec9 = (0, _emberArgTypes.arg)(_propTypes.number), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _dec11 = (0, _emberArgTypes.arg)(_propTypes.func), _dec12 = (0, _emberArgTypes.arg)(_propTypes.func), _dec13 = (0, _emberArgTypes.arg)(_propTypes.func), _dec14 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiContentWizardStep extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The id of the step, used to identify the currently active step.
       *
       * @argument id
       * @type {string}
       * @default null
       * @required
       */
      _initializerDefineProperty(this, "id", _descriptor, this);
      _initializerDefineProperty(this, "activeStep", _descriptor2, this);
      _initializerDefineProperty(this, "title", _descriptor3, this);
      _initializerDefineProperty(this, "titlePrefix", _descriptor4, this);
      _initializerDefineProperty(this, "position", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
      _initializerDefineProperty(this, "isTitleEditable", _descriptor7, this);
      _initializerDefineProperty(this, "parentStep", _descriptor8, this);
      _initializerDefineProperty(this, "depth", _descriptor9, this);
      _initializerDefineProperty(this, "_subSteps", _descriptor10, this);
      _initializerDefineProperty(this, "_activeSubStep", _descriptor11, this);
      _initializerDefineProperty(this, "onDestroy", _descriptor12, this);
      _initializerDefineProperty(this, "onDidInsert", _descriptor13, this);
      _initializerDefineProperty(this, "onPositionUpdate", _descriptor14, this);
      _initializerDefineProperty(this, "onTitleChange", _descriptor15, this);
      _initializerDefineProperty(this, "onClick", _descriptor16, this);
    }
    get _isHighlighted() {
      var _this$activeStep;
      return (_this$activeStep = this.activeStep) === null || _this$activeStep === void 0 ? void 0 : _this$activeStep.startsWith(this._stepSlug);
    }
    get _isVisible() {
      var _this$activeStep2;
      if (!this.parentStep) {
        return true;
      }
      return ((_this$activeStep2 = this.activeStep) === null || _this$activeStep2 === void 0 ? void 0 : _this$activeStep2.startsWith(this.parentStep)) && this.activeStep !== this.parentStep;
    }
    get _isActive() {
      return this.activeStep === this._stepSlug;
    }
    get _stepSlug() {
      let slug = this.id;
      if (this.parentStep) {
        slug = `${this.parentStep}.${slug}`;
      }
      return slug;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "id", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeStep", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "titlePrefix", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isTitleEditable", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "parentStep", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "depth", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_subSteps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_activeSubStep", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onDestroy", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onPositionUpdate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onTitleChange", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiContentWizardStep);
});