define("@additive-apps/media/templates/components/mm-fullscreen-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FolSU1Lc",
    "block": "[[[40,[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"container\",\"topRight\",\"bottomRight\"],[[50,\"ui-blank-template\",0,null,null],[50,\"ui-blank-template\",0,null,[[\"tagName\",\"class\"],[\"div\",\"absolute top-0 right-0\"]]],[50,\"ui-blank-template\",0,null,[[\"tagName\",\"class\"],[\"div\",\"absolute bottom-0 right-0\"]]]]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"_renderElement\"]],null]],[\"&default\"],false,[\"in-element\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-fullscreen-view.hbs",
    "isStrictMode": false
  });
});