define("@additive-apps/ui/components/ui-card/footer/menu/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiItemMenu as |content|>
    {{yield (hash raw=content.menuItem item=(component "ui-card/footer/menu/item" content=content))}}
  </UiItemMenu>
  */
  {
    "id": "4ZoN7kEM",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"raw\",\"item\"],[[30,1,[\"menuItem\"]],[50,\"ui-card/footer/menu/item\",0,null,[[\"content\"],[[30,1]]]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"content\",\"&default\"],false,[\"ui-item-menu\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/menu/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});