define("@additive-apps/media/templates/components/mm-resource-gallery/attributes-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vZYagKMy",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[28,[37,1],[\"mmAttributesDialog.dialogTitle\"],null],\"sm\",[28,[37,2],[[30,0],\"close\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"mmAttributesDialog.helpText\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@label\",\"@isReadOnly\",\"@value\"],[[28,[37,1],[\"mmAttributesDialog.title\"],null],[30,3],[30,0,[\"changeset\",\"title\"]]]],null],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@label\",\"@isReadOnly\",\"@value\"],[[28,[37,1],[\"mmAttributesDialog.alt\"],null],[30,3],[30,0,[\"changeset\",\"alt\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@isSecondary\",\"@isDisabled\",\"@onClick\",\"@label\"],[true,[30,3],[28,[37,2],[[30,0],\"save\"],null],[28,[37,1],[\"mmAttributesDialog.save\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"_isDiscardChangesDialog\"]],[28,[37,2],[[30,0],\"discardChanges\"],null]]],null]],[\"modal\",\"content\",\"@isReadOnly\"],false,[\"ui-modal\",\"t\",\"action\",\"ui-input\",\"ui-button\",\"ui-discard-changes\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-resource-gallery/attributes-dialog.hbs",
    "isStrictMode": false
  });
});