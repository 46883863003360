define("@additive-apps/configurator/validators/urls-with-placeholders", ["exports", "@additive-apps/configurator/utils/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateLink = validateLink;
  /**
   * Validates the link with a given set of allowed values or a given format
   *
   * @param {Array} allowedPlaceholders
   */
  function validateLink(options) {
    let allowedPlaceholders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (key, newValue) => {
      if ((0, _helpers.validateUrl)(newValue, allowedPlaceholders)) {
        return options === null || options === void 0 ? void 0 : options.message;
      }
      return true;
    };
  }
});