define("@additive-apps/ui/components/ui-card/header/accessories/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="accessories {{@position}} - absolute">
    {{yield (hash
      dragHandle=(component 'ui-card/header/accessories/drag-handle')
    )}}
  </div>
  */
  {
    "id": "LD+ipvGy",
    "block": "[[[10,0],[15,0,[29,[\"accessories \",[30,1],\" - absolute\"]]],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"dragHandle\"],[[50,\"ui-card/header/accessories/drag-handle\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@position\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/accessories/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});