define("@additive-apps/configurator/components/aw-configurator/style-editor/sidebar-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ae-sidebar__button ae-style-editor-sidebar__button relative flex items-center flex-shrink-0 br1 ph3 font-sm cpointer bg-secondary--40--hover
      {{@classNames}}
      {{if this.isActive "blue ae-style-editor-sidebar__button__is-active" "primary"}}"
    role="button"
    {{on "click" this.onClick}}
  >
    {{svg-jar this.icon width="20" height="20" class="flex-shrink-0"}}
    {{#unless this.hideText}}
      <span class="ph1 ellipsis">
        {{this.label}}
      </span>
    {{/unless}}
  </div>
  */
  {
    "id": "YILGmXv+",
    "block": "[[[11,0],[16,0,[29,[\"ae-sidebar__button ae-style-editor-sidebar__button relative flex items-center flex-shrink-0 br1 ph3 font-sm cpointer bg-secondary--40--hover\\n    \",[30,1],\"\\n    \",[52,[30,0,[\"isActive\"]],\"blue ae-style-editor-sidebar__button__is-active\",\"primary\"]]]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"icon\"]]],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"flex-shrink-0\"]]]],[1,\"\\n\"],[41,[51,[30,0,[\"hideText\"]]],[[[1,\"    \"],[10,1],[14,0,\"ph1 ellipsis\"],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@classNames\"],false,[\"if\",\"on\",\"svg-jar\",\"unless\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/style-editor/sidebar-button.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AwConfiguratorStyleEditorSidebarButtonComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _object.computed)('linkTo', 'linkToParam', 'router.{currentRouteName,currentURL}'), _class = class AwConfiguratorStyleEditorSidebarButtonComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * the button label
       *
       * @argument label
       * @type {String}
       */
      _initializerDefineProperty(this, "label", _descriptor2, this);
      /**
       * the button icon
       *
       * @argument icon
       * @type {String}
       */
      _initializerDefineProperty(this, "icon", _descriptor3, this);
      /**
       * whether the button should display the label
       *
       * @argument hideText
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "hideText", _descriptor4, this);
      /**
       * the link the button should transition to
       *
       * @argument linkTo
       * @type {String}
       */
      _initializerDefineProperty(this, "linkTo", _descriptor5, this);
      /**
       *  the link param
       *
       * @argument linkToParam
       * @type {String}
       */
      _initializerDefineProperty(this, "linkToParam", _descriptor6, this);
    }
    get isActive() {
      if (this.linkToParam) {
        return this.router.isActive(this.linkTo, this.linkToParam);
      }
      return this.router.isActive(this.linkTo);
    }
    onClick() {
      if (this.linkToParam) {
        this.router.transitionTo(this.linkTo, this.linkToParam);
      } else {
        this.router.transitionTo(this.linkTo);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hideText", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "linkTo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "linkToParam", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorStyleEditorSidebarButtonComponent);
});