define("@additive-apps/ui/components/ui-inline-edit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "@ember/runloop", "prop-types", "@additive-apps/ui/utils/keyboard-constants"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _runloop, _propTypes, _keyboardConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-inline-edit mw-100 flex-grow-1">
    {{#if this._isEditing}}
      <input
        class={{concat "ui-inline-edit__input w-100 " this.classNames}}
        value={{this._value}}
        oninput={{this._onChange}}
        {{did-insert this._didInsertInput}}
      />
    {{else}}
      <div class="flex h-100 {{if this.isCentered 'items-center' 'items-end'}}">
        {{#if this._isEmpty}}
          <div class={{(concat "ui-inline-edit__value " this.classNames)}}>{{this.placeholder}}</div>
        {{else}}
          <div class={{(concat "ui-inline-edit__value " this.classNames)}}>{{this._value}}</div>
        {{/if}}
  
        {{#if this.isEditable}}
          <UiIconButton
            @classNames="ml05"
            @icon="edit"
            @bubbles={{false}}
            @isSecondary={{true}}
            @onClick={{this._startEditing}}
          />
        {{/if}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "nws+PxtU",
    "block": "[[[10,0],[14,0,\"ui-inline-edit mw-100 flex-grow-1\"],[12],[1,\"\\n\"],[41,[30,0,[\"_isEditing\"]],[[[1,\"    \"],[11,\"input\"],[16,0,[28,[37,1],[\"ui-inline-edit__input w-100 \",[30,0,[\"classNames\"]]],null]],[16,2,[30,0,[\"_value\"]]],[16,\"oninput\",[30,0,[\"_onChange\"]]],[4,[38,2],[[30,0,[\"_didInsertInput\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[\"flex h-100 \",[52,[30,0,[\"isCentered\"]],\"items-center\",\"items-end\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"_isEmpty\"]],[[[1,\"        \"],[10,0],[15,0,[28,[37,1],[\"ui-inline-edit__value \",[30,0,[\"classNames\"]]],null]],[12],[1,[30,0,[\"placeholder\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[15,0,[28,[37,1],[\"ui-inline-edit__value \",[30,0,[\"classNames\"]]],null]],[12],[1,[30,0,[\"_value\"]]],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isEditable\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@classNames\",\"@icon\",\"@bubbles\",\"@isSecondary\",\"@onClick\"],[\"ml05\",\"edit\",false,true,[30,0,[\"_startEditing\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"concat\",\"did-insert\",\"ui-icon-button\"]]",
    "moduleName": "@additive-apps/ui/components/ui-inline-edit.hbs",
    "isStrictMode": false
  });
  let UiInlineEdit = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiInlineEdit extends _component2.default {
    get _isEmpty() {
      var _this$_value;
      if (!this._value) {
        return true;
      }
      return ((_this$_value = this._value) === null || _this$_value === void 0 ? void 0 : _this$_value.length) <= 0;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "classNames", _descriptor2, this);
      _initializerDefineProperty(this, "placeholder", _descriptor3, this);
      _initializerDefineProperty(this, "isEditable", _descriptor4, this);
      _initializerDefineProperty(this, "isCentered", _descriptor5, this);
      _initializerDefineProperty(this, "_isEditing", _descriptor6, this);
      _initializerDefineProperty(this, "_value", _descriptor7, this);
      _initializerDefineProperty(this, "onBlur", _descriptor8, this);
      _initializerDefineProperty(this, "onChange", _descriptor9, this);
      this._value = this.value;
      this._clickListener = e => {
        if (document) {
          const input = document.querySelector('.ui-inline-edit__input');
          // if click is outside close edit mode
          if (this._isEditing && !(input !== null && input !== void 0 && input.contains(e.target))) {
            this._closeEdit();
          }
        }
      };
      if (window) {
        this.clickListener = (0, _runloop.bind)(this, this._clickListener);
        window.addEventListener('mouseup', this.clickListener, false);
      }
    }
    _startEditing() {
      if (!this.isEditable) {
        return;
      }
      this._isEditing = true;
    }
    _didInsertInput(element) {
      this._inputElement = element;
      element.focus();
      element.select();
      this._keydownListener = (0, _runloop.bind)(this, this.onKeyDown);
      element.addEventListener('keydown', this._keydownListener, false);
    }
    _closeEdit() {
      this._isEditing = false;
      this.onBlur(this._value);
    }
    _onChange(event) {
      const {
        value
      } = event.target;
      this._value = value;
      this.onChange(value);
    }
    onKeyDown(event) {
      switch (event.keyCode) {
        case _keyboardConstants.ENTER:
          this._closeEdit();
        default:
          break;
      }
    }
    onWillDestroy() {
      var _this$_inputElement;
      (_this$_inputElement = this._inputElement) === null || _this$_inputElement === void 0 || _this$_inputElement.removeEventListener('keydown', this._keydownListener, false);
      window.removeEventListener('mouseup', this.clickListener, false);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "placeholder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '-';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isEditable", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isCentered", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isEditing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onBlur", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_startEditing", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_startEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_didInsertInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_didInsertInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_closeEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_closeEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInlineEdit);
});