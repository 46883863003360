define("@additive-apps/ui/components/ui-chart", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "chart.js/auto", "chartjs-chart-sankey", "@additive-apps/ui/utils/chart-defaults", "@additive-apps/ui/utils/chart-tooltip-plugin", "@additive-apps/ui/utils/chart-plugins", "@additive-apps/ui/utils/chart-draw-value-plugin", "lodash.merge", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object, _internals, _auto, _chartjsChartSankey, _chartDefaults2, _chartTooltipPlugin, _chartPlugins, _chartDrawValuePlugin, _lodash, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-chart
      {{if this.hideMobileTooltip "ui-chart--hide-mobile-tooltip"}}
  
      {{@classNames}}
  
      {{@class}}"
    ...attributes
    {{did-insert this.onDidInsert}}
    {{did-update this.onDidUpdate}}
    {{will-destroy this.onWillDestroy}}
  >
    {{yield (hash controls=(component "ui-blank-template"))}}
    <div class="ui-chart__area-wrapper">
      <div class="ui-chart__wrapper">
        <canvas id={{this.canvasId}}></canvas>
      </div>
    </div>
    {{#if this.hasExpandButton}}
      <div
        class="cpointer blue primary font-sm pl1 pv1"
        role="button"
        {{on "click" this.toggleCollapsed}}
      >
        {{if this.isCollapsed (t "uiTable.showMore") (t "uiTable.showLess")}}
      </div>
    {{/if}}
  
    {{yield (hash datasets=this.data.datasets legend=(component "ui-blank-template"))}}
  </div>
  */
  {
    "id": "pceejbeQ",
    "block": "[[[11,0],[16,0,[29,[\"ui-chart\\n    \",[52,[30,0,[\"hideMobileTooltip\"]],\"ui-chart--hide-mobile-tooltip\"],\"\\n\\n    \",[30,1],\"\\n\\n    \",[30,2]]]],[17,3],[4,[38,1],[[30,0,[\"onDidInsert\"]]],null],[4,[38,2],[[30,0,[\"onDidUpdate\"]]],null],[4,[38,3],[[30,0,[\"onWillDestroy\"]]],null],[12],[1,\"\\n  \"],[18,4,[[28,[37,5],null,[[\"controls\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"ui-chart__area-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui-chart__wrapper\"],[12],[1,\"\\n      \"],[10,\"canvas\"],[15,1,[30,0,[\"canvasId\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasExpandButton\"]],[[[1,\"    \"],[11,0],[24,0,\"cpointer blue primary font-sm pl1 pv1\"],[24,\"role\",\"button\"],[4,[38,7],[\"click\",[30,0,[\"toggleCollapsed\"]]],null],[12],[1,\"\\n      \"],[1,[52,[30,0,[\"isCollapsed\"]],[28,[37,8],[\"uiTable.showMore\"],null],[28,[37,8],[\"uiTable.showLess\"],null]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,4,[[28,[37,5],null,[[\"datasets\",\"legend\"],[[30,0,[\"data\",\"datasets\"]],[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@classNames\",\"@class\",\"&attrs\",\"&default\"],false,[\"if\",\"did-insert\",\"did-update\",\"will-destroy\",\"yield\",\"hash\",\"component\",\"on\",\"t\"]]",
    "moduleName": "@additive-apps/ui/components/ui-chart.hbs",
    "isStrictMode": false
  });
  let UiChartComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberArgTypes.arg)(_propTypes.number), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiChartComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      /**
       * The chart-type
       *
       * @property type
       * @type {String}
       * @default "line"
       */
      _initializerDefineProperty(this, "type", _descriptor2, this);
      /**
       * The data object required to draw the chart.
       *
       * The object, needs a collection of labels and the actual
       * chart datasets.
       *
       * If there are multiple datasets, they should always be of the same length
       *
       * @argument data
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "data", _descriptor3, this);
      /**
       * Determines if the chart uses the tooltip plugin
       *
       * @argument hasTooltip
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "hasTooltip", _descriptor4, this);
      /**
       * Determines if the chart uses the reference plugin
       *
       * @argument hasReferenceDataset
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasReferenceDataset", _descriptor5, this);
      /**
       * whether the chart is stacked
       *
       * @argument isStacked
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isStacked", _descriptor6, this);
      /**
       * Whether the chart is clickable (only supported by sankey chart)
       * @property isClickable
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isClickable", _descriptor7, this);
      /**
       * whether the tooltip should be hidden on mobile devices
       *
       * @argument hideMobileTooltip
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hideMobileTooltip", _descriptor8, this);
      /**
       * The number of items to be shown
       *
       * @property collapsedItemCount
       * @type {Number}
       */
      _initializerDefineProperty(this, "collapsedItemCount", _descriptor9, this);
      /**
       * Whether the chart only shows the collapsedItemCount or all items
       * @property isCollapsed
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isCollapsed", _descriptor10, this);
      /**
       * Stores the reference to the current chart instance,
       * provided by chartjs.
       *
       * @property chart
       * @type {Chart}
       * @default null
       */
      _initializerDefineProperty(this, "chart", _descriptor11, this);
      /**
       * The actual canvas element
       *
       * @property canvas
       * @type {HTMLCanvasElement}
       * @default null
       */
      _initializerDefineProperty(this, "canvas", _descriptor12, this);
      /**
       * current elements id
       * @property _elementId
       * @type {String}
       * @default guidFor(this)
       *
       */
      _initializerDefineProperty(this, "_elementId", _descriptor13, this);
      _initializerDefineProperty(this, "onClick", _descriptor14, this);
    }
    /**
     * Returns a unique id for the canvas element
     *
     * @property _canvasId
     * @type {String}
     * @readonly
     */

    get canvasId() {
      return `canvas-${this._elementId}`;
    }
    get hasExpandButton() {
      var _this$data;
      if (this.type === 'horizontalBar') return ((_this$data = this.data) === null || _this$data === void 0 || (_this$data = _this$data.labels) === null || _this$data === void 0 ? void 0 : _this$data.length) > this.collapsedItemCount;
      return false;
    }

    /**
     * initializes the chart.
     *
     * Has to be called after the component has been inserted into the DOM
     * or the canvas can't be found and Chart.js throws an error
     *
     * @function _load
     * @public
     */
    _load() {
      const canvasElement = document.getElementById(this.canvasId);
      if (!canvasElement || !this.data) return;
      let {
        comparison,
        datasets,
        labels,
        options,
        colors
      } = this.data;
      let type = this.type;
      let _chartDefaults = _chartDefaults2.chartDefaults;
      if (this.type === 'sankey') {
        _chartDefaults = (0, _lodash.default)({}, _chartDefaults2.chartDefaults, _chartDefaults2.chartSankeyDefaults);
        delete _chartDefaults.options.scales;
        _auto.default.register(_chartjsChartSankey.SankeyController, _chartjsChartSankey.Flow);
      } else if (this.type === 'horizontalBar') {
        if (this.isCollapsed) {
          labels = labels.slice(0, this.collapsedItemCount);
        }
        type = 'bar';
        _chartDefaults = (0, _lodash.default)({}, _chartDefaults2.chartDefaults, _chartDefaults2.chartHorizontalBarDefaults);
      } else if (this.isStacked) {
        _chartDefaults = (0, _lodash.default)({}, _chartDefaults2.chartDefaults, _chartDefaults2.chartStackedBarDefaults);
      }
      const chartOptions = (0, _lodash.default)({}, _chartDefaults, {
        type
      }, {
        options
      });
      const optionsData = (0, _lodash.default)({}, _chartDefaults2.chartDefaults.data);
      optionsData.comparison = comparison;
      optionsData.labels = typeof labels === 'object' ? Object.values(labels) : labels;
      if (this.type === 'sankey') {
        optionsData.datasets.push({
          data: Object.values(datasets),
          labels,
          colors,
          colorFrom: c => colors[c.dataset.data[c.dataIndex].from],
          colorTo: c => colors[c.dataset.data[c.dataIndex].to],
          borderWidth: 0,
          font: {
            family: 'Barlow',
            size: '14px',
            weight: 500
          }
        });
        chartOptions.options.onClick = (event, elements, chart) => {
          var _chart$data;
          if (!this.isClickable) {
            return;
          }
          if (elements.length === 0) {
            this.onClick();
            return;
          }
          const {
            data
          } = chart === null || chart === void 0 || (_chart$data = chart.data) === null || _chart$data === void 0 ? void 0 : _chart$data.datasets[0];
          const isClickable = data[elements[0].index].clickable;
          isClickable && this.onClick(elements[0]);
        };

        // onHover listener is used to set the correct cursor type (if chart elements are clickable)
        chartOptions.options.onHover = (event, elements, chart) => {
          var _chart$data2;
          if (!this.isClickable) {
            return;
          }
          if (elements.length === 0) {
            event.native.target.style.cursor = 'pointer';
            return;
          }
          const {
            data
          } = chart === null || chart === void 0 || (_chart$data2 = chart.data) === null || _chart$data2 === void 0 ? void 0 : _chart$data2.datasets[0];
          const isClickable = data[elements[0].index].clickable;
          if (isClickable) {
            event.native.target.style.cursor = 'pointer';
          } else {
            event.native.target.style.cursor = 'default';
          }
        };
      } else {
        optionsData.datasets = typeof datasets === 'object' ? Object.values(datasets) : datasets;
      }
      (0, _object.set)(chartOptions, 'data', optionsData);
      chartOptions.options.locale = this.uiAppSettings.currentLocale;

      // add external plugins
      chartOptions.plugins = chartOptions.plugins || [];
      if (this.hasTooltip) {
        chartOptions.plugins.push(_chartTooltipPlugin.chartTooltipPlugin);
      } else {
        chartOptions.options.plugins.tooltip = false;
      }
      if (this.hasReferenceDataset) {
        chartOptions.plugins.push(_chartPlugins.chartReferencePlugin);
      }
      if (this.type === 'horizontalBar') {
        chartOptions.plugins.push(_chartDrawValuePlugin.chartDrawValuePlugin);

        // change parent min-height according to chart data
        const barHeight = 42;
        const barSpacing = 6;
        const labelPadding = 6;
        const chartPadding = 100;
        const labelHeight = datasets.length * barHeight + (datasets.length - 1) * barSpacing + labelPadding;
        const chartHeight = parseInt(labelHeight * labels.length + chartPadding);

        // update height of second chart
        canvasElement.parentElement.style.minHeight = chartHeight + 'px';
      }

      // fill empty data with zeroes
      chartOptions.plugins.push(_chartPlugins.chartFillEmptyDataPlugin);

      // make different label color for the current week's label
      if (this.type !== 'sankey') {
        chartOptions.plugins.push(_chartPlugins.chartEmphasizeCurrentWeekPlugin);
      }
      const chartInstance = new _auto.default(canvasElement, chartOptions);

      // set reference to canvas and the active chart instance
      this.canvas = canvasElement;
      this.chart = chartInstance;
    }
    /**
     * Triggers a redraw of all chart elements.
     *
     * Note, this does not update elements for new data.
     * Use .update() in that case.
     *
     * @function draw
     * @public
     * @param {Object} config
     */
    draw(config) {
      const chartInstance = this.chart;
      chartInstance === null || chartInstance === void 0 || chartInstance.render(config);
    }

    /**
     * Triggers an update of the chart.
     * This can be safely called after updating the data object.
     * This will update all scales, legends, and then re-render the chart.
     *
     * @function update
     * @public
     */
    update() {
      const chartInstance = this.chart;
      chartInstance === null || chartInstance === void 0 || chartInstance.update();
    }

    /**
     * Reset the chart to it's state before the initial animation.
     * A new animation can then be triggered using update.
     *
     * @function reset
     * @public
     */
    reset() {
      const chartInstance = this.chart;
      chartInstance === null || chartInstance === void 0 || chartInstance.reset();
    }

    /**
     * Destroys the chart instance in order to clean up all references.
     * Should be called before unmounting the component
     *
     * @function destroy
     * @public
     */
    onDestroy() {
      const chartInstance = this.chart;
      chartInstance === null || chartInstance === void 0 || chartInstance.destroy();
    }
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
      const chartInstance = this.chart;
      chartInstance === null || chartInstance === void 0 || chartInstance.destroy();
      this._load();
    }
    onDidInsert() {
      this._load();
    }
    onDidUpdate() {
      // update chart if params have changed
      this.update();
    }

    /**
     * Destroys the chart instance in order to clean up all references.
     * Should be called before unmounting the component
     *
     * @function onWillDestroy
     * @public
     */
    onWillDestroy() {
      this.onDestroy();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'line';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hasTooltip", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasReferenceDataset", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isStacked", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isClickable", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hideMobileTooltip", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "collapsedItemCount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 10;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "chart", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "canvas", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_elementId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return (0, _internals.guidFor)(this);
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCollapsed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiChartComponent);
});