define("@additive-apps/ui/components/ui-menu", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-basic-dropdown/utils/calculate-position", "ember-arg-types", "prop-types", "@additive-apps/ui/constants"], function (_exports, _component, _templateFactory, _component2, _calculatePosition, _emberArgTypes, _propTypes, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiAdditiveBasicDropdown
    @calculatePosition={{this._calculatePosition}}
    @offsets={{this.offsets}}
    @stopBubbling={{this.stopBubbling}}
    @horizontalPosition={{this.horizontalPosition}}
    @verticalPosition={{this.verticalPosition}}
    @onClose={{this._onClose}}
    @onOpen={{this._onOpen}} as |d|
  >
    {{!
      @deprecated lower-case yields (`trigger` + `content`),
      just here to not introduce a breaking change.
      Use `Trigger` and `Content` instead!
    }}
    {{yield (hash api=d.api Trigger=d.Trigger Content=d.Content trigger=d.Trigger content=d.Content)}}
  </UiAdditiveBasicDropdown>
  */
  {
    "id": "Q9oZ0inW",
    "block": "[[[8,[39,0],null,[[\"@calculatePosition\",\"@offsets\",\"@stopBubbling\",\"@horizontalPosition\",\"@verticalPosition\",\"@onClose\",\"@onOpen\"],[[30,0,[\"_calculatePosition\"]],[30,0,[\"offsets\"]],[30,0,[\"stopBubbling\"]],[30,0,[\"horizontalPosition\"]],[30,0,[\"verticalPosition\"]],[30,0,[\"_onClose\"]],[30,0,[\"_onOpen\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[18,2,[[28,[37,2],null,[[\"api\",\"Trigger\",\"Content\",\"trigger\",\"content\"],[[30,1,[\"api\"]],[30,1,[\"Trigger\"]],[30,1,[\"Content\"]],[30,1,[\"Trigger\"]],[30,1,[\"Content\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"d\",\"&default\"],false,[\"ui-additive-basic-dropdown\",\"yield\",\"hash\"]]",
    "moduleName": "@additive-apps/ui/components/ui-menu.hbs",
    "isStrictMode": false
  });
  const MENU_EDGE_MARGIN = 8;

  /**
   * Component for a dropdown-menu
   *```hbs
  <UiMenu as |menu|>
    <menu.Trigger>
      My Trigger
    </menu.Trigger>
    <menu.Content>
      My Content
    </menu.Content>
  </UiMenu>
   ```
   *
   * @extends ui-additive-basic-dropdown
   * @class ui-menu
   * @yield {Hash} menu
   * @yield {Object} menu.api Ember-basic-dropdowns public api
   * @yield {Component} menu.Trigger Trigger Component
   * @yield {Component} menu.Content Content Component
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class _class extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Ember-basic-dropdowns `horizontalPosition`
       *
       * @argument {String} horizontalPosition
       * @default 'auto-right'
       */
      _initializerDefineProperty(this, "horizontalPosition", _descriptor, this);
      /**
       * Whether events should bubble
       *
       * @argument {Boolean} stopBubbling
       * @default true
       */
      _initializerDefineProperty(this, "stopBubbling", _descriptor2, this);
      /**
       * Ember-basic-dropdowns `verticalPosition`
       *
       * @argument {String} verticalPosition
       * @default 'auto'
       */
      _initializerDefineProperty(this, "verticalPosition", _descriptor3, this);
      /**
       * The left and top offset string for the content.
       *
       * @argument {String} offset
       * @default '0 8'
       */
      _defineProperty(this, "offset", `0 ${MENU_EDGE_MARGIN}`);
    }
    /**
     * Extracted left+top value out of the offset property
     *
     * @computed offsets
     */
    get offsets() {
      let offset = this.offset || '0 0';
      let [left, top] = offset.split(' ').map(s => s.trim()).map(parseFloat);
      top = top || left;
      return {
        left,
        top
      };
    }

    /**
     * Overwrite of ember-basic-dropdown `calculatePosition` function
     * to add custom offsets.
     *
     * @function _calculatePosition
     * @private
     */
    _calculatePosition(trigger, content, destination, _ref2) {
      let {
        dropdown
      } = _ref2;
      const parentPosition = (0, _calculatePosition.default)(...arguments);

      // add custom offsets to calculated position
      const offsets = dropdown.args.offsets;
      if (offsets.left) {
        if (parentPosition.horizontalPosition === 'left') {
          parentPosition.style.left += offsets.left;
        } else {
          parentPosition.style.right += offsets.left;
        }
      }
      if (offsets.top) {
        if (parentPosition.verticalPosition === 'above') {
          parentPosition.style.top -= offsets.top;
        } else {
          parentPosition.style.top += offsets.top;
        }
      }
      return parentPosition;
    }

    /**
     * On ember-basic-dropdown close event.
     * We have to preventDefault as well,
     * ember-basic-dropdown does not do that automatically
     *
     * @function _onClose
     * @param {Object} publicApi
     * @param {Event} e
     * @private
     */
    _onClose(publicApi, e) {
      if (e && this.stopBubbling) {
        e.preventDefault();
        e.stopPropagation();
      }
    }

    /**
     * On ember-basic-dropdown open event.
     * We have to preventDefault as well,
     * ember-basic-dropdown does not do that automatically
     *
     * @function _onOpen
     * @param {Object} publicApi
     * @param {Event} e
     * @private
     */
    _onOpen(publicApi, e) {
      if (e && this.stopBubbling) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "horizontalPosition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _constants.DEFAULT_MENU_POSITION.horizontal;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stopBubbling", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "verticalPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _constants.DEFAULT_MENU_POSITION.vertical;
    }
  }), _class));
});