define("@additive-apps/ui/components/ui-inline-notification", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-inline-notification flex flex-column w-100 pa2 gap2 br1
    {{@class}}
     {{@classNames}}
     {{if this.isWarning "ui-inline-notification--warning"}}
     {{if this.isSuccess "ui-inline-notification--success"}}
     {{if this.isError "ui-inline-notification--error"}}
    "
    ...attributes
  >
    <div class="flex">
      {{#if this.icon}}
        {{svg-jar this.icon class="icon"}}
      {{/if}}
  
      <div class="font-md flex flex-column {{if this.icon "pl1"}}">
        {{this.title}}
  
        {{#if this.content}}
          {{this.content}}
        {{/if}}
      </div>
    </div>
  
    {{#if this.buttonText}}
      <div class="flex-grow-1 flex justify-end items-end">
        <div>
          <UiIconButton
            @label={{this.buttonText}}
            @icon={{this.buttonIcon}}
            @class="mt1 mt0-md"
            @onClick={{this.onButtonClick}}
            @isDanger={{this.isError}}
            @isWarning={{this.isWarning}}
            @isSuccess={{this.isSuccess}}
          />
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "5EIYTVHb",
    "block": "[[[11,0],[16,0,[29,[\"ui-inline-notification flex flex-column w-100 pa2 gap2 br1\\n  \",[30,1],\"\\n   \",[30,2],\"\\n   \",[52,[30,0,[\"isWarning\"]],\"ui-inline-notification--warning\"],\"\\n   \",[52,[30,0,[\"isSuccess\"]],\"ui-inline-notification--success\"],\"\\n   \",[52,[30,0,[\"isError\"]],\"ui-inline-notification--error\"],\"\\n  \"]]],[17,3],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],[[\"class\"],[\"icon\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"font-md flex flex-column \",[52,[30,0,[\"icon\"]],\"pl1\"]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"content\"]],[[[1,\"        \"],[1,[30,0,[\"content\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"buttonText\"]],[[[1,\"    \"],[10,0],[14,0,\"flex-grow-1 flex justify-end items-end\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@label\",\"@icon\",\"@class\",\"@onClick\",\"@isDanger\",\"@isWarning\",\"@isSuccess\"],[[30,0,[\"buttonText\"]],[30,0,[\"buttonIcon\"]],\"mt1 mt0-md\",[30,0,[\"onButtonClick\"]],[30,0,[\"isError\"]],[30,0,[\"isWarning\"]],[30,0,[\"isSuccess\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@classNames\",\"&attrs\"],false,[\"if\",\"svg-jar\",\"ui-icon-button\"]]",
    "moduleName": "@additive-apps/ui/components/ui-inline-notification.hbs",
    "isStrictMode": false
  });
  /**
   * Shows a inline notification with a given text
   *
   * ```hbs
   * {{ui-inline-notification
   *   title
   *   icon
   *   buttonText
   *   onButtonClick
   *   isWarning
   *   isSuccess
   *   isError
   * }}
   * ```
   *
   * @class ui-inline-notification
   */
  let UiInlineNotification = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiInlineNotification extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Title of notification
       *
       * @argument title
       * @type {String|Object}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * the content of the notification
       *
       * @argument content
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "content", _descriptor2, this);
      /**
       * Icon to display
       *
       * @argument icon
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "icon", _descriptor3, this);
      /**
       * Text of button
       *
       * @argument buttonText
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "buttonText", _descriptor4, this);
      /**
       * Icon of button
       * @argument buttonIcon
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "buttonIcon", _descriptor5, this);
      /**
       * Whether it is a warning notification (yellow)
       *
       * CSS-class:
       *  - `ui-inline-notification--warning
       *
       * @argument isWarning
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isWarning", _descriptor6, this);
      /**
       * Whether it is a success notification (green)
       *
       * CSS-class:
       *  - `ui-inline-notification--success
       *
       * @argument isSuccess
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSuccess", _descriptor7, this);
      /**
       * Whether it is a error notification (red)
       *
       * CSS-class:
       *  - `ui-inline-notification--error
       *
       * @argument isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor8, this);
      /**
       * the callback that is executed when the button is clicked
       *
       * @function onButtonClicked
       */
      _initializerDefineProperty(this, "onButtonClick", _descriptor9, this);
    }
    /**
     * the action passed to the button in the notification calls the callback
     *
     * @function onButtonClick
     */
    onButtonClicked() {
      this.onButtonClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "buttonText", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "buttonIcon", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isError", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onButtonClick", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onButtonClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonClicked"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiInlineNotification);
});