define("@additive-apps/ui/templates/components/ui-filter-inputs/daterange", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "S1001UO5",
    "block": "[[[8,[39,0],null,[[\"@filter\",\"@initialValue\",\"@onChange\"],[[30,0,[\"filter\"]],[30,0,[\"_singleInitialValue\"]],[28,[37,1],[[30,0],\"onOptionChange\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"_isCustom\"]],[[[41,[30,0,[\"_hasCustomMeta\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@label\",\"@placeholder\",\"@initialDay\",\"@initialMonth\",\"@onChange\"],[[28,[37,4],[[30,0,[\"filter\",\"label\"]],\" \",[28,[37,5],[\"uiFilterInputs.daterange.start\"],null]],null],[28,[37,4],[[30,0,[\"filter\",\"label\"]],\" \",[28,[37,5],[\"uiFilterInputs.daterange.start\"],null]],null],[28,[37,6],[[30,0,[\"_initialCustomMetaRange\"]],\"start.day\"],null],[28,[37,6],[[30,0,[\"_initialCustomMetaRange\"]],\"start.month\"],null],[28,[37,1],[[30,0],\"onCustomMetaChange\",\"start\"],null]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@placeholder\",\"@initialDay\",\"@initialMonth\",\"@onChange\"],[[28,[37,4],[[30,0,[\"filter\",\"label\"]],\" \",[28,[37,5],[\"uiFilterInputs.daterange.end\"],null]],null],[28,[37,4],[[30,0,[\"filter\",\"label\"]],\" \",[28,[37,5],[\"uiFilterInputs.daterange.end\"],null]],null],[28,[37,6],[[30,0,[\"_initialCustomMetaRange\"]],\"end.day\"],null],[28,[37,6],[[30,0,[\"_initialCustomMetaRange\"]],\"end.month\"],null],[28,[37,1],[[30,0],\"onCustomMetaChange\",\"end\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,7],null,[[\"@label\",\"@placeholder\",\"@range\",\"@onConfirm\",\"@isRangePicker\"],[[30,0,[\"filter\",\"label\"]],[30,0,[\"filter\",\"label\"]],[30,0,[\"_initialRange\"]],[28,[37,1],[[30,0],\"onDatepickerChange\"],null],true]],null],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"ui-filter-inputs/single\",\"action\",\"if\",\"ui-month-day-picker\",\"concat\",\"t\",\"get\",\"ui-datepicker\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/daterange.hbs",
    "isStrictMode": false
  });
});