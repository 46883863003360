define("@additive-apps/media/classes/video-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Class to provide an consistent way to control videos.
   *
   * As we will support multiple video providers the video controls
   * are different for each provider.
   *
   * Create this class and pass the provider specific start/stop events.
   *
   *```js
   * new VideoControl({
   *   start: () => myProvider.playVideo(),
   *   stop: () => myProvider.stopVideo(),
   *});
   *```
   *
   * @class VideoControl
   */
  class VideoControl {
    /**
     * Method to stop the video.
     * @method stop
     */
    stop() {}
    /**
     * Method to start the video.
     * @method start
     */
    start() {}
    constructor(_ref) {
      let {
        stop,
        start
      } = _ref;
      this.stop = stop;
      this.start = start;
    }
  }
  _exports.default = VideoControl;
});