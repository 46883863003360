define("@additive-apps/ui/templates/components/ui-content-rating", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cZsxCgPU",
    "block": "[[[41,[30,0,[\"hasRatingProperty\"]],[[[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[[30,0,[\"max\"]]],null]],null]],null],null,[[[1,\"    \"],[8,[39,4],null,[[\"@currentRating\",\"@rating\",\"@isDisabled\",\"@onClick\"],[[30,0,[\"item\",\"rating\"]],[30,1],[30,0,[\"isDisabled\"]],[28,[37,5],[[30,0],\"onClick\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"infoMessage\"]],[[[1,\"    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"pl1 secondary\"],[12],[1,\"\\n          \"],[1,[28,[35,7],[\"info\"],[[\"width\",\"height\"],[\"20\",\"20\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"infoMessage\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[\"val\",\"pop\"],false,[\"if\",\"each\",\"-track-array\",\"loop\",\"ui-rating\",\"action\",\"ui-popover\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-content-rating.hbs",
    "isStrictMode": false
  });
});