define("@additive-apps/ui/services/s3-upload-file", ["exports", "@ember/service", "rsvp", "@ember/application", "@ember/utils", "fetch"], function (_exports, _service, _rsvp, _application, _utils, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    /**
     * @param {File} file the uploaded file
     * @param {String} uploadPath S3 upload path
     * @param {String} fallbackFileType a fallback mimetype in case the file does not have one
     *
     * @function uploadFile
     */
    uploadFile(_ref) {
      let {
        file,
        uploadPath,
        fallbackFileType
      } = _ref;
      if ((0, _utils.isEmpty)(file)) {
        throw new Error('Upload file is missing');
      } else if ((0, _utils.isEmpty)(uploadPath)) {
        throw new Error('Upload path is missing');
      }
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      return new _rsvp.default.Promise((resolve, reject) => {
        reader.onerror = error => {
          reader.abort();
          reject(error);
        };
        reader.onload = () => {
          this.getSignedUrl({
            uploadPath: uploadPath,
            fileType: file.type || fallbackFileType
          }).then(response => response.json()).then(json => {
            return (0, _fetch.default)(json.signedUrl, {
              method: 'PUT',
              headers: {
                'cache-control': 'public, max-age=31536000'
              },
              body: new Blob([reader.result], {
                type: file.type
              })
            }).then(() => {
              resolve(json.bucketPath);
            });
          }).catch(error => {
            reject(error);
          });
        };
      });
    },
    /**
     * @param {String} uploadPath S3 upload path
     * @param {String} fileType the type of the file to upload
     *
     * @function getSignedUrl
     */
    getSignedUrl(_ref2) {
      let {
        uploadPath,
        fileType
      } = _ref2;
      if ((0, _utils.isEmpty)(uploadPath)) {
        throw new Error('Upload path is missing');
      } else if ((0, _utils.isEmpty)(fileType)) {
        throw new Error('File type is missing');
      }
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (0, _fetch.default)(ENV.APP.imageUploadServiceHost, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          bucket: ENV.APP.awsBucket,
          path: uploadPath,
          type: fileType
        })
      });
    }
  });
});