define("@additive-apps/media/components/mm-media-gallery", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-media-gallery", "@ember/object", "@ember/utils", "@ember/array", "@ember/runloop", "@ember/object/computed", "@additive-apps/media/constants", "@additive-apps/media/classes/video-control"], function (_exports, _component, _mmMediaGallery, _object, _utils, _array, _runloop, _computed, _constants, _videoControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Fullscreen overlay component to show a given array of media.
   *
   *  See https://www.notion.so/additiveapps/mm-ui-Media-gallery-4b8f26a882494abc8311052228823d34 for deep details
   *
   * ```hbs
   * {{mm-media-gallery
   *    media=mediaForGallery
   *    startIndex=indexOfMediumIWantToStart
   *    onGalleryIndexChange=(action "onUserInteraction")
   *    onClose=(action (mut isMediaGalleryDialog) false)
   * }}
   * ```
   *
   * @class mm-media-gallery
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmMediaGallery.default,
    classNames: ['mm-media-gallery'],
    /**
     * The current active index object
     *
     * @property activeIndex
     * @type {Number}
     */
    activeIndex: 1,
    /**
     * Image's default size (beside srcSets)
     *
     * @property defaultSize
     * @type {String}
     */
    defaultSize: '',
    /**
     * Whether it should hide the navigation elements
     *
     * @property hideNavigation
     * @type {Boolean}
     */
    hideNavigation: false,
    /**
     * `<picture>`'s media-sets
     *
     * @property mediaSets
     * @type {Hash}
     */
    mediaSets: null,
    /**
     * Media array to use.
     *
     * @property media
     * @type {[Medium]}
     */
    media: undefined,
    /**
     * `<picture>`'s size-sets
     *
     * @property sizeSets
     * @type {Hash}
     */
    sizeSets: null,
    /**
     * The "media" array-index where the gallery shall start.
     *
     * @property startIndex
     * @type {Number}
     */
    startIndex: 1,
    /**
     * Whether it should render carousel-component. With only 1 medium existing we do not render carousel.
     *
     * @property useCarousel
     * @type {Boolean}
     * @private
     */
    useCarousel: (0, _computed.gte)('media.length', 2),
    /**
     * Object which holds the `VideoControl`s by medium id as key
     * of the started videos
     *
     * @property _startedVideos
     * @type {Object}
     * @private
     */
    _startedVideos: null,
    /**
     * The current active medium object
     *
     * @computed activeMedium
     * @type {Medium}
     */
    activeMedium: (0, _object.computed)('activeIndex', 'media', {
      get() {
        const activeIndex = this.activeIndex;
        const media = this.media;
        if (!activeIndex || !(0, _array.isArray)(media)) {
          return undefined;
        }
        const activeMedium = this._arrayObjectAt(media, activeIndex - 1);
        if (!activeMedium) {
          this._resetActiveMedium();
          return undefined;
        }
        return activeMedium;
      }
    }),
    /**
     * First medium of media array
     *
     * @computed firstMedium
     * @type {Medium}
     * @private
     */
    firstMedium: (0, _object.computed)('media.length', {
      get() {
        return this._arrayObjectAt(this.media, 0);
      }
    }),
    init() {
      this._super(...arguments);
      (0, _utils.isNone)(this.media) && (0, _object.set)(this, 'media', []);
      (0, _utils.isNone)(this.defaultSize) && (0, _object.set)(this, 'defaultSize', _constants.MEDIA_GALLERY_DEFAULT_SIZE);
      (0, _utils.isNone)(this._startedVideos) && (0, _object.set)(this, '_startedVideos', {});
      (0, _utils.isPresent)(this.startIndex) && (0, _object.set)(this, 'activeIndex', this.startIndex);
    },
    _arrayObjectAt(array, index) {
      return typeof array.objectAt === 'function' ? array.objectAt(index) : array[index];
    },
    _resetActiveMedium() {
      (0, _runloop.next)(this, () => !this.isDestroying && (0, _object.set)(this, 'activeIndex', 1));
    },
    /**
     * On close event
     *
     * @function onClose
     */
    onClose() {},
    /**
     * Gallery index has changed event
     *
     * @function onGalleryIndexChange
     * @param {Number} newIndex The new index
     */
    onGalleryIndexChange(/*newIndex*/) {},
    actions: {
      _onGalleryIndexChange(newIndex) {
        (0, _object.set)(this, 'activeIndex', newIndex);
        this.onGalleryIndexChange(newIndex);

        /* Stop started videos when moved out of view */
        const startedVideos = this._startedVideos;
        if (startedVideos) {
          Object.keys(startedVideos).map(key => {
            startedVideos[key].stop();
          });
        }
      },
      /**
       * Triggered when a video medium started
       *
       * @function _onVideoStarted
       * @param {Object} medium  The video medium which started
       * @param {VideoControl} videoControl The `VideoControl` instance for the started medium
       */
      _onVideoStarted(medium, videoControl) {
        if (!(videoControl instanceof _videoControl.default)) {
          throw new Error('[mm-media-gallery] Expected VideoControl parameter not passed');
        }
        let startedVideos = this._startedVideos;
        startedVideos[medium.id] = videoControl;
        (0, _object.set)(this, '_startedVideos', startedVideos);
      }
    }
  });
});