define("@additive-apps/ui/components/ui-pagination-page-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this._isActive this.isInput)}}
    <input
      class="ui-pagination__page-item--input flex flex-column flex-shrink-0 items-center justify-center font-medium font-sm br1 {{
        @classNames
      }} {{@class}}"
      type="number"
      min="1"
      max={{this.total}}
      value={{this.page}}
      ...attributes
      {{on "keyup" this._onInput}}
    />
  {{else}}
    <div
      class="ui-pagination__page-item flex flex-column flex-shrink-0 items-center justify-center cpointer font-medium font-sm br1 {{
        if this._isActive "primary bg-secondary--10" "ui-pagination__page-item--use-hover secondary"
      }} {{@classNames}} {{@class}}"
      role="button"
      ...attributes
      {{on "click" this.click}}
    >
      {{this.page}}
    </div>
  {{/if}}
  */
  {
    "id": "hD/k4PxQ",
    "block": "[[[41,[28,[37,1],[[30,0,[\"_isActive\"]],[30,0,[\"isInput\"]]],null],[[[1,\"  \"],[11,\"input\"],[16,0,[29,[\"ui-pagination__page-item--input flex flex-column flex-shrink-0 items-center justify-center font-medium font-sm br1 \",[30,1],\" \",[30,2]]]],[24,4,\"number\"],[24,\"min\",\"1\"],[16,\"max\",[30,0,[\"total\"]]],[16,2,[30,0,[\"page\"]]],[17,3],[4,[38,2],[\"keyup\",[30,0,[\"_onInput\"]]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[16,0,[29,[\"ui-pagination__page-item flex flex-column flex-shrink-0 items-center justify-center cpointer font-medium font-sm br1 \",[52,[30,0,[\"_isActive\"]],\"primary bg-secondary--10\",\"ui-pagination__page-item--use-hover secondary\"],\" \",[30,1],\" \",[30,2]]]],[24,\"role\",\"button\"],[17,3],[4,[38,2],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"page\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@classNames\",\"@class\",\"&attrs\"],false,[\"if\",\"and\",\"on\"]]",
    "moduleName": "@additive-apps/ui/components/ui-pagination-page-item.hbs",
    "isStrictMode": false
  });
  /**
   * Pagination page item.
   *
   * @class ui-pagination-page-item
   */
  let UiPaginationPageItemComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.number), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiPaginationPageItemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The current page.
       *
       * @argument currentPage
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "currentPage", _descriptor, this);
      /**
       * The total amount of pages.
       *
       * @argument total
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "totalPages", _descriptor2, this);
      /**
       * The number of the page this component stands for.
       *
       * @argument page
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "page", _descriptor3, this);
      /**
       * Whether the selected page is an input
       *
       * @argument isInput
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isInput", _descriptor4, this);
      /**
       * Action which get triggered on click
       *
       * @function onClick
       */
      _initializerDefineProperty(this, "onClick", _descriptor5, this);
    }
    /**
     * Calculates if it is the current page.
     *
     * @computed _isActive
     * @type {Boolean}
     * @default false
     */
    get _isActive() {
      const page = this.page;
      const currentPage = this.currentPage;
      return page === currentPage;
    }
    _onInput(e) {
      if ((e === null || e === void 0 ? void 0 : e.key) === 'Enter') {
        var _e$target;
        const value = e === null || e === void 0 || (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.value;
        const parsedValue = Number(value);
        if (typeof parsedValue !== 'number' || !isFinite(parsedValue)) {
          throw new Error('Invalid input: the value is not a valid number');
        }
        if (parsedValue > this.totalPages) {
          this.onClick(this.totalPages);
          return;
        }
        if (parsedValue < 1) {
          this.onClick(1);
          return;
        }
        this.onClick(parsedValue);
      }
    }

    /*
     * Component click event. Will fire onClick action.
     */
    click() {
      const page = this.page;
      this.onClick(page);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "totalPages", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isInput", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiPaginationPageItemComponent);
});