define("@additive-apps/ui/components/ui-intro", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-intro", "@ember/string", "@ember/object"], function (_exports, _component, _uiIntro, _string, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiIntro.default,
    classNames: ['ui-intro', 'flex-grow-1', 'mw-100'],
    attributeBindings: ['size'],
    /**
     * The intros description
     * @property description
     * @type {String}
     * @default ''
     */
    description: '',
    /**
     * Whether description should be multiline
     *
     * @property isMultiline
     * @type {Boolean}
     * @default false
     */
    isMultiline: false,
    /**
     * Whether also the title should be secondary
     *
     * @property isSecondary
     * @type {Boolean}
     * @default false
     */
    isSecondary: false,
    /**
     * The size of the intro
     * Availbale sizes sm, md, lg
     *
     * @property size
     * @type {String}
     * @default sm
     */
    size: 'sm',
    /**
     * The intros titel
     * @property title
     * @type {String}
     * @default ''
     */
    title: '',
    _description: (0, _object.computed)('description', {
      get() {
        return (0, _string.htmlSafe)(this.description);
      }
    })
  });
});