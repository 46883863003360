define("@additive-apps/ui/templates/components/ui-filter-inputs/multi", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "GwnFLVpd",
    "block": "[[[8,[39,0],null,[[\"@classNames\",\"@label\",\"@placeholder\",\"@initialValue\",\"@selected\",\"@options\",\"@isMultiSelect\",\"@scrollToOption\",\"@objectKey\",\"@isSearchable\",\"@onChange\"],[\"w-100\",[30,0,[\"filter\",\"label\"]],[30,0,[\"filter\",\"label\"]],[30,0,[\"initialValue\"]],[30,0,[\"initialValue\"]],[30,0,[\"filter\",\"values\"]],true,true,\"label\",[30,0,[\"filter\",\"isSearchable\"]],[28,[37,1],[[30,0],\"onOptionChange\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,2,[\"type\"]],\"group\"],null],[[[1,\"    \"],[8,[30,1,[\"groupLabel\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]],[1,2]]]]]],[\"select\",\"option\"],false,[\"ui-select\",\"action\",\"if\",\"equal\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/multi.hbs",
    "isStrictMode": false
  });
});