define("@additive-apps/auth/utils/roles-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasAccess = _exports.default = _exports.ROLE_VIEWER = _exports.ROLE_OWNER = _exports.ROLE_MEMBER = _exports.ROLE_MANAGER = _exports.ROLE_CUSTOM = _exports.ROLE_ADMIN = void 0;
  const ROLE_OWNER = _exports.ROLE_OWNER = 'owner';
  const ROLE_ADMIN = _exports.ROLE_ADMIN = 'admin';
  const ROLE_MANAGER = _exports.ROLE_MANAGER = 'manager';
  const ROLE_MEMBER = _exports.ROLE_MEMBER = 'member';
  const ROLE_VIEWER = _exports.ROLE_VIEWER = 'viewer';
  const ROLE_CUSTOM = _exports.ROLE_CUSTOM = 'custom';
  const ROLES_MAP = {
    owner: [ROLE_OWNER],
    admin: [ROLE_OWNER, ROLE_ADMIN],
    manager: [ROLE_OWNER, ROLE_ADMIN, ROLE_MANAGER],
    member: [ROLE_OWNER, ROLE_ADMIN, ROLE_MANAGER, ROLE_MEMBER],
    viewer: [ROLE_OWNER, ROLE_ADMIN, ROLE_MANAGER, ROLE_MEMBER, ROLE_VIEWER],
    custom: [ROLE_OWNER, ROLE_ADMIN, ROLE_MANAGER, ROLE_MEMBER, ROLE_VIEWER, ROLE_CUSTOM] // just here for completion
  };

  /**
   * Checks if a given userRole respects the requiredRoles roles access map.
   *
   * @param {STRING} requiredRole the min. required access-role
   * @param {STRING} userRole the current users role
   */
  const hasAccess = (requiredRole, userRole) => {
    const rolesMap = ROLES_MAP[requiredRole];
    return rolesMap ? rolesMap.indexOf(userRole) > -1 : false;
  };
  _exports.hasAccess = hasAccess;
  var _default = _exports.default = ROLES_MAP;
});