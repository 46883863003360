define("@additive-apps/ui/components/ui-discard-changes", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-discard-changes"], function (_exports, _component, _uiDiscardChanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Simple dialog that gets shown as a warning when a user has unpersisted
   * changes and is in the process of leaving the actual edit view.
   *
   * @class ui-discard-changes
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiDiscardChanges.default,
    tagName: '',
    /**
     * Indicates if the dialog is open or not
     * @property isOpen
     * @type {Boolean}
     * @default false
     */
    isOpen: false,
    /**
     * Callback when the discard action gets clicked
     * @function onDiscard
     */
    onDiscard() {}
  });
});