define("@additive-apps/configurator/components/panel", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/function-util"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes, _functionUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="panel flex items-center h1 br1
      {{if this.error "ba b--red" ""}}
      {{if this._isClickable "cpointer"}}
      {{@class}}
      {{@classNames}}
    "
    role="button"
    {{on "click" this._onClick}}
  >
    <span class="font-sm secondary pr1">
      {{this.label}}
    </span>
    {{#if this.description}}
      <UiPopover as |pop|>
        <pop.trigger>
          {{svg-jar "info" class="secondary"}}
        </pop.trigger>
        <pop.content>
          {{this.description}}
        </pop.content>
      </UiPopover>
    {{/if}}
    {{#if this.warning}}
      <UiPopover as |pop|>
        <pop.trigger>
          {{svg-jar "warning" class="yellow"}}
        </pop.trigger>
        <pop.content>
          {{this.warning}}
        </pop.content>
      </UiPopover>
    {{/if}}
    <div class="flex-grow-1"></div>
    <div class="panel__content flex items-center">
      {{yield}}
    </div>
  </div>
  
  {{#if this.error}}
    <UiFormMessage @value={{this.error}} @state="error" />
  {{/if}}
  */
  {
    "id": "wiW3qaQ/",
    "block": "[[[11,0],[16,0,[29,[\"panel flex items-center h1 br1\\n    \",[52,[30,0,[\"error\"]],\"ba b--red\",\"\"],\"\\n    \",[52,[30,0,[\"_isClickable\"]],\"cpointer\"],\"\\n    \",[30,1],\"\\n    \",[30,2],\"\\n  \"]]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"_onClick\"]]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"font-sm secondary pr1\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"description\"]],[[[1,\"    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"info\"],[[\"class\"],[\"secondary\"]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"description\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"warning\"]],[[[1,\"    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,3],[\"warning\"],[[\"class\"],[\"yellow\"]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,4,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"warning\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"flex-grow-1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"panel__content flex items-center\"],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@value\",\"@state\"],[[30,0,[\"error\"]],\"error\"]],null],[1,\"\\n\"]],[]],null]],[\"@class\",\"@classNames\",\"pop\",\"pop\",\"&default\"],false,[\"if\",\"on\",\"ui-popover\",\"svg-jar\",\"yield\",\"ui-form-message\"]]",
    "moduleName": "@additive-apps/configurator/components/panel.hbs",
    "isStrictMode": false
  });
  /**
   * Wrapper component with preset styling adjustments
   *
   * @class Panel
   */
  let Panel = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class Panel extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "label", _descriptor, this);
      _initializerDefineProperty(this, "description", _descriptor2, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor3, this);
      _initializerDefineProperty(this, "error", _descriptor4, this);
      _initializerDefineProperty(this, "warning", _descriptor5, this);
      _initializerDefineProperty(this, "onClick", _descriptor6, this);
    }
    get _isClickable() {
      return (0, _functionUtil.hasBody)(this.onClick) && !this.isDisabled;
    }
    _onClick() {
      if (this.isDisabled) {
        return;
      }
      this.onClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "warning", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Panel);
});