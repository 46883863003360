define("@additive-apps/configurator/validations/typography", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fontObjectValidations = void 0;
  /**
   * Validates typography item
   *
   * Should later be extended to validate correct hex-colors and the size format to
   * @param {Service} intl
   */
  const fontObjectValidations = _exports.fontObjectValidations = function fontObjectValidations(intl) {
    let objectKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const validations = {};
    ['fontFamily', 'fontSize', 'letterSpacing', 'lineHeight', 'fontSizeMobile', 'letterSpacingMobile', 'lineHeightMobile'].forEach(key => {
      if (objectKeys.indexOf(key) > -1) {
        validations[key] = [(0, _validators.validatePresence)({
          presence: true,
          message: intl.t('global.errors.required')
        })];
      }
    });
    return validations;
  };
});