define("@additive-apps/ui/components/ui-when", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-when", "@ember/service", "@ember/object/computed", "@ember/object", "@additive-apps/auth/utils/roles-map", "@additive-apps/utils/utils/plans"], function (_exports, _component, _uiWhen, _service, _computed, _object, _rolesMap, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC helper to hide new features from non-additive/non-partner user,
   * depending on given checks that should pass.
   *
   * ```hbs
   * <UiWhen @partner=true >
   *  Just for PARTNER
   * </UiWhen>
   * ```
   *
   * ```hbs
   * <UiWhen @partner=true @role='admin'>
   *  Just for PARTNER that have admin role
   * </UiWhen>
   * ```
   *
   * ```hbs
   * <UiWhen @partner=true @role='manager' plan='professional'>
   *    PARTNER-Manager and where the org. has a professional or enterprise plan for the app
   * </UiWhen>
   * ```
   *
   * ```hbs
   * <UiWhen @additive=true @role='admin'>
   *    ADDITIVE-Admin
   * </UiWhen>
   * ```
   *
   * @class ui-when
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    currentUser: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    layout: _uiWhen.default,
    tagName: '',
    /**
     * If true, the content will be displayed to partner only
     *
     * @property partner
     * @type {Boolean}
     * @default false
     */
    partner: false,
    /**
     * If true, the content will be displayed to additive-user only
     *
     * @property additive
     * @type {Boolean}
     * @default false
     */
    additive: false,
    /**
     * The content will be shown to the user if he satisfies
     * the given role-condition.
     *
     * Supported values are: manager, admin & owner
     *
     * @property role
     * @type {String}
     * @default null
     */
    role: null,
    /**
     * The content will be shown to the user if his organization
     * satisfies the given app-plan-condition
     *
     * Supported values are: professional & enterprise
     *
     * @property plan
     * @type {String}
     * @default null
     */
    plan: null,
    /**
     * Conjunction between all set restrictions
     *
     * In case no condtions are set it's always true, otherwise every conditions
     * that should be checked has to pass in order for the content to be rendered.
     *
     * @property _showContent
     * @type {String}
     * @default true
     * @private
     */
    _showContent: (0, _computed.and)('_hasPrivilegedAccess', '_hasRoleAccess', '_hasPlanAccess'),
    /**
     * The current organization-user role
     *
     * @property _userRole
     * @type {String}
     * @default undefined
     * @private
     */
    _userRole: (0, _computed.alias)('currentUser.user.role'),
    /**
     * The current organizations app-plan
     *
     * @property _planName
     * @type {String}
     * @default undefined
     * @private
     */
    _planName: (0, _computed.alias)('uiAppSettings.planName'),
    /**
     * Whether the user is part of additive org or not
     *
     * @computed _isAdditive
     * @private
     */
    _isAdditive: (0, _computed.alias)('currentUser.isAdditiveUser'),
    /**
     * Whether the user is part of partner org or not
     *
     * @computed _isPartner
     * @private
     */
    _isPartner: (0, _computed.alias)('currentUser.isPartnerUser'),
    /**
     * Whether the current app-plan is professional or higher
     *
     * @computed _isProfessionalPlan
     * @private
     */
    _isProfessionalPlan: (0, _computed.alias)('uiAppSettings.isProfessionalPlan'),
    /**
     * Whether the current app-plan is enterprise
     *
     * @computed _isEnterprisePlan
     * @private
     */
    _isEnterprisePlan: (0, _computed.alias)('uiAppSettings.isEnterprisePlan'),
    /**
     * Checks if the app-plan satisfies the given plan-restriction
     *
     * @computed _hasPlanAccess
     * @private
     */
    _hasPlanAccess: (0, _object.computed)('_isEnterprisePlan', '_isProfessionalPlan', 'plan', {
      get() {
        const plan = this.plan;
        switch (plan) {
          case _plans.PLAN_PROFESSIONAL:
            return this._isProfessionalPlan;
          case _plans.PLAN_ENTERPRISE:
            return this._isEnterprisePlan;
          default:
            return true;
        }
      }
    }),
    /**
     * Checks if the users role satisfies the given role-restriction
     *
     * @computed _hasPlanAccess
     * @private
     */
    _hasRoleAccess: (0, _object.computed)('role', '_userRole', {
      get() {
        const role = this.role;
        switch (!!role) {
          case false:
            return true;
          default:
            return (0, _rolesMap.hasAccess)(role, this._userRole);
        }
      }
    }),
    /**
     * Checks if the user is part of a partner or additive org
     *
     * @computed _hasPrivilegedAccess
     * @private
     */
    _hasPrivilegedAccess: (0, _object.computed)('_isAdditive', '_isPartner', 'additive', 'partner', {
      get() {
        let showContent = true;
        if (this.partner && !this._isPartner) {
          showContent = false;
        } else if (this.additive && !this._isAdditive) {
          showContent = false;
        }
        return showContent;
      }
    })
  });
});