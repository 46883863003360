define("@additive-apps/media/templates/components/mm-cropper-view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "wy44Xp57",
    "block": "[[[8,[39,0],null,[[\"@containerId\"],[[30,0,[\"containerId\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"mm-cropper-view ohidden fixed top-0 right-0 bottom-0 left-0 bg-black flex justify-center items-center flex-grow-0\"],[12],[1,\"\\n    \"],[8,[30,1,[\"container\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,2,[[28,[37,2],null,[[\"cropper\"],[[50,\"mm-cropper\",0,null,[[\"onDataChanged\",\"onReady\",\"class\"],[[28,[37,4],[[30,0],\"onCropDataChanged\"],null],[28,[37,4],[[30,0],\"onCropperReady\"],null],\"w-100 h-100 flex justify-center items-center flex-grow-0\"]]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"topRight\"]],null,[[\"@class\"],[\"flex absolute top-1 right-1 top-right\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@class\",\"@icon\",\"@label\",\"@isDisabled\",\"@onClick\"],[\"mr1\",\"save\",[28,[37,6],[\"uiActions.save\"],null],[30,0,[\"_isCropperLoading\"]],[28,[37,4],[[30,0],\"onSave\"],null]]],null],[1,\"\\n      \"],[8,[39,7],null,[[\"@icon\",\"@isBlur\",\"@onClick\"],[\"close\",true,[28,[37,4],[[30,0],[30,0,[\"onClose\"]]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"_isCropperLoading\"]],[[[1,\"      \"],[10,0],[14,0,\"loading-overlay absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center\"],[12],[1,\"\\n        \"],[8,[39,9],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"view\",\"&default\"],false,[\"mm-fullscreen-view\",\"yield\",\"hash\",\"component\",\"action\",\"ui-button\",\"t\",\"ui-action-button\",\"if\",\"ui-loading\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-cropper-view.hbs",
    "isStrictMode": false
  });
});