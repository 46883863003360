define("@additive-apps/ui/templates/components/ui-editor-legacy", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ivPchwlJ",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@inputElementId\",\"@helperText\",\"@onHelp\",\"@message\",\"@charCount\",\"@maxlength\",\"@recommendedLength\",\"@recommendedWarning\",\"@state\"],[[30,0,[\"label\"]],[30,0,[\"inputElementId\"]],[30,0,[\"helperText\"]],[30,0,[\"onHelp\"]],[30,0,[\"message\"]],[30,0,[\"_charCount\"]],[30,0,[\"maxlength\"]],[30,0,[\"recommendedLength\"]],[30,0,[\"recommendedWarning\"]],[30,0,[\"state\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"ui-editor br1\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@allowedWebLinkPlaceholders\",\"@isLight\",\"@editorSentinelId\",\"@offset\",\"@contentElement\",\"@isDisabled\",\"@hasHeadingActions\",\"@onActionClick\"],[[30,0,[\"allowedWebLinkPlaceholders\"]],[30,0,[\"isLight\"]],[30,0,[\"_editorSentinelId\"]],[30,0,[\"actionBarOffset\"]],[30,0,[\"_contentElement\"]],[30,0,[\"isDisabled\"]],[30,0,[\"hasHeadingActions\"]],[28,[37,2],[[30,0],\"execCommand\"],null]]],null],[1,\"\\n    \"],[10,0],[15,\"contenteditable\",[30,0,[\"_isEditable\"]]],[14,0,\"ui-editor__content mh4 mb4 mv4-sm font-md primary\"],[12],[13],[1,\"\\n    \"],[10,0],[15,1,[30,0,[\"_editorSentinelId\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"ui-form-control\",\"ui-editor/action-bar\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-editor-legacy.hbs",
    "isStrictMode": false
  });
});