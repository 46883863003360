define("@additive-apps/ui/mixins/ui-full-width-view", ["exports", "@ember/object/mixin", "@ember/service", "@additive-apps/ui/utils/dom-util"], function (_exports, _mixin, _service, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const navDrawerStateKey = 'ui-navigation-drawer';

  /**
   * Mixin that hides the navigation drawer in the current Route
   *
   * @class UiFullWidthView
   * @type Mixin
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = _mixin.default.create({
    uiState: (0, _service.inject)(),
    /**
     * hides the navigation drawer when the route is activated
     * @function activate
     */
    activate() {
      const isDestroying = this.isDestroying;

      // wait for component to initialize navdrawer state
      (0, _domUtil.nextTick)().then(() => isDestroying ? null : this.uiState.getState(navDrawerStateKey).hide());
    },
    /**
     * shows the navigation drawer again when the user leaves the route
     * @function deactivate
     */
    deactivate() {
      this.uiState.getState(navDrawerStateKey).show();
    }
  });
});