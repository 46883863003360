define("@additive-apps/auth/mixins/access-guard", ["exports", "@ember/object/mixin", "@ember/application", "@additive-apps/auth/utils/roles-map"], function (_exports, _mixin, _application, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * If the user is unauthenticated, invoke `callback`
   *
   * @param {ApplicationInstance} owner The ApplicationInstance that owns the service
   * @param {Transition} transition Transition for the user's original navigation
   * @param {Function} callback Callback that will be invoked after the user access-rights have been checked
   */
  const redirectIfUnauthorized = (owner, requiredRole, callback) => {
    const currentUser = owner.lookup('service:currentUser');
    const organizationUser = currentUser.user;
    try {
      // in case orgRole hasn't been loaded, block beforeModel-hook
      if (!organizationUser) {
        return currentUser.getUser().then(orgUser => {
          if (!orgUser || !orgUser.role) {
            callback(false);
            return;
          }
          const isAllowed = (0, _rolesMap.hasAccess)(requiredRole, orgUser.role);
          callback(isAllowed);
        });
      } else {
        // resolve immediatly if orgRole is known
        const organizationUserRole = organizationUser.role;
        const isAllowed = (0, _rolesMap.hasAccess)(requiredRole, organizationUserRole) || false;
        callback(isAllowed);
        return false;
      }
    } catch (e) {
      /* eslint-disable no-console */
      console && console.warn(`acces-guard-mixin unexpected error, access denied: '${e}'`);
      callback(false);
      return false;
    }
  };

  /**
   *
   * this mixin should be used as an access guard on route activation.
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = _mixin.default.create({
    /**
     * Role that is needed to get authorized-access
     * to the current route
     *
     * @property requiredRole
     * @type {String}
     */
    requiredRole: undefined,
    /**
     * Route the user gets redirected if unauthorized
     *
     * @property routeIfUnauthorized
     * @type {String}
     */
    routeIfUnauthorized: 'instance',
    /**
     * __If `beforeModel` is overridden in a route that uses this mixin, the route's
     * implementation must call `this._super(...arguments)`__ so that the mixin's
     * `beforeModel` method is actually executed.
     * @method beforeModel
     */
    beforeModel() {
      const requiredRole = this.requiredRole;

      // we have to block the request
      return redirectIfUnauthorized((0, _application.getOwner)(this), requiredRole, isAccessGranted => {
        if (isAccessGranted || !requiredRole) {
          return this._super(...arguments);
        } else {
          const routeIfUnauthorized = this.routeIfUnauthorized || 'instance';
          this.transitionTo(routeIfUnauthorized);
        }
      });
    }
  });
});