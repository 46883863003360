define("@additive-apps/ui/components/ui-searchbar", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@additive-apps/ui/templates/components/ui-searchbar", "@additive-apps/ui/utils/function-util"], function (_exports, _component, _object, _utils, _uiSearchbar, _functionUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * A ui-representation for the global searchbar.
   *
   * The only usecase the component gets used is inside a navbar.
   *
   * @class ui-searchbar
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSearchbar.default,
    classNames: ['ui-searchbar', 'flex flex-row items-center'],
    /**
     * whether the onClose function is defined
     *
     * @computed _hasOnCloseFunction
     * @type {Boolean}
     */
    _hasOnCloseFunction: (0, _object.computed)('onClose', {
      get() {
        return (0, _functionUtil.hasBody)(this.onClose);
      }
    }),
    /**
     * Callback function that gets triggered after the search-input
     * gets updated
     *
     * @function onSearch
     */
    onSearch() {},
    /**
     * Function gets executed when the users clicks thx x-icon
     *
     * @function onClose
     */
    onClose() {},
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      this.inputElement = this.element.querySelector('input');
      if (!(0, _utils.isNone)(this.value)) {
        this.inputElement.value = this.value || '';
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      const inputValue = this.inputElement && this.inputElement.value;
      // empty input value will be string `''` so treat empty value as string as well
      if (inputValue !== (this.value || '')) {
        this.inputElement.value = this.value;
      }
    }
  });
});