define("@additive-apps/ui/helpers/ui-to-string", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiToString = uiToString;
  function uiToString(_ref /*, hash*/) {
    let [value] = _ref;
    return String(value);
  }
  var _default = _exports.default = (0, _helper.helper)(uiToString);
});