define("@additive-apps/ui/templates/components/ui-timepicker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "f4Lrw3KX",
    "block": "[[[8,[39,0],null,[[\"@options\",\"@onChange\",\"@isReadOnly\"],[[30,0,[\"options\"]],[28,[37,1],[[30,0],\"onChangeSelect\"],null],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@isPlain\",\"@active\",\"@placeholder\",\"@isTouched\",\"@infoMessage\",\"@errorMessage\",\"@value\",\"@onChange\",\"@theme\",\"@isReadOnly\",\"@autocomplete\"],[[30,0,[\"label\"]],[30,0,[\"isPlain\"]],[30,1,[\"isOpen\"]],[30,0,[\"placeholder\"]],[30,0,[\"isTouched\"]],[30,0,[\"infoMessage\"]],[52,[30,0,[\"errorMessage\"]],[30,0,[\"errorMessage\"]],[52,[28,[37,4],[[30,0,[\"_changeset\",\"error\",\"time\"]],\"validation\"],null],[28,[37,5],[\"uiTimepicker.errorMessage\"],null]]],[30,0,[\"_inputValue\"]],[28,[37,1],[[30,0],\"onInputChange\"],null],[30,0,[\"theme\"]],[30,0,[\"isReadOnly\"]],\"false\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"left\"]],null,[[\"@class\"],[\"flex justify-center pr1 secondary\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[\"watch\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"      \"],[8,[39,9],null,[[\"@class\",\"@onClick\",\"@selectedItem\",\"@value\"],[\"flex ph2 pv1 font-sm\",[30,1,[\"selectOption\"]],[30,1,[\"selectedItem\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"key\"]]],[1,\"\\n        \"],[10,1],[14,0,\"pl05 secondary\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[\"uiTimepicker.timeUnit\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"selectable\",\"input\",\"item\"],false,[\"ui-selectable\",\"action\",\"ui-input\",\"if\",\"get\",\"t\",\"svg-jar\",\"each\",\"-track-array\",\"ui-select-option\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-timepicker.hbs",
    "isStrictMode": false
  });
});