define("@additive-apps/ui/helpers/ui-format-currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiFormatCurrency = uiFormatCurrency;
  function uiFormatCurrency(params) {
    const value = params[0];
    const currency = params[1] || 'EUR';
    const language = params[2] || currency === 'USD' ? 'en' : 'de';
    return (value / 100).toLocaleString(language, {
      currency,
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  var _default = _exports.default = (0, _helper.helper)(uiFormatCurrency);
});