define("@additive-apps/auth/mixins/application-auth-route-mixin", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object", "@ember/application", "@additive-apps/auth/utils/url", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _mixin, _service, _object, _application, _url, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint ember/no-new-mixins: 0 */
  // eslint-disable-next-line ember/no-mixins
  /**
   *  The mixin for the application route, defining the methods,
   *  that are called when the session is authenitcated.
   *
   *  When the session is authenticated the User is loaded and then redirected
   *  to the 'routeAfterAuthentication'.
   *  If this route is defined as 'instance' the organizations of the user are loaded
   *  and the user is redirected to the first organization, otherwise he is simply
   *  redirected to the specified route.
   *
   *  @mixin ApplicationAuthRouteMixin
   *  @module additive-auth/mixins/application-auth-route-mixin
   *  @extends Ember.Mixin
   *  @mixes ember-simple-auth/mixins/application-route-mixin
   *  @public
   */
  var _default = _exports.default = _mixin.default.create(_applicationRouteMixin.default, {
    currentUser: (0, _service.inject)(),
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    authUtils: (0, _service.inject)(),
    /**
     *  The route to transition to after successful authentication.
     *  Can be overwritten.
     *
     *  @property routeAfterAuthentication
     *  @type String
     *  @default 'instance'
     */
    routeAfterAuthentication: 'instance',
    /**
     *  Whether it should fetch first available org and set as `currentOrganization`.
     *
     *  @property fetchFirstOrgAfterAuthentication
     *  @type Boolean
     *  @default true
     */
    fetchFirstOrgAfterAuthentication: true,
    model() {
      return this._loadCurrentUser();
    },
    /**
     * ENV config of the aplication
     *
     * @property ENV
     * @type {Object}
     */
    ENV: (0, _object.computed)({
      get() {
        return (0, _application.getOwner)(this).resolveRegistration('config:environment');
      }
    }),
    /**
     * The uri to which the user is redirected back after the login.
     *
     * @property redirectUri
     * @type String
     */
    redirectUri: (0, _object.computed)('ENV.APP.{baseHost,redirectRoute}', {
      get() {
        var _this$ENV, _this$ENV2, _this$ENV3;
        const baseUrl = ((_this$ENV = this.ENV) === null || _this$ENV === void 0 ? void 0 : _this$ENV.environment) === 'staging' ? window.location.origin : (_this$ENV2 = this.ENV) === null || _this$ENV2 === void 0 || (_this$ENV2 = _this$ENV2.APP) === null || _this$ENV2 === void 0 ? void 0 : _this$ENV2.baseHost;
        return `${baseUrl}${(_this$ENV3 = this.ENV) === null || _this$ENV3 === void 0 || (_this$ENV3 = _this$ENV3.APP) === null || _this$ENV3 === void 0 ? void 0 : _this$ENV3.redirectRoute}`;
      }
    }),
    /**
     * Overrides simple-auths sessionAuthenticated method.
     * The user is loaded and then redirected to the specified route.
     *
     * @method sessionAuthenticated
     * @public
     */
    sessionAuthenticated() {
      this._loadCurrentUser().then(() => this.handleRedirect());
    },
    /**
     * Loads the current user when someone tries to access the application.
     *
     * In case the access happens unauthenticated the current user-session gets
     * invalidated.
     *
     * @method _loadCurrentUser
     * @private
     */
    _loadCurrentUser() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.getUser().catch(() => {
        var _this$session;
        (_this$session = this.session) === null || _this$session === void 0 || _this$session.invalidate();
      });
    },
    /**
     * Called when the session is invalidated, either because there is an unauthorized
     * request or the token is expired.
     * The default behaviour of ember-simple-auth is to redirect to the application route
     * we overwrite this behaviour in order to keep the route information, to which the
     * user can be redirected, once the user is authenticated again.
     *
     * @function sessionInvalidated
     */
    sessionInvalidated() {
      if (this.session._stateParams) {
        var _this$ENV4, _this$ENV5, _this$authUtils;
        const {
          targetName,
          routingParams
        } = this.session._stateParams;
        const stateParams = (0, _url.objectToQueryString)({
          continue: {
            route: targetName,
            params: routingParams,
            query: window.location.search.substring(1)
          }
        });
        const queryParams = (0, _url.objectToQueryString)({
          response_type: 'code',
          client_id: `${(_this$ENV4 = this.ENV) === null || _this$ENV4 === void 0 || (_this$ENV4 = _this$ENV4.APP) === null || _this$ENV4 === void 0 ? void 0 : _this$ENV4.clientId}`,
          redirect_uri: this.redirectUri,
          state: stateParams
        });
        const uri = '/oauth/authorize';
        const redirectUrl = `${(_this$ENV5 = this.ENV) === null || _this$ENV5 === void 0 || (_this$ENV5 = _this$ENV5.APP) === null || _this$ENV5 === void 0 ? void 0 : _this$ENV5.accountApiHost}${uri}?${queryParams}`;
        (_this$authUtils = this.authUtils) === null || _this$authUtils === void 0 || _this$authUtils.redirectToUrl(redirectUrl);
      } else {
        this._super(...arguments);
      }
    },
    /**
     * Success authenticated.
     * Handle redirecting.
     *
     * We currently have following different redirectings:
     *
     * * `state.continue` url query-param is present:
     *    * `state.continue` Object which holds infos of the route where we should redirect after authenticating
     *    * `state.continue.route`  The routename
     *    * `state.continue.params` The route params (Like `instance_id` or `model_id` of a detail-route)
     *    * `state.continue.query`  The query params (Like `token` for invite acceptactions)
     * * Default redirect is route to `this.routeAfterAuthentication`
     *
     * `instance_id` is a reserved `state.continue.params`-param for the `instance`.
     * When provided we fetch this organization and set it as `currentUser.currentOrganization`.
     */
    handleRedirect() {
      var _this$router;
      let oAuthContinueState = null;
      if ((_this$router = this.router) !== null && _this$router !== void 0 && _this$router.isActive('auth.callback')) {
        const state = this.controllerFor('auth.callback').state;
        const urlState = state && (0, _url.queryStringToObject)(decodeURIComponent(state));
        oAuthContinueState = (urlState === null || urlState === void 0 ? void 0 : urlState.continue) || {};
      }
      if (oAuthContinueState && Object.keys(oAuthContinueState).length > 0) {
        const redirectRoute = oAuthContinueState.route;
        const queryParams = (0, _url.queryStringToObject)(oAuthContinueState.query) || {};
        // redirect with params
        if (oAuthContinueState.params && Object.keys(oAuthContinueState.params).length > 0) {
          const transitionParams = oAuthContinueState.params;
          const instanceId = transitionParams === null || transitionParams === void 0 ? void 0 : transitionParams.instance_id;
          if (transitionParams && instanceId) {
            var _this$ENV6;
            const url = ((_this$ENV6 = this.ENV) === null || _this$ENV6 === void 0 || (_this$ENV6 = _this$ENV6.APP) === null || _this$ENV6 === void 0 ? void 0 : _this$ENV6.accountApiHost) + `/api/organizations/${instanceId}`;
            /**
             * Fetch org and set in currentUser service.
             * For org not found case we cannot use ember store as it has default behavior for 404 response code.
             */
            return this.authenticatedFetch.fetch(url, {
              method: 'GET'
            }, null, [422, 404]).then(response => {
              // Org not found or other error
              if (!response || !response.ok) {
                this.router.transitionTo('application');
                return;
              }
              return response.json().then(jsonResponse => {
                const {
                  organization
                } = jsonResponse;
                (0, _object.set)(this, 'currentUser.currentOrganization', organization);
                const paramValues = Object.keys(transitionParams).map(key => {
                  return transitionParams[key];
                });

                /**
                 * currently qps that are not defined in the redirectRoute controller
                 * are lost in the transition
                 *
                 * https://github.com/emberjs/ember.js/issues/5526
                 */
                return this.replaceWith.apply(this, [].concat(redirectRoute, paramValues, {
                  queryParams
                }));
              });
            });
          }
          if (this.fetchFirstOrgAfterAuthentication) {
            var _this$ENV7;
            const has_app = (_this$ENV7 = this.ENV) === null || _this$ENV7 === void 0 || (_this$ENV7 = _this$ENV7.APP) === null || _this$ENV7 === void 0 ? void 0 : _this$ENV7.appId;
            return this.store.query('organization', {
              has_app
            }).then(organizations => {
              const firstOrganization = organizations.get('firstObject');
              (0, _object.set)(this, 'currentUser.currentOrganization', firstOrganization);
              const paramValues = Object.keys(transitionParams || {}).map(key => {
                return transitionParams[key];
              });
              return this.replaceWith.apply(this, [].concat(redirectRoute, paramValues, {
                queryParams
              }));
            });
          }
          const paramValues = Object.keys(transitionParams || {}).map(key => {
            return transitionParams[key];
          });
          return this.replaceWith.apply(this, [].concat(redirectRoute, paramValues, {
            queryParams
          }));
        }
        return this.replaceWith(redirectRoute, {
          queryParams
        });
      } else if (this.fetchFirstOrgAfterAuthentication) {
        var _this$ENV8;
        const has_app = (_this$ENV8 = this.ENV) === null || _this$ENV8 === void 0 || (_this$ENV8 = _this$ENV8.APP) === null || _this$ENV8 === void 0 ? void 0 : _this$ENV8.appId;
        return this.store.query('organization', {
          has_app
        }).then(organizations => {
          const firstOrganization = organizations.get('firstObject');
          (0, _object.set)(this, 'currentUser.currentOrganization', firstOrganization);
          return this.replaceWith(this.routeAfterAuthentication);
        });
      }
      return this.replaceWith(this.routeAfterAuthentication);
    }
  });
});