define("@additive-apps/media/templates/components/ui-carousel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "a2q9KPxC",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\"],[[28,[37,1],null,[[\"previous\",\"next\"],[[50,\"ui-carousel/trigger\",0,null,[[\"type\",\"onTrigger\"],[\"previous\",[28,[37,3],[[30,0],[30,0,[\"onTrigger\"]]],null]]]],[50,\"ui-carousel/trigger\",0,null,[[\"type\",\"onTrigger\"],[\"next\",[28,[37,3],[[30,0],[30,0,[\"onTrigger\"]]],null]]]]]]]]]]]],[1,\"\\n\"],[10,0],[14,0,\"ui-carousel__slide-container h-100\"],[15,5,[30,0,[\"_slideContainerInlineStyles\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"useLazyRendering\"]],[[[1,\"    \"],[8,[39,5],null,[[\"@parentState\"],[[30,0,[\"state\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,1,[[28,[37,1],null,[[\"container\",\"activeIndex\"],[[50,\"ui-carousel/container\",0,null,[[\"parentState\"],[[30,0,[\"state\"]]]]],[30,0,[\"state\",\"activeIndex\"]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\",\"if\",\"ui-carousel/grow-container\"]]",
    "moduleName": "@additive-apps/media/templates/components/ui-carousel.hbs",
    "isStrictMode": false
  });
});