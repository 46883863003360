define("@additive-apps/media/templates/components/ui-carousel/trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "cbb0V8sc",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\"],[[28,[37,2],[[30,0],[30,0,[\"onTrigger\"]],[30,0,[\"type\"]]],null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"action\"]]",
    "moduleName": "@additive-apps/media/templates/components/ui-carousel/trigger.hbs",
    "isStrictMode": false
  });
});