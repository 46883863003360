define("@additive-apps/ui/components/ui-chart-legend", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/string"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-chart-legend flex flex-column pv05 pr3-sm font-sm {{this.classNames}}" ...attributes>
    <div class="flex items-center">
      <div class="ui-legend__point" style={{this.styles.backgroundColor}}></div>
      <div class="pl1" style={{this.styles.color}}>
        {{this.item.label}}
      </div>
    </div>
  
    {{#if this.item.content}}
      <div class="flex pl2 secondary">
        <span class="pr05 primary">
          {{format-number this.total.sum}}
        </span>
        <span>
          / {{if this.item.total (format-number this.item.total) "-"}}
        </span>
        <span class="pl1">
          ({{if this.total.percentage (concat (format-number this.total.percentage) "%") "-"}})
        </span>
      </div>
  
      {{#each this.content as |stack|}}
        <div class="flex pl2 secondary">
          <span class="pr05 primary" style={{stack.style}}>
            {{format-number stack.value}}
          </span>
          <span>
            {{stack.label}}
          </span>
        </div>
      {{/each}}
    {{/if}}
  </div>
  */
  {
    "id": "xg+mx35y",
    "block": "[[[11,0],[16,0,[29,[\"ui-chart-legend flex flex-column pv05 pr3-sm font-sm \",[30,0,[\"classNames\"]]]]],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui-legend__point\"],[15,5,[30,0,[\"styles\",\"backgroundColor\"]]],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"pl1\"],[15,5,[30,0,[\"styles\",\"color\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"item\",\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"item\",\"content\"]],[[[1,\"    \"],[10,0],[14,0,\"flex pl2 secondary\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"pr05 primary\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"total\",\"sum\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        / \"],[1,[52,[30,0,[\"item\",\"total\"]],[28,[37,1],[[30,0,[\"item\",\"total\"]]],null],\"-\"]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"pl1\"],[12],[1,\"\\n        (\"],[1,[52,[30,0,[\"total\",\"percentage\"]],[28,[37,2],[[28,[37,1],[[30,0,[\"total\",\"percentage\"]]],null],\"%\"],null],\"-\"]],[1,\")\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"content\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex pl2 secondary\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"pr05 primary\"],[15,5,[30,2,[\"style\"]]],[12],[1,\"\\n          \"],[1,[28,[35,1],[[30,2,[\"value\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[30,2,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null]],[]],null],[13]],[\"&attrs\",\"stack\"],false,[\"if\",\"format-number\",\"concat\",\"each\",\"-track-array\"]]",
    "moduleName": "@additive-apps/ui/components/ui-chart-legend.hbs",
    "isStrictMode": false
  });
  /**
   * @class ui-chart-legend
   */
  let UiChartLegendComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiChartLegendComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The class names
       *
       * @argument classNames
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "classNames", _descriptor, this);
      /**
       * the legend item
       *
       * @property item
       * @type {Object}
       */
      _initializerDefineProperty(this, "item", _descriptor2, this);
      /**
       * properties that should be ignored when computing the sum
       *
       * @property ignoreSum
       * @type {String}
       */
      _initializerDefineProperty(this, "ignoreSum", _descriptor3, this);
    }
    /**
     * the styles for the legend indicator
     *
     * @computed styles
     * @type {Object}
     */
    get styles() {
      const {
        item
      } = this;
      const dataColor = item.legendColor || item.borderColor || item.content && item.content[0].color;
      return {
        color: (0, _string.htmlSafe)(`color: ${dataColor}`),
        backgroundColor: (0, _string.htmlSafe)(`background-color: ${dataColor}`)
      };
    }

    /**
     * the content of the legend, including the styles
     *
     * @computed content
     * @type {Object[]}
     */
    get content() {
      if (this.item.content) {
        const data = [...this.item.content];
        return data.map(item => {
          const newItem = _objectSpread({}, item);
          newItem.style = item.color && (0, _string.htmlSafe)(`color: ${item.color}`);
          return newItem;
        });
      }
      return [];
    }
    /**
     * the second row of the legend containing the totals and percent values
     *
     * @computed total
     * @type {Object}
     */
    get total() {
      const {
        total
      } = this.item;
      const ignoreSumProperties = (this.ignoreSum || '').split(',');
      const content = (this.item && this.item.content || []).filter(item => ignoreSumProperties.indexOf(item.label) < 0);
      const values = content.map(item => item.value);
      const sum = values.reduce((acc, value) => acc + value);
      const percentage = total ? ((sum / total || 0) * 100).toFixed(0) : null;
      return {
        sum,
        percentage
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ignoreSum", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiChartLegendComponent);
});