define("@additive-apps/ui/components/ui-item-menu", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-item-menu", "@additive-apps/ui/constants"], function (_exports, _component, _uiItemMenu, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component that renders a ui-menu including its trigger
   *
   * ```hbs
   * {{#ui-item-menu
   *    menuContentClass
   *    menuContentWidth
   *    triggerIcon
   *    as |menu|
   * }}
   *   {{menu.menuItem
   *     onClick
   *   }}
   * {{/ui-item-menu}}
   * ```
   *
   * @class ui-item-menu
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiItemMenu.default,
    tagName: '',
    /**
     * Ui-Menus `horizontalPosition`
     *
     * @argument horizontalPosition
     * @type {String}
     * @default "auto-right"
     */
    horizontalPosition: _constants.DEFAULT_MENU_POSITION.horizontal,
    /**
     * Transform style for the menu content
     *
     * @argument menuContentTransform
     * @type {String}
     * @default ""
     */
    menuContentTransform: '',
    /**
     * The width of the menu content
     *
     * @argument menuContentWidth
     * @type {String}
     * @default "2"
     */
    menuContentWidth: '2',
    /**
     * Whether events should bubble
     *
     * @argument {Boolean} stopBubbling
     * @default true
     */
    stopBubbling: true,
    /**
     * The default icon used for the ui-menu
     *
     * @argument triggerIcon
     * @type {String}
     * @default "more"
     */
    triggerIcon: 'more',
    /**
     * Ui-Menus `verticalPosition`
     *
     * @argument verticalPosition
     * @type {String}
     * @default "auto"
     */
    verticalPosition: _constants.DEFAULT_MENU_POSITION.vertical
  });
});