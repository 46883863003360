define("@additive-apps/ui/components/ui-radio-button-group", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-radio-button-group"], function (_exports, _component, _uiRadioButtonGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component to select one single option among a list of options
   *
   * ```hbs
   * {{#ui-radio-button-group
   *   options
   *   selected
   *   as |group option|
   * }}
   *   {{group.option
   *     label=option.label
   *     value=option.value
   *   }}
   * {{/ui-radio-button-group}}
   * ```
   *
   * @class ui-radio-button-group
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiRadioButtonGroup.default,
    classNames: ['ui-radio-button-group', 'flex'],
    /**
     * the options to be listed as radio buttons
     *
     * @property options
     * @type {Object[]}
     * @default undefined
     */
    options: undefined,
    /**
     * the value of the currently selected radio button
     *
     * @property selected
     * @type {Any}
     * @default undefined
     */
    selected: undefined
  });
});