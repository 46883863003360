define("@additive-apps/ui/components/ui-form-view", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-form-view flex flex-column w-100" ...attributes>
    <div class="navigation-bar-spacer">
      {{yield
        (hash
          navbar=(component "ui-navigation-bar" isSentinelHidden=true onClose=this.onClose)
        )
      }}
    </div>
  
    <div class="ui-form-view__content relative flex-md flex-row-md flex-grow-1">
      <div class="ui-form-view__main oauto flex-grow-1">
        {{yield
          (hash
            main=(component "ui-content")
          )
        }}
      </div>
  
      <div class="ui-form-view__detail oauto">
        {{yield
          (hash
            detail=(component "ui-content")
          )
        }}
      </div>
    </div>
  </div>
  */
  {
    "id": "mu29vL/l",
    "block": "[[[11,0],[24,0,\"ui-form-view flex flex-column w-100\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"navigation-bar-spacer\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,1],null,[[\"navbar\"],[[50,\"ui-navigation-bar\",0,null,[[\"isSentinelHidden\",\"onClose\"],[true,[30,0,[\"onClose\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"ui-form-view__content relative flex-md flex-row-md flex-grow-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui-form-view__main oauto flex-grow-1\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,1],null,[[\"main\"],[[50,\"ui-content\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"ui-form-view__detail oauto\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,1],null,[[\"detail\"],[[50,\"ui-content\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-form-view.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});