define("@additive-apps/ui/templates/components/ui-form-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZyFpjwO5",
    "block": "[[[10,0],[14,0,\"pr05 ellipsis\"],[12],[1,\"\\n  \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"pl05\"],[12],[1,\"\\n\"],[41,[30,0,[\"computedCharCount\"]],[[[1,\"    \"],[1,[30,0,[\"computedCharCount\"]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-form-message.hbs",
    "isStrictMode": false
  });
});