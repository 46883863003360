define("@additive-apps/media/components/mm-fullscreen-view", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-fullscreen-view", "@ember/object", "@ember/service", "@ember/runloop", "@ember/utils"], function (_exports, _component, _mmFullscreenView, _object, _service, _runloop, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROOT_CONTAINER_ID = 'mm-fullscreen-view-container';
  const NAV_DRAWER_STATE_KEY = 'ui-navigation-drawer';

  /**
   *  Fullscreen view which renders content into a specific container and manages nav-drawer (show/hide)
   *
   *```hbs
   *<div id="mm-fullscreen-view-container"></div>
   *{{#mm-fullscreen-view as |view|}}
   *    {{#view.bottomRight}}Container positioned bottom right corner{{/view.bottomRight}}
   *    {{#view.container}}Container content{{/view.container}}
   *    {{#view.topRight}}Container positioned top right corner{{/view.topRight}}
   *{{/mm-fullscreen-view}}
   *```
   *
   * Place a container with id `mm-fullscreen-view-container` (default, could be overwritten)
   * to your html-structure somewhere where it will be after the normal app to
   * allow the fullscreen-view to overlapp other app-views. You might have to add some
   * extra css to controll z-index depending on the z-index situation of the app
   * (TODO: find better solution for global app + cross-packages z-index concept)
   *
   * @class mm-fullscreen-view
   * @public
   * @yield {Hash} view
   * @yield {Component} view.bottomRight Will be positioned at the bottom-right corner of the view
   * @yield {Component} view.container Container to use for content
   * @yield {Component} view.topRight Will be positioned at the top-right corner of the view
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmFullscreenView.default,
    uiState: (0, _service.inject)(),
    tagName: '',
    /**
     * The container id where the view should be rendered
     * @property containerId
     * @type {String}
     */
    containerId: null,
    /**
     * Navdrawer state
     * @property _navDrawerState
     * @type {Object}
     * @private
     */
    _navDrawerState: null,
    /**
     * Whether initialy nav-drawer was open
     * @property _navDrawerWasOpen
     * @type {Boolean}
     * @private
     */
    _navDrawerWasOpen: false,
    /**
     * The dom-element container where the view should be rendered
     * @computed _renderElement
     * @type {String}
     * @private
     */
    _renderElement: (0, _object.computed)('containerId', {
      get() {
        const containerId = this.containerId;
        const element = document.getElementById(containerId);
        if (!element) {
          throw new ErrorEvent(`[mm-fullscreen-view] Element with id "${containerId}" not found, aborted`);
        }
        return element;
      }
    }),
    init() {
      this._super(...arguments);

      /* Set default container if nothing provided */
      if ((0, _utils.isEmpty)(this.containerId)) {
        (0, _object.set)(this, 'containerId', ROOT_CONTAINER_ID);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      const uiState = this.uiState;
      const navDrawerState = uiState && uiState.getState(NAV_DRAWER_STATE_KEY);
      (0, _object.set)(this, '_navdrawerState', navDrawerState);
      if (navDrawerState) {
        const isOpen = !navDrawerState.isHidden && (navDrawerState.isOpen || navDrawerState.isPermanent);
        (0, _object.set)(this, '_navDrawerWasOpen', isOpen);
        isOpen && navDrawerState.hide();
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._navDrawerWasOpen && (0, _runloop.next)(() => this._navdrawerState.show());
    }
  });
});