define("@additive-apps/multimedia-engine/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _routes.default)(function () {
    this.route('shared', function () {
      this.route('folder', {
        path: ':folder_id'
      }, function () {
        this.route('medium', {
          path: 'medium/:medium_id'
        });
      });
    });
    this.route('folder', {
      path: ':folder_id'
    }, function () {
      this.route('medium', {
        path: 'medium/:medium_id'
      });
    });
    this.route('filter', function () {
      this.route('medium', {
        path: 'medium/:medium_id'
      });
    });
  });
});