define("@additive-apps/media/components/mm-poi/movable-container", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-poi/movable-container", "@ember/runloop", "@ember/object", "@ember/debug", "ember-concurrency"], function (_exports, _component, _movableContainer, _runloop, _object, _debug, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Subcomponent of mm-poi. It is a movable (mouse/touch) container inside its parent (mm-poi)
   *
   *```hbs
   *{{mm-poi/movable-container
   *   position=position
   *   parentElement=parentElement
   *   onPositionChanged=(action "onPositionChanged")
   * }}
   *```
   * @class mm-poi/movable-container
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _movableContainer.default,
    classNames: ['movable-container', 'absolute top-0 right-0 bottom-0 left-0', 'flex justify-center items-center'],
    /**
     * Parents (mm-poi) element
     * @property parentElement
     * @type {Object}
     */
    parentElement: null,
    /**
     * Position where to start
     * @property position
     * @type {Object}
     */
    position: null,
    /**
     * Whether it is disabled
     * @property isDisabled
     * @type {Boolean}
     */
    isDisabled: false,
    /**
     * Whether the movable element is currently dragging
     * @property _active
     * @type {Boolean}
     * @private
     */
    _active: false,
    /**
     * The current updateEventTask
     * @property _currentUpdateEvent
     * @type {task}
     * @private
     */
    _currentUpdateEvent: null,
    /**
     * The current value of position-attribute
     * @property _currentPosition
     * @type {Object}
     * @private
     */
    _currentPosition: null,
    /**
     * The current x-axis positionvalue of movable-element
     * @property _currentX
     * @type {Number}
     * @private
     */
    _currentX: null,
    /**
     * The current y-axis positionvalue of movable-element
     * @property _currentY
     * @type {Number}
     * @private
     */
    _currentY: null,
    /**
     * The initial x-axis positionvalue when started dragging (position of mousedown)
     * @property _initialX
     * @type {Number}
     * @private
     */
    _initialX: null,
    /**
     * The initial y-axis positionvalue when started dragging (position of mousedown)
     * @property _initialY
     * @type {Number}
     * @private
     */
    _initialY: null,
    /**
     * The movable DOM element
     * @property _movableElement
     * @type {Object}
     * @private
     */
    _movableElement: null,
    /**
     * Parent DOM elements rectangle size
     * @property _parentRect
     * @type {Object}
     * @private
     */
    _parentRect: null,
    /**
     * The last setted x-axis position
     * @property _xOffset
     * @type {Number}
     * @private
     */
    _xOffset: 0,
    /**
     * The last setted y-axis position
     * @property _yOffset
     * @type {Number}
     * @private
     */
    _yOffset: 0,
    didInsertElement() {
      var _this$position, _this$position2;
      this._super(...arguments);
      (0, _object.set)(this, '_movableElement', this.element.querySelector('.movable'));
      const parentElement = this.parentElement;
      parentElement.addEventListener('touchstart', (0, _runloop.bind)(this, this._dragStart), false);
      parentElement.addEventListener('touchend', (0, _runloop.bind)(this, this._dragEnd), false);
      parentElement.addEventListener('touchmove', (0, _runloop.bind)(this, this._drag), {
        passive: true
      });
      parentElement.addEventListener('mousedown', (0, _runloop.bind)(this, this._dragStart), false);
      parentElement.addEventListener('mouseup', (0, _runloop.bind)(this, this._dragEnd), false);
      parentElement.addEventListener('mousemove', (0, _runloop.bind)(this, this._drag), {
        passive: true
      });
      if (this.position && (_this$position = this.position) !== null && _this$position !== void 0 && _this$position.x && (_this$position2 = this.position) !== null && _this$position2 !== void 0 && _this$position2.y) {
        var _this$position3, _this$position4;
        this._handlePositionValue((_this$position3 = this.position) === null || _this$position3 === void 0 ? void 0 : _this$position3.x, (_this$position4 = this.position) === null || _this$position4 === void 0 ? void 0 : _this$position4.y);
      }

      // Take care of orientation change (mobile mostly), as this impacts the image size
      window.addEventListener('orientationchange', (0, _runloop.bind)(this, this._onOrientationChange));
    },
    willDestroyElement() {
      this._super(...arguments);
      const parentElement = this.parentElement;
      if (!parentElement) {
        return;
      }
      parentElement.removeEventListener('touchstart', this._dragStart, false);
      parentElement.removeEventListener('touchend', this._dragEnd, false);
      parentElement.removeEventListener('touchmove', this._drag, {
        passive: true
      });
      parentElement.removeEventListener('mousedown', this._dragStart, false);
      parentElement.removeEventListener('mouseup', this._dragEnd, false);
      parentElement.removeEventListener('mousemove', this._drag, {
        passive: true
      });
      window.removeEventListener('orientationchange', this._onOrientationChange);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._currentPosition !== this.position) {
        var _this$position5, _this$position6;
        if (this.position && (_this$position5 = this.position) !== null && _this$position5 !== void 0 && _this$position5.x && (_this$position6 = this.position) !== null && _this$position6 !== void 0 && _this$position6.y) {
          var _this$position7, _this$position8;
          this._handlePositionValue((_this$position7 = this.position) === null || _this$position7 === void 0 ? void 0 : _this$position7.x, (_this$position8 = this.position) === null || _this$position8 === void 0 ? void 0 : _this$position8.y);
        }
        (0, _object.set)(this, '_currentPosition', this.position);
      }
    },
    /**
     * On position changed
     * @function onPositionChanged
     */
    onPositionChanged() {},
    // Drag did start
    _dragStart(e) {
      if (this.isDisabled) return;
      this._getAndSetParentRect();

      // Skip if not movable element
      if (e.target !== this._movableElement) {
        return;
      }
      (0, _object.set)(this, '_active', true);
      if (e.type === 'touchstart') {
        this._initialX = e.touches[0].clientX - this._xOffset;
        this._initialY = e.touches[0].clientY - this._yOffset;
      } else {
        this._initialX = e.clientX - this._xOffset;
        this._initialY = e.clientY - this._yOffset;
      }

      // If task is currently running cancel it as we start a new task as soon the user stopped dragging
      this._currentUpdateEvent && this._currentUpdateEvent.cancel();
    },
    // Drag did end
    _dragEnd() {
      /* If drag not started, do nothing */
      if (this._active === false) {
        return;
      }
      (0, _object.set)(this, '_active', false);

      // Save to allow cancellation while in running but started dragging again
      (0, _object.set)(this, '_currentUpdateEvent', this._updateEvent.perform());

      // Check and fix possible miss-placement (overflowing outside)
      const rect = this._movableElement.getBoundingClientRect();
      const currentX = rect.left + rect.width / 2;
      const currentY = rect.top + rect.height / 2;
      this._fixEdges(currentX, currentY);
      this._setTranslate(this._currentX, this._currentY);
    },
    // While dragging
    _drag(e) {
      /* If drag not started, do nothing */
      if (this._active === false) {
        return;
      }

      // Grep new drag position from event
      const {
        clientX,
        clientY
      } = e.type === 'touchmove' ? e.touches[0] : e;
      const parentRect = this._parentRect;

      // Check whether X-position is inside the parents container
      if (clientX >= parentRect.x.start && clientX <= parentRect.x.end) {
        (0, _object.set)(this, '_currentX', clientX - this._initialX);
        (0, _object.set)(this, '_xOffset', this._currentX);
      } else {
        this._fixEdges(clientX);
      }

      // Check whether Y-position is inside the parents container
      if (clientY >= parentRect.y.start && clientY <= parentRect.y.end) {
        (0, _object.set)(this, '_currentY', clientY - this._initialY);
        (0, _object.set)(this, '_yOffset', this._currentY);
      } else {
        this._fixEdges(null, clientY);
      }
      this._setTranslate(this._currentX, this._currentY);
    },
    /**
     * Checks if given positions are outside the allowed move-container.
     * If outside it will force positionining to edge of move-container
     *
     * @function _fixEdges
     * @param {Number} xPos The x-axis position (optional)
     * @param {Number} yPos The Y-axis position (optional)
     */
    _fixEdges() {
      let xPos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let yPos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const parentRect = this._parentRect;
      if (xPos) {
        // Check whether X-position is outside the parents container
        if (xPos < parentRect.x.start) {
          (0, _object.set)(this, '_currentX', -(parentRect.width / 2));
        } else if (xPos > parentRect.x.end) {
          (0, _object.set)(this, '_currentX', parentRect.width / 2);
        }
        (0, _object.set)(this, '_xOffset', this._currentX);
      }
      if (yPos) {
        // Check whether Y-position is outside the parents container
        if (yPos < parentRect.y.start) {
          (0, _object.set)(this, '_currentY', -(parentRect.height / 2));
        } else if (yPos > parentRect.y.end) {
          (0, _object.set)(this, '_currentY', parentRect.height / 2);
        }
        (0, _object.set)(this, '_yOffset', this._currentY);
      }
    },
    // Get and save some meta-data of parent element
    _getAndSetParentRect() {
      const _parentRect = this.parentElement.getBoundingClientRect();
      (0, _object.set)(this, '_parentRect', {
        x: {
          start: _parentRect.left,
          end: _parentRect.left + _parentRect.width
        },
        y: {
          start: _parentRect.top,
          end: _parentRect.top + _parentRect.height
        },
        width: _parentRect.width,
        height: _parentRect.height
      });
    },
    // Calculates position value (range 0-1) into translate-pixel values und sets container to position
    _handlePositionValue(xPosition, yPosition) {
      if (xPosition < 0 || xPosition > 1 || yPosition < 0 || yPosition > 1) {
        xPosition = 0.5;
        yPosition = 0.5;
        (false && (0, _debug.warn)('[mm-poi] Wrong position value provided, fallback to center', false, {
          id: 'mm-poi.movable-container.wrong_position'
        }));
      }
      this._getAndSetParentRect();
      const parentRect = this._parentRect;

      // Calculate given position value into pixel values (relative to parent container)
      const xPositionInPixel = parentRect.width / 100 * (xPosition * 100);
      // Calculate mid-offset of given position value (range: -50 to 50)
      (0, _object.set)(this, '_initialX', xPositionInPixel - parentRect.width / 2);
      (0, _object.set)(this, '_xOffset', this._initialX);

      // same for Y axis
      const yPositionInPixel = parentRect.height / 100 * (yPosition * 100);
      (0, _object.set)(this, '_initialY', yPositionInPixel - parentRect.height / 2);
      (0, _object.set)(this, '_yOffset', this._initialY);

      // set new calculated values to move itself to the given position
      this._setTranslate(this._initialX, this._initialY);
    },
    // Reset position on orientation change
    // as the chance that current position overflows container is high.
    // If position is given use it otherwise center
    _onOrientationChange() {
      (0, _runloop.next)(() => {
        var _this$position9, _this$position10;
        if (this.isDestroying) {
          return;
        }
        if (this.position && (_this$position9 = this.position) !== null && _this$position9 !== void 0 && _this$position9.x && (_this$position10 = this.position) !== null && _this$position10 !== void 0 && _this$position10.y) {
          var _this$position11, _this$position12;
          this._handlePositionValue((_this$position11 = this.position) === null || _this$position11 === void 0 ? void 0 : _this$position11.x, (_this$position12 = this.position) === null || _this$position12 === void 0 ? void 0 : _this$position12.y);
        } else {
          // set to mid position (default)
          this._handlePositionValue(0.5, 0.5);
        }
      });
    },
    // Sets style transform values to movable element
    _setTranslate(xPos, yPos) {
      this._movableElement.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
    },
    /**
     * This restartable task triggers onPositionChanged event with a debounce of 250ms.
     * It calculates the internal x-y values to parent relative position values and passes it as params
     * @property _updateEvent
     * @type {task}
     * @private
     */
    _updateEvent: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _emberConcurrency.timeout)(250);
        const parentRect = this._parentRect;
        const currentX = this._currentX;
        const currentY = this._currentY;
        (0, _object.set)(this, '_initialX', currentX);
        (0, _object.set)(this, '_initialY', currentY);

        // Calculate the mid-offset px value into position-values
        const xPosition = currentX / (parentRect.width / 100);
        const yPosition = currentY / (parentRect.height / 100);

        // Calculates the position (percentage with range 0 to 1, 0 -> 0% and 1 -> 100%) value relative to its parent.
        // As the position (xPosition,yPosition) values are mid-offset values, we calculate with 0.5 (50%) as base position.
        // The mid-offset will be in a range of -50 to +50
        this.onPositionChanged({
          x: (50 + xPosition) / 100,
          y: (50 + yPosition) / 100
        });
        (0, _object.set)(this, '_currentUpdateEvent', null);
      } catch (error) {
        throw new ErrorEvent('[mm-poi] UpdateEvent error ', error);
      }
    }).restartable()
  });
});