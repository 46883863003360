define("@additive-apps/ui/components/ui-fullscreen-illustration", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-fullscreen-illustration", "@ember/service"], function (_exports, _component, _uiFullscreenIllustration, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * This component displays an svg and optionally a button, taking up the whole screen
   *
   * @class ui-fullscreen-illustration
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _uiFullscreenIllustration.default,
    classNames: ['ui-fullscreen-illustration', 'flex', 'flex-column', 'items-center', 'justify-center', 'pa4', 'min-vh-100'],
    /**
     * the route to which the user is redirected on button click
     *
     * @property buttonRoute
     * @type {String}
     * @default null
     */
    buttonRoute: null,
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * the buttons click action
       *
       * @function buttonTransition
       */
      buttonTransition() {
        return this.router.transitionTo(this.buttonRoute);
      }
    }
  });
});