define("@additive-apps/ui/templates/components/ui-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "AVBB6GKg",
    "block": "[[[41,[51,[30,0,[\"isLoading\"]]],[[[41,[51,[30,0,[\"isError\"]]],[[[1,\"    \"],[10,0],[14,0,\"flex items-center gap2\"],[12],[1,\"\\n      \"],[18,4,[[28,[37,2],null,[[\"count\"],[[50,\"ui-content-count\",0,null,[[\"class\"],[\"pv2\"]]]]]]]],[1,\"\\n\"],[41,[30,0,[\"items\",\"length\"]],[[[1,\"        \"],[10,1],[14,0,\"flex flex-grow-1\"],[12],[13],[1,\"\\n        \"],[18,4,[[28,[37,2],null,[[\"pagination\"],[[50,\"ui-pagination\",0,null,[[\"hidePages\"],[true]]]]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"  \"],[10,0],[14,0,\"ui-list__title font-sm secondary pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[44,[[28,[37,2],null,[[\"item\",\"blankTemplate\",\"pagination\"],[[50,\"ui-list-item\",0,null,null],[50,\"ui-blank-template\",0,null,null],[50,\"ui-pagination\",0,null,[[\"class\"],[\"mt2\"]]]]]]],[[[41,[30,1],[[[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@classNames\",\"@items\",\"@itemsPerRow\",\"@cardHeight\",\"@gap\"],[\"mt1\",[30,0,[\"placeholderCount\"]],1,72,4]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isError\"]],[[[1,\"    \"],[18,4,[[28,[37,2],null,[[\"error\"],[[30,1,[\"blankTemplate\"]]]]]]],[1,\"\\n\"]],[]],[[[42,[28,[37,8],[[28,[37,8],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[18,4,[[28,[37,2],null,[[\"item\"],[[30,1,[\"item\"]]]]],[30,2],[30,3]]],[1,\"\\n\"]],[2,3]],[[[1,\"      \"],[18,4,[[28,[37,2],null,[[\"empty\"],[[30,1,[\"blankTemplate\"]]]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"items\",\"length\"]],[[[1,\"      \"],[18,4,[[28,[37,2],null,[[\"pagination\"],[[30,1,[\"pagination\"]]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]],[]]]],[]],null]],[1]]]],[\"list\",\"item\",\"index\",\"&default\"],false,[\"unless\",\"yield\",\"hash\",\"component\",\"if\",\"let\",\"ui-skeleton\",\"each\",\"-track-array\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-list.hbs",
    "isStrictMode": false
  });
});