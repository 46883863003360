define("@additive-apps/ui/components/ui-language-tag", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiActionButton @onClick={{this.click}} ...attributes>
    {{#unless this.hideState}}
      <UiStatus @state={{this.state}} />
    {{/unless}}
    <span class="ui-action-button__label">{{t (concat "languageSelect." this.language)}}</span>
  </UiActionButton>
  */
  {
    "id": "SpZTnCyl",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onClick\"],[[30,0,[\"click\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"hideState\"]]],[[[1,\"    \"],[8,[39,2],null,[[\"@state\"],[[30,0,[\"state\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"ui-action-button__label\"],[12],[1,[28,[35,3],[[28,[37,4],[\"languageSelect.\",[30,0,[\"language\"]]],null]],null]],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\"],false,[\"ui-action-button\",\"unless\",\"ui-status\",\"t\",\"concat\"]]",
    "moduleName": "@additive-apps/ui/components/ui-language-tag.hbs",
    "isStrictMode": false
  });
  /**
   *
   * Readonly language tag
   *
   * ```hbs
   *  {{ui-lang-select
   *    language
   *    isPublished
   *  }}
   * ```
   *
   * @class UiLanguageTag
   */
  let UiLanguageTag = _exports.default = (_dec = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec2 = (0, _emberArgTypes.arg)(_emberArgTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_emberArgTypes.boolean), _class = class UiLanguageTag extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the displayed language
       *
       * @property language
       * @type {String}, language code
       * @default null
       */
      _initializerDefineProperty(this, "language", _descriptor, this);
      /**
       * whether the language was published
       *
       * @property isPublished
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPublished", _descriptor2, this);
      /**
       * Hides the status in the language tag
       *
       * @property hideState
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hideState", _descriptor3, this);
    }
    /**
     * the status of the langauge
     *
     * @property state
     * @type {String}
     * @default undefined
     */
    get state() {
      return this.isPublished ? 'success' : 'error';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "language", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideState", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLanguageTag);
});