define("@additive-apps/ui/components/ui-badge", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-badge"], function (_exports, _component, _uiBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiBadge.default,
    classNames: ['ui-badge', 'flex items-center bg--grey br1 secondary', 'pv05 ph1 font-xs' // size dependend
    ],
    classNameBindings: ['state'],
    attributeBindings: ['size'],
    state: null,
    size: 'xs'
  });
});