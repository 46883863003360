define("@additive-apps/ui/components/ui-content-count", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.totalCount}}
    <div
      class="ui-content-count w-100 flex justify-between font-sm secondary {{@class}}"
      ...attributes
    >
      <span class="primary">
        {{t
          (if this.isFiltering "uiContentCount.filterCount" "uiContentCount.count")
          count=this.totalCount
        }}
      </span>
  
      {{#if this.page}}
        <span>
          {{t
            "uiContentCount.pages"
            page=(format-number this.page)
            total=(format-number this.totalPages)
            htmlSafe=true
          }}
        </span>
      {{/if}}
    </div>
  {{/if}}
  */
  {
    "id": "KJ82pmu4",
    "block": "[[[41,[30,0,[\"totalCount\"]],[[[1,\"  \"],[11,0],[16,0,[29,[\"ui-content-count w-100 flex justify-between font-sm secondary \",[30,1]]]],[17,2],[12],[1,\"\\n    \"],[10,1],[14,0,\"primary\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[[52,[30,0,[\"isFiltering\"]],\"uiContentCount.filterCount\",\"uiContentCount.count\"]],[[\"count\"],[[30,0,[\"totalCount\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"page\"]],[[[1,\"      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"uiContentCount.pages\"],[[\"page\",\"total\",\"htmlSafe\"],[[28,[37,2],[[30,0,[\"page\"]]],null],[28,[37,2],[[30,0,[\"totalPages\"]]],null],true]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@class\",\"&attrs\"],false,[\"if\",\"t\",\"format-number\"]]",
    "moduleName": "@additive-apps/ui/components/ui-content-count.hbs",
    "isStrictMode": false
  });
  /**
   * Displays the number of entries in a view.
   *
   * ```hbs
   *  <UiContentCount
   *    @totalCount={{f.meta.total}}
   *    @page={{f.meta.currentPage}}
   *    @totalPages={{f.meta.lastPage}}
   *    @isFiltering={{f.hasActiveFilter}}
   *  />
   * ```
   * @class ui-content-count
   */
  let UiContentCountComponent = _exports.default = (_class = class UiContentCountComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the total item count
       *
       * @argument totalCount
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "totalCount", _descriptor, this);
      /**
       * the current page
       *
       * @argument page
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "page", _descriptor2, this);
      /**
       * the total number of pages
       *
       * @argument totalPages
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "totalPages", _descriptor3, this);
      /**
       * whether the filter is active
       *
       * @argument isFiltering
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFiltering", _descriptor4, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "totalPages", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFiltering", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiContentCountComponent);
});