define("@additive-apps/configurator/components/ae-configurations/color", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AwColorsMenu
    @active={{this.color}}
    @onChange={{this.onColorUpdate}}
    @options={{this.colorOptions}}
    @path="color"
    @isReadOnly={{@isReadOnly}}
  />
  */
  {
    "id": "kuAwRXfA",
    "block": "[[[8,[39,0],null,[[\"@active\",\"@onChange\",\"@options\",\"@path\",\"@isReadOnly\"],[[30,0,[\"color\"]],[30,0,[\"onColorUpdate\"]],[30,0,[\"colorOptions\"]],\"color\",[30,1]]],null]],[\"@isReadOnly\"],false,[\"aw-colors-menu\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/color.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AeConfigurationsColorComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _object.computed)('item.{value,syncedValue,sync}', 'colorOptions.[]'), _class = class AeConfigurationsColorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "item", _descriptor, this);
      _initializerDefineProperty(this, "colorOptions", _descriptor2, this);
      /**
       * whether the color tile is read-only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      _initializerDefineProperty(this, "onChange", _descriptor4, this);
    }
    get color() {
      var _this$colorOptions, _this$colorOptions2, _color$value, _color$value2, _syncedColor$value, _syncedColor$value2;
      const {
        value,
        syncedValue,
        sync
      } = this.item;
      const syncedColor = (_this$colorOptions = this.colorOptions) === null || _this$colorOptions === void 0 ? void 0 : _this$colorOptions.find(c => c.key === syncedValue);
      const color = (_this$colorOptions2 = this.colorOptions) === null || _this$colorOptions2 === void 0 ? void 0 : _this$colorOptions2.find(c => c.key === value);
      return {
        color: (color === null || color === void 0 || (_color$value = color.value) === null || _color$value === void 0 ? void 0 : _color$value.color) || value.color,
        contrastColor: (color === null || color === void 0 || (_color$value2 = color.value) === null || _color$value2 === void 0 ? void 0 : _color$value2.contrastColor) || value.contrastColor,
        sync,
        syncedColor: (syncedColor === null || syncedColor === void 0 || (_syncedColor$value = syncedColor.value) === null || _syncedColor$value === void 0 ? void 0 : _syncedColor$value.color) || syncedValue.color,
        syncedContrastColor: (syncedColor === null || syncedColor === void 0 || (_syncedColor$value2 = syncedColor.value) === null || _syncedColor$value2 === void 0 ? void 0 : _syncedColor$value2.contrastColor) || syncedValue.contrastColor
      };
    }
    onColorUpdate(_, color, sync, key) {
      typeof key === 'string' ? this.onChange(key) : this.onChange(color);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "colorOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "color", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "color"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onColorUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onColorUpdate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsColorComponent);
});