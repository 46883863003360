define("@additive-apps/media/components/mm-video", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals", "@ember/object", "ember-arg-types", "prop-types", "@additive-apps/media/classes/video-control"], function (_exports, _component, _templateFactory, _component2, _internals, _object, _emberArgTypes, _propTypes, _videoControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <video
    id={{this.id}}
    class="mm-video mw-100 {{if this.isPreview "h-100" ""}}"
    src={{this.medium.url}}
    type={{this.medium.mimeType}}
    controls={{if this.isPreview false true}}
    disablePictureInPicture="true"
    controlsList="nodownload"
    preload={{if this.isPreview "meta" "auto"}}
    {{did-insert this.didInsert}}
  ></video>
  */
  {
    "id": "wwrUVCW2",
    "block": "[[[11,\"video\"],[16,1,[30,0,[\"id\"]]],[16,0,[29,[\"mm-video mw-100 \",[52,[30,0,[\"isPreview\"]],\"h-100\",\"\"]]]],[16,\"src\",[30,0,[\"medium\",\"url\"]]],[16,\"controls\",[52,[30,0,[\"isPreview\"]],false,true]],[24,\"disablePictureInPicture\",\"true\"],[24,\"controlsList\",\"nodownload\"],[16,\"preload\",[52,[30,0,[\"isPreview\"]],\"meta\",\"auto\"]],[16,4,[30,0,[\"medium\",\"mimeType\"]]],[4,[38,1],[[30,0,[\"didInsert\"]]],null],[12],[13]],[],false,[\"if\",\"did-insert\"]]",
    "moduleName": "@additive-apps/media/components/mm-video.hbs",
    "isStrictMode": false
  });
  let MmVideoComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class MmVideoComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the medium model containing the video url
       *
       * @argument medium
       * @type {Object}
       */
      _initializerDefineProperty(this, "medium", _descriptor, this);
      /**
       * whether the video is in preview mode
       *
       * @argument isPreview
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isPreview", _descriptor2, this);
      /**
       * the video control object
       *
       * @property videoControl
       * @type {VideoControl}
       */
      _defineProperty(this, "videoControl", null);
      /**
       * callback function triggered when the video is played
       *
       * @argument onVideoStarted
       * @type {Function}
       */
      _initializerDefineProperty(this, "onVideoStarted", _descriptor3, this);
    }
    /**
     * the unique id identifying the video dom element
     *
     * @computed id
     * @type {String}
     */
    get id() {
      return (0, _internals.guidFor)(this);
    }
    /**
     * creates the video control and adds the event listener
     * when the video is mounted
     *
     * @function didInsert
     */
    didInsert(element) {
      if (this.medium) {
        var _document;
        const videoEl = (_document = document) === null || _document === void 0 ? void 0 : _document.getElementById(this.id);
        this.videoControl = new _videoControl.default({
          start: () => videoEl.play(),
          stop: () => videoEl.pause()
        });
        element.addEventListener('play', () => this.onVideoStarted(this.videoControl));
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "medium", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPreview", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onVideoStarted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MmVideoComponent);
});