define("@additive-apps/ui/components/ui-selection-container", ["exports", "@ember/object/computed", "@ember/component", "@additive-apps/ui/templates/components/ui-selection-container", "@ember/object", "@ember/debug", "@ember/array"], function (_exports, _computed, _component, _uiSelectionContainer, _object, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC-component that handles the selection logic, the internal selection is performed using an array
   * containing the ids (selectionKey) of the selected elements. By default the when the user selects all items
   * the callback returns only the excluded array. If the `disableExclusion` property is set to true, only the items
   * of the current folder/page are selected when selectAll is executed.
   *
   * By defining the `destinationElementSelector` in the selection-actions the actions are placed in the specified element.
   *
   * ```hbs
   * {{#ui-selection-container items selectedItems onSelectionChange isLoading loadingText as |selection|}}
   *     {{!-- List, images, table --}}
   *    {{#selection.actions destinationElementSelector}}
   *        {{!-- actions --}}
   *    {{/selection.actions}}
   * {{/ui-selection-container}}
   * ```
   *
   * @class ui-selection-container
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSelectionContainer.default,
    classNames: ['ui-selection-container'],
    /**
     * whether the exclusion should be disabled, the select all action will only select the items that
     * are in the current view
     *
     * @property disableExclusion
     * @type {Boolean}
     * @default false
     */
    disableExclusion: false,
    /**
     * whether the select all action is disabled
     *
     * @property disableSelectAll
     * @type {Boolean}
     * @default false
     */
    disableSelectAll: false,
    /**
     * a function to filter the selectable items
     *
     * @property filterItems
     * @type {Function}
     * @default null
     */
    filterItems: null,
    /**
     * the items of the current view
     *
     * @property items
     * @type {[Object]}
     * @default undefined
     */
    items: undefined,
    /**
     * the initial selection, array of keys
     *
     * @property initialSelection
     * @type {[String]}
     * @default null
     */
    initialSelection: null,
    /**
     *  used to position the action box fixed
     *
     * @property isFixed
     * @type {Boolean}
     * @default false
     */
    isFixed: false,
    /**
     * triggers loading state
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * while set the selection is not cleared when the items change
     * used to keep the selection on pagination change
     *
     * @property keepSelectionFor
     * @type {String}
     * @default null
     */
    keepSelectionFor: null,
    /**
     *  text shown during loading
     *
     * @property loadingText
     * @type {String}
     * @default null
     */
    loadingText: null,
    /**
     * the maximum number of selectable items
     *
     * @property maxCount
     * @type {Number}
     * @default null
     */
    maxCount: null,
    /**
     * the meta data of the model, it should contain the `total` property which specifies
     * the total amount of items that are selectable
     *
     * @property meta
     * @type {Object}
     * @default null
     */
    meta: null,
    /**
     * the key of an object containing unique values for every object
     *
     * @property selectionKey
     * @type {String}
     * @default 'id'
     */
    selectionKey: 'id',
    /**
     * Current items count
     *
     * @property _currentItemsCount
     * @type {Number}
     * @private
     */
    _currentItemsCount: null,
    /**
     * the keys that should be excluded when the inverted selection mode is on
     *
     * @property _excludedKeys
     * @type {[String]}
     * @default null
     */
    _excludedKeys: null,
    /**
     * whether the inverted selection mode is on, i.e. items are excluded instead of being included
     *
     * @property _isInvertedSelection
     * @type {Boolean}
     * @default false
     */
    _isInvertedSelection: false,
    /**
     * the values of the key of every selected item, when the inverted selection mode is off
     *
     * @property _selectedKeys
     * @type {Array}
     * @private
     */
    _selectedKeys: null,
    /**
     * whether there are selected items and the action-box should be displayed
     *
     * @computed hasSelectedItems
     * @type {Boolean}
     */
    hasSelectedItems: (0, _computed.gt)('_selectedItemsCount', 0),
    /**
     * the current number of selected items
     *
     * @computed _selectedItemsCount
     * @type {Number}
     * @private
     */
    _selectedItemsCount: (0, _object.computed)('_excludedKeys.length', '_isInvertedSelection', '_selectedKeys.length', 'items.length', 'meta.total', {
      get() {
        if (this._isInvertedSelection) {
          const totalCount = this.meta && this.meta.total || this.items.length;
          return totalCount - this._excludedKeys.length;
        } else {
          return this._selectedKeys.length;
        }
      }
    }),
    /**
     * whether all the items are in the current selection
     *
     * @computed _isAllSelected
     * @type {Boolean}
     * @private
     */
    _isAllSelected: (0, _object.computed)('_excludedKeys.length', '_isInvertedSelection', '_itemKeys.length', '_selectedKeys.length', 'disableExclusion', 'items.length', 'meta.total', {
      get() {
        // if exclusion is disabled check whether all items of view are in selection
        if (this.disableExclusion) {
          for (let i = 0; i < this._itemKeys.length; i++) {
            if (this._selectedKeys.indexOf(this._itemKeys[i]) < 0) {
              return false;
            }
          }
          return true;
        } else {
          // if inverted selection check if no items is excluded
          if (this._isInvertedSelection) {
            return this._excludedKeys.length === 0;
          } else {
            // if normal selection check if selected keys has same length as total amount
            const totalCount = this.meta && this.meta.total || this.items.length;
            return this._selectedKeys.length === totalCount;
          }
        }
      }
    }),
    /**
     * the keys of all the items of the current view
     *
     * @computed _itemKeys
     * @type {[String]}
     * @private
     */
    _itemKeys: (0, _object.computed)('items.[]', 'selectionKey', {
      get() {
        return (this.items || []).map(item => item[this.selectionKey]);
      }
    }),
    /**
     * the keys of the items that should not be selectable
     *
     * @computed _notSelectableItems
     * @type {[String]}
     * @private
     */
    _notSelectableItems: (0, _object.computed)('_isMaxCount', '_itemKeys.[]', '_selectedItemsCount', '_selectedKeys.[]', 'filterItems', 'items', 'maxCount', 'selectionKey', {
      get() {
        if (this._isMaxCount) {
          return this._itemKeys.filter(item => this._selectedKeys.indexOf(item) < 0);
        } else if (this.filterItems) {
          const filteredItems = this.items.filter(item => this.filterItems(item));
          return filteredItems.map(item => item[this.selectionKey]);
        }
        return null;
      }
    }),
    /**
     * whether the selected items have reached the max count
     *
     * @computed _isMaxCount
     * @type {Boolean}
     * @private
     */
    _isMaxCount: (0, _object.computed)('_selectedItemsCount', 'maxCount', {
      get() {
        return this.maxCount && this._selectedItemsCount >= this.maxCount;
      }
    }),
    init() {
      this._super(...arguments);
      (false && !(this.selectionKey) && (0, _debug.assert)('The selectionKey is not set', this.selectionKey)); // set the initial selection
      (0, _object.set)(this, '_selectedKeys', this.initialSelection || []);
      (0, _object.set)(this, '_excludedKeys', []);
      (0, _object.set)(this, '_currentItemsCount', (0, _array.isArray)(this.items) ? this.items.length : 0);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks

    /**
     * Handle passed items change,
     * for example one item has been deleted,
     * check if missing item was selected and handle
     *
     * @function didUpdateAttrs
     */
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);

      /* When keepSelectionFor is set, do nothing */
      if (this.keepSelectionFor) {
        return;
      }
      const itemsLength = (0, _array.isArray)(this.items) ? this.items.length : 0;
      /* When items did not get updated, do nothing */
      if (this._currentItemsCount === itemsLength) {
        return;
      }
      const selectedKeys = this._selectedKeys;
      /* Get new selectedkeys, with not existing items anymore filtered out */
      const newSelectedKeys = selectedKeys.filter(selectedKey => {
        return this._itemKeys.indexOf(selectedKey) >= 0;
      });
      (0, _object.setProperties)(this, {
        _selectedKeys: newSelectedKeys,
        _currentItemsCount: itemsLength
      });

      /* If changed trigger onChange event */
      if (newSelectedKeys.length !== selectedKeys.length) {
        this.onSelectionChange(newSelectedKeys, this._isInvertedSelection);
      }
    },
    /**
     * callback that is triggered everytime the selection changes
     *
     * @function onSelectionChange
     * @param {[String]} selectedItems
     * @param {Boolean} isInvertedSelection
     */
    onSelectionChange() {},
    /**
     * callback that is triggered when the selection is added
     *
     * @function onSelectionAdd
     * @param {[String]} selectedItems
     * @param {Boolean} isInvertedSelection
     */
    onSelectionAdd() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * selects or unselects a given item, by adding/removing it from the selected-, excludedKeys arrays
       * depending on the selection mode
       *
       * @function selectItem
       * @param {Object} item
       */
      selectItem(item) {
        const selectedKey = item[this.selectionKey];

        // normal selection mode: items are added/removed from `selectedKeys` array
        if (this.disableExclusion || !this._isInvertedSelection) {
          const indexOfKey = this._selectedKeys.indexOf(selectedKey);
          if (indexOfKey < 0) {
            this._selectedKeys.push(selectedKey);
          } else {
            this._selectedKeys.splice(indexOfKey, 1);
          }
          (0, _object.set)(this, '_selectedKeys', [...this._selectedKeys]);
          this.onSelectionChange(this._selectedKeys, false);
        } else {
          // inverted selection mode: items are added/removed from `excludedKeys` array
          const indexOfKey = this._excludedKeys.indexOf(selectedKey);
          if (indexOfKey < 0) {
            this._excludedKeys.push(selectedKey);
          } else {
            this._excludedKeys.splice(indexOfKey, 1);
          }
          (0, _object.set)(this, '_excludedKeys', [...this._excludedKeys]);
          this.onSelectionChange(this._excludedKeys, true);
        }
      },
      /**
       * selects all items, deselects the selected items if all items are already selected
       *
       * @function toggleSelectionMode
       */
      toggleSelectionMode() {
        // if exclusion is disabled all the items of the current view are removed/added to the selection
        if (this.disableExclusion) {
          if (this._isAllSelected || this.disableSelectAll) {
            /*
             * get items that are selected but not in the current items array and keep these in the selection
             * while deselecting all other items
             * the items of another folder in multimedia or list-items of another page are kept in the selection
             */
            const currentKeys = (this._selectedKeys || []).filter(key => !this._itemKeys.includes(key));
            (0, _object.set)(this, '_selectedKeys', currentKeys);
          } else {
            /*
             * add all the items to the selection, items that are already in the selection are filtered
             * to avoid duplicates
             */
            const newKeys = (this._itemKeys || []).filter(key => this._selectedKeys.indexOf(key) < 0);
            (0, _object.set)(this, '_selectedKeys', this._selectedKeys.concat(newKeys));
          }
          this.onSelectionChange(this._selectedKeys, false);
        } else {
          // if exclusion is enabled we switch between inverted selection mode and normal selection mode
          if (this._isInvertedSelection && !this.disableSelectAll) {
            (0, _object.setProperties)(this, {
              _isInvertedSelection: false,
              _excludedKeys: []
            });
            this.onSelectionChange([], false);
          } else {
            (0, _object.setProperties)(this, {
              _isInvertedSelection: true,
              _selectedKeys: []
            });
            this.onSelectionChange([], true);
          }
        }
      },
      /**
       * triggers the onSelectionAdd callback and resets the selection
       *
       * @function addSelection
       */
      addSelection() {
        const selection = this._isInvertedSelection ? this._excludedKeys : this._selectedKeys;
        this.onSelectionAdd(selection, this._isInvertedSelection);
        (0, _object.setProperties)(this, {
          _isInvertedSelection: false,
          _selectedKeys: [],
          _excludedKeys: []
        });
      },
      /**
       * clears current selection
       *
       * @function clearSelection
       */
      clearSelection() {
        (0, _object.setProperties)(this, {
          _isInvertedSelection: false,
          _selectedKeys: [],
          _excludedKeys: []
        });
      },
      /**
       * yielded action to check if a given item is selected
       *
       * @function isSelected
       */
      isSelected(item) {
        if (this._isAllSelected) {
          return true;
        }
        const selectedKey = typeof item === 'object' ? item && item[this.selectionKey] : item;
        if (!selectedKey) {
          return false;
        }

        // normal selection mode: items are added/removed from `selectedKeys` array
        if (this.disableExclusion || !this._isInvertedSelection) {
          return this._selectedKeys.indexOf(selectedKey) !== -1;
        } else {
          // inverted selection mode: items are added/removed from `excludedKeys` array
          return this._excludedKeys.indexOf(selectedKey) === -1;
        }
      }
    }
  });
});