define("@additive-apps/ui/components/ui-color-composition", ["exports", "@ember/component", "ember-changeset", "@additive-apps/ui/templates/components/ui-color-composition", "@ember/object", "rsvp", "@ember/service", "validated-changeset"], function (_exports, _component, _emberChangeset, _uiColorComposition, _object, _rsvp, _service, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component which allows to set a composition of colors:
   *   * color
   *   * contrastColor
   *
   * It can work with an passed changeset or passed values for color or bgColor attribute.
   *
   * @class ui-color-composition
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiColorComposition.default,
    classNames: ['ui-color-composition', 'flex', 'flex-grow-1', 'flex-shrink-0', 'cpointer', 'w-100', 'relative'],
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    /**
     * Color-value of the contrast-color.
     * Only needed when no changeset is provided
     *
     * @property contrastColor
     * @type {String}
     * @default null
     */
    contrastColor: null,
    /**
     * Color composition changeset which needs to has color and contrastColor as properties
     *
     * @property changeset
     * @type {Object}
     * @default null
     */
    changeset: null,
    /**
     * Color-value of the color.
     * Only needed when no changeset is provided
     *
     * @property color
     * @type {String}
     * @default null
     */
    color: null,
    /**
     * Description of the color composition
     *
     * @property description
     * @type {String}
     * @default null
     */
    description: null,
    /**
     * Additional text in the dialog between colors
     *
     * @property dialogText
     * @type {String}
     * @default null
     */
    dialogText: null,
    /**
     * Whether the detail dialog is open or not
     *
     * @property isDetailOpen
     * @type {Boolean}
     * @default false
     */
    isDetailOpen: false,
    /**
     * The name of this composition.
     *
     * @property name
     * @type {String}
     * @default null
     */
    name: null,
    /**
     * Whether the color settings should be read-only
     *
     * @property contrastColor
     * @type {String}
     * @default null
     */
    isReadOnly: false,
    /**
     * Internal changeset for the detail dialog
     *
     * @property _changeset
     * @type {Object}
     * @default null
     * @private
     */
    _changeset: null,
    /**
     * Whether it got passed an changeset-attribute, which means we do not have to create a changeset
     *
     * @property _didProvideChangeset
     * @type {Boolean}
     * @default false
     * @private
     */
    _didProvideChangeset: false,
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      this._updateCurrentValues();
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      this._updateCurrentValues();
    },
    click() {
      (0, _object.set)(this, 'isDetailOpen', true);
      this._didProvideChangeset === false && this._updateCurrentValues();
    },
    /**
     * Event which gets fired after save click.
     * Save logic should come here.
     *
     * @param {Object} Changeset or POJO based on the passed arguments (`changeset`-attribute passed or not)
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave(changeset) {
      if ((0, _validatedChangeset.isChangeset)(changeset)) {
        return changeset.save();
      }
      return (0, _rsvp.resolve)();
    },
    _close() {
      (0, _object.set)(this, 'isDetailOpen', false);
      // Clear up internal changeset
      this._didProvideChangeset === false && (0, _object.set)(this, '_changeset', null);
    },
    /**
     * Middleware to whether pass a changeset or the plain properties to the save event,
     * depending on passed arguments (changeset or only plain properties)
     * @function _triggerPublicSaveEvent
     * @return {Promise} Returns a promise
     * @private
     */
    _triggerPublicSaveEvent(changeset) {
      if (this._didProvideChangeset) {
        return this.onSave(changeset);
      }
      return this.onSave({
        color: changeset.color,
        contrastColor: changeset.contrastColor
      });
    },
    /**
     * Update internal "current" values and create internal changeset if no changeset is provided
     * @function _updateCurrentValues
     * @private
     */
    _updateCurrentValues() {
      let changeset = this.changeset;
      if (!changeset) {
        changeset = new _emberChangeset.default({
          color: this.color,
          contrastColor: this.contrastColor
        });
        (0, _object.set)(this, '_didProvideChangeset', false);
      } else {
        (0, _object.set)(this, '_didProvideChangeset', true);
      }
      (0, _object.set)(this, '_changeset', changeset);
      if (changeset.color !== this.currentColor) {
        (0, _object.set)(this, 'currentColor', changeset.color);
      }
      if (changeset.contrastColor !== this.currentContrastColor) {
        (0, _object.set)(this, 'currentContrastColor', changeset.contrastColor);
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      closeDialog() {
        const changeset = this._changeset;
        if (changeset.isDirty) {
          this.uiDialog.showDiscardChangesConfirm(() => {
            if (this.isDestroying) {
              return;
            }
            changeset.rollback();
            this._close();
          });
        } else {
          this._close();
        }
      },
      save() {
        const changeset = this._changeset;
        return changeset.validate().then(() => {
          if (changeset.isValid) {
            return this._triggerPublicSaveEvent(changeset).catch(() => this.uiDialog.showError()).then(() => {
              this._close();
              this._updateCurrentValues();
            });
          }
          return (0, _rsvp.resolve)();
        });
      }
    }
  });
});