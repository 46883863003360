define("@additive-apps/media/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRANSFORMATION_RESET = _exports.SUPPORTED_MEDIUM_URL_OPTIONS = _exports.ROOT_SHARED_FOLDER_ID = _exports.ROOT_FOLDER_ID = _exports.QUERY_PARAMS_KEYS_FOR_OPTIONS = _exports.OLD_IMAGE_TRANSFORMATIONS = _exports.MEDIA_REQUEST_HEADERS = _exports.MEDIA_GALLERY_DEFAULT_SIZE = _exports.MEDIA_ENGINE_NAME = _exports.IS_OLD_DARKEN_TRANSFORMATION = _exports.IS_OLD_BRIGHTEN_TRANSFORMATION = _exports.GRID_DEFAULT_SRC_SETS = _exports.GRID_DEFAULT_SIZE = _exports.FILEEXTENSION_COLOR_MAPPING = _exports.CROPPER_JS_DEFAULT_OPTIONS = void 0;
  // Mapping for fileextension and the corresponding color
  const FILEEXTENSION_COLOR_MAPPING = _exports.FILEEXTENSION_COLOR_MAPPING = {
    doc: 'blue',
    docx: 'blue',
    txt: 'blue',
    ppt: 'yellow',
    svg: 'yellow',
    xls: 'green',
    xlsx: 'green',
    pdf: 'red'
  };

  // cropper.js default options
  const CROPPER_JS_DEFAULT_OPTIONS = _exports.CROPPER_JS_DEFAULT_OPTIONS = {
    viewMode: 1,
    moveable: false,
    rotatable: false,
    scrollable: false,
    scalable: false,
    zoomable: false,
    guides: false,
    checkOrientation: false,
    restore: false,
    dragMode: 'none'
  };
  const MEDIA_GALLERY_DEFAULT_SIZE = _exports.MEDIA_GALLERY_DEFAULT_SIZE = 'x640';
  const MEDIA_ENGINE_NAME = _exports.MEDIA_ENGINE_NAME = 'additive-multimedia-engine';
  const MEDIA_REQUEST_HEADERS = _exports.MEDIA_REQUEST_HEADERS = {
    Accept: 'application/vnd.additive+json; version=1',
    'Accept-Language': 'de',
    'Content-Type': 'application/json'
  };
  const GRID_DEFAULT_SRC_SETS = _exports.GRID_DEFAULT_SRC_SETS = {
    mediaSets: {
      medium: '(min-width: 600px)'
    },
    sizeSets: {
      medium: '226x160'
    }
  };
  const GRID_DEFAULT_SIZE = _exports.GRID_DEFAULT_SIZE = '334x240';
  const SUPPORTED_MEDIUM_URL_OPTIONS = _exports.SUPPORTED_MEDIUM_URL_OPTIONS = ['size', 'bri', 'con', 'sat', 'poi'];
  const QUERY_PARAMS_KEYS_FOR_OPTIONS = _exports.QUERY_PARAMS_KEYS_FOR_OPTIONS = {
    size: 't'
  };
  const TRANSFORMATION_RESET = _exports.TRANSFORMATION_RESET = {
    mo: [100, 100, 100],
    co: [0.5, 0.5]
  };
  const OLD_IMAGE_TRANSFORMATIONS = _exports.OLD_IMAGE_TRANSFORMATIONS = {
    normal: {
      mo: null,
      co: null
    },
    brighten: {
      mo: [110, 60, 100],
      co: [0, 0.5]
    },
    darken: {
      mo: [60, 40, 100],
      co: [-0.5, 0.5]
    }
  };
  const IS_OLD_BRIGHTEN_TRANSFORMATION = transformation => {
    if (JSON.stringify(transformation === null || transformation === void 0 ? void 0 : transformation.mo) !== JSON.stringify(OLD_IMAGE_TRANSFORMATIONS.brighten.mo)) {
      return false;
    }
    if (JSON.stringify(transformation === null || transformation === void 0 ? void 0 : transformation.co) !== JSON.stringify(OLD_IMAGE_TRANSFORMATIONS.brighten.co)) {
      return false;
    }
    return true;
  };
  _exports.IS_OLD_BRIGHTEN_TRANSFORMATION = IS_OLD_BRIGHTEN_TRANSFORMATION;
  const IS_OLD_DARKEN_TRANSFORMATION = transformation => {
    if (JSON.stringify(transformation === null || transformation === void 0 ? void 0 : transformation.mo) !== JSON.stringify(OLD_IMAGE_TRANSFORMATIONS.darken.mo)) {
      return false;
    }
    if (JSON.stringify(transformation === null || transformation === void 0 ? void 0 : transformation.co) !== JSON.stringify(OLD_IMAGE_TRANSFORMATIONS.darken.co)) {
      return false;
    }
    return true;
  };
  _exports.IS_OLD_DARKEN_TRANSFORMATION = IS_OLD_DARKEN_TRANSFORMATION;
  const ROOT_FOLDER_ID = _exports.ROOT_FOLDER_ID = 'root';
  const ROOT_SHARED_FOLDER_ID = _exports.ROOT_SHARED_FOLDER_ID = 'shared';
});