define("@additive-apps/ui/templates/components/ui-input-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "wIs5mSjo",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@title\",\"@description\",\"@onClick\"],[\"pa1 ma05 font-sm bg-white br1\",[30,0,[\"title\"]],[30,0,[\"description\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"isInputDialog\"]]],null],true],null]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,[[30,1]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isInputDialog\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@size\",\"@title\",\"@onClose\"],[\"sm\",[30,0,[\"title\"]],[28,[37,1],[[30,0],\"closeAction\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"header\"]]],[1,\"\\n    \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@label\",\"@placeholder\",\"@value\",\"@helperText\",\"@errorMessage\"],[[30,0,[\"inputLabel\"]],[30,0,[\"inputPlaceholder\"]],[30,0,[\"_inputValue\"]],[30,0,[\"inputInfo\"]],[30,0,[\"errorMessage\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,7],null,[[\"@label\",\"@isSecondary\",\"@isDisabled\",\"@onClick\"],[[30,0,[\"actionLabel\"]],true,[30,0,[\"isActionDisabled\"]],[28,[37,1],[[30,0],\"confirmAction\"],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"item\",\"modal\",\"content\",\"&default\"],false,[\"ui-item\",\"action\",\"mut\",\"yield\",\"if\",\"ui-modal\",\"ui-input\",\"ui-button\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-input-item.hbs",
    "isStrictMode": false
  });
});