define("@additive-apps/media/serializers/folder", ["exports", "@ember-data/serializer/rest", "@ember/utils", "@ember/service", "@additive-apps/multimedia-engine/constants"], function (_exports, _rest, _utils, _service, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FolderSerializer = _exports.default = (_class = class FolderSerializer extends _rest.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }
    serialize(snapshot, options) {
      var json = super.serialize(snapshot, options);
      Object.keys(json).forEach(key => (0, _utils.isNone)(json[key]) && delete json[key]);
      return json;
    }
    serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    }
    normalizeResponse(store, primaryModelClass, payload) {
      if (payload !== null && payload !== void 0 && payload.folders || payload !== null && payload !== void 0 && payload.folder) {
        payload.folders = payload.folders ? payload.folders : [payload.folder];
        payload.folders.forEach(folder => {
          folder.shared = false;
        });
      } else if (payload !== null && payload !== void 0 && payload.sharedFolders || payload !== null && payload !== void 0 && payload.sharedFolder) {
        payload.folders = payload.sharedFolders ? payload.sharedFolders : [payload.sharedFolder];
        payload.folders.forEach(folder => {
          var _this$currentUser;
          folder.shared = true;
          if ((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && (_this$currentUser = _this$currentUser.currentOrganization) !== null && _this$currentUser !== void 0 && _this$currentUser.isPartner) {
            folder.parentId = _constants.ROOT_SHARED_FOLDER_ID;
            folder.parentName = this.intl.t('multimediaEngine.folderView.sharedFolder');
          }
        });
      }
      return super.normalizeResponse(...arguments);
    }
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      /*
       * Api sends 204-no-content as response when no content exists.
       * Ember-data seems to have problems with that, so convert empty response into an empty array
       */
      if ((0, _utils.isEmpty)(payload)) {
        payload = {
          folders: []
        };
      }
      return super.normalizeQueryResponse(store, primaryModelClass, payload, id, requestType);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});