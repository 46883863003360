define("@additive-apps/ui/components/ui-toast-container", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-toast-container", "@ember/array", "@ember/object", "@ember/service"], function (_exports, _component, _uiToastContainer, _array, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * ```hbs
   * {{ui-toast-container name parent}}
   * ```
   *
   * @class ui-toast-container
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    uiToast: (0, _service.inject)(),
    layout: _uiToastContainer.default,
    tagName: '',
    /**
     * the name of the container, all toasts that have the containerName property
     * set to this name are rendered into this container.
     * if the name is unset it renders all toasts
     *
     * @property name
     * @type {String}
     * @default null
     */
    name: null,
    /**
     * the parent element selector that is used to render the toasts
     *
     * @property parent
     * @type {String}
     * @default null
     */
    parent: null,
    /**
     * the queue of the toasts pushed by the toast service
     * filtered by the container name
     *
     * @computed _activeToasts
     * @type {Object[]} the toasts to be shown
     * @private
     */
    _activeToasts: (0, _object.computed)('name', 'uiToast._toasts.[]', {
      get() {
        const name = this.name;
        let allToasts = this.uiToast && this.uiToast._toasts;
        if (name && (0, _array.isArray)(allToasts)) {
          allToasts = allToasts.filter(toast => toast.containerName === name);
        }

        // stack first toast if there are two active toasts
        if ((0, _array.isArray)(allToasts) && allToasts.length > 0) {
          // eslint-disable-next-line ember/no-side-effects
          (0, _object.set)(allToasts.objectAt(0), 'isStacked', allToasts.length === 2);
        }
        return allToasts;
      }
    }),
    /**
     * onClose event.
     * Removes the given toast from the activeToast queue
     * @event onClose
     * @param {toast} the toast to be removed
     */
    onClose(toast) {
      this.uiToast && this.uiToast.destroyToast(toast);
    }
  });
});