define("@additive-apps/ui/components/ui-multilingual-modal", ["exports", "@ember/object/computed", "@ember/component", "@additive-apps/ui/templates/components/ui-multilingual-modal", "@ember/service", "@ember/object", "ember-changeset", "ember-changeset-validations", "ember-concurrency"], function (_exports, _computed, _component, _uiMultilingualModal, _service, _object, _emberChangeset, _emberChangesetValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC component that handles the language actions for a multilingual model
   *
   * @class ui-multilingual-dialog
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    store: (0, _service.inject)(),
    layout: _uiMultilingualModal.default,
    tagName: '',
    /**
     * the parent route
     *
     * @property backRoute
     * @type {String}
     * @default ''
     */
    backRoute: '',
    /**
     * whether the dialog is in edit mode
     *
     * @property isEdit
     * @type {Boolean}
     * @default false
     */
    isEdit: false,
    /**
     * whether the modal should be in loading state
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * The multilingual resource
     *
     * @property resource
     * @type {DS.Model}
     * @default null
     */
    resource: null,
    /**
     * the name of the multilingual model
     *
     * @property modelName
     * @type {String}
     * @default null
     */
    modelName: null,
    /**
     * Models additional options,
     * will be passed to any ember-data action as `options`
     *
     * @property modelOptions
     * @type {Object}
     * @default null
     */
    modelOptions: null,
    /**
     * a string that includes the properties that are changed in
     * based on the language, delimited by a comma e.g. `name,description`
     *
     * @property multilingualProperties
     * @type {String}
     * @default ''
     */
    multilingualProperties: '',
    /**
     * Whether it is read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default false
     */
    isReadOnly: false,
    /**
     * the subtitle of the dialog
     *
     * @property subtitle
     * @type {String}
     * @default ''
     */
    subtitle: '',
    /**
     * the title of the dialog
     *
     * @property title
     * @type {String}
     * @default ''
     */
    title: '',
    /**
     * the size of the modal
     *
     * @property size
     * @type {String}
     * @default 'md'
     */
    size: 'md',
    /**
     * Whether the changeset got validated minimum once
     *
     * @property _isValidated
     * @type {Boolean}
     * @default false
     * @private
     */
    _isValidated: false,
    availableLanguages: (0, _computed.alias)('uiAppSettings.languages.contentLanguages'),
    defaultLanguage: (0, _computed.alias)('uiAppSettings.languages.defaultLanguage'),
    currentLocale: (0, _computed.alias)('uiAppSettings.currentLocale'),
    showLanguageSelect: (0, _computed.or)('_isReadOnly', 'resource.isNew'),
    _isReadOnly: (0, _computed.not)('isEdit'),
    _isEditable: (0, _computed.alias)('isReadOnly'),
    _isLoading: (0, _computed.or)('isLoading', 'changeLocale.isRunning'),
    _hideLanguageActions: (0, _computed.or)('changeset.isNew', 'isReadOnly'),
    /**
     * The property that is passed to the language select. When the model is not published
     * or new the available languages are set to published in order to display them in the
     * select
     *
     * @computed _publishedLanguages
     * @type {[String]}
     * @private
     */
    _publishedLanguages: (0, _object.computed)('resource.{publishedLanguages.[],availableLanguages.[]}', 'availableLanguages.[]', 'isPublishable', {
      get() {
        if (this.resource.availableLanguages && this.isPublishable) {
          return this.resource.publishedLanguages;
        }
        return this.availableLanguages;
      }
    }),
    /**
     * Hides the language language state in the language select if the resource is new
     * or not publishable
     *
     * @computed _hideLanguageState
     * @type {Boolean}
     * @private
     */
    _hideLanguageState: (0, _object.computed)('resource.isNew', 'isPublishable', {
      get() {
        return this.resource.isNew || !this.isPublishable;
      }
    }),
    init() {
      this._super(...arguments);
      const changeset = new _emberChangeset.default(this.resource, (0, _emberChangesetValidations.default)(this.validation), this.validation);
      // Keep in mind: makes changeset dirty
      !changeset.get('language') && changeset.set('language', this.defaultLanguage);
      (0, _object.set)(this, 'changeset', changeset);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      if (this.currentLocale !== this.defaultLanguage) {
        this.uiAppSettings.setLocale(this.defaultLanguage);
        return this.resource.reload(this.modelOptions);
      }
    },
    /**
     * checks if the changeset is valid and saves the model
     *
     * @function save
     * @type {Task}
     * @param {Object} changeset
     */
    save: (0, _emberConcurrency.task)(function* /* changeset */
    () {
      yield this.changeset.validate();
      (0, _object.set)(this, '_isValidated', true);
      if (!this.changeset.get('isValid')) {
        return;
      } else if (this.changeset.get('isPristine')) {
        // dont send request if changeset is valid and pristine
        return (0, _object.set)(this, 'isEdit', false);
      }
      try {
        const isNewModel = this.resource.isNew;
        const savedModel = yield this.changeset.save(this.modelOptions);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });

        // go back to readOnly after saving
        (0, _object.set)(this, 'isEdit', false);
        return this.onSave(savedModel, isNewModel);
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    /**
     * Changes entities locale to a given locale
     *
     * @type {Task}
     * @function changeLocale
     * @param {String} locale
     */
    changeLocale: (0, _emberConcurrency.task)(function* (locale) {
      if (locale === this.currentLocale) {
        return;
      }
      this.uiAppSettings.setLocale(locale);
      if (this.resource.isNew) {
        this.changeset.set('language', locale);
        return;
      }

      /*
       * We need to reset the multilingual properties of the resource and push them to the store
       * since `model.reload()` does not overwrite properties that are not defined
       * in another language
       */
      const model = this.resource.serialize({
        includeId: true
      });
      const multilingualProperties = this.multilingualProperties.split(',');
      multilingualProperties.forEach(property => model[property] = '');

      // push the resetted model back into the store
      yield this.store.pushPayload({
        [this.modelName]: model
      });
      const newModel = this.store.peekRecord(this.modelName, model.id);
      (0, _object.set)(this, 'resource', newModel);
      let tasks = [];
      tasks.push(this.resource.reload(this.modelOptions));
      tasks.push((0, _emberConcurrency.timeout)(400));
      yield (0, _emberConcurrency.all)(tasks);
      const changeset = new _emberChangeset.default(this.resource, (0, _emberChangesetValidations.default)(this.validation), this.validation);
      (0, _object.set)(this, 'changeset', changeset);
    }),
    /**
     * deletes a given locale of the content model
     *
     * @type {Task}
     * @function removeLocale
     * @param {String} locale
     */
    removeLocale: (0, _emberConcurrency.task)(function* (locale) {
      try {
        const adapter = this.store.adapterFor(this.modelName);
        const baseUrl = adapter.urlForDeleteRecord(this.resource.id, this.modelName, this.modelOptions);
        const url = `${baseUrl}/${locale}`;
        const response = yield this.authenticatedFetch.fetch(url, {
          method: 'DELETE'
        });
        if (!response || !response.ok) {
          // include the error message when throwing the error
          const error = yield response && response.json();
          throw new Error(error && error.message);
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        if (e && e.message) {
          this.uiDialog.showError(this.intl.t(`global.errors.${e.message}.title`), this.intl.t(`global.errors.${e.message}.message`));
        } else {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }
    }),
    /**
     * adds a given locale to the resource
     *
     * @type {Task}
     * @function addLocale
     * @param {String} locale
     */
    addLocale: (0, _emberConcurrency.task)(function* (locale) {
      const multilingualProperties = this.multilingualProperties.split(',');
      multilingualProperties.forEach(property => this.resource.set(property, ''));
      this.resource.set('language', locale);
      const changeset = new _emberChangeset.default(this.resource, (0, _emberChangesetValidations.default)(this.validation), this.validation);
      (0, _object.set)(this, 'changeset', changeset);
      this.uiAppSettings.setLocale(locale);
      (0, _object.set)(this, 'isEdit', true);
      yield (0, _emberConcurrency.timeout)(250);
    }),
    /**
     * Handles discarding changes
     *
     * @type {Task}
     * @function _confirmDiscard
     * @private
     */
    _confirmDiscard: (0, _emberConcurrency.task)(function* () {
      if (this.resource.isNew) {
        return this._close();
      }

      // resource has ditry attributes if new language
      if (this.resource.hasDirtyAttributes) {
        yield this.resource.rollbackAttributes();
        yield this._resetLocale();
      } else {
        this.changeset.rollback();
      }
      (0, _object.setProperties)(this, {
        isEdit: false,
        isDiscardChangesDialog: false
      });
    }),
    _close() {
      this.backRoute && this.router.transitionTo(this.backRoute);
      this.onClose();
    },
    _resetLocale() {
      const availableLanguages = this.resource.availableLanguages;

      // reload resource with default language if available, else take the first available one
      const reloadLanguage = availableLanguages.indexOf(this.defaultLanguage) >= 0 ? this.defaultLanguage : this.resource.get('availableLanguages.firstObject');
      return this._reloadModel(reloadLanguage);
    },
    _reloadModel(locale) {
      this.uiAppSettings.setLocale(locale);

      // reload model and create new changeset
      return this.resource.reload(this.modelOptions).then(() => {
        const changeset = new _emberChangeset.default(this.resource, (0, _emberChangesetValidations.default)(this.validation), this.validation);
        (0, _object.set)(this, 'changeset', changeset);
      });
    },
    onSave() {},
    onClose() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onClose() {
        // if is new close dialog otherwise show discard changes
        if (this.resource.get('isNew')) {
          const changes = this.changeset.get('changes');
          /*
           * Close when not dirty or
           * only `language` is changed,
           * as we set it to defaultLocale for new resources,
           * which unfortunatley makes it dirty.
           * Otherwise show discard-changes dialog
           */
          if (this.changeset.get('isPristine') || changes.length === 1 && changes[0].key === 'language') {
            this._close();
          } else {
            (0, _object.set)(this, 'isDiscardChangesDialog', true);
          }
        } else if (this.isEdit) {
          if (this.changeset.get('isPristine') && !this.resource.hasDirtyAttributes) {
            (0, _object.set)(this, 'isEdit', false);
          } else {
            (0, _object.set)(this, 'isDiscardChangesDialog', true);
          }
        } else {
          this._close();
        }
      }
    }
  });
});