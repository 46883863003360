define("@additive-apps/ui/components/ui-chip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    theme={{this.theme}}
    class="ui-chip flex justify-between items-center h1 font-sm bg-secondary--20 br1
    {{if this._isSelected " ui-chip--is-selected bg-blue--10 blue "}}
     {{if this.isSmall " ui-chip--small "}}
     {{unless this.isReadOnly " cpointer "}}
     {{@class}}
     {{@classNames}}"
    role="button"
    {{on "click" this._onClick}}
    {{did-update this.setIsSelected this.isSelected}}
  >
    <div class="ui-chip__text flex flex-grow-1 items-end">
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{this.text}}
        {{#if this.description}}
          <span class="ui-chip__description font-xs pl05">
            {{this.description}}
          </span>
        {{/if}}
      {{/if}}
    </div>
    {{#unless @isReadOnly}}
      {{#if this._isSelected}}
        {{! template-lint-disable no-nested-interactive }}
        <div class="flex" role="button" data-test-deselect-icon="true" {{on "click" this.deselect}}>
          {{svg-jar "close" width="16" height="16" class="ui-chip__action ml1"}}
        </div>
      {{else if this.showAddButton}}
        {{svg-jar "plus" width="16" height="16" class="ui-chip__action ml1" data-test-add-icon="true"}}
      {{/if}}
    {{/unless}}
  </div>
  */
  {
    "id": "nt5HcXze",
    "block": "[[[11,0],[17,1],[16,\"theme\",[30,0,[\"theme\"]]],[16,0,[29,[\"ui-chip flex justify-between items-center h1 font-sm bg-secondary--20 br1\\n  \",[52,[30,0,[\"_isSelected\"]],\" ui-chip--is-selected bg-blue--10 blue \"],\"\\n   \",[52,[30,0,[\"isSmall\"]],\" ui-chip--small \"],\"\\n   \",[52,[51,[30,0,[\"isReadOnly\"]]],\" cpointer \"],\"\\n   \",[30,2],\"\\n   \",[30,3]]]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,0,[\"_onClick\"]]],null],[4,[38,3],[[30,0,[\"setIsSelected\"]],[30,0,[\"isSelected\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-chip__text flex flex-grow-1 items-end\"],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"text\"]]],[1,\"\\n\"],[41,[30,0,[\"description\"]],[[[1,\"        \"],[10,1],[14,0,\"ui-chip__description font-xs pl05\"],[12],[1,\"\\n          \"],[1,[30,0,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[30,4]],[[[41,[30,0,[\"_isSelected\"]],[[[1,\"      \"],[11,0],[24,0,\"flex\"],[24,\"role\",\"button\"],[24,\"data-test-deselect-icon\",\"true\"],[4,[38,2],[\"click\",[30,0,[\"deselect\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"close\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"ui-chip__action ml1\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showAddButton\"]],[[[1,\"      \"],[1,[28,[35,6],[\"plus\"],[[\"width\",\"height\",\"class\",\"data-test-add-icon\"],[\"16\",\"16\",\"ui-chip__action ml1\",\"true\"]]]],[1,\"\\n    \"]],[]],null]],[]]]],[]],null],[13]],[\"&attrs\",\"@class\",\"@classNames\",\"@isReadOnly\",\"&default\"],false,[\"if\",\"unless\",\"on\",\"did-update\",\"has-block\",\"yield\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/components/ui-chip.hbs",
    "isStrictMode": false
  });
  let UiChipComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool | _propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.number), _dec5 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec6 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec7 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec8 = (0, _emberArgTypes.arg)(_propTypes.string), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _dec11 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiChipComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * whether the chip is selected or not
       *
       * @argument isSelected
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSelected", _descriptor, this);
      /**
       * the visible text of the simple chip and the multichip before any other option is selected
       *
       * @argument text
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "text", _descriptor2, this);
      /**
       * the visible text of the simple chip and the multichip before any other option is selected
       *
       * @argument classNames
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "classNames", _descriptor3, this);
      /**
       * the smaller text on the right of the title
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor4, this);
      /**
       * whether the chip should be displayed in a smaller version
       *
       * @argument isSmall
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSmall", _descriptor5, this);
      /**
       * whether the chip should be readonly
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor6, this);
      /**
       * whether an add icon should be displayed on unselected chip
       *
       * @argument showAddButton
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showAddButton", _descriptor7, this);
      /**
       * the background color of the chip
       * the default and fallback theme is white
       *
       * [theme='white'] $ui-color-white
       * [theme='transparent'] transparent
       * [theme='green'] $ui-color-green
       * [theme='red'] $ui-color-red
       *
       * @argument theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor8, this);
      /**
       * whether the chip is selected or not;
       * internal switch property
       *
       * @property _isSelected
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isSelected", _descriptor9, this);
      /**
       * callback called when the selection state or the selected options change
       *
       * @function onChange
       */
      _initializerDefineProperty(this, "onChange", _descriptor10, this);
      /**
       * callback called when the deselect button is clicked
       *
       * @function onDeselect
       */
      _initializerDefineProperty(this, "onDeselect", _descriptor11, this);
      /**
       * callback called when the body of the chip is clicked
       *
       * @function onBodyClick
       */
      _initializerDefineProperty(this, "onBodyClick", _descriptor12, this);
      this.setIsSelected();
    }
    /**
     *  selects the chip on click if its not readOnly
     *
     * @function _onClick
     * @event mouseup
     */
    _onClick() {
      if (!this.isReadOnly) {
        if (!this._isSelected) {
          this._isSelected = true;
        }
        this.onChange();
        this.onBodyClick();
      }
    }

    /**
     * Triggered on close icon click.
     *
     * @function deselect
     */
    deselect(event) {
      event.stopPropagation();
      if (!this.isReadOnly) {
        this._isSelected = false;
      }
      this.onChange(false);
      this.onDeselect();
    }
    setIsSelected() {
      if (this._isSelected !== this.isSelected) {
        this._isSelected = this.isSelected;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "text", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSmall", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showAddButton", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_isSelected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onDeselect", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onBodyClick", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deselect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setIsSelected"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiChipComponent);
});