define("@additive-apps/ui/components/ui-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@additive-apps/ui/utils/keyboard-constants", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _keyboardConstants, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-checkbox flex cpointer items-center {{
      if (or this._isChecked this.isPartiallyChecked) "ui-checkbox--checked" ""
    }} {{@class}} {{@classNames}}"
    theme={{this.theme}}
    role="button"
    tabindex="0"
    ...attributes
    {{on "click" this._onClick}}
    {{on "keyup" this._onKeyPress}}
  >
    <div class="ui-checkbox__box">
      {{#if this._isChecked}}
        {{svg-jar "checkbox-checked" class="db"}}
      {{else if this.isPartiallyChecked}}
        {{svg-jar "checkbox-partial" class="db"}}
      {{else}}
        {{svg-jar "checkbox-unchecked" class="db"}}
      {{/if}}
    </div>
  
    <div class="absolute"></div>
    {{#if (has-block)}}
      <div class="ui-label pl1">
        <span>
          {{yield}}
        </span>
      </div>
    {{else if this.label}}
      <div class="ui-label pl1">
        <span>
          {{this.label}}
        </span>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "XoNoaau+",
    "block": "[[[11,0],[16,0,[29,[\"ui-checkbox flex cpointer items-center \",[52,[28,[37,1],[[30,0,[\"_isChecked\"]],[30,0,[\"isPartiallyChecked\"]]],null],\"ui-checkbox--checked\",\"\"],\" \",[30,1],\" \",[30,2]]]],[16,\"theme\",[30,0,[\"theme\"]]],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[17,3],[4,[38,2],[\"click\",[30,0,[\"_onClick\"]]],null],[4,[38,2],[\"keyup\",[30,0,[\"_onKeyPress\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-checkbox__box\"],[12],[1,\"\\n\"],[41,[30,0,[\"_isChecked\"]],[[[1,\"      \"],[1,[28,[35,3],[\"checkbox-checked\"],[[\"class\"],[\"db\"]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isPartiallyChecked\"]],[[[1,\"      \"],[1,[28,[35,3],[\"checkbox-partial\"],[[\"class\"],[\"db\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],[\"checkbox-unchecked\"],[[\"class\"],[\"db\"]]]],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"absolute\"],[12],[13],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[10,0],[14,0,\"ui-label pl1\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,0],[14,0,\"ui-label pl1\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"if\",\"or\",\"on\",\"svg-jar\",\"has-block\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-checkbox.hbs",
    "isStrictMode": false
  });
  /**
   * @class UiCheckbox
   * @public
   */
  let UiCheckbox = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiCheckbox extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * whether the checkbox should bubble through click events
       *
       * @property bubbles
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "bubbles", _descriptor, this);
      /**
       * whether the checkbox is disabled
       *
       * @property isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
      /**
       * whether the checkbox should be displayed as checked
       *
       * @property isChecked
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isChecked", _descriptor3, this);
      /**
       * whether the checkbox should be displayed as partially checked
       * displays a minus icon instead of the tick
       *
       * @property isPartiallyChecked
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPartiallyChecked", _descriptor4, this);
      /**
       * the label of the checkbox
       *
       * @property label
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "label", _descriptor5, this);
      /**
       * whether the checkbox is in a blue (blue) or white (white) theme
       *
       * @property theme
       * @type {String}
       * @default 'blue'
       */
      _initializerDefineProperty(this, "theme", _descriptor6, this);
      /**
       * the value of the checkbox
       *
       * @property value
       * @type {Boolean}
       * @default false
       * @deprecated use isChecked instead
       */
      _initializerDefineProperty(this, "value", _descriptor7, this);
      /**
       * the function that is triggered when the checkbox
       * is clicked
       *
       * @function onChange
       */
      _initializerDefineProperty(this, "onChange", _descriptor8, this);
    }
    get _isChecked() {
      return this.isChecked || this.value;
    }
    /**
     * handles the click event on the checkbox
     *
     * @param {Event} event
     * @function click
     */
    _onClick(event) {
      if (!this.isDisabled) {
        this.onChange(!this._isChecked);
      }
      if (!this.bubbles) {
        event.stopPropagation();
      }
    }

    /**
     * handles a key-press event
     *
     * @event keypress
     * @param {*} event, key event
     * @function keyPress
     */
    _onKeyPress(event) {
      if (event.which === _keyboardConstants.BACKSPACE || event.which === _keyboardConstants.ENTER) {
        event.preventDefault();
        this._onClick(event);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "bubbles", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPartiallyChecked", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'blue';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onKeyPress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onKeyPress"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCheckbox);
});