define("@additive-apps/media/templates/components/mm-poi/movable-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cr3jPI6i",
    "block": "[[[10,0],[14,0,\"movable\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-poi/movable-container.hbs",
    "isStrictMode": false
  });
});