define("@additive-apps/ui/components/ui-selection-container/action-bar", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-selection-container/action-bar"], function (_exports, _component, _actionBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Action box for selection container
   *
   * @class action-bar
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _actionBar.default,
    classNames: ['ui-selection-actions', 'left-3', 'bottom-3', 'w-100', 'w-auto-sm', 'h2', 'flex', 'flex-row', 'items-center', 'pa2', 'white', 'bg-blue', 'br1', 'shadow-2'],
    classNameBindings: ['isFixed:fixed:absolute'],
    /**
     * whether the box is visible
     *
     * @property isVisible
     * @type {Boolean}
     * @default false
     */
    isVisible: false,
    /**
     *  used to position the action boxed fixed
     *
     * @property isFixed
     * @type {Boolean}
     * @default false
     */
    isFixed: false,
    /**
     *  triggers loading state
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     *  text shown during loading
     *
     * @property loadingText
     * @type {String}
     * @default null
     */
    loadingText: null,
    /**
     * whether the select all action should be disabled
     *
     * @property disableSelectAll
     * @type {Boolean}
     * @default false
     */
    disableSelectAll: false,
    toggleSelectionMode() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      toggleSelectionMode() {
        this.toggleSelectionMode();
      }
    }
  });
});