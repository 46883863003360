define("@additive-apps/ui/components/ui-collapsible-table/head/row/column", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/template", "@glimmer/tracking", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _object, _runloop, _template, _tracking, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th
    class="font-sm secondary pa0 {{this._classNames}}"
    style={{this._style}}
    {{did-insert this.onInsert}}
    {{did-update this.getFixedClassNames @isFirstColumnFixed}}
    {{did-update this.updateColumnWidth this.originalTableHead}}
  >
    <div
      class="ui-collapsible-table__head-column__outer-wrapper bb b--secondary-40 flex flex-column justify-center bg-white"
    >
      <div
        class="flex flex-row w-100 h-100 pa2
  
  
          {{if this.isFirstColumn "pl2 bg-white" "justify-end"}}"
        style={{this._style}}
      >
        {{yield}}
      </div>
    </div>
  
    {{#if this.isFirstColumn}}
      <div
        class="ui-collapsible-table__head-column__fixed-outer-wrapper bb b--secondary-40 flex-column justify-center bg-white
  
  
          {{this._fixedClasses}}"
      >
        <div
          class="ui-collapsible-table__head-column__fixed-wrapper flex flex-row w-100 h-100 pa2 pl2 bg-white"
          style={{this._fixedStyle}}
        >
          {{yield}}
        </div>
      </div>
    {{/if}}
  </th>
  */
  {
    "id": "92L1I8oR",
    "block": "[[[11,\"th\"],[16,0,[29,[\"font-sm secondary pa0 \",[30,0,[\"_classNames\"]]]]],[16,5,[30,0,[\"_style\"]]],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[4,[38,1],[[30,0,[\"getFixedClassNames\"]],[30,1]],null],[4,[38,1],[[30,0,[\"updateColumnWidth\"]],[30,0,[\"originalTableHead\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-collapsible-table__head-column__outer-wrapper bb b--secondary-40 flex flex-column justify-center bg-white\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"flex flex-row w-100 h-100 pa2\\n\\n\\n        \",[52,[30,0,[\"isFirstColumn\"]],\"pl2 bg-white\",\"justify-end\"]]]],[15,5,[30,0,[\"_style\"]]],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isFirstColumn\"]],[[[1,\"    \"],[10,0],[15,0,[29,[\"ui-collapsible-table__head-column__fixed-outer-wrapper bb b--secondary-40 flex-column justify-center bg-white\\n\\n\\n        \",[30,0,[\"_fixedClasses\"]]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"ui-collapsible-table__head-column__fixed-wrapper flex flex-row w-100 h-100 pa2 pl2 bg-white\"],[15,5,[30,0,[\"_fixedStyle\"]]],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@isFirstColumnFixed\",\"&default\"],false,[\"did-insert\",\"did-update\",\"if\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-collapsible-table/head/row/column.hbs",
    "isStrictMode": false
  });
  /**
   * This component represents a column of the header row.
   *
   * @class ui-collapsible-table/head/row/column
   */
  let UiCollapsibleTableHeadRowColumn = _exports.default = (_class = class UiCollapsibleTableHeadRowColumn extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the column object
       *
       * @argument column
       * @type {Object}
       * @default undefined
       */
      _initializerDefineProperty(this, "column", _descriptor, this);
      /**
       * the height of the column
       *
       * @argument columnHeight
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "columnHeight", _descriptor2, this);
      /**
       * the width of the first column
       *
       * @argument firstColumnWidth
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "firstColumnWidth", _descriptor3, this);
      /**
       * whether it is the first column
       *
       * @property isFirstColumn
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFirstColumn", _descriptor4, this);
      /**
       * whether the first column should be fixed on horizontal scroll
       *
       * @property isFirstColumnFixed
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFirstColumnFixed", _descriptor5, this);
      /**
       * the original table head to get the styles from
       *
       * @argument originalTableHead
       * @type {HTMLElement}
       * @default null
       */
      _initializerDefineProperty(this, "originalTableHead", _descriptor6, this);
      /**
       * the width of the column
       *
       * @property _columnWidth
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_columnWidth", _descriptor7, this);
      /**
       * the main DOM Element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor8, this);
      /**
       * the classes of the fixed container
       *
       * @property _fixedClasses
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_fixedClasses", _descriptor9, this);
    }
    get _classNames() {
      const classList = [];
      if (this.isFirstColumn) {
        classList.push('ui-collapsible-table__first-head-column text-left');
        if (this.isFirstColumnFixed) {
          classList.push('db');
        }
      } else {
        classList.push('text-right');
      }
      return classList.join(' ');
    }

    /**
     * The style attribute to set a min-width
     *
     * @computed _style
     * @type {String}
     * @private
     */
    get _style() {
      var _this$column;
      const styles = [];
      const minWidth = (_this$column = this.column) === null || _this$column === void 0 ? void 0 : _this$column.minWidth;
      if (minWidth) {
        styles.push(`min-width: ${typeof minWidth === 'number' ? `${minWidth}px` : minWidth};`);
      }
      if (this.isFirstColumn && this.firstColumnWidth) {
        styles.push(`width: ${typeof this.firstColumnWidth === 'number' ? `${this.firstColumnWidth}px` : this.firstColumnWidth};`);
      }
      if (!this.isFirstColumn && this._columnWidth) {
        styles.push(`width: ${typeof this._columnWidth === 'number' ? `${this._columnWidth}px` : this._columnWidth};`);
      }
      if (this.columnHeight) {
        styles.push(`height: ${this.columnHeight};`);
      }
      return (0, _template.htmlSafe)(styles.join(' '));
    }

    /**
     * The style attribute to set a min-width
     *
     * @computed _fixedStyle
     * @type {String}
     * @private
     */
    get _fixedStyle() {
      const styles = [];
      if (this.firstColumnWidth) {
        styles.push(`width: ${typeof this.firstColumnWidth === 'number' ? `${this.firstColumnWidth}px` : this.firstColumnWidth};`);
      }
      if (this.columnHeight) {
        styles.push(`height: ${this.columnHeight};`);
      }
      return (0, _template.htmlSafe)(styles.join(' '));
    }
    getFixedClassNames() {
      this._fixedClasses = this.isFirstColumn && this.isFirstColumnFixed ? 'flex absolute top-0 left-0 br' : 'dn';
    }
    onInsert(element) {
      this._element = element;
      (0, _runloop.scheduleOnce)('afterRender', this, 'updateColumnWidth');
    }

    /**
     * Sets the width as style on the element
     *
     * @function updateColumnWidth
     */
    updateColumnWidth() {
      if (!this._element) {
        return;
      }
      if (this.originalTableHead) {
        const elementIndex = [...this._element.parentNode.children].indexOf(this._element);
        const column = [...this.originalTableHead.querySelectorAll('th')][elementIndex];
        this._columnWidth = getComputedStyle(column).getPropertyValue('width');
        this._element.style.width = this._columnWidth;
      } else if (this.isFirstColumn) {
        this._columnWidth = this.firstColumnWidth || getComputedStyle(this._element).getPropertyValue('width');
        this._element.style.width = this._columnWidth;
      }
      if (this.isFirstColumn) {
        this.getFixedClassNames();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "column", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "columnHeight", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "firstColumnWidth", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isFirstColumn", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isFirstColumnFixed", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "originalTableHead", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_columnWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_fixedClasses", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getFixedClassNames", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getFixedClassNames"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateColumnWidth", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateColumnWidth"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCollapsibleTableHeadRowColumn);
});