define("@additive-apps/ui/components/ui-collapsible-table/head", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking", "ember-arg-types", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _templateFactory, _component2, _object, _runloop, _tracking, _emberArgTypes, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.onInsert}}
    {{did-update this._cleanUpFixedButtons this.isDraggable}}
    {{did-update this._updateOriginalTableHead this.triggerUpdate}}
  ></div>
  <UiCollapsibleTable::Head::Row
    @columns={{@columns}}
    @firstColumnWidth={{@firstColumnWidth}}
    @isDraggable={{this.isDraggable}}
    @isFirstColumnFixed={{this.isFirstColumnFixed}}
    @parentTable={{this.parentTable}}
    @scrollLeft={{this.scrollLeft}}
    @onDrag={{this.onDrag}}
    @triggerUpdate={{this.triggerUpdate}}
  />
  
  {{#if this.isStickyOnScroll}}
    <div
      class="ui-collapsible-table__fixed-head-wrapper fixed ohidden
        {{if this._showFixedHeadRow "" "dn"}}"
    >
      <UiCollapsibleTable::Head::Row
        @columns={{@columns}}
        @firstColumnWidth={{@firstColumnWidth}}
        @isDraggable={{this.isDraggable}}
        @isFirstColumnFixed={{this.isFirstColumnFixed}}
        @originalTableHead={{this._originalTableHead}}
        @parentTable={{this.parentTable}}
        @scrollLeft={{this.scrollLeft}}
        @onDrag={{this.onDrag}}
        @triggerUpdate={{this.triggerUpdate}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "9qsBUPIK",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[4,[38,1],[[30,0,[\"_cleanUpFixedButtons\"]],[30,0,[\"isDraggable\"]]],null],[4,[38,1],[[30,0,[\"_updateOriginalTableHead\"]],[30,0,[\"triggerUpdate\"]]],null],[12],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@columns\",\"@firstColumnWidth\",\"@isDraggable\",\"@isFirstColumnFixed\",\"@parentTable\",\"@scrollLeft\",\"@onDrag\",\"@triggerUpdate\"],[[30,1],[30,2],[30,0,[\"isDraggable\"]],[30,0,[\"isFirstColumnFixed\"]],[30,0,[\"parentTable\"]],[30,0,[\"scrollLeft\"]],[30,0,[\"onDrag\"]],[30,0,[\"triggerUpdate\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isStickyOnScroll\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ui-collapsible-table__fixed-head-wrapper fixed ohidden\\n      \",[52,[30,0,[\"_showFixedHeadRow\"]],\"\",\"dn\"]]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\",\"@firstColumnWidth\",\"@isDraggable\",\"@isFirstColumnFixed\",\"@originalTableHead\",\"@parentTable\",\"@scrollLeft\",\"@onDrag\",\"@triggerUpdate\"],[[30,1],[30,2],[30,0,[\"isDraggable\"]],[30,0,[\"isFirstColumnFixed\"]],[30,0,[\"_originalTableHead\"]],[30,0,[\"parentTable\"]],[30,0,[\"scrollLeft\"]],[30,0,[\"onDrag\"]],[30,0,[\"triggerUpdate\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@columns\",\"@firstColumnWidth\"],false,[\"did-insert\",\"did-update\",\"ui-collapsible-table/head/row\",\"if\"]]",
    "moduleName": "@additive-apps/ui/components/ui-collapsible-table/head.hbs",
    "isStrictMode": false
  });
  /**
   * This component wraps the table head and the sticky head
   *
   * @class ui-collapsible-table/head
   */
  let UiCollapsibleTableHead = _exports.default = (_class = class UiCollapsibleTableHead extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the header columns
       *
       * @argument columns
       * @type {Object[]}
       * @default null
       */
      _initializerDefineProperty(this, "columns", _descriptor, this);
      /**
       * whether the table head has drag events
       *
       * @argument isDraggable
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDraggable", _descriptor2, this);
      /**
       * whether the first column is fixed
       *
       * @argument isFirstColumnFixed
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFirstColumnFixed", _descriptor3, this);
      /**
       * whether the table head should stick to the top on scroll
       *
       * @argument isStickyOnScroll
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isStickyOnScroll", _descriptor4, this);
      /**
       * the table around the component
       *
       * @argument parentTable
       * @type {Element}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "parentTable", _descriptor5, this);
      _initializerDefineProperty(this, "scrollContainerSelector", _descriptor6, this);
      /**
       * the amount the table is scrolled to the left
       *
       * @argument scrollLeft
       * @type {Integer}
       * @default 0
       */
      _initializerDefineProperty(this, "scrollLeft", _descriptor7, this);
      /**
       * the offset from the top where the table head gets sticky, if isStickyOnScroll is true
       *
       * @argument scrollOffset
       * @type {Integer}
       * @default 0
       */
      _initializerDefineProperty(this, "scrollOffset", _descriptor8, this);
      /**
       * used to update styles of head if toggled
       *
       * @argument triggerUpdate
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "triggerUpdate", _descriptor9, this);
      /**
       * the original table head used to style the fixed table head
       *
       * @argument _originalTableHead
       * @type {HTMLElement}
       * @default null
       */
      _initializerDefineProperty(this, "_originalTableHead", _descriptor10, this);
      /**
       * the scrollable container around the table
       *
       * @argument _scrollContainer
       * @type {HTMLElement}
       * @default null
       */
      _initializerDefineProperty(this, "_scrollContainer", _descriptor11, this);
      /**
       * whether the fixed head row should be rendered
       *
       * @argument _showFixedHeadRow
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_showFixedHeadRow", _descriptor12, this);
      this._onScroll = (0, _runloop.bind)(this, this._onScroll);
      if (this.isStickyOnScroll) {
        if (this.scrollContainerSelector) {
          this._scrollContainer = document.querySelector(this.scrollContainerSelector);
          this._scrollContainer.addEventListener('scroll', this._onScroll);
        } else {
          window.addEventListener('scroll', this._onScroll);
        }
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.isDestroying) {
        return;
      }
      if (this._scrollContainer) {
        this._scrollContainer.removeEventListener('scroll', this._onScroll);
      } else {
        window.removeEventListener('scroll', this._onScroll);
      }
    }
    onDrag() {}

    /**
     * triggered on window/container scroll to check if the head should be sticky
     *
     * @function _onScroll
     */
    async _onScroll() {
      if (!this.parentTable) {
        return;
      }
      const {
        y: tablePositionTop,
        height: tableHeight
      } = this.parentTable.getBoundingClientRect();
      if (this._showFixedHeadRow && (tablePositionTop > this.scrollOffset || tablePositionTop + tableHeight < this.scrollOffset)) {
        this._showFixedHeadRow = false;
      } else if (!this._showFixedHeadRow && tablePositionTop < this.scrollOffset && tablePositionTop + tableHeight > this.scrollOffset) {
        this._showFixedHeadRow = true;
        await (0, _domUtil.nextTick)();
        const fixedHeadWrapper = this.parentTable.querySelector('.ui-collapsible-table__fixed-head-wrapper');
        if (fixedHeadWrapper) {
          fixedHeadWrapper.style.width = getComputedStyle(this.parentTable).getPropertyValue('width');
          fixedHeadWrapper.style.top = `${this.scrollOffset}px`;
          const arrowButtons = fixedHeadWrapper.querySelectorAll('.ui-button');
          if (arrowButtons !== null && arrowButtons !== void 0 && arrowButtons.length) {
            fixedHeadWrapper.append(arrowButtons[0]);
            fixedHeadWrapper.append(arrowButtons[1]);
          }
        }
      }
    }
    onInsert(element) {
      this._originalTableHead = element.parentNode.querySelector('.ui-collapsible-table__head-row');
    }
    _cleanUpFixedButtons() {
      if (!this.isDraggable) {
        const fixedButtons = this.parentTable.querySelectorAll('.ui-collapsible-table__fixed-head-wrapper .ui-button');
        if (fixedButtons && fixedButtons.length > 0) {
          fixedButtons.forEach(button => button.remove());
        }
      }
    }
    _updateOriginalTableHead(element) {
      this._originalTableHead = element.parentNode.querySelector('.ui-collapsible-table__head-row');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "columns", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDraggable", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFirstColumnFixed", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isStickyOnScroll", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "parentTable", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "scrollContainerSelector", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "scrollLeft", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "scrollOffset", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "triggerUpdate", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_originalTableHead", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_scrollContainer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_showFixedHeadRow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDrag", [_emberArgTypes.arg], Object.getOwnPropertyDescriptor(_class.prototype, "onDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_cleanUpFixedButtons", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_cleanUpFixedButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_updateOriginalTableHead", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_updateOriginalTableHead"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCollapsibleTableHead);
});