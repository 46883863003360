define("@additive-apps/media/helpers/mm-medium-url-options", ["exports", "@ember/component/helper", "@additive-apps/media/utils/medium"], function (_exports, _helper, _medium) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mmMediumUrlOptions = mmMediumUrlOptions;
  /**
   * A medium url helper to generate image url with given options.
   *
   *
   * ```js
   * {{mm-medium-url-options "https://www.image.com" (hash size="72x72")}}
   * ```
   *
   * @function mmMediumUrlOptions
   * @param {String} param.0 The image url
   * @param {Object} param.1 The image options
   */
  function mmMediumUrlOptions(_ref) {
    let [url, options] = _ref;
    return url ? (0, _medium.mediumUrlOptions)(url, options) : url;
  }
  var _default = _exports.default = (0, _helper.helper)(mmMediumUrlOptions);
});