define("@additive-apps/media/serializers/medium", ["exports", "@ember-data/serializer/rest", "@ember/utils"], function (_exports, _rest, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MediumSerializer extends _rest.default {
    normalizeResponse(store, primaryModelClass, payload) {
      if (payload !== null && payload !== void 0 && payload.media || payload !== null && payload !== void 0 && payload.medium) {
        payload.media = payload.media ? payload.media : [payload.medium];
        payload.media.forEach(medium => {
          medium.shared = false;
        });
      } else if (payload !== null && payload !== void 0 && payload.sharedMedia || payload !== null && payload !== void 0 && payload.sharedMedium) {
        payload.media = payload.sharedMedia ? payload.sharedMedia : [payload.sharedMedium];
        payload.media.forEach(medium => {
          medium.shared = true;
        });
      }
      return super.normalizeResponse(...arguments);
    }
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      /*
       * Api sends 204-no-content as response when no content exists.
       * Ember-data seems to have problems with that, so convert empty response into an empty array
       */
      if ((0, _utils.isEmpty)(payload)) {
        payload = {
          media: []
        };
      }
      return super.normalizeQueryResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  _exports.default = MediumSerializer;
});