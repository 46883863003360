define("@additive-apps/ui/components/ui-filter-inputs/query/single", ["exports", "@additive-apps/ui/components/ui-filter-inputs/single", "@additive-apps/ui/templates/components/ui-filter-inputs/query/single", "@ember/object"], function (_exports, _single, _single2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Filter-input for type `query/single`.
   * Renders a plain HTML-Select with provided `values`
   *
   * @extends ui-filter-inputs/single
   * @class ui-filter-inputs/query/single
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _single.default.extend({
    layout: _single2.default,
    /**
     * whether font and icon should be smaller
     *
     * @property isSmall
     * @type {Boolean}
     * @default false
     */
    isSmall: false,
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onChange(e) {
        const {
          value
        } = e.target;
        const nullValue = value === 'null' ? null : value;
        const selectedOption = this.filter.values.find(option => String(option.value) === String(nullValue));
        (0, _object.set)(this, '_value', selectedOption);

        // parents onChange
        this._onChange(this.filter.key, nullValue);
      }
    }
  });
});