define("@additive-apps/ui/components/ui-dd-sort", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-animated/motions/move", "ember-concurrency", "@ember/service", "@ember/array", "@ember/runloop", "ember-arg-types", "@ember/object", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _move, _emberConcurrency, _service, _array, _runloop, _emberArgTypes, _object, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="dn {{@class}} {{@classNames}}"
    ...attributes
    {{did-insert this.onDidInsert}}
    {{will-destroy this.onWillDestroy}}
  >
  </div>
  {{#animated-each this.items use=this.transition duration=400 as |item index|}}
    {{yield
      (hash
        item=(component
          "ui-dd-sort/item" draggable=this._isDraggable index=index onChange=this.onChange
        )
        isLoading=this.isLoading
      )
      item
      index
    }}
  {{/animated-each}}
  */
  {
    "id": "6w2yXSuJ",
    "block": "[[[11,0],[16,0,[29,[\"dn \",[30,1],\" \",[30,2]]]],[17,3],[4,[38,0],[[30,0,[\"onDidInsert\"]]],null],[4,[38,1],[[30,0,[\"onWillDestroy\"]]],null],[12],[1,\"\\n\"],[13],[1,\"\\n\"],[6,[39,2],[[30,0,[\"items\"]]],[[\"use\",\"duration\"],[[30,0,[\"transition\"]],400]],[[\"default\"],[[[[1,\"  \"],[18,6,[[28,[37,4],null,[[\"item\",\"isLoading\"],[[50,\"ui-dd-sort/item\",0,null,[[\"draggable\",\"index\",\"onChange\"],[[30,0,[\"_isDraggable\"]],[30,5],[30,0,[\"onChange\"]]]]],[30,0,[\"isLoading\"]]]]],[30,4],[30,5]]],[1,\"\\n\"]],[4,5]]]]]],[\"@class\",\"@classNames\",\"&attrs\",\"item\",\"index\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"animated-each\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-dd-sort.hbs",
    "isStrictMode": false
  });
  /**
   * Component that handles the drag and drop sorting by modifying the passed array.
   * The request to the `/position` endpoint is handled as well if the `sortModel`
   * property is defined.
   *
   *
   * ```hbs
   *  {{#ui-dd-sort items sortModel as |sort item|}}
   *    {{#sort.item draggable}}
   *       content
   *    {{/sort.item}}
   *  {{/ui-dd-sort}}
   * ```
   *
   * @class UiDdSortComponent
   */
  let UiDdSortComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array | _propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _dec9 = (0, _emberConcurrency.task)(function* (change, index) {
    if (change === 'start') {
      this._activeIndex = index;
      this._initialIndex = index;
      // set fixed height to container during drag process
      if (this.containerId) {
        this._draggedItems += 1;
        const container = document.getElementById(this.containerId);
        container.style.height = container.offsetHeight + 'px';
      }
    } else if (change === 'dragover') {
      // move the item only if the target is not the item itself
      if (index !== this._activeIndex) {
        // clone array or ember array
        const items = (0, _array.A)(this.items).toArray();
        // move item to new index
        items.splice(index, 0, items.splice(this._activeIndex, 1)[0]);
        this.setItems(items);
        this._activeIndex = index;
        // yield a timeout to avoid multiple animations
        yield (0, _emberConcurrency.timeout)(200);
      }
    }
  }).drop(), _dec10 = (0, _emberConcurrency.task)(function* (index) {
    this.isLoading = true;
    if (this.sortModel) {
      try {
        // clone array or ember array
        const items = (0, _array.A)(this.items).toArray();
        const {
          id
        } = items[this._activeIndex];
        const type = index === 0 ? 'before' : 'after';
        const relativeId = type === 'before' ? items[index + 1].id : items[index - 1].id;
        const adapter = this.store.adapterFor(this.sortModel);
        const url = `${adapter.buildURL(this.sortModel)}/${id}/position`;
        const response = yield this.authenticatedFetch.fetch(url, {
          method: 'PUT'
        }, {
          type,
          [this.requestKey]: relativeId
        });
        if (!response || response.status !== 204) throw new Error();
      } catch (e) {
        // move item back in case an error occurs
        const items = (0, _array.A)(this.items).toArray();
        items.splice(this._activeIndex, 0, items.splice(this._initialIndex, 1)[0]);
        this.setItems(items);
        this.uiDialog.showError();
      }
    }
    // reset index properties if no other drag operation is in progress
    if (this._onChange.isIdle) {
      this._activeIndex = null;
      this._initialIndex = null;
    }
    this.onDrop();
    this.isLoading = false;
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(500);
    if ((this._draggedItems -= 1) === 0) {
      const container = document.getElementById(this.containerId);
      container.style.height = null;
    }
  }), _class = class UiDdSortComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor3, this);
      /**
       * the items that should be sorted
       *
       * @argument items
       * @type {Array, Object}
       * @default undefined
       */
      _initializerDefineProperty(this, "items", _descriptor4, this);
      /**
       * The name of the model that is sorted.
       * If it is not specified the items are sorted in place and
       * no request is sent.
       *
       * @argument sortModel
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "sortModel", _descriptor5, this);
      /**
       * the key of the id in the position request
       *
       * @argument requestKey
       * @type {String}
       * @default 'id'
       */
      _initializerDefineProperty(this, "requestKey", _descriptor6, this);
      /**
       * the id of the parent container
       *
       * @argument containerId
       * @type {String}
       * @default ''
       */
      _initializerDefineProperty(this, "containerId", _descriptor7, this);
      /**
       * whether the sorting should be disabled
       *
       * @argument isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor8, this);
      /**
       * whether the sorting should be disabled on mobile devices (>600px window width)
       *
       * @argument isMobileDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isMobileDisabled", _descriptor9, this);
      /**
       * the index of the dragged item while its dragged around
       *
       * @property _activeIndex
       * @type {Number}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_activeIndex", _descriptor10, this);
      /**
       * the initial index of the item that is currently dragged
       *
       * @property _initialIndex
       * @type {Number}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_initialIndex", _descriptor11, this);
      /**
       * whether the window width is > 600px
       *
       * @property _isMobile
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isMobile", _descriptor12, this);
      /**
       * The number of items currently being dragged
       *
       * @property _draggedItems
       * @type {Number}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_draggedItems", _descriptor13, this);
      /**
       * whether it is loading
       *
       * @property isLoading
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isLoading", _descriptor14, this);
      /**
       * callback function executed after the sorted item is dropped
       *
       * @function onDrop
       */
      _initializerDefineProperty(this, "onDrop", _descriptor15, this);
      /**
       * callback function executed after the sorted item is dropped
       *
       * @function setItems
       */
      _initializerDefineProperty(this, "setItems", _descriptor16, this);
      /**
       * handles the changes when an item is dragged
       *
       * @function _onChange
       * @type {task}
       * @param {String} change the type of event
       * @param {Number} index the index of the item that triggered the event
       */
      _initializerDefineProperty(this, "_onChange", _descriptor17, this);
      /**
       * sends the request when an item is dropped
       *
       * @function _onDrop
       * @type {task}
       * @param {Number} index, the index where the item is dropped
       */
      _initializerDefineProperty(this, "_onDrop", _descriptor18, this);
      /**
       * Removes the fixed height of the container after all animations are finished all items are on
       * their final positions.
       *
       * @function _removeContainerHeight
       * @type {task}
       */
      _initializerDefineProperty(this, "_removeContainerHeight", _descriptor19, this);
    }
    /**
     * whether the drag and drop feature is disabled
     *
     * @computed _isDisabled
     * @type {Boolean}
     */
    get _isDisabled() {
      return this.isDisabled || this.isMobileDisabled && this._isMobile;
    }

    /**
     * whether the drag and drop is draggable
     *
     * @computed isDraggable
     * @type {Boolean}
     */
    get _isDraggable() {
      return !this._isDisabled;
    }
    _checkWindow() {
      this._isMobile = window.matchMedia('(max-width: 600px)').matches;
    }

    // eslint-disable-next-line
    transition(_ref2) {
      let {
        keptSprites
      } = _ref2;
      return function* () {
        for (let sprite of keptSprites) {
          (0, _move.default)(sprite);
        }
      }();
    }
    onChange(change, index) {
      if (change === 'end') {
        // remove fixed height from container
        if (this.containerId) {
          this._removeContainerHeight.perform();
        }
        return this._onDrop.perform(index);
      }
      return this._onChange.perform(change, index);
    }
    onDidInsert() {
      this._checkWindow = (0, _runloop.bind)(this, this._checkWindow);
      if (window && this.isMobileDisabled) {
        window.addEventListener('resize', this._checkWindow, false);
      }
    }
    onWillDestroy() {
      if (window) {
        window.removeEventListener('resize', this._checkWindow, false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sortModel", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "requestKey", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'id';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "containerId", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isMobileDisabled", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_activeIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_initialIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isMobile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_draggedItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onDrop", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "setItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_onChange", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "_onDrop", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "_removeContainerHeight", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDdSortComponent);
});