define("@additive-apps/media/templates/components/ui-carousel/container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5AHX9nAf",
    "block": "[[[41,[30,0,[\"renderContent\"]],[[[1,\"  \"],[10,0],[15,0,[28,[37,1],[\"ui-carousel__container ohidden \",[30,0,[\"containerClasses\"]]],null]],[15,5,[30,0,[\"_inlineStyles\"]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"concat\",\"yield\"]]",
    "moduleName": "@additive-apps/media/templates/components/ui-carousel/container.hbs",
    "isStrictMode": false
  });
});