define("@additive-apps/ui/components/ui-form-dialog", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-form-dialog", "@ember/service", "@ember/object", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "validated-changeset"], function (_exports, _component, _uiFormDialog, _service, _object, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC components that wraps a form and a modal.
   *
   * ```hbs
   *{{#ui-form-dialog
   *  title="Title"
   *  subtitle="Subtitle"
   *  formData=formData
   *  changeset=changeset
   *  validation=validation
   *  onClose=(action "onClose")
   *  onSubmit=(action "onSubmit")
   *  onValidate=(action "onValidate")
   *  as |dialog|
   * }}
   *  {{dialog.header}}
   *  {{dialog.body}}
   *{{/ui-form-dialog}}
   * ```
   *
   * @class ui-form-dialog
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    layout: _uiFormDialog.default,
    tagName: '',
    /**
     * The changeset for the form. If not passed
     * the changeset is created using the passed validation
     * and model
     *
     * @property changeset
     * @type {Changeset}
     * @default null
     */
    changeset: null,
    /**
     * The underlying object used to create the changeset
     *
     * @property formData
     * @type {Object}
     * @default null
     */
    formData: null,
    /**
     * Class names for the modal component
     *
     * @property modalClassNames
     * @type {String}
     * @default null
     */
    modalClassNames: null,
    /**
     * The size of the modal
     *
     * @property size
     * @type {String}
     * @default 'md'
     */
    size: 'md',
    /**
     * The modal subtitle
     *
     * @property subtitle
     * @type {String}
     * @default ''
     *
     */
    subtitle: '',
    /**
     * The modal title
     *
     * @property title
     * @type {String}
     * @default ''
     */
    title: '',
    /**
     * An optional changeset validation
     *
     * @property validation
     * @type {?Function}
     */
    validation: null,
    /**
     * Whether discard changes dialog is shown
     *
     * @property _isDiscardChangesDialog
     * @type {Boolean}
     * @private
     */
    _isDiscardChangesDialog: false,
    /**
     * Whether form has been submitted
     *
     * @property _submitTouched
     * @type {Boolean}
     * @private
     */
    _submitTouched: false,
    init() {
      this._super(...arguments);
      const {
        validation,
        formData,
        changeset
      } = this;

      // create changeset if formData is defined and no changeset is passed
      if ((!changeset || !(0, _validatedChangeset.isChangeset)(changeset)) && formData) {
        const changeset = new _emberChangeset.default(formData, (0, _emberChangesetValidations.default)(validation || {}), validation || {});
        (0, _object.set)(this, 'changeset', changeset);
      }
    },
    /**
     * validates the changeset and saves it or executes the onSubmit callback
     *
     * @function submit
     * @type {Task}
     */
    submit: (0, _emberConcurrency.task)(function* () {
      const {
        changeset
      } = this;
      (0, _object.set)(this, '_submitTouched', true);
      try {
        this.onValidate(changeset);
        yield changeset.validate();
        if (!changeset.get('isValid')) {
          return;
        }

        // execute on save callback
        yield this.onSave(changeset);
        this.onSuccess();

        // execute onSubmit callback with the saved data
        this.onSubmit(changeset.data);
      } catch (e) {
        this.onError(e);
      }
    }),
    /**
     * Saves the current changeset, can be overwritten
     *
     * @function onSave
     * @param {Changeset} changeset A POJO with form-data
     * @return {Promise}
     */
    onSave(changeset) {
      return changeset.save();
    },
    /**
     * Called when the user clicks on the close icon
     *
     * @function onClose
     * @return {Any}
     */
    onClose() {},
    /**
     * Called when valid form has been submitted sucessfully
     *
     * @function onSubmit
     * @param {Object} formData A POJO with form-data
     */
    onSubmit() {},
    /**
     * Called when the form was saved successfully
     *
     * @function onSuccess
     */
    onSuccess() {
      this.uiToast.showToast({
        title: this.intl.t('uiToast.success'),
        type: 'success'
      });
    },
    /**
     * Called when an error occured while saving the form
     *
     * @function onError
     * @param {Object} error the occured error
     */
    onError(/* error */
    ) {
      this.uiToast.showToast({
        title: this.intl.t('uiToast.unexpectedError'),
        type: 'error'
      });
    },
    /**
     * Called before the changeset is validated
     *
     * @function onValidate
     * @param {Changeset} changeset
     * @return {Promise}
     */
    onValidate() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onClose() {
        if (this.changeset.get('isDirty')) {
          (0, _object.set)(this, '_isDiscardChangesDialog', true);
        } else {
          this.onClose();
        }
      },
      onDiscard() {
        this.changeset.rollback();
        return this.onClose();
      }
    }
  });
});