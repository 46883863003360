define("@additive-apps/configurator/components/ae-configurations/image", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ae-configuration__image flex items-center justify-center pa05 bg-secondary--10 b--secondary-10 ba br1 {{if (or this.value (not @isReadOnly)) "bg-secondary--40--hover cpointer"}}"
    role="button"
    {{on "click" this.onImageClick}}
    {{did-insert this.setup}}
  >
    {{#if this.value}}
      <img
        src={{if this.value.thumbnailUrl this.value.thumbnailUrl this.value.url}}
        height="22"
        alt={{this.value.name}}
      />
    {{else}}
      {{svg-jar "image"}}
    {{/if}}
  </div>
  
  {{#if (and this.value (not @isReadOnly))}}
    <UiIconButton @class="ml1" @icon="close" @isDanger={{true}} @onClick={{this.onRemoveMedium}} />
  {{/if}}
  
  {{#if this.isMediaGallery}}
    <AeConfigurations::MediaGallery
      @galleryMedia={{this.galleryMedia}}
      @onChange={{this.onChange}}
      @hidePoi={{this.hidePoi}}
      @isReadOnly={{@isReadOnly}}
      @onClose={{fn (mut this.isMediaGallery) false}}
    />
  {{/if}}
  
  {{#if this.isMultimediaOverlay}}
    <MmMultimediaOverlay @onClose={{this.closeMultimedia}} @onAddMedium={{this.onAddMedium}}>
      {{outlet}}
    </MmMultimediaOverlay>
  {{/if}}
  */
  {
    "id": "VvpHBvjG",
    "block": "[[[11,0],[16,0,[29,[\"ae-configuration__image flex items-center justify-center pa05 bg-secondary--10 b--secondary-10 ba br1 \",[52,[28,[37,1],[[30,0,[\"value\"]],[28,[37,2],[[30,1]],null]],null],\"bg-secondary--40--hover cpointer\"]]]],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[30,0,[\"onImageClick\"]]],null],[4,[38,4],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"value\"]],[[[1,\"    \"],[10,\"img\"],[15,\"src\",[52,[30,0,[\"value\",\"thumbnailUrl\"]],[30,0,[\"value\",\"thumbnailUrl\"]],[30,0,[\"value\",\"url\"]]]],[14,\"height\",\"22\"],[15,\"alt\",[30,0,[\"value\",\"name\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,5],[\"image\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[41,[28,[37,6],[[30,0,[\"value\"]],[28,[37,2],[[30,1]],null]],null],[[[1,\"  \"],[8,[39,7],null,[[\"@class\",\"@icon\",\"@isDanger\",\"@onClick\"],[\"ml1\",\"close\",true,[30,0,[\"onRemoveMedium\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isMediaGallery\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@galleryMedia\",\"@onChange\",\"@hidePoi\",\"@isReadOnly\",\"@onClose\"],[[30,0,[\"galleryMedia\"]],[30,0,[\"onChange\"]],[30,0,[\"hidePoi\"]],[30,1],[28,[37,9],[[28,[37,10],[[30,0,[\"isMediaGallery\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isMultimediaOverlay\"]],[[[1,\"  \"],[8,[39,11],null,[[\"@onClose\",\"@onAddMedium\"],[[30,0,[\"closeMultimedia\"]],[30,0,[\"onAddMedium\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,13],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@isReadOnly\"],false,[\"if\",\"or\",\"not\",\"on\",\"did-insert\",\"svg-jar\",\"and\",\"ui-icon-button\",\"ae-configurations/media-gallery\",\"fn\",\"mut\",\"mm-multimedia-overlay\",\"component\",\"-outlet\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/image.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AeConfigurationsImageComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _object.computed)('item.{value,sync,syncedValue}'), _class = class AeConfigurationsImageComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "multimedia", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      /**
       * Whether the POI setting in the image-edit view should be hidden
       *
       * @argument hidePoi
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hidePoi", _descriptor4, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor5, this);
      _initializerDefineProperty(this, "item", _descriptor6, this);
      _initializerDefineProperty(this, "onChange", _descriptor7, this);
      _initializerDefineProperty(this, "isMediaGallery", _descriptor8, this);
      _initializerDefineProperty(this, "isMultimediaOverlay", _descriptor9, this);
      _initializerDefineProperty(this, "currentRoute", _descriptor10, this);
    }
    get value() {
      return this.item.sync ? this.item.syncedValue : this.item.value;
    }
    get galleryMedia() {
      return this.value && [{
        mediumId: this.value.mediumId,
        name: this.value.name,
        url: this.value.url,
        thumbnailUrl: this.value.thumbnailUrl,
        transformations: this.value.transformations,
        type: this.value.type,
        isImage: true
      }];
    }
    setup() {
      this.currentRoute = this.router.currentRouteName;
    }
    onImageClick() {
      if (this.item.value || this.item.sync && this.item.syncedValue) {
        this.isMediaGallery = true;
      } else {
        if (this.isReadOnly) {
          return;
        }
        this.isMultimediaOverlay = true;
        const path = this.router.currentRouteName.split('.');
        if (path[path.length - 1] === 'index') {
          path.pop();
        }
        const mmRoute = `${path.join('.')}.additive-multimedia-engine`;
        const selectionOptions = {
          maxCount: 1
        };
        if (this.item.type === 'medium') {
          selectionOptions.mimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/x-youtube'];
          selectionOptions.warningMessage = this.intl.t('aeConfiguration.validations.multimedia.medium');
        } else {
          selectionOptions.type = 'image';
          selectionOptions.warningMessage = this.intl.t('aeConfiguration.validations.multimedia.image');
        }
        this.multimedia.setSelectionOptions(selectionOptions);
        this.router.transitionTo(mmRoute);
      }
    }
    onAddMedium(_, _ref2) {
      let [resource] = _ref2;
      this.onChange(resource);
    }
    onRemoveMedium() {
      this.onChange(null);
      this.item.sync && this.onChange(false, 'sync');
    }
    closeMultimedia() {
      this.isMultimediaOverlay = false;
      this.multimedia.resetSelectionOptions();
      this.router.transitionTo(this.currentRoute);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "multimedia", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hidePoi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isMediaGallery", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isMultimediaOverlay", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currentRoute", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "value", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "value"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onImageClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onImageClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAddMedium", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onAddMedium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRemoveMedium", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRemoveMedium"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMultimedia", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMultimedia"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsImageComponent);
});