define("@additive-apps/ui/templates/components/ui-filter-inputs/date", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YSqlCW+m",
    "block": "[[[8,[39,0],null,[[\"@filter\",\"@initialValue\",\"@onChange\"],[[30,0,[\"filter\"]],[30,0,[\"_singleInitialValue\"]],[28,[37,1],[[30,0],\"onOptionChange\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"_isCustom\"]],[[[41,[30,0,[\"_hasCustomMeta\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@label\",\"@placeholder\",\"@initialDay\",\"@initialMonth\",\"@onChange\"],[[30,0,[\"filter\",\"label\"]],[30,0,[\"filter\",\"label\"]],[28,[37,4],[[30,0,[\"_initialCustomMetaValue\"]],\"day\"],null],[28,[37,4],[[30,0,[\"_initialCustomMetaValue\"]],\"month\"],null],[28,[37,1],[[30,0],\"onCustomMetaChange\"],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@label\",\"@placeholder\",\"@dateFormat\",\"@initialDate\",\"@onSelect\"],[[30,0,[\"filter\",\"label\"]],[30,0,[\"filter\",\"label\"]],\"dd.MM.yyyy\",[30,0,[\"_initialDate\"]],[28,[37,1],[[30,0],\"onDatepickerChange\"],null]]],null],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"ui-filter-inputs/single\",\"action\",\"if\",\"ui-month-day-picker\",\"get\",\"ui-datepicker\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/date.hbs",
    "isStrictMode": false
  });
});