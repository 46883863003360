define("@additive-apps/configurator/components/aw-configurator/style-editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="aw-configurator__editor aw-configurator__style-editor flex-shrink-0"
    {{did-insert this.didInsert}}
    {{will-destroy this.willDestroy}}
  >
    <div class="content flex-shrink-0 h-100">
      {{yield
        (hash
          sidebar=(component
            "aw-configurator/style-editor/sidebar"
            onMouseEnter=this.onMouseEnterSidebar
            onMouseLeave=this.onMouseExitSidebar
            isReadOnly=@isReadOnly
            hideText=this.isSidebarSmall
          )
        )
      }}
      <div class="aw-configurator__inner-container w-100 h-100 pa3 flex-grow-1">
        <div id="editor-observer"></div>
  
        {{yield (hash content=(component "ui-blank-template"))}}
      </div>
    </div>
  </div>
  */
  {
    "id": "qRAdT4aF",
    "block": "[[[11,0],[24,0,\"aw-configurator__editor aw-configurator__style-editor flex-shrink-0\"],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"content flex-shrink-0 h-100\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,3],null,[[\"sidebar\"],[[50,\"aw-configurator/style-editor/sidebar\",0,null,[[\"onMouseEnter\",\"onMouseLeave\",\"isReadOnly\",\"hideText\"],[[30,0,[\"onMouseEnterSidebar\"]],[30,0,[\"onMouseExitSidebar\"]],[30,1],[30,0,[\"isSidebarSmall\"]]]]]]]]]],[1,\"\\n    \"],[10,0],[14,0,\"aw-configurator__inner-container w-100 h-100 pa3 flex-grow-1\"],[12],[1,\"\\n      \"],[10,0],[14,1,\"editor-observer\"],[12],[13],[1,\"\\n\\n      \"],[18,2,[[28,[37,3],null,[[\"content\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isReadOnly\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/style-editor.hbs",
    "isStrictMode": false
  });
  const MEDIA_QUERY = '(max-width: 1280px)';
  let AwConfiguratorStyleEditorComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwConfiguratorStyleEditorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the title displayed in the navbar
       *
       * @property title
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "backAction", _descriptor2, this);
      /**
       * whether the sidebar is collapsed
       *
       * @property isSidebarSmall
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isSidebarSmall", _descriptor3, this);
    }
    onResize(e) {
      this.isSidebarSmall = e.matches;
    }
    didInsert() {
      // set property on initial render
      if (window.matchMedia(MEDIA_QUERY).matches) {
        this.isSidebarSmall = true;
      }
      window.matchMedia(MEDIA_QUERY).addEventListener('change', this.onResize);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      window.matchMedia(MEDIA_QUERY).removeEventListener('change', this.onResize);
    }
    async onMouseEnterSidebar() {
      this.isSidebarSmall = false;
    }
    async onMouseExitSidebar() {
      // only set if screen is small
      if (window.matchMedia(MEDIA_QUERY).matches) {
        this.isSidebarSmall = true;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "backAction", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isSidebarSmall", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onResize", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseEnterSidebar", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseEnterSidebar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMouseExitSidebar", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseExitSidebar"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorStyleEditorComponent);
});