define("@additive-apps/ui/helpers/ui-optional-action", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiOptionalAction = uiOptionalAction;
  const noop = () => {};
  function uiOptionalAction(_ref) {
    let [fn] = _ref;
    return fn || noop;
  }
  var _default = _exports.default = (0, _helper.helper)(uiOptionalAction);
});