define("@additive-apps/ui/helpers/ui-parse-int", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiParseInt = uiParseInt;
  /**
   * Parses and returnes first param to integer.
   * If parsesInts result is NaN it will return a fallback which is 0 by default.
   * You can overwrite the fallback by passing it as the second param;
   *
   * @function uiParseInt
   */
  function uiParseInt(params) {
    const value = params[0];
    const fallback = params[1] || 0;
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? fallback : parsedValue;
  }
  var _default = _exports.default = (0, _helper.helper)(uiParseInt);
});