define("@additive-apps/configurator/templates/components/aw-configurator/preview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "CyyfzVZy",
    "block": "[[[41,[30,0,[\"hasError\"]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column justify-center items-center secondary pa3 h-100\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"widget.errorMessage\"],null]],[1,\"\\n    \"],[10,0],[14,0,\"pa2\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@onClick\",\"@isPromise\"],[[28,[37,1],[\"widget.retry\"],null],[30,0,[\"onRetry\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[10,0],[14,0,\"loading-overlay bg-light absolute top-0 left-0 bottom-0 right-0 flex flex-column items-center justify-center primary font-xl font-medium\"],[12],[1,\"\\n\"],[41,[30,0,[\"isStyleEditor\"]],[[[1,\"        \"],[1,[28,[35,1],[\"style.loadingMessage\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[\"widget.loadingMessage\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[10,0],[14,0,\"pa2\"],[12],[1,\"\\n        \"],[8,[39,3],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@hasCustomSentinel\",\"@isMenuHidden\",\"@title\",\"@intersectionTargetSelectorId\"],[true,true,[30,0,[\"title\"]],\"aw-configurator__observer\"]],[[\"default\"],[[[[1,\"\\n      \"],[18,2,[[28,[37,6],null,[[\"navLeft\"],[[30,1,[\"left\"]]]]]]],[1,\"\\n      \"],[18,2,[[28,[37,6],null,[[\"navRight\"],[[30,1,[\"right\"]]]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,0],[14,0,\"aw-configurator__preview__content flex flex-column h-100 oauto\"],[12],[1,\"\\n    \"],[10,0],[14,1,\"aw-configurator__observer\"],[14,0,\"mt1\"],[12],[13],[1,\"\\n    \"],[18,2,[[28,[37,6],null,[[\"content\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"navbar\",\"&default\"],false,[\"if\",\"t\",\"ui-button\",\"ui-loading\",\"ui-navigation-bar\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/templates/components/aw-configurator/preview.hbs",
    "isStrictMode": false
  });
});