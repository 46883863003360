define("@additive-apps/auth/serializers/organization", ["exports", "@ember-data/serializer/rest", "@ember/utils"], function (_exports, _rest, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class OrganizationSerializer extends _rest.default {
    serialize(snapshot, options) {
      var json = super.serialize(snapshot, options);
      json.subject = json.title;
      delete json.title;
      Object.keys(json).forEach(key => (0, _utils.isNone)(json[key]) && delete json[key]);
      return json;
    }
    serializeIntoHash(data, _, record, options) {
      Object.assign(data, this.serialize(record, options));
    }
  }
  _exports.default = OrganizationSerializer;
});