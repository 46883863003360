define("@additive-apps/auth/adapters/organization", ["exports", "@additive-apps/auth/adapters/application", "@ember/application", "@ember/service", "@ember-data/adapter/error"], function (_exports, _application, _application2, _service, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let OrganizationAdapter = _exports.default = (_class = class OrganizationAdapter extends _application.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _defineProperty(this, "namespace", 'api');
    }
    // account API host is loaded from application ENV
    get host() {
      return (0, _application2.getOwner)(this).resolveRegistration('config:environment').APP.accountApiHost;
    }

    /**
     * Headers for 'organization' model which goes to account-api, instead of app-api
     */
    get headers() {
      const headers = {
        Accept: 'application/vnd.additive+json',
        'Content-Type': 'application/json'
      };

      /* Apply authentication headers */
      return Object.assign({}, super.headers, headers);
    }
    handleResponse(status, headers, payload) {
      if (this.isInvalid(...arguments)) {
        payload.errors = (0, _error.errorsHashToArray)(payload.errors);
      }

      // organization does not extist or user has no permission to access it
      // TODO: transition to missing permissions route when unauthorized
      if (status === 403 || status === 404) {
        const {
          router
        } = this;
        router.transitionTo('/not-found');
        return;
      } else if (status === 401) {
        if (this.session.isAuthenticated) {
          this.session.invalidate();
        }
      }
      return super.handleResponse(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});