define("@additive-apps/configurator/components/aw-configurator/editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="aw-configurator__editor bg-white pb3 ph3 flex flex-column flex-shrink-0 h-100 oauto {{@classNames}}"
    ...attributes
  >
    <div class="header ph1 pb3 flex-shrink-0">
      <div id="editor-observer" class="mt1"></div>
      <div class="font-sm font-medium secondary">
        {{this.subtitle}}
      </div>
      <div class="font-xl white font-medium">
        {{this.title}}
      </div>
    </div>
  
    {{yield (hash group=(component "panel-group"))}}
  </div>
  */
  {
    "id": "st2bP6sp",
    "block": "[[[11,0],[16,0,[29,[\"aw-configurator__editor bg-white pb3 ph3 flex flex-column flex-shrink-0 h-100 oauto \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"header ph1 pb3 flex-shrink-0\"],[12],[1,\"\\n    \"],[10,0],[14,1,\"editor-observer\"],[14,0,\"mt1\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-sm font-medium secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"subtitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-xl white font-medium\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,3,[[28,[37,1],null,[[\"group\"],[[50,\"panel-group\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@classNames\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/editor.hbs",
    "isStrictMode": false
  });
  let AwConfiguratorEditor = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwConfiguratorEditor extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isReadOnly", _descriptor, this);
      _initializerDefineProperty(this, "backAction", _descriptor2, this);
      _initializerDefineProperty(this, "onSave", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "backAction", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorEditor);
});