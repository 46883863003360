define("@additive-apps/ui/components/ui-content-info", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed", "@ember/runloop", "@ember/object"], function (_exports, _component, _templateFactory, _computed, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-content-info__content flex flex-wrap justify-start ohidden">
    {{yield}}
    <div id={{concat "accordion-sentinel-" this.elementId}} class="w-100"></div>
  </div>
  {{#if this._shouldDisplayButton}}
    <div class="pt2 font-sm blue cpointer" role="button" {{action "toggleAccordion"}}>
      {{t (if this.isOpen "uiContentInfo.showLess" "uiContentInfo.showMore")}}
    </div>
  {{/if}}
  
  */
  {
    "id": "zhDBhnj8",
    "block": "[[[10,0],[14,0,\"ui-content-info__content flex flex-wrap justify-start ohidden\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n  \"],[10,0],[15,1,[28,[37,1],[\"accordion-sentinel-\",[30,0,[\"elementId\"]]],null]],[14,0,\"w-100\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"_shouldDisplayButton\"]],[[[1,\"  \"],[11,0],[24,0,\"pt2 font-sm blue cpointer\"],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"toggleAccordion\"],null],[12],[1,\"\\n    \"],[1,[28,[35,4],[[52,[30,0,[\"isOpen\"]],\"uiContentInfo.showLess\",\"uiContentInfo.showMore\"]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"concat\",\"if\",\"action\",\"t\"]]",
    "moduleName": "@additive-apps/ui/components/ui-content-info.hbs",
    "isStrictMode": false
  });

  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNames: ['ui-content-info', 'pv2'],
    classNameBindings: ['isOpen:ui-content-info--open'],
    /**
     * whether the content inside the accordion is overflowing and the
     * "show more" button should be displayed
     *
     * @property isContentOverflowing
     * @type {Boolean}
     */
    isContentOverflowing: false,
    /**
     * whether the accordion is open or collapsed
     *
     * @property isOpen
     * @type {Boolean}
     */
    isOpen: false,
    /**
     * the intersection observer
     *
     * @property _observer
     * @type {IntersectionObserver}
     * @private
     */
    _observer: null,
    /**
     * the intersection observer target dom element
     *
     * @property _observerTarget
     * @type {Element}
     * @private
     */
    _observerTarget: null,
    /**
     * whether the "show more" button should be displayed
     *
     * @property _shouldDisplayButton
     * @type {Boolean}
     * @private
     */
    _shouldDisplayButton: (0, _computed.or)('isOpen', 'isContentOverflowing'),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      if (document && this.element) {
        const observerOptions = {
          root: this.element.querySelector('.ui-content-info__content'),
          rootMargin: '1px',
          threshold: 1.0
        };
        const _observer = new IntersectionObserver(changes => {
          (0, _runloop.run)(() => this._setOverflow(changes));
        }, observerOptions);
        const _observerTarget = document.getElementById(`accordion-sentinel-${this.elementId}`);
        _observer.observe(_observerTarget);
        (0, _object.setProperties)(this, {
          _observer,
          _observerTarget
        });
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      const _observer = this._observer,
        _observerTarget = this._observerTarget;
      if (_observer && _observerTarget) {
        _observer.unobserve(_observerTarget);
      }
    },
    /**
     * checks whether content is overflowing and sets the `isContentOverflowing` property
     *
     * @function _setOverflow
     * @private
     */
    _setOverflow(changes) {
      if (changes && changes.length > 0) {
        const isIntersecting = changes[0].isIntersecting;
        this.isDestroying ? null : (0, _object.set)(this, 'isContentOverflowing', !isIntersecting);
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      toggleAccordion() {
        const isOpen = this.isOpen;
        (0, _object.set)(this, 'isOpen', !isOpen);
      }
    }
  }));
});