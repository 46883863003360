define("@additive-apps/media/components/mm-multimedia-overlay", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-multimedia-overlay", "@ember/application", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/runloop", "ember-concurrency", "@additive-apps/media/constants"], function (_exports, _component, _mmMultimediaOverlay, _application, _service, _object, _computed, _utils, _runloop, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  This is the multimedia-engine overlay wrapper which mounts the engine inside a modal.
   *  This is handling the communication between engine and host-app too (Not implemented yet).
   *
   * ```hbs
   *{{#mm-multimedia-overlay onClose=(action "onModalClose") onAddMedium=(action "addMedia")}}
   *   {{outlet}}
   *{{/mm-multimedia-overlay}}
   * ```
   *
   * @class mm-multimedia-overlay
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmMultimediaOverlay.default,
    router: (0, _service.inject)(),
    multimedia: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    ROOT_SHARED_FOLDER_ID: _constants.ROOT_SHARED_FOLDER_ID,
    ENV: (0, _object.computed)({
      get() {
        return (0, _application.getOwner)(this).resolveRegistration('config:environment');
      }
    }),
    _organizationId: (0, _computed.alias)('currentUser.currentOrganization.id'),
    tagName: '',
    /**
     * Whether the multimedia-overlay is open
     *
     * @property isMultimediaOverlay
     * @type {Boolean}
     * @private
     */
    isMultimediaOverlay: false,
    /**
     * The sub-route of the engine, used to check if overlay should be open
     *
     * @property multimediaEngineRouteName
     * @type {String}
     */
    multimediaEngineRouteName: null,
    /**
     * the parent route to which it should return when the overlay is closed
     *
     * @property parentRoute
     * @type {String}
     */
    parentRoute: null,
    /**
     * Downloads the content of the folder
     *
     * @property downloadFolder
     * @param {Object} folder, the folder to be downloaded
     * @type {Task}
     */
    downloadFolder: (0, _emberConcurrency.task)(function* (folder) {
      if (!folder) {
        return this.uiDialog.showError();
      }
      try {
        const response = yield this.authenticatedFetch.fetch(`${this.ENV.APP.mediaApi}/${this._organizationId}/folders/${folder.id}/download`, {
          headers: {
            Accept: 'application/vnd.additive+json; version=1'
          },
          method: 'POST'
        }, null, [404]);
        if (!response || !response.ok) {
          throw response;
        }
        this.uiToast.showToast({
          title: this.intl.t('mmBulkDownload.success.title'),
          description: this.intl.t('mmBulkDownload.success.description'),
          type: 'success'
        });
      } catch (e) {
        if (e.status === 404) {
          this.uiDialog.showError(this.intl.t('mmBulkDownload.error.title'), this.intl.t('mmBulkDownload.error.description'));
          return;
        }
        this.uiDialog.showError();
      }
    }),
    actions: {
      onDownloadFolder(folder) {
        const intl = this.intl;
        this.uiDialog.showConfirm(intl.t('mmBulkDownload.confirm.title'), intl.t('mmBulkDownload.confirm.description'), () => this.downloadFolder.perform(folder), intl.t('mmBulkDownload.download'), true);
      }
    },
    _hasDownload: (0, _object.computed)('multimedia.currentFolder.{id,shared}', 'currentUser.{currentOrganization.isPartner,isViewer}', {
      get() {
        var _this$currentUser;
        if (!this.multimedia.currentFolder) {
          return false;
        }
        if (this.multimedia.currentFolder.id === _constants.ROOT_SHARED_FOLDER_ID) {
          return false;
        }
        if ((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && _this$currentUser.isViewer) {
          return false;
        }
        return !this.multimedia.currentFolder.shared || this.currentUser.currentOrganization.isPartner;
      }
    }),
    /**
     * specifies the route of the multimedia engine inside the application
     * used in the folder-tree
     *
     * @computed _multimediaBaseRouteName
     * @type {String}
     * @private
     */
    _multimediaBaseRouteName: (0, _object.computed)('router.currentRouteName', {
      get() {
        const currentRoute = this.router.currentRouteName;
        const index = currentRoute.indexOf(_constants.MEDIA_ENGINE_NAME);

        // extract route path including engine name
        return currentRoute.substr(0, index + _constants.MEDIA_ENGINE_NAME.length);
      }
    }),
    /**
     * whether the engine routes have been lazily loaded
     *
     * @computed _isEngineLoaded
     * @type {Boolean}
     * @private
     */
    _isEngineLoaded: (0, _object.computed)('multimediaEngineRouteName', 'router.currentRouteName', {
      get() {
        return this.router.currentRouteName.indexOf(this.multimediaEngineRouteName) >= 0;
      }
    }),
    init() {
      this._super(...arguments);
      const routerService = this.router;

      /*
       * Check if router-service has `on` events (ember >=3.6 only).
       *
       * When not you will have to handle `isMultimediaOverlay` manually
       * on route-changes.
       */
      if (typeof routerService.on === 'function') {
        // Observe route-changes to notice whether curent route is a engine route
        routerService.on('routeWillChange', transition => {
          this._isEngineRoute(transition.targetName);
        });
      }
      (0, _utils.isNone)(this.multimediaEngineRouteName) && (0, _object.set)(this, 'multimediaEngineRouteName', _constants.MEDIA_ENGINE_NAME);

      // Detect if entry-route is a engine-route
      this._isEngineRoute(routerService.currentRouteName);
      const multimedia = this.multimedia;
      // set callbacks
      multimedia.setCallbacks({
        onAddSelectedMedia: (0, _runloop.bind)(this, this.onAddMedium),
        onCloseMultimedia: (0, _runloop.bind)(this, this.onClose)
      }, 'overlay');
    },
    willDestroyElement() {
      this._super(...arguments);
      this.multimedia.resetCallbacks('overlay');
    },
    /**
     * Check if current route is the route or a subroute of the engine
     *
     * @function _isEngineRoute
     * @param  {[String]} routeName   The routename to check
     * @return {Boolean}              Whether its the route or a subroute
     * @private
     */
    _isEngineRoute(routeName) {
      if (this.isDestroying) {
        return;
      }
      routeName && (0, _object.set)(this, 'isMultimediaOverlay', routeName.indexOf(this.multimediaEngineRouteName) >= 0);
    },
    /**
     * Call to open multimedia filter
     *
     * @function _openFilter
     * @return {void}
     * @private
     */
    _openFilter() {
      this.router.transitionTo(`${this._multimediaBaseRouteName}.filter`);
    },
    /**
     * Default close event that navigates to the parent route
     *
     * @function onClose
     */
    onClose() {
      if (this.parentRoute) {
        this.router.transitionTo(this.parentRoute);
      }
    },
    /**
     * callback called when media is added
     *
     * @function onAddMedium
     */
    onAddMedium() {}
  });
});