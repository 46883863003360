define("@additive-apps/ui/components/ui-toggle-group/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this._isActive}}
      <UiButton @class="ui-toggle-group__item" @icon={{this.icon}} @isSecondary={{true}} @onClick={{this._onClick}} ...attributes />
  {{else}}
      <UiActionButton @class="ui-toggle-group__item" @icon={{this.icon}} @onClick={{this._onClick}} ...attributes />
  {{/if}}
  */
  {
    "id": "1YU7BO9y",
    "block": "[[[41,[30,0,[\"_isActive\"]],[[[1,\"    \"],[8,[39,1],[[17,1]],[[\"@class\",\"@icon\",\"@isSecondary\",\"@onClick\"],[\"ui-toggle-group__item\",[30,0,[\"icon\"]],true,[30,0,[\"_onClick\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[17,1]],[[\"@class\",\"@icon\",\"@onClick\"],[\"ui-toggle-group__item\",[30,0,[\"icon\"]],[30,0,[\"_onClick\"]]]],null],[1,\"\\n\"]],[]]]],[\"&attrs\"],false,[\"if\",\"ui-button\",\"ui-action-button\"]]",
    "moduleName": "@additive-apps/ui/components/ui-toggle-group/item.hbs",
    "isStrictMode": false
  });
  /**
   * The toggle-group-item displays the content of the toggle value
   *
   * @class ui-toggle-group/item
   */
  let UiToggleGroupItem = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.number), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiToggleGroupItem extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the theme of the toggle-item
       *  - white
       *  - dark
       *
       * @property theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor, this);
      /**
       * the icon
       *
       * @property icon
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "icon", _descriptor2, this);
      /**
       * the value of the toggle item
       *
       * @property value
       * @type {String|Number}
       * @default null
       */
      _initializerDefineProperty(this, "value", _descriptor3, this);
      /**
       * the active value of the toggle-group
       *
       * @property activeValue
       * @type {activeValue}
       */
      _initializerDefineProperty(this, "activeValue", _descriptor4, this);
      /**
       * whether the toggle is read-only
       *
       * @property isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor5, this);
      /**
       * calls the onChange function inside the toggle-group
       *
       * @function onChange
       * @param {String|Number} value
       */
      _initializerDefineProperty(this, "onChange", _descriptor6, this);
    }
    /**
     * whether the item is active
     *
     * @computed _isActive
     * @type {Boolean}
     * @private
     */
    get _isActive() {
      return this.activeValue === this.value;
    }
    _onClick(e) {
      e.stopPropagation();
      if (this.isReadOnly) return;
      this.onChange(this.value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "activeValue", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return (/* value */) => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiToggleGroupItem);
});