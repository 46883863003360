define("@additive-apps/ui/components/ui-card/footer/popover", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="column popover">
    <UiPopover as |pop|>
      <pop.trigger>
        <UiPopoverTrigger @state={{@state}} />
      </pop.trigger>
      <pop.content>
        {{yield}}
      </pop.content>
    </UiPopover>
  </div>
  */
  {
    "id": "Xs9vKfp1",
    "block": "[[[10,0],[14,0,\"column popover\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@state\"],[[30,2]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13]],[\"pop\",\"@state\",\"&default\"],false,[\"ui-popover\",\"ui-popover-trigger\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/popover.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});