define("@additive-apps/ui/components/ui-collapsible-table", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/runloop", "@glimmer/tracking", "lodash.clonedeep", "ember-arg-types", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/utils/table-util"], function (_exports, _component, _templateFactory, _component2, _object, _runloop, _tracking, _lodash, _emberArgTypes, _domUtil, _tableUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-collapsible-table relative bg-white font-sm br2-md ohidden {{@class}} {{@classNames}}"
    {{did-insert this.onInsertElement}}
    {{did-update this.onUpdateHiddenColumns this.hiddenColumns}}
  >
    <UiDomHide
      @classNames="w-100"
      @hide={{this._hideColumnSelectors}}
      @hasDynamicContent={{true}}
      @onHideUpdate={{this.updateHiddenColumns}}
    >
      <div class="ui-collapsible-table__scroll-wrapper oauto">
        <table class="w-100" data-test-ui-collapsible-table>
          {{yield
            (hash
              head=(component
                "ui-collapsible-table/head"
                columns=this.columns
                firstColumnWidth=this._firstColumnWidth
                isDraggable=this._hasScrollBar
                isFirstColumnFixed=this._isScrolled
                parentTable=this._element
                scrollLeft=this._scrollLeft
                onDrag=this._onHeadDrag
                triggerUpdate=this._triggerHeadUpdate
              )
              body=(component
                "ui-collapsible-table/rows"
                rows=this._formattedRows
                descriptionTranslationKey=this._descriptionTranslationKey
                firstColumnWidth=this._firstColumnWidth
                isFirstColumnFixed=this._isScrolled
                scrollLeft=this._scrollLeft
                parentElement=this._element
                onClose=this._onChildOpen
                onOpen=this._onChildOpen
              )
            )
          }}
        </table>
      </div>
    </UiDomHide>
  </div>
  */
  {
    "id": "+ywrbKKt",
    "block": "[[[11,0],[16,0,[29,[\"ui-collapsible-table relative bg-white font-sm br2-md ohidden \",[30,1],\" \",[30,2]]]],[4,[38,0],[[30,0,[\"onInsertElement\"]]],null],[4,[38,1],[[30,0,[\"onUpdateHiddenColumns\"]],[30,0,[\"hiddenColumns\"]]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@classNames\",\"@hide\",\"@hasDynamicContent\",\"@onHideUpdate\"],[\"w-100\",[30,0,[\"_hideColumnSelectors\"]],true,[30,0,[\"updateHiddenColumns\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"ui-collapsible-table__scroll-wrapper oauto\"],[12],[1,\"\\n      \"],[10,\"table\"],[14,0,\"w-100\"],[14,\"data-test-ui-collapsible-table\",\"\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,4],null,[[\"head\",\"body\"],[[50,\"ui-collapsible-table/head\",0,null,[[\"columns\",\"firstColumnWidth\",\"isDraggable\",\"isFirstColumnFixed\",\"parentTable\",\"scrollLeft\",\"onDrag\",\"triggerUpdate\"],[[30,0,[\"columns\"]],[30,0,[\"_firstColumnWidth\"]],[30,0,[\"_hasScrollBar\"]],[30,0,[\"_isScrolled\"]],[30,0,[\"_element\"]],[30,0,[\"_scrollLeft\"]],[30,0,[\"_onHeadDrag\"]],[30,0,[\"_triggerHeadUpdate\"]]]]],[50,\"ui-collapsible-table/rows\",0,null,[[\"rows\",\"descriptionTranslationKey\",\"firstColumnWidth\",\"isFirstColumnFixed\",\"scrollLeft\",\"parentElement\",\"onClose\",\"onOpen\"],[[30,0,[\"_formattedRows\"]],[30,0,[\"_descriptionTranslationKey\"]],[30,0,[\"_firstColumnWidth\"]],[30,0,[\"_isScrolled\"]],[30,0,[\"_scrollLeft\"]],[30,0,[\"_element\"]],[30,0,[\"_onChildOpen\"]],[30,0,[\"_onChildOpen\"]]]]]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&default\"],false,[\"did-insert\",\"did-update\",\"ui-dom-hide\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-collapsible-table.hbs",
    "isStrictMode": false
  });
  /**
   * A collapsible table where children can have children too.
   *
   *```js
    <UiCollapsibleTable
      @columns=myHeader
      @rows=myData
      @hiddenColumns=(array "column-1" "column-2")
      as |ta|
    >
      <ta.head />
  
      <ta.body as |row|>
        <row.valueColumn />
      </ta.body>
    </UiCollapsibleTable>
   ```
   *
   * @class ui-collapsible-table
   */
  let UiCollapsibleTable = _exports.default = (_class = class UiCollapsibleTable extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the columns array
       *
       * @argument columns
       * @type {Object[]}
       * @default undefined
       */
      _initializerDefineProperty(this, "columns", _descriptor, this);
      /**
       * the column key of the columns which should be hidden
       *
       * @argument hiddenColumns
       * @type {Object[]}
       * @default undefined
       */
      _initializerDefineProperty(this, "hiddenColumns", _descriptor2, this);
      /**
       * the objects containing the values of the rows
       *
       * @argument rows
       * @type {Object[]}
       * @default undefined
       */
      _initializerDefineProperty(this, "rows", _descriptor3, this);
      /**
       * the formatting options for the table
       *
       * ```js
       * {
       *   date: { format: 'dd.MM.yyyy', locale: de },
       *   number: { locale: 'de-DE', options: { maximumFractionDigits: 2 } },
       *   currency: { locale: 'de-DE', options: { currency: 'EUR' } },
       *   percentage: { locale: 'de-DE', options: { maximumFractionDigits: 2, minimumFractionDigits: 2 } },
       *   custom: (val) => val * 1000
       * }
       * ```
       *
       * @argument formats
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "formats", _descriptor4, this);
      /**
       * The translations key which will be used for `first-column` description when it has children.
       * It passes `count=subChildCount` to the translation,
       * where `subChildCount` is the count of the children.
       *
       * @property _descriptionTranslationKey
       * @type {String}
       * @default 'uiCollapsibleTable.collapsibleRowDescription'
       * @private
       */
      _defineProperty(this, "_descriptionTranslationKey", 'uiCollapsibleTable.collapsibleRowDescription');
      /**
       * the main HTML element
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor5, this);
      /**
       * the width of the first column
       *
       * @property _firstColumnWidth
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_firstColumnWidth", _descriptor6, this);
      /**
       * whether the table has a scrollbar
       *
       * @property _hasScrollBar
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_hasScrollBar", _descriptor7, this);
      /**
       * private copy of hidden columns that can be updated
       *
       * @property _hiddenColumns
       * @type {Object[]}
       * @default undefined
       * @private
       */
      _initializerDefineProperty(this, "_hiddenColumns", _descriptor8, this);
      /**
       * whether the table is horizontally scrolled
       *
       * @property _isScrolled
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isScrolled", _descriptor9, this);
      /**
       * the amount the table is scrolled to the left
       *
       * @property _scrollLeft
       * @type {Integer}
       * @default 0
       * @private
       */
      _initializerDefineProperty(this, "_scrollLeft", _descriptor10, this);
      /**
       * the scrollable container
       *
       * @property _scrollWrapper
       * @type {Element}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_scrollWrapper", _descriptor11, this);
      /**
       * boolean that updates head styles if toggled
       *
       * @property _triggerHeadUpdate
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_triggerHeadUpdate", _descriptor12, this);
    }
    /**
     * the formatted rows
     *
     * @computed _formattedRows
     * @type {Array}
     */
    get _formattedRows() {
      if (this.rows) {
        // the rows need to be cloned deep since they contain nested objects and arrays
        const rows = (0, _lodash.default)(this.rows);
        return (0, _tableUtil.formatCollapsibleTableRows)(rows, this.formats);
      }
      return [];
    }

    /**
     * Builds an array with the selectors for the ui-dom-hide component
     *
     * @computed _hideColumnSelectors
     * @type {Array}
     * @private
     */
    get _hideColumnSelectors() {
      var _this$columns, _this$columns2;
      if (!Array.isArray(this._hiddenColumns)) {
        return [];
      }
      let columnKeys = [];
      (_this$columns = this.columns) === null || _this$columns === void 0 || _this$columns.forEach(column => {
        if (column && typeof column === 'object') {
          columnKeys.push(column.key);
        }
      });
      if (columnKeys.length !== ((_this$columns2 = this.columns) === null || _this$columns2 === void 0 ? void 0 : _this$columns2.length)) {
        return [];
      }
      let selectors = [];
      this._hiddenColumns.forEach(key => {
        const nthChild = columnKeys.indexOf(key) + 1;
        if (nthChild <= 0) {
          return;
        }
        selectors.push(`.ui-collapsible-table__head-row th:nth-child(${nthChild})`);
        selectors.push(`.ui-collapsible-table__body-row td:nth-child(${nthChild})`);
      });
      return selectors;
    }

    /**
     * checks whether the table is scrolled to the left or not
     *
     * @function _onScroll
     */
    _onScroll() {
      var _this$_scrollWrapper;
      const scrollLeft = (_this$_scrollWrapper = this._scrollWrapper) === null || _this$_scrollWrapper === void 0 ? void 0 : _this$_scrollWrapper.scrollLeft;
      if (this._scrollLeft !== scrollLeft) {
        this._scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
        // update only if necessary
        if (!this._isScrolled && scrollLeft > 0) {
          this._isScrolled = true;
        } else if (this._isScrolled && scrollLeft === 0) {
          this._isScrolled = false;
        }
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.isDestroying) {
        return;
      }
      window.removeEventListener('resize', this._onResize, false);
      if (this._element) {
        this._element.removeEventListener('scroll', this._onScroll, false);
      }
    }
    _handleAfterRender() {
      if (this.isDestroying || !this._element) {
        return;
      }
      const scrollWrapper = this._element.querySelector('.ui-collapsible-table__scroll-wrapper');
      this._hasScrollBar = scrollWrapper.scrollWidth > scrollWrapper.offsetWidth;
      this._scrollWrapper = scrollWrapper;
      if (this._hasScrollBar) {
        scrollWrapper.addEventListener('scroll', this._onScroll, false);
      }
      const firstColumn = this._element.querySelector('.ui-collapsible-table__head-row th:nth-child(1)');
      if (firstColumn) this._firstColumnWidth = getComputedStyle(firstColumn).getPropertyValue('width');
    }
    async _onChildOpen() {
      if (Array.isArray(this._hiddenColumns)) {
        /* Re-set the same array as new array, to re-trigger ui-dom-hide */
        /* TODO: fix me, avoid jump */
        this._hiddenColumns = this._hiddenColumns.splice(0);
      }
      const firstColumn = this._element.querySelector('.ui-collapsible-table__head-row th:nth-child(1) .ui-collapsible-table__head-column__outer-wrapper');
      this._firstColumnWidth = 'auto';
      await (0, _domUtil.nextTick)();
      this._firstColumnWidth = getComputedStyle(firstColumn).getPropertyValue('width');
      this._triggerHeadUpdate = !this._triggerHeadUpdate;
    }

    /**
     * event triggered when the table head is dragged
     *
     * @function _onHeadDrag
     */
    _onHeadDrag(difference) {
      this._scrollWrapper.scrollLeft += difference;
      this._onScroll();
    }
    async updateHiddenColumns() {
      this._triggerHeadUpdate = !this._triggerHeadUpdate;
      if (!this._scrollWrapper) {
        return;
      }
      const hasScrollBar = this._scrollWrapper.scrollWidth > this._scrollWrapper.offsetWidth;
      if (!hasScrollBar) {
        this._scrollWrapper.scrollLeft = 0;
      }
      await (0, _domUtil.nextTick)();
      this._hasScrollBar = hasScrollBar;
      if (this._hasScrollBar) {
        this._scrollWrapper.addEventListener('scroll', this._onScroll, false);
      } else {
        this._scrollWrapper.removeEventListener('scroll', this._onScroll, false);
      }
    }
    onInsertElement(element) {
      this._element = element;
      this._hiddenColumns = [...(this.hiddenColumns || [])];
      this._onScroll = (0, _runloop.bind)(this, this._onScroll);
      this._onResize = (0, _runloop.bind)(this, this.updateHiddenColumns);
      window.addEventListener('resize', this._onResize, false);
      (0, _runloop.scheduleOnce)('afterRender', this, '_handleAfterRender');
    }
    onUpdateHiddenColumns() {
      this._hiddenColumns = [...(this.hiddenColumns || [])];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "columns", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hiddenColumns", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rows", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formats", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_firstColumnWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_hasScrollBar", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_hiddenColumns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_isScrolled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_scrollLeft", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_scrollWrapper", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_triggerHeadUpdate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onChildOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onChildOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onHeadDrag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onHeadDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateHiddenColumns", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHiddenColumns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdateHiddenColumns", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateHiddenColumns"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCollapsibleTable);
});