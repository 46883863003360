define("@additive-apps/configurator/components/aw-configurator/content-editor/sidebar-button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ae-sidebar__button ae-content-editor-sidebar__button flex items-center flex-shrink-0 br1 font-sm bg-secondary--10
    {{@classNames}}
     {{unless @isReadOnly "ae-content-editor-sidebar__button__is-draggable bg-secondary--40--hover"}}
     {{if this._isDragged "is-dragged" ""}}
     {{if this._isHidden "is-hidden" ""}}"
    role="button"
    draggable={{not @isReadOnly}}
    {{on "click" this.onClick}}
    {{did-insert this._onInsertElement}}
    {{will-destroy this._onWillDestroy}}
  >
    {{svg-jar this.icon width="20" height="20" class="flex-shrink-0"}}
    {{#unless this.hideText}}
      <span class="ph1 ellipsis">
        {{this.label}}
      </span>
    {{/unless}}
  </div>
  */
  {
    "id": "9RiTBpyI",
    "block": "[[[11,0],[16,0,[29,[\"ae-sidebar__button ae-content-editor-sidebar__button flex items-center flex-shrink-0 br1 font-sm bg-secondary--10\\n  \",[30,1],\"\\n   \",[52,[51,[30,2]],\"ae-content-editor-sidebar__button__is-draggable bg-secondary--40--hover\"],\"\\n   \",[52,[30,0,[\"_isDragged\"]],\"is-dragged\",\"\"],\"\\n   \",[52,[30,0,[\"_isHidden\"]],\"is-hidden\",\"\"]]]],[24,\"role\",\"button\"],[16,\"draggable\",[28,[37,2],[[30,2]],null]],[4,[38,3],[\"click\",[30,0,[\"onClick\"]]],null],[4,[38,4],[[30,0,[\"_onInsertElement\"]]],null],[4,[38,5],[[30,0,[\"_onWillDestroy\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,6],[[30,0,[\"icon\"]]],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",\"flex-shrink-0\"]]]],[1,\"\\n\"],[41,[51,[30,0,[\"hideText\"]]],[[[1,\"    \"],[10,1],[14,0,\"ph1 ellipsis\"],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@classNames\",\"@isReadOnly\"],false,[\"unless\",\"if\",\"not\",\"on\",\"did-insert\",\"will-destroy\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/content-editor/sidebar-button.hbs",
    "isStrictMode": false
  });
  let AwConfiguratorContentEditorSidebarButtonComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwConfiguratorContentEditorSidebarButtonComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * the button label
       *
       * @argument label
       * @type {String}
       */
      _initializerDefineProperty(this, "label", _descriptor2, this);
      /**
       * the button icon
       *
       * @argument icon
       * @type {String}
       */
      _initializerDefineProperty(this, "icon", _descriptor3, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      /**
       * the main HTML element of the component
       *
       * @property _element
       * @type HTMLElement
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor5, this);
      /**
       * whether the button is dragged
       *
       * @property _isDragged
       * @type Boolean
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDragged", _descriptor6, this);
      /**
       * whether the button is hidden
       *
       * @property _isHidden
       * @type Boolean
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isHidden", _descriptor7, this);
      _initializerDefineProperty(this, "onDragStart", _descriptor8, this);
      _initializerDefineProperty(this, "onDragEnd", _descriptor9, this);
    }
    _onDragStart(event) {
      event.dataTransfer.effectAllowed = 'move';
      this._isDragged = true;
      this.onDragStart(this.contentBlock);
      (0, _runloop.next)(this, () => {
        if (!this.isDestroying) {
          this._isHidden = true;
        }
      });
    }
    _onDragEnd() {
      this._isDragged = false;
      this.onDragEnd();
      (0, _runloop.next)(this, () => {
        if (!this.isDestroying) {
          this._isHidden = false;
        }
      });
    }
    _onInsertElement(element) {
      if (this.isReadOnly) {
        return;
      }
      this._onDragStart = (0, _runloop.bind)(this, this._onDragStart);
      this._onDragEnd = (0, _runloop.bind)(this, this._onDragEnd);
      if (element) {
        this._element = element;
        this._element.addEventListener('dragstart', this._onDragStart, {
          passive: true
        });
        this._element.addEventListener('dragend', this._onDragEnd, {
          passive: true
        });
      }
    }
    _onWillDestroy() {
      if (this.isReadOnly) {
        return;
      }
      if (this._element) {
        this._element.removeEventListener('dragstart', this._onDragStart, {
          passive: true
        });
        this._element.removeEventListener('dragend', this._onDragEnd, {
          passive: true
        });
      }
    }
    onClick() {
      //TODO
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isDragged", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_isHidden", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onDragStart", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onDragEnd", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorContentEditorSidebarButtonComponent);
});