define("@additive-apps/ui/components/ui-loading", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-loading dib
      {{if this._isDone 'ui-loading--done'}}
      {{this._type}}
      {{@class}}
      {{@classNames}}"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={{this.width}}
      height={{this.height}}
      class="ui-spinner db"
    >
      <circle
        transform-origin="center"
        class="path"
        fill="none"
        stroke-width="1.5"
        stroke-linecap="round"
        cx="12"
        cy="12"
        r="11"
      ></circle>
      {{#if this.state}}
        {{svg-jar
          (concat "feedback-" this.state)
          class=(concat "inner-state " this.state)
          opacity="0"
          transform="scale(0.2)"
          transform-origin="center center"
        }}
      {{/if}}
    </svg>
  </div>
  */
  {
    "id": "zqV4O4Xq",
    "block": "[[[10,0],[15,0,[29,[\"ui-loading dib\\n    \",[52,[30,0,[\"_isDone\"]],\"ui-loading--done\"],\"\\n    \",[30,0,[\"_type\"]],\"\\n    \",[30,1],\"\\n    \",[30,2]]]],[12],[1,\"\\n  \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[15,\"width\",[30,0,[\"width\"]]],[15,\"height\",[30,0,[\"height\"]]],[14,0,\"ui-spinner db\"],[12],[1,\"\\n    \"],[10,\"circle\"],[14,\"transform-origin\",\"center\"],[14,0,\"path\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"1.5\"],[14,\"stroke-linecap\",\"round\"],[14,\"cx\",\"12\"],[14,\"cy\",\"12\"],[14,\"r\",\"11\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"state\"]],[[[1,\"      \"],[1,[28,[35,1],[[28,[37,2],[\"feedback-\",[30,0,[\"state\"]]],null]],[[\"class\",\"opacity\",\"transform\",\"transform-origin\"],[[28,[37,2],[\"inner-state \",[30,0,[\"state\"]]],null],\"0\",\"scale(0.2)\",\"center center\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\"],false,[\"if\",\"svg-jar\",\"concat\"]]",
    "moduleName": "@additive-apps/ui/components/ui-loading.hbs",
    "isStrictMode": false
  });
  //eslint-disable-next-line ember/no-classic-components

  /*
    TODO: Export to constants as states might be reused in other componente
  */
  const acceptedStates = ['success', 'error', 'warning'];

  /**
   * ```hbs
   * {{ui-loading}}
   *
   * {{ui-loading state=state}}
   * ```
   *
   * @class ui-loading
   */
  let UiLoading = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _class = class UiLoading extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The state of the related action, transition etc.
       * Updating the state triggers a change on the private type-cp.
       *
       * Can be one of success, error, warning and null.
       *
       * @argument state
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "state", _descriptor, this);
      /**
       * The width of the icon
       *
       * @argument width
       * @type {Number}
       * @default 24
       */
      _initializerDefineProperty(this, "width", _descriptor2, this);
      /**
       * The height of the icon
       *
       * @argument height
       * @type {Number}
       * @default 24
       */
      _initializerDefineProperty(this, "height", _descriptor3, this);
      /**
       * Whether the loading is finished and a transition to an other
       * state is going to happen
       *
       * CSS Classes:
       *  - `ui-loading--done`
       *
       * @property isDone
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isDone", _descriptor4, this);
    }
    get isAcceptedState() {
      return this.state && acceptedStates.indexOf(this.state) > -1;
    }
    get _type() {
      this._showInnerState();
      return this.isAcceptedState ? this.state : null;
    }

    /**
     * Utility that wraps done-trigger in a raf to avoid layout-trashing.
     * Changes the components state from loading to <state>
     *
     * @event _showInnerState
     */
    async _showInnerState() {
      await (0, _domUtil.nextTick)();
      !this.isDestroying && (this._isDone = this.isAcceptedState);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 24;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 24;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_isDone", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiLoading);
});