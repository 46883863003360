define("@additive-apps/ui/components/ui-rating", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-rating", "@ember/object", "@ember/runloop"], function (_exports, _component, _uiRating, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * @class ui-rating
   * @public
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiRating.default,
    classNames: ['ui-rating', 'relative', 'br-100 ohidden'],
    classNameBindings: ['_isActive:blue:grey', 'isDisabled::cpointer'],
    isDisabled: false,
    /**
     *
     * @type {Boolean}
     * @computed _isActive
     * @private
     */
    _isActive: (0, _object.computed)('rating', 'currentRating', {
      get() {
        return parseInt(this.rating) <= parseInt(this.currentRating);
      }
    }),
    /**
     * onClick callback
     * @function onClick
     */
    onClick() {},
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      if (!this.isDisabled) {
        this.clickListener = (0, _runloop.bind)(this, this.clickListener);
        this.element.addEventListener('click', this.clickListener, false);
      }
    },
    /**
     * Triggers the onClick-callback with the new rating.
     * In case the user clicked on an active rating, and it happens
     * to be the current-ratiung value, we reset the rating to 0.
     *
     * @function clickListener
     * @return {Function} onClick
     */
    clickListener() {
      const newRating = this.rating === this.currentRating ? 0 : this.rating;
      return this.onClick(newRating);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      this.clickListener && this.element.removeEventListener('click', this.clickListener, false);
    }
  });
});