define("@additive-apps/ui/services/ui-paths", ["exports", "@ember/application", "@ember/object", "@ember/service"], function (_exports, _application, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * This constant holds differences in route structure between ui and api.
   * Refactor api or ui to eliminate this mapping.
   * Key is ui route, value is api route.
   */
  const UI_API_ROUTE_DIFFERENCES = {
    instances: 'organizations'
  };
  const SLASH_AT_START_REGEX = /^\//;
  const SLASH_AT_END_REGEX = /\/$/;

  /**
   * Combines a url with a given baseUrl and addtional url-parts
   *
   * @param  {String} baseUrl The baseUrl to used
   * @param  {[String]} args An array with strings, which contains the additional url-parts
   * @function _buildUrl
   * @return {String} The builded URL
   */
  const _buildUrl = (baseUrl, args) => {
    const urlParts = args && args.slice(0);
    let url = baseUrl && baseUrl.replace(SLASH_AT_END_REGEX, '');
    urlParts && urlParts.forEach(part => {
      if (part) {
        // Remove slashes: /my-route/ --> my-route
        part = part.replace(SLASH_AT_START_REGEX, '').replace(SLASH_AT_END_REGEX, '');

        // Temporary: use the different route-name if given
        if (UI_API_ROUTE_DIFFERENCES[part]) {
          part = UI_API_ROUTE_DIFFERENCES[part];
        }
        url = _joinToUrl(url, part);
      }
    });
    return url;
  };

  // Joins all arguments together with a slash between
  const _joinToUrl = function _joinToUrl() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return [...args].join('/');
  };

  /**
   * Creates the object which gets returned for the functions:
   *
   * @param {String} apiBaseHost The apiBaseHost
   * @param {String} apiRootKey  The api route root key which will be added to any url after baseHost
   * @param {Array}  urlParts    An array that holds all the additional url-parts which gets added to the url
   *
   * @return {Object}:
   *  @type {String} apiBaseHost
   *  @type {String} apiRootKey
   *  @type {Function} api
   *    @return {Object}:
   *      @type {String}   url   The builded api url
   *      @type {Function} join  Function to add additional url-paths, based on the builded api url
   * @function _buildReturnObject
   */
  const _buildReturnObject = (apiBaseHost, apiRootKey, urlParts) => {
    return {
      apiBaseHost,
      apiRootKey,
      api() {
        const url = _buildUrl(apiBaseHost, [apiRootKey, ...urlParts]);
        return {
          url,
          join(/*arguments*/
          ) {
            return _joinToUrl(url, ...arguments);
          }
        };
      }
    };
  };

  /**
   * This service computes different paths out of the current route or for a given route.
   *
   * With service.paths.api().url you will get the current routes api url:
   *    - ui-route instances/:id/ will return ${apiBaseHost}/api/instances/:id
   *    - ui-route instances/:id/settings will return ${apiBaseHost}/api/instances/:id/settings
   *
   * With service.pathsByRouteName function is the same possibile as the service.paths property,
   * with the difference it will use the passed route instead of the current route.
   * As this is a function it is not cachable where the service.paths is.
   *
   * The api()-function which gets returned has a join-function where you can add additional routes to the computed api-url:
   *    - with ...api().join('partners', 'additive')
   *      it will return ${computedUrl}/partners/additive
   *      The join function adds all arguments as additional route
   *
   * @class uiPaths
   * @public
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    router: (0, _service.inject)(),
    _apiBaseHost: '',
    _apiRootKey: 'api',
    init() {
      this._super(...arguments);
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      (0, _object.set)(this, '_apiBaseHost', ENV.APP.apiBaseHost);
    },
    /**
     * This contains the paths for the current route.
     *
     * @computed paths
     * @return {Object} The builded object by function (see build-function docs for detail)
     */
    paths: (0, _object.computed)('_apiBaseHost', '_apiRootKey', 'router.currentURL', {
      get() {
        var _this$router;
        const apiBaseHost = this._apiBaseHost;
        // Remove and ignore query-params
        const currentURL = (_this$router = this.router) === null || _this$router === void 0 || (_this$router = _this$router.currentURL) === null || _this$router === void 0 ? void 0 : _this$router.split('?')[0];
        const currentURLParts = currentURL.split('/');
        const apiRootKey = this._apiRootKey;
        return _buildReturnObject(apiBaseHost, apiRootKey, currentURLParts);
      }
    }),
    /**
     * This contains the paths for a specific route which gets passed as param.
     *
     * @param {String} routeName The route string the used for building url
     * @return {Object} The builded object by function (see build-function docs for detail)
     * @function pathsByRouteName
     */
    pathsByRouteName(/* routeName, ...models, options */
    ) {
      const apiBaseHost = this._apiBaseHost;
      const apiRootKey = this._apiRootKey;
      const currentURL = this.router.urlFor(...arguments);
      const currentURLParts = currentURL.split('/');
      return _buildReturnObject(apiBaseHost, apiRootKey, currentURLParts);
    },
    /**
     * Setter function for the _apiRootKey
     *
     * @param {String} key the api root key
     * @default 'api'
     * @function setApiRootKey
     */
    setApiRootKey() {
      let key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'api';
      (0, _object.set)(this, '_apiRootKey', key);
    }
  });
});