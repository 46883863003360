define("@additive-apps/ui/components/ui-tabs-header", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-tabs-header", "@ember/object", "@ember/service"], function (_exports, _component, _uiTabsHeader, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * The header displaying the tabs in the routable-tab-view.
   *
   * ```hbs
   * {{#ui-tabs-header |header|}}
   *   {{header.tab tabName routePath icon="home"}}
   *   {{header.tab tabName routePath icon="home"}}
   * {{/ui-routable-tabs}}
   * ```
   *
   * @class ui-tabs-header
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _uiTabsHeader.default,
    classNames: ['ui-routable-tabs__header'],
    /**
     * Whether the tabs content displays routes or is used for inline tabs
     *
     * @property isRoutable
     * @type {Boolean}
     * @default true
     */
    isRoutable: true,
    /**
     * the active tab object
     *
     * @property activeTab
     * @type {Any}
     * @default undefined
     */
    activeTab: undefined,
    /**
     * Depending if routeable or not, the cp returns the current routes name or the activeTab propery
     * .
     * @computed _activeTab
     * @return {String|Object} active tab
     */
    _activeTab: (0, _object.computed)('router.currentRouteName', 'activeTab', 'isRoutable', {
      get() {
        return this.isRoutable ? this.router.currentRouteName : this.activeTab;
      }
    }),
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * scrolls the tabs header to center the current tab and sets it as
       * the active tab
       *
       * @param {Element} tabItem
       * @param {Any} tabValue
       * @function selectItem
       */
      selectItem(tabDomElement, tabValue) {
        const element = this.element;
        if (document) {
          const scrollContainer = element.querySelector('.ui-routable-tabs__header-scroll-container');
          scrollContainer && tabDomElement ? scrollContainer.scrollLeft = tabDomElement.offsetLeft - scrollContainer.offsetWidth / 2 + tabDomElement.offsetWidth / 2 : null;
        }
        (0, _object.set)(this, 'activeTab', tabValue);
      }
    }
  });
});