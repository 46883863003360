define("@additive-apps/media/adapters/medium", ["exports", "@additive-apps/auth/adapters/application", "@ember/application", "@ember/service", "@glimmer/tracking", "ember-inflector"], function (_exports, _application, _application2, _service, _tracking, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FolderAdapter = _exports.default = (_class = class FolderAdapter extends _application.default {
    get namespace() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }
    get headers() {
      const headers = {
        Accept: 'application/vnd.additive+json; version=1',
        'Accept-Language': 'de',
        'Content-Type': 'application/json'
      };
      return Object.assign({}, super.headers, headers);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "host", _descriptor2, this);
      const ENV = (0, _application2.getOwner)(this).resolveRegistration('config:environment');
      this.host = ENV.APP.mediaApi;
    }
    urlForMultiple(_, snapshot) {
      var _snapshot$adapterOpti;
      let url = this.buildURL();
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.folder_id) {
        var _snapshot$record, _snapshot$adapterOpti2;
        const folderId = snapshot.adapterOptions.folder_id;
        const folderType = (_snapshot$record = snapshot.record) !== null && _snapshot$record !== void 0 && _snapshot$record.shared || (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.shared ? 'shared-folder' : 'folder';
        url = `${url}/${(0, _emberInflector.pluralize)(folderType)}/${folderId}`;
      }
      url = `${url}/media`;
      return url;
    }
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    }
    urlForFindAll(modelName, snapshot) {
      return this.urlForMultiple(modelName, snapshot);
    }
    urlForCreateRecord(modelName, snapshot) {
      return this.urlForMultiple(modelName, snapshot);
    }
    urlForQuery(query) {
      const host = this.host;
      const namespace = this.namespace;
      const shared = query.shared;
      delete query.shared;
      return `${host}/${namespace}/${shared ? 'shared-media-filter' : 'media-filter'}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "host", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _class);
});