define("@additive-apps/ui/templates/components/ui-progress-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zu6gODrD",
    "block": "[[[10,0],[14,0,\"ui-progress-bar__progress-wrapper w-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-progress-bar__progress primary\"],[15,5,[30,0,[\"_progressWidthStyles\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"font-xs secondary w-100 mt1 flex justify-between\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"blue\"],[12],[1,\"\\n    \"],[1,[30,0,[\"_description\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[1,[30,0,[\"_remaining\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-progress-bar.hbs",
    "isStrictMode": false
  });
});