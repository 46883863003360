define("@additive-apps/media/states/ui-carousel", ["exports", "@ember/object", "ember-concurrency", "@ember/runloop", "@ember/array"], function (_exports, _object, _emberConcurrency, _runloop, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  State of ui-carousel component
   *
   *  @class STATE_OBJECT
   */
  const STATE_OBJECT = {
    /**
     * The current active index
     * @property activeIndex
     * @type {Number}
     */
    activeIndex: null,
    /**
     * The index where it should start
     * @property startIndex
     * @type {Number}
     */
    startIndex: 1,
    /**
     * The count of registered containers
     * @property containerCount
     * @type {Number}
     */
    containerCount: 0,
    /**
     * Array which holds the state of all registered containers
     * @property containerCount
     * @type [Object]
     */
    containers: null,
    /**
     * Whether the containers should be rendered lazily or not.
     * With true it will pre-render the next and previous slide. (TODO previous-slide pre-rendering not working yet)
     * @property useLazyRendering
     * @type {Boolean}
     */
    useLazyRendering: false,
    /**
     * Function to register a new container.
     * @function registerContainer
     * @param {Object} containerState Container state
     * @return {Number} The unique registered-indeState of ui-carousel componentx for the given state
     */
    registerContainer(containerState) {
      var _this$containers;
      const containers = this.containers;
      if (containers === null) {
        (0, _object.set)(this, 'containers', (0, _array.A)());
      }
      const newCount = ((_this$containers = this.containers) === null || _this$containers === void 0 ? void 0 : _this$containers.length) + 1;
      this.containers.pushObject(containerState);
      this._setContainerCountTask.perform(this, newCount);
      return newCount;
    },
    /**
     * Function to unregister a registered container.
     * @function unregisterContainer
     * @param {Object} Container state
     */
    unregisterContainer(containerState) {
      var _this$containers2;
      this.containers.removeObject(containerState);
      this._setContainerCountTask.perform(this, (_this$containers2 = this.containers) === null || _this$containers2 === void 0 ? void 0 : _this$containers2.length);
    },
    /**
     * Function to update its active index.
     * TODO: smooth infinte-carousel
     * @function updateActiveIndex
     * @param {Number} newIndex The new index to update
     */
    updateActiveIndex(newIndex) {
      const count = this.containerCount;
      const currentIndex = this.activeIndex || this.startIndex - 1;

      // When current index is not in edge positions we do not have to do anything special
      if (currentIndex > 1 && currentIndex < count - 1) {
        (0, _object.set)(this, 'activeIndex', newIndex);
        return;
      }
      switch (newIndex) {
        case count + 1:
          newIndex = 1;
          break;
        case 0:
          newIndex = count;
          break;
      }
      (0, _object.set)(this, 'activeIndex', newIndex);
    },
    /**
     * Function to navigate to next slide.
     * @function next
     */
    next() {
      this.updateActiveIndex(this.activeIndex + 1);
    },
    /**
     * Function to navigate to previous slide.
     * @function previous
     */
    previous() {
      this.updateActiveIndex(this.activeIndex - 1);
    }
  };

  // Task to prevent multiple change in a single render,
  // as it happens that in one single render multiple container gets registered.
  const CONTAINER_COUNT_TASK = (0, _emberConcurrency.task)(function* (self, count) {
    try {
      yield (0, _runloop.next)(self, () => {
        (0, _object.set)(self, 'containerCount', count);
      });
    } catch (error) {
      throw new ErrorEvent('[ui-carousel] COTNAINER_COUNT_TASK error', error);
    }
  }).restartable();
  (0, _object.defineProperty)(STATE_OBJECT, '_setContainerCountTask', CONTAINER_COUNT_TASK);
  var _default = _exports.default = STATE_OBJECT;
});