define("@additive-apps/ui/services/ui-pusher", ["exports", "@ember/service", "@ember/application", "@ember/object", "@ember/debug", "rsvp"], function (_exports, _service, _application, _object, _debug, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PUSHER_CLUSTER = 'eu';

  /**
   * Service that handles pusher messages globally
   *
   * @class ui-pusher
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    session: (0, _service.inject)(),
    /**
     * the internal pusher object
     *
     * @property _pusher
     * @type {Pusher}
     * @default null
     * @private
     */
    _pusher: null,
    /**
     * the key for the pusher connection
     *
     * @property _pusherKey
     * @type {String}
     * @default null
     * @private
     */
    _pusherKey: null,
    /**
     * the account api host string
     *
     * @property _accountApiHost
     * @type {String}
     * @default null
     * @private
     */
    _accountApiHost: null,
    init() {
      this._super(...arguments);

      // set pusher key and api host
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      (0, _object.setProperties)(this, {
        _pusherKey: ENV.APP.pusherKey,
        _accountApiHost: ENV.APP.accountApiHost
      });
    },
    /**
     * sets up the pusher service by authenticating the connection with the API
     *
     * @function setup
     */
    setup() {
      (false && !(!!this._pusherKey) && (0, _debug.assert)('Pusher key needs to be defined in ENV', !!this._pusherKey));
      if (this._pusher) {
        return (0, _rsvp.resolve)();
      }
      return emberAutoImportDynamic("pusher-js").then(model => model.default).then(Pusher => {
        const {
          token_type,
          access_token
        } = this.session.data.authenticated;
        const pusher = new Pusher(this._pusherKey, {
          cluster: PUSHER_CLUSTER,
          authEndpoint: `${this._accountApiHost}/broadcasting/auth`,
          auth: {
            headers: {
              authorization: `${token_type} ${access_token}`
            }
          }
        });
        (0, _object.set)(this, '_pusher', pusher);
      });
    },
    /**
     * Binds an event on a channel to a callback function. If the channel is bound to the `global` event
     * it will execute the callback on every event of this channel.
     *
     * @function bindEvent
     * @param {String} channelName
     * @param {String} event, either the name of the triggered event or `global` for all events
     * @param {Function} callback, receives `event (String)` (only if global) and `data (Object)` as params
     */
    bindEvent(channelName, event, callback) {
      if (channelName) {
        const channel = this._pusher.channel(channelName) || this._pusher.subscribe(channelName);
        if (event === 'global') {
          channel.bind_global(callback);
        } else {
          channel.bind(event, callback);
        }
      }
    },
    /**
     * Unbinds an event from a channel
     *
     * @function unbindEvent
     * @param {String} channelName
     * @param {String} event, The name of the event that the binding is to be removed from
     * @param {Function} callback, A function event handler used when binding to the event.
     *                            If no callback function is supplied, all handlers on the event will
     *                            be removed.
     */
    unbindEvent(channelName) {
      let event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      if (channelName) {
        const channel = this._pusher.channel(channelName);
        if (channel) {
          if (event === 'global') {
            channel.unbind_global();
          } else {
            channel.unbind(event, callback);
          }
        }
      }
    },
    willDestroy() {
      if (this._pusher) {
        this._pusher.disconnect();
      }
    }
  });
});