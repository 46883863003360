define("@additive-apps/ui/components/ui-additive-basic-dropdown", ["exports", "@ember/component", "@ember/template-factory", "ember-basic-dropdown/components/basic-dropdown"], function (_exports, _component, _templateFactory, _basicDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#with
    (hash
      uniqueId=this.publicAPI.uniqueId
      isOpen=this.publicAPI.isOpen
      disabled=this.publicAPI.disabled
      actions=this.publicAPI.actions
      Trigger=(component
        (or @triggerComponent "basic-dropdown-trigger")
        dropdown=(readonly this.publicAPI)
        hPosition=(readonly this.hPosition)
        renderInPlace=(readonly this.renderInPlace)
        vPosition=(readonly this.vPosition)
        stopPropagation=@stopBubbling
        defaultClass="ui-menu"
      )
      Content=(component
        (or @contentComponent "ui-menu-content")
        dropdown=(readonly this.publicAPI)
        hPosition=(readonly this.hPosition)
        renderInPlace=(readonly this.renderInPlace)
        preventScroll=(readonly @preventScroll)
        rootEventType=(or @rootEventType "click")
        vPosition=(readonly this.vPosition)
        destination=(readonly this.destination)
        top=(readonly this.top)
        left=(readonly this.left)
        right=(readonly this.right)
        width=(readonly this.width)
        height=(readonly this.height)
        otherStyles=(readonly this.otherStyles)
      )
    ) as |api|
  }}
    {{#if this.renderInPlace}}
      <div class="ember-basic-dropdown" ...attributes>
        {{yield api}}
      </div>
    {{else}}
      {{yield api}}
    {{/if}}
  {{/with}}
  */
  {
    "id": "UlyWIGqh",
    "block": "[[[44,[[28,[37,1],null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"Trigger\",\"Content\"],[[30,0,[\"publicAPI\",\"uniqueId\"]],[30,0,[\"publicAPI\",\"isOpen\"]],[30,0,[\"publicAPI\",\"disabled\"]],[30,0,[\"publicAPI\",\"actions\"]],[50,[28,[37,3],[[30,1],\"basic-dropdown-trigger\"],null],0,null,[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"vPosition\",\"stopPropagation\",\"defaultClass\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null],[28,[37,4],[[30,0,[\"hPosition\"]]],null],[28,[37,4],[[30,0,[\"renderInPlace\"]]],null],[28,[37,4],[[30,0,[\"vPosition\"]]],null],[30,2],\"ui-menu\"]]],[50,[28,[37,3],[[30,3],\"ui-menu-content\"],null],0,null,[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[28,[37,4],[[30,0,[\"publicAPI\"]]],null],[28,[37,4],[[30,0,[\"hPosition\"]]],null],[28,[37,4],[[30,0,[\"renderInPlace\"]]],null],[28,[37,4],[[30,4]],null],[28,[37,3],[[30,5],\"click\"],null],[28,[37,4],[[30,0,[\"vPosition\"]]],null],[28,[37,4],[[30,0,[\"destination\"]]],null],[28,[37,4],[[30,0,[\"top\"]]],null],[28,[37,4],[[30,0,[\"left\"]]],null],[28,[37,4],[[30,0,[\"right\"]]],null],[28,[37,4],[[30,0,[\"width\"]]],null],[28,[37,4],[[30,0,[\"height\"]]],null],[28,[37,4],[[30,0,[\"otherStyles\"]]],null]]]]]]]],[[[41,[30,6],[[[41,[30,0,[\"renderInPlace\"]],[[[1,\"    \"],[11,0],[24,0,\"ember-basic-dropdown\"],[17,7],[12],[1,\"\\n      \"],[18,8,[[30,6]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,8,[[30,6]]],[1,\"\\n\"]],[]]]],[]],null]],[6]]]],[\"@triggerComponent\",\"@stopBubbling\",\"@contentComponent\",\"@preventScroll\",\"@rootEventType\",\"api\",\"&attrs\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"or\",\"readonly\",\"if\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-additive-basic-dropdown.hbs",
    "isStrictMode": false
  });
  /**
   * ember-basic-dropdown wrapper with some additional additive-functionality.
   *
   * Maybe can be removed and added to ui-menu component directly depending on this issue:
   * https://github.com/cibernox/ember-basic-dropdown/issues/545
   *
   * @extends EmberBasicDropdown
   * @class ui-additive-basic-dropdown
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _basicDropdown.default {});
});