define("@additive-apps/ui/classes/ui-query-param", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * This object indicates one single query-param.
   * It manages browser history update if wanted and allows registering listener for
   * value change.
   *
   * @class ui-query-param
   */
  class UiQueryParam extends _object.default {
    constructor() {
      super(...arguments);
      /**
       * The key of the query-param (`page`, `per_page` etc.)
       *
       * @property key
       * @type {String}
       */
      _defineProperty(this, "key", null);
      /**
       * The value
       *
       * @property _value
       * @type {any}
       * @private
       */
      _defineProperty(this, "_value", undefined);
      /**
       * All registered callbacks for this query-param.
       * Child-key is a unique identifier.
       *
       * @property _onChangeCallbacks
       * @type {Object}
       * @private
       */
      _defineProperty(this, "_onChangeCallbacks", null);
      this._onChangeCallbacks = {};
    }

    /**
     * Get the value
     *
     * @function getValue
     */
    getValue() {
      return this._value;
    }

    /**
     * Update the value and
     * control the way it should behave (notify value change? browser-history entry?)
     *
     * @function setValue
     * @param {any} value The value
     * @param {Boolean} notify  Whether it should notify registered listener that value did change
     * @param {Boolean} history Whether it should push into browsers history
     */
    setValue(value) {
      let notify = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      let history = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      const hasChanged = JSON.stringify(this._value) !== JSON.stringify(value);
      if (!hasChanged) return;
      this._value = value;
      history && this._pushHistoryState();
      notify && this._notifyChange();
    }

    /**
     * Build/Encode value
     *
     * @function toString
     */
    toString() {
      const _URLSearchParams = new URLSearchParams(window.location.search);
      if ((0, _utils.isEmpty)(this._value)) {
        _URLSearchParams.delete(this.key);
      } else {
        _URLSearchParams.set(this.key, this._value);
      }
      return _URLSearchParams.toString();
    }

    /**
     * Add change listener
     *
     * @function addOnChangeCallback
     * @param {String} uniqueIdentifier A unique identifier
     * @param {Function} callback The function which gets executed on change
     */
    addOnChangeCallback(uniqueIdentifier, callback) {
      this._onChangeCallbacks[uniqueIdentifier] = callback;
    }

    /**
     * Remove change listener
     *
     * @function removeOnChangeCallback
     * @param {String} uniqueIdentifier The unique identifier
     */
    removeOnChangeCallback(uniqueIdentifier) {
      delete this._onChangeCallbacks[uniqueIdentifier];
    }

    /**
     * Push current query-param state into browsers history
     *
     * @function _pushHistoryState
     * @private
     */
    _pushHistoryState() {
      const {
        origin,
        pathname
      } = window.location;
      const paramsString = this.toString();
      const params = (0, _utils.isEmpty)(paramsString) ? '' : `?${paramsString}`;
      const newUrl = origin + pathname + params;
      window.history.pushState({}, document.title, newUrl);
    }

    /**
     * Notify registered members that value has changed
     *
     * @function _notifyChange
     * @private
     */
    _notifyChange() {
      Object.keys(this._onChangeCallbacks).forEach(memberKey => {
        this._onChangeCallbacks[memberKey](this.key, this._value);
      });
    }
  }
  _exports.default = UiQueryParam;
});