define("@additive-apps/ui/helpers/ui-array-includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiArrayIncludes = uiArrayIncludes;
  function uiArrayIncludes(_ref /*, hash*/) {
    let [item, selection, key = 'id', isInverted = false] = _ref;
    const arrayIncludes = selection && selection.find(selectedItem => selectedItem === item[key]);
    return isInverted ? !arrayIncludes : arrayIncludes;
  }
  var _default = _exports.default = (0, _helper.helper)(uiArrayIncludes);
});