define("@additive-apps/ui/components/ui-filterable", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield
    (hash
      data=this.data
      meta=this.meta
      isOpen=this.isOpen
      hasActiveFilter=this.hasActiveFilter
      isEmpty=this.isEmpty
      hasNoMatches=this.hasNoMatches
      isError=this.isError
      isLoading=this.uiFilter._isLoading
      filterable=this._publicAPI.filterable
      searchable=this._publicAPI.searchable
      paginated=this._publicAPI.paginated
      actions=this._publicAPI.actions
      filter=this._filterObject
    )
  }}
  */
  {
    "id": "YhN9HwPb",
    "block": "[[[18,1,[[28,[37,1],null,[[\"data\",\"meta\",\"isOpen\",\"hasActiveFilter\",\"isEmpty\",\"hasNoMatches\",\"isError\",\"isLoading\",\"filterable\",\"searchable\",\"paginated\",\"actions\",\"filter\"],[[30,0,[\"data\"]],[30,0,[\"meta\"]],[30,0,[\"isOpen\"]],[30,0,[\"hasActiveFilter\"]],[30,0,[\"isEmpty\"]],[30,0,[\"hasNoMatches\"]],[30,0,[\"isError\"]],[30,0,[\"uiFilter\",\"_isLoading\"]],[30,0,[\"_publicAPI\",\"filterable\"]],[30,0,[\"_publicAPI\",\"searchable\"]],[30,0,[\"_publicAPI\",\"paginated\"]],[30,0,[\"_publicAPI\",\"actions\"]],[30,0,[\"_filterObject\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "@additive-apps/ui/components/ui-filterable.hbs",
    "isStrictMode": false
  });
  /**
   * HOC responsible for CRUD operations inside a specific route.
   * The component itself doesn't do much, except triggering the first api-request for
   * a given model. For the remaining properties and functions it serves as public-proxy
   * which interacts with the filter service and exposes those informations where it gets used.
   *
   * @module Filter
   * @class ui-filterable
   */
  let UiFilterableComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiFilterableComponent extends _component2.default {
    get filterable() {
      var _this$_filterObject;
      return (_this$_filterObject = this._filterObject) === null || _this$_filterObject === void 0 ? void 0 : _this$_filterObject.filterable;
    }
    get searchable() {
      var _this$_filterObject2;
      return (_this$_filterObject2 = this._filterObject) === null || _this$_filterObject2 === void 0 ? void 0 : _this$_filterObject2.searchable;
    }
    get data() {
      var _this$_filterObject3;
      return (_this$_filterObject3 = this._filterObject) === null || _this$_filterObject3 === void 0 ? void 0 : _this$_filterObject3.data;
    }
    get meta() {
      var _this$_filterObject4;
      return (_this$_filterObject4 = this._filterObject) === null || _this$_filterObject4 === void 0 ? void 0 : _this$_filterObject4.meta;
    }

    // state aliases
    /**
     * True if the navbar is in `filter-mode`
     *
     * @computed isOpen
     * @return {Boolean}
     */
    get isOpen() {
      var _this$_filterObject5;
      return (_this$_filterObject5 = this._filterObject) === null || _this$_filterObject5 === void 0 ? void 0 : _this$_filterObject5.isOpen;
    }

    /**
     * True if there are filter or search options on the filter-object.
     * Pagination related qps will be ignored and do not count as such.
     *
     * @computed hasActiveFilter
     * @return {Boolean}
     */
    get hasActiveFilter() {
      var _this$_filterObject6;
      return (_this$_filterObject6 = this._filterObject) === null || _this$_filterObject6 === void 0 ? void 0 : _this$_filterObject6.hasActiveFilter;
    }

    /**
     * Whether the filter-objects data array is emtpy or not
     *
     * @computed isEmpty
     * @return {Boolean}
     */
    get isEmpty() {
      var _this$_filterObject7;
      return (_this$_filterObject7 = this._filterObject) === null || _this$_filterObject7 === void 0 ? void 0 : _this$_filterObject7.isEmpty;
    }

    /**
     * True if the filter object has active filters and the data array
     * is empty.
     *
     * @computed hasNoMatches
     * @return {Boolean}
     */
    get hasNoMatches() {
      var _this$_filterObject8;
      return (_this$_filterObject8 = this._filterObject) === null || _this$_filterObject8 === void 0 ? void 0 : _this$_filterObject8.hasNoMatches;
    }

    /**
     * True if the last request returned an error.
     *
     * @computed hasNoMatches
     * @return {Boolean}
     */
    get isError() {
      var _this$_filterObject9;
      return (_this$_filterObject9 = this._filterObject) === null || _this$_filterObject9 === void 0 ? void 0 : _this$_filterObject9.isError;
    }
    async _setup() {
      if (!this.key) {
        return;
      }
      const dataFilter = this.uiFilter.getQPFilter(this.key);
      this._filterObject = dataFilter === null || dataFilter === void 0 ? void 0 : dataFilter.filter;

      // Setup public api interface
      const publicAPI = Object.assign({}, {
        name: this.key,
        filterable: this.filterable,
        searchable: this.searchable,
        paginated: this.paginated,
        modelName: this.modelName,
        data: this.data,
        actions: {
          reset: this.reset.bind(this),
          resetAndRefresh: this.resetAndRefresh.bind(this),
          changePage: this.changePage.bind(this),
          search: this.search.bind(this),
          filter: this.filter.bind(this),
          reloadModel: this.reloadModel.bind(this),
          updateData: this.updateData.bind(this)
        }
      });
      this._publicAPI = publicAPI;
      const uiFilter = this.uiFilter;
      await uiFilter.setup(this.key);
      uiFilter.loadModel(this.key);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiFilter", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      /**
       * @argument key
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "key", _descriptor4, this);
      /**
       * @argument modelName
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "modelName", _descriptor5, this);
      /**
       * data that is yielded down
       *
       * @property _publicAPI
       * @type {Object}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_publicAPI", _descriptor6, this);
      /**
       * the internal filter object
       *
       * @property _filterObject
       * @type {Object}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_filterObject", _descriptor7, this);
      this._setup();
    }
    willDestroy() {
      /*
       * Filter might be already unregistered in this situation,
       * which would end up in a ember error as it does not find the filter.
       */
      try {
        // reset params when component gets destroyed
        this.reset();
      } catch (e) {
        /* ignore */
      }
      super.willDestroy(...arguments);
    }

    /**
     * `QueryParameters`-Proxy to reload model
     * @function reloadModel
     */
    reloadModel() {
      return this.uiFilter.loadModel(this.key);
    }

    /**
     * `QueryParameters`-Proxy to reset all filters
     * @function reset
     */
    reset() {
      return this.uiFilter.reset(this.key, false);
    }

    /**
     * `QueryParameters`-Proxy to reset all filters and refetch model
     * @function resetAndRefresh
     */
    resetAndRefresh() {
      return this.uiFilter.reset(this.key);
    }

    /**
     * `QueryParameters`-Proxy to update pagination
     * @function changePage
     */
    changePage(page) {
      return this.uiFilter.changePage(this.key, page);
    }

    /**
     * `QueryParameters`-Proxy to update pagination
     * @function changePage
     */
    updateData(data) {
      return this.uiFilter.updateData(this.key, data);
    }

    /**
     * `QueryParameters`-Proxy to set the search as filters
     * @function search
     */
    search() {
      let searchTerm = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return this.uiFilter.search(this.key, searchTerm);
    }

    /**
     * `QueryParameters`-Proxy to set a filter
     * @function filter
     */
    filter(key, val) {
      return this.uiFilter.filter(this.key, key, val);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "key", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_publicAPI", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_filterObject", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFilterableComponent);
});