define("@additive-apps/ui/components/ui-progress-bar", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-progress-bar", "@ember/object", "@ember/string"], function (_exports, _component, _uiProgressBar, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * A component that renders a progress bar
   * ```hbs
   * {{ui-progress-bar progress description remaining}}
   * ```
   *
   * @class ui-progress-bar
   * @public
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiProgressBar.default,
    classNames: ['ui-progress-bar', 'w-100'],
    /**
     * the progress of the progress bar
     * @property progress
     * @type {Number}
     * @default 0
     */
    progress: 0,
    /**
     * the description of the progress bar
     * @property description
     * @type {String}
     * @default undefined
     */
    description: undefined,
    /**
     * the description of the remaining time
     * @property remaining
     * @type {String}
     * @default undefined
     */
    remaining: undefined,
    /**
     * the html safe description
     *
     * @computed _description
     * @type {String}
     * @private
     */
    _description: (0, _object.computed)('description', {
      get() {
        return (0, _string.htmlSafe)(this.description);
      }
    }),
    /**
     * Computed width of the progress bar
     * @computed _progressWidthStyles
     * @type {String}
     * @private
     */
    _progressWidthStyles: (0, _object.computed)('progress', {
      get() {
        const progress = typeof this.progress === 'number' ? this.progress : 0;
        return (0, _string.htmlSafe)(`width: ${Math.floor(progress * 100)}%`);
      }
    }),
    /**
     * the html safe remaining
     *
     * @computed _remaining
     * @type {String}
     * @private
     */
    _remaining: (0, _object.computed)('remaining', {
      get() {
        return (0, _string.htmlSafe)(this.remaining);
      }
    })
  });
});