define("@additive-apps/ui/templates/components/ui-item-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "b1cOgULl",
    "block": "[[[8,[39,0],null,[[\"@stopBubbling\",\"@verticalPosition\",\"@horizontalPosition\"],[[30,0,[\"stopBubbling\"]],[30,0,[\"verticalPosition\"]],[30,0,[\"horizontalPosition\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-ui-item-menu-trigger\",\"\"]],[[\"@class\",\"@isSecondary\",\"@icon\"],[\"db\",true,[30,0,[\"triggerIcon\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"Content\"]],null,[[\"@width\",\"@transform\"],[[30,0,[\"menuContentWidth\"]],[30,0,[\"menuContentTransform\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[28,[37,3],null,[[\"menuItem\"],[[30,2,[\"menuItem\"]]]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"menu\",\"content\",\"&default\"],false,[\"ui-menu\",\"ui-icon-button\",\"yield\",\"hash\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-item-menu.hbs",
    "isStrictMode": false
  });
});