define("ember-svg-jar/inlined/resize-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.177 8.884a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0l-7.293 7.293a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0l7.293-7.293zM16.177 13.884a.5.5 0 000-.707l-.354-.354a.5.5 0 00-.707 0l-2.293 2.293a.5.5 0 000 .707l.354.354a.5.5 0 00.707 0l2.293-2.293z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});