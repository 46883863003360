define("@additive-apps/ui/components/ui-content-rating", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-content-rating", "@ember/object", "@ember/object/computed", "rsvp"], function (_exports, _component, _uiContentRating, _object, _computed, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiContentRating.default,
    classNames: ['flex', 'justify-center flex-shrink-0'],
    /**
     * the max rating value
     *
     * @argument max
     * @type {Number}
     * @default 3
     */
    max: 3,
    /**
     * whether the rating is read only
     *
     * @argument isDisabled
     * @type {Boolean}
     * @default null
     */
    isDisabled: false,
    /**
     * the item
     *
     * @argument item
     * @type {Object}
     * @default null
     */
    item: null,
    /**
     * whether the item has a rating property
     *
     * @computed hasRatingProperty
     * @type {Boolean}
     */
    hasRatingProperty: (0, _computed.notEmpty)('item.rating'),
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onClick(rating) {
        const item = this.item;
        if (!item) {
          return (0, _rsvp.resolve)();
        }
        (0, _object.set)(item, 'rating', rating);
        return item.save();
      }
    }
  });
});