define("@additive-apps/media/components/mm-youtube-video", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-youtube-video", "ember-concurrency", "@ember/object", "@ember/runloop", "@ember/object/internals"], function (_exports, _component, _mmYoutubeVideo, _emberConcurrency, _object, _runloop, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-undef */
  /**
   * Component to load a embeded youtube video.
   *
   * It loads the youtube iframe api and
   * creates a YT.player instance out of the provided YT-embedUrl.
   *
   * It has 2 events:
   *   - onVideoStarted (When the video starts)
   *   - onVideoStopped (When the video stops)
   *
   *```js
   *{{mm-youtube-video
   *  embedUrl='https://www.youtube.com/embed/---ID---'
   *  onVideoStarted=(action "onVideoStarted")
   *  onVideoStopped=(action "onVideoStopped")
   *}}
   *```
   *
   * @class mm-youtube-video
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmYoutubeVideo.default,
    tagName: '',
    /**
     * The youtube embed url.
     * `https://www.youtube.com/embed/---ID---`
     *
     * @property embedUrl
     * @type {String}
     */
    embedUrl: null,
    /**
     * The youtube player instance.
     *
     * @property _player
     * @type {Object}
     */
    _player: null,
    /**
     * Text for the iframe title tag
     *
     * @property _iframeTitle
     * @type {String}
     * @private
     */
    _iframeTitle: 'youtube-preview',
    /**
     * The iframe src for the youtube-api.
     * It adds `origin` for security reasons.
     *
     * @computed _iframeSrc
     * @type {Object}
     * @private
     */
    _iframeSrc: (0, _object.computed)('embedUrl', {
      get() {
        const embedUrl = this.embedUrl;
        const origin = window.location.origin;
        const seperator = embedUrl.indexOf('?') === -1 ? '?' : '&';
        return `${embedUrl}${seperator}enablejsapi=1&origin=${origin}`;
      }
    }),
    /**
     * The iframe dom reference id.
     *
     * @computed _iframeId
     * @type {String}
     * @private
     */
    _iframeId: (0, _object.computed)({
      get() {
        return (0, _internals.guidFor)(this);
      }
    }),
    /**
     * Creates a YT.Player instance
     *
     * @function _createPlayer
     */
    _createPlayer() {
      if (typeof YT !== 'object' || !YT.Player) {
        return;
      }
      const onStateChange = (0, _runloop.bind)(this, this._onStateChange);
      const player = new YT.Player(this._iframeId, {
        events: {
          onStateChange: onStateChange
        }
      });
      (0, _object.set)(this, '_player', player);
    },
    /**
     * On player state change
     *
     * @function _onStateChange
     */
    _onStateChange(event) {
      if (typeof YT !== 'object' || !YT.PlayerState) {
        return;
      }
      const data = event.data;
      switch (data) {
        case YT.PlayerState.PLAYING:
        case YT.PlayerState.BUFFERING:
          this.onVideoStarted(this._player);
          break;
        case YT.PlayerState.PAUSED:
          this.onVideoStopped(this._player);
          break;
        default:
          break;
      }
    },
    /**
     * Load youtube iframe api once
     *
     * @function _loadApi
     * @type {Task}
     */
    // eslint-disable-next-line
    _loadApi: (0, _emberConcurrency.task)(function* () {
      const script = document.getElementById('mm-youtube-video-script');

      /* Only load once.. */
      if (script) {
        /* Check if YT is ready */
        if (typeof YT === 'object') {
          this._createPlayer();
        } else {
          /* Register custom event listener, to get informed when api is ready */
          document.addEventListener('onYouTubeIframeAPIReady', this._createPlayer);
        }
      } else {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.async = 1;
        tag.id = 'mm-youtube-video-script';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        document.addEventListener('onYouTubeIframeAPIReady', this._createPlayer);
        window.onYouTubeIframeAPIReady = () => {
          /* trigger custom event listener */
          document.dispatchEvent(new CustomEvent('onYouTubeIframeAPIReady', {}));
        };
      }
    }).drop(),
    init() {
      this._super(...arguments);
      const createPlayer = (0, _runloop.bind)(this, this._createPlayer);
      (0, _object.set)(this, '_createPlayer', createPlayer);
    },
    didInsertElement() {
      this._super(...arguments);
      this._loadApi.perform();
    },
    willDestroyElement() {
      this._super(...arguments);
      const player = this._player;
      player && player.destroy();
      document.removeEventListener('onYouTubeIframeAPIReady', this._createPlayer);
    },
    /**
     * On video started event
     *
     * @function onVideoStarted
     */
    onVideoStarted() {},
    /**
     * On video stopped event
     *
     * @function onVideoStopped
     */
    onVideoStopped() {}
  });
});