define("@additive-apps/ui/templates/components/ui-fullscreen-illustration", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KPolfUFe",
    "block": "[[[41,[30,0,[\"illustration\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"illustration\"]]],[[\"class\"],[\"w-100 h-auto mb4\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"ui-fullscreen-illustration__content mh1\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"title font-lg font-xl-sm\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"description font-md font-lg-sm mt2 mb4\"],[12],[1,\"\\n    \"],[1,[30,0,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,[[28,[37,3],null,[[\"button\"],[[50,\"ui-button\",0,null,null]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"buttonRoute\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@onClick\",\"@label\"],[[28,[37,6],[[30,0],\"buttonTransition\"],null],[30,0,[\"actionLabel\"]]]],null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"svg-jar\",\"yield\",\"hash\",\"component\",\"ui-button\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-fullscreen-illustration.hbs",
    "isStrictMode": false
  });
});