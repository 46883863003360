define("@additive-apps/ui/components/ui-table", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-table", "@ember/object", "@ember/object/computed", "@additive-apps/ui/utils/sort-util", "@additive-apps/ui/utils/function-util", "@additive-apps/ui/utils/table-util"], function (_exports, _component, _uiTable, _object, _computed, _sortUtil, _functionUtil, _tableUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiTable.default,
    classNames: ['ui-table', 'bg-white', 'font-sm'],
    classNameBindings: ['hasSummary:ui-table--has-summary'],
    /**
     * the arrays containing the values of the rows
     *
     * ```js
     *  [
     *    "Unformatted text",
     *    { value: 40000, percentage: 55.55, format: "currency_cent" },
     *    { value: "2019-10-06T07:05:28+00:00", format: "date" },
     *  ]
     * ```
     *
     * @property rows
     * @type {Array[]}
     * @default undefined
     */
    rows: undefined,
    /**
     * the columns array
     *
     * ```js
     *  [{
     *      name: String,
     *      key: String,
     *      description: String,
     *      minWidth: String,
     *      classNames: String,
     *      sort: String,
     *      compare: Function
     *  }]
     * ```
     *
     * @property columns
     * @type {Object[]}
     * @default undefined
     */
    columns: undefined,
    /**
     * the formatting options for the table
     *
     * ```js
     * {
     *   date: { format: 'dd.MM.yyyy', locale: de },
     *   number: { locale: 'de-DE', options: { maximumFractionDigits: 2 } },
     *   currency: { locale: 'de-DE', options: { currency: 'EUR' } },
     *   percentage: { locale: 'de-DE', options: { maximumFractionDigits: 2, minimumFractionDigits: 2 } },
     *   custom: (val) => val * 1000
     * }
     * ```
     *
     * @property formats
     * @type {Object}
     * @default null
     */
    formats: null,
    /**
     * whether the last row should have a top
     * border
     *
     * when used with sorting the summary row must be at the last positon
     * in the rows array
     *
     * @property hasSummary
     * @type {Boolean}
     * @default false
     */
    hasSummary: false,
    /**
     * whether the loading placeholder should
     * be displayed
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * whether an error has occured
     *
     * @property isError
     * @type {Boolean}
     * @default false
     */
    isError: false,
    /**
     * the number of loading placeholders
     *
     * @property loadingCount
     * @type {Number}
     * @default 10
     */
    loadingCount: 10,
    /**
     * whether the "show more"-button should be displayed if the table contains more than {maxRowsShown} rows
     *
     * @property showMoreEnabled
     * @type {Boolean}
     * @default false
     */
    showMoreEnabled: false,
    /**
     * the maximum rows to be shown before the "show more"-button is displayed
     *
     * @property maxRowsShown
     * @type {Integer}
     * @default 7
     */
    maxRowsShown: 7,
    /**
     * whether the sorted rows should be displayed
     *
     * @property _isSorted
     * @type {Boolean}
     * @default false
     * @private
     */
    _isSorted: false,
    /**
     * whether the table is empty
     *
     * @computed isEmpty
     * @type {Boolean}
     */
    isEmpty: (0, _computed.empty)('rows'),
    /**
     * whether the table body is hidden
     *
     * @computed _hideBody
     * @type {Boolean}
     * @private
     */
    _hideBody: (0, _computed.or)('isEmpty', 'isLoading', 'isError'),
    /**
     * the formatted rows
     *
     * @computed _formattedRows
     * @type {Array}
     */
    _formattedRows: (0, _object.computed)('rows.[]', 'formats', {
      get() {
        if (!this.rows) {
          return [];
        }
        let rows = this.rows;
        if (typeof rows === 'object' && typeof rows.toArray === 'function') {
          rows = rows.toArray();
        }
        return (0, _tableUtil.formatTableRows)([...rows], this.formats);
      }
    }),
    onReload() {},
    onSort(/* rows, column, direction */) {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onSort(column, direction) {
        const rows = typeof this.rows === 'object' && typeof this.rows.toArray === 'function' ? [...this.rows.toArray()] : [...this.rows];

        // return callback if table is sorted using callback
        if ((0, _functionUtil.hasBody)(this.onSort)) {
          return this.onSort(rows, column, direction);
        }
        if (direction) {
          // remove last row if it has a summary row
          let summaryRow;
          if (this.hasSummary) {
            summaryRow = rows.pop();
          }

          // sort rows
          const sortedRows = (0, _sortUtil.sortRows)(rows, column, direction);

          // add summary row
          if (summaryRow) {
            sortedRows.push(summaryRow);
          }
          const formattedSortedRows = (0, _tableUtil.formatTableRows)(sortedRows, this.formats);
          (0, _object.setProperties)(this, {
            _sortedRows: formattedSortedRows,
            _isSorted: true
          });
        } else {
          (0, _object.set)(this, '_isSorted', false);
        }
      }
    }
  });
});