define("@additive-apps/ui/components/ui-multi-form-control", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-multi-form-control"], function (_exports, _component, _uiMultiFormControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Wrapper component to render a set of spacless input field.
   *
   * @class ui-multi-form-control
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiMultiFormControl.default,
    classNames: ['ui-multi-form-control'],
    /**
     * The helperText
     *
     * @argument helperText
     * @type {String}
     */
    helperText: null,
    /**
     * if true, label and error message are not rendered
     *
     * @property isPlain
     * @type {Boolean}
     * @default false
     */
    isPlain: false,
    /**
     * The label
     *
     * @argument label
     * @type {String}
     */
    label: null,
    /**
     * The message
     *
     * @argument message
     * @type {String}
     */
    message: null,
    /**
     * The state
     *
     * @argument state
     * @type {String}
     */
    state: null,
    /**
     * On helpertext click.
     *
     * @function onHelp
     * @public
     */
    onHelp() {}
  });
});