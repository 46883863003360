define("@additive-apps/ui/components/ui-card/footer/content/title", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="column content title - flex-auto font-sm ellipsis" ...attributes>{{@value}}</div>
  */
  {
    "id": "u8uOYJaQ",
    "block": "[[[11,0],[24,0,\"column content title - flex-auto font-sm ellipsis\"],[17,1],[12],[1,[30,2]],[13]],[\"&attrs\",\"@value\"],false,[]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/content/title.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});