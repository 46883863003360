define("@additive-apps/jam-ember/instance-initializers/google-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const outputWarning = () => {
    console && typeof console.warn === 'function' && console.warn(...arguments);
  };
  async function initialize(applicationInstance) {
    const environmentVars = applicationInstance.resolveRegistration('config:environment');
    const config = environmentVars['additive-jam-ember'] && environmentVars['additive-jam-ember'].googleAnalytics || {};
    if (!config || config.enabled === false || typeof config.trackingId !== 'string') {
      return;
    }
    try {
      // Load analytics script
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date();
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    } catch (error) {
      outputWarning('[additive-jam-ember]::googleAnalytics failed to load analytics script', error);
      return;
    }
    if (typeof ga !== 'function') {
      outputWarning('[additive-jam-ember]::googleAnalytics `ga` is not a function', error);
      return;
    }

    // Init google analytics with id
    ga('create', config.trackingId, 'auto');

    /*
      Define transition listener which will send pageviews.
      Due to the fact we have a single page app, we have to manually send pageviews
    */
    const routerService = applicationInstance.lookup('service:router');
    routerService && routerService.on('routeDidChange', () => {
      try {
        ga('send', {
          hitType: 'pageview',
          page: window.location.pathname
        });
      } catch (error) {
        // Do not break application when analytics fails
        outputWarning('[additive-jam-ember]::googleAnalytics failed to send pageview', error);
      }
    });
  }
  var _default = _exports.default = {
    initialize
  };
});