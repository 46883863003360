define("@additive-apps/configurator/components/ae-configurations/media-gallery", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@additive-apps/media/utils/medium", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _medium, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    {{#if this._isGalleryOpen}}
      <MmMediaGallery @media={{this._galleryMedia}} @onClose={{this.onClose}} as |gallery|>
        <gallery.header.left />
        <gallery.header.right @hideDropdown="true" />
        <gallery.footer.right>
          {{#if (eq this._activeMedium.type "image")}}
            <UiButton
              @class="absolute bottom-3 right-3"
              @icon="edit"
              @isFabButton={{true}}
              @onClick={{this._onImageTransformOpen}}
            />
          {{/if}}
        </gallery.footer.right>
      </MmMediaGallery>
    {{/if}}
  
    {{#if this._isImageTransformView}}
      <MmImageTransforms
        @url={{this._activeMedium.url}}
        @name={{this._activeMedium.name}}
        @position={{get this._activeMedium.transformations "poi"}}
        @hidePoi={{this.hidePoi}}
        @isReadOnly={{@isReadOnly}}
        @transforms={{this._activeMedium.transformations}}
        @onSubmit={{this._onImageTransformSubmit}}
        @onClose={{this._onImageTransformClose}}
      />
    {{/if}}
  </div>
  
  */
  {
    "id": "T+MsNfsU",
    "block": "[[[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"_isGalleryOpen\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@media\",\"@onClose\"],[[30,0,[\"_galleryMedia\"]],[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"header\",\"left\"]],null,null,null],[1,\"\\n      \"],[8,[30,1,[\"header\",\"right\"]],null,[[\"@hideDropdown\"],[\"true\"]],null],[1,\"\\n      \"],[8,[30,1,[\"footer\",\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"_activeMedium\",\"type\"]],\"image\"],null],[[[1,\"          \"],[8,[39,3],null,[[\"@class\",\"@icon\",\"@isFabButton\",\"@onClick\"],[\"absolute bottom-3 right-3\",\"edit\",true,[30,0,[\"_onImageTransformOpen\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"_isImageTransformView\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@url\",\"@name\",\"@position\",\"@hidePoi\",\"@isReadOnly\",\"@transforms\",\"@onSubmit\",\"@onClose\"],[[30,0,[\"_activeMedium\",\"url\"]],[30,0,[\"_activeMedium\",\"name\"]],[28,[37,5],[[30,0,[\"_activeMedium\",\"transformations\"]],\"poi\"],null],[30,0,[\"hidePoi\"]],[30,2],[30,0,[\"_activeMedium\",\"transformations\"]],[30,0,[\"_onImageTransformSubmit\"]],[30,0,[\"_onImageTransformClose\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"gallery\",\"@isReadOnly\"],false,[\"if\",\"mm-media-gallery\",\"eq\",\"ui-button\",\"mm-image-transforms\",\"get\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/media-gallery.hbs",
    "isStrictMode": false
  });
  let AeConfigurationsMediaGalleryComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AeConfigurationsMediaGalleryComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Whether the POI setting in the image-edit view should be hidden
       *
       * @argument hidePoi
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hidePoi", _descriptor, this);
      /**
       * the gallery media to be shown
       *
       * @argument galleryMedia
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "galleryMedia", _descriptor2, this);
      /**
       * whether the gallery is open
       *
       * @property _isGalleryOpen
       * @type {Boolean}
       * @default true
       * @private
       */
      _initializerDefineProperty(this, "_isGalleryOpen", _descriptor3, this);
      /**
       * whether the image transformation view is open
       *
       * @property _isImageTransformView
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isImageTransformView", _descriptor4, this);
    }
    get _galleryMedia() {
      return this.galleryMedia.map(medium => {
        if (medium.thumbnailUrl) {
          medium.url = medium.thumbnailUrl;
          medium.isImage = true;
          delete medium.thumbnailUrl;
        }
        return medium;
      });
    }
    get _activeMedium() {
      return this.galleryMedia[0];
    }
    onChange() {}
    onClose() {}
    _onImageTransformClose() {
      this._isGalleryOpen = false;
      this._isImageTransformView = false;
      this.onClose();
    }
    _onImageTransformOpen() {
      this._isGalleryOpen = false;
      this._isImageTransformView = true;
    }
    _onImageTransformSubmit(newTransforms) {
      const resource = this._activeMedium;
      const newResourceTransforms = Object.assign({}, resource.transformations, newTransforms);

      // remove unset transformations
      Object.keys(newResourceTransforms).forEach(resource => {
        !newResourceTransforms[resource] && delete newResourceTransforms[resource];
      });
      const newResource = Object.assign({}, resource, {
        transformations: newResourceTransforms,
        url: (0, _medium.mediumUrlOptions)(resource.url, newTransforms)
      });
      this.onChange(newResource);
      this._onImageTransformClose();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "hidePoi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "galleryMedia", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_isGalleryOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_isImageTransformView", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onImageTransformClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onImageTransformClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onImageTransformOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onImageTransformOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onImageTransformSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onImageTransformSubmit"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsMediaGalleryComponent);
});