define("@additive-apps/ui/components/ui-filter-inputs/text", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/text", "@ember/object"], function (_exports, _base, _text, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represents the `text` type filter for ui-filter-inputs
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/text
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _text.default,
    /**
     * The current value of the `initialValue` prop
     *
     * @property _currentInitialValue
     * @type {String}
     * @private
     */
    _currentInitialValue: null,
    /**
     * Computed property for the value,
     * it uses the initial value when present
     * and triggers onChange after input-change
     *
     * @computed _value
     * @type {String}
     * @private
     */
    _value: (0, _object.computed)('_currentInitialValue', 'initialValue', {
      get() {
        return this._currentInitialValue || this.initialValue;
      },
      set(_, value) {
        this._onChange(this.filter.key, value);
        return value;
      }
    }),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._currentInitialValue !== this.initialValue) {
        (0, _object.set)(this, '_currentInitialValue', this.initialValue);
      }
    }
  });
});