define("@additive-apps/configurator/validations/custom-link", ["exports", "@additive-apps/configurator/validators/urls-with-placeholders"], function (_exports, _urlsWithPlaceholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customLinkValidation = void 0;
  const customLinkValidation = (intl, allowedPlaceholders) => {
    return {
      url: (0, _urlsWithPlaceholders.validateLink)({
        message: intl.t('global.errors.url')
      }, allowedPlaceholders)
    };
  };
  _exports.customLinkValidation = customLinkValidation;
});