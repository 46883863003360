define("@additive-apps/ui/components/ui-selection-container/actions", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-selection-container/actions", "@ember/object"], function (_exports, _component, _actions, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Handles the positioning of the action box
   *
   * @class actions
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _actions.default,
    /**
     * whether the box is visible
     *
     * @property isVisible
     * @type {Boolean}
     * @default false
     */
    isVisible: false,
    /**
     *  used to position the action boxed fixed
     *
     * @property isFixed
     * @type {Boolean}
     * @default false
     */
    isFixed: false,
    /**
     *  triggers loading state
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     *  text shown during loading
     *
     * @property loadingText
     * @type {String}
     * @default null
     */
    loadingText: null,
    /**
     * the element into which the action bar is rendered if specified
     *
     * @property destinationElement
     * @type {Boolean}
     * @default null
     */
    destinationElement: null,
    /**
     * the selector of the destination element
     *
     * @property destinationElementSelector
     * @type {Boolean}
     * @default null
     */
    destinationElementSelector: null,
    toggleSelectionMode() {},
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      if (document && this.destinationElementSelector) {
        (0, _object.set)(this, 'destinationElement', document.querySelector(this.destinationElementSelector));
      }
    }
  });
});