define("ember-svg-jar/inlined/click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.248 6a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h.5a.5.5 0 01.5.5v2zm2.656 2.4a.5.5 0 01-.683-.183l-.25-.433a.5.5 0 01.183-.683l1.732-1a.5.5 0 01.683.183l.25.433a.5.5 0 01-.183.683l-1.732 1zM5.427 6.284A.5.5 0 016.11 6.1l1.732 1a.5.5 0 01.183.683l-.25.433a.5.5 0 01-.683.183l-1.732-1a.5.5 0 01-.183-.683l.25-.433zM7.092 10.6a.5.5 0 01.683.183l.25.433a.5.5 0 01-.183.683l-1.732 1a.5.5 0 01-.683-.183l-.25-.433a.5.5 0 01.183-.683l1.732-1zm3.874-1.39a.5.5 0 00-.8.462l1.155 8.748a.5.5 0 00.746.368l2.272-1.312 1.75 3.03a.5.5 0 00.683.184l.433-.25a.5.5 0 00.183-.683l-1.75-3.031 2.273-1.312a.5.5 0 00.054-.83L10.966 9.21zm4.899 5.653l-3.253 1.878-.643-4.87 3.896 2.992z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});