define("@additive-apps/ui/components/ui-view-modes/toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiToggleGroup
    @activeValue={{this._activeValue}}
    @onChange={{this.onChange}}
    ...attributes as |toggle|
  >
    {{#each this.modes as |mode|}}
      <toggle.item @value={{mode.key}} @icon={{mode.icon}} data-test-ui-view-modes-toggle={{mode.key}} />
    {{/each}}
  </UiToggleGroup>
  */
  {
    "id": "mIyS5jQB",
    "block": "[[[8,[39,0],[[17,1]],[[\"@activeValue\",\"@onChange\"],[[30,0,[\"_activeValue\"]],[30,0,[\"onChange\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"modes\"]]],null]],null],null,[[[1,\"    \"],[8,[30,2,[\"item\"]],[[16,\"data-test-ui-view-modes-toggle\",[30,3,[\"key\"]]]],[[\"@value\",\"@icon\"],[[30,3,[\"key\"]],[30,3,[\"icon\"]]]],null],[1,\"\\n\"]],[3]],null]],[2]]]]]],[\"&attrs\",\"toggle\",\"mode\"],false,[\"ui-toggle-group\",\"each\",\"-track-array\"]]",
    "moduleName": "@additive-apps/ui/components/ui-view-modes/toggle.hbs",
    "isStrictMode": false
  });
  /**
   * Component to render the toggle-modes of the `ui-view-modes` component.
   *
   * @class ui-view-modes/toggle
   */
  let UiViewModesToggleComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec3 = (0, _emberArgTypes.arg)(_emberArgTypes.func), _class = class UiViewModesToggleComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      /**
       * The modes
       *
       * @argument modes
       * @type {Object[]}
       * @default []
       */
      _initializerDefineProperty(this, "modes", _descriptor, this);
      /**
       * The active mode key
       *
       * @argument activeModeKey
       * @type {Object[]}
       * @default []
       */
      _initializerDefineProperty(this, "activeModeKey", _descriptor2, this);
      /**
       * Event to trigger on active change
       *
       * @argument onChange
       * @type {function}
       */
      _initializerDefineProperty(this, "onChange", _descriptor3, this);
      /**
       * The active toggle-group value
       *
       * @argument _activeValue
       * @type {String}
       * @default null
       * @private
       */
      _defineProperty(this, "_activeValue", null);
      if (this.args.activeModeKey) {
        /* Make a unmutable copy, as ui-toggle-group does not follow data-down-actions-up concept! */
        this._activeValue = this.args.activeModeKey;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeModeKey", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiViewModesToggleComponent);
});