define("@additive-apps/ui/components/ui-card/footer/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="footer - flex flex-row pa2 relative {{if @isActive 'active'}}">
    {{yield
      (hash
        status=(component "ui-card/footer/status")
        popover=(component "ui-card/footer/popover")
        content=(component "ui-card/footer/content")
        menu=(component "ui-card/footer/menu")
      )
    }}
  </div>
  */
  {
    "id": "3BUwLT4X",
    "block": "[[[10,0],[15,0,[29,[\"footer - flex flex-row pa2 relative \",[52,[30,1],\"active\"]]]],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"status\",\"popover\",\"content\",\"menu\"],[[50,\"ui-card/footer/status\",0,null,null],[50,\"ui-card/footer/popover\",0,null,null],[50,\"ui-card/footer/content\",0,null,null],[50,\"ui-card/footer/menu\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@isActive\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});