define("@additive-apps/configurator/components/ae-configurations/apps/enquiry-establishments", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/application", "@ember/object", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _application, _object, _service, _emberConcurrency, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert (perform this.fetchEnquiryEstablishments)}}>
    {{#if this.fetchEnquiryEstablishments.isRunning}}
      <UiSkeleton @items={{1}} @cardHeight={{32}} @gap={{0}} />
    {{else}}
      <UiSelect
        @options={{this._enquiryEstablishments}}
        @selected={{this._selectedEstablishments}}
        @onChange={{this.onSelect}}
        @onSelectAll={{fn this.onSelectAll true}}
        @onDeselectAll={{fn this.onSelectAll false}}
        @isMultiSelect={{true}}
        @objectKey="name"
        @valueKey="code"
        @isReadOnly={{@isReadOnly}}
        @isPlain={{true}} as |select item|
      >
        <select.item @value={{item}}>
          {{item.name}}
        </select.item>
      </UiSelect>
    {{/if}}
  </div>
  */
  {
    "id": "fQgLOhOK",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"fetchEnquiryEstablishments\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"fetchEnquiryEstablishments\",\"isRunning\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@items\",\"@cardHeight\",\"@gap\"],[1,32,0]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@options\",\"@selected\",\"@onChange\",\"@onSelectAll\",\"@onDeselectAll\",\"@isMultiSelect\",\"@objectKey\",\"@valueKey\",\"@isReadOnly\",\"@isPlain\"],[[30,0,[\"_enquiryEstablishments\"]],[30,0,[\"_selectedEstablishments\"]],[30,0,[\"onSelect\"]],[28,[37,5],[[30,0,[\"onSelectAll\"]],true],null],[28,[37,5],[[30,0,[\"onSelectAll\"]],false],null],true,\"name\",\"code\",[30,1],true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]],[13]],[\"@isReadOnly\",\"select\",\"item\"],false,[\"did-insert\",\"perform\",\"if\",\"ui-skeleton\",\"ui-select\",\"fn\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/apps/enquiry-establishments.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AeConfigurationsAppsEnquiryEstablishments = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _object.computed)('item.value', '_enquiryEstablishments'), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberConcurrency.task)(function* () {
    this._isFetchError = false;

    // skip fetch if already loaded
    if (this._enquiryEstablishments) {
      return;
    }
    try {
      var _this$currentUser;
      let tasks = [];
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const baseHost = ENV.APP.apiBaseHost;
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': this.uiAppSettings.currentLocale
        }
      };
      const organizationSlug = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
      const request = this.authenticatedFetch.fetch(`${baseHost}/${organizationSlug}/enquiry/establishments`, requestOptions);
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        throw Error('[ENQUIRY-ESTABLISHMENTS] Fetch of enquiry establishments failed');
      }
      const {
        enquiryEstablishments
      } = yield response.json();
      if (!enquiryEstablishments) {
        throw Error('[ENQUIRY-ESTABLISHMENTS] Fetch of enquiry establishments failed');
      }
      this._enquiryEstablishments = enquiryEstablishments;
    } catch (error) {
      this._isFetchError = true;
    }
  }), _class = class AeConfigurationsAppsEnquiryEstablishments extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      /**
       * the enquiry establishments item
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor4, this);
      /**
       * the fetched enquiry establishments
       *
       * @property _enquiryEstablishments
       * @type {Array}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_enquiryEstablishments", _descriptor5, this);
      /**
       * whether an error occured while fetching the enquiry establishments
       *
       * @property _isFetchError
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isFetchError", _descriptor6, this);
      _initializerDefineProperty(this, "onChange", _descriptor7, this);
      /**
       * fetches the enquiry establishments and formats them
       *
       * @function fetchEnquiryEstablishments
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchEnquiryEstablishments", _descriptor8, this);
    }
    get _selectedEstablishments() {
      if (!this._enquiryEstablishments) {
        return [];
      }
      return this._enquiryEstablishments.filter(establishment => (this.item.value || []).includes(establishment.code));
    }
    onSelect(toggledEstablishment, checked) {
      const establishments = Array.isArray(this.item.value) ? [...this.item.value] : [];
      if (checked) {
        establishments.pushObject(toggledEstablishment.code);
      } else {
        establishments.removeObject(toggledEstablishment.code);
      }
      this.onChange(establishments);
    }
    onSelectAll(checked) {
      const establishments = checked ? [...this._enquiryEstablishments.map(establishment => establishment.code)] : [];
      this.onChange(establishments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_enquiryEstablishments", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isFetchError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_selectedEstablishments", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_selectedEstablishments"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetchEnquiryEstablishments", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAll"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsAppsEnquiryEstablishments);
});