define("@additive-apps/media/components/ui-carousel/container", ["exports", "@ember/component", "@additive-apps/media/templates/components/ui-carousel/container", "@ember/object", "@ember/service", "@ember/string"], function (_exports, _component, _container, _object, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_KEY_PREFIX = 'ui-carousel__container';
  const STATE_OBJECT = {
    id: null,
    index: 0,
    didLazyRender: false
  };

  /**
   *  ui-carousel slide-container.
   *
   * TODO:
   *  - events: previous, next, hidden, visible ....
   *
   * ```hbs
   * {{#ui-carousel as |carousel|}}
   *    {{#carousel.container}}Content of container 1{{/carousel.container}}
   * {{/ui-carousel}}
   * ```
   *
   * @class ui-carousel-container
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _container.default,
    tagName: '',
    uiState: (0, _service.inject)(),
    /**
     * HTML-Classes the container-div should get
     * @property containerClasses
     * @type {String}
     * @optional
     * @default ''
     */
    containerClasses: '',
    /**
     * HOC's state object
     * @property parentState
     * @type {Object}
     */
    parentState: null,
    /**
     * Whether it can render content or not.
     * @property renderContent
     * @type {Boolean}
     * @private
     */
    renderContent: null,
    /**
     * Its state object
     * @property state
     * @type {Object}
     * @private
     */
    state: null,
    /**
     * "order" inline-styles according to its index.
     * @computed _inlineStyles
     * @type {String}
     * @private
     */
    _inlineStyles: (0, _object.computed)('state.index', {
      get() {
        var _this$state;
        return (0, _string.htmlSafe)(`order: ${(_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.index}`);
      }
    }),
    init() {
      var _this$parentState;
      this._super(...arguments);
      const randomSlug = Math.random().toString(36).substr(2, 9);
      const id = `${STATE_KEY_PREFIX}-${randomSlug}`;
      (0, _object.set)(this, 'state', Object.assign({}, STATE_OBJECT, {
        id
      }));
      this.uiState.register(id, this.state);
      const myIndex = this.parentState.registerContainer(this.state);
      (0, _object.set)(this, 'state.index', myIndex);

      // When carousel uses lazy rendering we define renderContent as computedPorperty, to only render when needed.
      if ((_this$parentState = this.parentState) !== null && _this$parentState !== void 0 && _this$parentState.useLazyRendering) {
        const renderContentComputed = (0, _object.computed)('parentState.activeIndex', 'state.{didLazyRender,index}', {
          get() {
            var _this$state2, _this$state3, _this$parentState2;
            // If allready rendered lazilly, do not destroy and re-render again
            if ((_this$state2 = this.state) !== null && _this$state2 !== void 0 && _this$state2.didLazyRender) {
              return true;
            }
            const myPosition = (_this$state3 = this.state) === null || _this$state3 === void 0 ? void 0 : _this$state3.index;
            const activePosition = (_this$parentState2 = this.parentState) === null || _this$parentState2 === void 0 ? void 0 : _this$parentState2.activeIndex;
            // On lazy-rendering we pre-render one ahead and one behind of current index
            const canRender = myPosition >= activePosition - 1 && myPosition <= activePosition + 1;
            // eslint-disable-next-line ember/no-side-effects
            (0, _object.set)(this, 'state.didLazyRender', canRender);
            return canRender;
          }
        });
        (0, _object.defineProperty)(this, 'renderContent', renderContentComputed);
      } else {
        (0, _object.set)(this, 'renderContent', true);
      }
    },
    willDestroyElement() {
      var _this$state4;
      this._super(...arguments);
      this.parentState.unregisterContainer(this.state);
      this.uiState.unregister((_this$state4 = this.state) === null || _this$state4 === void 0 ? void 0 : _this$state4.id);
    }
  });
});