define("@additive-apps/ui/components/ui-info-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-info-item flex-grow-1-sm flex-shrink-0 pv1 pr2 font-sm">
    <div class="secondary">
      {{@label}}
    </div>
    <div class="flex primary">
      {{@content}}
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "0158JpF1",
    "block": "[[[10,0],[14,0,\"ui-info-item flex-grow-1-sm flex-shrink-0 pv1 pr2 font-sm\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex primary\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"@content\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-info-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});