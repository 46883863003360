define("@additive-apps/ui/components/ui-list-item/thumbnail", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="thumbnail flex items-center justify-center flex-shrink-0 ohidden {{if @isRounded "br-100" "br1"}}">
      {{#if (has-block)}}
          {{yield}}
      {{else if @src}}
          <img
              class="br1"
              src={{@src}}
              alt={{@alt}}
          />
      {{else}}
          <div class="flex items-center justify-center w-100 h-100 bg-secondary--10">
              {{svg-jar (or @placeholderIcon "placeholder-image") class="secondary--40"}}
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "slleSm/s",
    "block": "[[[10,0],[15,0,[29,[\"thumbnail flex items-center justify-center flex-shrink-0 ohidden \",[52,[30,1],\"br-100\",\"br1\"]]]],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[18,5,null],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"        \"],[10,\"img\"],[14,0,\"br1\"],[15,\"src\",[30,2]],[15,\"alt\",[30,3]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"flex items-center justify-center w-100 h-100 bg-secondary--10\"],[12],[1,\"\\n            \"],[1,[28,[35,3],[[28,[37,4],[[30,4],\"placeholder-image\"],null]],[[\"class\"],[\"secondary--40\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]],[]]],[13]],[\"@isRounded\",\"@src\",\"@alt\",\"@placeholderIcon\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"svg-jar\",\"or\"]]",
    "moduleName": "@additive-apps/ui/components/ui-list-item/thumbnail.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});