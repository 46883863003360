define("ember-svg-jar/inlined/exclusion-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.562 11.995l1.608 1.608-1.608 1.608a.38.38 0 000 .537l.269.269a.38.38 0 00.537 0l1.607-1.608 1.608 1.608a.38.38 0 00.538 0l.268-.269a.38.38 0 000-.537l-1.608-1.608 1.608-1.608a.38.38 0 000-.537l-.268-.269a.38.38 0 00-.538 0l-1.607 1.608-1.608-1.608a.38.38 0 00-.537 0l-.27.27a.38.38 0 000 .536z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.49 5.38a.38.38 0 00-.38-.38H3.713a.38.38 0 00-.38.38v8.357c0 .21.17.38.38.38h7.598c.21 0 .38-.17.38-.38v-.38a.38.38 0 00-.38-.38H4.473V8.534l4.75 2.714a.38.38 0 00.377 0l4.75-2.714v1.406c0 .21.17.38.38.38h.38c.21 0 .38-.17.38-.38v-4.56zm-11.017.76v1.08l4.939 2.822L14.35 7.22V6.14H4.473z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});