define("@additive-apps/ui/templates/components/ui-filter-inputs/group-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "FNqdPGhy",
    "block": "[[[10,0],[14,0,\"ui-filter-inputs__group-label font-sm primary mb2\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"words\"]]],null]],null],null,[[[41,[30,1,[\"type\"]],[[[1,\"      \"],[46,[28,[37,4],[\"ui-filter-inputs/query/\",[30,1,[\"type\"]]],null],null,[[\"filter\",\"initialValues\",\"onChange\"],[[30,1],[30,0,[\"initialValues\"]],[28,[37,5],[[30,0],[30,0,[\"onChange\"]]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1]],[1,\"\\n\"]],[]]]],[1]],null],[13]],[\"word\"],false,[\"each\",\"-track-array\",\"if\",\"component\",\"concat\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/group-label.hbs",
    "isStrictMode": false
  });
});