define("@additive-apps/ui/components/ui-tabs-pane", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-tabs-pane", "@ember/object"], function (_exports, _component, _uiTabsPane, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiTabsPane.default,
    tagName: '',
    /**
     * the name of the pane, when this name matches the one of the  active tab is is visible
     *
     * @property name
     * @type {String}
     * @default null
     */
    name: null,
    isActive: (0, _object.computed)('name', 'activeTab.name', {
      get() {
        var _this$activeTab;
        return ((_this$activeTab = this.activeTab) === null || _this$activeTab === void 0 ? void 0 : _this$activeTab.name) === this.name;
      }
    })
  });
});