define("@additive-apps/configurator/components/aw-typography-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.typographyKeys as |typographyKey|}}
    {{#let (concat this.baseKey "." typographyKey) as |fullKey|}}
      <AwTypographyList::Item
        @key={{typographyKey}}
        @fonts={{this.fonts}}
        @colorOptions={{this._colorOptions}}
        @corporateDesignTypography={{this.corporateDesignTypography}}
        @value={{get this.styles fullKey}}
        @showCustomFontWarning={{this.showCustomFontWarning}}
        @intlBaseKey={{this.intlBaseKey}}
        @onClick={{pipe
          (fn (mut this.fontKey) typographyKey)
          (fn (mut this.currentFont) (get this.styles fullKey))
          (fn (mut this._isDetailOpen) true)
        }}
        @onToggleSync={{fn this._toggleSync fullKey}}
        @onUpdate={{fn this._update fullKey}}
        @isReadOnly={{this.isReadOnly}}
      />
    {{/let}}
  {{/each}}
  
  {{#if this._isDetailOpen}}
    <AwTypographyDialog
      @colorOptions={{this._colorOptions}}
      @fontOptions={{this.fontOptions}}
      @font={{this.currentFont}}
      @key={{this.fontKey}}
      @showMobileProperties={{this.showMobileProperties}}
      @onClose={{fn (mut this._isDetailOpen) false}}
      @onUpdate={{this._update}}
      @isReadOnly={{this.isReadOnly}}
    />
  {{/if}}
  
  */
  {
    "id": "x8UMsIyI",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"typographyKeys\"]]],null]],null],null,[[[44,[[28,[37,3],[[30,0,[\"baseKey\"]],\".\",[30,1]],null]],[[[1,\"    \"],[8,[39,4],null,[[\"@key\",\"@fonts\",\"@colorOptions\",\"@corporateDesignTypography\",\"@value\",\"@showCustomFontWarning\",\"@intlBaseKey\",\"@onClick\",\"@onToggleSync\",\"@onUpdate\",\"@isReadOnly\"],[[30,1],[30,0,[\"fonts\"]],[30,0,[\"_colorOptions\"]],[30,0,[\"corporateDesignTypography\"]],[28,[37,5],[[30,0,[\"styles\"]],[30,2]],null],[30,0,[\"showCustomFontWarning\"]],[30,0,[\"intlBaseKey\"]],[28,[37,6],[[28,[37,7],[[28,[37,8],[[30,0,[\"fontKey\"]]],null],[30,1]],null],[28,[37,7],[[28,[37,8],[[30,0,[\"currentFont\"]]],null],[28,[37,5],[[30,0,[\"styles\"]],[30,2]],null]],null],[28,[37,7],[[28,[37,8],[[30,0,[\"_isDetailOpen\"]]],null],true],null]],null],[28,[37,7],[[30,0,[\"_toggleSync\"]],[30,2]],null],[28,[37,7],[[30,0,[\"_update\"]],[30,2]],null],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"]],[2]]]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"_isDetailOpen\"]],[[[1,\"  \"],[8,[39,10],null,[[\"@colorOptions\",\"@fontOptions\",\"@font\",\"@key\",\"@showMobileProperties\",\"@onClose\",\"@onUpdate\",\"@isReadOnly\"],[[30,0,[\"_colorOptions\"]],[30,0,[\"fontOptions\"]],[30,0,[\"currentFont\"]],[30,0,[\"fontKey\"]],[30,0,[\"showMobileProperties\"]],[28,[37,7],[[28,[37,8],[[30,0,[\"_isDetailOpen\"]]],null],false],null],[30,0,[\"_update\"]],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n\"]],[]],null]],[\"typographyKey\",\"fullKey\"],false,[\"each\",\"-track-array\",\"let\",\"concat\",\"aw-typography-list/item\",\"get\",\"pipe\",\"fn\",\"mut\",\"if\",\"aw-typography-dialog\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-typography-list.hbs",
    "isStrictMode": false
  });
  /**
   * Component which displays a list of given typography-keys
   *
   * @class AwTypographyList
   */
  let AwTypographyList = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.array), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _emberArgTypes.arg)(_propTypes.object), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string), _dec8 = (0, _emberArgTypes.arg)(_propTypes.object), _dec9 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec10 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec11 = (0, _emberArgTypes.arg)(_propTypes.array), _dec12 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwTypographyList extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the styles object parent/base key
       *
       * `styles.${baseKey}.h1`
       *
       * @argument baseKey
       * @type {String}
       */
      _initializerDefineProperty(this, "baseKey", _descriptor, this);
      /**
       * The typography defined in the corporate design
       *
       * @property corporateDesignTypography
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor2, this);
      /**
       * the available font options
       *
       * @argument fontOptions
       * @type {Array}
       */
      _initializerDefineProperty(this, "fontOptions", _descriptor3, this);
      /**
       * whether the typography settings are read-only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      /**
       * the available fonts for non standard fonts
       * example for a font key like `primary.bold`
       *
       * @argument fonts
       * @type {Object}
       */
      _initializerDefineProperty(this, "fonts", _descriptor5, this);
      /**
       * whether the typography color should be hidden
       *
       * @argument hideColor
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hideColor", _descriptor6, this);
      /**
       * the intl-service base key
       *
       * @argument intlBaseKey
       * @type {String}
       */
      _initializerDefineProperty(this, "intlBaseKey", _descriptor7, this);
      /**
       * The configurator styles object
       *
       * @argument intlBaseKey
       * @type {Object}
       */
      _initializerDefineProperty(this, "styles", _descriptor8, this);
      /**
       * whether a warning should be shown on selected custom font
       *
       * @argument showCustomFontWarning
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "showCustomFontWarning", _descriptor9, this);
      /**
       * whether the mobile properties should be shown
       *
       * @argument showMobileProperties
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showMobileProperties", _descriptor10, this);
      /**
       * A list of typography keys to use
       *
       * `['h1', 'h2', 'small', ...]`
       *
       * @argument typographyKeys
       * @type {Array}
       */
      _initializerDefineProperty(this, "typographyKeys", _descriptor11, this);
      /**
       * Whether detail dialog is open
       *
       * @property _isDetailOpen
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isDetailOpen", _descriptor12, this);
      /**
       * on update event
       *
       * @argument onUpdate
       * @type {Function}
       */
      _initializerDefineProperty(this, "onUpdate", _descriptor13, this);
    }
    get _colorOptions() {
      if (this.hideColor) {
        return null;
      }
      const colors = [];
      const colorObject = (this.styles || {}).colors;
      if (!colorObject) {
        return colors;
      }
      ['main', 'accent', 'ambient'].forEach(key => {
        const {
          color,
          contrastColor,
          sync,
          syncedColor,
          syncedContrastColor
        } = colorObject[key];
        colors.push({
          key,
          value: {
            color: sync ? syncedColor : color,
            contrastColor: sync ? syncedContrastColor : contrastColor,
            sync: false
          }
        });
      });
      return colors;
    }
    _update(key, value) {
      this.onUpdate(key, value);
    }
    _toggleSync(fullKey, _, sync) {
      const value = {
        sync: !sync
      };
      this.onUpdate(fullKey, value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "baseKey", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'typography';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hideColor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intlBaseKey", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'awTypographyList';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "styles", [_dec8, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showCustomFontWarning", [_dec9, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showMobileProperties", [_dec10, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "typographyKeys", [_dec11, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isDetailOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec12, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_toggleSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_toggleSync"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwTypographyList);
});