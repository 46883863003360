define("@additive-apps/ui/components/ui-filter-inputs/group-label", ["exports", "@additive-apps/ui/components/ui-filter-inputs/query", "@additive-apps/ui/templates/components/ui-filter-inputs/group-label"], function (_exports, _query, _groupLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Filter-input for type `grouplabel`.
   * Provides same functionality as `query` with bigger font
   *
   * @extends ui-filter-inputs/query
   * @class ui-filter-inputs/grouplabel
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _query.default.extend({
    layout: _groupLabel.default
  });
});