define("@additive-apps/ui/components/ui-application-navigation-drawer/account-switch/wrapper", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @hasMultipleOrganizations}}
    <div
      class="account-switch relative flex w-100 pa3 cpointer bg-secondary--10--hover {{@class}}"
      role="button"
      {{on "click" @onClick}}
      ...attributes
    >
      {{yield}}
    </div>
  {{else if @href}}
    <a
      href={{@href}}
      class="account-switch relative flex w-100 pa3 cpointer bg-secondary--10--hover {{@class}}"
      target="_self"
    >
      {{yield}}
    </a>
  {{else}}
    <div class="account-switch relative flex w-100 pa3" ...attributes>
      {{yield}}
    </div>
  {{/if}}
  */
  {
    "id": "UkBxjl2A",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,0],[16,0,[29,[\"account-switch relative flex w-100 pa3 cpointer bg-secondary--10--hover \",[30,2]]]],[24,\"role\",\"button\"],[17,3],[4,[38,1],[\"click\",[30,4]],null],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"  \"],[10,3],[15,6,[30,5]],[15,0,[29,[\"account-switch relative flex w-100 pa3 cpointer bg-secondary--10--hover \",[30,2]]]],[14,\"target\",\"_self\"],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"account-switch relative flex w-100 pa3\"],[17,3],[12],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"@hasMultipleOrganizations\",\"@class\",\"&attrs\",\"@onClick\",\"@href\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-application-navigation-drawer/account-switch/wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});