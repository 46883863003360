define("@additive-apps/auth/services/current-user", ["exports", "@ember/service", "@glimmer/tracking", "rsvp", "@additive-apps/auth/utils/roles-map"], function (_exports, _service, _tracking, _rsvp, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CurrentUser = _exports.default = (_class = class CurrentUser extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      /**
       * The current logged-in organization-user
       *
       * @property user
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "user", _descriptor3, this);
      // TODO: Move it away from this service.
      _initializerDefineProperty(this, "currentOrganization", _descriptor4, this);
    }
    /**
     * Alias to user. Use user property instead.
     * @deprecated
     */
    get organizationUser() {
      return this.user;
    }

    /**
     * The current organization-user role
     *
     * @property _userRole
     * @type {String}
     * @default undefined
     * @private
     */
    get _userRole() {
      var _this$user;
      return (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.role;
    }

    /**
     * If the organization user is owner
     *
     * @property isOwner
     * @type {Boolean}
     * @default false
     */
    get isOwner() {
      const userRole = this._userRole;
      return userRole && (0, _rolesMap.hasAccess)(_rolesMap.ROLE_OWNER, userRole);
    }

    /**
     * If the organization user is admin
     *
     * @property isOwner
     * @type {Boolean}
     * @default false
     */

    get isAdmin() {
      const userRole = this._userRole;
      return userRole && (0, _rolesMap.hasAccess)(_rolesMap.ROLE_ADMIN, userRole);
    }

    /**
     * If the organization user is manager
     *
     * @property isOwner
     * @type {Boolean}
     * @default false
     */
    get isManager() {
      const userRole = this._userRole;
      return userRole && (0, _rolesMap.hasAccess)(_rolesMap.ROLE_MANAGER, userRole);
    }

    /**
     * If the organization user is viewer
     *
     * @property isViewer
     * @type {Boolean}
     * @default false
     */
    get isViewer() {
      const userRole = this._userRole;
      return userRole && userRole === _rolesMap.ROLE_VIEWER;
    }

    /**
     * If the organization user is member
     *
     * @property isOwner
     * @type {Boolean}
     * @default false
     */
    get() {
      const userRole = this._userRole;
      return userRole && (0, _rolesMap.hasAccess)(_rolesMap.ROLE_MEMBER, userRole);
    }

    /**
     * If the organization user is member
     *
     * @property isAdditiveUser
     * @type {Boolean}
     * @default false
     */
    get isAdditiveUser() {
      var _this$user2;
      return (_this$user2 = this.user) === null || _this$user2 === void 0 ? void 0 : _this$user2.isAdditive;
    }

    /**
     * If the organization user is member
     *
     * @property isOwner
     * @type {Boolean}
     * @default false
     */
    get isPartnerUser() {
      var _this$user3;
      return (_this$user3 = this.user) === null || _this$user3 === void 0 ? void 0 : _this$user3.isPartner;
    }

    /*
     * the method loads the current, logged-in user from the me-endpoint
     * and makes him available globally through the services
     * user object.
     *
     * ENV variable is imported in app file.
     *
     * Define a APP.apiCurrentUserUri environment variable to overwrite the api-me endpoint.
     * This env-var can have a placeholder for the current users organization id: {{organizationId}}.
     * This env-vars default value is '/{{organizationId}}/me'
     */
    loadUser() {
      var _this$session;
      const organization = this.currentOrganization;
      if (organization && (_this$session = this.session) !== null && _this$session !== void 0 && _this$session.isAuthenticated) {
        var _this$ENV, _this$ENV2;
        const uri = ((_this$ENV = this.ENV) === null || _this$ENV === void 0 || (_this$ENV = _this$ENV.APP) === null || _this$ENV === void 0 ? void 0 : _this$ENV.apiCurrentUserUri) || '/{{organizationId}}/me';
        const currentUserUri = uri.replace('{{organizationId}}', organization.id);
        const currentUserUrl = `${(_this$ENV2 = this.ENV) === null || _this$ENV2 === void 0 || (_this$ENV2 = _this$ENV2.APP) === null || _this$ENV2 === void 0 ? void 0 : _this$ENV2.apiBaseHost}${currentUserUri}`;
        return this.authenticatedFetch.get('fetchTask').perform(currentUserUrl).then(response => response.ok ? response.json() : (0, _rsvp.reject)({
          status: response.status
        }))
        // account API returns `{ user: {data} }` while app APIs return `{ data }`
        .then(response => response.user ? response.user : response).then(user => {
          this.user = user;
          return (0, _rsvp.resolve)(user);
        }).catch(() => {
          this.user = null;
        });
      } else {
        return (0, _rsvp.resolve)();
      }
    }

    /**
     * returns the user object if it has already been fetched or fetches it and returns it
     *
     * @function getUser
     * @returns {Object}
     */
    getUser() {
      const user = this.user;
      if (user) {
        return (0, _rsvp.resolve)(user);
      } else {
        return this.loadUser();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "user", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentOrganization", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
});