define("@additive-apps/ui/components/ui-list", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-list"], function (_exports, _component, _uiList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * ```hbs
   * <UiList
   *  @isLoading
   *  @isError
   *  @items
   *  as |List item index|
   * >
   *  <List.count />
   *  <List.error />
   *  <List.empty />
   *  <List.item />
   *    @title={{item.title}}
   *    @description={{item.description}}
   *    @state={{item.state}}
   *    @isSelected={{item.selected}}
   *  />
   * </UiList>
   * ```
   *
   * @class ui-list
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiList.default,
    classNames: ['ui-list'],
    /**
     * The lists title, normally used to divide list-groups.
     *
     * @property title
     * @type {String}
     * @default undefined
     */
    title: undefined,
    /**
     * if set there are displayed some loading-placeholders
     * instead of the actual content.
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * whether there has been an error and the error view
     * should be yielded
     *
     * @property isError
     * @type {Boolean}
     * @default false
     */
    isError: false,
    /**
     * the number of loading placeholder that should be shown
     *
     * @property placeholderCount
     * @type {Number}
     * @default 10
     */
    placeholderCount: 10
  });
});