define("@additive-apps/ui/components/ui-card/header/accessories/drag-handle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="accessory drag-handle - cgrab"
    role="button"
    {{on 'click' this.onClick}}
  >
    {{svg-jar "dragdrop"}}
  </div>
  */
  {
    "id": "UosAyu1T",
    "block": "[[[11,0],[24,0,\"accessory drag-handle - cgrab\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"dragdrop\"],null]],[1,\"\\n\"],[13]],[],false,[\"on\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/accessories/drag-handle.hbs",
    "isStrictMode": false
  });
  class UiCardHeaderAccessoriesDragHandle extends _component2.default {
    onClick(e) {
      e.stopPropagation();
    }
  }
  _exports.default = UiCardHeaderAccessoriesDragHandle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCardHeaderAccessoriesDragHandle);
});