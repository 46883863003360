define("@additive-apps/ui/templates/components/ui-filter-inputs/query/single", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "L7NhYemR",
    "block": "[[[10,0],[14,0,\"ui-filter-inputs__query-single dib relative\"],[12],[1,\"\\n  \"],[10,\"select\"],[14,\"aria-label\",\"filter-query-select\"],[15,0,[29,[\"bg-transparent font-sm secondary cpointer pl05 \",[52,[30,0,[\"isSmall\"]],\"pr2\",\"pr3\"]]]],[15,\"onchange\",[28,[37,1],[[30,0],\"onChange\"],null]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"filter\",\"values\"]]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,2,[28,[37,4],[[30,1,[\"value\"]]],null]],[15,\"selected\",[28,[37,5],[[30,0,[\"_selected\",\"value\"]],[30,1,[\"value\"]]],null]],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,6],[\"caret\"],[[\"class\"],[\"absolute right-0 top-0 bottom-0 secondary\"]]]],[1,\"\\n\"],[13]],[\"value\"],false,[\"if\",\"action\",\"each\",\"-track-array\",\"ui-to-string\",\"equal\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/query/single.hbs",
    "isStrictMode": false
  });
});