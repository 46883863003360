define("@additive-apps/ui/components/ui-filter-inputs/query", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/query", "@ember/object"], function (_exports, _base, _query, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Filter-input for type `query`.
   * Renders sentence with embedded inputs
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/query
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _query.default,
    /**
     * splits the words and places the components in the template-string
     *
     * @computed words
     * @type {Array}
     */
    words: (0, _object.computed)('filter.{components,label}', {
      get() {
        return this.filter.label.split(' ').map(word => {
          const regex = /{(\w+)}/;
          const match = word.match(regex);
          if (match) {
            return this.filter.components[match[1]];
          }
          return word;
        });
      }
    })
  });
});