define("@additive-apps/ui/templates/components/ui-filter-inputs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "zw8oOPWc",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"inputs\",\"submit\"],[[50,\"ui-filter-inputs/inputs\",0,null,[[\"groups\",\"initialValues\",\"onChange\"],[[30,0,[\"_groups\"]],[30,0,[\"initialValues\"]],[28,[37,5],[[30,0],\"inputChanged\"],null]]]],[28,[37,5],[[30,0],\"submit\"],null]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n    \"],[8,[39,6],null,[[\"@groups\",\"@initialValues\",\"@onChange\"],[[30,0,[\"_groups\"]],[30,0,[\"initialValues\"]],[28,[37,5],[[30,0],\"inputChanged\"],null]]],null],[1,\"\\n    \"],[10,0],[14,0,\"flex self-end\"],[12],[1,\"\\n      \"],[8,[39,7],null,[[\"@onClick\",\"@label\"],[[28,[37,5],[[30,0],\"submit\"],null],[28,[37,8],[\"uiFilterInputs.buttonText\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"action\",\"ui-filter-inputs/inputs\",\"ui-button\",\"t\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs.hbs",
    "isStrictMode": false
  });
});