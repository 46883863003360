define("@additive-apps/ui/components/ui-collapsible-table/row/tooltip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.tooltipData}}
    <UiPopover
      @classNames="ui-tooltip w-100"
      @hasTableContent={{this.isTable}}
      @theme="light" as |pop|
    >
      <pop.trigger>
        <div class="table-value w-100">
          <div class="table-value-content relative dib">
            {{yield}}
          </div>
        </div>
      </pop.trigger>
      <pop.content>
        {{#if this.isTable}}
          <UiTable
            @class="mh0 no-shadow"
            @columns={{this._columns}}
            @rows={{this._formattedRows}} as |table|
          >
            <table.head />
            <table.body as |body|>
              <body.row @hasActions={{false}} />
            </table.body>
          </UiTable>
        {{else}}
          {{this.content}}
        {{/if}}
      </pop.content>
    </UiPopover>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "v4u9bmtI",
    "block": "[[[41,[30,0,[\"tooltipData\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@classNames\",\"@hasTableContent\",\"@theme\"],[\"ui-tooltip w-100\",[30,0,[\"isTable\"]],\"light\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"table-value w-100\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"table-value-content relative dib\"],[12],[1,\"\\n          \"],[18,4,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isTable\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@class\",\"@columns\",\"@rows\"],[\"mh0 no-shadow\",[30,0,[\"_columns\"]],[30,0,[\"_formattedRows\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,2,[\"head\"]],null,null,null],[1,\"\\n          \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[30,3,[\"row\"]],null,[[\"@hasActions\"],[false]],null],[1,\"\\n          \"]],[3]]]]],[1,\"\\n        \"]],[2]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"content\"]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,4,null],[1,\"\\n\"]],[]]]],[\"pop\",\"table\",\"body\",\"&default\"],false,[\"if\",\"ui-popover\",\"yield\",\"ui-table\"]]",
    "moduleName": "@additive-apps/ui/components/ui-collapsible-table/row/tooltip.hbs",
    "isStrictMode": false
  });

  //eslint-disable-next-line ember/no-computed-properties-in-native-classes

  //eslint-disable-next-line ember/no-computed-properties-in-native-classes
  /**
   * This component handles the tooltip of a column.
   *
   * @class UiCollapsibleTableRowTooltip
   */
  let UiCollapsibleTableRowTooltip = _exports.default = (_dec = (0, _computed.alias)('tooltipData.content'), _dec2 = (0, _computed.equal)('tooltipData.type', 'table'), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _object.computed)('tooltipData.content.labels.[]'), _dec5 = (0, _object.computed)('tooltipData.{content.data,data.[]}'), _class = class UiCollapsibleTableRowTooltip extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "content", _descriptor, this);
      _initializerDefineProperty(this, "isTable", _descriptor2, this);
      _initializerDefineProperty(this, "tooltipData", _descriptor3, this);
    }
    get _columns() {
      var _this$tooltipData;
      if (!((_this$tooltipData = this.tooltipData) !== null && _this$tooltipData !== void 0 && _this$tooltipData.content.labels)) {
        return [];
      }
      const labels = this.tooltipData.content.labels;
      return labels.map((label, index) => {
        return {
          name: label,
          key: index,
          classNames: `no-shadow ${index > 0 ? 'text-right' : 'text-left'}`
        };
      });
    }
    get _formattedRows() {
      var _this$tooltipData2;
      if (!((_this$tooltipData2 = this.tooltipData) !== null && _this$tooltipData2 !== void 0 && (_this$tooltipData2 = _this$tooltipData2.content) !== null && _this$tooltipData2 !== void 0 && _this$tooltipData2.data)) {
        return [];
      }
      const rows = this.tooltipData.content.data;
      return [...rows];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "content", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isTable", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tooltipData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_columns", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_formattedRows", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_formattedRows"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCollapsibleTableRowTooltip);
});