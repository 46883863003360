define("@additive-apps/ui/components/ui-pagination-arrow", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isButton}}
    <UiIconButton
      @classNames={{@classNames}}
      @isTertiary={{true}}
      @onClick={{this.click}}
      @icon={{if this.isNextPageArrow "arrow-right" "arrow"}}
      @isDisabled={{this.isDisabled}}
      ...attributes
    />
  {{else}}
    <div
      role="button"
      class="ui-pagination__arrow flex {{
        if this.isDisabled "ui-pagination__arrow--disabled" "cpointer"
      }} {{if this.isNextPageArrow "ui-pagination__arrow--next" ""}} {{@classNames}} {{@class}}"
      ...attributes
      {{on "click" this.click}}
    >
      <div class="ui-pagination__arrow__svg-container flex justify-center items-center br1">
        {{svg-jar "arrow" width="20" height="20" class=(if this.isDisabled "grey" "secondary")}}
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "Juc0fh8a",
    "block": "[[[41,[30,0,[\"isButton\"]],[[[1,\"  \"],[8,[39,1],[[17,1]],[[\"@classNames\",\"@isTertiary\",\"@onClick\",\"@icon\",\"@isDisabled\"],[[30,2],true,[30,0,[\"click\"]],[52,[30,0,[\"isNextPageArrow\"]],\"arrow-right\",\"arrow\"],[30,0,[\"isDisabled\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"ui-pagination__arrow flex \",[52,[30,0,[\"isDisabled\"]],\"ui-pagination__arrow--disabled\",\"cpointer\"],\" \",[52,[30,0,[\"isNextPageArrow\"]],\"ui-pagination__arrow--next\",\"\"],\" \",[30,2],\" \",[30,3]]]],[17,1],[4,[38,2],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui-pagination__arrow__svg-container flex justify-center items-center br1\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"arrow\"],[[\"width\",\"height\",\"class\"],[\"20\",\"20\",[52,[30,0,[\"isDisabled\"]],\"grey\",\"secondary\"]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@classNames\",\"@class\"],false,[\"if\",\"ui-icon-button\",\"on\",\"svg-jar\"]]",
    "moduleName": "@additive-apps/ui/components/ui-pagination-arrow.hbs",
    "isStrictMode": false
  });
  /**
   * Pagination arrow component.
   * Will handle disabled state while its first or last page.
   * Disabled state depends on is next arrow (last page) or previous arrow (first page).
   *
   * @class ui-pagination-arrow
   */
  let UiPaginationArrow = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.number), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiPaginationArrow extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Determines it is the next-page arrow on the right side of the pagination.
       *
       * @property isNextPageArrow
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isNextPageArrow", _descriptor, this);
      /**
       * If the arrow should be an icon button
       * @argument isButton
       * @type {Boolean}
       *
       * @default false
       */
      _initializerDefineProperty(this, "isButton", _descriptor2, this);
      /**
       * The total amount of pages.
       *
       * @property total
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "total", _descriptor3, this);
      /**
       * The current page.
       *
       * @property currentPage
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "currentPage", _descriptor4, this);
      /**
       * Action which get triggered on click
       *
       * @function onClick
       */
      _initializerDefineProperty(this, "onClick", _descriptor5, this);
    }
    /**
     * Calculates if it should be disabled, for example last page or first page.
     *
     * @computed isDisabled
     * @type {Boolean}
     * @default false
     */
    get isDisabled() {
      const isNextPageArrow = this.isNextPageArrow;
      const currentPage = this.currentPage;
      if (isNextPageArrow) {
        const total = this.total;
        return currentPage >= total;
      }
      return currentPage <= 1;
    }
    /*
     * Component click event. Will fire onClick action if is not disabled.
     */
    click() {
      if (this.isDisabled) {
        return;
      }
      this.onClick();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isNextPageArrow", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isButton", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "total", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiPaginationArrow);
});