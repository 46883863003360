define("@additive-apps/configurator/components/widget-config-group", ["exports", "@ember/component", "@additive-apps/configurator/templates/components/widget-config-group"], function (_exports, _component, _widgetConfigGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-components
  // eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _widgetConfigGroup.default
  });
});