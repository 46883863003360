define("@additive-apps/ui/components/ui-menu-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class="ui-menu-item relative flex items-center font-sm ph3 text-left
    {{this._classNames}}
     {{@class}}
     {{@classNames}}
    "
    type="button"
    ...attributes
    {{on "click" this.onClickAction}}
  >
    {{yield (hash left=(component "ui-blank-template"))}}
  
    {{@label}}
  </button>
  */
  {
    "id": "7gcT4S3w",
    "block": "[[[11,\"button\"],[16,0,[29,[\"ui-menu-item relative flex items-center font-sm ph3 text-left\\n  \",[30,0,[\"_classNames\"]],\"\\n   \",[30,1],\"\\n   \",[30,2],\"\\n  \"]]],[24,4,\"button\"],[17,3],[4,[38,0],[\"click\",[30,0,[\"onClickAction\"]]],null],[12],[1,\"\\n  \"],[18,5,[[28,[37,2],null,[[\"left\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\\n  \"],[1,[30,4]],[1,\"\\n\"],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"@label\",\"&default\"],false,[\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-menu-item.hbs",
    "isStrictMode": false
  });
  /**
   * Represents a single ui-menu content element
   *
   * @class UiMenuItemComponent
   */
  let UiMenuItemComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiMenuItemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * whether the action is disabled
       *
       * @argument isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor, this);
      /**
       * whether the action is red
       *
       * @argument isDanger
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDanger", _descriptor2, this);
      /**
       * ember-basic-dropdown object
       *
       * @argument dropdown
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "dropdown", _descriptor3, this);
      /**
       * the callback that is executed when the item is clicked
       *
       * @function onClick
       */
      _initializerDefineProperty(this, "onClick", _descriptor4, this);
    }
    get _classNames() {
      if (this.isDisabled) {
        return 'secondary cdefault';
      }
      let classNames = 'cpointer bg-secondary--10--hover';
      if (this.isDanger) {
        classNames += ' red';
      }
      return classNames;
    }
    /**
     * the action passed to the button in the menu item, calls the callback and then
     * closes the dropdown-menu
     *
     * @function onClickAction
     */
    async onClickAction() {
      var _this$dropdown;
      await this.onClick();
      const dropdownActions = (_this$dropdown = this.dropdown) === null || _this$dropdown === void 0 ? void 0 : _this$dropdown.actions;
      if (dropdownActions) {
        dropdownActions.close();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDanger", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dropdown", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClickAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClickAction"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiMenuItemComponent);
});