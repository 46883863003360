define("@additive-apps/ui/components/ui-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _utils, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @href}}
    <a
      class="ui-item flex items-center relative br1 gap1 primary
        {{this.backgroundClass}}
        {{if this.isDisabled 'ui-item--disabled cdefault' 'cpointer'}}
        {{@class}}
        {{@classNames}}
        "
      disabled={{this.isDisabled}}
      href="{{@href}}"
      target="{{@target}}"
      ...attributes
    >
      {{#if this.icon}}
        {{svg-jar this.icon class="self-start db" width="20" height="20"}}
      {{/if}}
      {{yield
        (hash intro=(component "ui-intro" class="flex-grow-1" isMultiline=true))
        this.title
        this.description
      }}
    </a>
  {{else}}
    <div
      class="ui-item flex items-center relative br1 gap1
        {{this.backgroundClass}}
        {{if this.isSelected 'ui-item--selected'}}
        {{if this.isActive 'ui-item--active'}}
        {{if this.isDisabled 'ui-item--disabled cdefault' 'cpointer'}}
        {{@class}}
        {{@classNames}}
        "
      role="button"
      ...attributes
      {{on "click" this._onClick}}
    >
      {{#if this.icon}}
        {{svg-jar this.icon class="self-start db" width="20" height="20"}}
      {{/if}}
      {{yield
        (hash
          intro=(component "ui-intro" class="flex-grow-1" isMultiline=true)
          popover=(component "ui-popover")
          switch=(component "ui-switch")
          menu=(component "ui-menu" stopBubbling=true)
        )
        this.title
        this.description
        this._isLoading
      }}
    </div>
  {{/if}}
  */
  {
    "id": "t9zjkWnF",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,3],[16,0,[29,[\"ui-item flex items-center relative br1 gap1 primary\\n      \",[30,0,[\"backgroundClass\"]],\"\\n      \",[52,[30,0,[\"isDisabled\"]],\"ui-item--disabled cdefault\",\"cpointer\"],\"\\n      \",[30,2],\"\\n      \",[30,3],\"\\n      \"]]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[16,6,[29,[[30,1]]]],[16,\"target\",[29,[[30,4]]]],[17,5],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],[[\"class\",\"width\",\"height\"],[\"self-start db\",\"20\",\"20\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,6,[[28,[37,3],null,[[\"intro\"],[[50,\"ui-intro\",0,null,[[\"class\",\"isMultiline\"],[\"flex-grow-1\",true]]]]]],[30,0,[\"title\"]],[30,0,[\"description\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[16,0,[29,[\"ui-item flex items-center relative br1 gap1\\n      \",[30,0,[\"backgroundClass\"]],\"\\n      \",[52,[30,0,[\"isSelected\"]],\"ui-item--selected\"],\"\\n      \",[52,[30,0,[\"isActive\"]],\"ui-item--active\"],\"\\n      \",[52,[30,0,[\"isDisabled\"]],\"ui-item--disabled cdefault\",\"cpointer\"],\"\\n      \",[30,2],\"\\n      \",[30,3],\"\\n      \"]]],[24,\"role\",\"button\"],[17,5],[4,[38,5],[\"click\",[30,0,[\"_onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],[[\"class\",\"width\",\"height\"],[\"self-start db\",\"20\",\"20\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,6,[[28,[37,3],null,[[\"intro\",\"popover\",\"switch\",\"menu\"],[[50,\"ui-intro\",0,null,[[\"class\",\"isMultiline\"],[\"flex-grow-1\",true]]],[50,\"ui-popover\",0,null,null],[50,\"ui-switch\",0,null,null],[50,\"ui-menu\",0,null,[[\"stopBubbling\"],[true]]]]]],[30,0,[\"title\"]],[30,0,[\"description\"]],[30,0,[\"_isLoading\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@href\",\"@class\",\"@classNames\",\"@target\",\"&attrs\",\"&default\"],false,[\"if\",\"svg-jar\",\"yield\",\"hash\",\"component\",\"on\"]]",
    "moduleName": "@additive-apps/ui/components/ui-item.hbs",
    "isStrictMode": false
  });
  /**
   * Base-component for all items that are used across HOCs.
   *
   * ```hbs
   * <UiItem title description isSelected as |item|>
   *   <item.popover />
   *   <item.checkbox />
   * </UiItem>
   * ```
   * @class ui-item
   */
  let UiItem = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string), _dec7 = (0, _emberArgTypes.arg)(_propTypes.string), _dec8 = (0, _emberArgTypes.arg)(_propTypes.string), _dec9 = (0, _emberArgTypes.arg)(_propTypes.string), _dec10 = (0, _emberArgTypes.arg)(_propTypes.string), _dec11 = (0, _emberArgTypes.arg)(_propTypes.string), _dec12 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiItem extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * Disable interactivity
       *
       * @argument isDisabled
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
      /**
       * whether the item is active
       *
       * @argument isActive
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isActive", _descriptor3, this);
      /**
       * Whether the item is selected.
       * In order to work it has to be used in conjunction with
       * a selectable item-group.
       *
       * CSS Classes:
       *  - `ui-item--selected`
       *
       * @argument isSelected
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isSelected", _descriptor4, this);
      /**
       * the description of the item
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor5, this);
      /**
       * the title of the item
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor6, this);
      /**
       * the link of the item
       *
       * @argument href
       * @type {String}
       * @default undefined
       */
      _initializerDefineProperty(this, "href", _descriptor7, this);
      /**
       * the link target of the item
       *
       * @argument target
       * @type {String}
       * @default '_self'
       */
      _initializerDefineProperty(this, "target", _descriptor8, this);
      /**
       * specifies the route-path if the item is used as a link
       *
       * @property linkTo
       * @type {String}
       * @default ''
       */
      _initializerDefineProperty(this, "linkTo", _descriptor9, this);
      /**
       * specifies the route parameter if the item is used as a link
       *
       * @property linkToParam
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "linkToParam", _descriptor10, this);
      /**
       * specifies the theme of the item
       *
       * @property theme
       * @type {String}
       * @default "light"
       */
      _initializerDefineProperty(this, "theme", _descriptor11, this);
      /**
       * the icon of the item
       *
       * @argument icon
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "icon", _descriptor12, this);
      /**
       * Triggers loading state while a promise is running.
       *
       * @property isLoading
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isLoading", _descriptor13, this);
      /**
       * onClick event. Handles transitions and action handling
       * @param {Event} event The click event
       * @function onClick
       * @return {Array}
       */
      _initializerDefineProperty(this, "onClick", _descriptor14, this);
    }
    get backgroundClass() {
      if (this.theme === 'grey') {
        return 'bg-secondary--10--hover';
      }
      if (this.isDisabled) {
        return 'bg-white';
      }
      return 'bg-secondary--10 bg-secondary--20--hover';
    }
    /**
     * the click event handler
     *
     * @function _onClick
     */
    async _onClick(event) {
      try {
        this._isLoading = true;
        if (this.isDisabled) {
          return;
        }
        const linkTo = this.linkTo;
        if ((0, _utils.isPresent)(linkTo)) {
          this.router.transitionTo(linkTo, this.linkToParam);
        } else {
          await this.onClick(event);
        }
      } finally {
        this._isLoading = false;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "href", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "target", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '_self';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "linkTo", [_dec8, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "linkToParam", [_dec9, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec10, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'light';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec11, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiItem);
});