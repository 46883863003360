define("@additive-apps/ui/templates/components/ui-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "XMtEV//J",
    "block": "[[[18,1,[[28,[37,1],null,[[\"controls\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"],[10,0],[14,0,\"ui-chart__area-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-chart__wrapper\"],[12],[1,\"\\n    \"],[10,\"canvas\"],[15,1,[30,0,[\"canvasId\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,1,[[28,[37,1],null,[[\"datasets\",\"legend\"],[[30,0,[\"data\",\"datasets\"]],[50,\"ui-blank-template\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-chart.hbs",
    "isStrictMode": false
  });
});