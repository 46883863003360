define("@additive-apps/media/templates/components/mm-medium-item/file-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/fzbXq7y",
    "block": "[[[10,1],[14,0,\"ellipsis\"],[12],[1,\"\\n  \"],[1,[30,0,[\"filename\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"fileextension\",\"extension\"]],[[[41,[30,0,[\"fileextension\",\"color\"]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"fileextension\",\"color\"]]],[12],[1,\"\\n      .\"],[1,[30,0,[\"fileextension\",\"extension\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[12],[1,\"\\n      .\"],[1,[30,0,[\"fileextension\",\"extension\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-medium-item/file-name.hbs",
    "isStrictMode": false
  });
});