define("@additive-apps/configurator/components/aw-configurator/content-editor/sidebar", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative flex">
    <div
      class="aw-configurator__sidebar w-100 flex flex-column ph3 pb3 oauto"
    >
      {{yield
        (hash
          spacer=(component "ui-blank-template" tagName="div" classNames="mt2")
          button=(component "aw-configurator/content-editor/sidebar-button" isReadOnly=@isReadOnly)
        )
      }}
    </div>
  </div>
  */
  {
    "id": "1tbx0Ak4",
    "block": "[[[10,0],[14,0,\"relative flex\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"aw-configurator__sidebar w-100 flex flex-column ph3 pb3 oauto\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,1],null,[[\"spacer\",\"button\"],[[50,\"ui-blank-template\",0,null,[[\"tagName\",\"classNames\"],[\"div\",\"mt2\"]]],[50,\"aw-configurator/content-editor/sidebar-button\",0,null,[[\"isReadOnly\"],[[30,1]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isReadOnly\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/content-editor/sidebar.hbs",
    "isStrictMode": false
  });
  class AwConfiguratorContentEditorSidebarComponent extends _component2.default {}
  _exports.default = AwConfiguratorContentEditorSidebarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorContentEditorSidebarComponent);
});