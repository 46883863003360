define("@additive-apps/ui/components/ui-card/header/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="header - flex flex-column relative {{@class}}">
    {{yield (hash
      left=(component 'ui-card/header/accessories' position='left')
      right=(component 'ui-card/header/accessories' position='right')
      content=(component 'ui-card/header/content')
    )}}
  </div>
  */
  {
    "id": "0M1/slEG",
    "block": "[[[10,0],[15,0,[29,[\"header - flex flex-column relative \",[30,1]]]],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"left\",\"right\",\"content\"],[[50,\"ui-card/header/accessories\",0,null,[[\"position\"],[\"left\"]]],[50,\"ui-card/header/accessories\",0,null,[[\"position\"],[\"right\"]]],[50,\"ui-card/header/content\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"@class\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/header/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});