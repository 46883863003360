define("@additive-apps/ui/components/ui-tab-item", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-tab-item", "@ember/object", "@ember/service"], function (_exports, _component, _uiTabItem, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * The tab-item which is displayed in the tab-view.
   *
   * Inline tab-item
   * ```hbs
   * <UiTabItem @tabName="" @routePath="" @icon="" @contentCount=""/>
   *```
   *
   * When rendered with template-block a custom tab-item can be used.
   * ```hbs
   * <UiTabItem @tabName="" @routePath="" @icon="" @contentCount="" as |tabName routePath|}}
   *    custom tab-item
   * </UiTabItem>
   * ```
   *
   * @class ui-tab-item
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _uiTabItem.default,
    classNames: ['ui-routable-tabs__tab-item flex h1', 'ba br1', 'cpointer'],
    classNameBindings: ['isActive:ui-routable-tabs__tab-item--active'],
    /**
     * the currently active tab
     *
     * @property activeTab
     * @type {Any}
     * @default null
     */
    activeTab: null,
    /**
     * icon for the tab item
     *
     * @property icon
     * @type {String}
     * @default null
     */
    icon: null,
    /**
     * the text of the badge
     *
     * @property badge
     * @type {String}
     * @default null
     */
    badge: null,
    /**
     * number of contents in the tab
     *
     * @property contentCount
     * @type {Number}
     * @default null
     */
    contentCount: null,
    /**
     * The tab name
     *
     * @property tabName
     * @type {String}
     * @default null
     */
    tabName: null,
    /**
     * The path to the tab-route
     *
     * @property routePath
     * @type {String}
     * @default null
     */
    routePath: null,
    /**
     * the value that identifies the tab
     *
     * @property value
     * @type {Any}
     * @default null
     */
    value: null,
    /**
     * Determines the active tab by comparing it to the routePath for routable
     * or to the value for basic tabs
     *
     * @property isActive
     * @type {Boolean}
     * @default false
     */
    isActive: (0, _object.computed)('routePath', 'activeTab', 'value', {
      get() {
        if (this.routePath) {
          return this.router.isActive(this.routePath);
        }
        return JSON.stringify(this.value) === JSON.stringify(this.activeTab);
      }
    }),
    onClick() {},
    selectItem() {},
    click() {
      this.routePath && this.router.transitionTo(this.routePath);
      this.onClick(this.value);
      const tabValue = this.routePath || this.value;
      this.selectItem(this.element, tabValue);
    }
  });
});