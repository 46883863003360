define("@additive-apps/ui/components/ui-filter-inputs", ["exports", "@ember/component", "@ember/object", "ember-changeset", "@additive-apps/ui/templates/components/ui-filter-inputs"], function (_exports, _component, _object, _emberChangeset, _uiFilterInputs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component for the ADDITIVE filter concept: https://www.notion.so/Filter-Struktur-6359a9a02d5248b491809a24d3be9580
   * It builds the HTML fields depending on type
   *
   * @class ui-filter-inputs
   * @module ui-filter-inputs
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiFilterInputs.default,
    tagName: '',
    /**
     * The filters array as defined by concept.
     *
     * @property filters
     * @type {Object[]}
     */
    filters: null,
    /**
     * The initial values for the filters.
     *
     * object key  => filterkey
     * object[key] => filtervalue
     *
     ```javascript
        { category: 'category-id', age: ['1-10', '11-18']}
     ```
     *
     * @property initialValues
     * @type {Object}
     */
    initialValues: null,
    /**
     * Array with `groupKey`s which should be ignored.
     *
     * @property ignoreGroups
     * @type {Object[]}
     */
    ignoreGroups: null,
    /**
     * Array with `groupKey`s which should be used.
     *
     * @property specificGroups
     * @type {Object[]}
     */
    specificGroups: null,
    /**
     * The current value of the `initialValues` prop
     *
     * @property _currentInitialValues
     * @type {Array}
     * @private
     */
    _currentInitialValues: null,
    /**
     * The internal changeset to track changes.
     *
     * @property _changeset
     * @type {Changeset}
     */
    _changeset: null,
    /**
     * Normalizer of the filters property.
     *
     * @computed _filters
     * @type {Object[]|Any}
     * @private
     */
    _filters: (0, _object.computed)('filters.@each.type', {
      get() {
        return (this.filters || []).map(filter => {
          /*
           * Normalize filter types, as we use it to render components
           * and underscore is not allowed as component name.
           * `date_range` -> `daterange`
           */
          return Object.assign({}, filter, {
            type: filter.type.replace('_', '')
          });
        });
      }
    }),
    /**
     * Group filters by group-key.
     * It respects ignoreGroups and specificGroups.
     *
     * @computed _groups
     * @type {Object[]|Any}
     * @private
     */
    _groups: (0, _object.computed)('_filters.{[],@each.groupKey}', 'ignoreGroups.[]', 'specificGroups.[]', {
      get() {
        let groups = {};
        this._filtersIterator(filter => {
          if (!Object.prototype.hasOwnProperty.call(groups, filter.groupKey)) {
            groups[filter.groupKey] = {
              label: filter.groupLabel,
              filters: []
            };
          }

          // replace label with grouplabel
          if (filter.type === 'grouplabel') {
            groups[filter.groupKey].label = filter;
          } else {
            groups[filter.groupKey].filters.push(filter);
          }
        });
        return groups;
      }
    }),
    init() {
      this._super(...arguments);
      !this.ignoreGroups && (0, _object.set)(this, 'ignoreGroups', []);
      !this.specificGroups && (0, _object.set)(this, 'specificGroups', []);
      if (Array.isArray(this.filters) && this.filters.length >= 1) {
        this._createChangeset();
      }
      (0, _object.set)(this, '_currentInitialValues', this.initialValues);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (JSON.stringify(this._currentInitialValues) !== JSON.stringify(this.initialValues)) {
        (0, _object.set)(this, '_currentInitialValues', this.initialValues);
        this._createChangeset();
      }
    },
    /**
     * Creates changeset of the given filters, by respecting initialValues.
     *
     * @function _createChangeset
     * @private
     */
    _createChangeset() {
      let resource = {};
      this._filtersIterator(filter => resource[filter.key] = filter.value);
      const changeset = new _emberChangeset.default(Object.assign({}, resource, this.initialValues));
      (0, _object.set)(this, '_changeset', changeset);
    },
    /**
     * Iterats through all filters, by respecting ignoreGroups and specificGroups.
     *
     * @function _filtersIterator
     * @param {function} callback
     * @private
     */
    _filtersIterator(callback) {
      const specificGroups = Array.isArray(this.specificGroups) ? this.specificGroups : [];
      const ignoreGroups = Array.isArray(this.ignoreGroups) ? this.ignoreGroups : [];
      this._filters.forEach(filter => {
        if (ignoreGroups.indexOf(filter.groupKey) >= 0 || specificGroups.length >= 1 && specificGroups.indexOf(filter.groupKey) === -1) {
          return;
        }
        callback(filter);
      });
    },
    /**
     * Event for submission.
     * Returns the changeset 'changes'.
     *
     * @function onSubmit
     * @return {Object[]}
     */
    onSubmit() {},
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      inputChanged(key, value) {
        this._changeset.set(key, value);
      },
      submit() {
        this.onSubmit(this._changeset.get('changes'), ...arguments);
      }
    }
  });
});