define("@additive-apps/media/templates/components/mm-external-search/video-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HlqskIuC",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@items\",\"@isLoading\"],[\"mm-external-search__list\",[30,0,[\"videos\"]],[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"item\"]],null,[[\"@class\",\"@isSelected\",\"@onClick\"],[\"mm-external-search__list-item mb05\",[28,[37,1],[[30,2,[\"id\"]],[30,0,[\"selected\"]]],null],[28,[37,2],[[30,0],\"select\",[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"thumbnail\"]],null,[[\"@class\"],[\"mm-external-search__list-item_thumbnail\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"thumbnail\"]],[[[1,\"        \"],[10,\"img\"],[15,\"src\",[30,2,[\"thumbnail\"]]],[14,\"role\",\"presentation\"],[14,\"width\",\"112\"],[14,\"height\",\"84\"],[14,\"alt\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n    \"],[8,[30,3,[\"title\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"flex flex-column h-100\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"font-medium font-sm\"],[12],[1,\"\\n            \"],[1,[30,2,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"ui-list-item__description font-medium font-sm secondary\"],[12],[1,\"\\n            \"],[1,[30,2,[\"description\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"ui-list-item__description font-medium font-xs secondary\"],[12],[1,\"\\n          \"],[1,[30,2,[\"duration\"]]],[1,\"\\n          -\\n          \"],[1,[28,[35,4],[[30,2,[\"date\"]],\"dd.MM.yyyy\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1,2]]]]]],[\"list\",\"item\",\"listItem\"],false,[\"ui-list\",\"equal\",\"action\",\"if\",\"date-format\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-external-search/video-list.hbs",
    "isStrictMode": false
  });
});