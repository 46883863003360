define("@additive-apps/ui/services/ui-state", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    init() {
      this._super(...arguments);
      this._states = {};
    },
    register(key, state) {
      if (!this._states[key]) {
        this._states[key] = state;
      }
    },
    unregister(key) {
      delete this._states[key];
    },
    getState(key) {
      if (this._states[key]) {
        return this._states[key];
      }
      return false;
    }
  });
});