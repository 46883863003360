define("@additive-apps/ui/templates/components/ui-filter-inputs/inputs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "paicMiwy",
    "block": "[[[42,[28,[37,1],[[30,0,[\"groups\"]]],null],null,[[[1,\"  \"],[10,0],[14,0,\"mb3\"],[12],[1,\"\\n\"],[41,[30,1,[\"label\",\"type\"]],[[[1,\"      \"],[46,[28,[37,4],[\"ui-filter-inputs/group-label\"],null],null,[[\"filter\",\"initialValues\",\"onChange\"],[[30,1,[\"label\"]],[30,0,[\"initialValues\"]],[28,[37,5],[[30,0],[30,0,[\"onChange\"]]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"font-sm primary mb3\"],[12],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[42,[28,[37,6],[[28,[37,6],[[30,1,[\"filters\"]]],null]],null],null,[[[1,\"      \"],[46,[28,[37,4],[\"ui-filter-inputs/\",[30,3,[\"type\"]]],null],null,[[\"filter\",\"initialValues\",\"onChange\"],[[30,3],[30,0,[\"initialValues\"]],[28,[37,5],[[30,0],[30,0,[\"onChange\"]]],null]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"props\",\"key\",\"filter\"],false,[\"each\",\"-each-in\",\"if\",\"component\",\"concat\",\"action\",\"-track-array\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/inputs.hbs",
    "isStrictMode": false
  });
});