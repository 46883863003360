define("@additive-apps/ui/templates/components/ui-editor/mobile-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cycca1/v",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"button\"],[14,0,\"ui-editor__action pa0 font-sm font-regular secondary cpointer\"],[14,4,\"button\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"dots\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\",\"@width\"],[\"ui-editor__dropdown\",\"2\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"editorActions\"]]],null]],null],null,[[[1,\"      \"],[8,[30,2,[\"menuItem\"]],null,[[\"@onClick\"],[[30,3,[\"onClick\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"ui-editor__dropdown-item flex items-center secondary font-sm\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[[30,3,[\"icon\"]]],[[\"class\"],[\"mr1\"]]]],[1,\" \"],[1,[28,[35,4],[[28,[37,5],[\"uiEditor.actions.\",[30,3,[\"name\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"menu\",\"content\",\"editorAction\"],false,[\"ui-menu\",\"svg-jar\",\"each\",\"-track-array\",\"t\",\"concat\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-editor/mobile-actions.hbs",
    "isStrictMode": false
  });
});