define("@additive-apps/ui/components/ui-faq-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/string", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _service, _string, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @modalClassNames="ui-faq-dialog"
    @title={{this.title}}
    @subtitle={{this.subtitle}}
    @size={{this.size}}
    @onClose={{this.onClose}}
    as |modal|
  >
    <modal.header />
    <modal.content as |content|>
      <content.body>
        {{#each this._questions as |question index|}}
          <div class="font-lg pb1">
            {{question.question}}
          </div>
          <div class="font-md {{if (eq (inc index) this._questions.length) '' 'pb4'}}">
            {{question.answer}}
          </div>
        {{/each}}
      </content.body>
    </modal.content>
  </UiModal>
  */
  {
    "id": "fyL0lhv9",
    "block": "[[[8,[39,0],null,[[\"@modalClassNames\",\"@title\",\"@subtitle\",\"@size\",\"@onClose\"],[\"ui-faq-dialog\",[30,0,[\"title\"]],[30,0,[\"subtitle\"]],[30,0,[\"size\"]],[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"_questions\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"font-lg pb1\"],[12],[1,\"\\n          \"],[1,[30,3,[\"question\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[15,0,[29,[\"font-md \",[52,[28,[37,4],[[28,[37,5],[[30,4]],null],[30,0,[\"_questions\",\"length\"]]],null],\"\",\"pb4\"]]]],[12],[1,\"\\n          \"],[1,[30,3,[\"answer\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\",\"question\",\"index\"],false,[\"ui-modal\",\"each\",\"-track-array\",\"if\",\"eq\",\"inc\"]]",
    "moduleName": "@additive-apps/ui/components/ui-faq-dialog.hbs",
    "isStrictMode": false
  });
  /**
   * Component which renders an dialog with the passed questions
   *
   * ```hbs
    <UiFaqDialog
      @title="My title"
      @subtitle="My Subtitle"
      @translationPrefix="faq.settings"
      @questionKeys={{array 'inheritedSettings'}}
      @onClose={{this.hideMe}}
    />
   * ```
   *
   * @class ui-faq-dialog
   * @public
   */
  let UiFaqDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_emberArgTypes.array), _dec2 = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec3 = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec4 = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec5 = (0, _emberArgTypes.arg)(_emberArgTypes.string), _dec6 = (0, _emberArgTypes.arg)(_emberArgTypes.func), _class = class UiFaqDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * array of question keys in the translation file
       *
       * @property questionKeys
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "questionKeys", _descriptor2, this);
      /**
       * the subtitle of the dialog
       *
       * @property subtitle
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "subtitle", _descriptor3, this);
      /**
       * the size of the dialog
       *
       * @property size
       * @type {String}
       * @default 'md'
       */
      _initializerDefineProperty(this, "size", _descriptor4, this);
      /**
       * the title of the dialog
       *
       * @property title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor5, this);
      /**
       * the prefix of the questions in the translation file
       *
       * @property translationPrefix
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "translationPrefix", _descriptor6, this);
      /**
       * Action that gets triggered on close
       *
       * @type {Action}
       * @function onClose
       */
      _initializerDefineProperty(this, "onClose", _descriptor7, this);
    }
    /**
     * computed array of objects containing a question and answer
     *
     * @computed _questions
     * @return {Array}
     * @private
     */
    get _questions() {
      const {
        intl
      } = this;
      const translationPrefix = this.translationPrefix ? `${this.translationPrefix}.` : '';
      let questions = [];
      this.questionKeys.forEach(key => {
        const questionAnswerPair = {
          question: (0, _string.htmlSafe)(intl.t(`${translationPrefix}${key}.question`)),
          answer: (0, _string.htmlSafe)(intl.t(`${translationPrefix}${key}.answer`))
        };
        questions.push(questionAnswerPair);
      });
      return questions;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "questionKeys", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "subtitle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'md';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "translationPrefix", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiFaqDialog);
});