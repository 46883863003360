define("@additive-apps/configurator/templates/components/widget-config-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xpeY/okL",
    "block": "[[[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"ui-item\",0,null,[[\"class\"],[\"pl2 pr1 ma05 h2 br1\"]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/templates/components/widget-config-group.hbs",
    "isStrictMode": false
  });
});