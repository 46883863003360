define("@additive-apps/auth/services/authenticated-fetch", ["exports", "@ember/service", "@glimmer/tracking", "@ember/utils", "ember-concurrency", "fetch", "rsvp", "@ember/application", "@ember/object"], function (_exports, _service, _tracking, _utils, _emberConcurrency, _fetch, _rsvp, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10; // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  let AuthenticatedFetch = _exports.default = (_dec = (0, _object.computed)('ENV.APP.apiHeaders', 'session.data.authenticated.{token_type,access_token}'), _dec2 = (0, _emberConcurrency.task)(function (url) {
    var _this = this;
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      method: 'get'
    };
    let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    return function* () {
      return yield _this.fetch(url, options, data);
    }();
  }), _class = class AuthenticatedFetch extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "intl", _descriptor5, this);
      _initializerDefineProperty(this, "ongoingRequests", _descriptor6, this);
      _initializerDefineProperty(this, "_takingLongTimeToast", _descriptor7, this);
      _initializerDefineProperty(this, "_trackedRequestCounter", _descriptor8, this);
      _initializerDefineProperty(this, "_longRequestToastThreshold", _descriptor9, this);
      _initializerDefineProperty(this, "fetchTask", _descriptor10, this);
    }
    get ENV() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    get defaultAuthHeaders() {
      var _this$session, _this$ENV;
      const sessionData = (_this$session = this.session) === null || _this$session === void 0 || (_this$session = _this$session.data) === null || _this$session === void 0 ? void 0 : _this$session.authenticated;
      const apiHeaders = (_this$ENV = this.ENV) === null || _this$ENV === void 0 || (_this$ENV = _this$ENV.APP) === null || _this$ENV === void 0 ? void 0 : _this$ENV.apiHeaders;
      return Object.assign({}, defaultHeaders, apiHeaders, {
        Authorization: `${sessionData === null || sessionData === void 0 ? void 0 : sessionData.token_type} ${sessionData === null || sessionData === void 0 ? void 0 : sessionData.access_token}`
      });
    }
    fetch(url) {
      var _this$session2;
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        method: 'get'
      };
      let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let ignoredErrorCodes = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [422];
      if (!((_this$session2 = this.session) !== null && _this$session2 !== void 0 && _this$session2.isAuthenticated)) {
        return (0, _rsvp.reject)({
          status: 401,
          message: 'unauthenticated'
        });
      }
      options.headers = Object.assign({}, this.defaultAuthHeaders, options.headers);
      const requestId = this.startTrackingRequest(options);
      if ((0, _utils.isPresent)(data)) {
        options.body = JSON.stringify(data);
      }
      return (0, _fetch.default)(url, options).then(response => {
        if (ignoredErrorCodes.indexOf(response.status) > -1) {
          return response;
        }
        if (response.status === 404) {
          const router = this.router;
          router.transitionTo(`${router.rootURL}/not-found`);
          return;
        } else if (response.status === 403) {
          this.uiDialog.showMissingPermissions();
          return;
        } else if (response.status > 401) {
          this.uiDialog.showError();
        }
        return response;
      }).finally(() => {
        this.stopTrackingRequest(requestId, options);
      });
    }
    startTrackingRequest(options) {
      const {
        trackResponseTime = true
      } = options;
      if (!trackResponseTime) return;
      const requestId = this._trackedRequestCounter + 1;
      this.ongoingRequests.push({
        id: requestId,
        timeOfFetchStart: new Date()
      });
      (0, _emberConcurrency.timeout)(this._longRequestToastThreshold).then(() => {
        this.checkLongRunningRequests();
      });
      return requestId;
    }
    stopTrackingRequest(requestId, options) {
      const {
        trackResponseTime = true
      } = options;
      if (!trackResponseTime) return;
      this.ongoingRequests = this.ongoingRequests.filter(request => request.id !== requestId);
      this.checkLongRunningRequests();
    }
    checkLongRunningRequests() {
      const longRunningRequests = this.ongoingRequests.filter(request => {
        return Date.now() - request.timeOfFetchStart > this._longRequestToastThreshold;
      });
      if (longRunningRequests.length > 0) {
        if (!this._takingLongTimeToast) {
          this._takingLongTimeToast = this.uiToast.showToast({
            title: this.intl.t('toasts.loading.title'),
            type: 'information',
            description: this.intl.t('toasts.loading.description'),
            duration: -1
          });
        }
      } else {
        this.uiToast.destroyToast(this._takingLongTimeToast);
        this._takingLongTimeToast = null;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ongoingRequests", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_takingLongTimeToast", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_trackedRequestCounter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_longRequestToastThreshold", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      var _this$ENV2;
      return ((_this$ENV2 = this.ENV) === null || _this$ENV2 === void 0 || (_this$ENV2 = _this$ENV2.auth) === null || _this$ENV2 === void 0 ? void 0 : _this$ENV2.longRequestToastThreshold) || 5000;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "defaultAuthHeaders", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "defaultAuthHeaders"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fetchTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});