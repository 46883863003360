define("@additive-apps/ui/components/ui-control-panel-group", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-control-panel-group flex flex-column {{@class}} {{@classNames}}" ...attributes>
    {{#if this.title}}
      <div class="ui-list__title font-sm secondary flex items-center pb2">
        {{this.title}}
        {{yield (hash titleSuffix=(component "ui-blank-template"))}}
      </div>
    {{/if}}
  
    {{#if this.isLoading}}
      <UiSkeleton
        @classNames="mt1"
        @items={{this.placeholderCount}}
        @itemsPerRow={{1}}
        @cardHeight={{72}}
        @gap={{4}}
      />
    {{else if this.isError}}
      {{yield (hash error=(component "ui-blank-template"))}}
    {{else}}
      {{yield (hash panel=(component "ui-control-panel"))}}
    {{/if}}
  </div>
  */
  {
    "id": "mT0svRQf",
    "block": "[[[11,0],[16,0,[29,[\"ui-control-panel-group flex flex-column \",[30,1],\" \",[30,2]]]],[17,3],[12],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"    \"],[10,0],[14,0,\"ui-list__title font-sm secondary flex items-center pb2\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n      \"],[18,4,[[28,[37,2],null,[[\"titleSuffix\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@classNames\",\"@items\",\"@itemsPerRow\",\"@cardHeight\",\"@gap\"],[\"mt1\",[30,0,[\"placeholderCount\"]],1,72,4]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isError\"]],[[[1,\"    \"],[18,4,[[28,[37,2],null,[[\"error\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,4,[[28,[37,2],null,[[\"panel\"],[[50,\"ui-control-panel\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"ui-skeleton\"]]",
    "moduleName": "@additive-apps/ui/components/ui-control-panel-group.hbs",
    "isStrictMode": false
  });
  let UiControlPanelGroupComponent = _exports.default = (_class = class UiControlPanelGroupComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the title of the group
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * whether its loading
       *
       * @argument isLoading
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isLoading", _descriptor2, this);
      /**
       * whether an error has occurred
       *
       * @argument isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor3, this);
      /**
       * how many loading placeholders should be shown
       *
       * @argument placeholderCount
       * @type {Number}
       * @default 5
       */
      _initializerDefineProperty(this, "placeholderCount", _descriptor4, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isError", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "placeholderCount", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 5;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiControlPanelGroupComponent);
});