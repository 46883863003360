define("@additive-apps/media/components/ui-carousel/trigger", ["exports", "@ember/component", "@additive-apps/media/templates/components/ui-carousel/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Component with action to trigger navigation events (next, previous).
   *
   * @class ui-carousel/trigger
   * @yield {Action} trigger
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _trigger.default,
    tagName: '',
    /**
     * Whether its the next or previous trigger. (ENUM: next, previous)
     * @property type
     * @type {String}
     */
    type: null,
    /**
     * On trigger event
     * @function onTrigger
     */
    onTrigger() {}
  });
});