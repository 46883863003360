define("@additive-apps/ui/components/ui-onboarding", ["exports", "@ember/object/computed", "@ember/component", "@additive-apps/ui/templates/components/ui-onboarding", "@ember/runloop", "@ember/service", "@ember/object", "ember-animated/transitions/move-over"], function (_exports, _computed, _component, _uiOnboarding, _runloop, _service, _object, _moveOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * This component handles the onboarding process
   *
   * @class ui-onboarding
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _uiOnboarding.default,
    classNames: ['ui-onboarding', 'top-0', 'left-0', 'fixed', 'w-100'],
    classNameBindings: ['hasFinished:ui-onboarding--finished'],
    attributeBindings: ['theme'],
    /**
     * the views theme
     * possible values are dark and blue, the default (null)
     * implies the standard white-theme
     *
     * @computed theme
     * @default null
     */
    theme: (0, _computed.alias)('activeStep.theme'),
    /**
     * the onboarding options that are passed
     * ```js
     * { '1': [{
     *     title: String,
     *     description: String,
     *     actionLabel: String,
     *     illustration: String,
     *     theme: String,
     *     isFirstStep: Boolean,
     *     isLastStep: Boolean
     *   }]
     * }
     * ```
     *
     * @property onboarding
     * @type {Object}
     * @default null
     */
    onboarding: null,
    /**
     * the onboarding query param
     *
     * @property activeOnboarding
     * @type {String}
     * @default null
     */
    activeOnboarding: null,
    /**
     * the current onboarding step
     *
     * @property currentStep
     * @type {Number}
     * @default 0
     */
    currentStep: 0,
    /**
     * the active onboarding-step object
     *
     * @computed activeStep
     */
    activeStep: (0, _object.computed)('activeOnboarding', 'currentStep', 'onboarding', {
      get() {
        const activeOnboarding = this.activeOnboarding;
        const step = this.currentStep;
        if (activeOnboarding) {
          return this.onboarding[activeOnboarding][step];
        }
        return null;
      }
    }),
    init() {
      this._super(...arguments);
      const onboardingExists = this.activeStep;
      /*
       * Check if the passed in onboarding and the step exist
       * Don't show onboarding otherwise
       */
      if (!onboardingExists) {
        this.onFinish();
      }
    },
    /**
     * Gets called after the onboarding finished or if it's skipped
     *
     * @function onFinish
     */
    onFinish() {},
    /**
     * animation rules for the animated container
     *
     * @function _animationRules
     */
    _animationRules(_ref) {
      let {
        oldItems,
        newItems
      } = _ref;
      if (oldItems[0] < newItems[0]) {
        return _moveOver.toRight;
      } else {
        return _moveOver.toLeft;
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * increment current onboarding step
       *
       * @function next
       */
      next() {
        (0, _object.set)(this, 'animation', 'toLeft');
        const step = this.currentStep;
        (0, _object.set)(this, 'currentStep', step + 1);
      },
      /**
       * decrement current onboarding step
       *
       * @function back
       */
      back() {
        (0, _object.set)(this, 'animation', 'toRight');
        const step = this.currentStep;
        (0, _object.set)(this, 'currentStep', step - 1);
      },
      /**
       * finish the onboarding
       *
       * @function endOnboarding
       */
      endOnboarding() {
        (0, _object.set)(this, 'animation', 'fade');
        (0, _object.set)(this, 'currentStep', -1);
        (0, _runloop.later)(() => {
          if (this.isDestroying) {
            return;
          }
          (0, _object.set)(this, 'hasFinished', true);
          this.onFinish();
        }, 250);
      }
    }
  });
});