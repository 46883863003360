define("@additive-apps/jam-ember/instance-initializers/sentry", ["exports", "@sentry/ember", "ember-concurrency", "@ember/object", "@additive-apps/jam-ember/sentry/defaults"], function (_exports, Sentry, _emberConcurrency, _object, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const arrayWithDefaults = (input, defaults) => {
    if (!Array.isArray(input)) {
      return defaults;
    }
    return [...defaults, ...input];
  };
  const objectWithDefaults = (input, defaults) => {
    if (!input || !typeof input === 'object') {
      return defaults;
    }
    return Object.assign({}, defaults, input);
  };
  function initialize(applicationInstance) {
    const environmentVars = applicationInstance.resolveRegistration('config:environment');
    const config = environmentVars['additive-jam-ember'] && environmentVars['additive-jam-ember'].sentry || {};
    if (!config || config.enabled === false) {
      return;
    }
    Sentry.init({
      dsn: config.dsn,
      maxBreadcrumbs: config.maxBreadcrumbs || 50,
      integrations: [...(config.useReplays ? [new Sentry.Replay(objectWithDefaults(config.replaysConfig, _defaults.replaysConfig) || {})] : [])],
      ignoreErrors: arrayWithDefaults(config.ignoreErrors, _defaults.ignoreErrors),
      denyUrls: arrayWithDefaults(config.denyUrls, _defaults.denyUrls),
      allowUrls: arrayWithDefaults(config.allowUrls, _defaults.allowUrls),
      replaysSessionSampleRate: config.replaysSessionSampleRate || 0.0,
      replaysOnErrorSampleRate: config.replaysOnErrorSampleRate || 1.0
    });
    if (config.tags && Object.keys(config.tags).length >= 0) {
      Object.keys(config.tags).forEach(key => {
        Sentry.setTag(key, config.tags[key]);
      });
    }
    if (config.anonymous) {
      return;
    }
    const currentUser = applicationInstance.lookup('service:currentUser');
    const waitForUserTask = (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _emberConcurrency.waitForProperty)(currentUser, 'user');

        /* TODO: possible add more extra data like role */
        Sentry.setUser({
          id: currentUser.user.id,
          email: currentUser.user.email,
          username: currentUser.user.fullName
        });
      } catch (error) {}
    });
    (0, _object.defineProperty)(this, 'waitForUserTask', waitForUserTask);
    this.waitForUserTask.perform();
  }
  var _default = _exports.default = {
    name: 'additive-jam-sentry',
    initialize
  };
});