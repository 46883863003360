define("@additive-apps/media/components/mm-cropper", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-cropper", "@ember/object", "@additive-apps/media/constants"], function (_exports, _component, _mmCropper, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Medium crop component
   *
   *```hbs
   *{{mm-cropper
   *    medium=MEDIUM
   *    onDataChanged=(action "onDataChanged")
   *    onReady=(action "onReady")
   *    minSize="200x200"
   *    aspectRatio=16/9
   *}}
   *```
   * @class mm-cropper
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _mmCropper.default,
    classNames: ['mm-cropper'],
    classNameBindings: ['_isCropperLoading:is-loading'],
    /**
     * Aspect ratio for the crop-box (like 16/9 or 4/3)
     * @property aspectRatio
     * @type {Number}
     */
    aspectRatio: NaN,
    /**
     * Additional custom cropper-js options
     * @property cropperJsOptions
     * @type {Object}
     */
    cropperJsOptions: null,
    /**
     * The medium model
     * @property medium
     * @type {Object}
     */
    medium: null,
    /**
     * The minimum size of the crop
     * @property minSize
     * @type {String}
     */
    minSize: '0x0',
    /**
     * The cropper-js instance
     * @property _cropperInstance
     * @type {Object}
     * @private
     */
    _cropperInstance: null,
    /**
     * Whether cropper-js is initializing/loading
     * @property _isCropperLoading
     * @type {Boolean}
     * @private
     */
    _isCropperLoading: true,
    didInsertElement() {
      var _this$medium;
      this._super(...arguments);
      if (!((_this$medium = this.medium) !== null && _this$medium !== void 0 && _this$medium.url)) {
        throw new ErrorEvent('[mm-cropper] Medium not found');
      }
      const imgTag = this.element && this.element.querySelector('.cropper-image');
      if (!imgTag) {
        throw new ErrorEvent('[mm-cropper] HTML-Image not found');
      }
      emberAutoImportDynamic("cropperjs").catch(e => {
        throw new ErrorEvent('[mm-cropper] Cropperjs-script lazy-loading error', e);
      }).then(module => module.default).then(Cropper => {
        var _this$medium2;
        const minSize = this.minSize;
        const minSizeArray = minSize && minSize.split('x');
        const options = Object.assign({}, _constants.CROPPER_JS_DEFAULT_OPTIONS, {
          minCropBoxWidth: parseInt(minSizeArray[0]) || 0,
          minCropBoxHeight: parseInt(minSizeArray[1]) || 0,
          data: ((_this$medium2 = this.medium) === null || _this$medium2 === void 0 ? void 0 : _this$medium2.crop) || null,
          aspectRatio: this.aspectRatio || NaN
        }, this.cropperJsOptions || {}, {
          ready: () => this._onReady(),
          cropend: () => this._onCropEnd()
        });
        const cropperInstance = new Cropper(imgTag, options);
        (0, _object.set)(this, '_cropperInstance', cropperInstance);
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      const cropperInstance = this._cropperInstance;
      cropperInstance && cropperInstance.destroy();
    },
    /**
     * On crop data changed
     * @function onDataChanged
     */
    onDataChanged() {},
    /**
     * On cropper-js ready
     * @function onReady
     */
    onReady() {},
    _onCropEnd() {
      this.onDataChanged(this._cropperInstance.getData());
    },
    _onReady() {
      // trigger onCropEnd to fire onDataChanged for initial crop-data
      this._onCropEnd();
      (0, _object.set)(this, '_isCropperLoading', false);
      this.onReady();
    }
  });
});