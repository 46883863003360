define("@additive-apps/media/templates/components/mm-cropper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "lPCc4PpS",
    "block": "[[[10,\"img\"],[15,\"src\",[30,0,[\"medium\",\"url\"]]],[15,\"alt\",[30,0,[\"medium\",\"name\"]]],[14,0,\"cropper-image\"],[12],[13]],[],false,[]]",
    "moduleName": "@additive-apps/media/templates/components/mm-cropper.hbs",
    "isStrictMode": false
  });
});