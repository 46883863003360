define("@additive-apps/ui/components/ui-colored-tile", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-colored-tile", "@ember/object", "@ember/string"], function (_exports, _component, _uiColoredTile, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiColoredTile.default,
    attributeBindings: ['_styleAttribute:style'],
    classNames: ['ui-colored-tile'],
    backgroundColor: null,
    color: null,
    styles: (0, _object.computed)('backgroundColor', 'color', {
      get() {
        const color = this.color;
        const backgroundColor = this.backgroundColor;
        return Object.assign({}, color && {
          color
        }, backgroundColor && {
          'background-color': backgroundColor
        });
      }
    }),
    _styleAttribute: (0, _object.computed)('styles', {
      get() {
        const styles = this.styles;
        const formattedStyles = Object.keys(styles).map(styleKey => {
          const styleValue = styles[styleKey];
          return styleValue && `${styleKey}: ${styleValue};`;
        });
        return formattedStyles && (0, _string.htmlSafe)(formattedStyles.join(' '));
      }
    })
  });
});