define("@additive-apps/ui/templates/components/ui-popover-trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "W9Njfxfn",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@state\"],[[30,0,[\"state\"]]]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"ui-status\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-popover-trigger.hbs",
    "isStrictMode": false
  });
});