define("@additive-apps/ui/components/ui-pusher", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-pusher", "@ember/service", "@ember/object", "@ember/runloop"], function (_exports, _component, _uiPusher, _service, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC component that binds a channel + event to the pusher service and yields the data that
   * is received by the pusher service to be displayed. Optionally a DOM selector can be passed to
   * yield the content into a given element
   *
   * ```hbs
   * {{#ui-pusher channelName event destinationElementSelector as |pusher|}}
   *    {{#each pusher.data as |message|}}
   *      display the message
   *    {{/each}}
   * {{/ui-pusher}}
   * ```
   *
   * @class ui-pusher
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    uiPusher: (0, _service.inject)(),
    router: (0, _service.inject)(),
    layout: _uiPusher.default,
    tagName: '',
    /**
     * the name of the pusher channel
     *
     * @property channelName
     * @type {String}
     * @default null
     */
    channelName: null,
    /**
     * the name of the pusher event
     *
     * @property event
     * @type {String}
     * @default null
     */
    event: null,
    /**
     * optional element selector into which the content should be yielded
     *
     * @property destinationElementSelector
     * @type {String}
     * @default null
     */
    destinationElementSelector: null,
    init() {
      this._super(...arguments);
      this._onUpdate = (0, _runloop.bind)(this, this._onUpdate);
      this.onUpdate = (0, _runloop.bind)(this, this.onUpdate);

      // connect pusher
      this.uiPusher.setup().then(() => {
        if (this.channelName && this.event) {
          this.uiPusher.bindEvent(this.channelName, this.event, this._onUpdate);
          (0, _object.set)(this, '_boundEvent', this.event);
        }
      });
    },
    /**
     * the element into which the content is yielded
     *
     * @computed destinationElement
     */
    destinationElement: (0, _object.computed)('destinationElementSelector', 'router.currentRouteName', {
      get() {
        if (document && this.destinationElementSelector && this.router.currentRouteName) {
          return document.querySelector(this.destinationElementSelector);
        }
        return null;
      }
    }),
    onUpdate() {},
    _onUpdate(notification) {
      const data = this.data && [...this.data] || [];
      data.push(notification);
      (0, _object.set)(this, 'data', data);
      this.onUpdate(notification);
    }
  });
});