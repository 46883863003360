define("@additive-apps/ui/components/ui-radio-button", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-radio-button", "@additive-apps/ui/utils/keyboard-constants", "@ember/object", "@ember/runloop"], function (_exports, _component, _uiRadioButton, _keyboardConstants, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component to select one single option among a list of options
   * inside of a radio button group
   *
   * ```hbs
   * {{ui-radio-button
   *   class
   *   label
   *   value
   *   selected
   *   isDisabled
   * }}
   * ```
   *
   * @class ui-radio-button
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiRadioButton.default,
    classNames: ['ui-radio-button', 'flex', 'items-center'],
    classNameBindings: ['_isSelected:ui-radio-button--is-selected', 'isDisabled:ui-radio-button--disabled:cpointer'],
    /**
     * whether the radio button is disabled
     *
     * @property isDisabled
     * @type {Boolean}
     * @default false
     */
    isDisabled: false,
    /**
     * an optional label of the radio button
     *
     * @property label
     * @type {String}
     * @default undefined
     */
    label: undefined,
    /**
     * the currently selected value of the radio button group
     *
     * @property selected
     * @type {Any}
     * @default undefined
     */
    selected: undefined,
    /**
     * the value of the radio button
     *
     * @property value
     * @type {Any}
     * @default undefined
     */
    value: undefined,
    // we need to set the tabindex on the element that gets actually focused
    tabIndex: 0,
    /**
     * whether the radio button is selected or not
     *
     * @computed _isSelected
     * @type {Boolean}
     * @private
     */
    _isSelected: (0, _object.computed)('selected', 'value', {
      get() {
        return this.selected !== undefined && this.value !== undefined && this.selected === this.value;
      }
    }),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      const element = this.element;
      if (element) {
        this.onRadioButtonClick = (0, _runloop.bind)(this, this._onRadioButtonClick);
        element.addEventListener('click', this.onRadioButtonClick, false);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      if (this.element && !this.isDestroying) {
        this.element.removeEventListener('click', this.onRadioButtonClick, false);
      }
    },
    /**
     * handles the click event on the radio button
     * @param {Event} event
     * @function _onRadioButtonClick
     * @private
     */
    _onRadioButtonClick() {
      if (!this.isDisabled && this.value !== undefined) {
        (0, _object.set)(this, 'selected', this.value);
      }
    },
    /**
     * handles a key-press event
     *
     * @event keypress
     * @param {*} event, key event
     * @function keyPress
     */
    keyPress(event) {
      if (event.which === _keyboardConstants.BACKSPACE || event.which === _keyboardConstants.ENTER) {
        event.preventDefault();
        this._onRadioButtonClick();
      }
    }
  });
});