define("@additive-apps/ui/components/ui-popover-trigger", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-popover-trigger"], function (_exports, _component, _uiPopoverTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   *
   * ```hbs
   * {{#ui-popover-trigger}}
   *    content
   * {{/ui-popover-trigger}}
   *
   * {{ui-popover-trigger state}}
   * ```
   *
   * @class ui-popover-trigger
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiPopoverTrigger.default,
    tagName: '',
    /**
     * Defines the state of the bullet.
     * states: success, warning, error
     *
     * @property state
     * @type {String}
     * @default null
     */
    state: null
  });
});