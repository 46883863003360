define("@additive-apps/ui/components/ui-popover", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/internals", "@ember/runloop", "@ember/string", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/dom-util", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _internals, _runloop, _string, _emberArgTypes, _propTypes, _domUtil, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-popover__outer flex {{@this.classNames}}"
    ...attributes
    {{did-insert this.onInsertElement}}
    {{will-destroy this.willDestroy}}
  >
    {{#with
      (hash
        trigger=(component "ui-popover-trigger" state=this.state)
        content=(component
          "ui-blank-template" classNames=(concat (if this._isDark "white " "primary ") "font-sm") tagName="div"
        )
      ) as |pop|
    }}
      {{yield (hash trigger=pop.trigger)}}
      {{#if this._renderPopover}}
        {{#-in-element this._destinationEl}}
          <div
            class="ui-popover absolute
            {{if this.autoWidth "no-max-width"}}
             {{if this._isDark "bg-primary-blur" "bg-white shadow-2"}}
             {{if this._entering "ui-popover--entering" ""}}
             {{if this._isAboveTrigger "ui-popover--animation-top" ""}}
             {{if this.hasTableContent "ph05 no-max-width" "pa2"}}"
            id={{this._popoverId}}
            style={{this._style}}
          >
            {{yield (hash content=pop.content)}}
          </div>
        {{/-in-element}}
      {{/if}}
    {{/with}}
  </div>
  */
  {
    "id": "jZHGWa/q",
    "block": "[[[11,0],[16,0,[29,[\"ui-popover__outer flex \",[30,1]]]],[17,2],[4,[38,0],[[30,0,[\"onInsertElement\"]]],null],[4,[38,1],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n\"],[44,[[28,[37,3],null,[[\"trigger\",\"content\"],[[50,\"ui-popover-trigger\",0,null,[[\"state\"],[[30,0,[\"state\"]]]]],[50,\"ui-blank-template\",0,null,[[\"classNames\",\"tagName\"],[[28,[37,5],[[52,[30,0,[\"_isDark\"]],\"white \",\"primary \"],\"font-sm\"],null],\"div\"]]]]]]],[[[41,[30,3],[[[1,\"    \"],[18,4,[[28,[37,3],null,[[\"trigger\"],[[30,3,[\"trigger\"]]]]]]],[1,\"\\n\"],[41,[30,0,[\"_renderPopover\"]],[[[40,[[[1,\"        \"],[10,0],[15,0,[29,[\"ui-popover absolute\\n          \",[52,[30,0,[\"autoWidth\"]],\"no-max-width\"],\"\\n           \",[52,[30,0,[\"_isDark\"]],\"bg-primary-blur\",\"bg-white shadow-2\"],\"\\n           \",[52,[30,0,[\"_entering\"]],\"ui-popover--entering\",\"\"],\"\\n           \",[52,[30,0,[\"_isAboveTrigger\"]],\"ui-popover--animation-top\",\"\"],\"\\n           \",[52,[30,0,[\"hasTableContent\"]],\"ph05 no-max-width\",\"pa2\"]]]],[15,1,[30,0,[\"_popoverId\"]]],[15,5,[30,0,[\"_style\"]]],[12],[1,\"\\n          \"],[18,4,[[28,[37,3],null,[[\"content\"],[[30,3,[\"content\"]]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"_destinationEl\"]],null]],[]],null]],[]],null]],[3]]],[13]],[\"@classNames\",\"&attrs\",\"pop\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"let\",\"hash\",\"component\",\"concat\",\"if\",\"yield\",\"in-element\"]]",
    "moduleName": "@additive-apps/ui/components/ui-popover.hbs",
    "isStrictMode": false
  });
  /**
   *
   * ```hbs
   * <UiPopover
   *   @destination
   *   @state as |pop|
   * >
   *   <pop.trigger />
   *   <pop.content>
   *      content here
   *   </pop.content>
   * </UiPopover>
   * ```
   *
   * @class ui-popover
   * @public
   */
  let UiPopover = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiPopover extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Whether there should be no max-width set
       *
       * @argument autoWidth
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "autoWidth", _descriptor, this);
      /**
       * Whether the content is a table
       *
       * @argument hasTableContent
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasTableContent", _descriptor2, this);
      /**
       * Defines the state of the trigger-bullet.
       * states: success, warning, error
       *
       * @argument state
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "state", _descriptor3, this);
      /**
       * The selector of the element the popover gets rendered into.
       *
       * @argument destination
       * @type {String}
       * @default "body"
       */
      _initializerDefineProperty(this, "destination", _descriptor4, this);
      /**
       * the theme of the popover
       *
       * @argument theme
       * @type {String}
       * @default "default"
       */
      _initializerDefineProperty(this, "theme", _descriptor5, this);
      /**
       * Bounding Box of the element (trigger)
       *
       * @property _boundingBox
       * @type {Object}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_boundingBox", _descriptor6, this);
      /**
       * The wrapper where the popover is rendered into
       *
       * @property _destinationEl
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_destinationEl", _descriptor7, this);
      /**
       * Whether the popover is entering
       *
       * @property _entering
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_entering", _descriptor8, this);
      /**
       * The main HTML element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor9, this);
      /**
       * Whether it is above the trigger
       *
       * @property _isAboveTrigger
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isAboveTrigger", _descriptor10, this);
      /**
       * The id of the popover
       *
       * @property _popoverId
       * @type {String}
       * @default 'popover-{id}'
       * @private
       */
      _defineProperty(this, "_popoverId", `popover-${(0, _internals.guidFor)(this)}`);
      /**
       * Height of the popover
       *
       * @property _popoverHeight
       * @type {Integer}
       * @default 0
       * @private
       */
      _defineProperty(this, "_popoverHeight", 0);
      /**
       * Width of the Popover
       *
       * @property _popoverWidth
       * @type {Integer}
       * @default 0
       * @private
       */
      _defineProperty(this, "_popoverWidth", 0);
      /**
       * Renders the popover into the destination
       *
       * @property _renderPopover
       * @type {Boolean}
       * @default true
       * @private
       */
      _initializerDefineProperty(this, "_renderPopover", _descriptor11, this);
      /**
       * The style of the popover
       *
       * @property _style
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_style", _descriptor12, this);
      /**
       * Window Height
       *
       * @property _windowHeight
       * @type {Integer}
       * @default 0
       * @private
       */
      _initializerDefineProperty(this, "_windowHeight", _descriptor13, this);
      /**
       * Window Width
       *
       * @property _windowWidth
       * @type {Integer}
       * @default 0
       * @private
       */
      _initializerDefineProperty(this, "_windowWidth", _descriptor14, this);
    }
    /**
     * Whether the theme is dark
     *
     * @computed _isDark
     * @type {Boolean}
     * @default true
     * @private
     */
    get _isDark() {
      return this.theme === 'dark';
    }

    /**
     * Inverts height and width variables on orientation change.
     *
     * @function changeOrientation
     */
    changeOrientation() {
      const width = this._windowWidth;
      const height = this._windowHeight;
      this._windowWidth = height;
      this._windowHeight = width;
    }
    setWindowSize() {
      this._windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this._windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    /**
     * Calculates and sets absolute position of popover.
     *
     * @function setPosition
     */
    setPosition() {
      const MARGIN = 12;
      const TRIGGER_HEIGHT = 24;
      const boundingBox = this._element.getBoundingClientRect();
      const windowHeight = this._windowHeight;
      const windowWidth = this._windowWidth;
      const scroll = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
      let top = boundingBox.bottom + scroll + MARGIN;
      let left = boundingBox.left - MARGIN;

      //positions popover above trigger in case it would overflow at the bottom of the screen
      const popoverHeight = this._popoverHeight;
      if (top + popoverHeight > windowHeight + scroll) {
        top = top - popoverHeight - TRIGGER_HEIGHT - 2 * MARGIN;
        this._isAboveTrigger = true;
      } else {
        this._isAboveTrigger = false;
      }
      //moves the popover to the left in case it would overflow on the right side of the screen
      const popoverWidth = this._popoverWidth;
      if (left + popoverWidth > windowWidth) {
        left = windowWidth - popoverWidth - MARGIN;
      }
      this._style = (0, _string.htmlSafe)(`top: ${top}px; left: ${left}px;`);
    }
    onTouch() {
      // remove popover and eventlistener
      this.closePopover();
      if (document) {
        document.removeEventListener('touchstart', this._touchListener, false);
      }
    }
    async touchStart() {
      // display popover and add eventlistener to handle touch outside
      if (document && !this._renderPopover) {
        this._renderPopover = true;
        this.setPosition();
        await (0, _domUtil.nextTick)();
        this._entering = true;
        document.addEventListener('touchstart', this._touchListener, false);
        window.addEventListener('wheel', this._closePopover, false);
      }
    }
    async mouseEnter() {
      if (!this._renderPopover) {
        this._renderPopover = true;
        this.setPosition();
        await (0, _domUtil.nextTick)();
        this._entering = true;
        window.addEventListener('wheel', this._closePopover, false);
      }
    }
    mouseLeave() {
      this.closePopover();
    }
    resizeWindow() {
      this.setWindowSize();
    }
    onClick(e) {
      // close popover if click is outside
      if (this._element && !this._element.contains(e.target)) {
        this.closePopover();
      }
    }
    closePopover() {
      this._renderPopover = false;
      this._entering = false;
      this._isAboveTrigger = false;
      !this.isDestroying && window.removeEventListener('wheel', this._closePopover, false);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.isDestroying) return;
      if (this._element) {
        this._element.removeEventListener('mouseenter', this.mouseEnter, false);
        this._element.removeEventListener('touchstart', this.touchStart, false);
        this._element.removeEventListener('mouseleave', this.mouseLeave, false);
      }

      //remove event listeners
      if (window && document) {
        window.removeEventListener('orientationchange', this._orientationListener, false);
        window.removeEventListener('wheel', this._closePopover, false);
        window.removeEventListener('resize', this.resizeWindow, false);
        document.removeEventListener('click', this._closePopover, false);
        document.removeEventListener('touchstart', this._touchListener, false);
      }
    }
    async onInsertElement(element) {
      this._element = element;
      this._destinationEl = document.querySelector(this.destination);
      this._boundingBox = element.getBoundingClientRect();
      this.setWindowSize();

      // height and width of popovers are read and then the popover is removed from the DOM again
      await (0, _domUtil.nextTick)();
      if (this.isDestroying) {
        return;
      }
      const popoverElement = document.querySelector(`#${this._popoverId}`);
      if (!popoverElement) {
        return;
      }
      const popoverBoundingBox = popoverElement.getBoundingClientRect();
      this._popoverHeight = popoverBoundingBox.height;
      this._popoverWidth = popoverBoundingBox.width;

      //bind orientation and scroll listeners
      if (window && document) {
        this._orientationListener = (0, _runloop.bind)(this, this.changeOrientation);
        this._closePopover = (0, _runloop.bind)(this, this.closePopover);
        this._touchListener = (0, _runloop.bind)(this, this.onTouch);
        this._clickListener = (0, _runloop.bind)(this, this.onClick);
        this.mouseEnter = (0, _runloop.bind)(this, this.mouseEnter);
        this.touchStart = (0, _runloop.bind)(this, this.touchStart);
        this.mouseLeave = (0, _runloop.bind)(this, this.mouseLeave);
        this.resizeWindow = (0, _runloop.bind)(this, this.resizeWindow);
        element.addEventListener('mouseenter', this.mouseEnter, false);
        element.addEventListener('touchstart', this.touchStart, false);
        element.addEventListener('mouseleave', this.mouseLeave, false);
        window.addEventListener('resize', this.resizeWindow, false);
        window.addEventListener('orientationchange', this._orientationListener, false);
        document.addEventListener('click', this._clickListener, false);
        document.addEventListener('touchstart', this._touchListener, false);
      }
      this._renderPopover = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "autoWidth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasTableContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "destination", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'body';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'default';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_boundingBox", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_destinationEl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_entering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_isAboveTrigger", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_renderPopover", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_style", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_windowHeight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_windowWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertElement"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiPopover);
});