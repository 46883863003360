define("@additive-apps/ui/components/ui-editor/action", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-editor/action"], function (_exports, _component, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * the action button for the ui-editor
   *
   * @class ui-editor/action
   * @module ui-editor
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _action.default,
    //eslint-disable-next-line ember/require-tagless-components
    tagName: 'button',
    classNames: ['ui-editor__action', 'flex', 'ph05', 'font-sm', 'font-regular', 'secondary', 'cpointer'],
    attributeBindings: ['commandName:command', 'value'],
    /**
     * the name of the command that is set as an attribute
     *
     * @property commandName
     * @type {String}
     * @default null
     */
    commandName: null,
    /**
     * the parameter for the formatBlock command
     *
     * @property value
     * @type {String}
     * @default ''
     */
    value: '',
    onClick() {},
    click() {
      this.onClick();
    }
  });
});