define("@additive-apps/ui/components/ui-form-message", ["exports", "@ember/component", "@ember/object", "@ember/service", "@additive-apps/ui/templates/components/ui-form-message"], function (_exports, _component, _object, _service, _uiFormMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    layout: _uiFormMessage.default,
    classNames: ['ui-form-message', 'flex justify-between', 'font-xs', 'secondary'],
    classNameBindings: ['state'],
    state: null,
    /**
     * the message
     *
     * @argument value
     * @type {String}
     * @default null
     */
    value: null,
    /**
     * the char count
     *
     * @argument charCount
     * @type {Number}
     * @default 0
     */
    charCount: 0,
    /**
     * if the char count should always be displayed
     *
     * @argument showCount
     * @type {boolean}
     * @default false
     */
    showCount: false,
    /**
     * the max length
     *
     * @argument maxlength
     * @type {Number}
     * @default null
     */
    maxlength: null,
    /**
     * the recommended length
     *
     * @argument recommendedLength
     * @type {Number}
     * @default null
     */
    recommendedLength: null,
    /**
     * computed string containing the char count and the limit
     *
     * @computed computedCharCount
     * @type {String}
     */
    computedCharCount: (0, _object.computed)('charCount', 'maxlength', 'recommendedLength', {
      get() {
        if (this.showCount) {
          return this.intl.t('uiFormMessage.characters', {
            charCount: this.charCount
          });
        }
        if (this.maxlength) {
          return `${this.charCount}/${this.maxlength}`;
        } else if (this.recommendedLength && this.charCount > this.recommendedLength) {
          return `${this.charCount}/${this.recommendedLength}`;
        }
        return null;
      }
    })
  });
});