define("@additive-apps/ui/components/ui-input-item", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-input-item", "@ember/object", "rsvp"], function (_exports, _component, _uiInputItem, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Input-implementation of a simple ui-item.
   *
   * On click a confirmation-dialog with only one text-field gets opened.
   *
   * ```hbs
   * {{#ui-item title description isSelected as |item|}}
   *   {{item.svg}}
   *   {{item.popover}}
   *   {{item.checkbox}}
   * {{/ui-item}}
   * ```
   *
   * @class ui-input-item
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _uiInputItem.default,
    tagName: '',
    /**
     * the initial value the input gets set to
     *
     * @property value
     * @type {any}
     * @default undefined
     */
    value: undefined,
    /**
     * wether onConfirm is a promise
     *
     * @property isPromise
     * @type {Boolean}
     * @default false
     */
    isPromise: false,
    /**
     * whether the action button in the dialog is disabled
     *
     * @property isActionDisabled
     * @type {Boolean}
     * @default false
     */
    isActionDisabled: false,
    /**
     * the actual value the input sets.
     * Should not be accessed or used as part of the public-API.
     *
     * The property is passed to the onConfirm function as first-paramer
     *
     * @property _inputValue
     * @type {any}
     * @private
     */
    _inputValue: undefined,
    onConfirm() {},
    onClose() {},
    init() {
      this._super(...arguments);
      const initialValue = this.value;
      /*
       * The passed value gets set only once to avoid
       * two-way databinding issues.
       * The dev whom uses the component should handle how the changes
       * behave in each diff. case.
       */
      if (initialValue) {
        (0, _object.set)(this, '_inputValue', initialValue);
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      confirmAction(event) {
        const inputValue = this._inputValue;
        if (this.isPromise) {
          return this.onConfirm(inputValue, event).then(() => this.isDestroying ? null : (0, _object.set)(this, 'isInputDialog', false)).catch(error => (0, _rsvp.reject)(error));
        } else {
          this.onConfirm(inputValue, event);
          this.isDestroying ? null : (0, _object.set)(this, 'isInputDialog', false);
        }
      },
      closeAction() {
        const inputValue = this._inputValue;
        this.onClose(inputValue);
        (0, _object.set)(this, 'isInputDialog', false);
      }
    }
  });
});