define("@additive-apps/ui/components/ui-slider", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "@ember/object", "@ember/template", "@glimmer/tracking", "@ember/runloop", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _object, _template, _tracking, _runloop, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-slider flex flex-grow-1 items-center justify-end"
    {{did-insert this.onInsert}}
    {{will-destroy this.willDestroy}}
  >
    <div
      class="ui-slider--track-clickable relative br2 mr1 flex items-center
        {{unless @isReadOnly 'cpointer'}}"
      style={{this.defaultTrackStyle}}
    >
      <div class="ui-slider--track-default relative w-100 bg-secondary--10">
        <div class="ui-slider--track absolute br2 bg-blue" style={{this.trackStyle}}></div>
        <div class="ui-slider--thumb absolute cpointer bg-white" style={{this.thumbStyle}}></div>
      </div>
    </div>
    <UiInput
      @classNames="ui-slider--input ml05"
      @isPlain={{true}}
      @isReadOnly={{@isReadOnly}}
      @value={{this._value}}
      @type="number"
      @step={{this.step}}
      @minValue={{this.minValue}}
      @maxValue={{this.maxValue}}
      @defaultValue={{this.defaultValue}}
      @onChange={{this.onInputChange}}
    />
  </div>
  */
  {
    "id": "qtzodRIr",
    "block": "[[[11,0],[24,0,\"ui-slider flex flex-grow-1 items-center justify-end\"],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[4,[38,1],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"ui-slider--track-clickable relative br2 mr1 flex items-center\\n      \",[52,[51,[30,1]],\"cpointer\"]]]],[15,5,[30,0,[\"defaultTrackStyle\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui-slider--track-default relative w-100 bg-secondary--10\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"ui-slider--track absolute br2 bg-blue\"],[15,5,[30,0,[\"trackStyle\"]]],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"ui-slider--thumb absolute cpointer bg-white\"],[15,5,[30,0,[\"thumbStyle\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],null,[[\"@classNames\",\"@isPlain\",\"@isReadOnly\",\"@value\",\"@type\",\"@step\",\"@minValue\",\"@maxValue\",\"@defaultValue\",\"@onChange\"],[\"ui-slider--input ml05\",true,[30,1],[30,0,[\"_value\"]],\"number\",[30,0,[\"step\"]],[30,0,[\"minValue\"]],[30,0,[\"maxValue\"]],[30,0,[\"defaultValue\"]],[30,0,[\"onInputChange\"]]]],null],[1,\"\\n\"],[13]],[\"@isReadOnly\"],false,[\"did-insert\",\"will-destroy\",\"unless\",\"ui-input\"]]",
    "moduleName": "@additive-apps/ui/components/ui-slider.hbs",
    "isStrictMode": false
  });
  let UiSliderComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.number), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.number), _dec4 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec5 = (0, _emberArgTypes.arg)(_propTypes.number), _dec6 = (0, _emberArgTypes.arg)(_propTypes.number), _dec7 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiSliderComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the min. value of the slider
       *
       * @argument minValue
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "minValue", _descriptor, this);
      /**
       * the max. value of the slider
       *
       * @argument maxValue
       * @type {Number}
       * @default 100
       */
      _initializerDefineProperty(this, "maxValue", _descriptor2, this);
      /**
       * the default value of the slider
       *
       * @argument defaultValue
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "defaultValue", _descriptor3, this);
      /**
       * whether the component is read-only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      /**
       * the width of the slider
       *
       * @argument width
       * @type {Number}
       * @default null
       */
      _initializerDefineProperty(this, "width", _descriptor5, this);
      /**
       * the step size of the number input field
       *
       * @argument step
       * @type {Number}
       * @default 10
       */
      _initializerDefineProperty(this, "step", _descriptor6, this);
      /**
       * whether onChange should be called on every move
       *
       * @argument updateOnMove
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "updateOnMove", _descriptor7, this);
      /**
       * the initial value of the slider
       *
       * @property value
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "value", _descriptor8, this);
      /**
       * the current value of the slider
       *
       * @property value
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "_value", _descriptor9, this);
      /**
       * the current position on the slider track
       *
       * @property _position
       * @type {Number}
       * @default 0
       */
      _initializerDefineProperty(this, "_position", _descriptor10, this);
      /**
       * the element of this component
       *
       * @property _element
       * @type {HTMLElement}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor11, this);
      /**
       * whether the slider thumb is currently moved
       *
       * @property _isMoving
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isMoving", _descriptor12, this);
      /**
       * the function triggered on value change
       *
       * @function onChange
       */
      _initializerDefineProperty(this, "onChange", _descriptor13, this);
      this._value = this.value;
      if (this.isReadOnly) {
        return;
      }
      this._onClick = (0, _runloop.bind)(this, this._onClick);
      this._onMoveStart = (0, _runloop.bind)(this, this._onMoveStart);
      this._onMove = (0, _runloop.bind)(this, this._onMove);
      this._onMoveEnd = (0, _runloop.bind)(this, this._onMoveEnd);
    }

    /**
     * the current position on the slider track
     *
     * @computed trackPosition
     * @type {function}
     */
    trackPosition() {
      if (!this._element) {
        return;
      }
      const sliderTrack = this._element.querySelector('.ui-slider--track-clickable');
      const trackWidth = sliderTrack.getBoundingClientRect().width;
      const thumbWidth = 12;

      // Calculate slider range
      const range = this.maxValue - this.minValue;

      // Calculate position of slider thumb
      if (this.minValue < 0) {
        return trackWidth / 2 + trackWidth / range * (this._value - thumbWidth / 2);
      } else {
        return trackWidth / range * (this._value - thumbWidth / 2);
      }
    }
    get thumbStyle() {
      return (0, _template.htmlSafe)(`left: ${this.trackPosition()}px`);
    }
    get defaultTrackStyle() {
      if (this.width) {
        return (0, _template.htmlSafe)(`width: ${this.width}px`);
      }
      return (0, _template.htmlSafe)(`width: ${this.maxValue - this.minValue}px`);
    }
    get trackStyle() {
      if (!this._element) {
        return null;
      }
      const sliderTrack = this._element.querySelector('.ui-slider--track-clickable');
      const trackWidth = sliderTrack.getBoundingClientRect().width;
      const range = this.maxValue - this.minValue;

      // Get position of defined defaultValue
      const defaultPosition = Math.abs(this.minValue - this.defaultValue) * (trackWidth / range);
      if (this.minValue < 0) {
        if (this._value < 0) {
          return (0, _template.htmlSafe)(`left: ${this.trackPosition()}px; right: ${defaultPosition}px`);
        }
        return (0, _template.htmlSafe)(`left: ${defaultPosition}px; right: ${trackWidth - this.trackPosition()}px`);
      }
      return (0, _template.htmlSafe)(`left: 0px; right: ${trackWidth - this.trackPosition()}px`);
    }
    _onMoveStart() {
      this._isMoving = true;
    }
    _onMove(event) {
      if (!this._isMoving) {
        return;
      }
      if (event.type === 'touchmove') {
        this._value = this._calculateThumbValue(event.touches[0].clientX);
      } else {
        const sliderTrack = this._element.querySelector('.ui-slider--track-clickable');
        const trackWidth = sliderTrack.getBoundingClientRect().width;
        const range = this.maxValue - this.minValue;
        this._value += event.movementX / (trackWidth / range);
      }
      if (this._value > this.maxValue) {
        this._value = this.maxValue;
      } else if (this._value < this.minValue) {
        this._value = this.minValue;
      }
      if (this.updateOnMove) {
        this.onChange(this._value);
      }
    }
    _calculateThumbValue(position) {
      const sliderTrack = this._element.querySelector('.ui-slider--track-default');
      // Calculate position relative to the slider track
      const relativePosition = Math.round(position) - Math.round(sliderTrack.getBoundingClientRect().left);
      const trackWidth = sliderTrack.getBoundingClientRect().width;
      const range = this.maxValue - this.minValue;
      const defaultPosition = Math.abs(this.minValue - this.defaultValue) * (trackWidth / range);
      let value = (relativePosition - defaultPosition) * (range / trackWidth);
      if (value > this.maxValue) {
        return this.maxValue;
      } else if (value < this.minValue) {
        return this.minValue;
      }
      return value;
    }
    _onMoveEnd(event) {
      // If "_isMoving" is false, then it is treated as a single touch (like onclick)
      if (this._isMoving == false && event.type == 'touchend') {
        // Update the slider value
        this._value = this._calculateThumbValue(event.changedTouches[0].clientX);
        this.onChange(this._value);
      }
      if (!this.updateOnMove && this._isMoving) {
        this.onChange(this._value);
      }
      this._isMoving = false;
    }
    _onClick(event) {
      event.preventDefault();
      if (this._isMoving) {
        return;
      }

      // Update the slider value
      this._value = this._calculateThumbValue(event.pageX);
      this.onChange(this._value);
    }
    onInputChange(val) {
      const value = val ? val : 0;
      this.onChange(value);
    }
    onInsert(element) {
      this._element = element;
      if (this.isReadOnly) {
        return;
      }
      const sliderThumb = this._element.querySelector('.ui-slider--thumb');
      sliderThumb.addEventListener('mousedown', this._onMoveStart, false);
      sliderThumb.addEventListener('touchstart', this._onMovestart, false);
      window.addEventListener('mousemove', this._onMove, {
        passive: true
      });
      window.addEventListener('touchmove', this._onMove, {
        passive: true
      });
      window.addEventListener('mouseup', this._onMoveEnd, false);
      const defaultTrack = this._element.querySelector('.ui-slider--track-clickable');
      defaultTrack.addEventListener('mousedown', this._onClick, false);
      defaultTrack.addEventListener('touchend', this._onMoveEnd, false);
    }
    willDestroy() {
      var _this$_element, _window, _window2, _window3, _window4, _this$_element2;
      super.willDestroy(...arguments);
      if (this.isReadOnly) {
        return;
      }
      const sliderThumb = (_this$_element = this._element) === null || _this$_element === void 0 ? void 0 : _this$_element.querySelector('.ui-slider--thumb');
      sliderThumb === null || sliderThumb === void 0 || sliderThumb.removeEventListener('mousedown', this._onMoveStart, false);
      sliderThumb === null || sliderThumb === void 0 || sliderThumb.removeEventListener('touchstart', this._onMovestart, false);
      (_window = window) === null || _window === void 0 || _window.removeEventListener('mousemove', this._onMove, {
        passive: true
      });
      (_window2 = window) === null || _window2 === void 0 || _window2.removeEventListener('touchmove', this._onMove, {
        passive: true
      });
      (_window3 = window) === null || _window3 === void 0 || _window3.removeEventListener('mouseup', this._onMoveEnd, false);
      (_window4 = window) === null || _window4 === void 0 || _window4.removeEventListener('touchend', this._onMoveEnd, false);
      const defaultTrack = (_this$_element2 = this._element) === null || _this$_element2 === void 0 ? void 0 : _this$_element2.querySelector('.ui-slider--track-clickable');
      defaultTrack === null || defaultTrack === void 0 || defaultTrack.removeEventListener('mousedown', this._onClick, false);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "minValue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "maxValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 100;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "defaultValue", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "step", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 10;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updateOnMove", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "value", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_position", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isMoving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInputChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInputChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiSliderComponent);
});