define("@additive-apps/media/services/multimedia", ["exports", "@ember/service", "@ember/runloop", "@glimmer/tracking", "@additive-apps/media/utils/medium"], function (_exports, _service, _runloop, _tracking, _medium) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_SELECTION_OPTIONS = {
    isAddable: true,
    isEnabled: true,
    maxCount: null,
    type: null,
    minHeight: null,
    maxHeight: null,
    minWidth: null,
    maxWidth: null,
    minSize: null,
    maxSize: null,
    warningMessage: ''
  };

  /**
   *  Multimedia service to communicate between multimedia-engine and host-app
   *
   *  @class multimedia
   */
  let MultimediaService = _exports.default = (_class = class MultimediaService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      /**
       * The currentFolder model
       * @property currentFolder
       * @type {Medium}
       */
      _initializerDefineProperty(this, "currentFolder", _descriptor2, this);
      /**
       * the object containing the current selection
       *
       * @property currentSelection
       * @type {Array}
       * @default []
       */
      _initializerDefineProperty(this, "currentSelection", _descriptor3, this);
      /**
       * object that stores the callbacks for different routes
       *
       * @property callbacks
       * @type {Objects}
       * @default {}
       */
      _initializerDefineProperty(this, "callbacks", _descriptor4, this);
      /**
       * the current key under which the callbacks are stored
       *
       * @property currentCallbackKey
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "currentCallbackKey", _descriptor5, this);
      /**
       * Whether the engine is currently in filter-mode
       *
       * @property isFilterMode
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFilterMode", _descriptor6, this);
      /**
       * the options for the selection
       *
       * @property selectionOptions
       * @type {Object}
       * @default DEFAULT_SELECTION_OPTIONS
       */
      _initializerDefineProperty(this, "selectionOptions", _descriptor7, this);
      this.selectMedia = (0, _runloop.bind)(this, this.selectMedia);
      this.selectionChange = (0, _runloop.bind)(this, this.selectionChange);
      this.closeMultimedia = (0, _runloop.bind)(this, this.closeMultimedia);
      this.setCallbacks = (0, _runloop.bind)(this, this.setCallbacks);
      this.resetCallbacks = (0, _runloop.bind)(this, this.resetCallbacks);
      this.executeCallback = (0, _runloop.bind)(this, this.executeCallback);
      this.selectionFilter = (0, _runloop.bind)(this, this.selectionFilter);
    }

    /**
     * sets the options for the selection
     *
     * @function setSelectionOptions
     * @param {Object} options
     * @param {Boolean} options.isAddable, whether the selection is addable
     * @param {Boolean} options.isEnabled, whether the selection should be enabled
     * @param {Number} options.maxCount, the maximal number of selectable elements
     * @param {String} options.type, the type of the medium
     * @param {Number} options.minHeight, the minimal height of the medium
     * @param {Number} options.maxHeight, the maximal height of the medium
     * @param {Number} options.minWidth, the minimal width of the medium
     * @param {Number} options.maxWidth, the maximal width of the medium
     * @param {Number} options.recommendedWidth, the recommended width
     * @param {Number} options.recommendedHeight, the recommended height
     * @param {Number} options.minSize, the minimum size of the medium
     * @param {Number} options.maxSize, he maximum size of the medium
     * @param {String} options.warningMessage, the warning message that is displayed in the popover of the medium
     * @param {Array} options.mimeTypes, an array containing the accepted mime types
     *
     */
    setSelectionOptions() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.selectionOptions = Object.assign({}, DEFAULT_SELECTION_OPTIONS, options);
    }

    /**
     * resets the selection options to the default options
     *
     * @function resetSelectionOptions
     */
    resetSelectionOptions() {
      this.selectionOptions = DEFAULT_SELECTION_OPTIONS;
    }

    /**
     * filter function that is passed to the selection container
     *
     * @function selectionFilter
     * @param {Medium} medium
     */
    selectionFilter(medium) {
      const options = this.selectionOptions;
      if (options.mimeTypes && options.mimeTypes.indexOf(medium.mimeType) < 0) return true;
      if (options.minHeight && options.minHeight > medium.height) return true;
      if (options.maxHeight && options.maxHeight < medium.height) return true;
      if (options.minWidth && options.minWidth > medium.width) return true;
      if (options.maxWidth && options.maxWidth < medium.width) return true;
      if (options.minSize && options.minSize > medium.size) return true;
      if (options.maxSize && options.maxSize < medium.size) return true;
      if (options.type && Array.isArray(options.type)) {
        if (options.type.indexOf(medium.type) < 0) return true;
      } else if (options.type) {
        if (options.type !== medium.type) return true;
      }
      return false;
    }

    /**
     * sets the callbacks for a specific route. possible callbacks:
     *  - onAddSelectedMedia
     *  - onCloseMultimedia
     *
     * @function setCallbacks
     * @param {Object} callbacks, object containing functions
     * @param {String} key, the key under which the callbacks are stored
     */
    setCallbacks(callbacks, key) {
      const currentCallbacks = this.callbacks[key] || {};
      this.callbacks[key] = Object.assign(currentCallbacks, callbacks);
      this.currentCallbackKey = key;
    }

    /**
     * resets the callbacks of a specific route
     *
     * @function resetCallbacks
     * @param {String} key, the key under which the callbacks are stored
     */
    resetCallbacks(key) {
      this.callbacks[key] = null;
      this.currentCallbackKey = null;
    }

    /**
     * executes a certain callback for the current route
     *
     * @function executeCallback
     * @param {String} name, the name of the callback
     * @param {Any} params the params of the callback
     */
    executeCallback(name) {
      const currentCallbackKey = this.currentCallbackKey;
      if (!currentCallbackKey) {
        return;
      }
      const callbacks = this.callbacks;
      for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        params[_key - 1] = arguments[_key];
      }
      callbacks && callbacks[currentCallbackKey] && callbacks[currentCallbackKey][name] && callbacks[currentCallbackKey][name](...params);
    }

    /**
     * updates the current selection
     *
     * @function selectionChange
     * @param {Array} selection
     */
    selectionChange(selection) {
      /* Prevent multiple modify in a single render */
      (0, _runloop.next)(this, () => {
        if (this.isDestroying) {
          return;
        }
        this.currentSelection = selection;
      });
    }

    /**
     * returns all selected media and clears the selection
     *
     * @function selectMedia
     */
    selectMedia() {
      let resetSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const mediumItems = [];
      const resourceItems = [];
      this.currentSelection.forEach(key => {
        const item = this.store.peekRecord('medium', key);
        if (item) {
          mediumItems.push(item);
          resourceItems.push((0, _medium.mediumToResource)(item));
        }
      });
      if (mediumItems.length > 0 && resourceItems.length > 0) {
        this.executeCallback('onAddSelectedMedia', mediumItems, resourceItems);
      }
      if (resetSelection) {
        this.currentSelection = [];
      }
    }

    /**
     * returns all selected folders and clears the selection
     *
     * @function selectMedia
     */
    selectFolders() {
      let resetSelection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const folderItems = [];
      this.currentSelection.forEach(key => {
        const item = this.store.peekRecord('folder', key);
        if (item) {
          folderItems.push(item);
        }
      });
      if (folderItems.length > 0) {
        this.executeCallback('onAddSelectedFolders', folderItems);
      }
      if (resetSelection) {
        this.currentSelection = [];
      }
    }

    /**
     * closes the multimedia overlay and resets the selection
     *
     * @function closeMultimedia
     */
    closeMultimedia() {
      this.currentSelection = [];
      this.executeCallback('onCloseMultimedia');
    }

    /**
     * checks whether all the items in the selection fit the recommended size
     *
     * @function checkRecommendedSize
     * @return {Boolean} true if all items fit the recommended size
     */
    checkRecommendedSize() {
      const selection = this.currentSelection;
      const options = this.selectionOptions;
      if (!options.recommendedWidth && !options.recommendedHeight) return true;
      for (let key of selection) {
        const medium = this.store.peekRecord('medium', key);
        if (medium.type === 'image' && (options.recommendedWidth && options.recommendedWidth > medium.width || options.recommendedHeight && options.recommendedHeight > medium.height)) {
          return false;
        }
      }
      return true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentFolder", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentSelection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "callbacks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentCallbackKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isFilterMode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectionOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return DEFAULT_SELECTION_OPTIONS;
    }
  }), _class);
});