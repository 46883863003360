define("@additive-apps/ui/components/ui-form-control", ["exports", "@ember/component", "@ember/object", "@additive-apps/ui/templates/components/ui-form-control"], function (_exports, _component, _object, _uiFormControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiFormControl.default,
    classNames: 'ui-form-control',
    classNameBindings: ['computedState'],
    /**
     * the id of the input
     *
     * @argument inputElementId
     * @type {String}
     * @default null
     */
    inputElementId: null,
    /**
     * the label of the input
     *
     * @argument label
     * @type {String}
     * @default null
     */
    label: null,
    /**
     * the help text
     *
     * @argument helperText
     * @type {String}
     * @default null
     */
    helperText: null,
    /**
     * if the char count should always be displayed
     *
     * @argument showCount
     * @type {boolean}
     * @default false
     */
    showCount: false,
    /**
     * the message below the input
     *
     * @argument message
     * @type {String}
     * @default null
     */
    message: null,
    /**
     * the state of the form control
     *
     * @argument state
     * @type {String}
     * @default null
     */
    state: null,
    /**
     * the current char count
     *
     * @argument charCount
     * @type {Number}
     * @default null
     */
    charCount: null,
    /**
     * the max length
     *
     * @argument maxlength
     * @type {Number}
     * @default null
     */
    maxlength: null,
    /**
     * the recommended length
     *
     * @argument recommendedLength
     * @type {Number}
     * @default null
     */
    recommendedLength: null,
    /**
     * the warning shown when the recommended length is exceeded
     *
     * @property recommendedWarning
     * @type {String}
     * @default null
     */
    recommendedWarning: null,
    /**
     * @computed computedState
     * @type {String}
     */
    computedState: (0, _object.computed)('state', 'charCount', 'maxlength', 'recommendedLength', {
      get() {
        if (this.state) {
          return this.state;
        }
        if (this.charCount && this.maxlength && this.charCount > this.maxlength) {
          return 'error';
        }
        if (this.charCount && this.recommendedLength && this.charCount > this.recommendedLength) {
          return 'warning';
        }
        return null;
      }
    }),
    /**
     * whether the warning should be shown
     *
     * @computed showRecommendedLengthWarning
     * @type {Boolean}
     */
    showRecommendedLengthWarning: (0, _object.computed)('charCount', 'recommendedLength', {
      get() {
        if (this.charCount && this.recommendedLength && this.charCount > this.recommendedLength) {
          return true;
        }
        return false;
      }
    }),
    /**
     * on help callback
     *
     * @argument onHelp
     * @type {Function}
     */
    onHelp() {}
  });
});