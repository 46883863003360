define("@additive-apps/ui/components/ui-toolbar", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-toolbar"], function (_exports, _component, _uiToolbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiToolbar.default,
    classNames: ['ui-toolbar', 'flex', 'flex-row', 'items-center'],
    classNameBindings: ['isTransparent:ui-toolbar--transparent', 'isSecondary:ui-toolbar--secondary']
  });
});