define("@additive-apps/ui/components/ui-editor/action-bar", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-editor/action-bar", "@ember/object", "@ember/service", "@ember/template", "ember-concurrency", "@ember/runloop", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/utils/editor-util", "@additive-apps/ui/validators/editor-links"], function (_exports, _component, _actionBar, _object, _service, _template, _emberConcurrency, _runloop, _emberChangeset, _emberChangesetValidations, _validators, _domUtil, _editorUtil, _editorLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components

  const WEBLINK_TYPE = 'weblink';
  const EMAIL_TYPE = 'email';
  const PHONE_TYPE = 'tel';
  const DEFAULT_STICKY_OFFSET = 56;
  const ACTION_CLASS = '.ui-editor__action';
  const ACTIONS = [{
    isSpacer: true,
    isLight: true
  }, {
    icon: 'bullet-list',
    name: 'bulletList',
    isLight: true
  }, {
    icon: 'numbered-list',
    name: 'numberedList',
    isLight: true
  }, {
    isSpacer: true
  }, {
    icon: 'link',
    name: 'link'
  }, {
    icon: 'code',
    name: 'code'
  }, {
    icon: 'quote',
    name: 'quote'
  }, {
    icon: 'spacer',
    name: 'horizontalRule'
  }];

  /**
   * Container for the actions of the editor
   *
   * @class ui-editor/action-bar
   * @module ui-editor
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    layout: _actionBar.default,
    /**
     * the changeset for the url validation
     *
     * @property changeset
     * @type {Object}
     * @default null
     */
    changeset: null,
    /**
     * whether the dialog to specify the url is open
     *
     * @property isUrlDialog
     * @type {Boolean}
     * @default false
     */
    isUrlDialog: false,
    /**
     * a light-editor only includes the formatting options:
     * bold, italic, underline, ordered and unordered lists
     *
     * @property isLight
     * @type {Boolean}
     * @default false
     */
    isLight: false,
    /**
     * the content element of the editor
     *
     * @property contentElement
     * @type {Object}
     * @default null
     */
    contentElement: null,
    /**
     * the id of the bottom sentinel
     *
     * @property editorSentinelId
     * @type {String}
     * @default null
     */
    editorSentinelId: null,
    /**
     * the top offset of the actionbar
     * when it is sticky
     *
     * @property offset
     * @type {Number}
     * @default 56
     */
    offset: DEFAULT_STICKY_OFFSET,
    /**
     * The actions displayed in the menu
     *
     * @property menuActions
     * @type {Array}
     */
    menuActions: undefined,
    /**
     * The actions displayed in the actionbar
     *
     * @property actionBarActions
     * @type {Array}
     */
    actionBarActions: undefined,
    /**
     * the placeholders that are allowed as weblink
     *
     * @argument allowedWebLinkPlaceholders
     * @type {Array}
     * @default null
     */
    allowedWebLinkPlaceholders: null,
    /**
     * whether the bottom Sentinel is in the viewport
     *
     * @property _isBottomSentinelIntersecting
     * @type {Boolean}
     * @default false
     * @private
     */
    _isBottomSentinelIntersecting: false,
    /**
     * whether the top Sentinel is in the viewport
     *
     * @property _isTopSentinelIntersecting
     * @type {Boolean}
     * @default false
     * @private
     */
    _isTopSentinelIntersecting: false,
    /**
     * the selection range used to restore the previous selection
     * after an url has been inserted
     *
     * @property _selectionRange
     * @type {Object}
     * @default null
     * @private
     */
    _selectionRange: null,
    /**
     * the prefix of the url input
     *
     * @property _urlInputPrefix
     * @type {String}
     * @default ''
     * @private
     */
    _urlInputPrefix: '',
    /**
     * the selected option for the url
     *
     * @property _urlSelectedOption
     * @type {String}
     * @default null
     * @private
     */
    _urlSelectedOption: null,
    /**
     * the select options for the url
     *
     * @property _urlSelectOptions
     * @type {Array}
     * @default null
     * @private
     */
    _urlSelectOptions: null,
    /**
     * the actionbar sentinel id
     *
     * @property sentinelId
     * @type {String}
     * @default null
     * @private
     */
    _sentinelId: null,
    /**
     * whether the actionbar should stick
     *
     * @computed _isSticky
     * @private
     */
    _isSticky: (0, _object.computed)('_isTopSentinelIntersecting', '_isBottomSentinelIntersecting', {
      get() {
        return !this._isTopSentinelIntersecting && this._isBottomSentinelIntersecting;
      }
    }),
    /**
     * the style that is passed to the
     * actionbar
     *
     * @computed _style
     * @type {String}
     * @private
     */
    _style: (0, _object.computed)('_isSticky', '_sentinelId', {
      get() {
        const {
          offset
        } = this;
        const topTargetElement = document.getElementById(this._sentinelId);
        const {
          width
        } = topTargetElement.getBoundingClientRect();
        return (0, _template.htmlSafe)(`width: ${width}px; top: ${offset}px`);
      }
    }),
    onActionClick() {},
    init() {
      this._super(...arguments);
      this._urlSelectOptions = [WEBLINK_TYPE, EMAIL_TYPE, PHONE_TYPE].map(type => {
        return {
          name: this.intl.t(`uiEditor.urlDialog.type.options.${type}`),
          value: type
        };
      });
      (0, _object.set)(this, '_sentinelId', this.elementId && `${this.elementId}-sentinel`);
      this._observers = {};
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);

      // if the editor is inside a dialog and has the default offset we set it to 180
      if (this.offset === DEFAULT_STICKY_OFFSET && this.element.closest('.ui-modal')) {
        (0, _object.set)(this, 'offset', 180);
      }
      const {
        offset
      } = this;

      // observe intersection at top and make action bar sticky
      const topTargetElement = document.getElementById(this._sentinelId);
      if (topTargetElement) {
        const intersectionobserverTop = new IntersectionObserver(changes => this._intersectionObserverCallback.perform(changes), {
          rootMargin: `-${offset}px 0px 0px 0px`,
          threshold: 1
        });
        // start observing the target
        intersectionobserverTop.observe(topTargetElement);
        (0, _object.set)(this, '_observers.top', {
          observer: intersectionobserverTop,
          target: topTargetElement
        });
      }

      // observe intersection at bottom and make actionbar unstick again
      const bottomTargetElement = document.getElementById(this.editorSentinelId);
      if (bottomTargetElement) {
        const intersectionobserverBottom = new IntersectionObserver(changes => this._intersectionObserverCallback.perform(changes), {
          rootMargin: `-${offset + 56}px 0px 0px 0px`,
          threshold: 1
        });
        // start observing the target
        intersectionobserverBottom.observe(bottomTargetElement);
        (0, _object.set)(this, '_observers.bottom', {
          observer: intersectionobserverBottom,
          target: bottomTargetElement
        });
      }
      const element = this.element.querySelector('.ui-editor__actionbar');
      if (element) {
        const onClickActions = [null, () => this.execCommand('insertUnorderedList'), () => this.execCommand('insertOrderedList'), null, () => this.toggleUrlDialog(), () => this.execCommand('formatBlock', 'pre'), () => this.execCommand('formatBlock', 'blockquote'), () => this.execCommand('insertHTML', '<hr>')];
        this.execCommand = (0, _runloop.bind)(this, this.execCommand);
        this.toggleUrlDialog = (0, _runloop.bind)(this, this.toggleUrlDialog);

        // get all current action elements and compute their total width
        const usedSpace = [...element.querySelectorAll(ACTION_CLASS)].reduce((sum, element) => sum + element.offsetWidth, 0);
        // compute current available space by subtracting the used space + padding and the menu icon width
        let availableSpace = this.element.offsetWidth - usedSpace - 48;
        const actionBarActions = [];
        const menuActions = [];
        const actionList = onClickActions.map((action, i) => Object.assign({
          onClick: action
        }, ACTIONS[i]));

        // remove non-light actions if editor is light
        const actions = actionList.filter(action => this.isLight ? action.isLight : true);

        // iterate through actions and place them either in the actionbar or in the menu
        // depending on how much space is left
        actions.forEach(action => {
          // 24 = spacewidth, 40 = width of an action
          const width = action.isSpacer ? 17 : 40;
          availableSpace -= width;
          if (availableSpace > 0) {
            actionBarActions.push(action);
          } else if (!action.isSpacer) {
            menuActions.push(action);
          }
        });
        (0, _object.setProperties)(this, {
          actionBarActions,
          menuActions
        });
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didDestroyElement() {
      this._super(...arguments);
      const {
        top,
        bottom
      } = this._observers;
      // remove the observers
      if (top.observer) {
        top.observer.unobserve(top.target);
      }
      if (bottom.observer) {
        bottom.observer.unobserve(bottom.target);
      }
    },
    /**
     * handle the intersectionobserver changes
     *
     * @function _intersectionObserverCallback
     * @type {Task}
     * @param {Array} changes
     */
    _intersectionObserverCallback: (0, _emberConcurrency.task)(function* (changes) {
      if (!this.isDestroying) {
        var _entry$boundingClient, _entry$rootBounds;
        const [entry] = yield changes;
        // check whether the target is above the viewport
        const isAbove = ((_entry$boundingClient = entry.boundingClientRect) === null || _entry$boundingClient === void 0 ? void 0 : _entry$boundingClient.y) < ((_entry$rootBounds = entry.rootBounds) === null || _entry$rootBounds === void 0 ? void 0 : _entry$rootBounds.y);
        if (entry.target.id === this._sentinelId) {
          // if the actionbar reaches the top it sticks
          if (isAbove && this._isTopSentinelIntersecting) {
            (0, _object.set)(this, '_isTopSentinelIntersecting', false);
          } else if (!isAbove && !this._isTopSentinelIntersecting) {
            (0, _object.set)(this, '_isTopSentinelIntersecting', true);
          }
        } else if (entry.target.id === this.editorSentinelId) {
          // when the whole editor leaves the viewport the actionbar unsticks
          if (isAbove && this._isBottomSentinelIntersecting) {
            (0, _object.set)(this, '_isBottomSentinelIntersecting', false);
          } else if (!isAbove && !this._isBottomSentinelIntersecting) {
            (0, _object.set)(this, '_isBottomSentinelIntersecting', true);
          }
        }
      }
    }).drop(),
    /**
     * create new changeset with given data
     * @function _createChangeset
     * @param {Object} data
     * @private
     */
    _createChangeset() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // initialize changeset for url validation
      const validation = this._getValidationByType(this._urlSelectedOption);
      const changeset = new _emberChangeset.default({
        text: data.text ? data.text : '',
        url: data.url ? data.url : '',
        openInNewTab: data.openInNewTab ? data.openInNewTab : false
      }, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.set)(this, 'changeset', changeset);
    },
    /**
     * updates the current selected url type
     * @function _updateSelectedUrlOption
     * @param {Object} data
     * @private
     */
    _updateSelectedUrlOption(option) {
      (0, _object.set)(this, '_urlSelectedOption', option);
      (0, _object.set)(this, '_urlInputPrefix', this._getUrlPrefixByType(option));
    },
    /**
     * returns the url prefix for a given type
     * @function _getUrlPrefixByType
     * @param {String} type
     * @private
     */
    _getUrlPrefixByType(type) {
      switch (type) {
        case EMAIL_TYPE:
          return 'mailto:';
        case PHONE_TYPE:
          return 'tel:';
        default:
          return '';
      }
    },
    /**
     * returns the validation for a given type
     * @function _getValidationByType
     * @param {String} type
     * @private
     */
    _getValidationByType(type) {
      switch (type) {
        case EMAIL_TYPE:
          return {
            url: (0, _validators.validateFormat)({
              type: 'email'
            })
          };
        case PHONE_TYPE:
          return {
            url: (0, _validators.validateFormat)({
              regex: /^([+][ ]*[0-9])?[ \-0-9]*$/
            })
          };
        default:
          return {
            url: (0, _editorLinks.validateLink)({
              type: 'url'
            }, this.allowedWebLinkPlaceholders)
          };
      }
    },
    execCommand(command) {
      let value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.onActionClick(command, value);
    },
    /**
     * opens the dialog and saves the current selection
     * closes the dialog after checking for changes
     *
     * @function toggleUrlDialog
     */
    toggleUrlDialog() {
      const isUrlDialog = this.isUrlDialog;
      if (isUrlDialog) {
        const changeset = this.changeset;
        if (changeset.get('isPristine')) {
          (0, _object.set)(this, 'isUrlDialog', false);
        } else {
          this.uiDialog.showDiscardChangesConfirm(() => changeset.rollback() && (0, _object.set)(this, 'isUrlDialog', false));
        }
      } else {
        var _selection$commonAnce;
        const changesetData = {};

        // save selection if available
        const selection = (0, _editorUtil.getCurrentSelection)();
        (0, _object.set)(this, '_selectionRange', selection);

        // if current selection is already wrapped by a link use its data
        if ((selection === null || selection === void 0 || (_selection$commonAnce = selection.commonAncestorContainer) === null || _selection$commonAnce === void 0 || (_selection$commonAnce = _selection$commonAnce.parentNode) === null || _selection$commonAnce === void 0 ? void 0 : _selection$commonAnce.tagName) === 'A') {
          const parentNode = selection.commonAncestorContainer.parentNode;
          changesetData.text = parentNode.innerText && parentNode.innerText;
          changesetData.url = parentNode.getAttribute('href');
          changesetData.openInNewTab = parentNode.getAttribute('target') === '_blank';
          let urlType = WEBLINK_TYPE;
          if (changesetData.url.indexOf('mailto:') === 0) {
            urlType = EMAIL_TYPE;
            changesetData.url = changesetData.url.substring(7);
          } else if (changesetData.url.indexOf('tel:') === 0) {
            urlType = PHONE_TYPE;
            changesetData.url = changesetData.url.substring(4);
          }
          this._updateSelectedUrlOption(urlType);

          // set current selection to wrapping link-tag
          const newRange = (0, _editorUtil.setCurrentSelectionByNode)(parentNode);
          newRange && (0, _object.set)(this, '_selectionRange', newRange);
        } else {
          // set text to selection
          if (selection && !selection.isCollapsed) {
            changesetData.text = selection.toString();
          }

          // reset url type to weblink when dialog is opened
          this._updateSelectedUrlOption(WEBLINK_TYPE);
        }
        this._createChangeset(changesetData);
        (0, _object.set)(this, 'isUrlDialog', true);
        // move focus to dialog
        (0, _domUtil.nextTick)().then(() => {
          const input = document.querySelector('.ui-modal input');
          input ? input.focus() : null;
        });
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      execCommand() {
        this.execCommand(...arguments);
      },
      toggleUrlDialog() {
        this.toggleUrlDialog(...arguments);
      },
      toggleCheckbox() {
        const value = this.changeset.get('openInNewTab');
        (0, _object.set)(this, 'changeset.openInNewTab', !value);
      },
      /**
       * inserts a link into the content, if a selection can be resored it the anchor tag wraps around
       * the current selection
       *
       * @function insertUrl
       */
      insertUrl() {
        const changeset = this.changeset;
        changeset.validate();
        if (changeset.get('isValid')) {
          if (!changeset.get('text')) {
            changeset.set('text', changeset.get('url'));
          }
          const selectedOption = this._urlSelectedOption;
          const urlPrefix = this._getUrlPrefixByType(selectedOption);
          const url = `${urlPrefix}${changeset.get('url').replace(/\s/g, '')}`;
          const content = this.contentElement;
          content.focus();

          // restore selection
          const selection = this._selectionRange;
          (0, _editorUtil.restoreSelection)(selection);

          // insert anchor tag
          const target = changeset.get('openInNewTab') ? ' target="_blank"' : '';
          let aTag = `<a href="${url}"${target}>${changeset.get('text')}</a>`;
          if (!content.innerHTML) {
            aTag = `<p>${aTag}</p>`;
          }
          (0, _editorUtil.exec)('insertHTML', aTag);
          changeset.rollback();
          (0, _object.set)(this, 'isUrlDialog', false);
        }
      },
      /**
       * handles the change of the url type and creates a new changeset
       *
       * @function onChangeType
       */
      onChangeType(option) {
        this._updateSelectedUrlOption(option);
        const changeset = this.changeset;
        let data = {};
        if (changeset) {
          data = {
            text: changeset.get('text'),
            url: changeset.get('url'),
            openInNewTab: changeset.get('openInNewTab')
          };
        }
        this._createChangeset(data);
      }
    }
  });
});