define("@additive-apps/ui/components/ui-dom-hide", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HIDE_CLASS = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id={{this._uniqueIdentifier}}
    class={{@classNames}}
    {{did-insert this._setup}}
    {{did-update this._hideUpdated this.hide}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "3AfHIfHM",
    "block": "[[[11,0],[16,1,[30,0,[\"_uniqueIdentifier\"]]],[16,0,[30,1]],[4,[38,0],[[30,0,[\"_setup\"]]],null],[4,[38,1],[[30,0,[\"_hideUpdated\"]],[30,0,[\"hide\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@classNames\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-dom-hide.hbs",
    "isStrictMode": false
  });
  const HIDE_CLASS = _exports.HIDE_CLASS = 'ui-dom-hide--hidden';
  /**
   * A higher order component which allows hiding child dom elements by selectors.
   *
   * @class ui-dom-hide
   */
  let UiDomHideComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(), _dec2 = (0, _emberArgTypes.arg)(), _dec3 = (0, _emberArgTypes.arg)(), _class = class UiDomHideComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      /**
       * The DOM selectors (must be compatible with JS `querySelectorAll`)
       *
       * @argument hide
       * @type {String[]}
       */
      _initializerDefineProperty(this, "hide", _descriptor, this);
      /**
       * Whether the content can change during runtime.
       * If enabled, it will handle updating `hide` to same array.
       * If not enabled, it will ignore `hide` update with same content.
       *
       * @argument hasDynamicContent
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "hasDynamicContent", _descriptor2, this);
      /**
       * The current `hide` value
       *
       * @property _currentHide
       * @type {String[]}
       * @private
       */
      _defineProperty(this, "_currentHide", []);
      /**
       * A unique identifier for this component
       *
       * @property _uniqueIdentifier
       * @type {String}
       * @private
       */
      _defineProperty(this, "_uniqueIdentifier", (0, _internals.guidFor)(this));
      /**
       * Children wrapper element
       *
       * @property _rootElement
       * @type {HTMLElement}
       * @private
       */
      _defineProperty(this, "_rootElement", null);
      /**
       * Executed after hidden elements are updated
       *
       * @argument onHideUpdate
       * @type {Function}
       */
      _initializerDefineProperty(this, "onHideUpdate", _descriptor3, this);
      if (this.hide.length > 0) {
        this._currentHide = this.hide.slice(0);
      }
    }
    _addClassToElementsBySelector(selector) {
      var _this$_rootElement;
      (_this$_rootElement = this._rootElement) === null || _this$_rootElement === void 0 || _this$_rootElement.querySelectorAll(selector).forEach(element => {
        element.classList.add(HIDE_CLASS);
      });
    }
    _removeClassToElementsBySelector(selector) {
      var _this$_rootElement2;
      (_this$_rootElement2 = this._rootElement) === null || _this$_rootElement2 === void 0 || _this$_rootElement2.querySelectorAll(selector).forEach(element => {
        element.classList.remove(HIDE_CLASS);
      });
    }
    _setup() {
      this._rootElement = document.getElementById(this._uniqueIdentifier);
      this.hide.forEach(selector => this._addClassToElementsBySelector(selector));
    }
    _hideUpdated() {
      if (this.hasDynamicContent === false && this._currentHide.length === this.hide.length) {
        if (JSON.stringify(this._currentHide) === JSON.stringify(this.hide)) {
          /* Do nothing when hide attribute didn't change */
          return;
        }
      }
      this.hide.forEach(selector => {
        const currentHideIndex = this._currentHide.indexOf(selector);
        /* If is still hidden */
        if (currentHideIndex >= 0) {
          /* Remove this element from array */
          this._currentHide.splice(currentHideIndex, 1);

          /* Performance: When content is not dynamic break here, to avoid adding class again */
          if (this.hasDynamicContent === false) {
            return;
          }
        }

        /* New selector, add class */
        this._addClassToElementsBySelector(selector);
      });

      /* Remaining elements in `_currentHide` are those which do not match anymore */
      this._currentHide.forEach(selector => this._removeClassToElementsBySelector(selector));
      this._currentHide = this.hide.slice(0);
      this.onHideUpdate();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "hide", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasDynamicContent", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onHideUpdate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_hideUpdated", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_hideUpdated"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDomHideComponent);
});