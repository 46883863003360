define("@additive-apps/ui/components/ui-editor/mobile-actions", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-editor/mobile-actions"], function (_exports, _component, _mobileActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Dropdown for secondary actions on mobile devices
   *
   * @class ui-editor/mobile-actions
   * @module ui-editor
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _mobileActions.default,
    classNames: ['ui-editor__mobile-actions', 'flex', 'flex-grow-1', 'justify-end'],
    /**
     * The actions displayed in the menu
     *
     * @property menuActions
     * @type {Array}
     */
    menuActions: undefined
  });
});