define("@additive-apps/auth/services/auth-utils", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This is only outsourced to a service to allow overwriting in tests
  class AuthUtils extends _service.default {
    redirectToUrl(url) {
      window.location.href = url;
    }
  }
  _exports.default = AuthUtils;
});