define("@additive-apps/ui/components/ui-month-day-picker", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-month-day-picker", "@ember/object", "@ember/service", "date-fns"], function (_exports, _component, _uiMonthDayPicker, _object, _service, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Component which lets you pick a month and a day
   *
   * @class ui-month-day-picker
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    uiLocale: (0, _service.inject)(),
    layout: _uiMonthDayPicker.default,
    /**
     * Whether value is resetable
     *
     * @argument isResetable
     * @type {Boolean}
     */
    isResetable: true,
    /**
     * The initial day
     *
     * @argument initialDay
     * @type {Number}
     */
    initialDay: null,
    /**
     * The initial month
     *
     * @argument initialDay
     * @type {Number}
     */
    initialMonth: null,
    /**
     * The label around the inputs
     *
     * @argument label
     * @type {String|null}
     */
    label: null,
    /**
     * The current initial day
     *
     * @property _currentInitialDay
     * @type {Number|null}
     * @private
     */
    _currentInitialDay: null,
    /**
     * The current initial month
     *
     * @property _currentInitialMonth
     * @type {Number|null}
     * @private
     */
    _currentInitialMonth: null,
    /**
     * The day options for the select
     *
     * @property _dayOptions
     * @type {Object[]}
     * @private
     */
    _dayOptions: null,
    /**
     * The day options for the select
     *
     * @property _monthOptions
     * @type {Object[]}
     * @private
     */
    _monthOptions: null,
    /**
     * The currently selected day
     *
     * @property _selectedDay
     * @type {Object[]}
     * @private
     */
    _selectedDay: null,
    /**
     * The currently selected month
     *
     * @property _selectedMonth
     * @type {Object[]}
     * @private
     */
    _selectedMonth: null,
    init() {
      this._super(...arguments);

      /* Generate 32 days, as index starts at 0 and remove first */
      let days = [...Array(32).keys()];
      days.shift();
      if (this.isResetable) {
        /* Add null (reset) as first day */
        days = [null, ...days];
      }

      /* Generate 13 months, as index starts at 0 and remove first */
      let months = [...Array(13).keys()];
      months.shift();
      if (this.isResetable) {
        /* Add null (reset) as first day */
        months = [null, ...months];
      }
      (0, _object.setProperties)(this, {
        _dayOptions: days.map(this._numberToOption),
        _monthOptions: months.map(month => {
          return {
            value: month,
            label: this._getLongMonthByNumber(month)
          };
        }),
        _selectedDay: this.initialDay,
        _selectedMonth: this.initialMonth && this._getLongMonthByNumber(this.initialMonth)
      });
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._currentInitialDay !== this.initialDay) {
        (0, _object.setProperties)(this, {
          _selectedDay: this.initialDay,
          _currentInitialDay: this.initialDay
        });
      }
      if (this._currentInitialMonth !== this.initialMonth) {
        const longMonth = this.initialMonth && this._getLongMonthByNumber(this.initialMonth);
        (0, _object.setProperties)(this, {
          _selectedMonth: longMonth,
          _currentInitialMonth: longMonth
        });
      }
    },
    /**
     * onChange event.
     *
     * @function onChange
     * @param {String} key Whether `day` or `month`
     * @param {Number} value The selected value
     */
    onChange(/*key, value*/) {},
    /**
     * Helper function to build an object of a single number to match select components needs
     *
     * @function _numberToOption
     * @param {Number} number The number value
     */
    _numberToOption(number) {
      return {
        value: number,
        label: number
      };
    },
    /**
     * Returns given month number as long month string
     *
     * @function _getLongMonthByNumber
     * @param {Number} month The month number (1, 2, 5, 12...)
     */
    _getLongMonthByNumber(month) {
      if (!month) {
        return null;
      }
      try {
        /* Dummy date */
        const date = new Date(2020, 5, 5, 12);
        date.setMonth(month - 1);
        return (0, _dateFns.format)(date, 'MMMM', {
          locale: this.uiLocale.dateFnsLocale
        });
      } catch (error) {
        return null;
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onChange(key, value) {
        const label = value.label || null;
        (0, _object.set)(this, key === 'day' ? '_selectedDay' : '_selectedMonth', label);
        this.onChange(key, value.value || null);
      }
    }
  });
});