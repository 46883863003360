define("@additive-apps/ui/components/ui-category-grid", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-category-grid"], function (_exports, _component, _uiCategoryGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiCategoryGrid.default,
    classNames: ['ui-category-grid', 'pv3']
  });
});