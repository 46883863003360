define("@additive-apps/ui/components/ui-card/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-card - cpointer w-100 -"
    role="button"
    {{on 'click' (ui-optional-action @onClick)}}
    ...attributes
  >
    {{yield (hash
      header=(component 'ui-card/header')
      footer=(component 'ui-card/footer' isActive=@isActive)
    )}}
  </div>
  */
  {
    "id": "oI/RibC6",
    "block": "[[[11,0],[24,0,\"ui-card - cpointer w-100 -\"],[24,\"role\",\"button\"],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,2]],null]],null],[12],[1,\"\\n  \"],[18,4,[[28,[37,3],null,[[\"header\",\"footer\"],[[50,\"ui-card/header\",0,null,null],[50,\"ui-card/footer\",0,null,[[\"isActive\"],[[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@onClick\",\"@isActive\",\"&default\"],false,[\"on\",\"ui-optional-action\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});