define("@additive-apps/ui/components/ui-property-watcher", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC component that watches for changes in properties and
   * executes a callback when the happen
   *
   * @class amc-property-watcher
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    /**
     * the properties that should be watched for changes
     *
     * @property _watchedProperties
     * @type {[String]}
     * @default null
     * @private
     */
    _watchedProperties: null,
    _onPropertyChange() {},
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._watchedProperties) {
        // iterate through all properties
        for (const property of this._watchedProperties) {
          // check whether property value has changed
          if (JSON.stringify(this[property]) !== JSON.stringify(this[`_previous${property}`])) {
            this._onPropertyChange();
            // set the new property values to a private property `_previous{Prop}`
            const properties = {};
            this._watchedProperties.forEach(property => {
              properties[`_previous${property}`] = this[property];
            });
            (0, _object.setProperties)(this, properties);
            return;
          }
        }
      }
    }
  });
});