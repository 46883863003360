define("@additive-apps/ui/components/ui-filter-inputs/multi", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/multi", "@ember/array", "@ember/object"], function (_exports, _base, _multi, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *
   * Filter-input for type `multi`.
   * Renders multi-select with provided `values`
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/multi
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _multi.default,
    /**
     * The current value of the initialValue
     *
     * @property _currentInitialValue
     * @type {Any}
     * @private
     */
    _currentInitialValue: null,
    /**
     * The current value of the select
     *
     * @property _currentSelected
     * @type {Any}
     * @private
     */
    _currentSelected: null,
    init() {
      this._super(...arguments);
      this.initialValue = this.initialValue || [];
      if (!(0, _array.isArray)(this.initialValue)) {
        this.initialValue = [this.initialValue];
      }
      this._currentSelected = this.initialValue;
      this._currentInitialValue = this.initialValue;
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (!this._currentInitialValue || JSON.stringify(this.initialValue) !== JSON.stringify(this._currentInitialValue)) {
        (0, _object.setProperties)(this, {
          _currentSelected: this.initialValue,
          _currentInitialValue: this.initialValue
        });
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onOptionChange(value, checked) {
        if (checked) {
          /* Only push if not already in */
          if (this._currentSelected.indexOf(value) === -1) {
            this._currentSelected.push(value);
          }
        } else {
          const optionIndex = this._currentSelected.indexOf(value);
          if (optionIndex !== -1) {
            this._currentSelected.splice(optionIndex, 1);
          }
        }
        const _value = this._currentSelected.map(value => value.value);
        this._onChange(this.filter.key, _value);
      }
    }
  });
});