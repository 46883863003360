define("@additive-apps/jam-ember/instance-initializers/full-story", ["exports", "@fullstory/browser", "ember-concurrency", "@ember/object"], function (_exports, FullStory, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(applicationInstance) {
    var _env$additiveJamEmb;
    const env = applicationInstance.resolveRegistration('config:environment');
    const config = (_env$additiveJamEmb = env['additive-jam-ember']) === null || _env$additiveJamEmb === void 0 ? void 0 : _env$additiveJamEmb.fullStory;
    if (!config || config.enabled === false || !config.orgId) {
      return;
    }
    FullStory.init({
      orgId: config.orgId
    });
    if (config.anonymous) {
      return;
    }
    const currentUser = applicationInstance.lookup('service:currentUser');
    const waitForUserTask = (0, _emberConcurrency.task)(function* () {
      try {
        var _currentUser$user, _currentUser$user2, _currentUser$user3;
        yield (0, _emberConcurrency.waitForProperty)(currentUser, 'user');
        FullStory.identify(currentUser === null || currentUser === void 0 || (_currentUser$user = currentUser.user) === null || _currentUser$user === void 0 ? void 0 : _currentUser$user.id, {
          displayName: currentUser === null || currentUser === void 0 || (_currentUser$user2 = currentUser.user) === null || _currentUser$user2 === void 0 ? void 0 : _currentUser$user2.fullName,
          email: currentUser === null || currentUser === void 0 || (_currentUser$user3 = currentUser.user) === null || _currentUser$user3 === void 0 ? void 0 : _currentUser$user3.email
        });
      } catch (error) {}
    });
    (0, _object.defineProperty)(this, 'waitForUserTask', waitForUserTask);
    this.waitForUserTask.perform();
  }
  var _default = _exports.default = {
    initialize
  };
});