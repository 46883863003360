define("@additive-apps/media/components/mm-resource-gallery/attributes-dialog", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-resource-gallery/attributes-dialog", "@ember/object", "ember-changeset"], function (_exports, _component, _attributesDialog, _object, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _attributesDialog.default,
    tagName: '',
    /**
     * the currently active resource
     *
     * @property resource
     * @type {Object}
     */
    resource: null,
    /**
     * whether the component is read-only
     *
     * @argument isReadOnly
     * @type {Boolean}
     * @default false
     * @public
     */
    isReadOnly: false,
    init() {
      this._super(...arguments);

      // create the changeset using the current resource
      this._createChangeset();
    },
    didUpdateAttrs() {
      this._super(...arguments);

      // update the changeset when the dialog is opened
      if (this.resource.id !== this.changeset.get('id')) {
        this._createChangeset();
      }
    },
    /**
     * on close callback
     *
     * @function onClose
     */
    onClose() {},
    /**
     * on submit callback
     *
     * @function onSubmit
     */
    onSubmit() {},
    /**
     * creates a new changeset using the active resource
     * as an underlying object
     *
     * @function _createChangeset
     * @private
     */
    _createChangeset() {
      const resource = this.resource;
      const changeset = new _emberChangeset.default(resource);
      (0, _object.set)(this, 'changeset', changeset);
    },
    actions: {
      /**
       * passes the title and alt to the callback
       *
       * @function save
       */
      save() {
        const title = this.changeset.get(title);
        const alt = this.changeset.get(alt);
        this.onSubmit({
          title,
          alt
        });
      },
      /**
       * checks for changes and opens discard changes dialog
       * or closes this dialog
       *
       * @function close
       */
      close() {
        if (this.changeset.get('isDirty')) {
          (0, _object.set)(this, '_isDiscardChangesDialog', true);
        } else {
          this.onClose();
        }
      },
      /**
       * discards the changes made and closes the dialog
       *
       * @function discardChanges
       */
      discardChanges() {
        this.changeset.rollback();
        (0, _object.set)(this, '_isDiscardChangesDialog', false);
        this.onClose();
      }
    }
  });
});