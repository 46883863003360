define("@additive-apps/ui/services/ui-backdrop", ["exports", "@ember/service", "@ember/utils", "@ember/application", "@additive-apps/ui/utils/dom-util"], function (_exports, _service, _utils, _application, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const rootClass = 'ui-backdrop--show';
  const transparentClass = 'ui-backdrop--transparent';
  const activeElementZindex = 151;

  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    init() {
      var _config$APP;
      this._super(...arguments);
      this._backdrops = [];
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      this._rootElement = (_config$APP = config.APP) === null || _config$APP === void 0 ? void 0 : _config$APP.rootElement;
      if ((0, _utils.isEmpty)(document.querySelector(this._rootElement))) {
        this._rootElement = 'body';
      }
    },
    show(domId, onClickCallback) {
      let transparent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      if ((0, _utils.isPresent)(this._backdrops[domId])) {
        return;
      }
      if (!this._idExistsInDom(domId)) {
        return;
      }
      this._backdrops[domId] = {
        onClickCallback: onClickCallback
      };
      const rootClassList = document.querySelector(this._rootElement).classList;
      if (!rootClassList.contains(rootClass)) {
        rootClassList.add(rootClass);
      }
      if (!rootClassList.contains(transparentClass) && transparent) {
        rootClassList.add(transparentClass);
      }
      const backdropKeys = Object.keys(this._backdrops);
      if (backdropKeys.length > 1) {
        const previousActiveElementId = backdropKeys[backdropKeys.length - 2];
        this._removeShowInlineStylesToElementById(previousActiveElementId, this._backdrops[previousActiveElementId].addPositionRelative);
        this._removeClickListenerFromId(previousActiveElementId);
      }
      const addPositionRelative = this._addShowInlineStylesToElementById(domId);
      Object.assign(this._backdrops[domId], {
        addPositionRelative: addPositionRelative
      });
      if ((0, _utils.isPresent)(onClickCallback)) {
        document.getElementById('ui-backdrop').addEventListener('click', onClickCallback);
      }
    },
    hide(domId) {
      if ((0, _utils.isEmpty)(this._backdrops[domId])) {
        return;
      }
      this._removeShowInlineStylesToElementById(domId, this._backdrops[domId].addPositionRelative);
      this._removeClickListenerFromId(domId);
      delete this._backdrops[domId];
      if (Object.keys(this._backdrops).length === 0) {
        document.querySelector(this._rootElement).classList.remove(rootClass);
        document.querySelector(this._rootElement).classList.remove(transparentClass);
      }
      this._showLastRegisteredElement();
    },
    _addShowInlineStylesToElementById(elementId) {
      const element = document.getElementById(elementId);
      const addPositionRelative = window.getComputedStyle(element).getPropertyValue('position') === 'static';
      if (addPositionRelative === true) {
        element.style.position = 'relative';
      }
      const backdropElement = document.getElementById('ui-backdrop');
      const backdropZIndex = backdropElement && window.getComputedStyle(backdropElement, null).zIndex;
      element.style.zIndex = backdropZIndex ? parseInt(backdropZIndex) + 1 : activeElementZindex;
      return addPositionRelative;
    },
    _removeShowInlineStylesToElementById(elementId, removeInlinePosition) {
      const element = document.getElementById(elementId);
      if (element) {
        if (removeInlinePosition === true) {
          element.style.position = '';
        }
        element.style.zIndex = '';
      }
    },
    _removeClickListenerFromId(domId) {
      if ((0, _utils.isPresent)(this._backdrops[domId].onClickCallback)) {
        document.getElementById('ui-backdrop').removeEventListener('click', this._backdrops[domId].onClickCallback);
      }
    },
    _showLastRegisteredElement() {
      // Iterate all registered backdrops backwards, and show the first still existing id
      Object.keys(this._backdrops).reverse().some(id => {
        const element = document.getElementById(id);
        if (!element) {
          delete this._backdrops[id];
        } else {
          this._addShowInlineStylesToElementById(id);
          return true;
        }
      });
    },
    _idExistsInDom(id) {
      return (0, _domUtil.isDomElement)(document.getElementById(id));
    }
  });
});