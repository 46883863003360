define("@additive-apps/media/components/ui-carousel", ["exports", "@ember/component", "@additive-apps/media/templates/components/ui-carousel", "@ember/service", "@ember/string", "@ember/object", "@ember/runloop", "@additive-apps/media/states/ui-carousel"], function (_exports, _component, _uiCarousel, _service, _string, _object, _runloop, _uiCarousel2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_KEY_PREFIX = 'ui-carousel';

  /**
   *  Component to let containers slide.
   *  Its yielding a trigger to trigger navigation events, like next-slide or previous-slide.
   *
   * ```hbs
   * {{#ui-carousel as |carousel|}}
   *    {{carousel.trigger.previous}}
   *    {{carousel.trigger.next}}
   *    {{#carousel.container}}Content of container 1{{/carousel.container}}
   *    {{#carousel.container}}Content of container 2{{/carousel.container}}
   * {{/ui-carousel}}
   * ```
   *
   * @class ui-carousel
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _uiCarousel.default,
    uiState: (0, _service.inject)(),
    classNames: ['ui-carousel', 'ohidden', 'w-100'],
    classNameBindings: ['useTransition'],
    /**
     * Its state object
     * @property state
     * @type {Object}
     * @private
     */
    state: null,
    /**
     * Index where it should start. Starting at 1
     * @property startIndex
     * @type {Number}
     */
    startIndex: 1,
    /**
     * The percentage needed to translate (css) per step
     * @property _transformTranslateStep
     * @type {Number}
     * @private
     */
    _transformTranslateStep: 0,
    /**
     * Whether it should use lazy rendering of container content or nor
     * @property useLazyRendering
     * @type {Boolean}
     */
    useLazyRendering: false,
    /**
     * Whether it should use css transition for sliding or not
     * @property useTransition
     * @type {Boolean}
     */
    useTransition: false,
    /**
     * The container inline-styles which are needed to set size and grid depending on length of containers
     * @computed _containerStyles
     * @type {String}
     * @private
     */
    _containerStyles: (0, _object.computed)('state.containerCount', {
      get() {
        var _this$state;
        const containerCount = (_this$state = this.state) === null || _this$state === void 0 ? void 0 : _this$state.containerCount;
        // eslint-disable-next-line ember/no-side-effects
        (0, _object.set)(this, '_transformTranslateStep', containerCount <= 0 ? 0 : 100 / containerCount);
        return {
          width: `${containerCount * 100}%`,
          'grid-template-columns': `repeat(${containerCount}, 1fr)`
        };
      }
    }),
    /**
     * The container inline-styles including the transform value depending on current active index
     * @computed _slideContainerInlineStyles
     * @type {String}
     * @private
     */
    _slideContainerInlineStyles: (0, _object.computed)('_containerStyles', '_transformTranslateStep', 'state.activeIndex', {
      get() {
        var _this$state2;
        const containerStyles = this._containerStyles || {};
        const index = ((_this$state2 = this.state) === null || _this$state2 === void 0 ? void 0 : _this$state2.activeIndex) - 1;
        const _transformTranslateStep = this._transformTranslateStep;
        const transformPercentage = index * _transformTranslateStep || 0;
        const styles = Object.assign(containerStyles, {
          transform: `translate(-${transformPercentage}%, 0)`
        });
        const formattedStyles = Object.keys(styles).map(styleKey => {
          const styleValue = styles[styleKey];
          return styleValue && `${styleKey}: ${styleValue};`;
        });
        this._stylesWillChange(formattedStyles);
        return (0, _string.htmlSafe)(formattedStyles.join(' '));
      }
    }),
    init() {
      this._super(...arguments);
      const state = Object.assign({}, _uiCarousel2.default, {
        activeIndex: this.startIndex || 1,
        useLazyRendering: this.useLazyRendering
      });
      (0, _object.set)(this, 'state', state);
      this.uiState.register(`${STATE_KEY_PREFIX}-${this.elementId}`, state);
    },
    /**
     * This function will decide if carousel should use css-transitions or not.
     * Css transitions should only be used after carousel did setted up and renderd for the first time.
     * To prevent unwanted transitions at boot up
     * @function _stylesWillChange
     * @private
     */
    _stylesWillChange() {
      var _this$state3;
      const useTransition = this.useTransition;
      if (useTransition) {
        return;
      }
      if (((_this$state3 = this.state) === null || _this$state3 === void 0 ? void 0 : _this$state3.containerCount) > 0) {
        // TODO: investigate why next is not working, sometimes it transitions when it shouldn't
        (0, _runloop.later)(this, () => !this.isDestroying && (0, _object.set)(this, 'useTransition', true), 500);
      }
    },
    /**
     * Index has changed event
     * @function onIndexChange
     */
    onIndexChange() {},
    /**
     * On gallery-trigger action
     * @function onTrigger
     * @private
     */
    onTrigger(type) {
      var _this$state4;
      if (type === 'previous') {
        this.state.previous();
      } else if (type === 'next') {
        this.state.next();
      } else {
        throw new ErrorEvent(`[ui-carousel] TYPE "${type}" not found, aborted`);
      }
      this.onIndexChange((_this$state4 = this.state) === null || _this$state4 === void 0 ? void 0 : _this$state4.activeIndex);
    }
  });
});