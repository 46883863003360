define("@additive-apps/ui/components/ui-card/footer/content/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    default=(component 'ui-card/footer/content/default')
    title=(component 'ui-card/footer/content/title')
  )}}
  */
  {
    "id": "2T89Dwr0",
    "block": "[[[18,1,[[28,[37,1],null,[[\"default\",\"title\"],[[50,\"ui-card/footer/content/default\",0,null,null],[50,\"ui-card/footer/content/title\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/content/index.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});