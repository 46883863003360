define("@additive-apps/ui/components/ui-navigation-bar-filterable", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-navigation-bar-filterable", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "@ember/array", "@ember/runloop", "ember-concurrency", "@additive-apps/ui/utils/query-param-util", "@additive-apps/ui/utils/constants", "@additive-apps/ui/constants"], function (_exports, _component, _uiNavigationBarFilterable, _object, _computed, _service, _utils, _array, _runloop, _emberConcurrency, _queryParamUtil, _constants, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj; //eslint-disable-next-line ember/no-classic-components
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * Navigation bar component, which implements all the filter and search functionality.
   *
   * The component uses the filter-service as a proxy for the filterobject,
   * while the filters states are directly read from the object itself.
   * @class ui-navigation-bar-filterable
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend((_obj = {
    intl: (0, _service.inject)(),
    uiFilter: (0, _service.inject)(),
    uiState: (0, _service.inject)(),
    uiQueryParams: (0, _service.inject)(),
    layout: _uiNavigationBarFilterable.default,
    classNames: ['ui-navigation-bar-filterable'],
    classNameBindings: ['isFilterOpen:ui-navigation-bar-filterable__filter-mode'],
    /**
     * The key which is used to interact with the global filter service
     * @property key
     * @type {String}
     * @default '''
     */
    key: '',
    /**
     * This state indicates if the filter-section is visible or not.
     *
     * @property isFilterOpen
     * @type {Boolean}
     * @default false
     */
    isFilterOpen: false,
    /**
     * The navigation-bars title
     *
     * @property title
     * @type {String}
     * @default ''
     */
    title: '',
    /**
     * This is the search dom-input placeholder.
     *
     * @property searchInputPlaceholder
     * @type {String}
     * @default ''
     */
    searchPlaceholder: '',
    /**
     * The current search-term, which is passed down and modified
     * through the `ui-searchbar` component.
     *
     * @property searchTerm
     * @type {String}
     * @default null
     */
    searchTerm: null,
    /**
     * The active filter query params. Object containing a string for every active param.
     *
     * @property filterValues
     * @type {Object}
     * @default null
     */
    filterValues: null,
    /**
     * Object containing the properties that are passed to the
     * breadcrumb component
     *
     * @property {Object} breadcrumbs
     * @default null
     */
    breadcrumbs: null,
    /**
     * Whether a custom navigation drawer is used
     *
     * @computed hasCustomNavigationDrawer
     * @type {Boolean}
     * @default false
     */
    hasCustomNavigationDrawer: false,
    /**
     * The dynamic filters
     *
     * @property _dynamicFilters
     * @type {Object[]}
     * @default null
     * @private
     */
    _dynamicFilters: null,
    /**
     * Reference to the qp-objects search-term.
     *
     * This allows to instantiate the navbar in filter-mode with a preset search term.
     *
     * @property _initialSearchTerm
     * @type {String}
     * @default null
     * @private
     */
    _initialSearchTerm: null,
    /**
     * Indicates whether the entity is filterable
     * @computed filterable
     * @type {Boolean}
     */
    filterable: (0, _computed.alias)('_filterObject.filterable'),
    /**
     * Indicates whether the entity is searchable
     * @computed searchable
     * @type {Boolean}
     */
    searchable: (0, _computed.alias)('_filterObject.searchable'),
    /**
     * Indicates whether an api-call is beeing executed inside the
     * filter-service.
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: (0, _computed.alias)('uiFilter.isLoading'),
    /**
     * Reference to the underlying QueryParams-Instance.
     *
     * @property _filterObject
     * @type {QueryParameters}
     * @private
     */
    _filterObject: null,
    /**
     * Whether dynamic filters has error
     *
     * @computed isDynamicFilterError
     * @type {Boolean}
     * @default false
     */
    isDynamicFilterError: (0, _computed.or)('uiFilter.isDynamicFilterError', 'uiFilter.retryDynamicFiltersFetchTask.isRunning'),
    /**
     * Whether filter has dynamic filters
     *
     * @computed _isDynamicFilter
     * @type {Boolean}
     * @default false
     */
    _isDynamicFilter: (0, _computed.bool)('_filterObject.dynamicFilters.url'),
    /**
     * The default dynamic filters,
     * depends on registered filters `defaultGroupKey`
     *
     * @computed _defaultDynamicfilters
     * @type {Object[]}
     * @private
     */
    _defaultDynamicfilters: (0, _object.computed)('_isDynamicFilter', '_dynamicFilters.@each.groupKey', '_filterObject.dynamicFilters.defaultGroupKey', {
      get() {
        if (!this._isDynamicFilter) {
          return [];
        }
        const filters = this._dynamicFilters;
        if (!filters || !Array.isArray(filters) || filters.length === 0) {
          return [];
        }
        const groupKey = this._filterObject.dynamicFilters.defaultGroupKey;
        return filters.filter(filter => filter.groupKey === groupKey);
      }
    }),
    /**
     * Whether dynamic filters contains non-default filters.
     *
     * @computed _hasExtendedDynamicFilters
     * @type {Boolean}
     * @private
     */
    _hasExtendedDynamicFilters: (0, _object.computed)('_isDynamicFilter', '_dynamicFilters.@each.groupKey', '_filterObject.dynamicFilters.defaultGroupKey', {
      get() {
        if (!this._isDynamicFilter) {
          return false;
        }
        const filters = this._dynamicFilters;
        if (!filters || !Array.isArray(filters) || filters.length === 0) {
          return false;
        }
        const groupKey = this._filterObject.dynamicFilters.defaultGroupKey;
        return filters.some(filter => filter.groupKey != groupKey);
      }
    }),
    /**
     * Whether extended filters are in use
     *
     * @property _extendedFiltersInUse
     * @type {Boolean}
     * @default false
     * @private
     */
    _extendedFiltersInUse: (0, _object.computed)('_dynamicFilters', '_filterObject.dynamicFilters.defaultGroupKey', '_hasExtendedDynamicFilters', 'filterValues.[]', {
      get() {
        if (!this._hasExtendedDynamicFilters) {
          return false;
        }
        if (!this.filterValues || Object.keys(this.filterValues).length === 0) {
          return false;
        }
        const groupKey = this._filterObject.dynamicFilters.defaultGroupKey;
        // check whether there are active filters
        return this._dynamicFilters.some(filter => {
          // check whether components of query or group_label are active
          if (filter.type === 'query' || filter.type === 'group_label') {
            return filter.components && Object.values(filter.components).some(filter => {
              return (0, _utils.isPresent)(this.filterValues[filter.key]);
            });
          } else if (filter.groupKey != groupKey) {
            return (0, _utils.isPresent)(this.filterValues[filter.key]);
          }
          return false;
        });
      }
    }),
    _setup() {
      if (!this.key) return;
      const dataFilter = !(0, _utils.isEmpty)(this.key) && this.uiFilter.getQPFilter(this.key);
      (0, _object.set)(this, '_filterObject', dataFilter && dataFilter.filter);
      this.uiFilter.onReady(this.key, () => {
        if (this.isDestroying) {
          return;
        }
        if (this._isDynamicFilter) {
          (0, _object.set)(this, '_extendedNavDrawerContainer', document.getElementById(_constants.uiNavigationDrawerRightContainerId));
          let dynamicFilters = this.uiFilter.getDynamicFilters(this.key);
          if (dynamicFilters) {
            dynamicFilters.forEach(filter => {
              (0, _object.set)(filter, 'isSearchable', filter.values && filter.values.length > 7);
            });
            (0, _object.set)(this, '_dynamicFilters', dynamicFilters);
          }
        }

        /* Listener which gets triggered when a value of the ui-query-params service changes */
        const onChangeListener = () => {
          const customFilters = this._filterObject && this._filterObject.getCustomFilters() || null;
          const searchTerm = this._filterObject && this._filterObject.getSearchTerm() || null;
          const isFilterOpen = (0, _utils.isPresent)(searchTerm) || (0, _utils.isPresent)(customFilters) && Object.keys(customFilters).length > 0;
          (0, _object.setProperties)(this, {
            filterValues: Object.assign({}, customFilters),
            searchTerm,
            isFilterOpen,
            '_filterObject.isOpen': isFilterOpen
          });
        };
        let listeners = {};
        /* Build listener object to register onchange event for each available filter-param */
        Object.keys(this._filterObject.getValues()).map(key => {
          listeners[key] = {
            onChange: onChangeListener.bind(this)
          };
        });
        this.uiQueryParams.register(this.elementId, listeners);

        // Get url values and set into filter
        Object.keys(listeners).map(queryParamKey => {
          const urlValue = this.uiQueryParams.getValue(this.elementId, queryParamKey);
          /* Only set when given */
          urlValue && this._filterObject && this._filterObject.filter(queryParamKey, (0, _queryParamUtil.normalizeQueryParamValue)(urlValue));
        });
        const _initialSearchTerm = this._filterObject && this._filterObject.getSearchTerm() || null;
        if (_initialSearchTerm !== this.searchTerm) {
          (0, _object.setProperties)(this, {
            searchTerm: _initialSearchTerm,
            isFilterOpen: true,
            '_filterObject.isOpen': true
          });
        }
        const customFilters = this._filterObject && this._filterObject.getCustomFilters() || null;
        if (Object.keys(customFilters).length > 0) {
          (0, _object.setProperties)(this, {
            filterValues: Object.assign({}, customFilters),
            isFilterOpen: true,
            '_filterObject.isOpen': true
          });
        }
      });
    },
    init() {
      this._super(...arguments);
      this._setup();
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super();
      this._setup();
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);

      // reset qp-objects state
      this._filterObject && (0, _object.set)(this, '_filterObject.isOpen', false);
      this.uiQueryParams.unregister(this.elementId);
    },
    /**
     * We define a private task group.
     *
     * The group assures that all the filter, search and reset task
     * have hard-dependency with each other.
     *
     * I.e. they can cancel one an other and assure the we keep the internal
     * filter-state immutable.
     *
     * @property _filterTasks
     * @type {Task}
     * @private
     */
    _filterTasks: (0, _emberConcurrency.taskGroup)().restartable(),
    /**
     * The task receives a qp and a corresponding value and calls
     * the filter-function on the filter-service.
     *
     * @property _filterTask
     * @type {Task}
     * @param {String} qp, the query-paramters name
     * @param {String|Array} value, the value for the qp
     * @private
     */
    _filterTask: (0, _emberConcurrency.task)(function* (qp, value) {
      yield (0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS);
      if (this.uiFilter) {
        yield this.uiFilter.filter(this.key, qp, value, false);
        const customFilters = this._filterObject && this._filterObject.getCustomFilters() || null;
        // Update internal filterValues
        (0, _object.set)(this, 'filterValues', Object.assign({}, customFilters));
      }
    }).group('_filterTasks'),
    /**
     * The task receives a search-term and calls the search-function
     * on the filter-service.
     *
     * @property _searchDebounceTask
     * @type {Task}
     * @param {String} searchTerm, the term to search for
     * @private
     */
    _searchDebounceTask: (0, _emberConcurrency.task)(function* (searchTerm) {
      (0, _object.set)(this, 'searchTerm', searchTerm);
      yield (0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS);
      if (this.uiFilter) {
        yield this.uiFilter.search(this.key, searchTerm, false);
      }
    }).group('_filterTasks'),
    /**
     * Resets all qps and internal state. The api call will only be triggered
     * if there are other qps then pagination related ones.
     *
     * @property _resetTask
     * @type {Task}
     * @private
     */
    _resetTask: (0, _emberConcurrency.task)(function* () {
      const shouldReload = Object.keys(this._filterObject.getCustomFilters()).length || !!this._filterObject.getSearchTerm();
      // clear up component values
      (0, _object.setProperties)(this, {
        isFilterOpen: false,
        '_filterObject.isOpen': false,
        filterValues: {}
      });
      if (shouldReload && this.uiFilter) {
        yield this.uiFilter && this.uiFilter.reset(this.key);
      }
    }).group('_filterTasks'),
    clearExtendedFilter() {
      const newFilterValues = Object.assign({}, this.filterValues);
      this._dynamicFilters.map(dynamicFilter => {
        /* Ignore default filters */
        if (dynamicFilter.groupKey === _constants2.DYNAMIC_FILTER_DEFAULT_GROUP_KEY) {
          return;
        } else if (dynamicFilter.type === 'query' || dynamicFilter.type === 'group_label') {
          // reset components if query or group_label filter type
          return dynamicFilter.components && Object.values(dynamicFilter.components).forEach(filter => {
            this.uiFilter.filter(this.key, filter.key, null);
            delete newFilterValues[filter.key];
          });
        }
        const resetValue = dynamicFilter.type === 'multi' ? [] : null;
        this.uiFilter.filter(this.key, dynamicFilter.key, resetValue);
        delete newFilterValues[dynamicFilter.key];
      });
      (0, _object.set)(this, 'filterValues', newFilterValues);
    },
    closeFilter() {
      this._resetTask.perform();
    },
    onFilter(qp, _ref, _, filters) {
      let {
        value
      } = _ref;
      let qps;
      // Is the filters-param defined and an array, we treat it as multiple-qp.
      if (filters && (0, _array.isArray)(filters)) {
        qps = filters;
      } else {
        // Otherwise the value will be used as qp.
        qps = value;
      }
      return this._filterTask.perform(qp, qps);
    },
    onDateFilter(qp, startDate, endDate, _, filters) {
      let filterValue = null;
      if (startDate && endDate) {
        filterValue = `${startDate},${endDate}`;
      }
      this.send('onFilter', qp, {
        value: filterValue
      }, _, filters);
    },
    onSearch(event) {
      const value = event.target.value;
      return this._searchDebounceTask.perform(value);
    },
    openExtendedFilter() {
      const state = this.uiState.getState('ui-navigation-bar-filterable--extended-filter');
      state && state.open();
    },
    openFilter() {
      (0, _object.setProperties)(this, {
        isFilterOpen: true,
        '_filterObject.isOpen': true
      });
      if (this.searchable) {
        (0, _runloop.next)(this, () => {
          !this.isDestroying && document.getElementById(_constants.SEARCH_INPUT_DOM_ID) && document.getElementById(_constants.SEARCH_INPUT_DOM_ID).focus();
        });
      }
    },
    submitExtendedFilter(filters, navigationDrawerClose) {
      const filterService = this.uiFilter;
      filters.forEach(filter => {
        filterService.filter(this.key, filter.key, filter.value);
      });

      // Update internal filterValues
      const customFilters = this._filterObject && this._filterObject.getCustomFilters() || null;
      (0, _object.set)(this, 'filterValues', Object.assign({}, customFilters));
      typeof navigationDrawerClose === 'function' && navigationDrawerClose();
    }
  }, _applyDecoratedDescriptor(_obj, "clearExtendedFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "clearExtendedFilter"), _obj), _applyDecoratedDescriptor(_obj, "closeFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "closeFilter"), _obj), _applyDecoratedDescriptor(_obj, "onFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onFilter"), _obj), _applyDecoratedDescriptor(_obj, "onDateFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onDateFilter"), _obj), _applyDecoratedDescriptor(_obj, "onSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSearch"), _obj), _applyDecoratedDescriptor(_obj, "openExtendedFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openExtendedFilter"), _obj), _applyDecoratedDescriptor(_obj, "openFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openFilter"), _obj), _applyDecoratedDescriptor(_obj, "submitExtendedFilter", [_object.action], Object.getOwnPropertyDescriptor(_obj, "submitExtendedFilter"), _obj), _obj));
});