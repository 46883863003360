define("@additive-apps/ui/components/ui-card/footer/content/default", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="column content default - flex-auto flex flex-column ohidden font-sm">
    <div class="title - ellipsis">{{or @title ' '}}</div>
    <div class="description - ellipsis secondary">{{or @description ' '}}</div>
  </div>
  */
  {
    "id": "7PBaYjE/",
    "block": "[[[10,0],[14,0,\"column content default - flex-auto flex flex-column ohidden font-sm\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"title - ellipsis\"],[12],[1,[28,[35,0],[[30,1],\" \"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"description - ellipsis secondary\"],[12],[1,[28,[35,0],[[30,2],\" \"],null]],[13],[1,\"\\n\"],[13]],[\"@title\",\"@description\"],false,[\"or\"]]",
    "moduleName": "@additive-apps/ui/components/ui-card/footer/content/default.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});