define("@additive-apps/ui/components/ui-datetime-range-picker", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex {{@classNames}}" ...attributes>
    <div class="w-50 pa1">
      <UiDatetimePicker
        @dateLabel={{t "uiDatetimePicker.startDate"}}
        @timeLabel={{t "uiDatetimePicker.startTime"}}
        @onChange={{this.onChangeStartDate}}
        @initialDate={{this._startDate}}
        @minDate={{this.minDate}}
        @maxDate={{this._endDate}}
        @dateErrorMessage={{this.startErrorMessage}}
        @isTouched={{this.isTouched}}
        @theme={{this.theme}}
        @resetDate={{this.resetDate}}
        @hasFilters={{this.hasFilters}}
        @filters={{this.filters}}
        @defaultFilter={{this.defaultFilter}}
      />
    </div>
    <div class="w-50 pa1">
      <UiDatetimePicker
        @dateLabel={{t "uiDatetimePicker.endDate"}}
        @timeLabel={{t "uiDatetimePicker.endTime"}}
        @onChange={{this.onChangeEndDate}}
        @initialDate={{this._endDate}}
        @minDate={{if this._startDate this._startDate this.minDate}}
        @maxDate={{this.maxDate}}
        @dateErrorMessage={{this.endErrorMessage}}
        @isTouched={{this.isTouched}}
        @theme={{this.theme}}
        @resetDate={{this.resetDate}}
      />
    </div>
  </div>
  */
  {
    "id": "Yr06riH/",
    "block": "[[[11,0],[16,0,[29,[\"flex \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-50 pa1\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@dateLabel\",\"@timeLabel\",\"@onChange\",\"@initialDate\",\"@minDate\",\"@maxDate\",\"@dateErrorMessage\",\"@isTouched\",\"@theme\",\"@resetDate\",\"@hasFilters\",\"@filters\",\"@defaultFilter\"],[[28,[37,1],[\"uiDatetimePicker.startDate\"],null],[28,[37,1],[\"uiDatetimePicker.startTime\"],null],[30,0,[\"onChangeStartDate\"]],[30,0,[\"_startDate\"]],[30,0,[\"minDate\"]],[30,0,[\"_endDate\"]],[30,0,[\"startErrorMessage\"]],[30,0,[\"isTouched\"]],[30,0,[\"theme\"]],[30,0,[\"resetDate\"]],[30,0,[\"hasFilters\"]],[30,0,[\"filters\"]],[30,0,[\"defaultFilter\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"w-50 pa1\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@dateLabel\",\"@timeLabel\",\"@onChange\",\"@initialDate\",\"@minDate\",\"@maxDate\",\"@dateErrorMessage\",\"@isTouched\",\"@theme\",\"@resetDate\"],[[28,[37,1],[\"uiDatetimePicker.endDate\"],null],[28,[37,1],[\"uiDatetimePicker.endTime\"],null],[30,0,[\"onChangeEndDate\"]],[30,0,[\"_endDate\"]],[52,[30,0,[\"_startDate\"]],[30,0,[\"_startDate\"]],[30,0,[\"minDate\"]]],[30,0,[\"maxDate\"]],[30,0,[\"endErrorMessage\"]],[30,0,[\"isTouched\"]],[30,0,[\"theme\"]],[30,0,[\"resetDate\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@classNames\",\"&attrs\"],false,[\"ui-datetime-picker\",\"t\",\"if\"]]",
    "moduleName": "@additive-apps/ui/components/ui-datetime-range-picker.hbs",
    "isStrictMode": false
  });
  /**
   * Component to select a date time range
   *
   * ```hbs
   * {{ui-datetime-range-picker
   *    startDate
   *    endDate
   *    startErrorMessage
   *    endErrorMessage
   *    isTouched
   * }}
   * ```
   *
   * @class ui-datetime-range-picker
   */
  let UiDatetimeRangePickerComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.date | _propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.date), _dec6 = (0, _emberArgTypes.arg)(_propTypes.string | _propTypes.date), _dec7 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec8 = (0, _emberArgTypes.arg)(_propTypes.array), _dec9 = (0, _emberArgTypes.arg)(_propTypes.string), _dec10 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec11 = (0, _emberArgTypes.arg)(_propTypes.object), _dec12 = (0, _emberArgTypes.arg)(_propTypes.object), _dec13 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiDatetimeRangePickerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the reset date of the date input
       *
       * @argument resetDate
       * @type {Date |String}
       * @default null
       */
      _initializerDefineProperty(this, "resetDate", _descriptor, this);
      /**
       * the error message of the end date
       *
       * @argument endErrorMessage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "endErrorMessage", _descriptor2, this);
      /**
       * the error message of the start date
       *
       * @argument startErrorMessage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "startErrorMessage", _descriptor3, this);
      /**
       * the theme of the inputs
       *
       * @argument theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor4, this);
      /**
       * the minimal date that can be selected
       *
       * @argument minDate
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "minDate", _descriptor5, this);
      /**
       * the maximal selectable date
       *
       * @argument maxDate
       * @type {Date | String}
       * @default null
       */
      _initializerDefineProperty(this, "maxDate", _descriptor6, this);
      /**
       * whether the datepicker has filters
       *
       * @argument hasFilters
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "hasFilters", _descriptor7, this);
      /**
       * the filters applied to the datepicker
       *
       * @argument filters
       * @type {Array}
       * @default ['this_week', 'last_week', 'this_month', 'last_month', 'custom_date']
       */
      _initializerDefineProperty(this, "filters", _descriptor8, this);
      /**
       * the default filter
       *
       * @argument defaultFilter
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "defaultFilter", _descriptor9, this);
      /**
       * whether one of the inputs has been touched
       *
       * @property isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTouched", _descriptor10, this);
      _initializerDefineProperty(this, "startDate", _descriptor11, this);
      _initializerDefineProperty(this, "endDate", _descriptor12, this);
      /**
       * the selected start date of the range
       *
       * @property startDate
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "_startDate", _descriptor13, this);
      /**
       * the selected end date of the range
       *
       * @property endDate
       * @type {Date}
       * @default null
       */
      _initializerDefineProperty(this, "_endDate", _descriptor14, this);
      /**
       *  the callback when the range changes
       *
       * @function onChange
       * @param {Date} date.start
       * @param {Date} date.end
       */
      _initializerDefineProperty(this, "onChange", _descriptor15, this);
      this._startDate = this.startDate;
      this._endDate = this.endDate;
    }
    /**
     * changes the start date
     *
     * @function onChangeStartDate
     * @param {Date} date
     */
    onChangeStartDate(date) {
      this._startDate = date;
      this.onChange({
        start: date,
        end: this._endDate
      });
    }
    /**
     * changes the end date
     *
     * @function onChangeEndDate
     * @param {Date} date
     */
    onChangeEndDate(date) {
      this._endDate = date;
      this.onChange({
        start: this._startDate,
        end: date
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "resetDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "endErrorMessage", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "startErrorMessage", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "minDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "maxDate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasFilters", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec8, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return ['this_week', 'last_week', 'this_month', 'last_month', 'custom_date'];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "defaultFilter", [_dec9, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_dec10, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_startDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_endDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeStartDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeEndDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeEndDate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDatetimeRangePickerComponent);
});