define("@additive-apps/ui/components/ui-modal/header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="modal-navigation-bar-spacer">
    <UiNavigationBar
      @title={{@title}}
      @isMenuHidden={{true}}
      @intersectionTargetSelectorId={{@observerId}}
      @hasCustomSentinel={{true}}
      @isBackButton={{this.isBackButton}}
      @onClose={{this.onClose}}
      as |navbar|
    >
      {{yield
        (
          hash
            action=(component navbar.action)
            button=(component navbar.button)
            switch=(component navbar.switch)
        )
      }}
    </UiNavigationBar>
  </div>
  
  */
  {
    "id": "SNfQcFTs",
    "block": "[[[10,0],[14,0,\"modal-navigation-bar-spacer\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\",\"@isMenuHidden\",\"@intersectionTargetSelectorId\",\"@hasCustomSentinel\",\"@isBackButton\",\"@onClose\"],[[30,1],true,[30,2],true,[30,0,[\"isBackButton\"]],[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,4,[[28,[37,2],null,[[\"action\",\"button\",\"switch\"],[[50,[30,3,[\"action\"]],0,null,null],[50,[30,3,[\"button\"]],0,null,null],[50,[30,3,[\"switch\"]],0,null,null]]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"@observerId\",\"navbar\",\"&default\"],false,[\"ui-navigation-bar\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-modal/header.hbs",
    "isStrictMode": false
  });
  let UiModalHeader = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiModalHeader extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the title of the modal
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * Wheter a back button instead of close should be shown in the header
       *
       * @property isBackButton
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isBackButton", _descriptor2, this);
      /**
       * the background color of the modal
       * [theme='white'] $ui-color-white
       * [theme='grey'] $ui-color-near-white
       *
       * @property theme
       * @type {String}
       * @default 'white'
       */
      _initializerDefineProperty(this, "theme", _descriptor3, this);
      /**
       * the id to handle navbar on-scroll behavior
       *
       * @property observerId
       * @type {String}
       * @default ui-modal-header-sentinel
       */
      _initializerDefineProperty(this, "observerId", _descriptor4, this);
      _initializerDefineProperty(this, "onClose", _descriptor5, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isBackButton", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'white';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "observerId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'ui-modal-header-sentinel';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiModalHeader);
});