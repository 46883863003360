define("@additive-apps/ui/templates/components/ui-month-day-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LXxXqcNs",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[30,0,[\"label\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"flex flex-row\"],[12],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@selected\",\"@options\",\"@placeholder\",\"@onChange\"],[[30,0,[\"_selectedDay\"]],[30,0,[\"_dayOptions\"]],[28,[37,2],[\"uiMonthDayPicker.day\"],null],[28,[37,3],[[30,0],\"onChange\",\"day\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2,3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"input\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@selected\",\"@options\",\"@placeholder\",\"@onChange\"],[[30,0,[\"_selectedMonth\"]],[30,0,[\"_monthOptions\"]],[28,[37,2],[\"uiMonthDayPicker.month\"],null],[28,[37,3],[[30,0],\"onChange\",\"month\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"item\"]],null,[[\"@value\"],[[30,5]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,5,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4,5]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]]]]]],[\"form\",\"option\",\"item\",\"option\",\"item\"],false,[\"ui-multi-form-control\",\"ui-select\",\"t\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-month-day-picker.hbs",
    "isStrictMode": false
  });
});