define("@additive-apps/ui/services/ui-toast", ["exports", "@ember/service", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/application"], function (_exports, _service, _array, _object, _computed, _runloop, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    title: null,
    description: null,
    duration: 2500,
    handleState: false,
    cb: null
  };
  const MAX_STACKED_TOASTS = 2;
  const TEST_ENV_TOAST_DURATION = 200;

  /**
   * @class uiToast
   * @public
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _service.default.extend({
    /**
     * contains all scheduled toasts
     *
     * @type Array
     * @property _queue
     * @default null
     * @private
     */
    _queue: null,
    /**
     * contains all active toasts
     *
     * @type Array
     * @property _toasts
     * @default null
     * @private
     */
    _toasts: null,
    queueCount: (0, _computed.reads)('_queue.length'),
    toastCount: (0, _computed.reads)('_toasts.length'),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_queue', (0, _array.A)());
      (0, _object.set)(this, '_toasts', (0, _array.A)());
    },
    /**
     * creates a new toast and adds it to the queue
     * options
     *  - title
     *  - description
     *  - duration
     *  - type: success | error | warning | info
     *
     * @param {Object} options
     * @function showToast
     * @return {Object}, the created toast
     */
    showToast() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let toastOptions = Object.assign({}, DEFAULT_OPTIONS, options);
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const isTestEnv = ENV.environment === 'test';
      if (isTestEnv) {
        toastOptions.duration = TEST_ENV_TOAST_DURATION;
      }

      // if max amount of toasts is reached, try to destroy one non-progress toast
      if (this.toastCount === MAX_STACKED_TOASTS) {
        this._toasts.some(toast => {
          if (!toast.progress) {
            this.destroyToast(toast);
            return true;
          }
        });
      }
      return typeof this.toastCount === 'number' && this.toastCount < MAX_STACKED_TOASTS ? this._toasts.pushObject(toastOptions) : (0, _array.isArray)(this._queue) && this._queue.pushObject(toastOptions);
    },
    /**
     * destroys the provided toast
     *
     * @param {Object} toast
     * @function destroyToast
     */
    destroyToast(toast) {
      if ((0, _array.isArray)(this._queue) && this._queue.includes(toast)) {
        this._queue.removeObject(toast);
        return;
      }
      if ((0, _array.isArray)(this._toasts) && this._toasts.includes(toast)) {
        (0, _object.set)(toast, 'entering', false);
        _runloop.run.later(() => {
          (0, _array.isArray)(this._toasts) && this._toasts.removeObject(toast);
          if (this.toastCount < MAX_STACKED_TOASTS && this.queueCount > 0) {
            this._toasts.pushObject(this._queue.shiftObject());
          }
        }, 300);
      }
    }
  });
});