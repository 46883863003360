define("@additive-apps/ui/components/ui-modal/content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-modal__content flex flex-column flex-grow-1 ph3" ...attributes>
    <div id={{this.observerId}}></div>
    <div class="flex flex-shrink-0">
      {{yield (hash top=(component "ui-blank-template"))}}
    </div>
  
    {{#if this.title}}
      <div class="ui-modal__intro flex flex-column flex-shrink-0 {{if this.isLarge 'mb3' 'mb2'}}">
        {{#if this.subtitle}}
          <div class="pb05 secondary {{if this.isLarge 'font-md' 'font-sm'}}">
            {{this.subtitle}}
          </div>
        {{/if}}
        <div class="primary {{if this.isLarge 'font-xl' 'font-lg'}}">
          {{this.title}}
        </div>
      </div>
    {{/if}}
  
    {{#if this.isLoading}}
      <div class="w-100 flex justify-center items-center flex-grow-1">
        <UiLoading />
      </div>
    {{else}}
      {{yield
        (hash
          body=(component
            "ui-blank-template"
            tagName="div"
            class="ui-modal__content-body flex flex-column flex-shrink-0 font-md"
          )
        )
      }}
    {{/if}}
  </div>
  */
  {
    "id": "fl6LNNVy",
    "block": "[[[11,0],[24,0,\"ui-modal__content flex flex-column flex-grow-1 ph3\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,1,[30,0,[\"observerId\"]]],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-shrink-0\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,1],null,[[\"top\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"title\"]],[[[1,\"    \"],[10,0],[15,0,[29,[\"ui-modal__intro flex flex-column flex-shrink-0 \",[52,[30,0,[\"isLarge\"]],\"mb3\",\"mb2\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"subtitle\"]],[[[1,\"        \"],[10,0],[15,0,[29,[\"pb05 secondary \",[52,[30,0,[\"isLarge\"]],\"font-md\",\"font-sm\"]]]],[12],[1,\"\\n          \"],[1,[30,0,[\"subtitle\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,0],[15,0,[29,[\"primary \",[52,[30,0,[\"isLarge\"]],\"font-xl\",\"font-lg\"]]]],[12],[1,\"\\n        \"],[1,[30,0,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isLoading\"]],[[[1,\"    \"],[10,0],[14,0,\"w-100 flex justify-center items-center flex-grow-1\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,2,[[28,[37,1],null,[[\"body\"],[[50,\"ui-blank-template\",0,null,[[\"tagName\",\"class\"],[\"div\",\"ui-modal__content-body flex flex-column flex-shrink-0 font-md\"]]]]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"if\",\"ui-loading\"]]",
    "moduleName": "@additive-apps/ui/components/ui-modal/content.hbs",
    "isStrictMode": false
  });
  let UiModalContent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class UiModalContent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the title of the modal
       *
       * @property title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * the subtitle of the modal
       *
       * @property subtitle
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "subtitle", _descriptor2, this);
      /**
       * the id to handle navbar on-scroll behavior
       *
       * @property observerId
       * @type {String}
       * @default ui-modal-header-sentinel
       */
      _initializerDefineProperty(this, "observerId", _descriptor3, this);
      /**
       * the size of the modal
       *
       * @property size
       * @type {String}
       * @default 'md'
       */
      _initializerDefineProperty(this, "size", _descriptor4, this);
    }
    /**
     * whether the modal is Large
     *
     * @get isLarge
     * @type {Boolean}
     */
    get isLarge() {
      return this.size === 'lg';
    }

    /**
     * whether the modal is small
     *
     * @computed isLarge
     * @type {Boolean}
     */
    get isSmall() {
      return this.size === 'sm';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "subtitle", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "observerId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'ui-modal-header-sentinel';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'md';
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiModalContent);
});