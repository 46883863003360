define("@additive-apps/ui/templates/components/ui-filter-inputs/range", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DMSUYzFH",
    "block": "[[[11,0],[24,0,\"font-sm secondary mb1\"],[17,1],[12],[1,\"\\n  \"],[1,[30,0,[\"filter\",\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@isDualSlider\",\"@minRangeValue\",\"@maxRangeValue\",\"@defaultMinValue\",\"@defaultMaxValue\",\"@appendix\",\"@format\",\"@onChange\"],[true,[30,0,[\"minRangeValue\"]],[30,0,[\"maxRangeValue\"]],[30,0,[\"defaultMinValue\"]],[30,0,[\"defaultMaxValue\"]],\"€\",\"currency\",[28,[37,1],[[30,0],\"onOptionChange\"],null]]],null],[1,\"\\n\"],[10,0],[14,0,\"mb2\"],[12],[13]],[\"&attrs\"],false,[\"ui-range-slider\",\"action\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-filter-inputs/range.hbs",
    "isStrictMode": false
  });
});