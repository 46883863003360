define("@additive-apps/ui/templates/components/ui-intro", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jf8iDS6C",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"font-\",[30,0,[\"size\"]]],null],\"\\n    \",[52,[28,[37,2],[[30,0,[\"size\"]],\"md\"],null],\"font-medium\"],\"\\n    \",[52,[30,0,[\"isSecondary\"]],\"secondary\",\"primary\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"hasBreadcrumbs\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@rootName\",\"@currentRouteName\",\"@currentRouteNameText\",\"@maxCount\",\"@siblingRoutes\",\"@size\",\"@classNames\"],[[30,0,[\"breadcrumbs\",\"rootName\"]],[30,0,[\"breadcrumbs\",\"currentRouteName\"]],[30,0,[\"breadcrumbs\",\"currentRouteNameText\"]],[30,0,[\"breadcrumbs\",\"maxCount\"]],[30,0,[\"breadcrumbs\",\"siblingRoutes\"]],[30,0,[\"size\"]],\"flex-wrap\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[18,1,[[28,[37,5],null,[[\"title\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"_description\"]],[[[1,\"  \"],[10,0],[15,0,[29,[[52,[28,[37,7],[[28,[37,2],[[30,0,[\"size\"]],\"xl\"],null],[28,[37,2],[[30,0,[\"size\"]],\"lg\"],null]],null],\"font-md pt1\",\"font-sm secondary pt05\"],\"\\n      \",[52,[30,0,[\"isMultiline\"]],\"\",\"ellipsis\"]]]],[12],[1,\"\\n    \"],[1,[30,0,[\"_description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"concat\",\"if\",\"eq\",\"ui-breadcrumbs\",\"yield\",\"hash\",\"component\",\"or\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-intro.hbs",
    "isStrictMode": false
  });
});