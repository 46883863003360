define("@additive-apps/ui/components/ui-multi-form-control/input", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-multi-form-control/input"], function (_exports, _component, _input) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Represents a single input field of the multi-form input-set
   *
   * @class ui-multi-form-control/input
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _input.default,
    classNames: ['ui-multi-form-control--input']
  });
});