define("@additive-apps/media/templates/components/mm-poi", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ksracisd",
    "block": "[[[18,1,[[28,[37,1],null,[[\"movableContainer\"],[[50,\"mm-poi/movable-container\",0,null,[[\"parentElement\",\"onPositionChanged\",\"position\",\"isDisabled\"],[[30,0,[\"element\"]],[28,[37,3],[[30,0],[30,0,[\"onPositionChanged\"]]],null],[30,0,[\"position\"]],[30,0,[\"isDisabled\"]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "@additive-apps/media/templates/components/mm-poi.hbs",
    "isStrictMode": false
  });
});