define("@additive-apps/ui/components/ui-blank-template", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (equal @tagName "div")}}
    <div class="{{@class}} {{@classNames}}" ...attributes {{did-insert this.onDidInsert}}>
      {{yield}}
    </div>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "xt5IMOIQ",
    "block": "[[[41,[28,[37,1],[[30,1],\"div\"],null],[[[1,\"  \"],[11,0],[16,0,[29,[[30,2],\" \",[30,3]]]],[17,4],[4,[38,2],[[30,0,[\"onDidInsert\"]]],null],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,5,null],[1,\"\\n\"]],[]]]],[\"@tagName\",\"@class\",\"@classNames\",\"&attrs\",\"&default\"],false,[\"if\",\"equal\",\"did-insert\",\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-blank-template.hbs",
    "isStrictMode": false
  });
  let UiBlankTemplate = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiBlankTemplate extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "tagName", _descriptor, this);
      _initializerDefineProperty(this, "onDidInsert", _descriptor2, this);
      if (this.tagName !== 'div') {
        this.onDidInsert();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tagName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiBlankTemplate);
});