define("@additive-apps/ui/services/ui-filter", ["exports", "@ember/service", "@ember/debug", "@ember/runloop", "@ember/string", "@ember/utils", "@glimmer/tracking", "ember-concurrency", "rsvp", "@additive-apps/ui/classes/query-parameters", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/constants", "@additive-apps/ui/utils/filter-util", "@additive-apps/ui/utils/parse-url"], function (_exports, _service, _debug, _runloop, _string, _utils, _tracking, _emberConcurrency, _rsvp, _queryParameters, _domUtil, _constants, _filterUtil, _parseUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Filter-service
   * A global proxy to enabled filter, search and pagination-mode for model.
   *
   * It stores each new instance under a given, unique key.
   *
   * When something depends on proper setup filter use the `onReady`-event,
   * which calls a callback when filter is ready-to-use/-interact.
   *
   * @class UiFilterService
   */
  let UiFilterService = _exports.default = (_dec = (0, _emberConcurrency.task)(function* (key) {
    this._isLoading = true;
    this.isDynamicFilterError = false;
    if (this._readyFilters.indexOf(key) === -1) {
      this._readyFilters.push(key);
    }
    if (!Array.isArray(this._onReadyCallbacks[key])) return;
    const callbacks = this._onReadyCallbacks[key].map(callback => callback());
    yield Promise.all(callbacks);
    this._isLoading = this._fetchData.isRunning || this._fetchDynamicFilters.isRunning;
  }), _dec2 = (0, _emberConcurrency.task)(function* (qps) {
    this._isLoading = true;
    if (!qps.name) return;

    // short debounce timeout to prevent multiple fetches
    yield (0, _emberConcurrency.timeout)(10);
    const queryParams = qps.toQueryFilter();
    try {
      (qps === null || qps === void 0 ? void 0 : qps.data) && (qps.isError = false);
      const customFetchRequest = typeof (qps === null || qps === void 0 ? void 0 : qps.request) === 'function' && qps.request;
      const response = yield customFetchRequest ? customFetchRequest(queryParams) : this.store.query(qps.name, queryParams);
      if (response.meta) {
        const {
          meta
        } = response;
        (qps === null || qps === void 0 ? void 0 : qps.data) && (qps.meta = meta);
      }
      (qps === null || qps === void 0 ? void 0 : qps.data) && (qps.data = response.toArray());
    } catch (e) {
      (qps === null || qps === void 0 ? void 0 : qps.data) && (qps.isError = true);
      this._isLoading = this._fetchDynamicFilters.isRunning || this._onReady.isRunning;
    }
    this._isLoading = this._fetchDynamicFilters.isRunning || this._onReady.isRunning;
  }).restartable(), _dec3 = (0, _emberConcurrency.task)(function* (key) {
    var _filter$dynamicFilter;
    const qps = this.getQPFilter(key);
    const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
    let tasks = [];
    tasks.push((0, _emberConcurrency.timeout)(250));
    if (filter.filterable && (_filter$dynamicFilter = filter.dynamicFilters) !== null && _filter$dynamicFilter !== void 0 && _filter$dynamicFilter.url) {
      tasks.push(this._fetchDynamicFilters.perform(filter, key));
    }
    yield (0, _emberConcurrency.all)(tasks);
  }), _dec4 = (0, _emberConcurrency.task)(function* (filter, key) {
    this._isLoading = true;
    const url = filter.dynamicFilters.url;
    const cacheKey = (0, _parseUrl.parseUrl)(url).pathname;
    try {
      let filters = null;
      if (cacheKey && this._dynamicFiltersCache[cacheKey]) {
        filters = this._dynamicFiltersCache[cacheKey];
      } else {
        var _this$currentUser$use;
        const headers = {
          'Accept-Language': ((_this$currentUser$use = this.currentUser.user) === null || _this$currentUser$use === void 0 ? void 0 : _this$currentUser$use.language) || 'de'
        };
        const response = yield this.authenticatedFetch.fetch(url, {
          headers
        });
        if (!response.ok) {
          throw new Error('[UI-FILTER]: dynamic filter fetch');
        }
        const json = yield response.json();
        if (!json.filters) {
          throw new Error('[UI-FILTER]: dynamic filter fetch response rror');
        }
        filters = json.filters;
        (filters || []).map(filter => {
          if (filter.key) {
            filter.key = (0, _string.camelize)(filter.key);
          }
          return filter;
        });
        if (cacheKey) {
          this._dynamicFiltersCache[cacheKey] = filters;
        }
      }
      const queryParams = (0, _filterUtil.dynamicFiltersToQueryParams)(filters);

      // Grep initial url params and set in filter-QP-object
      const initialValues = this._setupQueryParamsService(key, {
        queryParams,
        searchable: filter.searchable,
        paginated: filter.paginated,
        sort: filter.sort
      });
      filter.queryParams = queryParams;
      filter.initialValues = initialValues;
      yield (0, _domUtil.nextTick)();
      filter.setup();
      this._onReady.perform(key);
    } catch (e) {
      this.isDynamicFilterError = true;
    }
    this._isLoading = this._fetchData.isRunning || this._onReady.isRunning;
  }), _class = class UiFilterService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiQueryParams", _descriptor5, this);
      /**
       * Whether dynamic filter has an error
       *
       * @property isDynamicFilterError
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isDynamicFilterError", _descriptor6, this);
      /**
       * Registered callbacks
       *
       * @property _callbacks
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "_callbacks", _descriptor7, this);
      /**
       * The custom request function to fetch the model data
       *
       * @property _customFetchRequest
       * @type {Function}
       * @default null
       */
      _initializerDefineProperty(this, "_customFetchRequest", _descriptor8, this);
      /**
       * Object which holds all dynamic filters responses for caching.
       *
       * @property _dynamicFiltersCache
       * @type {Object}
       */
      _initializerDefineProperty(this, "_dynamicFiltersCache", _descriptor9, this);
      /**
       * Global filters reference
       *
       * @property _filters
       * @type {Object}
       */
      _initializerDefineProperty(this, "_filters", _descriptor10, this);
      /**
       * All registered callbacks for the 'ready' event
       *
       * @property _onReadyCallbacks
       * @type {Object}
       */
      _initializerDefineProperty(this, "_onReadyCallbacks", _descriptor11, this);
      /**
       * Filter keys which are already properly set up (ready)
       *
       * @property _readyFilters
       * @type {Array}
       */
      _initializerDefineProperty(this, "_readyFilters", _descriptor12, this);
      /**
       * Whether service is currently in loading state
       *
       * @property isLoading
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "_isLoading", _descriptor13, this);
      /**
       * Internal event to trigger 'ready' event.
       *
       * @function _onReady
       * @param {String} key The filter key
       * @private
       */
      _initializerDefineProperty(this, "_onReady", _descriptor14, this);
      /**
       * Fetches for given QPs-Object data from the api
       *
       * @function _fetchData
       * @type {Task}
       * @param {QueryParams} qps the filter object
       * @private
       */
      _initializerDefineProperty(this, "_fetchData", _descriptor15, this);
      /*
       * ------------------------------
       * Dynamic filters related logic
       * ------------------------------
       */
      /**
       * Retries fetching dynamic filters
       *
       * @function retryDynamicFiltersFetchTask
       * @type {Task}
       * @param {String} key the filter key
       */
      _initializerDefineProperty(this, "retryDynamicFiltersFetchTask", _descriptor16, this);
      /**
       * Fetches the dynamic filters and caches response for further needs
       *
       * @function _fetchDynamicFilters
       * @type {Task}
       * @param {Object} filter the filter
       * @param {String} key the filter key
       * @private
       */
      _initializerDefineProperty(this, "_fetchDynamicFilters", _descriptor17, this);
    }
    /**
     * Add a new item to the QPs-Objects data array
     *
     * @function addItem
     * @param {String} name the QPs-Objects name
     * @param {String} item the item to add
     * @return {Void}
     */
    addItem(name, item) {
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      return filter === null || filter === void 0 ? void 0 : filter.addItem(item);
    }
    updateData(name, data) {
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      return filter === null || filter === void 0 ? void 0 : filter.updateData(data);
    }

    /**
     * Updates the pagination, if enabled, for a given QPs-Object
     * Active search and filter params remain intact
     *
     * @function changePage
     * @param {String} name the QPs-Objects name
     * @param {Int} page the page to navigate to
     * @return {Promise} promise for api-request
     */
    changePage(name, page) {
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      if (filter !== null && filter !== void 0 && filter.paginated) {
        this._changePage(name, page);
        return this.loadModel(name);
      }
      return (0, _rsvp.resolve)();
    }

    /**
     * Adds, if enabled, passed key-value pair for a given QPs-Object
     *
     * Resets pagination
     *
     * @function filter
     * @param {String} name the QPs-Objects name
     * @param {String} key the query-parameter for the filter
     * @param {String} value the value to filter
     * @param {Boolean} notify Whether it should trigger ui-query-params onChange event (for direct filter-changes not needed)
     * @return {Promise} promise for api-request
     */
    filter(name, key, value) {
      let notify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const qps = this.getQPFilter(name);
      const filter = qps.filter;
      if (filter !== null && filter !== void 0 && filter.filterable) {
        this._changeFilter(name, key, value, notify);
        return this.loadModel(name);
      }
      return (0, _rsvp.resolve)();
    }

    /**
     * Get fetched dynamic filters
     *
     * @function getDynamicFilters
     * @param {String} key the filter key
     * @return {Object[]} dynamicFilters
     */
    getDynamicFilters(key) {
      var _filter$dynamicFilter2;
      const qps = this.getQPFilter(key);
      const filter = qps.filter;
      if (!(filter !== null && filter !== void 0 && (_filter$dynamicFilter2 = filter.dynamicFilters) !== null && _filter$dynamicFilter2 !== void 0 && _filter$dynamicFilter2.url)) return;
      const cacheKey = (0, _parseUrl.parseUrl)(filter.dynamicFilters.url).pathname;
      const filters = this._dynamicFiltersCache[cacheKey];
      return filters || null;
    }

    /**
     * Get all query-param values for a certain QPs-Object
     *
     * @function getFilterValues
     * @param {String} name the QPs-Objects name
     * @return {Object} queryParams
     */
    getFilterValues(name) {
      const qps = this.getQPFilter(name);
      return qps.getValues();
    }

    /**
     * Returns the QueryParameters-Object for the given key.
     * @function getQPFilter
     * @param {String} key
     * @return {QueryParameters} QueryParameters-Object
     */
    getQPFilter(key) {
      (false && !(this._filters[key]) && (0, _debug.assert)(`You tried to get a filter named '${key}' but no such filter is registered`, this._filters[key]));
      return this._filters[key];
    }

    /**
     * Returns a serialized query-params Object that is compatible
     * with the default E.C. query-method.
     * @function getQueryParams
     * @param {String} name the QPs-Objects name
     * @return {Object} queryParams
     */
    getQueryParams(name) {
      const qps = this.getQPFilter(name);
      return qps.toString();
    }

    /**
     * Starts the api-request with a QPs-Object.
     *
     * @function loadModel
     * @param {String} key the QPs-Object identifier
     * @return {Promise} promise-reference to api-request
     */
    async loadModel(key) {
      const qps = this.getQPFilter(key);
      (false && !(qps['name']) && (0, _debug.assert)(`You tried to load a model for the filter '${key}' but the filter has no model name`, qps['name']));
      let model = null;
      try {
        // @ts-ignore
        model = await this._fetchData.perform(qps.filter);
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }
      return model;
    }

    /**
     * Function to register a callback which will be called
     * when filter is ready-to-use.
     *
     * @function onReady
     * @param {String} key
     * @param {Function} callback
     */
    onReady(key, callback) {
      if (typeof callback !== 'function') return;

      /* When already ready, trigger callback instantly */
      if (this._readyFilters.indexOf(key) >= 0) {
        return callback();
      }
      if (!Array.isArray(this._onReadyCallbacks[key])) {
        this._onReadyCallbacks[key] = [];
      }
      this._onReadyCallbacks[key].push(callback);
    }

    /**
     * Register a new filter object, in case there is already one for the given key
     * return it.
     *
     * @function register
     * @param {String} key
     * @param {String} name
     * @param {Object} options
     */
    register(key, name) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      if (this._filters[key]) {
        return this._filters[key];
      }
      let initialValues = {};
      if (!options || options.useHistory === undefined || options.useHistory === true) {
        // Grep initial url params and set in filter-QP-object
        initialValues = this._setupQueryParamsService(key, options);
      }
      if (options.dynamicFilters && !options.dynamicFilters.defaultGroupKey) {
        options.dynamicFilters.defaultGroupKey = _constants.DYNAMIC_FILTER_DEFAULT_GROUP_KEY;
      }
      this._filters[key] = [];
      // TODO: validate passed in filter-params (format, uniqueness, multiple...)
      const filterObject = _queryParameters.default.create(_objectSpread({
        name,
        initialValues
      }, options));
      return this._filters[key] = {
        name,
        filter: filterObject
      };
    }

    /**
     * Register a callback function for the given key.
     *
     * @function registerCallback
     * @param {String} key
     * @param {String} name
     * @param {Function} callback
     */
    registerCallback(key, name) {
      let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      if (!this._filters[key]) return;
      let keyCallbacks = this._callbacks[key] || {};
      keyCallbacks[name] = callback;
      this._callbacks[key] = keyCallbacks;
    }

    /**
     * Resets all filters and remove qps from url.
     *
     * Resets active search, filter and pagination params
     *
     * @function reset
     * @param {String} name the QPs-Objects name
     * @param {Boolean} refreshData Whether the data should be refreshed
     * @return {Promise} promise for api-request
     */
    reset(name) {
      let refreshData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      // reset params
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      if (filter) {
        this.uiQueryParams.reset(name, refreshData, refreshData);
        // Silently (without change-events) reset page value
        filter.paginated && this.uiQueryParams.setValue(name, 'page', '1', false, false);
        filter.reset(refreshData);
        if (this._callbacks[name] && typeof this._callbacks[name].reset === 'function') {
          this._callbacks[name].reset();
        }
        return refreshData && this.loadModel(name);
      }
      return (0, _rsvp.resolve)();
    }

    /**
     * Adds, if enabled, the searchterm as filter for a given QPs-Object
     *
     * Resets pagination
     *
     * @function search
     * @param {String} name the QPs-Objects name
     * @param {String} value the value to filter
     * @param {Boolean} notify Whether it should trigger ui-query-params onChange event (for direct filter-changes not needed)
     * @return {Promise} promise for api-request
     */
    search(name, value) {
      let notify = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      if (filter !== null && filter !== void 0 && filter.searchable) {
        const searchParam = filter === null || filter === void 0 ? void 0 : filter.searchParam;
        this._changeFilter(name, searchParam, value, notify);
        return this.loadModel(name);
      }
      return (0, _rsvp.resolve)();
    }

    /**
     * Resets all filters by rebuilding the whole qp-structure
     *
     * Resets active search, filter and pagination params
     *
     * @function setup
     * @param {String} key the filter key
     * @return {Promise}
     */
    async setup(key) {
      var _filter$dynamicFilter3;
      // reset params
      const qps = this.getQPFilter(key);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      if (filter.filterable && (_filter$dynamicFilter3 = filter.dynamicFilters) !== null && _filter$dynamicFilter3 !== void 0 && _filter$dynamicFilter3.url) {
        return this._fetchDynamicFilters.perform(filter, key);
      }
      await (0, _domUtil.nextTick)();
      filter.setup();

      /* Trigger ready event on next run loop, to ensure all renderings has done */
      (0, _runloop.next)(this, () => this._onReady.perform(key));
      return (0, _rsvp.resolve)();
    }

    /**
     * Unregister an existing filter object.
     *
     * @function unregister
     * @param {String} key the filter that should be unregistered
     */
    unregister(key) {
      (false && !(!!key) && (0, _debug.assert)(`You need to specify the filters key to unregister it`, !!key));
      this.reset(key, false);
      this.uiQueryParams.unregister(key);
      delete this._filters[key];
      delete this._onReadyCallbacks[key];
      const indexOf = this._readyFilters.indexOf(key);
      delete this._readyFilters[indexOf];
      delete this._callbacks[key];
    }

    /**
     * Update current filter in filter- and query param object
     *
     * @function _changeFilter
     * @param {String} name the filter object
     * @param {String} queryParam which query param should be updated
     * @param {Number} value the new value
     * @param {Boolean} notify Whether it should trigger ui-query-params onChange event (for direct filter-changes not needed)
     * @private
     */
    _changeFilter(name, queryParam, value) {
      let notify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;

      // Reset page on filter
      if (filter.paginated) {
        filter.changePage(1);
        // Silently (without change-events) reset page value
        this.uiQueryParams.setValue(name, 'page', '1', false, false);
      }
      filter.filter(queryParam, value);
      this.uiQueryParams.setValue(name, queryParam, value, notify);
      if (this._callbacks[name] && typeof this._callbacks[name].onChange === 'function') {
        this._callbacks[name].onChange();
      }

      // Only replace history when page is in url
      if (!new URLSearchParams(window.location.search).get('page')) return;

      /*
       * As we silently reset page in query-params service
       * to prevent a additional history state just for resetting page,
       * we have to replace the current state to update new `page` value in url
       */
      const _URLSearchParams = new URLSearchParams(window.location.search);
      _URLSearchParams.delete('page');
      const paramsString = _URLSearchParams.toString();
      const params = (0, _utils.isEmpty)(paramsString) ? '' : `?${paramsString}`;
      const {
        origin,
        pathname
      } = window.location;
      const newUrl = origin + pathname + params;
      window.history.replaceState({}, document.title, newUrl);
    }

    /**
     * Update current page in filter- and query param object
     *
     * @function _changePage
     * @param {String} name the filter object
     * @param {Number} page the new page value
     * @private
     */
    _changePage(name, page) {
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      filter.changePage(page);
      this.uiQueryParams.setValue(name, 'page', `${page}`);
    }
    /*
     * ------------------------------
     * Query-params Service related logic
     * ------------------------------
     */

    /**
     * Listener for query params changed
     *
     * @function _queryParamsServiceOnChange
     * @param {String} name  Filter identifier
     * @param {String} key   Which query param changed
     * @param {Number} value The new value
     * @private
     */
    _queryParamsServiceOnChange(name, key, value) {
      var _filter$queryParams$k;
      const qps = this.getQPFilter(name);
      const filter = qps === null || qps === void 0 ? void 0 : qps.filter;
      if (!filter) return;
      const defaultValue = filter.queryParams && ((_filter$queryParams$k = filter.queryParams[key]) === null || _filter$queryParams$k === void 0 ? void 0 : _filter$queryParams$k.defaultValue);

      // Use defaultValue when provided and value is empty
      const _value = !value && defaultValue ? defaultValue : value;
      if (key === 'page') {
        filter.paginated && filter.changePage(_value);
      } else if (key === filter.searchParam) {
        filter.searchable && filter.filter(key, _value);
      } else {
        filter.filterable && filter.filter(key, _value);
      }
      return this.loadModel(name);
    }

    /**
     * Sets up and register current filter options to query params service.
     *
     * @function _setupQueryParamsService
     * @param {String} name  Filter identifier
     * @param {Number} value the new value
     * @return {Object} Object with the current URL values
     * @private
     */
    _setupQueryParamsService(name) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const onChangeHandler = (key, value) => {
        this._queryParamsServiceOnChange(name, key, value);
      };
      let queryParamOptions = {};
      if (options !== null && options !== void 0 && options.queryParams) {
        Object.keys(options.queryParams).map(key => {
          var _options$queryParams$;
          queryParamOptions[key] = {
            onChange: onChangeHandler,
            defaultValue: (_options$queryParams$ = options.queryParams[key]) === null || _options$queryParams$ === void 0 ? void 0 : _options$queryParams$.defaultValue
          };
        });
      }
      if (!options || options.searchable === undefined || options.searchable === true) {
        const searchParam = options.searchParam || _constants.URL_SEARCH_PARAM_KEY;
        queryParamOptions[searchParam] = {
          onChange: onChangeHandler,
          defaultValue: ''
        };
      }
      if (!options || options.paginated === undefined || options.paginated === true) {
        queryParamOptions['page'] = {
          onChange: onChangeHandler,
          defaultValue: options.page || 1
        };
      }
      if (options && options.sort) {
        queryParamOptions['sort'] = {
          onChange: onChangeHandler,
          defaultValue: options.sort
        };
      }
      if (Object.keys(queryParamOptions).length === 0) return;

      // Register query params listeners
      this.uiQueryParams.register(name, queryParamOptions);

      // Get current url values of the currently registered query params
      let queryParams = {};
      Object.keys(queryParamOptions).map(queryParam => {
        queryParams[queryParam] = this.uiQueryParams.getValue(name, queryParam);
      });
      return queryParams;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiQueryParams", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isDynamicFilterError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_callbacks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_customFetchRequest", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_dynamicFiltersCache", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_onReadyCallbacks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_readyFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_onReady", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_fetchData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "retryDynamicFiltersFetchTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_fetchDynamicFilters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});