define("@additive-apps/ui/classes/query-parameters", ["exports", "@ember/object", "@ember/debug", "@ember/utils", "@glimmer/tracking", "@additive-apps/ui/constants"], function (_exports, _object, _debug, _utils, _tracking, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * The QueryParameters Class, is used to keep track of all qp-changes happing on a certain
   * model-instance.
   *
   * To initialize it with preset values the defaults should be passed on the classes creation
   * through the ui-filter service.
   *
   * @module Filter
   * @class QueryParamters
   */
  let QueryParameters = _exports.default = (_class = class QueryParameters extends _object.default {
    /**
     * Whether the fetched array is empty
     * @property isEmpty
     * @type {Boolean}
     */
    get isEmpty() {
      var _this$data;
      return ((_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data.length) === 0;
    }

    /**
     * True if there are active filters and the data array
     * is empty.
     * If the data `isEmpty` but there are no activeFilters, it will
     * remain false
     *
     * @computed hasNoMatches
     * @return {Boolean}
     */
    get hasNoMatches() {
      return this.hasActiveFilter && this.isEmpty;
    }
    constructor() {
      super(...arguments);

      // Build the filter options
      _initializerDefineProperty(this, "modelName", _descriptor, this);
      _defineProperty(this, "filters", null);
      /* queryParams */
      _defineProperty(this, "searchParam", _constants.URL_SEARCH_PARAM_KEY);
      _defineProperty(this, "limitParam", _constants.URL_PER_PAGE_PARAM_KEY);
      _defineProperty(this, "queryParams", null);
      _initializerDefineProperty(this, "_queryParamValues", _descriptor2, this);
      /**
       * Values which should initialy be used for queryParams
       *
       * @property initialValues
       * @type {Object}
       */
      _initializerDefineProperty(this, "initialValues", _descriptor3, this);
      /* internal states */
      /**
       * Per page limit, determinates how many items per page should be requested
       * @property limit
       * @type {Integer}
       * @default 40
       */
      _initializerDefineProperty(this, "limit", _descriptor4, this);
      /**
       * The current page
       * @property page
       * @type {Integer}
       * @default 1
       */
      _initializerDefineProperty(this, "page", _descriptor5, this);
      /**
       * Whether pagination is enabled or not
       * @property paginated
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "paginated", _descriptor6, this);
      /**
       * Whether filter is enabled or not
       * @property filterable
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "filterable", _descriptor7, this);
      /**
       * Whether search is enabled or not
       * @property searchable
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "searchable", _descriptor8, this);
      /**
       * Whether it should use browsers history
       * @property useHistory
       * @type {Boolean}
       * @default true
       */
      _initializerDefineProperty(this, "useHistory", _descriptor9, this);
      /**
       * The actual data returned by the api for given query-params
       * @property data
       * @type {Array}
       * @default []
       */
      _initializerDefineProperty(this, "data", _descriptor10, this);
      /**
       * The meta-data for the current data object.
       * The information is needed in order to know how many pages etc. exist
       * @property meta
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "meta", _descriptor11, this);
      /**
       * Gets set to true if fetching data from the api returned an error.
       * `We don't pass the actual error for now.`
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor12, this);
      /**
       * True if the navbar is in `filter-mode`
       *
       * @computed isOpen
       * @return {Boolean}
       */
      _initializerDefineProperty(this, "isOpen", _descriptor13, this);
      /**
       * True if there are filter or search params.
       * Pagination related qps will be ignored and do not count as such.
       *
       * @computed hasActiveFilter
       * @return {Boolean}
       */
      _initializerDefineProperty(this, "hasActiveFilter", _descriptor14, this);
      this._setup();

      // add function bindings
      this.setup = this._setup.bind(this);
      this.changePage = this._changePage.bind(this);
      this.reset = this._reset.bind(this);
      this.filter = this._setFilter.bind(this);
      this.addItem = this._addItem.bind(this);
      this.updateData = this._updateData.bind(this);
    }

    /**
     * Public API to get a copy of all query-param values
     * @function getValues
     * @return {Object} queryParams
     */
    getValues() {
      return Object.assign({}, this._queryParamValues);
    }

    /**
     * Serializes the internal query-params to a ember-data compatible format.
     * E.D. parses Array-qps in Array-String format
     * i.e.
     *
     * ```json
     *  {
     *  "type": ["fruit", "vegetable"]
     * }
     * ```
     * becomes `type[]=fruit&type[]=vegetable`. In order to make E.D. serialize the passed
     * Array-qps in comma-separated format, we need to serialize all Array-qps in a String.
     * ```json
     * {
     *  "type": ["fruit", "vegetable"]
     * }
     * ```
     * becomes
     * ```json
     * {
     *  "type": "fruit,vegetable"
     * }
     * ```
     *
     * @function toQueryFilter
     * @return {Object} E.D. compatible QueryParameter form
     */
    toQueryFilter() {
      const params = this._queryParamValues;
      let serialized = {};
      Object.keys(params).map(key => {
        if (!(0, _utils.isEmpty)(params[key])) {
          // concatenating an array to a string joins the value separated by comma
          serialized[key] = '' + params[key];
        }
      });
      return serialized;
    }

    /**
     * Serializes the internal query-params to string
     * i.e.
     *
     * ```json
     * {
     *  "category": 1,
     *  "type": ["fruit", "vegetable"]
     * }
     * ```
     * becomes `category=1&type=fruit,vegetable`.
     *
     * @function toString
     * @return {String} the qps string representation
     */
    toString() {
      const params = this._queryParamValues || {};
      const serialized = Object.keys(params).map(key => {
        if (!(0, _utils.isEmpty)(params[key])) {
          return key + '=' + params[key];
        }
      }).filter(item => !!item).join('&');
      return serialized;
    }

    /**
     * Returns the current search value.
     *
     * @function getSearchTerm
     * @return {String} the search term value
     */
    getSearchTerm() {
      const params = Object.assign({}, this._queryParamValues);
      return params[`${this.searchParam}`];
    }

    /**
     * Returns the current sort value.
     *
     * @function getSortValue
     * @return {String} the sort value
     */
    getSortValue() {
      const params = Object.assign({}, this._queryParamValues);
      return params['sort'];
    }

    /**
     * Returns an object with all custom filter params.
     * It's a 1:1 copy of the orginal queryFilter without, search and pagination params.
     *
     * @function getCustomFilters
     * @return {Object} Object containing all custom filters for the query-params object
     */
    getCustomFilters() {
      let params = Object.assign({}, this._queryParamValues);
      /* Remove empty */
      Object.keys(params).filter(key => {
        const param = params[key];
        if ((0, _utils.isEmpty)(param) || Array.isArray(param) && param.length === 0) {
          delete params[key];
        }
      });
      delete params[`${this.searchParam}`];
      delete params[`${this.limitParam}`];
      delete params['page'];
      delete params['sort'];
      return params;
    }

    /**
     * Builds given query-params in order to conform to our api-standards.
     * A QP can be simple or multiple (more then just one value per QP).
     * Those multiple QPs are separated by comma, i.e. type=new,old
     * Multiple QPs should always be treated as a logical OR.
     *
     * @function _setup
     * @private
     */
    _setup() {
      const params = Object.assign({}, this.queryParams);
      (false && !(!params[`${this.searchParam}`]) && (0, _debug.assert)(`You tried to override the searchParam '${this.searchParam}' as queryParam. You should use searchParam instead.`, !params[`${this.searchParam}`]));
      (false && !(!params[`${this.limitParam}`]) && (0, _debug.assert)(`You tried to override the paginationParam '${this.limitParam}' as queryParam.`, !params[`${this.limitParam}`])); // set search param only if search is enabled
      if (this.searchable) {
        params[`${this.searchParam}`] = {
          defaultValue: ''
        };
      }
      // set default pagination options only if the filter has pagination enabled
      if (this.paginated) {
        // set initial page
        params['page'] = {
          defaultValue: this.page || 1
        };
        // set how many elements should be fetched per page
        params[`${this.limitParam}`] = {
          defaultValue: this.limit
        };
      }

      // set sort options if they are set
      if (this.sort) {
        params['sort'] = {
          defaultValue: this.sort
        };
      }

      // get values of changed filters to overwrite initialValues
      const customFilters = this.getCustomFilters();
      const initialCustomFilters = Object.assign({}, this.initialValues, customFilters);
      const _paramValues = Object.keys(params).reduce((value, key) => {
        const _current = params[key];
        if ((0, _utils.isPresent)(_current)) {
          const presetValue = initialCustomFilters[key];
          if (presetValue) {
            if (_current.multiple && typeof presetValue === 'string') {
              value[key] = presetValue.split(',');
            } else {
              value[key] = presetValue;
            }
          } else {
            value[key] = _current.defaultValue ? _current.defaultValue : _current.multiple ? [] : '';
          }
        }
        return value;
      }, {});
      this._queryParamValues = _paramValues;
      this._updateActiveFilters();
    }

    /**
     * Resets all filters
     * @function _reset
     * @private
     */
    _reset() {
      this.initialValues = {};
      this._setup();
    }

    /**
     * Adds given key-value pair as query-parameter filter.
     *
     * Resets pagination
     *
     * @function setFilter
     * @param {String} key the query-parameter for the filter
     * @param {String} value the value to filter
     * @private
     */
    _setFilter(key) {
      let value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this._queryParamValues[`${key}`] = value;
      this._updateActiveFilters();
    }

    /**
     * Updates the pagination.
     * Active search and filter params remain intact
     *
     * @function _changePage
     * @param {Int} page, the page to navigate to
     * @private
     */
    _changePage(page) {
      this.page = page;
      this._queryParamValues['page'] = page;
    }

    /**
     * Adds a new item to the data array
     *
     * @function _addItem
     * @param {Object} item, the item to add
     * @private
     */
    _addItem(item) {
      const data = this.data.toArray();
      data.push(item);
      this.data = data;
    }

    /**
     * Adds a new item to the data array
     *
     * @function _addItem
     * @param {Object} item, the item to add
     * @private
     */
    _updateData(data) {
      this.data = data;
    }

    /**
     * Sets the `hasActiveFilter` property if there are customFilters
     * or an active search.
     *
     * @function _updateActiveFilters
     * @private
     */
    _updateActiveFilters() {
      const customFilterKeys = Object.keys(this.getCustomFilters());
      if (customFilterKeys.length || this.getSearchTerm()) {
        this.hasActiveFilter = true;
      } else {
        this.hasActiveFilter = false;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modelName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_queryParamValues", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "initialValues", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _constants.PER_PAGE_DEFAULT;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "paginated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filterable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "searchable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "useHistory", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasActiveFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
});