define("@additive-apps/ui/components/ui-modal/footer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-modal__footer flex justify-end pa3 flex-shrink-0" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "ngT8CDQR",
    "block": "[[[11,0],[24,0,\"ui-modal__footer flex justify-end pa3 flex-shrink-0\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@additive-apps/ui/components/ui-modal/footer.hbs",
    "isStrictMode": false
  });
  // eslint-disable-next-line ember/no-empty-glimmer-component-classes
  class UiModalFooter extends _component2.default {}
  _exports.default = UiModalFooter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiModalFooter);
});