define("@additive-apps/ui/components/ui-filter-inputs/range", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@ember/object", "@additive-apps/ui/templates/components/ui-filter-inputs/range"], function (_exports, _base, _object, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    layout: _range.default,
    defaultMinValue: (0, _object.computed)('filter.key', 'initialValues', 'minRangeValue', {
      get() {
        if (!this.initialValues || !this.initialValues[this.filter.key]) {
          return this.minRangeValue;
        }
        return Number(this.initialValues[this.filter.key][0]);
      }
    }),
    defaultMaxValue: (0, _object.computed)('filter.key', 'initialValues', 'maxRangeValue', {
      get() {
        if (!this.initialValues || !this.initialValues[this.filter.key]) {
          return this.maxRangeValue;
        }
        return Number(this.initialValues[this.filter.key][1]);
      }
    }),
    minRangeValue: (0, _object.computed)('filter.values', {
      get() {
        var _this$filter;
        return (_this$filter = this.filter) === null || _this$filter === void 0 || (_this$filter = _this$filter.values[0]) === null || _this$filter === void 0 ? void 0 : _this$filter.value;
      }
    }),
    maxRangeValue: (0, _object.computed)('filter.values', {
      get() {
        var _this$filter2;
        return (_this$filter2 = this.filter) === null || _this$filter2 === void 0 || (_this$filter2 = _this$filter2.values[1]) === null || _this$filter2 === void 0 ? void 0 : _this$filter2.value;
      }
    }),
    // eslint-disable-next-line ember/no-actions-hash
    actions: {
      onOptionChange(min, max) {
        if (!min && !max) {
          return this._onChange(this.filter.key, null);
        }
        this._onChange(this.filter.key, [min, max]);
      }
    }
  });
});