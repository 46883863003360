define("@additive-apps/ui/components/ui-menu-content-inner", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@additive-apps/ui/templates/components/ui-menu-content-inner"], function (_exports, _component, _computed, _runloop, _uiMenuContentInner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiMenuContentInner.default,
    classNames: ['ui-menu-content'],
    attributeBindings: ['width'],
    classNameBindings: ['dense:ui-dense'],
    enabledMenuItems: (0, _computed.filterBy)('childComponents', 'isDisabled', false),
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      _runloop.run.later(() => {
        let focusTarget = this.element.querySelector('.md-menu-focus-target');
        if (!focusTarget) {
          var _this$enabledMenuItem;
          focusTarget = (_this$enabledMenuItem = this.enabledMenuItems) === null || _this$enabledMenuItem === void 0 || (_this$enabledMenuItem = _this$enabledMenuItem.firstObject) === null || _this$enabledMenuItem === void 0 || (_this$enabledMenuItem = _this$enabledMenuItem.element) === null || _this$enabledMenuItem === void 0 ? void 0 : _this$enabledMenuItem.firstElementChild;
        }
        if (focusTarget) {
          focusTarget.focus();
        }
      });
    },
    keyDown(ev) {
      switch (ev.which) {
        case 27:
          this.dropdown.actions.close();
          break;
        case 37:
        case 38:
          ev.preventDefault();
          this.focusMenuItem(ev, -1);
          break;
        case 39:
        case 40:
          ev.preventDefault();
          this.focusMenuItem(ev, 1);
          break;
      }
    },
    focusMenuItem(e, direction) {
      let currentItem = e.target.closest('md-menu-item');
      let children = this.enabledMenuItems;
      let items = children.map(child => child.element);
      let currentIndex = items.indexOf(currentItem);

      // Traverse through our elements in the specified direction (+/-1) and try to
      // focus them until we find one that accepts focus
      for (let i = currentIndex + direction; i >= 0 && i < items.length; i = i + direction) {
        let focusTarget = items[i].firstElementChild || items[i];
        let didFocus = this.attemptFocus(focusTarget);
        if (didFocus) {
          break;
        }
      }
    },
    attemptFocus(el) {
      if (el && el.getAttribute('tabindex') !== -1) {
        el.focus();
        if (document.activeElement === el) {
          return true;
        } else {
          return false;
        }
      }
    }
  });
});