define("@additive-apps/ui/components/ui-collapsible-table/rows", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @rows as |row|}}
    <tbody class="ui-collapsible-table__body-row" data-test-ui-collapsible-table-row>
      <UiCollapsibleTable::Row
        @row={{row}}
        @deepLevel={{@deepLevel}}
        @descriptionTranslationKey={{@descriptionTranslationKey}}
        @firstColumnWidth={{@firstColumnWidth}}
        @isFirstColumnFixed={{@isFirstColumnFixed}}
        @scrollLeft={{@scrollLeft}}
        @onHover={{this.applyHover}}
        @onHoverLeave={{this.removeHover}}
        @onClose={{this._onClose}}
        @onOpen={{this._onOpen}} as |row|
      >
        {{yield
          (hash
            valueColumn=row.valueColumn
            afterFirstColumn=row.afterFirstColumn
            rowMeta=row.rowMeta
            deepLevel=row.deepLevel
            columnIndex=row.columnIndex
          )
        }}
      </UiCollapsibleTable::Row>
    </tbody>
  {{/each}}
  */
  {
    "id": "XLfGcyMY",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[10,\"tbody\"],[14,0,\"ui-collapsible-table__body-row\"],[14,\"data-test-ui-collapsible-table-row\",\"\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@row\",\"@deepLevel\",\"@descriptionTranslationKey\",\"@firstColumnWidth\",\"@isFirstColumnFixed\",\"@scrollLeft\",\"@onHover\",\"@onHoverLeave\",\"@onClose\",\"@onOpen\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,0,[\"applyHover\"]],[30,0,[\"removeHover\"]],[30,0,[\"_onClose\"]],[30,0,[\"_onOpen\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,9,[[28,[37,4],null,[[\"valueColumn\",\"afterFirstColumn\",\"rowMeta\",\"deepLevel\",\"columnIndex\"],[[30,8,[\"valueColumn\"]],[30,8,[\"afterFirstColumn\"]],[30,8,[\"rowMeta\"]],[30,8,[\"deepLevel\"]],[30,8,[\"columnIndex\"]]]]]]],[1,\"\\n    \"]],[8]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@rows\",\"row\",\"@deepLevel\",\"@descriptionTranslationKey\",\"@firstColumnWidth\",\"@isFirstColumnFixed\",\"@scrollLeft\",\"row\",\"&default\"],false,[\"each\",\"-track-array\",\"ui-collapsible-table/row\",\"yield\",\"hash\"]]",
    "moduleName": "@additive-apps/ui/components/ui-collapsible-table/rows.hbs",
    "isStrictMode": false
  });
  /**
   * A wrapper which iterates through all the given rows.
   *
   * This component is used recursively,
   * the `deepLevel` indicates the current depth.
   *
   * @class ui-collapsible-table/rows
   */
  let UiCollapsibleTableRows = _exports.default = (_class = class UiCollapsibleTableRows extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The current depth
       *
       * @argument deepLevel
       * @type {Integer}
       * @default 0
       */
      _initializerDefineProperty(this, "deepLevel", _descriptor, this);
      /**
       * the width of the first column
       *
       * @argument firstColumnWidth
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "firstColumnWidth", _descriptor2, this);
      /**
       * whether the first column is fixed
       *
       * @argument isFirstColumnFixed
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFirstColumnFixed", _descriptor3, this);
      /**
       * Parents DOM element
       *
       * @argument parentElement
       * @type {Object}
       * @default undefined
       */
      _initializerDefineProperty(this, "parentElement", _descriptor4, this);
      /**
       * the objects containing the values of the rows
       *
       * @argument rows
       * @type {Object[]}
       * @default undefined
       */
      _initializerDefineProperty(this, "rows", _descriptor5, this);
    }
    /**
     * Callback called when child gets closed
     *
     * @function onClose
     */
    onClose() {}

    /**
     * Callback called when child gets opened
     *
     * @function onOpen
     */
    onOpen() {}
    _onClose() {
      this.onClose(...arguments);
    }
    _onOpen() {
      this.applyHover(...arguments);
      this.onOpen(...arguments);
    }
    applyHover(rowElement, rowDepthLevel) {
      let currentNode = rowElement;
      let currentDepthLevel = currentNode.getAttribute('data-depth-level');

      /*
       * Sets `hover` class to each sibling
       * where the `data-depth-level` is higher than the previous element.
       * Which results in a situation where each depper child (are siblings)
       * has the `hover` class with the background-color.
       */
      do {
        currentNode.classList.add('hover');
        currentNode = currentNode.nextElementSibling;
        currentDepthLevel = currentNode && currentNode.getAttribute('data-depth-level') || -1;
      } while (currentDepthLevel > rowDepthLevel);
    }
    removeHover() {
      this.parentElement.querySelectorAll('.hover').forEach(element => {
        element.classList.remove('hover');
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "deepLevel", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firstColumnWidth", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFirstColumnFixed", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "parentElement", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rows", [_emberArgTypes.arg, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_emberArgTypes.arg], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpen", [_emberArgTypes.arg], Object.getOwnPropertyDescriptor(_class.prototype, "onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyHover", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyHover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeHover", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeHover"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiCollapsibleTableRows);
});