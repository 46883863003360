define("@additive-apps/configurator/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateUrl = _exports.validateText = _exports.validateContentBlockValue = _exports.pathToObject = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @function pathToObject
   * @param {String} path hte path representation of an object
   * @param {Any} value the value that gets assigned to the newly created object
   * @return {Object} the object with the proper value
   */
  const pathToObject = _exports.pathToObject = function pathToObject() {
    let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    let value = arguments.length > 1 ? arguments[1] : undefined;
    // in order to recursively build a nested object we have to start from the end
    const orderedPath = path.split('.').reverse();
    const o = orderedPath.reduce((prev, current, index) => {
      return index === 0 ? {
        [current]: value
      } : {
        [current]: _objectSpread({}, prev)
      };
    }, {});
    return o;
  };

  /**
   * Validates a configuration item property
   *
   * @function validateConfigurationItemValue
   * @param {String} item
   */
  const validateContentBlockValue = _exports.validateContentBlockValue = function validateContentBlockValue() {
    var _item$value, _item$value2, _item$value3, _item$value4, _item$value5, _item$value6, _item$value10, _item$value11, _item$value12;
    let item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let allowedLinkPlaceholders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    let allowedTextPlaceholders = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let isNewsletterApp = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    if (item.validation) {
      if (item.validation.required) {
        const validationObject = {};
        const now = new Date();
        switch (item.type) {
          case 'link':
            if (!((_item$value = item.value) !== null && _item$value !== void 0 && _item$value.text)) {
              validationObject.text = 'required';
            }
            if (!((_item$value2 = item.value) !== null && _item$value2 !== void 0 && _item$value2.url) && !((_item$value3 = item.value) !== null && _item$value3 !== void 0 && _item$value3.name) && !((_item$value4 = item.value) !== null && _item$value4 !== void 0 && _item$value4.survey)) {
              validationObject.url = 'required';
            }
            if (Object.keys(validationObject).length > 0) {
              return validationObject;
            }
            break;
          case 'images':
          case 'images_text':
            if (((_item$value5 = item.value) === null || _item$value5 === void 0 ? void 0 : _item$value5.length) === 0) {
              return 'required';
            } else {
              item.value.forEach((value, key) => {
                if (!value.mediumId || !value.url) {
                  validationObject[key] = 'required';
                }
              });
              if (Object.keys(validationObject).length > 0) {
                return validationObject;
              }
            }
            break;
          case 'medium':
            if (!((_item$value6 = item.value) !== null && _item$value6 !== void 0 && _item$value6.mediumId)) {
              return 'required';
            }
            break;
          case 'offer':
            {
              var _item$value8, _item$value9, _item$value$content$t;
              if (isNewsletterApp) {
                var _item$value7;
                if (!((_item$value7 = item.value) !== null && _item$value7 !== void 0 && _item$value7.id)) {
                  return 'required';
                }
                break;
              }
              if (!((_item$value8 = item.value) !== null && _item$value8 !== void 0 && _item$value8.id)) {
                return 'required';
              }
              if (!((_item$value9 = item.value) !== null && _item$value9 !== void 0 && _item$value9.content)) {
                return 'unpublishedContent';
              }
              const isActive = ((_item$value$content$t = item.value.content.timespans) === null || _item$value$content$t === void 0 ? void 0 : _item$value$content$t.some(timespan => {
                const endDate = new Date(timespan.endsAt);
                return now < endDate;
              })) || false;
              if (!isActive) {
                return 'expiredContent';
              }
              break;
            }
          case 'room':
          case 'image_gallery':
            if (!((_item$value10 = item.value) !== null && _item$value10 !== void 0 && _item$value10.id)) {
              return 'required';
            }
            if (!((_item$value11 = item.value) !== null && _item$value11 !== void 0 && _item$value11.content)) {
              return 'unpublishedContent';
            }
            break;
          case 'usp_category':
            if (!((_item$value12 = item.value) !== null && _item$value12 !== void 0 && _item$value12.category) && !item.isHidden) {
              return 'required';
            }
            break;
          default:
            if (!item.value) {
              return 'required';
            }
        }
      }
      if (item.validation.contentRequired) {
        var _item$value13, _item$value14, _item$value15;
        const hasContent = ((_item$value13 = item.value) === null || _item$value13 === void 0 || (_item$value13 = _item$value13.categories) === null || _item$value13 === void 0 ? void 0 : _item$value13.length) > 0 || ((_item$value14 = item.value) === null || _item$value14 === void 0 || (_item$value14 = _item$value14.content) === null || _item$value14 === void 0 ? void 0 : _item$value14.length) > 0 || ((_item$value15 = item.value) === null || _item$value15 === void 0 || (_item$value15 = _item$value15.category) === null || _item$value15 === void 0 ? void 0 : _item$value15.publicCount) > 0;
        if (!hasContent) {
          return 'noContent';
        }
      }
      if (!isNaN(item.validation.minValue)) {
        if (!isNaN(item.value)) {
          const value = item.sync ? item.syncedValue : item.value;
          if (value < item.validation.minValue) {
            return {
              minValue: item.validation.minValue
            };
          }
        }
      }
      if (!isNaN(item.validation.maxValue)) {
        if (!isNaN(item.value)) {
          const value = item.sync ? item.syncedValue : item.value;
          if (value > item.validation.maxValue) {
            return {
              maxValue: item.validation.maxValue
            };
          }
        }
      }
    }
    if (item.type === 'link') {
      var _item$value16, _item$value17;
      if (validateUrl((_item$value16 = item.value) === null || _item$value16 === void 0 ? void 0 : _item$value16.url, allowedLinkPlaceholders)) {
        return {
          url: 'url'
        };
      }
      if (validateText((_item$value17 = item.value) === null || _item$value17 === void 0 ? void 0 : _item$value17.text, allowedTextPlaceholders)) {
        return {
          text: 'placeholder'
        };
      }
    }
    if (['images', 'images_text'].indexOf(item.type) >= 0) {
      var _item$value18;
      if (((_item$value18 = item.value) === null || _item$value18 === void 0 ? void 0 : _item$value18.length) !== 0) {
        const validationObject = {};
        item.value.forEach((image, key) => {
          if (validateUrl(image.link, allowedLinkPlaceholders)) {
            validationObject[key] = 'url';
          }
          if (validateText(image.text, allowedTextPlaceholders)) {
            validationObject[key] = 'textPlaceholder';
          }
        });
        if (Object.keys(validationObject).length > 0) {
          return validationObject;
        }
      }
    }
    if (item.type === 'accommodations') {
      var _item$value19, _item$value20;
      if (item.isHidden) {
        return '';
      }
      if (((_item$value19 = item.value) === null || _item$value19 === void 0 ? void 0 : _item$value19.length) < 2) {
        return {
          minCount: 2
        };
      }
      const validationObject = {};
      (_item$value20 = item.value) === null || _item$value20 === void 0 || _item$value20.forEach((accommodation, key) => {
        if (!accommodation.text) {
          validationObject[key] = 'required';
        } else if (!accommodation.channelCode) {
          validationObject[key] = 'channelCode';
        }
      });
      if (Object.keys(validationObject).length > 0) {
        return validationObject;
      }
    }
    if (typeof item.value === 'string' && validateText(item.value, allowedTextPlaceholders)) {
      return 'placeholder';
    }
    return '';
  };
  const validateUrl = _exports.validateUrl = function validateUrl(value) {
    let allowedLinkPlaceholders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!value) {
      return false;
    }
    if (value !== value.trim()) {
      return true;
    }
    const isUrl = value.match(/(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    const isPhoneUrl = value.match(/tel:([+][ ]*[0-9])?[ \-0-9]*$/);
    const isMailUrl = value.match(/mailto:\S+@\S+\.\S+/) && !/\s/g.test(value);
    if (!isUrl && !isPhoneUrl && !isMailUrl) {
      // allow link placeholders of shape {#PLACEHOLDER#}
      const placeholderRegex = /{#(.*)#}/gm;
      let regexResult = placeholderRegex.exec(value);
      if (!Array.isArray(regexResult) || regexResult.length < 2 || !allowedLinkPlaceholders.includes(regexResult[1].toUpperCase().trim()) // trim PLACEHOLDER to allow spaces around it
      ) {
        return true;
      }
    }
    return false;
  };
  const validateText = _exports.validateText = function validateText(value) {
    let allowedTextPlaceholders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!value || !Array.isArray(allowedTextPlaceholders) || allowedTextPlaceholders.length === 0) {
      return false;
    }

    // allow link placeholders of shape {#PLACEHOLDER#}
    const placeholderRegex = /{#(.*)#}/gm;
    let regexResult = placeholderRegex.exec(value);
    if (Array.isArray(regexResult) && !allowedTextPlaceholders.includes(regexResult[1].toUpperCase().trim()) // trim PLACEHOLDER to allow spaces around it
    ) {
      return true;
    }
    return false;
  };
});