define("@additive-apps/ui/components/ui-publish-resource", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-publish-resource", "@ember/object/computed", "ember-concurrency", "@ember/object", "@ember/service", "@ember/array", "rsvp", "@ember/utils", "ember-animated/transitions/fade", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "date-fns", "@additive-apps/ui/utils/constants", "@additive-apps/utils/validators/date", "@additive-apps/ui/utils/function-util"], function (_exports, _component, _uiPublishResource, _computed, _emberConcurrency, _object, _service, _array, _rsvp, _utils, _fade, _emberChangeset, _emberChangesetValidations, _validators, _dateFns, _constants, _date, _functionUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * HOC that handles internally the publishing and undpublishing of resources.
   * @class ui-publish-resource
   */
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    store: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    layout: _uiPublishResource.default,
    animatedTransition: _fade.default,
    /**
     * whether the published resource has a time limitiation
     *
     * @property hasDateRange
     * @type {Boolean}
     * @default false
     */
    hasDateRange: false,
    /**
     * Triggers loading state while a promise is running.
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: (0, _computed.alias)('getPublishableResources.isRunning'),
    /**
     * tha actual reference to the model whichs public states
     * should be updated
     * @property data
     * @type {Object}
     */
    data: null,
    /**
     * The given models name in order to build the adapter-url
     * @property modelName
     * @type {Object}
     */
    modelName: null,
    /**
     * whether there are no selected languages
     *
     * @computed hasNoSelectedLanguages
     * @type {Boolean}
     */
    hasNoSelectedLanguages: (0, _computed.not)('hasSelectedLanguages'),
    /**
     * whether the date range selection should be enabled
     *
     * @computed dateRangeIsOpen
     * @type {Boolean}
     */
    dateRangeIsOpen: (0, _computed.and)('hasSelectedLanguages', 'hasDateRange'),
    /**
     * whether at least one language is selected
     *
     * @computed hasSelectedLanguages
     * @type {Boolean}
     */
    hasSelectedLanguages: (0, _object.computed)('languages.{[],@each.isChecked}', 'isPublished', {
      get() {
        return this.languages && this.languages.find(lang => lang.isChecked) || this.isPublished;
      }
    }),
    /**
     * Callback function that gets triggered after the languages have been
     * published successfully
     *
     * @function onPublish
     * @return {Object} the updated model
     */
    onPublish() {},
    /**
     * Function gets executed when the users clicks
     * on the close button.
     *
     * @function onClose
     */
    onClose() {},
    /**
     * Function that gets executed when a validation errors
     * occurs during publishing
     *
     * @function onValidationError
     */
    onValidationError() {},
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'date', {
        start: null,
        end: null
      });
      const validation = {
        start: [(0, _validators.validatePresence)({
          presence: true,
          message: this.intl.t('uiPublishResource.errors.required')
        }),
        // use custom validator to support ember-changeset 2.x
        (key, newValue) => {
          if ((0, _utils.isEmpty)(newValue) || (0, _dateFns.isAfter)(new Date(newValue), new Date())) {
            return true;
          }
          return this.intl.t('uiDatetimePicker.error.beforeToday');
        }, (0, _date.validateDateDependency)({
          allowBlank: true,
          on: 'end',
          type: 'before',
          allowEmptyDependency: true,
          message: this.intl.t('uiPublishResource.errors.before')
        })],
        end: [(0, _validators.validatePresence)({
          presence: true,
          message: this.intl.t('uiPublishResource.errors.required')
        }), (0, _date.validateDateDependency)({
          allowBlank: true,
          on: 'start',
          type: 'after',
          allowEmptyDependency: true,
          message: this.intl.t('uiPublishResource.errors.after')
        })]
      };
      const changeset = new _emberChangeset.default(this.date, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.set)(this, 'changeset', changeset);
      (0, _object.set)(this, 'isPublished', this.data.publishedState === 'success');
    },
    /**
     * The tasks checks if there are some missing dependencies or certian errors for a
     * given model or part of it (translations).
     *
     * if there are errors, the responsible translations gets disabled and can't be published.
     * There are some cases where the whole object can't be published, i.e. if a required field
     * is invalid or missing. In that case we should think about a unterstandable global solution.
     *
     * @type {Task}
     * @function getPublishableResources
     */
    getPublishableResources: (0, _emberConcurrency.task)(function* () {
      try {
        let tasks = [];
        // check if there are languages that can't be published
        const adapter = this.store.adapterFor(this.modelName);
        const computedUrl = adapter.buildURL(this.modelName, this.data && this.data.id);
        // check for errors
        const request = this.authenticatedFetch.fetch(`${computedUrl}/publish`);
        tasks.push(request);
        tasks.push((0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS));
        const [response] = yield (0, _emberConcurrency.all)(tasks);

        // build publishable changesets based on api response
        const languages = this.data.availableLanguages && this.data.availableLanguages.map(lang => {
          // Check the current state
          const isPublic = (this.data.publishedLanguages || []).includes(lang);
          return {
            key: lang,
            isChecked: isPublic,
            isPublic,
            errors: []
          };
        });
        if (!response || !response.ok) {
          const {
            errors
          } = yield response.json();
          languages === null || languages === void 0 || languages.forEach(language => {
            const err = errors[language.key] && formatApiErrors(errors[language.key]);
            if (err) language.errors = err;
          });
          const shared = errors['shared'] && formatApiErrors(errors['shared']);
          (0, _object.set)(this, 'shared', shared);
        } else {
          var _resource$modelKey;
          const resource = yield response.json();

          // if the resource is time limited preselect the dates
          const modelKey = resource && Object.keys(resource)[0];
          if (modelKey && (_resource$modelKey = resource[modelKey]) !== null && _resource$modelKey !== void 0 && _resource$modelKey.timeLimited) {
            this.changeset.set('start', resource[modelKey].publicFrom);
            this.changeset.set('end', resource[modelKey].publicUntil);
            yield this.changeset.validate();
            (0, _object.set)(this, 'hasDateRange', true);
          }
        }
        (0, _object.set)(this, 'languages', languages);
      } catch (error) {
        return (0, _rsvp.reject)(error);
      }
    }).on('init'),
    /**
     * The tasks publishs all languages where the switch is enabled.
     * For publishing we neet to send a PUT request on the resources publish-endpoint
     *
     * @type {Task}
     * @function publishResources
     */
    publishResources: (0, _emberConcurrency.task)(function* () {
      (0, _object.set)(this, 'isTouched', true);
      try {
        let tasks = [];
        const store = this.store;
        const adapter = store.adapterFor(this.modelName);
        const computedUrl = adapter.buildURL(this.modelName, this.data && this.data.id);
        const languagesToPublish = [];
        (this.languages || []).forEach(item => {
          if (item.isChecked) {
            languagesToPublish.push(item.key);
          }
        });
        const body = {
          languages: languagesToPublish,
          public: this.isPublished
        };
        if (this.hasDateRange) {
          yield this.changeset.validate();

          // set the date limitations
          if (this.changeset.isValid) {
            // send timelimited only if publishing
            if (this.isPublished || languagesToPublish.length > 0) {
              body['timeLimited'] = true;
              body['publicFrom'] = this.changeset.get('start');
              body['publicUntil'] = this.changeset.get('end');
            }
          } else if (!this.changeset.isValid) {
            // return if changeset is not valid
            return;
          }
        }
        const request = yield this.authenticatedFetch.fetch(`${computedUrl}/publish`, {
          method: 'PUT',
          body: JSON.stringify(body)
        });
        tasks.push(request);
        tasks.push((0, _emberConcurrency.timeout)(_constants.REQUEST_DEBOUNCE_MS));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response || !response.ok) {
          if (response.status === 422) {
            const {
              errors
            } = yield response.json();
            if (!(errors !== null && errors !== void 0 && errors.shared) || !(0, _functionUtil.hasBody)(this.onValidationError)) {
              throw new Error('[PUBLISH] Unknown validation error');
            }
            const errorMessages = errors === null || errors === void 0 ? void 0 : errors.shared;
            const firstError = errorMessages[Object.keys(errorMessages)[0]][0];
            return this.onValidationError(firstError);
          }

          // throw error, show toast or show an error dialog
          throw new Error('[PUBLISH] resource error');
        }
        const resource = yield response.json();
        const modelClass = store.modelFor(this.modelName);
        const serializer = store.serializerFor(this.modelName);
        if (modelClass && serializer) {
          const resourceJson = resource[this.modelName] || {};
          const data = serializer.normalizeResponse(store, modelClass, resource, resourceJson.id, 'findRecord');
          store.push(data);
        } else {
          store.pushPayload(this.modelName, resource);
        }
        this.onPublish(resource);
        this.onClose();
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('uiToast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * sets the date range
       *
       * @function onDateChange
       * @param {Object} dateRange
       * @param {Date} dateRange.start
       * @param {Date} dateRange.end
       */
      onChangeDate(_ref) {
        let {
          start,
          end
        } = _ref;
        this.changeset.set('start', start);
        // set default time of enddate to 23:59
        if ((0, _dateFns.getHours)(end) === 0 && (0, _dateFns.getMinutes)(end) === 0) {
          this.changeset.set('end', (0, _dateFns.endOfDay)(end));
        } else {
          this.changeset.set('end', end);
        }
        this.changeset.validate();
      },
      /**
       *
       * @function toggleDateRange
       */
      toggleDateRange() {
        if (this.hasSelectedLanguages) {
          this.changeset && this.changeset.rollback();
          (0, _object.set)(this, 'hasDateRange', !this.hasDateRange);
        }
      }
    }
  });
  /**
   * Parses the api-errors and builds them on a per language or shared basis.
   *
   * Errors which effect a collection will be merged to ońe, i.e.
   * if there's an error with multiple `posts` for a language, the api
   * returns them as single errors
   *
   * "errors": {
   *  "posts.0": "not_found",
   *  "posts.1": "not_found"
   * }
   *
   * becomes `[posts.not_found]`
   * @param {Array} apiErrors the validation errors
   * @param {Object} errors.* any resource related error
   * @function formatApiErrors
   * @return {String[]} errorMap
   */
  const formatApiErrors = apiErrors => {
    const errorKeys = Object.keys(apiErrors);
    let errorMap = [];
    errorKeys.forEach(errorKey => {
      const [key] = errorKey.split('.');
      const errors = apiErrors[errorKey];
      const error = `${key}.${(0, _array.isArray)(errors) ? errors[0] : errors}`;
      if (error && errorMap.indexOf(error) === -1) {
        errorMap = errorMap.concat(error);
      }
    });
    return errorMap;
  };
});