define("@additive-apps/ui/components/ui-status", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-status"], function (_exports, _component, _uiStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * status circle
   *
   * @class ui-status
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiStatus.default,
    /**
     * status
     * @property status
     * @type {String}
     * @default null
     */
    state: null
  });
});