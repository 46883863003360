define("@additive-apps/media/components/mm-medium-item/file-name", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-medium-item/file-name", "@additive-apps/media/constants", "@ember/object"], function (_exports, _component, _fileName, _constants, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  Sub component of mm-medium-item to render filename with colored fileextension
   *
   *```hbs
   *{{mm-medium-item/file-name
   *    medium=medium
   *}}
   *```
   *
   * @class file-name
   * @public
   */
  var _default = _exports.default = _component.default.extend({
    layout: _fileName.default,
    tagName: '',
    /**
     * The medium model
     * @property medium
     * @type {Medium}
     */
    medium: null,
    /**
     * The name of the file without file extension
     * @computed filename
     * @type {String}
     */
    filename: (0, _object.computed)('medium.name', {
      get() {
        var _this$medium;
        return ((_this$medium = this.medium) === null || _this$medium === void 0 ? void 0 : _this$medium.name) && this.medium.name.split('.').slice(0, -1).join('.');
      }
    }),
    /**
     * The extension of the file
     * @computed fileextension
     * @type {String}
     */
    fileextension: (0, _object.computed)('medium.name', {
      get() {
        var _this$medium2;
        const extension = ((_this$medium2 = this.medium) === null || _this$medium2 === void 0 ? void 0 : _this$medium2.name) && this.medium.name.split('.').pop();
        if (_constants.FILEEXTENSION_COLOR_MAPPING[extension]) {
          return {
            extension,
            color: _constants.FILEEXTENSION_COLOR_MAPPING[extension]
          };
        }
        return {
          extension
        };
      }
    })
  });
});