define("@additive-apps/configurator/components/aw-configurator/preview", ["exports", "@ember/component", "@additive-apps/configurator/templates/components/aw-configurator/preview", "@ember/object", "@ember/template"], function (_exports, _component, _preview, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-components
  /**
   * The component serves as container where the widget gets rendered
   *
   * TODO: Add a css reset and avoid an IFrame
   * @class widget-configurator-preview
   */
  // eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _preview.default,
    attributeBindings: ['_style:style'],
    classNames: ['aw-configurator__preview', 'flex flex-column flex-grow-1', 'relative'],
    classNameBindings: ['isLoading:aw-configurator__preview--loading'],
    /**
     * Containers background color
     *
     * @argument backgroundColor
     * @type {String}
     * @default null
     */
    backgroundColor: null,
    isLoading: false,
    /**
     * Containers style attribute
     *
     * @property _style
     * @type {String}
     * @private
     */
    _style: (0, _object.computed)('backgroundColor', {
      get() {
        if (this.backgroundColor) {
          return (0, _template.htmlSafe)(`background-color: ${this.backgroundColor}`);
        }
        return (0, _template.htmlSafe)('');
      }
    }),
    didInsert() {},
    /* Called when the retry button is pressed */
    onRetry() {},
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      //* notiy parent that preview has been inserted into the DOM
      this.didInsert();
    }
  });
});