define("@additive-apps/ui/components/ui-filter-inputs/date", ["exports", "@additive-apps/ui/components/ui-filter-inputs/base", "@additive-apps/ui/templates/components/ui-filter-inputs/date", "@ember/object", "@ember/object/computed", "@additive-apps/ui/utils/date-util", "date-fns", "@additive-apps/ui/utils/constants"], function (_exports, _base, _date, _object, _computed, _dateUtil, _dateFns, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Filter-input for type `date`.
   * Renders select with provided `values`,
   * when `custom` is selected we render an additional date-picker,
   * the filter-value is in this case the date-picker value,
   * otherwise it will be the value of the select-option like `today`.
   *
   * @extends ui-filter-inputs/base
   * @class ui-filter-inputs/date
   * @module ui-filter-inputs
   */
  var _default = _exports.default = _base.default.extend({
    layout: _date.default,
    /**
     * The custom meta value
     *
     * @property _customMetaValue
     * @return {Object}
     * @private
     */
    _customMetaValue: null,
    /**
     * Whether `custom` value has meta configuration
     *
     * @computed _hasCustomMeta
     * @return {Boolean}
     * @private
     */
    _hasCustomMeta: (0, _computed.equal)('_customValue.meta.year', false),
    /**
     * Whether `custom` is selected
     *
     * @computed _isCustom
     * @return {Boolean}
     * @private
     */
    _isCustom: (0, _computed.equal)('_singleSelected', 'custom'),
    /**
     * Whether `custom` is selected
     *
     * @computed _isCustom
     * @return {Boolean}
     * @private
     */
    _customValue: (0, _object.computed)('filter.values.[]', {
      get() {
        return (this.filter && this.filter.values || []).find(value => value.value === 'custom');
      }
    }),
    /**
     * The current selected value of the `single` filter-input component
     *
     * @computed _singleSelected
     * @return {Any}
     * @private
     */
    _singleSelected: (0, _object.computed)('_singleInitialValue', {
      get() {
        const initialValue = this._singleInitialValue;
        if (initialValue && initialValue.label) {
          return initialValue.value;
        }
        return null;
      },
      set(key, value) {
        return value;
      }
    }),
    /**
     * The initial selected value of the `single` filter-input component
     *
     * @computed _singleInitialValue
     * @return {Any}
     * @private
     */
    _singleInitialValue: (0, _object.computed)('initialValues.[]', 'initialValue', 'filter.{key,values.@each.value}', {
      get() {
        if (this.initialValue) {
          return this.initialValue;
        }
        if (!this.initialValues) {
          return null;
        }
        const filterValues = this.filter && this.filter.values;
        const initialValue = this.initialValues && this.initialValues[this.filter.key];

        /* When `filter.values` is empty or a empty array we return the given initial value */
        if (!Array.isArray(filterValues) || Array.isArray(filterValues) && filterValues.length === 0) {
          return initialValue;
        }

        /* When we have a initial value it must be a custom value, so use custom filter value */
        if (initialValue) {
          return filterValues.find(value => value.value === 'custom');
        }
        return null;
      }
    }),
    /**
     * The initial date when filter-value is `custom`
     *
     * @computed _initialDate
     * @return {Date|null}
     * @private
     */
    _initialDate: (0, _object.computed)('initialValues', 'filter.{key,values.@each.value}', {
      get() {
        if (!this.initialValues) {
          return null;
        }
        const initialValue = this.initialValues[this.filter.key];
        try {
          const date = (0, _dateUtil.parseDate)(initialValue);
          return date instanceof Date && !isNaN(date) ? date : null;
        } catch (e) {
          return null;
        }
      },
      set(key, value) {
        return value;
      }
    }),
    /**
     * The initial value for the custom meta when filter-value is `custom`
     *
     * @computed _initialCustomMetaValue
     * @return {Object|null}
     * @private
     */
    _initialCustomMetaValue: (0, _object.computed)('_isCustom', 'initialValues', 'filter.{key,values.@each.value}', {
      get() {
        if (!this._isCustom || !this.initialValues) {
          return {
            day: null,
            month: null
          };
        }
        const initialValue = this.initialValues[this.filter.key];
        const [startMonth, startDay] = initialValue && initialValue.split('-') || [];
        return {
          day: startDay,
          month: startMonth
        };
      },
      set(key, value) {
        return value;
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_customMetaValue', Object.assign({}, this._initialCustomMetaValue));
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);

      /*
       * initialValue will be undefined when a date is selected,
       * so when initialDate/_initialCustomMetaValue is present but no initialValue,
       * we set `custom` as selected
       */
      if (this._hasCustomMeta) {
        if (this._initialCustomMetaValue && (this._initialCustomMetaValue.day || this._initialCustomMetaValue.month)) {
          (0, _object.set)(this, '_singleSelected', 'custom');
          (0, _object.set)(this, '_customMetaValue', Object.assign({}, this._initialCustomMetaValue));
          return;
        }
      } else {
        if (!this.initialValue && this._initialDate) {
          (0, _object.set)(this, '_singleSelected', 'custom');
          return;
        }
      }
      if (this.initialValue && this.initialValue !== this._singleSelected) {
        (0, _object.set)(this, '_singleSelected', this.initialValue);
        (0, _object.set)(this, '_customMetaValue', null);
      }
    },
    _buildOnChangeCustomMetaValue() {
      const customMetaValue = this._customMetaValue;
      if (!customMetaValue) {
        return null;
      }
      const ensureTwoDigits = value => value && ('0' + value).slice(-2);
      const day = ensureTwoDigits(customMetaValue.day);
      const month = ensureTwoDigits(customMetaValue.month);
      if (day && month) {
        return `${month}-${day}`;
      }
      return null;
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      onCustomMetaChange(key, value) {
        const customMetaValue = Object.assign({}, this._customMetaValue);
        (0, _object.set)(customMetaValue, key, value);
        (0, _object.set)(this, '_customMetaValue', customMetaValue);
        const onChangeValue = this._buildOnChangeCustomMetaValue();
        this._onChange(this.filter.key, onChangeValue || null);
      },
      onOptionChange(key, value) {
        (0, _object.set)(this, '_singleSelected', value);

        /* Trigger onChange for initial values when changed to custom */
        if (value === 'custom') {
          const date = this._initialDate;
          if (date) {
            this._onChange(key, (0, _dateFns.format)(date, _constants.UI_DATEPICKER_DEFAULT_FORMAT));
          } else {
            const onChangeValue = this._buildOnChangeCustomMetaValue();
            this._onChange(this.filter.key, onChangeValue || null);
          }
        } else {
          /* Reset possible inital values */
          (0, _object.setProperties)(this, {
            _customMetaValue: {
              day: null,
              month: null
            },
            _initialDate: null,
            _initialCustomMetaValue: null
          });
          this._onChange(key, value);
        }
      },
      onDatepickerChange(selected) {
        this._onChange(this.filter.key, selected);
      }
    }
  });
});