define("@additive-apps/configurator/components/aw-configurator/content-editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="aw-configurator__editor aw-configurator__content-editor bg-white
      {{if @activeBlock "has-open-detail" ""}}
       flex-shrink-0"
  >
    <div class="content flex-shrink-0 h-100">
      {{#if @activeBlock}}
        {{yield (hash configuration=(component "ui-blank-template"))}}
      {{else}}
        {{yield (hash sidebar=(component "aw-configurator/content-editor/sidebar" isReadOnly=@isReadOnly))}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "hLPs//lb",
    "block": "[[[10,0],[15,0,[29,[\"aw-configurator__editor aw-configurator__content-editor bg-white\\n    \",[52,[30,1],\"has-open-detail\",\"\"],\"\\n     flex-shrink-0\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"content flex-shrink-0 h-100\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[18,3,[[28,[37,2],null,[[\"configuration\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,3,[[28,[37,2],null,[[\"sidebar\"],[[50,\"aw-configurator/content-editor/sidebar\",0,null,[[\"isReadOnly\"],[[30,2]]]]]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@activeBlock\",\"@isReadOnly\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator/content-editor.hbs",
    "isStrictMode": false
  });
  let AwConfiguratorContentEditorComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwConfiguratorContentEditorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      /**
       * the model name shown before the title
       *
       * @argument modelName
       * @type {String}
       */
      _initializerDefineProperty(this, "modelName", _descriptor2, this);
      /**
       * whether the content editor is read-only
       *
       * @argument isReadOnly
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      /**
       * whether the language is show in the navbar
       *
       * @argument showLanguage
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "showLanguage", _descriptor4, this);
      /**
       * the title displayed in the navbar
       *
       * @argument title
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor5, this);
      _initializerDefineProperty(this, "onSave", _descriptor6, this);
      _initializerDefineProperty(this, "backAction", _descriptor7, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showLanguage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backAction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfiguratorContentEditorComponent);
});