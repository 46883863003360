define("@additive-apps/ui/components/ui-reports/card", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-reports/card", "@ember/service"], function (_exports, _component, _card, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * A simple card component used globally inside reports
   *
   * ```hbs
   *  <UiReports::Card title="My Title" desciprtion="nothing here"/>
   * ```
   *
   *  or as block in case the isEmpty-flag is true
   *
   * ```hbs
   *  <UiReports::Card isEmpty=true as |card|>
   *    {{card.title}}
   *    {{card.description}}
   *  </UiReports::Card>
   * ```
   *
   * @class card
   * @module ui-reports
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    layout: _card.default,
    classNames: ['reports-card flex flex-grow-1 relative pa3 shadow-1 br1 items-center', 'cpointer'],
    classNameBindings: ['isEmpty:bg-blue:bg-white', 'isEmpty:justify-center'],
    /**
     * The cards title
     *
     * @property title
     * @type {String}
     * @default ''
     */
    title: '',
    /**
     * The cards description
     *
     * @property description
     * @type {String}
     * @default ''
     */
    description: '',
    /**
     * Whether the item represented by the card is empty
     *
     * @property isEmpty
     * @type {Boolean}
     * @default false
     */
    isEmpty: false,
    onClick() {},
    click() {
      this.onClick();
    }
  });
});