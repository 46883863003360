define("@additive-apps/configurator/components/ae-configurations/apps/newsletter-widget", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/application", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _application, _service, _emberConcurrency, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert (perform this.fetchNewsletterWidgets)}}>
    {{#if this.fetchNewsletterWidgets.isRunning}}
      <UiSkeleton @items={{1}} @cardHeight={{32}} @gap={{0}} />
    {{else}}
      <UiSelect
        @options={{this._newsletterWidgets}}
        @selected={{get (array-find this.item.value.id this._newsletterWidgets "id") "name"}}
        @onChange={{this.onChange}}
        @isReadOnly={{@isReadOnly}}
        @isPlain={{true}} as |select item|
      >
        <select.item @value={{item.value}}>
          {{item.name}}
        </select.item>
      </UiSelect>
    {{/if}}
  </div>
  */
  {
    "id": "v9pjj6j0",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"fetchNewsletterWidgets\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,0,[\"fetchNewsletterWidgets\",\"isRunning\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@items\",\"@cardHeight\",\"@gap\"],[1,32,0]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@options\",\"@selected\",\"@onChange\",\"@isReadOnly\",\"@isPlain\"],[[30,0,[\"_newsletterWidgets\"]],[28,[37,5],[[28,[37,6],[[30,0,[\"item\",\"value\",\"id\"]],[30,0,[\"_newsletterWidgets\"]],\"id\"],null],\"name\"],null],[30,0,[\"onChange\"]],[30,1],true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\"]],[]]],[13]],[\"@isReadOnly\",\"select\",\"item\"],false,[\"did-insert\",\"perform\",\"if\",\"ui-skeleton\",\"ui-select\",\"get\",\"array-find\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/apps/newsletter-widget.hbs",
    "isStrictMode": false
  });
  let AeConfigurationsAppsNewsletterWidgetComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.func), _dec3 = (0, _emberConcurrency.task)(function* () {
    this._isFetchError = false;

    // skip fetch if already loaded
    if (this._newsletterWidgets) {
      return;
    }
    try {
      var _this$currentUser;
      let tasks = [];
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const baseHost = ENV.APP.apiBaseHost;
      const requestOptions = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': this.uiAppSettings.currentLocale
        }
      };
      const organizationSlug = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
      const request = this.authenticatedFetch.fetch(`${baseHost}/${organizationSlug}/newsletter-widgets`, requestOptions);
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        throw Error('[VOUCHER-WIDGET] Fetch of newsletters failed');
      }
      const {
        newsletterWidgets
      } = yield response.json();
      if (!newsletterWidgets) {
        throw Error('[VOUCHER-WIDGET] Fetch of newsletter widgets failed');
      }
      const formattedWidgets = [];
      newsletterWidgets.filter(newsletterWidget => newsletterWidget.version === 3).forEach(newsletterWidget => {
        const widgetData = {
          id: newsletterWidget.id,
          name: newsletterWidget.name,
          value: {
            id: newsletterWidget.id,
            selector: newsletterWidget.selector,
            url: newsletterWidget.url
          }
        };
        formattedWidgets.push(widgetData);
      });
      this._newsletterWidgets = formattedWidgets;
    } catch (error) {
      this._isFetchError = true;
    }
  }), _class = class AeConfigurationsAppsNewsletterWidgetComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      /**
       * the newsletter widget item
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor4, this);
      /**
       * the fetched newsletter widgets
       *
       * @property _newsletterWidgets
       * @type {Array}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_newsletterWidgets", _descriptor5, this);
      /**
       * whether an error occured while fetching the newsletter widgets
       *
       * @property _isFetchError
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isFetchError", _descriptor6, this);
      _initializerDefineProperty(this, "onChange", _descriptor7, this);
      /**
       * fetches the newsletter widgets and formats them
       *
       * @function _fetchNewsletters
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchNewsletterWidgets", _descriptor8, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_newsletterWidgets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isFetchError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fetchNewsletterWidgets", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsAppsNewsletterWidgetComponent);
});