define("ember-svg-jar/inlined/bolt-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.917 7.917v.75h3.546L9.917 15.77V11.333H6.37l4.547-7.103v3.687zm.614-4.647s0 0 0 0h0l-.281-.18.28.18z\" fill=\"#00172F\" stroke=\"#00172F\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});