define("@additive-apps/ui/templates/components/ui-multi-form-control", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "eIZ+8EtA",
    "block": "[[[8,[39,0],null,[[\"@helperText\",\"@isPlain\",\"@label\",\"@message\",\"@onHelp\",\"@state\"],[[30,0,[\"helperText\"]],[30,0,[\"isPlain\"]],[30,0,[\"label\"]],[30,0,[\"message\"]],[30,0,[\"onHelp\"]],[30,0,[\"state\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"mv05 w-100 br1 ohidden\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,2],null,[[\"input\"],[[50,\"ui-blank-template\",0,null,[[\"tagName\",\"classNames\"],[\"div\",[28,[37,4],[\"ui-multi-form-control--input\",\"w-100\"],null]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"container\",\"&default\"],false,[\"ui-form-control\",\"yield\",\"hash\",\"component\",\"array\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-multi-form-control.hbs",
    "isStrictMode": false
  });
});