define("@additive-apps/media/components/mm-folder-tree", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "@additive-apps/media/constants", "@additive-apps/multimedia-engine/utils/engine", "@additive-apps/multimedia-engine/constants"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes, _constants, _engine, _constants2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mm-folder-tree flex font-medium {{@class}} {{@classNames}}">
    {{#if this.multimedia.currentFolder.parentName}}
      <div class="mr1">
        <span
          class="secondary cpointer"
          role="button"
          {{on "click" (fn this.transitionTo this._rootFolderId)}}
        >
          ...
        </span>
        <span class="secondary">
          /
        </span>
        <span
          class="secondary cpointer"
          role="button"
          {{on "click" (fn this.transitionTo this.multimedia.currentFolder.parentId)}}
        >
          {{this.multimedia.currentFolder.parentName}}
        </span>
      </div>
    {{else}}
      <div class="mr1">
        <div
          class="{{if this.multimedia.currentFolder "secondary" "primary"}} cpointer"
          role="button"
          {{on "click" (fn this.transitionTo this._rootFolderId)}}
        >
          {{t "multimediaEngine.folderTree.root"}}
        </div>
      </div>
    {{/if}}
    {{#if this.multimedia.currentFolder}}
      <div class="mr1 primary">
        <span class="secondary">
          /
        </span>
        {{this.multimedia.currentFolder.name}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "xQPQCrPY",
    "block": "[[[10,0],[15,0,[29,[\"mm-folder-tree flex font-medium \",[30,1],\" \",[30,2]]]],[12],[1,\"\\n\"],[41,[30,0,[\"multimedia\",\"currentFolder\",\"parentName\"]],[[[1,\"    \"],[10,0],[14,0,\"mr1\"],[12],[1,\"\\n      \"],[11,1],[24,0,\"secondary cpointer\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"transitionTo\"]],[30,0,[\"_rootFolderId\"]]],null]],null],[12],[1,\"\\n        ...\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n        /\\n      \"],[13],[1,\"\\n      \"],[11,1],[24,0,\"secondary cpointer\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"transitionTo\"]],[30,0,[\"multimedia\",\"currentFolder\",\"parentId\"]]],null]],null],[12],[1,\"\\n        \"],[1,[30,0,[\"multimedia\",\"currentFolder\",\"parentName\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"mr1\"],[12],[1,\"\\n      \"],[11,0],[16,0,[29,[[52,[30,0,[\"multimedia\",\"currentFolder\"]],\"secondary\",\"primary\"],\" cpointer\"]]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"transitionTo\"]],[30,0,[\"_rootFolderId\"]]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,3],[\"multimediaEngine.folderTree.root\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[41,[30,0,[\"multimedia\",\"currentFolder\"]],[[[1,\"    \"],[10,0],[14,0,\"mr1 primary\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n        /\\n      \"],[13],[1,\"\\n      \"],[1,[30,0,[\"multimedia\",\"currentFolder\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@classNames\"],false,[\"if\",\"on\",\"fn\",\"t\"]]",
    "moduleName": "@additive-apps/media/components/mm-folder-tree.hbs",
    "isStrictMode": false
  });
  /**
   *  Component to render folder-tree by using the multimedia service.
   *
   * ```hbs{{mm-folder-tree}}```
   *
   * @class mm-folder-tree
   * @public
   */
  let MmFolderTree = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _class = class MmFolderTree extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "multimedia", _descriptor2, this);
      /**
       * The route name of the folder.
       * When used inside the engine it is enough to set it to "folder".
       * When it is used outside of the engine e.g. in the navbar the whole
       * route name, including the path inside the app needs to be specified.
       *
       * @argument folderRouteName
       * @type {String}
       * @default 'folder'
       */
      _initializerDefineProperty(this, "folderRouteName", _descriptor3, this);
      /**
       * the id of the root folder
       *
       * @property _rootFolderId
       * @type {String}
       * @private
       */
      _defineProperty(this, "_rootFolderId", _constants.ROOT_FOLDER_ID);
    }
    transitionTo(modelId) {
      if (modelId === _constants2.ROOT_SHARED_FOLDER_ID) {
        (0, _engine.routeToSubEngineRoute)(this.router, 'shared');
        return;
      }
      (0, _engine.routeToSubEngineRoute)(this.router, 'folder', modelId);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "multimedia", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "folderRouteName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'folder';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "transitionTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MmFolderTree);
});