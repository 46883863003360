define("@additive-apps/configurator/components/ae-configurations/content-categories", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/application", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "ember-concurrency", "prop-types", "@additive-apps/ui/constants"], function (_exports, _component, _templateFactory, _component2, _application, _object, _service, _tracking, _emberArgTypes, _emberConcurrency, _propTypes, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert (perform this._fetchCategories)}}>
    <UiSelect
      @valueKey="id"
      @selected={{
        if
        this._isMultiselect
        this._selectedCategories
        (get (array-find this.item.value.categoryId this._categories "id") "name")
      }}
      @onChange={{this.onCategorySelect}}
      @onSelectAll={{fn this.onCategorySelectAll true}}
      @onDeselectAll={{fn this.onCategorySelectAll false}}
      @options={{this._categories}}
      @placeholder={{if this._isMultiselect (t "aeConfiguration.content.allCategories") (t "aeConfiguration.content.selectCategory")}}
      @isMultiSelect={{this._isMultiselect}}
      @isReadOnly={{@isReadOnly}}
      @isPlain={{true}} as |option item|
    >
      {{#if this._fetchCategories.isRunning}}
        <option.left>
          <UiLoading @classNames="pa05" />
        </option.left>
      {{/if}}
      <option.item @value={{item}}>
        {{item.name}}
      </option.item>
    </UiSelect>
  </div>
  */
  {
    "id": "t3fPn8RK",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"_fetchCategories\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@valueKey\",\"@selected\",\"@onChange\",\"@onSelectAll\",\"@onDeselectAll\",\"@options\",\"@placeholder\",\"@isMultiSelect\",\"@isReadOnly\",\"@isPlain\"],[\"id\",[52,[30,0,[\"_isMultiselect\"]],[30,0,[\"_selectedCategories\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"item\",\"value\",\"categoryId\"]],[30,0,[\"_categories\"]],\"id\"],null],\"name\"],null]],[30,0,[\"onCategorySelect\"]],[28,[37,6],[[30,0,[\"onCategorySelectAll\"]],true],null],[28,[37,6],[[30,0,[\"onCategorySelectAll\"]],false],null],[30,0,[\"_categories\"]],[52,[30,0,[\"_isMultiselect\"]],[28,[37,7],[\"aeConfiguration.content.allCategories\"],null],[28,[37,7],[\"aeConfiguration.content.selectCategory\"],null]],[30,0,[\"_isMultiselect\"]],[30,1],true]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"_fetchCategories\",\"isRunning\"]],[[[1,\"      \"],[8,[30,2,[\"left\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@classNames\"],[\"pa05\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2,3]]]]],[1,\"\\n\"],[13]],[\"@isReadOnly\",\"option\",\"item\"],false,[\"did-insert\",\"perform\",\"ui-select\",\"if\",\"get\",\"array-find\",\"fn\",\"t\",\"ui-loading\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations/content-categories.hbs",
    "isStrictMode": false
  });

  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let AeConfigurationsContentCategoriesComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _object.computed)('_categories', 'item.value.categoryIds'), _dec4 = (0, _emberConcurrency.task)(function* () {
    // skip fetch if already loaded
    if (this._categories) {
      return;
    }
    try {
      var _this$currentUser;
      let tasks = [];
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const contentBaseHost = ENV.APP.appId === _constants.APP_ID_CONTENT ? ENV.APP.apiBaseHost : ENV.APP.contentHost;
      const organizationSlug = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
      const request = this.authenticatedFetch.fetch(`${contentBaseHost}/${organizationSlug}/categories?categorizableType=${this._categorizableType}`, {
        headers: {
          'Accept-Language': this.uiAppSettings.currentLocale
        }
      });
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      if (!response || !response.ok) {
        throw Error('[CONTENT] Fetch of categories failed');
      }
      const {
        categories
      } = yield response.json();
      if (!categories) {
        throw Error('[CONTENT] Fetch of categories failed');
      }
      this._categories = categories.map(category => {
        var _category$image;
        // remove transformations because a content image is returned that already has the transformations applied
        if (category !== null && category !== void 0 && (_category$image = category.image) !== null && _category$image !== void 0 && _category$image.transformations) {
          delete category.image.transformations;
        }
        return category;
      });
    } catch (error) {
      this._isFetchError = true;
    }
  }), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AeConfigurationsContentCategoriesComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor3, this);
      _initializerDefineProperty(this, "item", _descriptor4, this);
      _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
      /**
       * the fetched categories
       *
       * @argument _categories
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "_categories", _descriptor6, this);
      /**
       * fetches the categories
       *
       * @function _fetchCategories
       * @type {Task}
       */
      _initializerDefineProperty(this, "_fetchCategories", _descriptor7, this);
      _defineProperty(this, "getSelectedCategoriesWithPublicCount", selectedCategoryIds => {
        const categoriesWithPublicCount = [...this._categories].filter(category => category.publicCount);
        return selectedCategoryIds.length === 0 ? categoriesWithPublicCount : categoriesWithPublicCount.filter(category => selectedCategoryIds.includes(category.id));
      });
      _initializerDefineProperty(this, "onChange", _descriptor8, this);
    }
    get _categorizableType() {
      switch (this.item.type) {
        case 'faq_categories':
        case 'faq_category':
          return 'frequently_asked_question';
        case 'room_categories':
        case 'room_category':
        case 'last_minute_offer_categories':
          return 'room';
        case 'offer_categories':
        case 'offer_category':
          return 'offer';
        case 'usp_categories':
        case 'usp_category':
          return 'page';
        case 'quote_categories':
        case 'quote_category':
          return 'quote';
        default:
          return null;
      }
    }
    get _isMultiselect() {
      return ['faq_categories', 'room_categories', 'offer_categories', 'last_minute_offer_categories', 'usp_categories', 'quote_categories'].includes(this.item.type);
    }
    get _selectedCategory() {
      var _this$item$value, _this$_categories$fin;
      if (!this._categories) {
        return [];
      }
      if (!((_this$item$value = this.item.value) !== null && _this$item$value !== void 0 && _this$item$value.categoryId)) {
        return null;
      }
      return (_this$_categories$fin = this._categories.find(category => this.item.value.categoryId === category.id)) === null || _this$_categories$fin === void 0 ? void 0 : _this$_categories$fin.name;
    }
    get _selectedCategories() {
      if (!this._categories) {
        return [];
      }
      return this._categories.filter(category => {
        var _this$item$value2;
        return (((_this$item$value2 = this.item.value) === null || _this$item$value2 === void 0 ? void 0 : _this$item$value2.categoryIds) || []).includes(category.id);
      });
    }
    onCategorySelect(toggledCategory, checked) {
      if (this._isMultiselect) {
        var _this$item$value3;
        const categoryIds = Array.isArray((_this$item$value3 = this.item.value) === null || _this$item$value3 === void 0 ? void 0 : _this$item$value3.categoryIds) ? [...this.item.value.categoryIds] : [];
        if (checked) {
          categoryIds.pushObject(toggledCategory.id);
        } else {
          categoryIds.removeObject(toggledCategory.id);
        }
        const categories = this.getSelectedCategoriesWithPublicCount(categoryIds);
        this.onChange({
          categoryIds,
          categories
        });
        return;
      }
      const category = [...this._categories].find(category => category.id === toggledCategory.id);
      this.onChange({
        categoryId: toggledCategory.id,
        category
      });
    }
    onCategorySelectAll(checked) {
      const categoryIds = checked ? [...this._categories.map(category => category.id)] : [];
      const categories = this.getSelectedCategoriesWithPublicCount(categoryIds);
      this.onChange({
        categoryIds,
        categories
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_categories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_selectedCategories", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_selectedCategories"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_fetchCategories", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onCategorySelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCategorySelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCategorySelectAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCategorySelectAll"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsContentCategoriesComponent);
});