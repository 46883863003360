define("@additive-apps/ui/templates/components/ui-form-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VHl82CFn",
    "block": "[[[8,[39,0],null,[[\"@size\",\"@subtitle\",\"@title\",\"@modalClassNames\",\"@onClose\"],[[30,0,[\"size\"]],[30,0,[\"subtitle\"]],[30,0,[\"title\"]],[30,0,[\"modalClassNames\"]],[28,[37,1],[[30,0],\"onClose\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,4,[[28,[37,3],null,[[\"header\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,4,[[28,[37,3],null,[[\"body\",\"changeset\",\"errors\",\"isTouched\"],[[50,\"ui-blank-template\",0,null,null],[30,0,[\"changeset\"]],[30,0,[\"changeset\",\"error\"]],[30,0,[\"_submitTouched\"]]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[16,\"data-test-ui-form-dialog-submit\",true]],[[\"@label\",\"@isSecondary\",\"@isDisabled\",\"@onClick\"],[[28,[37,6],[\"uiFormDialog.save\"],null],true,[30,3],[28,[37,7],[[30,0,[\"submit\"]]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"_isDiscardChangesDialog\"]],[28,[37,1],[[30,0],[30,0,[\"onClose\"]]],null]]],null]],[\"modal\",\"content\",\"@isReadOnly\",\"&default\"],false,[\"ui-modal\",\"action\",\"yield\",\"hash\",\"component\",\"ui-button\",\"t\",\"perform\",\"ui-discard-changes\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-form-dialog.hbs",
    "isStrictMode": false
  });
});