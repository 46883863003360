define("@additive-apps/configurator/components/aw-custom-colors-menu", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AwColorsMenu
    @active={{this._active}}
    @options={{this._customColorOptions}}
    @useAlpha={{@useAlpha}}
    @path={{@path}}
    @isReadOnly={{@isReadOnly}}
    @onChange={{this._onColorsMenuChange}}
  />
  */
  {
    "id": "1UiQv7g+",
    "block": "[[[8,[39,0],null,[[\"@active\",\"@options\",\"@useAlpha\",\"@path\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"_active\"]],[30,0,[\"_customColorOptions\"]],[30,1],[30,2],[30,3],[30,0,[\"_onColorsMenuChange\"]]]],null]],[\"@useAlpha\",\"@path\",\"@isReadOnly\"],false,[\"aw-colors-menu\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-custom-colors-menu.hbs",
    "isStrictMode": false
  });
  /**
   * Menu component for custom colors.
   *
   * Behaves nearly identical to aw-colors-menu except we are setting `syncColorKey`
   * to the chosen synced color.
   *
   * ```js
    {
      "color": "#FFFFFF", // custom
      "contrastColor": "#3D3D3D", // custom
      "sync": true, // whether a sync-color is chosen
      "syncColorKey": "ambient" // The selected color-variable (main, accent, ambient) when sync is true
    }
   * ```
   *
   * @class AwCustomColorsMenuComponent
   */
  let AwCustomColorsMenuComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.array), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwCustomColorsMenuComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the active color option
       *
       * @argument active
       * @type {Object}
       */
      _initializerDefineProperty(this, "active", _descriptor, this);
      /**
       * All available colors.
       * Will be used to pick sync color values
       *
       * @argument colors
       * @type {Object}
       */
      _initializerDefineProperty(this, "colors", _descriptor2, this);
      /**
       * the options
       *
       * @argument options
       * @type {Array}
       */
      _initializerDefineProperty(this, "options", _descriptor3, this);
      /**
       * parents object path
       *
       * @argument path
       * @type {String}
       */
      _initializerDefineProperty(this, "path", _descriptor4, this);
      /**
       * whether it has an alpha-value.
       *
       * @argument useAlpha
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "useAlpha", _descriptor5, this);
      /**
       * whether it is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor6, this);
      /**
       * on change event
       *
       * @argument onChange
       * @type {Function}
       */
      _initializerDefineProperty(this, "onChange", _descriptor7, this);
    }
    get _active() {
      if (!this.active || this.active.sync === false) {
        return this.active;
      }
      if (!this.colors || Object.keys(this.colors).length === 0) {
        return this.active;
      }
      if (!this.colors[this.active.syncColorKey]) {
        return this.active;
      }
      const syncColor = this.colors[this.active.syncColorKey];
      /* Add sync values to have same object aw-colors-menu expects it */
      return Object.assign({}, this.active, {
        syncedColor: syncColor.sync ? syncColor.syncedColor : syncColor.color,
        syncedContrastColor: syncColor.sync ? syncColor.syncedContrastColor : syncColor.contrastColor
      });
    }
    get _customColorOptions() {
      if (!Array.isArray(this.options) || this.options.length === 0) {
        return this.options;
      }
      if (!this.colors || Object.keys(this.colors).length === 0) {
        return this.options;
      }

      /*
       * Add `syncColorKey` to value, to know which option has been choosen.
       * Force `sync: true`, ...
       */
      return this.options.map(option => {
        return {
          key: option.key,
          value: Object.assign({}, option.value, {
            syncColorKey: option.key,
            sync: true
          })
        };
      });
    }
    _onColorsMenuChange(key, value, _, custom) {
      if (!custom) {
        this.onChange(key, value);
      } else {
        this.onChange(key, Object.assign({}, value, {
          sync: false
        }));
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "active", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "path", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "useAlpha", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onColorsMenuChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onColorsMenuChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwCustomColorsMenuComponent);
});