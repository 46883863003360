define("@additive-apps/ui/components/ui-editor/heading-actions", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object", "@ember/service", "ember-concurrency", "@glimmer/tracking", "@additive-apps/ui/utils/editor-util"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object, _service, _emberConcurrency, _tracking, _editorUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiSelectable
    @isReadOnly={{@isDisabled}}
    @customOffset={{1}}
    {{did-insert (perform this.didInsertSelectable)}}
    {{will-destroy this.willDestroySelectable}} as |selectable|
  >
    <UiEditor::Action
      @classNames="ui-editor__heading-select items-center"
      @onClick={{action selectable.toggleDropdown}}
    >
      <span class="db-sm dn">
        {{this.selectedValue.long}}
      </span>
      <span class="dn-sm db">
        {{this.selectedValue.short}}
      </span>
      {{svg-jar "caret"}}
    </UiEditor::Action>
  
    <selectable.content>
      {{#each this.headings as |heading|}}
        <UiEditor::Action
          @commandName="formatBlock"
          @value={{heading.value}}
          @onClick={{fn this.formatHeading heading.value selectable.toggleDropdown}}
        >
          <span class="pa1 font-sm primary">
            {{heading.name}}
          </span>
        </UiEditor::Action>
      {{/each}}
    </selectable.content>
  </UiSelectable>
  */
  {
    "id": "2euWW6RN",
    "block": "[[[8,[39,0],[[4,[38,1],[[28,[37,2],[[30,0,[\"didInsertSelectable\"]]],null]],null],[4,[38,3],[[30,0,[\"willDestroySelectable\"]]],null]],[[\"@isReadOnly\",\"@customOffset\"],[[30,1],1]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,[[\"@classNames\",\"@onClick\"],[\"ui-editor__heading-select items-center\",[28,[37,5],[[30,0],[30,2,[\"toggleDropdown\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"db-sm dn\"],[12],[1,\"\\n      \"],[1,[30,0,[\"selectedValue\",\"long\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"dn-sm db\"],[12],[1,\"\\n      \"],[1,[30,0,[\"selectedValue\",\"short\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,6],[\"caret\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"headings\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@commandName\",\"@value\",\"@onClick\"],[\"formatBlock\",[30,3,[\"value\"]],[28,[37,9],[[30,0,[\"formatHeading\"]],[30,3,[\"value\"]],[30,2,[\"toggleDropdown\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"pa1 font-sm primary\"],[12],[1,\"\\n          \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[2]]]]]],[\"@isDisabled\",\"selectable\",\"heading\"],false,[\"ui-selectable\",\"did-insert\",\"perform\",\"will-destroy\",\"ui-editor/action\",\"action\",\"svg-jar\",\"each\",\"-track-array\",\"fn\"]]",
    "moduleName": "@additive-apps/ui/components/ui-editor/heading-actions.hbs",
    "isStrictMode": false
  });
  const HEADING_TYPES = ['h1', 'h2', 'h3', 'h4', 'p'];
  let UiEditorHeadingActionsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.func), _dec2 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this, 'args.contentElement');
    this.contentElement.addEventListener('keyup', this.setSelectedValue, false);
    this.contentElement.addEventListener('mouseup', this.setSelectedValue, false);
    this.setSelectedValue();
  }), _class = class UiEditorHeadingActionsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * the selected Heading
       *
       * @property selectedValue
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "selectedValue", _descriptor2, this);
      /**
       * the content element of the editor
       *
       * @argument contentElement
       * @type {Element}
       */
      _initializerDefineProperty(this, "contentElement", _descriptor3, this);
      /**
       * adds event listeners to the content to set the selected value
       *
       * @function didInsertSelectable
       * @type {Task}
       */
      _initializerDefineProperty(this, "didInsertSelectable", _descriptor4, this);
    }
    /**
     * the callback function to apply the heading
     *
     * @argument execCommand
     * @type {Function}
     */
    execCommand() {}

    /**
     * the avalable headings, including the translation
     *
     * @computed headings
     * @type {Object[]}
     */
    get headings() {
      const {
        intl
      } = this;
      return HEADING_TYPES.map(heading => {
        return {
          name: intl.t(`uiEditor.actions.headings.${heading}`),
          value: heading
        };
      });
    }
    willDestroySelectable() {
      if (this.contentElement) {
        this.contentElement.removeEventListener('keyup', this.setSelectedValue);
        this.contentElement.removeEventListener('mouseup', this.setSelectedValue);
      }
    }

    /**
     * sets the selected value
     *
     * @function setSelectedValue
     */
    setSelectedValue() {
      const {
        intl
      } = this;
      for (let value of HEADING_TYPES) {
        if (value === (0, _editorUtil.queryValue)('formatBlock')) {
          this.selectedValue = {
            long: intl.t(`uiEditor.actions.headings.${value}`),
            short: intl.t(`uiEditor.actions.headingsShort.${value}`)
          };
          return;
        }
      }
      this.selectedValue = {
        long: intl.t(`uiEditor.actions.headings.p`),
        short: intl.t(`uiEditor.actions.headingsShort.p`)
      };
    }

    /**
     * applies the heading format and close the selectable
     *
     * @function formatHeading
     * @param {String} type
     * @param {Function} callback
     */
    formatHeading(type, callback) {
      // apply heading format
      this.execCommand('formatBlock', type);
      this.setSelectedValue();
      callback();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contentElement", [_emberArgTypes.arg], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "execCommand", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "execCommand"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "didInsertSelectable", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willDestroySelectable", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroySelectable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formatHeading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "formatHeading"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiEditorHeadingActionsComponent);
});