define("@additive-apps/media/components/mm-resource-gallery", ["exports", "@ember/component", "@additive-apps/media/templates/components/mm-resource-gallery", "@ember/object", "@ember/object/computed", "@additive-apps/media/constants", "@additive-apps/ui/constants", "@additive-apps/media/utils/medium", "@ember/utils", "@ember/service"], function (_exports, _component, _mmResourceGallery, _object, _computed, _constants, _constants2, _medium, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component to render a array of resource media.
   *
   * It uses a grid to display those resources.
   * It provides a media-gallery of those resources as well,
   * which opens by clicking on a resource.
   *
   * The first item inside this grid is a ui-dropzone card,
   * which comes with a extra onClick event named `onDropzoneClick`.
   *
   * It also adds the `delete` svg to each resource
   * with a dedicated onClick event named `onResourceRemove`
   *
   * It provides a readOnly mode as well,
   * where the dropzone and delete functionality gets hidden.
   *
   * On resource-click it will open a mm-media-gallery component
   * with the provided resource array and will start at the clicked resource.
   *
   *```hbs
   *{{mm-resource-gallery
   *  resources=ARRAY_WITH_RESOURCE_OBJECTS
   *  onDropzoneClick=(action "onDropzoneClick")
   *  onResourceRemove=(action "onResourceRemove")
   *  onResourceEdit=(action "onResourceEdit")
   *  isReadOnly=true
   *  defaultsize="78x78"
   *  mediaSets=mediaSets
   *  sizeSets=sizeSets
   *}}
   *```
   *
   * @class mm-resource-gallery
   */
  var _default = _exports.default = _component.default.extend({
    multimedia: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    layout: _mmResourceGallery.default,
    classNames: ['mm-resource-gallery'],
    accessibleApps: (0, _computed.alias)('currentUser.currentOrganization.accessibleApps'),
    /**
     * checks if "Add image" should be displayed
     *
     * @property isImageAddable
     * @type {Boolean}
     */
    isImageAddable: true,
    /**
     * checks if "Add video" should be displayed
     *
     * @property isVideoAddable
     * @type {Boolean}
     */
    isVideoAddable: false,
    /**
     * default size for mm-medium-item
     *
     * @property defaultSize
     * @type {String}
     */
    defaultSize: '',
    /**
     * Optional custom dropzone inner text
     *
     * @property dropZoneText
     * @type {String}
     */
    dropZoneText: '',
    /**
     * mediaSets for mm-medium-item
     *
     * @property mediaSets
     * @type {Hash}
     */
    mediaSets: null,
    /**
     * When you want to define a fixed resource `type`.
     * `video`, `file`, `image` are valid.
     *
     * @property mediumType
     * @type {String}
     */
    mediumType: null,
    /**
     * The array of resources
     *
     * One resource is similiar to a medium-object
     *
     * @property resources
     * @type {Object[]}
     */
    resources: null,
    /**
     * Whether it is readOnly
     *
     * @property isReadOnly
     * @type {Boolean}
     */
    isReadOnly: false,
    /**
     * Whether the medium is editable
     *
     * @property isEditable
     * @type {Boolean}
     */
    isEditable: true,
    /**
     * sizeSets for mm-medium-item
     *
     * @property sizeSets
     * @type {Hash}
     */
    sizeSets: null,
    /*
     * To hide possibility for image-transform.
     *
     * TODO: Remove when legacy-apps api supports image-transform
     */
    hideTransform: false,
    /**
     * whether sorting is enabled
     *
     * @property isSortingEnabled
     * @type {Boolean}
     */
    isSortingEnabled: false,
    /**
     * the selectable image labels
     *
     * @property selectableImageLabels
     * @type {Array}
     */
    selectableImageLabels: null,
    /**
     * whether the edit dialog contains the option
     * to change the alt and title attributes
     *
     * @property hasAttributesDialog
     * @type {Boolean}
     */
    hasAttributesDialog: false,
    /**
     * The current active resource index of the gallery
     *
     * @property _activeGalleryIndex
     * @type {Number}
     * @private
     */
    _activeGalleryIndex: 1,
    /**
     * The start index for the gallery (gallerys index starts at 1 not 0)
     *
     * @property _startIndex
     * @type {Number}
     * @private
     */
    _startIndex: 1,
    _isSortingEnabled: (0, _object.computed)('isEditable', 'isReadOnly', 'isSortingEnabled', {
      get() {
        return this.isEditable && !this.isReadOnly && this.isSortingEnabled;
      }
    }),
    /**
     * Whether the organization has Marketing Cloud
     *
     * @computed _hasMC
     * @type {Boolean}
     * @private
     */
    _hasMC: (0, _object.computed)('accessibleApps', {
      get() {
        const {
          accessibleApps
        } = this;
        if (!accessibleApps) {
          return false;
        }
        return accessibleApps.find(app => {
          return app.id === _constants2.APP_ID_MARKETING_CLOUD;
        });
      }
    }),
    /**
     * The active resource which is currently active in gallery
     *
     * @computed _activeResourceInGallery
     * @type {String}
     * @private
     */
    _activeResourceInGallery: (0, _object.computed)('_preparedResources.[]', '_activeGalleryIndex', {
      get() {
        /* -1 as array index starts at 0 while mm-gallery starts at 1 */
        const activeGalleryIndex = this._activeGalleryIndex - 1;
        const resources = this._preparedResources;
        return (Array.isArray(resources) ? resources[activeGalleryIndex] : resources) || {};
      }
    }),
    /**
     * Whether medium is editable or not
     *
     * @computed _canEdit
     * @type {Boolean}
     * @private
     */
    _canEdit: (0, _object.computed)('_activeResourceInGallery.isVideo', 'isEditable', {
      get() {
        var _this$_activeResource;
        if ((_this$_activeResource = this._activeResourceInGallery) !== null && _this$_activeResource !== void 0 && _this$_activeResource.isVideo || !this.isEditable) {
          return false;
        }
        return true;
      }
    }),
    /**
     * Wrapper of the resources array for some medium computed properties,
     * on which medium-item relies on but we do not have in resources,
     * as resources does not uses the medium-model.
     *
     * @computed _preparedResources
     * @type {Object[]}
     * @private
     */
    _preparedResources: (0, _object.computed)('mediumType', 'resources.@each.id', {
      get() {
        const resourcesProp = this.resources;
        if ((0, _utils.isEmpty)(resourcesProp)) {
          return [];
        }
        const resources = Array.isArray(resourcesProp) ? resourcesProp : [resourcesProp];
        return resources.map(resource => {
          const type = this.mediumType || resource.type;
          const _isVideo = type === 'video';
          let videoProps = {};
          if (_isVideo && resource.provider === 'youtube') {
            const remoteId = resource.mediumId;
            const urlBlueprints = _medium.VIDEO_URLS_BLUEPRINT.youtube;
            videoProps = {
              thumbnailUrl: urlBlueprints.thumbnailUrl.replace('{{remote_id}}', remoteId),
              embedUrl: urlBlueprints.embedUrl.replace('{{remote_id}}', remoteId),
              provider: 'youtube'
            };
          }
          return Object.assign({}, videoProps, {
            isFile: type === 'file',
            isImage: type === 'image',
            isVideo: _isVideo
          }, resource);
        });
      },
      set(_, value) {
        (0, _object.set)(this, 'resources', value);
        return value;
      }
    }),
    /**
     * whether the dropzone should be displayed. It is hidden in readOnly mode, and when
     * the maxCount is reached
     *
     * @computed _displayDropzone
     */
    _displayDropzone: (0, _object.computed)('_preparedResources.[]', 'isEditable', 'isReadOnly', 'multimedia.selectionOptions.maxCount', {
      get() {
        if (this.isReadOnly || !this.isEditable) return false;
        const maxCount = this.multimedia.selectionOptions.maxCount;
        const resourcesLength = this._preparedResources ? this._preparedResources.length : 0;
        if (!maxCount || maxCount > resourcesLength) {
          return true;
        }
        return false;
      }
    }),
    init() {
      this._super(...arguments);

      /* Only set as default, when not passed as property */
      !this.defaultSize && (0, _object.set)(this, 'defaultSize', _constants.GRID_DEFAULT_SIZE);
      !this.mediaSets && (0, _object.set)(this, 'mediaSets', _constants.GRID_DEFAULT_SRC_SETS.mediaSets);
      !this.sizeSets && (0, _object.set)(this, 'sizeSets', _constants.GRID_DEFAULT_SRC_SETS.sizeSets);
      !this.resources && (0, _object.set)(this, 'resources', []);
      if (!this.dropZoneText) {
        if (this.isImageAddable && this.isVideoAddable) {
          (0, _object.set)(this, 'dropZoneText', this.intl.t('mmResourceGallery.isImageOrVideoAddable'));
        } else {
          this.isImageAddable && (0, _object.set)(this, 'dropZoneText', this.intl.t('mmResourceGallery.isImageAddable'));
          this.isVideoAddable && (0, _object.set)(this, 'dropZoneText', this.intl.t('mmResourceGallery.isVideoAddable'));
        }
      }
    },
    /**
     * On dropzone click event
     *
     * @function onDropzoneClick
     */
    onDropzoneClick() {},
    /**
     * On resource edit
     *
     * @function onResourceEdit
     * @param {Object}  resource  The changed resource
     * @param {Integer} index     The array index of `resources` of the changed resource
     */
    onResourceEdit(/*resource, index*/) {},
    /**
     * On resource remove icon click event
     *
     * @function onDropzoneClick
     * @param {Object}  resource  The clicked resource
     * @param {Integer} index     The array index of `resources` of the clicked resource
     */
    onResourceRemove(/*resource, index*/) {},
    /**
     * On image-label change
     *
     * @function onImageLabelChange
     * @param {Object}  resource  The image
     * @param {Integer} index     The array index of `resources`
     * @param {String}  label     The image-label which should be added or removed
     */
    onImageLabelChange(/*resource, index, label*/) {},
    /**
     * On resource sort
     *
     * @function onResourceSort
     */
    onResourceSort() {},
    actions: {
      /**
       * On resource click, to open media-gallery
       * with the start-index
       *
       * @function _onResourceClick
       */
      _onResourceClick(index) {
        (0, _object.setProperties)(this, {
          _activeGalleryIndex: index + 1,
          _startIndex: index + 1,
          _isGalleryOpen: true
        });
      },
      /**
       * Stop propagation of _onResourceClick
       *
       * @function _stopOnResourceClickPropagation
       */
      _stopOnResourceClickPropagation: function _stopOnResourceClickPropagation(event) {
        event.stopPropagation();
      },
      /**
       * On gallery close click
       *
       * @function _onGalleryClose
       */
      _onGalleryClose() {
        (0, _object.setProperties)(this, {
          _activeGalleryIndex: 1,
          _startIndex: 1,
          _isGalleryOpen: false
        });
      },
      /**
       * On group-item image-transform select
       *
       * @function _onImageTransformOpen
       */
      _onImageTransformOpen() {
        (0, _object.setProperties)(this, {
          _startIndex: this._activeGalleryIndex,
          _isGalleryOpen: false,
          _isImageTransformView: true,
          _isEditDialog: false
        });
      },
      /**
       * On mm-image-transforms submit
       *
       * @function _onImageTransformSubmit
       */
      _onImageTransformSubmit(newTransforms) {
        const resource = this._activeResourceInGallery;
        const newResourceTransforms = Object.assign({}, resource.transformations, newTransforms);

        // remove unset transformations
        Object.keys(newResourceTransforms).forEach(resource => {
          !newResourceTransforms[resource] && delete newResourceTransforms[resource];
        });
        const newResource = Object.assign({}, resource, {
          transformations: newResourceTransforms,
          url: (0, _medium.mediumUrlOptions)(resource.url, newTransforms)
        });
        this.onResourceEdit(newResource, this._activeGalleryIndex - 1);
        this.send('_onImageTransformClose');
      },
      /**
       * On mm-image-transforms close without change
       *
       * @function _onImageTransformClose
       */
      _onImageTransformClose() {
        (0, _object.setProperties)(this, {
          _isGalleryOpen: true,
          _isImageTransformView: false,
          _isEditDialog: false
        });
      },
      /**
       * On image-label change
       *
       * @function _onImageLabelChange
       */
      _onImageLabelChange(resource, index, imageLabel, e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.isReadOnly || !this.isEditable) {
          return;
        }
        this.onImageLabelChange(resource, index, imageLabel);
      },
      /**
       * on attributes save action
       *
       * @function onAttributesSubmit
       */
      onAttributesSubmit(attributes) {
        const resource = this._activeResourceInGallery;
        const newResource = Object.assign({}, resource, attributes);
        this.onResourceEdit(newResource, this._activeGalleryIndex - 1);
        (0, _object.setProperties)(this, {
          _isAttributesDialog: false,
          _isEditDialog: false
        });
      },
      /**
       * close attributes dialog and edit dialog
       *
       * @function onAttributesDialogClose
       */
      onAttributesDialogClose() {
        (0, _object.setProperties)(this, {
          _isAttributesDialog: false,
          _isEditDialog: false
        });
      },
      /**
       * Open edit dialog / image edit-view
       *
       * @function openEditDialog
       */
      openEditDialog() {
        if (!this.hasAttributesDialog) {
          this.send('_onImageTransformOpen');
          return;
        }
        (0, _object.set)(this, '_isEditDialog', true);
      }
    }
  });
});