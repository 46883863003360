define("@additive-apps/ui/components/ui-selectable/trigger", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-selectable/trigger"], function (_exports, _component, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _trigger.default,
    classNames: ['ui-selectable-trigger'],
    onClick() {},
    click(event) {
      this.onClick(event);
    }
  });
});