define("@additive-apps/ui/components/ui-selectable/content", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-selectable/content", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _content, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _content.default,
    /**
     * whether the the content is rendered
     *
     * @argument isOpen
     * @type {Boolean}
     * @default false
     */
    isOpen: false,
    /**
     * the destination element the options are rendered into
     *
     * @argument destinationEl
     * @type {Object}
     * @default null
     */
    destinationEl: null,
    /**
     * the current search value
     *
     * @argument searchValue
     * @type {String}
     * @default null
     */
    searchValue: null,
    /**
     * id of the outer select element
     *
     * @argument selectElementId
     * @type {String}
     * @default null
     */
    selectElementId: null,
    /**
     * the style for the positioning of the container
     *
     * @argument style
     * @type {Object}
     * @default null
     */
    style: null,
    /**
     * internal state to check whether isOpen was changed
     *
     * @property _isOpen
     * @type {Boolean}
     * @default false
     * @private
     */
    _isOpen: false,
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    async didUpdateAttrs() {
      this._super(...arguments);
      if (!this._isOpen && this.isOpen) {
        this._isOpen = true;
        await (0, _domUtil.nextTick)();

        // prevent scrolling on search wrapper as it bubbles up to the background
        const selectSearchWrapper = document.querySelector(`.ui-selectable-search-wrapper`);
        if (selectSearchWrapper) {
          selectSearchWrapper.addEventListener('mousewheel', e => {
            e.preventDefault();
          });
        }

        // prevent scrolling from bubbling up to the background
        const optionsScroller = document.querySelector(`#options-container-${this.selectElementId} .options-container__scroller`);
        if (optionsScroller) {
          optionsScroller.addEventListener('mousewheel', e => {
            // stop propagation of the event to outer elements in case there are nested scrollers
            e.stopPropagation();
            if (e.deltaY < 0 && optionsScroller.scrollTop === 0 || e.deltaY > 0 && optionsScroller.scrollTop + optionsScroller.clientHeight === optionsScroller.scrollHeight) {
              e.preventDefault();
            }
            return false;
          });
        }
      } else if (this._isOpen && !this.isOpen) {
        this._isOpen = false;
      }
    },
    /**
     * callback function executed on search input change
     *
     * @function onSearch
     */
    onSearch() {},
    /**
     * callback function executed on search clear
     *
     * @function onSearchClear
     */
    onSearchClear() {}
  });
});