define("@additive-apps/configurator/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serifFontsFallbacks = _exports.sansSerifFontsFallbacks = _exports.TYPOGRAPHY_KEYS = _exports.ACCEPT_HEADER_V1 = _exports.ACCEPT_HEADER = void 0;
  const sansSerifFontsFallbacks = _exports.sansSerifFontsFallbacks = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
  const serifFontsFallbacks = _exports.serifFontsFallbacks = '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif';
  const TYPOGRAPHY_KEYS = _exports.TYPOGRAPHY_KEYS = ['h1', 'h2', 'h3', 'p', 'small', 'button', 'label'];
  const ACCEPT_HEADER = _exports.ACCEPT_HEADER = {
    Accept: 'application/vnd.additive+json; version=2'
  };
  const ACCEPT_HEADER_V1 = _exports.ACCEPT_HEADER_V1 = {
    Accept: 'application/vnd.newsletter.backend+json'
  };
});