define("@additive-apps/ui/components/ui-input", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-input", "@ember/object", "@ember/utils"], function (_exports, _component, _uiInput, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiInput.default,
    classNames: ['ui-input-container', 'w-100', 'mv05'],
    classNameBindings: ['showHelper:ui-input-container--helper', 'textarea:ui-input-container--textarea', 'isFocused:focused'],
    attributeBindings: ['theme', 'isReadOnly:readonly'],
    label: '',
    placeholder: '',
    type: 'text',
    step: '1',
    /**
     * Conditional the determines if it's a regular input or a textarea
     *
     * @property textarea
     * @type {Boolean}
     * @default false
     */
    textarea: false,
    /**
     * Text to be displayed
     *
     * @property helperText
     * @type {String}
     * @default undefined
     */
    helperText: undefined,
    /**
     * The showCount property indicates if a character count should be displayed
     *
     * @property showCount
     * @type {boolean}
     * @default undefined
     */
    showCount: false,
    /**
     * The isLoading property indicates if a background-request is happing
     *
     * i.e. for live-validation or search
     *
     * CSS Classes: ui-input--loading
     *
     * @property isLoading
     * @type {Boolean}
     * @default false
     */
    isLoading: false,
    /**
     * Whether the input is disabled or not
     *
     * @argument isDisabled
     * @type {Boolean}
     * @default false
     */
    isDisabled: false,
    /**
     * The isTouched property indicates if a input elem has been touched or not
     *
     * @property isTouched
     * @type {Boolean}
     * @default false
     */
    isTouched: false,
    /**
     * the state property indicates the values current state.
     *
     * State is only handled for statefull inputs.
     *
     * Possible values are: success, error and warning
     *
     * CSS Classes: ui-input--loading
     *
     * @property state
     * @type {String}
     * @default undefined
     */
    state: undefined,
    /**
     * the error message to be displayed
     *
     * @property errorMessage
     * @type {String}
     * @default undefined
     */
    errorMessage: undefined,
    /**
     * the info message to be displayed, when an error message pops up this
     * message is not displayed until the error message is gone
     *
     *
     * @property infoMessage
     * @type {String}
     * @default undefined
     */
    infoMessage: undefined,
    /**
     * the maximum count of characters
     *
     * @property maxlength
     * @type {Number}
     * @default null
     */
    maxlength: null,
    /**
     * the minimum value (for number inputs)
     *
     * @property minValue
     * @type {Number}
     * @default null
     */
    minValue: null,
    /**
     * the maximum value (for number inputs)
     *
     * @property maxValue
     * @type {Number}
     * @default null
     */
    maxValue: null,
    /**
     * the default value
     *
     * @property defaultValue
     * @type {String}
     * @default null
     */
    defaultValue: '',
    /**
     * the recommended count of characters
     *
     * @property recommendedLength
     * @type {Number}
     * @default null
     */
    recommendedLength: null,
    /**
     * the warning shown when the recommended length is exceeded
     *
     * @property recommendedWarning
     * @type {String}
     * @default null
     */
    recommendedWarning: null,
    // Alow element to be focusable by supplying a tabindex 0
    tabindex: (0, _object.computed)('isDisabled', {
      get() {
        return this.isDisabled ? '-1' : 0;
      }
    }),
    inputElementId: (0, _object.computed)('elementId', {
      get() {
        return `input-${this.elementId}`;
      }
    }),
    _charCount: (0, _object.computed)('value.length', function () {
      let currentLength = this.value ? this.value.length : 0;
      return currentLength;
    }),
    /**
     * The state should not be shown if it is a readOnly input (e.g.: select)
     *
     * @property showState
     * @type {Boolean}
     * @default undefined
     */
    showState: (0, _object.computed)('state', 'isReadOnly', {
      get() {
        return this.state && !this.isReadOnly;
      }
    }),
    computedState: (0, _object.computed)('errorMessage', 'state', 'isTouched', {
      get() {
        if (this.isTouched && this.errorMessage) {
          return 'error';
        }
        return this.state;
      }
    }),
    message: (0, _object.computed)('errorMessage.{firstObject,length}', 'infoMessage', 'isTouched', {
      get() {
        if (this.errorMessage && this.isTouched) {
          return typeof this.errorMessage === 'string' ? this.errorMessage : this.errorMessage.firstObject;
        }
        return this.infoMessage;
      }
    }),
    onBlur() {},
    onChange() {},
    onHelp() {},
    onFocus() {},
    onPaste() {},
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      this.inputElement = this.textarea ? this.element.querySelector('textarea') : this.element.querySelector('input');
      if (!(0, _utils.isNone)(this.value)) {
        this.inputElement.value = this.value;
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      const value = this.value;
      const inputValue = !(0, _utils.isNone)(value) ? value : this.defaultValue;
      if (this.inputElement.value !== inputValue) {
        this.inputElement.value = inputValue;
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      handleInput(event) {
        const {
          value
        } = event.target;
        if (this.type === 'number' && this.maxValue !== null && value > this.maxValue) {
          (0, _object.set)(this, 'value', this.maxValue);
          this.inputElement.value = this.maxValue;
          return;
        }
        if (this.type === 'number' && this.minValue !== null && value < this.minValue) {
          (0, _object.set)(this, 'value', this.minValue);
          this.inputElement.value = this.minValue;
          return;
        }
        this.onChange(event.target.value);
        (0, _object.set)(this, 'value', event.target.value);
      },
      handlePaste(event) {
        this.onPaste(event);
      },
      handleBlur(event) {
        (0, _object.set)(this, 'isFocused', false);
        (0, _object.set)(this, 'isTouched', true);
        this.onBlur(event.target.value);
      },
      handleFocus(event) {
        (0, _object.set)(this, 'isFocused', true);
        this.onFocus(event.target.value);
      }
    }
  });
});