define("@additive-apps/ui/components/ui-selectable-chip", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-selectable-chip", "@ember/array", "@ember/object", "ember-concurrency", "@ember/utils"], function (_exports, _component, _uiSelectableChip, _array, _object, _emberConcurrency, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * Selection chip component
   *
   * ```hbs
   * {{#ui-selectable-chip
   *   onChange
   *   text
   *   options
   *   isMultiSelect
   *   as |option item|
   * }}
   *   {{#option.item value=item}}
   *     {{item.name}}
   *   {{/option.item}}
   * {{/ui-selectable-chip}}
   * ```
   *
   * @class ui-selectable-chip
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _uiSelectableChip.default,
    classNames: ['ui-selectable-chip'],
    /**
     * Scrolls to the selected option when set to true
     *
     * @property scrollToOptionOnOpen
     * @type {Boolean}
     */
    scrollToOptionOnOpen: false,
    /**
     * the displayed text on the unselected chip
     *
     * @property text
     * @type {String}
     * @default null
     */
    text: null,
    /**
     * the options for the select
     *
     * @property options
     * @type {Object[]}
     * @default undefined
     */
    options: undefined,
    /**
     * the key of the options containing the name that should be displayed
     *
     * @property optionKey
     * @type {String}
     * @default 'name'
     */
    optionKey: 'name',
    /**
     * the key of the actual value of each option-item
     *
     * @property valueKey
     * @type {String}
     * @default 'value'
     */
    valueKey: 'value',
    /**
     * whether multiple options can be selected
     *
     * @property isMultiSelect
     * @type {Boolean}
     * @default false
     */
    isMultiSelect: false,
    /**
     * whether the options can be searched
     *
     * @property isSearchable
     * @type {Boolean}
     * @default false
     */
    isSearchable: false,
    /**
     * the value that is preselected
     *
     * @property initialValue
     * @type {String|Object}
     * @default null
     */
    initialValue: null,
    /**
     * the values that are preselected, the values can be passed as an Array containing option
     * objects or as a string containing the values, delimited by a `,`
     *
     * @property selected
     * @type {String|Array}
     * @default null
     */
    selected: null,
    /**
     * whether the the select should automatically scroll to an option
     * when a key is pressed
     *
     * @property scrollToOption
     * @type {Boolean}
     * @default false
     */
    scrollToOption: false,
    /**
     * The current initial value
     *
     * @property _currentInitialValue
     * @type {String|Object}
     * @default null
     * @private
     */
    _currentInitialValue: null,
    /**
     * The current selected value
     *
     * @property _currentSelected
     * @type {String|Array}
     * @default null
     * @private
     */
    _currentSelected: null,
    /**
     * the selected options
     *
     * @property _selected
     * @type {Object[]}
     * @default undefined
     * @private
     */
    _selected: undefined,
    /**
     * the current search value
     *
     * @property _searchValue
     * @type {String}
     * @default ''
     * @private
     */
    _searchValue: '',
    /**
     * the current value of the initialValue
     *
     * @property _initialValue
     * @type {Object[]}
     * @default undefined
     * @private
     */
    _initialValue: undefined,
    /**
     * returns the number of selected items minus 1
     *
     * @computed selectedCount
     * @type {Number}
     * @default null
     */
    selectedCount: (0, _object.computed)('_selected.[]', {
      get() {
        const selectedLength = this._selected ? this._selected.length : 0;
        return selectedLength > 1 ? selectedLength - 1 : null;
      }
    }),
    /**
     * returns the first selected option to be shown in a multichip
     *
     * @computed selectedText
     * @type {String}
     */
    selectedText: (0, _object.computed)('_selected.[]', 'optionKey', {
      get() {
        const selected = this._selected;

        // return first selected option concatenated with comma if there are more options
        if (selected && selected.length > 0) {
          var _selected$;
          return `${(_selected$ = selected[0]) === null || _selected$ === void 0 ? void 0 : _selected$[this.optionKey]}${selected.length > 1 ? ',' : ''}`;
        }
        return '';
      }
    }),
    /**
     * Internal options array without the clearing option
     *
     * @computed _options
     * @type {Array}
     * @private
     */
    _options: (0, _object.computed)('options.[]', 'optionKey', 'valueKey', '_searchValue', {
      get() {
        return (0, _array.isArray)(this.options) && this.options.filter(option => {
          const isNoEmptyOption = typeof option[this.valueKey] === 'boolean' || option[this.valueKey];
          const regex = new RegExp(`^${this._searchValue}`, 'i');
          const startsWithSearchValue = !this._searchValue || regex.test(option[this.optionKey]);
          return isNoEmptyOption && startsWithSearchValue || option.type === 'group';
        }) || [];
      }
    }),
    init() {
      this._super();
      (0, _object.set)(this, '_selected', (0, _array.A)());

      // load options in case they are not defined before preselecting
      if (!this.options) {
        this._loadOptionsTask.perform().then(() => this._preselectOptions());
      } else {
        this._preselectOptions();
      }
      (0, _object.set)(this, '_currentSelected', this.selected);
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didUpdateAttrs() {
      this._super(...arguments);
      if (JSON.stringify(this._currentSelected) !== JSON.stringify(this.selected)) {
        if ((0, _utils.isEmpty)(this.selected)) {
          (0, _object.set)(this, '_selected', (0, _array.A)());
        } else {
          this._preselectOptions();
        }
        (0, _object.set)(this, '_currentSelected', this.selected);
      }
      if (JSON.stringify(this._currentInitialValue) !== JSON.stringify(this.initialValue)) {
        if ((0, _utils.isEmpty)(this.initialValue)) {
          (0, _object.set)(this, '_initialValue', null);
        } else {
          this._preselectOptions();
        }
        (0, _object.set)(this, '_currentInitialValue', this.initialValue);
      }
    },
    /**
     * callback called when the selection state or the selected options change
     *
     * @param {Object} option, only for multichip
     * @param {Boolean} value
     * @param {Array} selected, the selected values
     * @function onChange
     */
    onChange() {},
    /**
     * the function to load options asynchronously
     *
     * @function loadOptions
     * @return {Array}
     */
    loadOptions() {},
    /**
     * preselects initial options
     *
     * @function _preselectOptions
     */
    _preselectOptions() {
      if (this.isMultiSelect && this.selected) {
        let selectedValues = this.selected;
        if (typeof this.selected === 'string') {
          // split the string to get an array containing the selected values
          selectedValues = this.selected.split(',');
        }

        // find the option of every value
        const selected = selectedValues.map(selectedOption => (this.options || []).find(option => option[this.valueKey] === selectedOption));
        (0, _object.set)(this, '_selected', selected);
      } else if (this.initialValue !== undefined) {
        // find the option of the initialValue
        const initialOption = (this.options || []).find(option => `${option[this.valueKey]}` === `${this.initialValue}`);
        (0, _object.set)(this, '_initialValue', initialOption);
      }
    },
    /**
     * loads and sets the options
     *
     * @function _loadOptionsTask
     * @type {Task}
     */
    _loadOptionsTask: (0, _emberConcurrency.task)(function* () {
      const options = yield this.loadOptions();
      if (options) {
        (0, _object.set)(this, 'options', options);
      }
    }).drop(),
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      /**
       * selects the clicked option
       *
       * @action onSelectChange
       * @param {Object} option
       * @param {Boolean} checked
       */
      onSelectChange(option, checked) {
        if (this.isMultiSelect) {
          // clone selected array
          const selected = this._selected.splice(0) || [];

          // add the option if checked is true
          if (checked) {
            selected.push(option);
          } else {
            // find index and remove option to uncheck
            const index = selected.findIndex(item => item[this.optionKey] === option[this.optionKey]);
            selected.splice(index, 1);
          }
          (0, _object.set)(this, '_selected', selected);
          const selectedValues = selected && selected.map(item => item[this.valueKey]);
          this.onChange(option, checked, selectedValues);
        } else {
          this.onChange(option);
        }
      },
      /**
       * deselects all options
       *
       * @action deselectAll
       */
      deselectAll() {
        if (this.isMultiSelect) {
          (0, _object.set)(this, '_selected', []);
        }
      }
    }
  });
});