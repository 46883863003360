define("@additive-apps/media/components/mm-media-gallery/header-right", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="pa3 {{@class}} {{@classNames}}" ...attributes>
    {{#if this._showDropdownMenu}}
      <UiActionButton
        @label={{t "mmMediumItem.download"}}
        @icon="download"
        @isDisabled={{this._isDownloadDisabled}}
        @isBlur={{true}}
        @onClick={{this.onDownload}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "LBeFCR12",
    "block": "[[[11,0],[16,0,[29,[\"pa3 \",[30,1],\" \",[30,2]]]],[17,3],[12],[1,\"\\n\"],[41,[30,0,[\"_showDropdownMenu\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@label\",\"@icon\",\"@isDisabled\",\"@isBlur\",\"@onClick\"],[[28,[37,2],[\"mmMediumItem.download\"],null],\"download\",[30,0,[\"_isDownloadDisabled\"]],true,[30,0,[\"onDownload\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"@classNames\",\"&attrs\"],false,[\"if\",\"ui-action-button\",\"t\"]]",
    "moduleName": "@additive-apps/media/components/mm-media-gallery/header-right.hbs",
    "isStrictMode": false
  });
  let MmMediaGalleryHeaderRight = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _class = class MmMediaGalleryHeaderRight extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      /**
       * The current active medium object
       * @property activeMedium
       * @type {Medium}
       */
      _initializerDefineProperty(this, "activeMedium", _descriptor2, this);
      /**
       * Whether dropdown should be hidden
       * @property hideDropdown
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "hideDropdown", _descriptor3, this);
    }
    /**
     * If download of active medium is disabled
     *
     * @computed _isDownloadDisabled
     * @type {Boolean}
     */
    get _isDownloadDisabled() {
      const activeMedium = this.activeMedium;
      if (!activeMedium) {
        return true;
      }
      const {
        mimeType
      } = activeMedium;
      return mimeType === 'video/x-youtube';
    }

    /**
     * URL for media download
     *
     * @computed _downloadUrl
     * @type {String}
     */
    get _downloadUrl() {
      if (!this.activeMedium) {
        return null;
      }
      return `${this.activeMedium.url}?download=1`;
    }

    /**
     * Whether the dropdown menu is visible
     *
     * @computed _showDropdownMenu
     * @type {Boolean}
     */
    get _showDropdownMenu() {
      var _this$currentUser, _this$activeMedium, _this$currentUser2;
      if (this.hideDropdown) {
        return false;
      }
      if (!((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && (_this$currentUser = _this$currentUser.currentOrganization) !== null && _this$currentUser !== void 0 && _this$currentUser.isPartner) && (_this$activeMedium = this.activeMedium) !== null && _this$activeMedium !== void 0 && _this$activeMedium.shared) {
        return false;
      }
      if ((_this$currentUser2 = this.currentUser) !== null && _this$currentUser2 !== void 0 && _this$currentUser2.isViewer) {
        return false;
      }
      return !!this.activeMedium;
    }
    onDownload() {
      window.open(this._downloadUrl, '_blank');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeMedium", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideDropdown", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDownload", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDownload"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MmMediaGalleryHeaderRight);
});