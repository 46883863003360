define("@additive-apps/media/utils/youtube-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFormattedYoutubeVideo = void 0;
  /**
   * Takes video data provided by the Youtube API and returns it in a formatted way
   *
   * @export
   * @param {Object} video
   * @param {Service} intl
   * @function getFormattedYoutubeVideo
   * @return {Object} formatted data
   */
  const getFormattedYoutubeVideo = (video, intl) => {
    const {
      id,
      title,
      description,
      publishedAt,
      duration,
      thumbnail
    } = video;
    const formattedDuration = _formatYoutubeDuration(duration, intl);
    return {
      id,
      title,
      description,
      date: new Date(publishedAt),
      duration: formattedDuration,
      thumbnail
    };
  };

  /**
   * Translates a duration provided by Youtube to a readable string
   * Seconds are omitted on durations of over 2 minutes
   * TODO: Try to refactor
   *
   * @param {String} duration in format 'PT1H20M30S'
   * @param {Service} intl
   * @function formatYoutubeDuration
   * @return {String} in format '1 hour 20 minutes',
   */
  _exports.getFormattedYoutubeVideo = getFormattedYoutubeVideo;
  const _formatYoutubeDuration = (duration, intl) => {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const parts = match === null || match === void 0 ? void 0 : match.slice(1).map(function (part) {
      if (part != null) {
        return part.replace(/\D/, '');
      }
    });
    if (!parts) {
      return '';
    }
    const intlDurationBase = 'mmExternalSearch.duration';
    let hours = parseInt(parts[0]) || false;
    let minutes = parseInt(parts[1]) || false;
    let seconds = hours < 1 && minutes < 2 && parseInt(parts[2]) || false;
    if (hours) {
      hours = `${intl.t(`${intlDurationBase}.hours`, {
        count: hours
      })}`;
    }
    if (minutes) {
      minutes = `${intl.t(`${intlDurationBase}.minutes`, {
        count: minutes
      })}`;
    }
    if (seconds) {
      seconds = `${intl.t(`${intlDurationBase}.seconds`, {
        count: seconds
      })}`;
    }
    return [hours, minutes, seconds].filter(part => part).join(' ');
  };
});